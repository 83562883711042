import { selector } from "recoil";

import tickles from "../../atoms/notifications/tickles";

const addTickle = selector({
  key: "addTickle",
  set: ({ set, get }, newTickle) => {
    set(tickles, [...get(tickles), newTickle]);
  },
});

export default addTickle;
