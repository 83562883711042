import { useContext } from "react";

import { NotificationAlertContext } from "../context/NotificationAlertProvider";

function useNotificationAlert() {
  const {
    message,
    severity,
    addNotificationMessage,
    removeNotificationMessage,
  } = useContext(NotificationAlertContext);
  return {
    message,
    severity,
    addNotificationMessage,
    removeNotificationMessage,
  };
}

export default useNotificationAlert;
