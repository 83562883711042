import "./styles.css";

import * as Yup from "yup";

import { Avatar, IconButton, InputAdornment } from "@material-ui/core/";
import { Container, Grid, Button } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect, useState, useRef } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AuthService from "../../_services/auth.service";
import Bugsnag from "@bugsnag/js";
import Checkbox from "@material-ui/core/Checkbox";
import { ChevronLeft, Visibility, VisibilityOff } from "@material-ui/icons";
import ContentCreatorTermsOfUseModal from "../../components/Modals/ContentCreatorTermsOfUse copy";
import { CountryDropdown } from "react-country-region-selector";
import InputLabel from "@material-ui/core/InputLabel";
import MomentUtils from "@date-io/moment";
import PictyService from "../../_services/picty.service";
import TermsOfUseModal from "../../components/Modals/TermsOfUse";
import TextField from "@material-ui/core/TextField";
import moment from "moment/moment";
import setSignUpUserData from "../../stateManagement/selectors/auth/sign-up-user-data-selector";
import signUpUserDataAtom from "../../stateManagement/atoms/auth/sign-up-user-data-atom";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import useSessionStorage from "../../components/forms/services/storageHooks/useSessionStorage";
import { useStyles } from "./styled";
import { useTranslation } from "react-i18next";
import { userTypeAtom } from "../../stateManagement/atoms/studioAtom";
import ReCAPTCHA from "react-google-recaptcha";
import CircularProgress from "@material-ui/core/CircularProgress";

function SignUp() {
  const classes = useStyles();
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const { addNotificationMessage } = useNotificationAlert();

  const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY;

  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [token, setToken] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const reCaptchaRef = useRef(null);
  const [code, setVerifiedCode] = useState("");

  const [userType] = useRecoilState(userTypeAtom);
  const [selectedPictyPrefId] = useSessionStorage({}, "preferredPictysId");
  const [signUpUserData] = useRecoilState(signUpUserDataAtom); // todo: if this is empty due to reload of browser, then use the local storage.
  const setSignUpUserDataSelector = useSetRecoilState(setSignUpUserData);

  const [openTermsOfUseModal, setOpenTermsOfUseModal] = useState(false);
  const [
    openContentCreatorTermsOfUseModal,
    setOpenContentCreatorTermsOfUseModal,
  ] = useState(false);
  const [favoritePicties, setFavoritePicties] = useState([]);

  const userTypeFromSessionStorage = window.sessionStorage.getItem("userType");
  const signUpDataFromSessionStorage = JSON.parse(
    window.sessionStorage.getItem("sign-up-user-data")
  );

  const handleReCaptchaChange = async (token) => {
    setToken(token);
    setIsValid(true);
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const [bioCount, setBioCount] = useState(0);
  const [bioTitleCount, setBioTitleCount] = useState(0);

  const formik = useFormik({
    initialValues: {
      preferredLanguage:
        localStorage.getItem("language") || localStorage.getItem("i18nextLng"),
      username: "",
      password: "",
      confirmPassword: "",
      email: "",
      birthday: new Date(),
      nationality: "",
      title: "",
      description: "",
      termsOfUse: false,
      readAndAcceptTermsOfUse: false,
      contentCreatorTermsOfUse: false,
      readAndAcceptContentCreatorTermsOfUse: false,
      userType: userType || window.sessionStorage.getItem("userType"),
      legalAge: false,
      receiveNewsletter: true,
      ...(((signUpUserData && signUpUserData?.userType === "Model") ||
        (userTypeFromSessionStorage &&
          userTypeFromSessionStorage === "Model")) && {
        currentProduct: signUpDataFromSessionStorage?.currentProduct,
        userFootType: signUpUserData?.userFootType,
        footSize: signUpUserData?.footSize,
      }),
      ...(((signUpUserData && signUpUserData?.userType === "Lover") ||
        (userTypeFromSessionStorage &&
          userTypeFromSessionStorage === "Lover")) && {
        preferredPicties: selectedPictyPrefId,
      }),

      // provide specific data needed by feet model or feet lover
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(2, t("errUsernameShortSignup"))
        .max(20, t("errUsernameLongSignup"))
        .matches(/^\w+$/, t("username only supports alpha numeric"))
        .required(t("errUsernameRequiredSignup")),
      password: Yup.string()
        .min(8, t("errPasswordShortSignin"))
        .required(t("errNewPasswordRequired")),
      confirmPassword: Yup.string()
        .when("password", {
          is: (val) => val && val.length > 0,
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            t("errPasswordDonotMatch")
          ),
        })
        .min(8, t("errPasswordShortSignin"))
        .required(t("errConfirmNewPassword")),
      email: Yup.string()
        .email(t("errEmailInvalidFormatSignup"))
        .required(t("errEmailRequiredSignup")),
      nationality: Yup.string().required(t("errNationalityRequiredSignup")),
      birthday: Yup.string()
        .required(t("errBirthdayRequiredSignup"))
        .test("birthday", t("errRegistrationDateAbove18Signup"), (value) => {
          return (
            moment().diff(moment(value).format("MM/DD/yyyy"), "years") >= 18
          );
        }),
      title: Yup.string()
        .required(t("errBioTitleRequired"))
        .min(5, "5 caractères min")
        .max(40, "40 caractères max"),
      description: Yup.string()
        .min(10, "10 caractères min")
        .max(400, "400 caractères max")
        .optional(),
      termsOfUse: Yup.bool().oneOf([true], t("errTermOfUseRequiredSignup")),
      contentCreatorTermsOfUse: Yup.bool().when("userType", {
        is: (val) => val === "Model",
        then: Yup.bool().oneOf(
          [true],
          t("errContentCreatorTermRequiredSignup")
        ),
      }),
      legalAge: Yup.bool().oneOf([true], t("errLegalAgeRequiredSignup")),
    }),
    onSubmit: async (values) => {
      try {
        setIsSubmitted(true);
        await AuthService.verifyCaptcha({ token }).then(async (res) => {
          if (!isCaptchaValid) {
            setIsCaptchaValid(true);
          }
          if (res.data.success || isCaptchaValid) {
            await AuthService.signUp(values, code)
              .then((response) => {
                setSignUpUserDataSelector(
                  response?.data?.user || signUpUserData
                );
                history.push("/confirm-registration");
              })
              .catch((error) => {
                setIsSubmitted(false);
                if (
                  error?.response?.data?.error === "Username already exists"
                ) {
                  formik.setErrors({ username: t("Username already exists") });
                  addNotificationMessage(t("Username already exists"), "error");
                  Bugsnag.notify(error?.response?.data?.error);
                } else if (
                  error?.response?.data?.error === "Email already exists" ||
                  error?.response?.data?.error === "emailAlreadyExists"
                ) {
                  formik.setErrors({ email: t("errEmailAlreadyExists") });
                  addNotificationMessage(t("errEmailAlreadyExists"), "error");
                  Bugsnag.notify(error?.response?.validation?.body);
                } else if (
                  error?.response?.data.validation?.body?.keys[0] === "username"
                ) {
                  formik.setErrors({
                    username: t("username only supports alpha numeric"),
                  });
                  addNotificationMessage(
                    t("username only supports alpha numeric"),
                    "error"
                  );
                  Bugsnag.notify(error?.response?.validation?.body);
                  setIsSubmitted(false);
                } else if (
                  error?.response?.data?.error === "usernameAlreadyExists"
                ) {
                  addNotificationMessage(t("errUsernameExist"), "error");
                  formik.setErrors({
                    username: t("errUsernameExist"),
                  });
                  Bugsnag.notify(error?.response.data.error);
                  setIsSubmitted(false);
                }
              });
          } else {
            addNotificationMessage(t("reCaptchaValidationMsg"), "error");
            Bugsnag.notify(res.data.success);
            console.error("reCaptcha", res);
            setIsSubmitted(false);
          }
        });
      } catch (error) {
        addNotificationMessage(error?.response.data, "error");
        Bugsnag.notify(error?.response.data.error);
        setIsSubmitted(false);
      }
    },
  });

  useEffect(async () => {
    const { data } = await AuthService.getRegistrationStatus();
    console.log("data softlaunch: ", data);
    setVerifiedCode(data.registration_status["special_invitation_code"]);
  }, []);

  useEffect(() => {
    if (
      (userType && userType === "Lover") ||
      (window.sessionStorage.getItem("userType") &&
        window.sessionStorage.getItem("userType") === "Lover")
    ) {
      if (selectedPictyPrefId && selectedPictyPrefId.length) {
        const fetchPicties = async () => {
          const picties = selectedPictyPrefId.map(async (picty) => {
            const pictyData = await PictyService.getPictyWithoutAuthentication(
              picty
            );

            return pictyData?.data?.picty;
          });

          const pictiesData = await Promise.all(picties);

          setFavoritePicties(pictiesData);
        };

        fetchPicties();
      }
    }
  }, [selectedPictyPrefId, setFavoritePicties, userType]);

  const handleAcceptTermsOfUse = () => {
    formik.setFieldValue("readAndAcceptTermsOfUse", true);
    formik.setFieldValue("termsOfUse", true);
    setOpenTermsOfUseModal(false);
  };

  const handleAcceptContentCreatorTermsOfUse = () => {
    formik.setFieldValue("contentCreatorTermsOfUse", true);
    formik.setFieldValue("readAndAcceptContentCreatorTermsOfUse", true);
    setOpenContentCreatorTermsOfUseModal(false);
  };

  return (
    <div>
      <div className="profileForm__header header page_fixed_header edit-profile-header__v3">
        <IconButton className="back__button" onClick={() => history.goBack()}>
          <ChevronLeft style={{ fill: "black" }} />
        </IconButton>
        <h4>{t("Profile")}</h4>
      </div>
      <div className={classes.root}>
        {/* Feet lover favorite picties */}
        <Container>
          <Grid>
            {(userType && userType === "Lover") ||
            (window.sessionStorage.getItem("userType") &&
              window.sessionStorage.getItem("userType") === "Lover") ? (
              <div>
                <InputLabel htmlFor="favoritePicties" className={classes.label}>
                  {t("Favorite Pictys")}
                </InputLabel>

                {favoritePicties && favoritePicties.length ? (
                  <div
                    style={{
                      marginBottom: "30px",
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                  >
                    {favoritePicties.map((picty) => (
                      <Avatar
                        key={picty?._id}
                        alt="picty"
                        src={picty?.url}
                        className="postPublication__selectedAvatar"
                      />
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : null}

            {/* possible upload of picture for user. */}

            <form
              noValidate
              className={classes.form}
              onSubmit={formik.handleSubmit}
            >
              <div className={classes.formInput}>
                <InputLabel
                  htmlFor="username"
                  className={classes.label}
                  required
                >
                  {t("username")}
                </InputLabel>
                <TextField
                  id="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={classes.textInput}
                  InputProps={{ disableUnderline: true }}
                />
                {formik.errors.username && formik.touched.username ? (
                  <p className={classes.formikError}>
                    {formik.errors.username}
                  </p>
                ) : null}
              </div>

              <div className={classes.formInput}>
                <InputLabel
                  htmlFor="password"
                  className={classes.label}
                  required
                >
                  {t("passwordTitleSignin")}
                </InputLabel>
                <TextField
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className={classes.textInput}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end" className="password-view">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {formik.errors?.password && formik.touched?.password ? (
                  <p className={classes.formikError}>
                    {formik.errors.password}
                  </p>
                ) : null}
              </div>

              <div className={classes.formInput}>
                <InputLabel
                  htmlFor="confirmPassword"
                  className={classes.label}
                  required
                >
                  {t("confirmNewPasswordTitleSignin")}
                </InputLabel>
                <TextField
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  value={formik.values.confirmPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className={classes.textInput}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end" className="password-view">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {formik.errors?.confirmPassword &&
                formik.touched?.confirmPassword ? (
                  <p className={classes.formikError}>
                    {formik.errors.confirmPassword}
                  </p>
                ) : null}
              </div>

              <div className={classes.formInput}>
                <InputLabel htmlFor="email" className={classes.label} required>
                  {t("emailLabelSignUp")}
                </InputLabel>
                <TextField
                  id="email"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className={classes.textInput}
                  InputProps={{ disableUnderline: true }}
                />
                {formik.errors?.email && formik.touched?.email ? (
                  <p className={classes.formikError}>{formik.errors.email}</p>
                ) : null}
              </div>

              <div
                className={`${classes.materialUiDatePicker} ${classes.formInput}`}
              >
                <InputLabel
                  htmlFor="birthday"
                  className={classes.label}
                  required
                >
                  {t("birthdayTitleSignup")}
                </InputLabel>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="birthday"
                    name="birthday"
                    format="DD/MM/yyyy"
                    value={formik.values.birthday}
                    onChange={(date) => formik.setFieldValue("birthday", date)}
                    onBlur={formik.handleBlur}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{ disableUnderline: true }}
                    keyboardIcon={
                      <ArrowDropDownIcon style={{ fill: "white" }} />
                    }
                  />
                </MuiPickersUtilsProvider>

                {formik.errors?.birthday && formik.touched?.birthday ? (
                  <p className={classes.formikError}>
                    {formik.errors.birthday}
                  </p>
                ) : null}
              </div>

              <div className={classes.formInput}>
                <InputLabel
                  htmlFor="nationality"
                  className={classes.label}
                  required
                >
                  {t("Country")}
                </InputLabel>
                <CountryDropdown
                  id="nationality"
                  name="nationality"
                  value={formik.values.nationality}
                  onChange={(_, e) => formik.handleChange(e)}
                  className={classes.select}
                />

                {formik.errors?.nationality && formik.touched?.nationality ? (
                  <p className={classes.formikError}>
                    {formik.errors.nationality}
                  </p>
                ) : null}
              </div>

              <div className={classes.bio}>
                <div className={classes.formInput}>
                  <InputLabel
                    htmlFor="title"
                    className={classes.label}
                    required
                  >
                    {t("Title of your bio")} (40 caractères max)
                  </InputLabel>
                  <TextField
                    id="title"
                    multiline
                    minRows={4}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onKeyUp={(e) => setBioTitleCount(e.target.value.length)}
                    variant="filled"
                    className={classes.textArea}
                    onBlur={formik.handleBlur}
                  />
                  <div className="bottom-bio-content">
                    {formik.errors?.title && formik.touched?.title ? (
                      <p className={classes.formikError}>
                        {formik.errors.title}
                      </p>
                    ) : null}
                    {
                      <span className="character-count">
                        {40 - bioTitleCount}/40
                      </span>
                    }
                  </div>
                </div>

                <div className={classes.formInput}>
                  <InputLabel htmlFor="description" className={classes.label}>
                    {t("Bio")} (400 caractères max)
                  </InputLabel>
                  <TextField
                    id="description"
                    multiline
                    minRows={4}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onKeyUp={(e) => setBioCount(e.target.value.length)}
                    variant="filled"
                    className={classes.textArea}
                    onBlur={formik.handleBlur}
                  />
                  <div className="bottom-bio-content">
                    {formik.errors?.description &&
                    formik.touched?.description ? (
                      <p className={classes.formikError}>
                        {formik.errors.description}
                      </p>
                    ) : null}
                    {
                      <span className="character-count">
                        {400 - bioCount}/400
                      </span>
                    }
                  </div>
                </div>
              </div>

              <div
                className={`${classes.formInputCheckBox} ${classes.formInput}`}
              >
                <div>
                  <Checkbox
                    onClick={() => {
                      if (formik.values.readAndAcceptTermsOfUse === false) {
                        setOpenTermsOfUseModal(true);
                      } else {
                        formik.setFieldValue(
                          "termsOfUse",
                          !formik.values.termsOfUse
                        );
                      }
                    }}
                    checked={formik.values.termsOfUse}
                    name="termsOfUse"
                  />

                  <p>
                    {t("signUpIhaveReadAndAcceptedLabelPart1")}{" "}
                    <a
                      href={
                        i18n.language === "en"
                          ? "https://feety.com/en/terms-of-service"
                          : "https://feety.com/conditions-generales-utilisation"
                      }
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "blue" }}
                    >
                      {t("signUpIhaveReadAndAcceptedLabelPart2")}
                    </a>
                  </p>
                </div>
                {formik.errors?.termsOfUse && formik.touched?.termsOfUse ? (
                  <p className={classes.formikCheckBoxError}>
                    {formik.errors.termsOfUse}
                  </p>
                ) : null}

                {(userType && userType === "Model") ||
                (window.sessionStorage.getItem("userType") &&
                  window.sessionStorage.getItem("userType") === "Model") ? (
                  <div>
                    <Checkbox
                      onClick={() => {
                        if (
                          formik.values
                            .readAndAcceptContentCreatorTermsOfUse === false
                        ) {
                          // open modal to accept
                          setOpenContentCreatorTermsOfUseModal(true);
                        } else {
                          formik.setFieldValue(
                            "contentCreatorTermsOfUse",
                            !formik.values.contentCreatorTermsOfUse
                          );
                        }
                      }}
                      checked={formik.values.contentCreatorTermsOfUse}
                      name="contentCreatorTermsOfUse"
                    />

                    <p>
                      {t("signUpIhaveReadAndAcceptedContentLabelPart1")}{" "}
                      <a
                        href={
                          i18n.language === "fr"
                            ? "https://feety.com/conditions-utilisation-feetgirl"
                            : "https://feety.com/en/content-providers-written"
                        }
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "blue" }}
                      >
                        {t("signUpIhaveReadAndAcceptedContentLabelPart2")}
                      </a>
                    </p>
                    {formik.errors?.contentCreatorTermsOfUse &&
                    formik.touched?.contentCreatorTermsOfUse ? (
                      <p className={classes.formikCheckBoxError}>
                        {formik.errors.contentCreatorTermsOfUse}
                      </p>
                    ) : null}
                  </div>
                ) : null}

                <div>
                  <Checkbox
                    checked={formik.values.receiveNewsletter}
                    onChange={formik.handleChange}
                    name="receiveNewsletter"
                    id="receiveNewsletter"
                  />

                  <p
                    onClick={() =>
                      formik.setFieldValue(
                        "receiveNewsletter",
                        !formik.values.receiveNewsletter
                      )
                    }
                  >
                    {t("signUpIhearByAllowAccount")}
                  </p>
                </div>

                <div>
                  <Checkbox
                    checked={formik.values.legalAge}
                    onChange={formik.handleChange}
                    name="legalAge"
                    id="legalAge"
                  />

                  <p
                    onClick={() =>
                      formik.setFieldValue("legalAge", !formik.values.legalAge)
                    }
                  >
                    {t("signUpICertifyOver18Age")}
                  </p>
                </div>

                {formik.errors?.legalAge && formik.touched?.legalAge ? (
                  <p className={classes.formikCheckBoxError}>
                    {formik.errors.legalAge}
                  </p>
                ) : null}
              </div>

              <div className={classes.formInput}>
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey={reCaptchaSiteKey}
                  onChange={handleReCaptchaChange}
                  size={"normal"}
                  onExpired={() => {
                    setIsCaptchaValid(false);
                    setIsValid(false);
                    setIsSubmitted(false);
                  }}
                />
              </div>

              <div className={classes.formInput}>
                <Button
                  type={"submit"}
                  style={{
                    background:
                      "linear-gradient(90deg, #E54E70 0%, #FF5768 100%)",
                    height: "clamp(38px, 3vw, 52px)",
                    color: "#fff",
                    boxShadow: "none",
                    padding: "0",
                    fontSize: "clamp(14px, 3vw, 14px)",
                    borderRadius: "128px",
                    width: "100%",
                    opacity: isValid ? "1" : "0.5",
                  }}
                  disabled={!isValid || isSubmitted}
                >
                  {!isSubmitted ? (
                    t("confirm")
                  ) : (
                    <CircularProgress
                      style={{
                        width: "1.875rem",
                        height: "1.875rem",
                        color: "#ccc",
                      }}
                    />
                  )}
                </Button>
              </div>
            </form>
          </Grid>
        </Container>
      </div>

      <TermsOfUseModal
        openTermsOfUseModal={openTermsOfUseModal}
        setOpenTermsOfUseModal={setOpenTermsOfUseModal}
        handleAcceptTermsOfUse={handleAcceptTermsOfUse}
      />

      <ContentCreatorTermsOfUseModal
        handleAcceptContentCreatorTermsOfUse={
          handleAcceptContentCreatorTermsOfUse
        }
        openContentCreatorTermsOfUseModal={openContentCreatorTermsOfUseModal}
        setOpenContentCreatorTermsOfUseModal={
          setOpenContentCreatorTermsOfUseModal
        }
      />
    </div>
  );
}

export default SignUp;
