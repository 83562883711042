import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import { CountryDropdown } from "react-country-region-selector";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MomentUtils from "@date-io/moment";
import { Button as MuiButton, Divider } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { not } from "ramda";
import Bugsnag from "@bugsnag/js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isEmpty as _isEmpty } from "lodash";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { useDropzone } from "react-dropzone";
import Avatar from "@material-ui/core/Avatar";
import AddIcon from "@material-ui/icons/Add";

import { StyledBadge, useStyles } from "./styled";
import ChangeFeetModelSubscriptionPriceButton from "../../../components/EditProfile/ChangeFeetModelSubscriptionPriceButton";
import useNotificationAlert from "../../../hooks/useNotificationAlert";
import { _objectDiffer } from "../../../helpers";
import getUserQuery from "../../../stateManagement/selectors/getUserSelector";
import Button from "../../../components/Button";
import authUserAtom from "../../../stateManagement/atoms/auth/user";
import UserService from "../../../_services/user.service";
import PictyService from "../../../_services/picty.service";
import DefaultProfileImage from "../../../assets/images__v3/default-profile-image.png";
import { handleImageCompression } from "../../../helpers/browser-image-compression";
import Checkbox from "@material-ui/core/Checkbox";
import ImageCropperModal from "../ImageCropperModal/index";

import "../styles.css";
import { setAuthSession } from "../../../helpers/auth.helpers";
import { uploadMedias } from "../../../_services/firebase.service";

function EditFeetModelProfilePage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { addNotificationMessage } = useNotificationAlert();

  const [_, setUpdateAuthenticatedUser] = useRecoilState(authUserAtom);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [feetParticularities, setFeetParticularities] = useState(null);
  const [hasPendingProfileImage, setHasPendingProfileImage] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const { user } = useRecoilValue(getUserQuery);
  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleBlur,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      ...user,
      receiveNewsletter: user.receiveNewsletter,
      birthday:
        user && user.birthday
          ? new Date(moment(user.birthday).format("MM/DD/yyyy"))
          : new Date(),
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(2, t("errUsernameShortSignup"))
        .max(20, t("errUsernameLongSignup"))
        .required(t("errUsernameRequiredSignup")),
      nationality: Yup.string().required(t("errNationalityRequiredSignup")),
      birthday: Yup.string()
        .required(t("errBirthdayRequiredSignup"))
        .test("birthday", t("errRegistrationDateAbove18Signup"), (value) => {
          return (
            moment().diff(moment(value).format("MM/DD/yyyy"), "years") >= 18
          );
        }),
      title: Yup.string()
        .min(5, "5 caractères min")
        .max(40, "40 caractères max"),
      description: Yup.string()
        .min(10, "10 caractères min")
        .max(400, "400 caractères max"),
    }),
    onSubmit: async (values) => {
      setIsUpdatingUser(true);

      // initial values
      const {
        username: initialUsername,
        nationality: initialNationality,
        birthday: initialBirthday,
        title: initialTitle,
        description: initialDescription,
        footSize: initialFootSize,
        userFootType: initialUserFootType,
        image: initialImage,
        receiveNewsletter: initialReceiveNewsletter,
      } = user;

      // new values
      const {
        username,
        title,
        description,
        nationality,
        birthday,
        footSize,
        userFootType,
        image,
        receiveNewsletter,
      } = values;

      // differentiate or check if user data has been updated or changed
      const updatedUserValues = _objectDiffer(
        {
          username,
          title,
          description,
          nationality,
          birthday: moment(birthday).format("MM/DD/yyyy"),
          footSize,
          userFootType: userFootType?._id,
          image: image?._id || "",
          receiveNewsletter: receiveNewsletter,
        },
        {
          username: initialUsername,
          title: initialTitle,
          description: initialDescription,
          nationality: initialNationality,
          birthday: moment(initialBirthday).format("MM/DD/yyyy"),
          footSize: initialFootSize,
          userFootType: initialUserFootType?._id,
          image: initialImage?._id || "",
          receiveNewsletter: initialReceiveNewsletter || false,
        }
      );

      // user data has been changed.
      if (not(_isEmpty(updatedUserValues))) {
        try {
          const { image: imageId, ...updatedUserdata } = updatedUserValues;

          if (imageId) {
            // const compressedFile = await handleImageCompression(image.file);
            await uploadMedias(
              image.file,
              setProgress,
              handleCallBackFn,
              `users/${values._id}`
            );
            // // if there is a change to the image, need to await here to process the upload of image.
            // await UserService.uploadProfileImageBlobFile(compressedFile);
            const data = {
              ...updatedUserdata,
              ...(updatedUserdata?.birthday && { birthday }),
            };

            const response = await UserService.updateUser(values._id, data);

            // after image is uploaded, update the global authenticated user with supposedly an updated image data.
            await setAuthSession(JSON.stringify({ user: response.data }));
            setUpdateAuthenticatedUser(response.data);
          } else {
            const data = {
              ...updatedUserdata,
              ...(updatedUserdata?.birthday && { birthday }),
            };

            const response = await UserService.updateUser(values._id, data);
            await setAuthSession(JSON.stringify({ user: response.data }));
            setUpdateAuthenticatedUser(response.data);
          }

          addNotificationMessage(t("Successful update profile"), "success");
        } catch (error) {
          if (error?.response?.data?.error === "Username already exists") {
            addNotificationMessage(t("Username already exists"), "error");
            Bugsnag.notify(error?.response?.data?.error);
          } else if (
            error?.response?.data.validation?.body?.keys[0] === "username"
          ) {
            addNotificationMessage(
              t("username only supports alpha numeric"),
              "error"
            );
            Bugsnag.notify(error?.response?.validation?.body);
          } else if (
            error?.response?.data?.error ===
            "another image is still unmoderated"
          ) {
            addNotificationMessage(
              t("moderationPictureStillAwaiting"),
              "error"
            );
            Bugsnag.notify(error?.response?.data?.error);
          } else if (
            error?.response?.data?.message === "idVerificationRequired"
          ) {
            addNotificationMessage(t(error?.response?.data?.message), "error");
            Bugsnag.notify(error?.response?.data?.error);
          } else {
            addNotificationMessage(error.message, "error");
          }
        } finally {
          setIsUpdatingUser(false);
        }
      } else {
        user.userType === "Lover"
          ? history.push("/settings-lovers")
          : history.push("/setting-models");
      }
    },
  });

  const handleCallBackFn = async (success, response_url) => {
    // if there is a change to the image, need to await here to process the upload of image.
    if (success) {
      await UserService.uploadProfileImageBlobFile(response_url);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    noDrag: true,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => {
        Object.assign(file, {
          url: URL.createObjectURL(file),
        });

        setFieldValue("image", {
          _id: file.name,
          url: URL.createObjectURL(file),
          file,
        });
      });

      // todo: process compression.
    },
  });

  useEffect(() => {
    async function fetchPictiesByCategory() {
      const response = await PictyService.getPictyByCategory("Inscription");

      if (response) {
        setFeetParticularities(response.data.picties);
      }
    }

    fetchPictiesByCategory();
  }, [setFeetParticularities]);

  useEffect(() => {
    UserService.getPendingProfileImage().then((response) => {
      if (response && response?.image) {
        setHasPendingProfileImage(true);
      }
    });
  }, []);

  return (
    <div className={classes.root}>
      {hasPendingProfileImage && (
        <p className="has_profile_picture_waiting_moderation edit-profile-moderation">
          {t("moderationPictureAwaiting")}
        </p>
      )}
      {/* profile image */}
      <div
        className={classes.profileImage}
        onClick={() => {
          if (!user?.idCheckDetails?.verified)
            history.push("/identity-verification");

          setOpenImageModal(true);
        }}
      >
        <div className="dropzone">
          {/* <input
            {...getInputProps()}
            disabled={!user?.idCheckDetails?.verified}
          /> */}

          {values && values?.image?.url ? (
            <StyledBadge
              badgeContent={<AddIcon />}
              color="secondary"
              overlap={"rectangular"}
            >
              <Avatar src={values?.image?.url} />
            </StyledBadge>
          ) : (
            // todo: need to improve. need to have the default user image icon.
            <Avatar src={DefaultProfileImage} />
          )}
        </div>
      </div>

      {/* profile form settings */}
      <form noValidate className={classes.form} onSubmit={handleSubmit}>
        <div>
          <InputLabel htmlFor="username" className={classes.label}>
            {t("username")}
          </InputLabel>
          <TextField
            id="username"
            value={values.username}
            onChange={handleChange}
            className={classes.textInput}
            InputProps={{ disableUnderline: true }}
          />
          {errors?.username && touched?.username ? (
            <p className="formik-errors">{errors?.username}</p>
          ) : null}
        </div>

        <div>
          <InputLabel htmlFor="nationality" className={classes.label}>
            {t("Country")}
          </InputLabel>
          <CountryDropdown
            id="nationality"
            name="nationality"
            value={values.nationality}
            onChange={(_, e) => handleChange(e)}
            className={classes.select}
          />

          {errors?.nationality && touched?.nationality ? (
            <p className="formik-errors">{errors?.nationality}</p>
          ) : null}
        </div>

        <div className={classes.materialUiDatePicker}>
          <InputLabel htmlFor="birthday" className={classes.label}>
            {t("Date of birth")}
          </InputLabel>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="birthday"
              name="birthday"
              format="DD/MM/yyyy"
              value={values.birthday}
              onChange={(date) => setFieldValue("birthday", date)}
              onBlur={handleBlur}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ disableUnderline: true }}
              keyboardIcon={<ArrowDropDownIcon style={{ fill: "white" }} />}
            />
          </MuiPickersUtilsProvider>

          {errors?.birthday && touched?.birthday ? (
            <p className="formik-errors">{errors?.birthday}</p>
          ) : null}
        </div>

        <div>
          <InputLabel htmlFor="footSize" className={classes.label}>
            {t("Foot size")}
          </InputLabel>
          <TextField
            className={classes.inputNumber}
            id="footSize"
            type="number"
            value={values.footSize}
            InputProps={{ disableUnderline: true }}
            onChange={handleChange}
          />
        </div>

        <div className={classes.accordion}>
          <InputLabel htmlFor="userFootType" className={classes.label}>
            {t("Choose your lineage")}
          </InputLabel>
          <Accordion>
            <AccordionSummary
              expandIcon={
                <ArrowDropDownIcon style={{ fill: "#E5586E" }} size={15} />
              }
              aria-controls="panel1a-content"
              id=""
            >
              <img
                src={values?.userFootType?.url}
                alt={values?.userFootType?.label}
                style={{ width: "50px" }}
              />
            </AccordionSummary>
            <Divider />
            <div className="picties">
              {feetParticularities && feetParticularities.length
                ? feetParticularities.map((picty) => (
                    <div key={picty._id} className="picty">
                      <MuiButton
                        onClick={() => setFieldValue("userFootType", picty)}
                        style={{ borderRadius: "25px" }}
                      >
                        <img
                          className={
                            values?.userFootType?._id === picty._id
                              ? "selected-picty"
                              : undefined
                          }
                          src={picty.url}
                          alt={picty.label}
                          style={{ width: "50px" }}
                        />
                      </MuiButton>
                      <p>{picty.label}</p>
                    </div>
                  ))
                : ""}
            </div>
          </Accordion>
        </div>

        <div className={classes.bio}>
          <div>
            <InputLabel htmlFor="title" className={classes.label}>
              {t("Title of your bio")}
            </InputLabel>
            <TextField
              id="title"
              multiline
              minRows={4}
              value={values.title}
              onChange={handleChange}
              variant="filled"
              className={classes.textArea}
            />
            {errors?.title && touched?.title ? (
              <p className="formik-errors">{errors?.title}</p>
            ) : null}
          </div>

          <div>
            <InputLabel htmlFor="description" className={classes.label}>
              {t("Bio")}
            </InputLabel>
            <TextField
              id="description"
              multiline
              minRows={4}
              value={values.description}
              onChange={handleChange}
              variant="filled"
              className={classes.textArea}
            />
            {errors?.description && touched?.description ? (
              <p className="formik-errors">{errors?.description}</p>
            ) : null}
          </div>
        </div>

        <ChangeFeetModelSubscriptionPriceButton
          monthlySub={values?.currentProduct?.amount}
        />

        <div className={classes.editProfileMoreSettingsContainer}>
          <div
            className={classes.editProfileSettingsButtonRounded}
            // onClick={() =>
            //   addNotificationMessage(
            //     "This feature will be available soon",
            //     "info"
            //   )
            // }
            onClick={() => history.push("/update-email")}
          >
            <MuiButton>{t("Modify your email")}</MuiButton>
            <ArrowForwardIosIcon />
          </div>

          <div
            className={classes.editProfileSettingsButtonRounded}
            onClick={() => history.push("/change-password")}
          >
            <MuiButton>{t("Change your password")}</MuiButton>
            <ArrowForwardIosIcon />
          </div>
        </div>

        <div>
          <Checkbox
            checked={values.receiveNewsletter}
            onChange={handleChange}
            name="receiveNewsletter"
            id="receiveNewsletter"
          />
          <span
            onClick={() =>
              setFieldValue("receiveNewsletter", !values.receiveNewsletter)
            }
          >
            {t("News Letters Email")}
          </span>
        </div>

        <Button
          type="submit"
          width="clamp(100%, 4vw, 24.5rem)"
          background="#F35162"
          height="clamp(42px, 4vw, 52px)"
        >
          {isUpdatingUser ? (
            <CircularProgress color="inherit" size={15} />
          ) : (
            t("Save")
          )}
        </Button>
      </form>

      <ImageCropperModal
        openModal={openImageModal}
        setOpenModal={setOpenImageModal}
        values={values}
        setFieldValue={setFieldValue}
      />
    </div>
  );
}

export default EditFeetModelProfilePage;
