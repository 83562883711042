import React, { useEffect, useRef, useState } from "react";
import { Avatar, Button, IconButton } from "@material-ui/core";
import { useRecoilState, useRecoilValue } from "recoil";
import { ToastContainer } from "react-toastify";
import { isEmpty as _isEmpty, remove as _remove } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { ChevronLeft } from "@material-ui/icons";

import "react-toastify/dist/ReactToastify.css";

import "./choosePictyInscription.css";

import authUserAtom from "../../stateManagement/atoms/auth/user";

import preferredPictysAtom from "../../stateManagement/atoms/preferredPictys";

import getPictiesQuery from "../../stateManagement/selectors/getPictiesSelector";
import PictyService from "../../_services/picty.service";
import UserService from "../../_services/user.service";
import CloseIcon from "../../assets/images/pict/close.svg";
import useSessionStorage from "../../components/forms/services/storageHooks/useSessionStorage";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import { useTranslation } from "react-i18next";

// INSCRIPTION PICTY LIST
const DisplayInscriptionList = ({
  preferredPictys,
  setPreferredPictys,
  buttonRef,
  setFullList,
  picties,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="picty-content__inscription">
        <div className="PictyListFull">
          <h3 style={{ fontSize: 16 }}>{t("Type of feet")}</h3>
          <div className="pictyIcons">
            <div className="picty__container">
              {picties &&
                picties.length &&
                picties
                  .filter((picty) => picty.category === "Inscription")
                  .map((picty) => (
                    <div key={picty._id} className="imgPicty">
                      {preferredPictys.length <= 5 ? (
                        <Button
                          ref={buttonRef}
                          onClick={() => {
                            const existingSelectedPicty = preferredPictys.filter(
                              (id) => id === picty._id
                            );

                            if (!existingSelectedPicty.length) {
                              setPreferredPictys((prevState) =>
                                prevState.concat(picty._id)
                              );
                              setFullList((prevState) =>
                                prevState.concat(picty)
                              );
                            }
                          }}
                        >
                          <img
                            src={picty.url}
                            alt=""
                            className={
                              preferredPictys.filter((id) => id === picty._id)
                                .length
                                ? "selectedPicty"
                                : ""
                            }
                            style={{ width: "50px" }}
                          />
                        </Button>
                      ) : (
                        <Button
                          disabled
                          style={{
                            filter: "grayscale(1) brightness(0.9)",
                            transition: "filter 0.6s ease-in-out",
                          }}
                        >
                          <img
                            src={picty.url}
                            alt=""
                            style={{ width: "50px" }}
                          />
                        </Button>
                      )}
                      <p className="paragListPicty">{t(picty.label)}</p>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// QUOTIDIEN PICTY LIST
const DisplayQuotidienList = ({
  preferredPictys,
  setPreferredPictys,
  buttonRef,
  setFullList,
  picties,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="picty-content__quotidien">
        <div className="PictyListFull">
          <h3 style={{ fontSize: 16 }}>{t("On the daily")}</h3>
          <div className="pictyIcons">
            <div className="picty__container">
              {picties &&
                picties.length &&
                picties
                  .filter((picty) => picty.category === "Au quotidien")
                  .map((picty) => (
                    <div key={picty._id} className="imgPicty">
                      {preferredPictys.length <= 5 ? (
                        <Button
                          ref={buttonRef}
                          onClick={() => {
                            const existingSelectedPicty = preferredPictys.filter(
                              (id) => id === picty._id
                            );

                            if (!existingSelectedPicty.length) {
                              setPreferredPictys((prevState) =>
                                prevState.concat(picty._id)
                              );
                              setFullList((prevState) =>
                                prevState.concat(picty)
                              );
                            }
                          }}
                        >
                          <img
                            src={picty.url}
                            alt=""
                            className={
                              preferredPictys.filter((id) => id === picty._id)
                                .length
                                ? "selectedPicty"
                                : ""
                            }
                            style={{ width: "50px" }}
                          />
                        </Button>
                      ) : (
                        <Button
                          disabled
                          style={{
                            filter: "grayscale(1) brightness(0.9)",
                            transition: "filter 0.6s ease-in-out",
                          }}
                        >
                          <img
                            src={picty.url}
                            alt=""
                            style={{ width: "50px" }}
                          />
                        </Button>
                      )}
                      <p className="paragListPicty">{t(picty.label)}</p>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// SOPHISTIQUÉ PICTY LIST
const DisplaySophistiqueList = ({
  preferredPictys,
  setPreferredPictys,
  buttonRef,
  setFullList,
  picties,
}) => {
  const { t } = useTranslation();

  return (
    <div className="picty-content__Sophistique">
      <div className="PictyListFull">
        <h3 style={{ fontSize: 16 }}>{t("Sophisticated")}</h3>
        <div className="pictyIcons">
          <div className="picty__container">
            {picties &&
              picties.length &&
              picties
                .filter((picty) => picty.category === "Les sophistiques")
                .map((picty) => (
                  <div key={picty._id} className="imgPicty">
                    {preferredPictys.length <= 5 ? (
                      <Button
                        ref={buttonRef}
                        onClick={() => {
                          const existingSelectedPicty = preferredPictys.filter(
                            (id) => id === picty._id
                          );

                          if (!existingSelectedPicty.length) {
                            setPreferredPictys((prevState) =>
                              prevState.concat(picty._id)
                            );
                            setFullList((prevState) => prevState.concat(picty));
                          }
                        }}
                      >
                        <img
                          src={picty.url}
                          alt=""
                          className={
                            preferredPictys.filter((id) => id === picty._id)
                              .length
                              ? "selectedPicty"
                              : ""
                          }
                          style={{ width: "50px" }}
                        />
                      </Button>
                    ) : (
                      <Button
                        disabled
                        style={{
                          filter: "grayscale(1) brightness(0.9)",
                          transition: "filter 0.6s ease-in-out",
                        }}
                      >
                        <img src={picty.url} alt="" style={{ width: "50px" }} />
                      </Button>
                    )}
                    <p className="paragListPicty">{t(picty.label)}</p>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// VEDETTES PICTY LIST
const DisplayVedettesList = ({
  preferredPictys,
  setPreferredPictys,
  buttonRef,
  picties,
  setFullList,
}) => {
  const { t } = useTranslation();

  return (
    <div className="picty-content__Vedettes">
      <div className="PictyListFull">
        <h3 style={{ fontSize: 16 }}>{t("The stars")}</h3>
        <div className="pictyIcons">
          <div className="picty__container">
            {picties &&
              picties.length &&
              picties
                .filter((picty) => picty.category === "Les vedettes")
                .map((picty) => (
                  <div key={picty._id} className="imgPicty">
                    {preferredPictys.length <= 5 ? (
                      <Button
                        ref={buttonRef}
                        onClick={() => {
                          const existingSelectedPicty = preferredPictys.filter(
                            (id) => id === picty._id
                          );

                          if (!existingSelectedPicty.length) {
                            setPreferredPictys((prevState) =>
                              prevState.concat(picty._id)
                            );
                            setFullList((prevState) => prevState.concat(picty));
                          }
                        }}
                      >
                        <img
                          src={picty.url}
                          alt=""
                          className={
                            preferredPictys.filter((id) => id === picty._id)
                              .length
                              ? "selectedPicty"
                              : ""
                          }
                          style={{ width: "50px" }}
                        />
                      </Button>
                    ) : (
                      <Button
                        disabled
                        style={{
                          filter: "grayscale(1) brightness(0.9)",
                          transition: "filter 0.6s ease-in-out",
                        }}
                      >
                        <img src={picty.url} alt="" style={{ width: "50px" }} />
                      </Button>
                    )}
                    <p className="paragListPicty">{t(picty.label)}</p>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// AUDACIEUX PICTY LIST
const DisplayAudacieuxList = ({
  preferredPictys,
  setPreferredPictys,
  buttonRef,
  setFullList,
  picties,
}) => {
  const { t } = useTranslation();

  return (
    <div className="picty-content__Audacieux">
      <div className="PictyListFull">
        <h3 style={{ fontSize: 16 }}>{t("The bold")}</h3>
        <div className="pictyIcons">
          <div className="picty__container">
            {picties &&
              picties.length &&
              picties
                .filter((picty) => picty.category === "Les audecieux")
                .map((picty) => (
                  <div key={picty._id} className="imgPicty">
                    {preferredPictys.length <= 5 ? (
                      <Button
                        ref={buttonRef}
                        onClick={() => {
                          const existingSelectedPicty = preferredPictys.filter(
                            (id) => id === picty._id
                          );

                          if (!existingSelectedPicty.length) {
                            setPreferredPictys((prevState) =>
                              prevState.concat(picty._id)
                            );
                            setFullList((prevState) => prevState.concat(picty));
                          }
                        }}
                      >
                        <img
                          src={picty.url}
                          alt=""
                          className={
                            preferredPictys.filter((id) => id === picty._id)
                              .length
                              ? "selectedPicty"
                              : ""
                          }
                          style={{ width: "50px" }}
                        />
                      </Button>
                    ) : (
                      <Button
                        disabled
                        style={{
                          filter: "grayscale(1) brightness(0.9)",
                          transition: "filter 0.6s ease-in-out",
                        }}
                      >
                        <img src={picty.url} alt="" style={{ width: "50px" }} />
                      </Button>
                    )}
                    <p className="paragListPicty">{t(picty.label)}</p>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// PERSONNALITÉ PICTY LIST
const DisplayPersonnaliteList = ({
  preferredPictys,
  setPreferredPictys,
  buttonRef,
  setFullList,
  picties,
}) => {
  const { t } = useTranslation();

  return (
    <div className="picty-content__Personnalite">
      <div className="PictyListFull">
        <h3 style={{ fontSize: 16 }}>{t("Personalite")}</h3>
        <div className="pictyIcons">
          <div className="picty__container">
            {picties &&
              picties.length &&
              picties
                .filter((picty) => picty.category === "Personalite")
                .map((picty) => (
                  <div key={picty._id} className="imgPicty">
                    {preferredPictys.length <= 5 ? (
                      <Button
                        ref={buttonRef}
                        onClick={() => {
                          const existingSelectedPicty = preferredPictys.filter(
                            (id) => id === picty._id
                          );

                          if (!existingSelectedPicty.length) {
                            setPreferredPictys((prevState) =>
                              prevState.concat(picty._id)
                            );
                            setFullList((prevState) => prevState.concat(picty));
                          }
                        }}
                      >
                        <img
                          src={picty.url}
                          alt=""
                          className={
                            preferredPictys.filter((id) => id === picty._id)
                              .length
                              ? "selectedPicty"
                              : ""
                          }
                          style={{ width: "50px" }}
                        />
                      </Button>
                    ) : (
                      <Button
                        disabled
                        style={{
                          filter: "grayscale(1) brightness(0.9)",
                          transition: "filter 0.6s ease-in-out",
                        }}
                      >
                        <img src={picty.url} alt="" style={{ width: "50px" }} />
                      </Button>
                    )}
                    <p className="paragListPicty">{picty.label}</p>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// PIEDS NUS PICTY LIST
const DisplayPiedsNusList = ({
  preferredPictys,
  setPreferredPictys,
  buttonRef,
  setFullList,
  picties,
}) => {
  const { t } = useTranslation();

  return (
    <div className="picty-content__PiedsNus">
      <div className="PictyListFull">
        <h3 style={{ fontSize: 16 }}>{t("Barefoot")}</h3>
        <div className="pictyIcons">
          <div className="picty__container">
            {picties &&
              picties.length &&
              picties
                .filter((picty) => picty.category === "Pieds nus")
                .map((picty) => (
                  <div key={picty._id} className="imgPicty">
                    {preferredPictys.length <= 5 ? (
                      <Button
                        ref={buttonRef}
                        onClick={() => {
                          const existingSelectedPicty = preferredPictys.filter(
                            (id) => id === picty._id
                          );

                          if (!existingSelectedPicty.length) {
                            setPreferredPictys((prevState) =>
                              prevState.concat(picty._id)
                            );
                            setFullList((prevState) => prevState.concat(picty));
                          }
                        }}
                      >
                        <img
                          src={picty.url}
                          alt=""
                          className={
                            preferredPictys.filter((id) => id === picty._id)
                              .length
                              ? "selectedPicty"
                              : ""
                          }
                          style={{ width: "50px" }}
                        />
                      </Button>
                    ) : (
                      <Button
                        disabled
                        style={{
                          filter: "grayscale(1) brightness(0.9)",
                          transition: "filter 0.6s ease-in-out",
                        }}
                      >
                        <img src={picty.url} alt="" style={{ width: "50px" }} />
                      </Button>
                    )}
                    <p className="paragListPicty">{t(picty.label)}</p>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// GLOBAL COMPONENT
const ChoosePictyInscription = () => {
  const buttonRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { addNotificationMessage } = useNotificationAlert();

  // global state of authenticated user. When updated from here, all subscribed components from global state will be reflected.
  const [, setUpdateAuthenticatedUser] = useRecoilState(authUserAtom);

  const [user] = useSessionStorage({}, "infosUser");

  // remote data
  const { picties } = useRecoilValue(getPictiesQuery);

  const [clickedOnPicty] = useState();
  const [clikedPictys, setClickedPictys] = useState(false);
  const [fullList, setFullList] = useState([]);
  const [preferredPictys, setPreferredPictys] = useRecoilState(
    preferredPictysAtom
  );

  // watch for history location state if coming from edit-profile page.
  useEffect(() => {
    if (location && location.state && location.state.preferredPicties) {
      if (location.state.preferredPicties.length) {
        setPreferredPictys([...location.state.preferredPicties]);

        async function fetchPicty() {
          const pictiesPromise = location.state.preferredPicties.map(
            async (picty) => {
              const response = await PictyService.getPicty(picty);

              return response.data.picty;
            }
          );

          const pictiesData = await Promise.all(pictiesPromise);

          setFullList([...pictiesData]);
        }

        fetchPicty();
      }
    }
  }, [location]);

  const handleClickNextStep = async () => {
    if (preferredPictys.length !== 0) {
      // coming from edit-profile page.
      if (location && location.state && location.state.editProfile) {
        const response = await UserService.updateUser(location.state.user._id, {
          preferredPicties: preferredPictys,
        });

        setUpdateAuthenticatedUser(response.data);

        addNotificationMessage("Successful edit of favorite pictys", "success");
        history.goBack();
      } else {
        sessionStorage.setItem(
          "preferredPictysId",
          JSON.stringify(preferredPictys)
        );
        localStorage.setItem(
          "preferredPictysId",
          JSON.stringify(preferredPictys)
        );

        sessionStorage.setItem(
          "preferredPictyImg",
          JSON.stringify(
            preferredPictys.length
              ? preferredPictys.map((pictyId) => {
                  return picties
                    .filter((pictyData) => pictyData._id === pictyId)
                    .map((selectedPicty) => {
                      return selectedPicty.url;
                    });
                })
              : []
          )
        );

        setTimeout(() => {
          history.push("/sign-up");
        }, 700);
      }
    } else {
      addNotificationMessage("Vous devez selectionner des picty!!", "error");
    }
  };

  // NEED TO DEBUG THIS CODE, ITS REMOVE ALL PICTY NOT ONE BY ONE AT THIS TIME!!
  const handleRemove = () => {
    setClickedPictys(true);
    if (clikedPictys) {
      const evens = fullList.filter((item) => item.id);
      const removeValue = evens.splice(clickedOnPicty, 1);
      const evens2 = preferredPictys.filter(
        (item) => item.id === clickedOnPicty
      );
      const removeValue2 = evens2.splice(clickedOnPicty, 1);
      setFullList(removeValue);
      setPreferredPictys(removeValue2);
    }
  };

  const handleRemoveSelectedSearchFilterPicty = (selectedPicty) => {
    setPreferredPictys((prev) =>
      _remove([...prev], (picty) => picty !== selectedPicty._id)
    );

    setFullList((prev) =>
      _remove([...prev], (picty) => picty._id !== selectedPicty._id)
    );
  };

  return (
    <div className="choose-picty__container">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{top: "5em"}}
      />
      <div className="choosingArea">
        <div className="chooseArea_header_contents">
          <div className="chooseArea_action">
            <IconButton
              className="back__button"
              onClick={() => {
                // if there is an active user, most likely this is coming from edit-profile page
                if (user && !_isEmpty(user)) {
                  history.goBack();
                } else {
                  // there is no user yet, so most likely this is for sign-up process. If there is no user yet, then clicked back from choose picty page, it
                  // must go back to choose-account page.
                  history.push("/choose-account");
                }
              }}
            >
              <ChevronLeft />
            </IconButton>

            <div style={{ padding: "0 15px 0 0", fontSize: "15px" }}>
              <b>{t("Choose your 6 favorite Pictys,")}</b>
              <br />
              <b>{t("our revisited hashtags!")}</b>
            </div>
            <button
              className="MuiButton-containedSecondary pictyInscription"
              onClick={handleClickNextStep}
              style={{ width: "78px", backgroundColor: "#E1305A" }}
            >
              {t("OK")}
            </button>
          </div>
          <div className="preferredListTitle">
            <b>{`${preferredPictys.length} / 6`}</b>
          </div>
          <div /* className="chooseAreaContent" */>
            <div
              className="postPublicationSelectedPicty__list picty__container"
              style={{ justifyContent: "start" }}
            >
              {preferredPictys.length !== 0 &&
                preferredPictys.map((resFiPict, index) => (
                  <React.Fragment key={index}>
                    {picties
                      .filter((picty) => picty._id === resFiPict)
                      .map((selectedPicty) => (
                        <div key={selectedPicty._id}>
                          <div className="picty__with_delete">
                            <Avatar
                              src={selectedPicty.url}
                              className="postPublication__selectedAvatar"
                            />
                            <span className="postPublicationPictylist__button">
                              <img
                                onClick={() =>
                                  handleRemoveSelectedSearchFilterPicty(
                                    selectedPicty
                                  )
                                }
                                className="postPublicationPictyList__deleteButton"
                                src={CloseIcon}
                              />
                            </span>
                          </div>
                        </div>
                      ))}
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div className="pictyList">
        <div className="picty-img">
          <DisplayInscriptionList
            setFullList={setFullList}
            buttonRef={buttonRef}
            preferredPictys={preferredPictys}
            setPreferredPictys={setPreferredPictys}
            picties={picties}
          />
          <DisplayQuotidienList
            setFullList={setFullList}
            buttonRef={buttonRef}
            preferredPictys={preferredPictys}
            setPreferredPictys={setPreferredPictys}
            picties={picties}
          />
          <DisplaySophistiqueList
            setFullList={setFullList}
            buttonRef={buttonRef}
            preferredPictys={preferredPictys}
            setPreferredPictys={setPreferredPictys}
            picties={picties}
          />
          <DisplayVedettesList
            setFullList={setFullList}
            buttonRef={buttonRef}
            preferredPictys={preferredPictys}
            setPreferredPictys={setPreferredPictys}
            picties={picties}
          />
          <DisplayAudacieuxList
            setFullList={setFullList}
            buttonRef={buttonRef}
            preferredPictys={preferredPictys}
            setPreferredPictys={setPreferredPictys}
            picties={picties}
          />
          <DisplayPiedsNusList
            setFullList={setFullList}
            buttonRef={buttonRef}
            preferredPictys={preferredPictys}
            setPreferredPictys={setPreferredPictys}
            picties={picties}
          />
          {/* <DisplayPersonnaliteList
            setFullList={setFullList}
            buttonRef={buttonRef}
            preferredPictys={preferredPictys}
            setPreferredPictys={setPreferredPictys}
            picties={picties}
          /> */}
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default ChoosePictyInscription;
