export const FILTER_1977 = [
  {
    name: "sepia",
    property: "sepia",
    range: {
      min: 0,
      max: 2,
    },
    unit: "%",
  },
  {
    name: "saturate",
    property: "saturate",
    range: {
      min: 0,
      max: 114.4,
    },
    unit: "%",
  },
  {
    name: "hue-rotate",
    property: "hue-rotate",
    range: {
      min: 0,
      max: -32,
    },
    unit: "deg",
  },
];

export const FILTER_ADEN = [
  {
    name: "sepia",
    property: "sepia",
    range: {
      min: 0,
      max: 0,
    },
    unit: "%",
  },
  {
    name: "saturate",
    property: "saturate",
    range: {
      min: 0,
      max: 130,
    },
    unit: "%",
  },
  {
    name: "brightness",
    property: "brightness",
    range: {
      min: 0,
      max: 110,
    },
    unit: "%",
  },
  {
    name: "hue-rotate",
    property: "hue-rotate",
    range: {
      min: 0,
      max: 0,
    },
    unit: "deg",
  },
  {
    name: "contrast",
    property: "contrast",
    range: {
      min: 0,
      max: 110,
    },
    unit: "%",
  },
];

export const DEFAULT_OPTIONS_3 = [
  {
    name: "saturate",
    property: "saturate",
    range: {
      min: 0,
      max: 153,
    },
    unit: "%",
  },
  {
    name: "contrast",
    property: "contrast",
    range: {
      min: 0,
      max: 79,
    },
    unit: "%",
  },
  {
    name: "brightness",
    property: "brightness",
    range: {
      min: 0,
      max: 103,
    },
    unit: "%",
  },
  {
    name: "hue-rotate",
    property: "hue-rotate",
    range: {
      min: 0,
      max: 0,
    },
    unit: "deg",
  },
  {
    name: "sepia",
    property: "sepia",
    range: {
      min: 0,
      max: 98,
    },
    unit: "%",
  },
];

export const DEFAULT_OPTIONS_4 = [
  {
    name: "contrast",
    property: "contrast",
    range: {
      min: 0,
      max: 90,
    },
    unit: "%",
  },
  {
    name: "brightness",
    property: "brightness",
    range: {
      min: 0,
      max: 110,
    },
    unit: "%",
  },
  {
    name: "saturate",
    property: "saturate",
    range: {
      min: 0,
      max: 150,
    },
    unit: "%",
  },
  {
    name: "hue-rotate",
    property: "hue-rotate",
    range: {
      min: 0,
      max: -10,
    },
    unit: "deg",
  },
  {
    name: "sepia",
    property: "sepia",
    range: {
      min: 0,
      max: 0,
    },
    unit: "%",
  },
];

export const FILTER_AMARO = [
  {
    name: "brightness",
    property: "brightness",
    range: {
      min: 0,
      max: 100,
    },
    unit: "%",
  },
  {
    name: "saturate",
    property: "saturate",
    range: {
      min: 0,
      max: 107,
    },
    unit: "%",
  },
  {
    name: "contrast",
    property: "contrast",
    range: {
      min: 0,
      max: 153,
    },
    unit: "%",
  },
];

export const FILTER_ASHBY = [
  {
    name: "saturate",
    property: "saturate",
    range: {
      min: 0,
      max: 67,
    },
    unit: "%",
  },
  {
    name: "contrast",
    property: "contrast",
    range: {
      min: 0,
      max: 185,
    },
    unit: "%",
  },
  {
    name: "hue-rotate",
    property: "hue-rotate",
    range: {
      min: 0,
      max: 0,
    },
    unit: "deg",
  },
  {
    name: "brightness",
    property: "brightness",
    range: {
      min: 0,
      max: 85,
    },
    unit: "%",
  },
];

export const FILTER_BRANNAN = [
  {
    name: "brightness",
    property: "brightness",
    range: {
      min: 0,
      max: 110,
    },
    unit: "%",
  },
  {
    name: "saturate",
    property: "saturate",
    range: {
      min: 0,
      max: 9,
    },
    unit: "%",
  },
  {
    name: "contrast",
    property: "contrast",
    range: {
      min: 0,
      max: 125,
    },
    unit: "%",
  },
  {
    name: "sepia",
    property: "sepia",
    range: {
      min: 0,
      max: 4,
    },
    unit: "%",
  },
  {
    name: "hue-rotate",
    property: "hue-rotate",
    range: {
      min: 0,
      max: -2,
    },
    unit: "deg",
  },
];

export const FILTER_BROOKLYN = [
  {
    name: "brightness",
    property: "brightness",
    range: {
      min: 0,
      max: 110,
    },
    unit: "%",
  },
  {
    name: "contrast",
    property: "contrast",
    range: {
      min: 0,
      max: 90,
    },
    unit: "%",
  },
  {
    name: "saturate",
    property: "saturate",
    range: {
      min: 0,
      max: 100,
    },
    unit: "%",
  },
  {
    name: "sepia",
    property: "sepia",
    range: {
      min: 0,
      max: 0,
    },
    unit: "%",
  },
  {
    name: "hue-rotate",
    property: "hue-rotate",
    range: {
      min: 0,
      max: 0,
    },
    unit: "deg",
  },
];

export const FILTER_CHARMES = [
  {
    name: "brightness",
    property: "brightness",
    range: {
      min: 0,
      max: 125,
    },
    unit: "%",
  },
  {
    name: "contrast",
    property: "contrast",
    range: {
      min: 0,
      max: 125,
    },
    unit: "%",
  },
  {
    name: "sepia",
    property: "sepia",
    range: {
      min: 0,
      max: 25,
    },
    unit: "%",
  },
  {
    name: "hue-rotate",
    property: "hue-rotate",
    range: {
      min: 0,
      max: -5,
    },
    unit: "deg",
  },
  {
    name: "saturate",
    property: "saturate",
    range: {
      min: 0,
      max: 13.5,
    },
    unit: "%",
  },
];

export const FILTER_CLARENDON = [
  {
    name: "brightness",
    property: "brightness",
    range: {
      min: 0,
      max: 100,
    },
    unit: "%",
  },
  {
    name: "saturate",
    property: "saturate",
    range: {
      min: 0,
      max: 125,
    },
    unit: "%",
  },
  {
    name: "contrast",
    property: "contrast",
    range: {
      min: 0,
      max: 120,
    },
    unit: "%",
  },
  {
    name: "sepia",
    property: "sepia",
    range: {
      min: 0,
      max: 0,
    },
    unit: "%",
  },
  {
    name: "hue-rotate",
    property: "hue-rotate",
    range: {
      min: 0,
      max: 0,
    },
    unit: "deg",
  },
];

export const FILTER_CREMA = [
  {
    name: "brightness",
    property: "brightness",
    range: {
      min: 0,
      max: 100,
    },
    unit: "%",
  },
  {
    name: "contrast",
    property: "contrast",
    range: {
      min: 0,
      max: 90,
    },
    unit: "%",
  },
  {
    name: "sepia",
    property: "sepia",
    range: {
      min: 0,
      max: 20,
    },
    unit: "%",
  },
  {
    name: "hue-rotate",
    property: "hue-rotate",
    range: {
      min: 0,
      max: 0,
    },
    unit: "deg",
  },
  {
    name: "saturate",
    property: "saturate",
    range: {
      min: 0,
      max: 100,
    },
    unit: "%",
  },
];

export const FILTER_DOGPATCH = [
  {
    name: "contrast",
    property: "contrast",
    range: {
      min: 0,
      max: 90,
    },
    unit: "%",
  },
  {
    name: "brightness",
    property: "brightness",
    range: {
      min: 0,
      max: 120,
    },
    unit: "%",
  },
  {
    name: "sepia",
    property: "sepia",
    range: {
      min: 0,
      max: 0,
    },
    unit: "%",
  },
  {
    name: "saturate",
    property: "saturate",
    range: {
      min: 0,
      max: 110,
    },
    unit: "%",
  },
];

export const FILTER_EARLYBIRD = [
  {
    name: "brightness",
    property: "brightness",
    range: {
      min: 0,
      max: 110,
    },
    unit: "%",
  },
  {
    name: "contrast",
    property: "contrast",
    range: {
      min: 0,
      max: 110,
    },
    unit: "%",
  },
  {
    name: "sepia",
    property: "sepia",
    range: {
      min: 0,
      max: 30,
    },
    unit: "%",
  },
  {
    name: "hue-rotate",
    property: "hue-rotate",
    range: {
      min: 0,
      max: 0,
    },
    unit: "deg",
  },
  {
    name: "grayscale",
    property: "grayscale",
    range: {
      min: 0,
      max: 100,
    },
    unit: "%",
  },
  {
    name: "saturate",
    property: "saturate",
    range: {
      min: 0,
      max: 100,
    },
    unit: "%",
  },
];

export const FILTER_GINGHAM = [
  {
    name: "brightness",
    property: "brightness",
    range: {
      min: 0,
      max: 110,
    },
    unit: "%",
  },
  {
    name: "contrast",
    property: "contrast",
    range: {
      min: 0,
      max: 100,
    },
    unit: "%",
  },
  {
    name: "hue-rotate",
    property: "hue-rotate",
    range: {
      min: 0,
      max: 350,
    },
    unit: "deg",
  },
  {
    name: "saturate",
    property: "saturate",
    range: {
      min: 0,
      max: 160,
    },
    unit: "%",
  },
  {
    name: "sepia",
    property: "sepia",
    range: {
      min: 0,
      max: 30,
    },
    unit: "%",
  },
];

export const FILTER_GINZA = [
  {
    name: "brightness",
    property: "brightness",
    range: {
      min: 0,
      max: 100,
    },
    unit: "%",
  },
  {
    name: "contrast",
    property: "contrast",
    range: {
      min: 0,
      max: 150,
    },
    unit: "%",
  },
  {
    name: "sepia",
    property: "sepia",
    range: {
      min: 0,
      max: 0,
    },
    unit: "%",
  },
  {
    name: "hue-rotate",
    property: "hue-rotate",
    range: {
      min: 0,
      max: 346,
    },
    unit: "deg",
  },
  {
    name: "saturate",
    property: "saturate",
    range: {
      min: 0,
      max: 110,
    },
    unit: "%",
  },
];
