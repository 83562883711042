import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  proposeDialogTitleRoot: {
    margin: 0,
    // padding: theme.spacing(2),
    // padding: "13px 13px 0 0 ",
    padding: "15px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",

    "& .MuiTypography-h6": {
      fontSize: "16px",
      fontWeight: "700",
    },

    "& .sub-text": {
      fontSize: "15px",
    },
  },
  proposeDialogContentRoot: {
    padding: "5px 20px",
    fontSize: "14px",

    "& .buy-tickles-check-list": {
      listStyle: "none",
      padding: 0,
      margin: 0,
    },

    "& .buy-tickles-check-list > li": {
      fontSize: "0.75rem",
      marginBottom: "16px",
      display: "flex",
      alignItems: "center",
      gap: "8.21px",
    },

    "&  .buy-tickles-check-list > .leave-tips-in-the-chat": {
      alignItems: "flex-start !important",
    },

    "& .buy-tickles-check-list > li > .check-icon": {
      fill: "#FF5768 !important",
    },

    "& .tickles-prices": {
      // width: "261px",
      // height: "152px",
      display: "flex",
      flexWrap: "wrap",
      marginTop: "22px",
      gap: "10px",
      justifyContent: "space-around",
    },

    "& .tickles-prices > .tickles-prices-item": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: "0 0 23.3333333333%",
      marginBottom: "5px",
      cursor: "pointer",
    },

    "& .tickles-prices > .tickles-prices-item div:first-child": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      outline: "0.01em solid #9f9f9f ",
      width: "80%",
      padding: "10px",
      borderRadius: "20px",
      justifyContent: "flex-end",
      gap: "5px",
    },

    "& .tickles-prices > .tickles-prices-item div:first-child span:nth-child(2)": {
      fontWeight: "700",
    },

    "& .tickles-prices > .tickles-prices-item > span": {
      fontSize: "0.75rem",
      marginTop: "0.313rem",
    },

    /* active selected tickle product */

    "& .selected-tickle-product > .tickles-prices-item-icon": {
      backgroundColor: "#f8cbd0",
      outlineColor: "#FF7E9D !important",
    },

    "& .input-section": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "12px",
    },

    "& .input-section > .propose-tickle-input": {
      height: "27px",
      background: "#f7f7f7 !important",
      boxShadow: "none !important",
      borderRadius: "10px",
      marginTop: "10px",
      border: "1px solid black",
      width: "50%",
      textAlign: "right",
    },

    "& .input-section > h4": {
      textAlign: "left",
      marginTop: "15px",
      marginBottom: "4px",
      marginRight: "15px",
      fontWeight: "500",
      fontSize: "14px !important",
    },

    "& i": {
      marginTop: "10px",
      marginBottom: "10px",
    },

    "& a": {
      color: "#ff2775",
      fontSize: "14px !important",
      fontWeight: "700",
      fontFamily: "Roboto",
    },
  },
  proposeDialog: {
    textAlign: "center",
    "& .MuiPaper-root": {
      borderRadius: "15px",
      top: "-30px",
    },
    "& p": {
      margin: 0,
      fontSize: "clamp(16px, 3vw, 18px)",
    },
    "& button": {
      backgroundColor: "white",
      padding: "10px",
      border: "none",
      color: "#ff2775",
      fontSize: "	1.1rem",
      fontWeight: "700",
      fontFamily: "Roboto",
    },
  },
  proposeModalButtonsContainer: {
    display: "flex",
    justifyContent: "space-around",
    "& button": {
      flex: "1",
    },
    "& button:first-child": {
      borderRight: "1px solid gray",
    },
  },
  confirmDialogContentRoot: {
    "& .confirm-tickles-section": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "22px",
    },

    "& .confirm-tickles-section > h4": {
      textAlign: "left",
      marginTop: "15px",
      marginBottom: "14px",
      marginRight: "5px",
      fontWeight: "500",
      fontSize: "2rem !important",
      fontFamily: "Roboto",
    },

    "& .confirm-tickles-section > img": {
      marginBottom: "5px",
    },
  },
}));
