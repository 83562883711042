/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { Avatar } from "@material-ui/core";
import { useRecoilState, useRecoilValue } from "recoil";
import { find as _find } from "lodash";

import "./favoritePicty.css";
import numberClickFromLocalstorageSelector from "../../stateManagement/selectors/numberClickFromLocalstorageSelector";
import selectedPictiesAtom from "../../stateManagement/atoms/selectedPictiesAtom";
import FeetModelService from "../../_services/feet-models.service";
import postPublicationSelectedPictiesAtom from "../../stateManagement/atoms/postPublicationPictiesAtom";
import getPictiesQuery from "../../stateManagement/selectors/getPictiesSelector";
import {useTranslation} from "react-i18next";

const FavoritePicty = (props) => {
  const { t } = useTranslation();
  const { isDisabled, ...rest } = props;
  const { picties } = useRecoilValue(getPictiesQuery);
  const AllValuesFromLocalStore = useRecoilValue(
    numberClickFromLocalstorageSelector
  );
  const [selectedPictiesList, setSelectedPictiesList] = useRecoilState(
    selectedPictiesAtom
  );
  const [
    selectedPictiesForPostPublication,
    setSelectedPictiesForPostPublication,
  ] = useRecoilState(postPublicationSelectedPictiesAtom);

  const [selectedFavoritePictyBloc, setSelectedFavoritePictyBloc] = useState(
    ""
  );
  const [iSNumberNotMoreThanOne, setIsNumberNotMoreThanOne] = useState(
    undefined
  );

  const [favoritePicties, setFavoritePicties] = useState(undefined);

  const AllValuesFromLocalStoreSorted = []
    .concat(AllValuesFromLocalStore)
    .sort((a, b) => (b.number > a.number ? 1 : -1));

  useLayoutEffect(() => {
    localStorage.removeItem("imgBase64Cropped");
    localStorage.removeItem("preview");

    // Little hack for correct the bug : (at the very first loading of this page the array is empty),
    // after the reload it's work fine because the array is loaded for ever ;-)
    // This little bug appen only at the firt loading page in the very first visit of Feety app, after that this bug never appen again.
    if (AllValuesFromLocalStore === undefined) {
      document.location.reload();
    }

    // Then when the array is ok =>
    const iSAllNumbers = AllValuesFromLocalStoreSorted.map(
      (resMap) => resMap.number
    );
    const iterator = iSAllNumbers.values();
    for (const value of iterator) {
      const isMoreOfOne = value > 0;
      if (isMoreOfOne === false) {
        return setIsNumberNotMoreThanOne(true);
      } else {
        return setIsNumberNotMoreThanOne(false);
      }
    }
  });

  useEffect(() => {
    if (selectedFavoritePictyBloc !== "") {
      setSelectedPictiesList((selectedPictiesList) =>
        selectedPictiesList.concat(selectedFavoritePictyBloc)
      );
    }
  }, [selectedFavoritePictyBloc, setSelectedPictiesList]);

  // fetch favorite picties here.
  useEffect(() => {
    const fetchFavoritePictiesByPublications = async () => {
      const response = await FeetModelService.getFavoritePictiesByPublications();

      setFavoritePicties(response?.data?.picties);
    };

    fetchFavoritePictiesByPublications();
  }, []);

  const DisplayPicty = () => {
    return (
      <Fragment>
        {favoritePicties && favoritePicties.length ? (
          <Fragment>
            <b>{t("pFavorites")}</b>
            <div className="postPublicationPictiesChoiceFavorites__list picty__container">
              {favoritePicties.map((picty) => (
                <div key={picty._id}>
                  <Avatar
                    onClick={() => {
                      if (isDisabled === true) {
                        setSelectedFavoritePictyBloc("");
                        return false;
                      }
                      // keep the old logic for now. It seems like it will affect multiple components since the selectedPictiesAtom is used in multiple components.

                      //TODO: need to improve or refactor the whole selected picties logic. Currently it is using the image from the asset directory and not from the url which is stored in the server.

                      const existingPublicationPicty = _find(
                        selectedPictiesForPostPublication,
                        { code: picty.code }
                      );

                      if (!existingPublicationPicty) {
                        /* selected picty for post publication */
                        setSelectedFavoritePictyBloc(
                          `${
                            AllValuesFromLocalStore.find(
                              (data) => data.code === picty.code
                            ).name
                          }`
                        );

                        // I think for now this is used for the picty icon.
                        setSelectedPictiesForPostPublication(
                          (publicationPicties) =>
                            publicationPicties.concat(
                              _find(picties, { code: picty.code })
                            )
                        );
                      }
                    }}
                    src={picty.url}
                    className={
                      selectedPictiesList.includes(
                        `${
                          AllValuesFromLocalStore.find(
                            (data) => data.code === picty.code
                          ).name
                        }`
                      )
                        ? "postPublication__favoritesAvatar selectedPicty"
                        : "postPublication__favoritesAvatar"
                    }
                  />
                  <p>{picty.label}</p>
                </div>
              ))}
            </div>
          </Fragment>
        ) : null}
      </Fragment>
    );
  };

  return (
    <div className="favoritePicty__container">
      <DisplayPicty />
    </div>
  );
};

export default FavoritePicty;
