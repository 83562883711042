import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import dateLocale from "../../../../../dateLocale";
import { getAuthSession } from "../../../../../helpers/auth.helpers";

export const MessageCustomMedia = (props) => {
  const user = getAuthSession().user;
  const { t } = useTranslation();
  return (
    <Fragment>
      <br />
      <p className="createMessage__date">
        {dateLocale.localize(props.message.createdAt).fromNow()}
      </p>
      <div className="createMessage__SentTickles">
        {user.id === props.message.from.id ? (
          <span>{t("createMessageYouVeRequestCM")}</span>
        ) : (
          <span>
            {props.message.from.username}
            {t("createMessageYouVeReceivedCM")}
          </span>
        )}
      </div>
    </Fragment>
  );
};
