import React, {useEffect, useRef, useState} from "react";
import { Avatar, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";

import "../followList.css";
import defaultAvatar from "../../../../assets/images/generic-user-avatar.png";
import FollowService from "../../../../_services/follow.service";

import setNotificationFollowerBadgeInvisibleSelector from "../../../../stateManagement/selectors/notifications/setFollowerBadgeInvisible";

import NotificationBadgeService from "../../../../_services/notification-badge.service";

import feetModelAtom from "../../../../stateManagement/atoms/feetModelAtom";

import useSessionStorage from "../../../forms/services/storageHooks/useSessionStorage";
import { useTranslation } from "react-i18next";
import DeleteFollower from "../../messageSection/createMessage/modals/deleteFollower";

const FollowersList = ({
  users,
  id,
  loadMoreUsersReference,
  setUsers,
  handleReduceFollowersCount,
  loading,
  setIsFetchingNextPage,
  isFetchingNextPage
}) => {
  let history = useHistory();
  const { t } = useTranslation();

  const myRef = useRef();

  const [authenticated] = useSessionStorage({}, "infosUser");
  const [__, setFeetModel] = useRecoilState(feetModelAtom);

  const [isAnotherUserViewing, setIsAnotherUserViewing] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const setNotificationFollowerBadgeInVisible = useSetRecoilState(
    setNotificationFollowerBadgeInvisibleSelector
  );

  useEffect(() => {
    NotificationBadgeService.deleteNotificationBadge("Follower").then(
      (response) => {
        setNotificationFollowerBadgeInVisible(true);
      }
    );
  }, [setNotificationFollowerBadgeInVisible]);

  useEffect(() => {
    if (id) {
      if (authenticated.user._id !== id) {
        setIsAnotherUserViewing(true);
      }
    }
  }, [authenticated, id]);

  /**
   * Handle removing of follower
   */
  const handleRemoveFollower = async (followData) => {
    setCurrentData(followData);
    setOpenAcceptModal(true);
  };

  const handleFollowOrUnfollow = async (data) => {
    try {
      const elementsIndex = users.findIndex((user) => user._id === data._id);

      const newUsers = [...users];

      const followed = !newUsers[elementsIndex].followed;

      newUsers[elementsIndex] = { ...newUsers[elementsIndex], followed };

      setUsers([...newUsers]);

      if (followed) {
        // follow
        await FollowService.followUser({
          followedUser: data._id,
        });
      } else {
        // un-follow
        await FollowService.remove(data._id);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleScroll = () => {
    const bottom =
        myRef.current?.scrollHeight -
        myRef.current?.scrollTop ===
        myRef.current?.clientHeight;
    console.log(`is bottom now: ${bottom}`)
    if (bottom) {
      console.log("Followers Reached bottom");
      setIsFetchingNextPage(true)
    }
  }

  return (
    <div className="followList__container">
      <div
          className="followList__content"
          ref={myRef}
          onScroll={handleScroll}
          style={{
            height: '700px',
            overflow: 'scroll',
          }}
      >
        {loading ? (
          <div
            style={{
              position: "absolute",
              left: "calc(50% - 20px)",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            {users && users.length
              ? users.map((follower) => (
                  <div key={follower._id} className="follow-user-list">
                    <div
                      className="user_thumbnail_details"
                      onClick={() => {
                        if (follower.userType === "Lover" && !follower.suppress && !follower.accountSoftDeleted) {
                          history.push({
                            pathname: `/visit-feet-lover/${follower._id}`,
                          });
                        } else if (!follower.suppress && !follower.accountSoftDeleted) {
                          setFeetModel(follower);
                          history.push({
                            pathname: "/feet-models-view",
                            search: `?user=${follower._id}`,
                          });
                        }
                      }}
                    >
                      <div className="avatar">
                        {!follower.suppress && !follower.accountSoftDeleted ? <Avatar
                            src={follower && follower.image ? follower.image.url : defaultAvatar}
                            alt="models"
                        /> : <Avatar style={{backgroundColor: "gray"}}>D</Avatar>}
                      </div>
                      <div className="user_details">
                        <h2
                          style={
                            follower.subscriber && !follower.suppress && !follower.accountSoftDeleted
                              ? { color: "#ff2775", fontWeight: "bold" }
                              : follower.suppress || follower.accountSoftDeleted ? { textDecorationLine: 'line-through', backgroundColor: "gray" } : {}
                          }
                        >
                          {follower && follower.username}
                        </h2>
                      </div>
                    </div>
                    <div className="followList__buttonContainer">
                      {!isAnotherUserViewing && !follower.suppress && !follower.accountSoftDeleted ? (
                        <RemoveFollowerButton
                          handleRemoveFollower={handleRemoveFollower}
                          followData={follower}
                        />
                      ) : authenticated.user._id !== follower._id && !follower.suppress && !follower.accountSoftDeleted ? (
                        <Button
                          className={`normalize-text ${
                            follower.followed
                              ? "followList__Button default__button"
                              : "followList__Button pink__button"
                          }`}
                          onClick={() => {
                            handleFollowOrUnfollow(follower);
                          }}
                        >
                          {follower.followed
                            ? t("unfollowKebabMenuLover")
                            : t("followButtonFeetModelView")}
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))
              : ""}
          </>
        )}
      </div>
      <div ref={loadMoreUsersReference}>
        {isFetchingNextPage ? (
            <CircularProgress color="secondary" size={10} />
        ) : (
            ""
        )}
      </div>

      <DeleteFollower
        openModal={openAcceptModal}
        setOpenModal={setOpenAcceptModal}
        data={currentData}
        handleReduceFollowersCount={handleReduceFollowersCount}
        setUsers={setUsers}
      />
    </div>
  );
};

const RemoveFollowerButton = ({ handleRemoveFollower, followData }) => {
  const { t } = useTranslation();
  return (
    <Button
      className="followList__Button pink__button"
      onClick={() => {
        handleRemoveFollower(followData);
      }}
    >
      {t("Remove")}
    </Button>
  );
};

export default FollowersList;
