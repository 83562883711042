import "../styles.css";

import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import React from "react";
import { useTranslation } from "react-i18next";

function TermsOfUseModal(props) {
  const { t } = useTranslation();
  const {
    openTermsOfUseModal,
    setOpenTermsOfUseModal,
    handleAcceptTermsOfUse,
  } = props;

  return (
    <Dialog
      // className={classes.dialogRoot}
      open={openTermsOfUseModal}
      onClose={() => setOpenTermsOfUseModal(false)}
      className="accept-terms-dialog"
    >
      <DialogTitle
        id="customized-dialog-title"

        //   classes={classes}
      >
        {t("acceptTerms")}
      </DialogTitle>
      <DialogContent /* className={classes.dialogContentRoot} */>
        <Container>{t("acceptTermsContent")}</Container>
      </DialogContent>
      <DialogActions /* className={classes.dialogActionsRoot} */>
        <Button
          onClick={() => handleAcceptTermsOfUse()}
          className="accept-terms-actions"
        >
          {t("acceptsAction")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TermsOfUseModal;
