import { Button } from "@material-ui/core";
import React from "react";
import { getAuthSession } from "../../helpers/auth.helpers";
import LikeService from "../../_services/like.service";
import heart from "../../assets/images/menu/heart.svg";
import likedHeart from "../../assets/images/notifications-models/likes.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export const PublicationLike = (props) => {
  const history = useHistory();
  const { publication, setPublication } = props;

  const { t } = useTranslation();

  const handleLikeOrUnlikePublication = () => {
    // DELETE unlike publication endpoint
    if (
      publication.isLiked
    ) {
      LikeService.unLikePublication({
        publication: publication._id,
      })
        .then((result) => result)
        .catch((error) => error);

      setPublication((item) => ({
        ...item,
        likesCount: item.likesCount - 1,
        heart,
        isLiked: false
        // likes: item.likes.filter(
        //   (data) => data.user !== getAuthSession().user._id
        // ),
      }));
    } else {
      // POST like publication endpoint
      LikeService.likePublication({
        publication: publication._id,
      })
        .then((result) => result)
        .catch((error) => error);

      setPublication((item) => ({
        ...item,
        likesCount: item.likesCount + 1,
        heart: likedHeart,
        isLiked: true
        // likes: [...item.likes, { user: getAuthSession().user._id }],
      }));
    }
  };

  const handleDisplayLikersList = (publication) => {
    history.push({
      pathname: `/publication/${publication._id}/likers`,
    });
  };

  return (
    <div>
      <Button>
        <img
          className={
            publication.isLiked ? "fullHeart" : "emptyHeart"
          }
          key={publication.heart}
          src={publication.heart}
          alt="heart"
          onClick={() => handleLikeOrUnlikePublication()}
        />
        <sub onClick={() => handleDisplayLikersList(publication)}>
          {" "}
          {publication.likesCount} {t("likesHomePageLover")}{" "}
        </sub>
      </Button>
    </div>
  );
};
