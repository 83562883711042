import React, { useEffect, useState } from "react";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { Button, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";

import "./buyTicklesCredit.css";
import "react-toastify/dist/ReactToastify.css";

import TicklesImg from "../../../assets/images/tickles/Intersection 3@2x.png";

import TicklePricesService from "../../../_services/tickle-prices.service";
import useSessionStorage from "../../../components/forms/services/storageHooks/useSessionStorage";

const BuyTicklesCredit = () => {
  let history = useHistory();
  const { t } = useTranslation();

  const [authenticated] = useSessionStorage({}, "infosUser");

  const [selectedTicklePrice, setSelectedTicklePrice] = useState({});
  const [ticklePrices, setTicklePrices] = useState([]);

  useEffect(() => {
    const fetchTicklePrices = async () => {
      const response = await TicklePricesService.getTicklePrices();

      setTicklePrices(response.prices);
    };

    fetchTicklePrices();
  }, []);

  const handleSubmitWithOffert = () => {
    toast.dark(t("buyTicklesCreditClickedOffert"), {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div className="buyTickleContainer">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{top: "5em"}}
      />
      <div className="buyTicklesCredit__header header page_fixed_header">
        <IconButton className="back__button" onClick={() => history.goBack()}>
          <ChevronLeft />
        </IconButton>
        <h4>{t("buyTicklesTitle")}</h4>
      </div>
      <div className="buyTicklesCredit__content content__withHeader minmax__wrapper">
        <div className="buyTicklesCredit__top">
          <p>{t("buyTicklesTitle")}</p>
          <img
            src={TicklesImg}
            alt="tickles"
            className={"buyTicklesCreditTop__TicklesImg"}
          />
          <b>
            {t("buyTicklesYouHave")} {authenticated.user.tickles}{" "}
            {t("buyTicklesTickles")}
          </b>
        </div>
        <div className="buyTicklesCredit__cards">
          {ticklePrices && ticklePrices.length
            ? ticklePrices.map((ticklePrice) => (
                <IconButton
                  key={ticklePrice._id}
                  style={{ color: "inherit" }}
                  onClick={() => {
                    setSelectedTicklePrice(ticklePrice);
                  }}
                >
                  <div
                    className={
                      ticklePrice._id === selectedTicklePrice._id
                        ? "buyTicklesCredit__card activeTickles"
                        : "buyTicklesCredit__card"
                    }
                  >
                    <h4>{ticklePrice.tickles}</h4>
                    <p>{t("buyTicklesTickles")}</p>
                    <b>{ticklePrice.amount}€</b>
                  </div>
                </IconButton>
              ))
            : ""}
        </div>

        <div className="buyTicklesCredit__buttons">
          <Button
            variant="contained"
            className="pink__button"
            onClick={() => {
              if (selectedTicklePrice && selectedTicklePrice._id) {
                history.push({
                  pathname: "/tickles-payment",
                  state: { selectedTicklePrice },
                });
              } else {
                handleSubmitWithOffert();
              }
            }}
          >
            {t("buyTicklesIDo")}
          </Button>
          <Button variant="contained" className="white__button">
            {t("buyTicklesNo")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BuyTicklesCredit;
