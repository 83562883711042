import LikesIcon from "../../../assets/images/notifications-models/likes.png";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {StyledBadge} from "./NotificationsModels";
import useNotificationsModels from "./hooks/useNotificationsModels";
import {useRecoilState, useRecoilValue} from "recoil";
import { uniqBy as _uniqBy } from "lodash";
import likeBadgeAtom from "../../../stateManagement/atoms/notifications/badge/like";
import likesNotificationsQuery from "../../../stateManagement/selectors/likesNotificationsSelector";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import likesNotificationsAtom from "../../../stateManagement/atoms/notifications/likes";
import useInfiniteQuery from "../../../hooks/useInfiniteQuery";
import LikeService from "../../../_services/like.service";
import {useTranslation} from "react-i18next";
import NotificationService from "../../../_services/notification.service";
import {SocketContext} from "../../../context/socket";

const NotificationLikes = ({subscribers}) => {
    const socket = useContext(SocketContext);
    const [isInvisibleLikeBadge, setInvisibleLikeBadge] = useRecoilState(likeBadgeAtom);
    const [allLikes, setAllLikes] = useState([]);
    const [count, setCount] = useState(0);
    const [likesNotifications] = useRecoilState(likesNotificationsAtom);
    const { t } = useTranslation();

    const {
        data,
        error,
        status,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery("notification-likes", async ({ next_offset = 0 }) => {
        const response = count > 0 ? await LikeService.getLikesV2(next_offset) : null;

        return {
            data: response.data.likes,
            next_offset: response.data.next_offset,
        };
    }, count);

    const newLikeHandler = useCallback(
        (newLike) => {
            setCount(count+1)
        },
        [data]
    );
    useEffect(() => {
        // observer > new message
        const message = socket.onLike()
        const onLike = message.subscribe(newLikeHandler);

        // clean up observer
        return () => onLike.unsubscribe();
    }, [socket, newLikeHandler]);

    const {
        clickedLikes,
        clickedComments,
        clickedTickles,
        noSubscriptionsYet,
        setNoSubscriptionsYet,
        clickedSubscriptions,
        NoNotificationsYet,
        LikesNotifications,
        CommentsNotifications,
        TicklesNotifications,
        SubscriptionsNotifications,
        deployComments,
        handleDeployComments,
        deployTickles,
        handleDeployTickles,
        deployLikes,
        handleDeployLikes,
        deploySubscriptions,
        handleDeploySubscriptions,
    } = useNotificationsModels();

    const loadMoreLikesReference = useRef();

    useEffect(() => {
        setAllLikes(() =>
            _uniqBy(
                [...likesNotifications, ...data].sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                ),
                "_id"
            )
        );
    }, [data]);

    // likes notifications
    useEffect(() => {
        if (likesNotifications) {
            /**
             *  - merge remote data likes and global state likes
             *  - make sure likes _id is unique by using _uniqBy function from lodash
             *  - perform sort using createdAt date
             */
            setAllLikes(() =>
                _uniqBy(
                    [...likesNotifications, ...data].sort(
                        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    ),
                    "_id"
                )
            );
        }

        return () => {};
    }, [likesNotifications, data]);



    useIntersectionObserver({
        target: loadMoreLikesReference,
        onIntersect: fetchNextPage,
        enabled: hasNextPage,
    });
    return (
        <div className="notificationsModelsContentSection__likes">
        <div
            className={
                deployLikes === false
                    ? "notificationsModels__likesList hidden-body"
                    : "notificationsModels__likesList"
            }
            onClick={
                deployLikes === true ? handleDeployLikes : 
                async () => {
                    handleDeployLikes()
                    await NotificationService.readAllNotificationsWithType("Likes")
                    setInvisibleLikeBadge(true)
                    setCount(count+1)
                    // handleCheckReadAllNotifications();
                }
            }
        >
            <div className="notificationsModelsSection__head">
                <div className="notif-title">
                    <StyledBadge
                        color="secondary"
                        variant="dot"
                        invisible={isInvisibleLikeBadge}
                        overlap={"rectangular"}
                    >
                        <img
                            src={LikesIcon}
                            alt=""
                            className="notificationsModels__Icon"
                        />
                    </StyledBadge>
                    <b>{t("likesFeetModelView")}</b>
                </div>
                {deployLikes === true ? (
                    <KeyboardArrowUpIcon
                        className="likesNotificationsTopChevron"
                    />
                ) : (
                    <KeyboardArrowDownIcon
                        className="likesNotificationsTopChevron"
                    />
                )}
            </div>
            <hr className="hr-sep-notifications" />
            <div className="notification-body">
                {allLikes.length <= 0 ? (
                    <NoNotificationsYet />
                ) : (
                    <LikesNotifications
                        subscribers={subscribers}
                        likes={allLikes}
                        loadMoreLikesReference={loadMoreLikesReference}
                        likesNotifications={likesNotifications}
                        isInvisibleLikeBadge={isInvisibleLikeBadge}
                    />
                )}
            </div>
        </div>
    </div>)
}

export default NotificationLikes
