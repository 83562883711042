import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const getPicties = async () => {
  // const headers = {
  //   Authorization: `Bearer ${getAuthSession().user.access_token}`,
  // };

  const response = await axiosInstance.get("/v1/picties");

  return response;
};

const getPicty = async (id) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/picties/${id}`, {
    headers,
  });

  return response;
};

const getPictyWithoutAuthentication = async (id) => {
  const response = await axiosInstance.get(`/v1/picties/picty/${id}`);

  return response;
};

const getPictyByCategory = async (category) => {
  try {
    const response = await axiosInstance.get(
      `/v1/picties/category/${category}`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

const PictyService = {
  getPicties,
  getPicty,
  getPictyByCategory,
  getPictyWithoutAuthentication,
};

export default PictyService;
