import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

export const getPublicationComments = async ({ id }) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const { data } = await axiosInstance.get(`/v1/publications/${id}/comments`, {
    headers,
  });
  return data;
};

export const saveComment = async (body) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const { data } = await axiosInstance.post(`/v1/comments`, body, { headers });
  return data;
};

export const getNotificationComments = async (offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
    `/v1/notifications/comments?offset=${offset}`,
    {
      headers,
    }
  );

  return response;
};
export const getNotificationCommentsV2 = async (offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
      `/v2/notifications/comments?offset=${offset}`,
      {
        headers,
      }
  );

  return response;
};

export const countUnreadNotificationComments = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
      `/v2/notifications/comments/count-unread`,
      {
        headers,
      }
  );

  return response;
};

export const deleteComment = async (commentId) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const response = await axiosInstance.delete(`/v1/comments/${commentId}`, {
      headers,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
