import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const followUser = async (data) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.post("/v1/follows", data, { headers });

  return response.data;
};

const unfollow = async (id) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.delete(`/v1/follows/${id}`, {
    headers,
  });

  return response.data;
};

const remove = async (id) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.delete(`/v1/follows/${id}`, {
    headers,
  });

  return response.data;
};

const getFollowers = async (id) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/follows/followers`, {
    headers,
  });

  return response.data;
};

const checkFollower = async (id) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/follows/feet-model/${id}`, {
    headers,
  });

  return response.data;
};

const FollowService = {
  followUser,
  unfollow,
  remove,
  getFollowers,
  checkFollower,
};

export default FollowService;
