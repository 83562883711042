import React from "react";
import Snackbar from "@material-ui/core/Snackbar";

import Alert from "../Alert";
import useNotificationAlert from "../../hooks/useNotificationAlert";

function AlertNotification() {
  const { message, severity, removeNotificationMessage } =
    useNotificationAlert();

  const handleCloseNotificationAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    removeNotificationMessage(null);
  };

  return (
    <Snackbar
      open={message}
      autoHideDuration={6000}
      onClose={handleCloseNotificationAlert}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={handleCloseNotificationAlert} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default AlertNotification;
