/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { useRecoilState } from "recoil";

import "./privacyPolicy.css";
import {
  onSettingModelsAtom,
  studioAtom,
} from "../../stateManagement/atoms/studioAtom";

const PrivacyPolicy = (props) => {
  let history = useHistory();

  const [onSettingModels, setOnSettingModels] = useRecoilState(
    onSettingModelsAtom
  );
  const [onStudio, setOnStudio] = useRecoilState(studioAtom);

  useEffect(() => {
    if (onStudio === false) {
      setOnStudio(true);
    }
  }, [onStudio, setOnStudio]);

  return (
    <div className="privacyPolicy__container">
      <div className="privacypolicy__header header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() =>
            onSettingModels === true
              ? history.push("/setting-models")
              : history.push("/settings-lovers")
          }
        >
          <ChevronLeft />
        </IconButton>
        <h4>{t("privacyPolicy")}</h4>
      </div>
      <div className="privacyPolicy__content minmax__wrapper content__withHeader">
        <h3>Editeur</h3>
        <span>LMCF SAS</span>
        <span>Siège social : 2 rue Beaussier Toulon - France</span>
        <span>Capital social : 1 200 €</span>
        <span>RCS : 845 132 596</span>
        <span>Contact : contact@feety.app</span>
        <span>Hébergeur : GOOGLE CLOUD 8 RUE DE LONDRES 75009 PARIS</span>

        <h3>Mentions légales</h3>

        <p className="privacyPolicyContent__parag1">
          Le présent document constitue les conditions générales d’utilisation
          du site accessible sous l’adresse « https://feety.app » (ci-après
          dénommé le « Site »). En accédant ou en utilisant ce Site, vous êtes
          réputé accepter ces conditions générales et nous nous réservons le
          droit de refuser l’accès à tout ou partie du Site à tout utilisateur
          qui ne les respecterait pas. Vous vous engagez par ailleurs à ne pas
          adresser ou transmettre des textes ou images contraires à la loi, ou
          susceptibles de choquer la sensibilité, notamment par un contenu
          haineux, pornographique, ou incitatif à des comportements du même
          ordre. FEETY se réserve le droit de prendre toute mesure, ou d’engager
          toute action qu’elle estime nécessaire au cas où son Site serait
          utilisé pour diffuser des éléments de cette nature. Nous vous
          informons que le Site est soumis au droit français, aux juridictions
          françaises et qu’il a pour langue officielle le français.
        </p>

        <h3>Propriété intellectuelle</h3>

        <p className="privacyPolicyContent__parag1">
          Le Site et chacun des éléments qui le composent (les marques,
          graphismes, photographies,...) relèvent de la législation française et
          internationale notamment celle relative au droit d’auteur, aux bases
          de données et à la propriété intellectuelle, en particulier, la marque
          Feety.app et son logo. Toutes les autres marques citées sont la
          propriété de leurs titulaires respectifs. Toute reproduction,
          représentation, publication, transmission, ou plus généralement toute
          exploitation non autorisée du Site et/ou de ses éléments engage votre
          responsabilité et est susceptible d’entraîner des poursuites
          judiciaires, notamment pour contrefaçon.
        </p>

        <h3>Responsabilité</h3>

        <p className="privacyPolicyContent__parag1">
          Le Site peut contenir des liens vers d’autres sites que nous
          n’exploitons pas. Nous ne pouvons en aucune manière être tenus
          responsables de la mise à disposition de ces liens permettant l’accès
          à ces sites et sources externes, et ne pouvons supporter aucune
          responsabilité quant au contenu, publicités, produits, services ou
          tout autre matériel disponible sur ou à partir de ces sites ou sources
          externes qui ne sont ni vérifiées ni approuvées par nos équipes. Nous
          nous engageons à assurer nos meilleurs efforts pour offrir des
          informations actualisées et exactes. Cependant, nous ne saurions être
          tenus pour responsables d’erreurs, d’omissions ou des résultats qui
          pourraient être obtenus par un mauvais usage de ces informations. Nous
          nous réservons le droit de les corriger, dès que ces erreurs sont
          portées à notre connaissance et, plus généralement, de modifier, à
          tout moment, sans préavis, tout ou partie du Site ainsi que ses
          conditions d’utilisation, sans que notre responsabilité puisse être
          engagée de ce fait. Le téléchargement de tous matériels lors de
          l’utilisation du service sera à vos risques et périls. Vous serez seul
          responsable pour tout dommage subi par votre ordinateur ou toutes
          pertes de données consécutives à ce téléchargement. Plus généralement,
          Feety.app ne pourra en aucun cas être responsable en cas de dommages
          directs et/ou indirects résultant de l’utilisation de ce Site.
        </p>

        <h3>Disponibilité du site Web</h3>

        <p className="privacyPolicyContent__parag1">
          Il est techniquement impossible de fournir le Site exempt de tout
          défaut et ces défauts peuvent conduire à l’indisponibilité temporaire
          du Site ; le fonctionnement du Site peut être affecté par des
          événements et/ou des éléments que nous ne contrôlons pas, tels que par
          exemple, des moyens de transmission et de communication entre vous et
          nous et entre nous et d’autres réseaux ; nous et/ou nos fournisseurs
          pourrons, à tout moment, modifier ou interrompre temporairement ou de
          façon permanente tout ou partie du Site pour effectuer des opérations
          de maintenance et/ou effectuer des améliorations et/ou des
          modifications sur le Site. Nous vous informerons, si possible, de
          chaque modification / interruption des services disponibles sur le
          Site. Nous ne sommes pas responsables de toute modification,
          suspension ou interruption du Site.
        </p>

        <h3>Politique de confidentialité</h3>

        <p className="privacyPolicyContent__parag1">
          FEETY collecte vos données lors de la création de votre compte. Vous
          pouvez consulter notre F.A.Q. pour plus d'informations au sujet de
          votre compte.
        </p>

        <span>
          1. L'ensemble des contenus FEETY sont gérés et stockés par des
          partenaires tierces afin de garantir toute légitimité et légalité.
        </span>

        <span>
          2. Les informations bancaires sont gérées par nos partenaires
          externes.
        </span>

        <span>
          3. Les données des utilisateurs FEETY ne sont pas communiquées à des
          partenaires externes. Les utilisateurs sont soit créateurs de contenus
          (Feetgirl), soit admirateurs (Feetlover). Les Feetgirl postent du
          contenu pour leurs Feetlover qui payent un abonnement pour les suivre.
          Les Feetlover peuvent demander du contenu privé et personnalisé à
          toutes les Feetgirl (celles qu'ils suivent et celles qui ne suivent
          pas). Ces dernières peuvent décider d'accepter ou de refuser les
          demandes. En cas d'acceptation, elles fixent un prix que les Feetlover
          acceptent ou refusent s'ils l'estiment trop élevé.
        </span>

        <h3>Informations collectées</h3>

        <span>Les informations que vous choisissez de nous communiquer.</span>
        <span>
          Les informations que FEETY exige pour profiter de ses services.
        </span>
        <span>
          Les informations potentielles recueillies grâce aux partenaires
          externes.
        </span>

        <h5>Informations que les "Feetgirl" communiquent</h5>
        <ol>
          <li>Nom Prénom</li>
          <li>Adresse complète</li>
          <li>Biographie</li>
          <li>Téléphone mobile</li>
          <li>Email</li>
          <li>Pseudonyme</li>
          <li> Date de naissance</li>
          <li>Copie de passeport</li>
          <li>Copie de la carte d’identité nationale</li>
          <li>Email PayPal</li>
        </ol>

        <h5>Informations que les "Feetlover" communiquent :</h5>
        <ol>
          <li>Nom Prénom</li>
          <li>Adresse complète</li>
          <li>Téléphone mobile</li>
          <li>Email</li>
          <li>Pseudonyme</li>
          <li>Date de naissance</li>
          <li>Copie de passeport</li>
          <li>Copie de la carte d’identité nationale</li>
        </ol>

        <h3>Informations obtenues quand le service est utilisé</h3>

        <p className="privacyPolicyContent__parag1">
          Quand vous utilisez le service FEETY, nous collectons des informations
          à propos des contenus que vous publiez, partagez ou consultez.
        </p>
        <p className="privacyPolicyContent__parag1">Par exemple :</p>
        <p className="privacyPolicyContent__parag1">
          FEETY s'accorde le droit de pouvoir consulter les contenus partagés
          afin de vérifier qu'ils sont conformes à la demande. Aussi, que ces
          contenus n'enfreignent pas la loi.
        </p>

        <p className="privacyPolicyContent__parag1">
          Données de navigation : FEETY collecte les adresses IP et autres
          données d'identification, ainsi que le type d'appareil que vous
          utilisez pour consulter les services de FEETY. Données collectées par
          cookies : les cookies FEETY utilisés sont des cookies de session et
          d'analytique Google, ainsi que des cookies pour d'enregistrement de
          vos préférences de navigation, comme la langue utilisée.
        </p>
        <p className="privacyPolicyContent__parag1">
          Données d'identification : nous collectons les données
          d'identification quand vous utilisez les services de FEETY.
        </p>

        <p className="privacyPolicyContent__parag1">
          Ces données peuvent être :
        </p>
        <ol>
          <li>
            Le mode d'accès des services FEETY (web, Facebook Connect, Instagtam
            Connect)
          </li>
          <li>Les informations sur le navigateur ou la langue utilisée</li>
          <li>La date et heure de navigation</li>
          <li>Les pages consultées</li>
          <li>La ou les adresses IP</li>
        </ol>

        <h3>Comment nous utilisons les données collectées</h3>

        <span>Nous collectons l'ensemble de ces données pour :</span>
        <span>Garantir la meilleure expérience de navigation possible</span>
        <span>
          Communiquer facilement avec les utilisateurs des services FEETY
        </span>
        <span>
          Analyser les comportements des utilisateurs pour mieux comprendre
          leurs attentes
        </span>
        <span>
          Vérifier les identités des utilisateurs et se conformer aux exigences
          légales
        </span>
        <span>
          Imposer nos conditions d'utilisation et notre politique de
          confidentialité
        </span>

        <h3>Comment nous partageons l'information</h3>

        <span>Avec les abonnés :</span>
        <span>
          Les informations qui constituent votre profil (pseudo, biographie,
          photo de profil)
        </span>
        <span>
          Les informations additionnelles que vous avez consenti à partager
        </span>
        <span>
          Les contenus postés en privé par les Feetgirl, ainsi que les contenus
          photos ou vidéos que
        </span>
        <span>partagés avec les Feetlover</span>
        <span>Avec tout le monde :</span>
        <span>
          Les informations postées publiquement par les créateurs de contenus
        </span>

        <h3>Combien de temps FEETY conserve les contenus</h3>
        <span>
          Les contenus peuvent être supprimés sur simple demande :
          support@feety.app.
        </span>
        <span>
          Si vous supprimez votre compte, vos données seront supprimées au bout
          de six mois.
        </span>

        <h3>Déclaration 18 USC 2257</h3>

        <p className="privacyPolicyContent__parag1">
          FEETY n'est pas producteur (à tout niveau) du contenu disponible sur
          le site web feety.app. En ce qui concerne la loi Américaine 18 USC
          2257, pour tout contenu disponible sur ce site, veuillez adresser
          votre demande au créateur qui a publié le contenu.
        </p>

        <p className="privacyPolicyContent__parag1">
          FEETY est un réseau social qui permet le partage et la visualisation
          de divers types de contenus (vidéos ou photos). Même si FEETY fait de
          son mieux pour vérifier la conformité de l'ensemble des médias
          hébergés, il se peut que ces vérifications ne soient pas fiables à
          100%.
        </p>

        <p className="privacyPolicyContent__parag1">
          FEETY respecte les procédures suivantes pour assurer la conformité :
        </p>
        <span>
          - Exiger que tous les utilisateurs "créateurs" soient âgés de 18 ans
          ou plus pour s'inscrire et ajouter des vidéos et des photos.Si vous
          supprimez votre compte, vos données seront supprimées au bout de six
          mois.
        </span>
        <span>
          - Pour obtenir de l'aide et / ou des informations sur la recherche du
          créateur à l'origine d'un contenu, veuillez contacter notre support à
          contact@feety.app.
        </span>

        <p className="privacyPolicyContent__parag1">
          FEETY permet à ses internautes de signaler le contenu comme
          inapproprié. Si un contenu est signalé comme étant illégal, harcelant,
          préjudiciable, offensant ou pour toute autre raison, FEETY le supprime
          sans délai de sa plateforme.
        </p>

        <p className="privacyPolicyContent__parag1">
          Les utilisateurs de FEETY qui rencontrent ce type de contenu sont
          invités à le signaler comme inapproprié en cliquant sur le lien
          'Signaler ce média' situé sous chaque vidéo ou photo.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
