import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Button, IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { ToastContainer } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./viewFeetModelsProfile.css";
import menu from "../../../assets/images/view-feet-models-profile/menu.svg";
import feetModelProfileQuery from "../../../stateManagement/selectors/feetModelProfileSelector";
import genericUserIcon from "../../../assets/images/generic-user-avatar.png";
import TabChoiceModelProfile from "./TabChoiceModelProfile";
import FollowService from "../../../_services/follow.service";
import BlockService from "../../../_services/block.service";
import ViewService from "../../../_services/view.service";
import KebabMenuOptionsLovers from "../../loversComponents/KebabMenuOptionsLovers/KebabMenuOptionsLovers";

import useSessionStorage from "../../forms/services/storageHooks/useSessionStorage";

import ContentNotAvailable from "../../ContentNotAvailable";
import HistoryService from "../../../_services/history.service";
import certifiedIcon from "../../../assets/images__v3/Certification_solid_ribbon.png";
import UserViewModal from "../../Modals/UserModals/UserViewModal";
import starIcon from "../../../assets/images/view-feet-models-profile/picto/Calque_1.svg";

const ViewFeetModelsProfile = () => {
  let history = useHistory();
  const { t } = useTranslation();

  const [authenticated] = useSessionStorage({}, "infosUser");

  const [toggleAccordion, setToggleAccordion] = React.useState(true);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [isFollower, setIsFollower] = React.useState({});
  const [isSubscriber, setIsSubscriber] = React.useState(true);
  const [isBlockedOrBlocker, setIsBlockedOrBlocker] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isSubscriptionSuspended, setIsSubscriptionSuspended] = React.useState(false);
  const [isBlockedSubscription, setIsBlockedSubscription] = React.useState(false);

  const [open, setOpenModal] = useState(false);
  const [userType, setUserType] = useState({});
  const [roleType, setRoleType] = useState(false);

  const feetModelProfile = useRecoilValue(feetModelProfileQuery);

  const HandleToggleDescription = () => {
    if (toggleAccordion === true) {
      setToggleAccordion(false);
    }
    if (toggleAccordion === false) {
      setToggleAccordion(true);
    }
  };

  useEffect(() => {
    if (
      feetModelProfile &&
      feetModelProfile?.profile &&
      feetModelProfile?.profile?._id
    ) {
      setLoading(true);
      setUserType(feetModelProfile?.profile?.userType);
      setIsFollower(feetModelProfile?.profile?.isFollower)
      setIsSubscriber(feetModelProfile?.profile?.isSubscriber === true && feetModelProfile?.profile?.isSubscriber !== 0)
      setIsSubscriptionSuspended(feetModelProfile?.profile?.isSuspended === true && feetModelProfile?.profile?.isSuspended !== 0)
      setIsBlockedSubscription(feetModelProfile?.profile?.acceptSubscribers)
      setRoleType(feetModelProfile?.profile?.roleType && feetModelProfile?.profile?.roleType.includes("blogger"))
      // async function checkIfAuthenticatedUserIsAFollower() {
      //   const response = await FollowService.checkFollower(
      //     feetModelProfile?.profile?._id
      //   );
      //
      //   if (response && response.follower) {
      //     setIsFollower(response.follower);
      //   }
      // }

      // async function checkIfAuthenticatedUserIsASubscriber() {
      //   const response = await FeetLoverService.checkIfSubscribed(
      //     feetModelProfile?.profile?._id
      //   );
      //
      //   if (response && response.data && response.data.subscriber) {
      //     setIsSubscriber(true);
      //
      //     if(response.data.subscriber.suspendedAt) {
      //       setIsSubscriptionSuspended(true);
      //     } else {
      //       setIsSubscriptionSuspended(false);
      //     }
      //   } else {
      //     setIsSubscriber(false);
      //     setIsSubscriptionSuspended(false);
      //   }
      // }

      async function checkIfBlockedOrBlocker() {
        const response = await BlockService.findBlockedOrBlocker(
          feetModelProfile?.profile?._id
        );

        setLoading(false);
        if (response && response.block) {
          setIsBlockedOrBlocker(true);
        } else {
          setIsBlockedOrBlocker(false);
        }
      }

      // checkIfAuthenticatedUserIsAFollower();
      // checkIfAuthenticatedUserIsASubscriber();
      checkIfBlockedOrBlocker();

      //trigger viewing of user endpoint.
      ViewService.viewUser(feetModelProfile?.profile?._id);

      const query = new URLSearchParams(history.location?.search);

      if (query && query.get("fromSearchPage")) {
        // trigger search history api endpoint
        HistoryService.postSearchHistory({
          searchedUser: feetModelProfile?.profile?._id,
        });
      }
    }
  }, [feetModelProfile, history]);

  const handleFollowOrUnfollow = async (data) => {
    try {
      if (data && data._id) {
        // un-follow
        await FollowService.remove(isFollower.followedUser);
        setIsFollower({});
      } else {
        // follow
        const response = await FollowService.followUser({
          followedUser: feetModelProfile?.profile?._id,
        });
        setIsFollower(response.follow);
      }
    } catch (error) {
      console.error("ERROR POST /api/v1/follows/:id", error);
    }
  };

  return (
    <div className="viewFeetModelsProfiles__container">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{top: "5em"}}
      />
      <UserViewModal
        open={open}
        handleClose={() => setOpenModal((open) => !open)}
        feetModelProfile={feetModelProfile}
        userType={userType}
        setUserType={setUserType}
      />
      <div className="viewFeetModelsProfiles__top header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => {
            history.goBack();
          }}
        >
          <ChevronLeft />
        </IconButton>
        <h4>{feetModelProfile && feetModelProfile?.profile?.username}</h4>

        {isBlockedOrBlocker ? (
          ""
        ) : (
          <IconButton
            className="viewFeetModelsProfiles__IconButton header__rightButtton"
            onClick={() => setOpenProfile(true)}
          >
            <img src={menu} alt="menu" className="viewPodelsProfileTop__img" />
          </IconButton>
        )}

        <KebabMenuOptionsLovers
          user={feetModelProfile?.profile}
          open={openProfile}
          setOpen={setOpenProfile}
          isFollower={isFollower}
          setFollower={setIsFollower}
          showReportButtonForUser={true}
        />
      </div>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "400px",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : isBlockedOrBlocker ? (
        <ContentNotAvailable />
      ) : (
        <div className="viewFeetModelsProfiles__content content__withHeader">
          <div className="viewModelProfilesContent__top">
            <div className="viewFeetModelsProfilesContentTop__bloc">
              <div className="ViewOwnProfileModelsTop__picture">
                <img
                  src={
                    feetModelProfile &&
                    feetModelProfile?.profile &&
                    feetModelProfile?.profile?.image
                      ? feetModelProfile?.profile?.image?.url
                      : genericUserIcon
                  }
                  alt="icon"
                />
                <div className="ViewOwnProfileModelsTop__text">
                  {feetModelProfile?.profile?.ambassadress === true ? (
                    <span className="ViewOwnProfileContentBloc__text">
                      <h5 style={{ color: "#ff5768" }}>
                        {t("AmbassadressLabel")}
                      </h5>
                    </span>
                  ) : null}
                  <div className="profile__username">
                    <span className="ViewOwnProfileContentBloc__text">
                      <h4>
                        {feetModelProfile?.profile?.username ||
                          (feetModelProfile?.profile?.facebook &&
                            feetModelProfile?.profile?.facebook?.displayName)}
                      </h4>
                      {feetModelProfile?.profile?.certified ? (
                        <img
                          src={certifiedIcon}
                          alt="tickles"
                          width="25"
                          height="25"
                        />
                      ) : (
                        ""
                      )}
                    </span>
                    {toggleAccordion === false ? (
                      <KeyboardArrowDownIcon
                        onClick={HandleToggleDescription}
                        className="ViewOwnProfileModels__chevron"
                      />
                    ) : (
                      <KeyboardArrowUpIcon
                        onClick={HandleToggleDescription}
                        className="ViewOwnProfileModels__chevron"
                      />
                    )}
                  </div>
                  <span style={{ fontWeight: "bold" }}>
                    {feetModelProfile?.profile?.title}
                  </span>
                  <div
                    className={
                      toggleAccordion === false
                        ? "ViewOwnProfileContentBloc__description"
                        : "ViewOwnProfileContentBloc__description diplaying"
                    }
                  >
                    <p className="ViewOwnProfileContentBlocDescription__text">
                      <span>{feetModelProfile?.profile?.description}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="ViewOwnProfileModels__bloc">
                <div className="profile_counts">
                  <div
                    onClick={() => {
                      // history.push({
                      //   pathname: `/likes/${feetModelProfile?.profile?._id}`,
                      // });
                    }}
                  >
                    <h4 className="likes">
                      {feetModelProfile?.profile?.likesCount}
                    </h4>
                    <p className="likesText">{t("likesFeetModelView")}</p>
                  </div>
                  <div
                    onClick={() => {
                      history.push({
                        pathname: `/follow-page-lover/${feetModelProfile?.profile?._id}`,
                        state: { data: "following" },
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <h4 className="following">
                      {feetModelProfile?.profile?.activeFollowings}
                    </h4>
                    <p className="followingText">
                      {t("followingFeetModelView")}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      history.push({
                        pathname: `/follow-page-lover/${feetModelProfile?.profile?._id}`,
                        state: { data: "followers" },
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <h4 className="followers">
                      {feetModelProfile?.profile?.activeFollowers}
                    </h4>

                    <p className="followersText">
                      {t("followersFeetModelView")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="viewModelsProfiles__buttonsTopBloc">
                <Button
                  onClick={() => handleFollowOrUnfollow(isFollower)}
                  variant="contained"
                  className={`normalize-text follow-button ${
                    isFollower && isFollower._id
                      ? "white__button"
                      : "pink__button"
                  }`}
                >
                  {isFollower && isFollower._id
                    ? t("unfollowKebabMenuLover")
                    : t("followButtonFeetModelView")}
                </Button>
                <Button
                  variant="contained"
                  className="message-button white__button"
                  onClick={() =>
                    history.push({
                      pathname: "/create-messages",
                      state: { receiver: feetModelProfile?.profile },
                    })
                  }
                >
                  {t("Message")}
                </Button>
              </div>
              <div className={
                  authenticated.user.userType !== "Model" && !roleType &&
                  isSubscriber === false && isBlockedSubscription === false ?
                  "viewFeetModelsProfiles__buttonBottomBloc button-sticky" : 
                  "viewFeetModelsProfiles__buttonBottomBloc"
                }>
                {authenticated.user.userType !== "Model" && !roleType &&
                  isSubscriber === false && isBlockedSubscription === false && (
                    <Button
                      variant="contained"
                      className="subscribe-button pink__button"
                      onClick={() => setOpenModal((open) => !open)}
                    >
                      {t("subscribeFeetModelView")} <span><img className="coralStar" src={starIcon} alt="" /></span>
                    </Button>
                  )}
              </div>
              <TabChoiceModelProfile feetModelProfile={feetModelProfile} isOwner={authenticated.user._id} isSubscriptionSuspended={isSubscriptionSuspended} isBlogger={roleType} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewFeetModelsProfile;
