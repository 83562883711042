import React, { useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import PictureFilters from "./pictureFilters/PictureFilters";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import "./profilePictureResize.css";
import { useRecoilState } from "recoil";
import {
  editProfileAtom,
  onSignupAtom,
  studioAtom,
  userTypeAtom,
} from "../../stateManagement/atoms/studioAtom";

function getModalStyle() {
  const top = 47;
  const left = 48;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function ModalFilters(props) {
  const { t } = useTranslation();

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(true);
  const [onSignUp, setOnSignUp] = useRecoilState(onSignupAtom);
  const [editProfile, setEditProfile] = useRecoilState(editProfileAtom);
  const [userType, setUserType] = useRecoilState(userTypeAtom);
  // eslint-disable-next-line no-unused-vars
  const [, setOnStudio] = useRecoilState(studioAtom);

  const handleClose = () => {
    setOpen(false);
  };

  // Custom message for toast notification
  const Msg = () => (
    <div>
      <b style={{ color: "black" }}>{t("filtersToastMessage")}</b>
    </div>
  );
  // Function for displaying notification to screen
  useEffect(() => {
    const displayMsg = () => {
      toast(<Msg />);
    };
    displayMsg();
  }, []);

  // Boby of the modal content
  const body = (
    <div style={modalStyle} className="paper modal-414">
      <PictureFilters
        setOnStudio={setOnStudio}
        onSignUp={onSignUp}
        setOnSignUp={setOnSignUp}
        setEditProfile={setEditProfile}
        editProfile={editProfile}
        userType={userType}
        setUserType={setUserType}
      />
    </div>
  );

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{top: "5em"}}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
