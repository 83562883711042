import "react-medium-image-zoom/dist/styles.css";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Zoom from "react-medium-image-zoom";
import { isMobile } from "react-device-detect";
import MessageService from "../../_services/message.service";
import VideoThumbnail from "react-video-thumbnail";
import "./fetchFile.css";
import { makeStyles } from "@material-ui/core/styles";

import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import ReactPlayer from "react-player";

import VideoImg from "../../assets/images__v3/Video.png";
import { Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

/**
 * Fetch file with authentication
 *
 *
 * @param {*} file :: image || video || audio
 * @param {*} preview :: generic publication image if publication filename does not exist
 * @returns
 *
 */
const FetchFileWithAuthentication = (props) => {
  const {
    alt,
    messageFsUrl,
    type,
    showVideoModal,
    status,
    authUser,
    page,
    images,
    ...rest
  } = props;
  const classes = useStyles();
  const [url, setUrl] = useState("");
  const [loader, setLoader] = useState(false);
  const [videoLength, setVideoLength] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [slideImage, setSlideImage] = useState([]);

  const statuses = ["rejected", "pending", undefined];

  useEffect(() => {
    setLoader(true);
    // render message file with authentication
    if (messageFsUrl) {
      async function fetchPublication() {
        try {
          if (messageFsUrl.includes("storage.googleapis.com")) {
            const media = {
              id: `media-${1}`,
              src: messageFsUrl,
              isVideoType: type === "video",
            };
            setUrl(messageFsUrl);
            setSlideImage([media]);
          } else {
            const response = await MessageService.getMessageFileFromFileSystem(
              messageFsUrl
            );
            const text = await new Response(response).text();

            if (text !== "Input file is missing") {
              if (type === "video") {
                const video = document.createElement("video");
                video.preload = "metadata";

                video.onloadedmetadata = function () {
                  window.URL.revokeObjectURL(video.src);
                  const duration = video.duration;
                  if (duration) {
                    let m = 0;
                    let s = 0;
                    let result = "";
                    const countPoint =
                      duration.toString().split(".").length - 1;
                    if (countPoint === 1) {
                      s = Number(duration.toString().split(".")[0]);
                      result = m.toString() + "min " + s.toString() + "s";
                    } else {
                      m = Number(duration.toString().split(".")[0]);
                      s = Number(duration.toString().split(".")[1]);
                      result = m.toString() + "min " + s.toString() + "s";
                    }

                    setVideoLength(result);
                  }
                };
                video.src = URL.createObjectURL(response);
              }
              const objectURL = URL.createObjectURL(response);
              setUrl(objectURL);
              const media = {
                id: `media-${1}`,
                src: objectURL,
                isVideoType: type === "video",
              };
              setSlideImage([media]);
            }
          }
          setTimeout(() => setLoader(false), 1000);
        } catch (error) {
          console.error(error);
        }
      }

      fetchPublication();
    }
  }, [messageFsUrl, type]);

  useEffect(() => {
    if (alt === "custom-media") {
      const imgUrl = images?.map((image, key) => {
        return {
          id: `media-${key}`,
          src: image.content,
          isVideoType: image.customMediaType === "video",
        };
      });
      setSlideImage(imgUrl);
    }
  }, []);

  //todo: handle audio soon...
  const handleCapitalize = (text) =>
    text.charAt(0).toUpperCase() + text.slice(1);

  const lazyLoadImage = ({ url }) => {
    return (
      <LazyLoadImage
        onContextMenu={(e) => {
          e.preventDefault();
          return false;
        }}
        src={url}
      />
    );
  };

  const renderVideo = (props) => {
    return (
      <ReactPlayer
        id={props.id}
        url={props.src}
        config={{ file: { attributes: { controlsList: "nodownload" } } }}
        playing
        controls
        light
        muted
      />
    );
  };

  const isPending = status !== "approved";
  const isBlur = statuses.includes(status);

  const renderImage = (props) => (
    <div
      className={`slideImage ${
        authUser?.user?.userType === "Lover" && isBlur ? "blur-image" : ""
      }`}
    >
      <img src={props.src} alt={props.id} width={"100%"} height={"auto"} />
    </div>
  );

  const renderGallery = () => {
    return slideImage?.map((media, key) => (
      <div key={`media-${key}`} style={{ width: "100%", height: "100%" }}>
        {media.isVideoType ? renderVideo(media) : renderImage(media)}
      </div>
    ));
  };

  const handleSliderImages = () => {
    return (
      <div style={{ width: "100%" }}>
        <Dialog
          open={true}
          className={`sliderModal ${classes.backdrop}`}
          onClose={() => setIsOpen(false)}
        >
          <DialogTitle
            id="slider-image-close"
            onClose={() => setIsOpen(false)}
            className={classes.dialogTitleRoot}
          >
            {/* <img src={CloseIcon} alt="close-icon" onClick={() => setIsOpen(false)}/> */}
            <IconButton aria-label="close" onClick={() => setIsOpen(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <div style={{ width: "100%" }}>
            <AwesomeSlider
              bullets={slideImage?.length > 1}
              organicArrows={slideImage?.length > 1}
            >
              {renderGallery()}
            </AwesomeSlider>
          </div>
        </Dialog>
      </div>
    );
  };

  return (
    <div
      className={`
        ${
          type === "video"
            ? `cm-file-container-video ${
                page === "tracker" ? "tracker-file-container-video" : ""
              } `
            : `cm-file-container ${
                page === "tracker" ? "tracker-file-container" : ""
              }`
        }
      `}
      onClick={() => {
        return type && showVideoModal && type === "video"
          ? showVideoModal(messageFsUrl, status)
          : null;
      }}
      onKeyDown={() => {
        return type && showVideoModal && type === "video"
          ? showVideoModal(messageFsUrl, status)
          : null;
      }}
    >
      {isOpen && handleSliderImages()}
      {(type && type === "image") || !type ? (
        authUser?.user?.userType === "Lover" && status !== "approved" ? (
          <div
            className={`cm-file-wrapper ${
              authUser?.user?.userType === "Lover" && statuses.includes(status)
                ? "blur-image"
                : ""
            }`}
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          >
            {isPending && authUser.user.userType === "Lover" ? (
              <p
                style={{
                  color: status === "rejected" ? "red" : "blue",
                  marginTop: 0,
                }}
              >
                {handleCapitalize(status)}
              </p>
            ) : null}
            {lazyLoadImage({ url })}
          </div>
        ) : (
          <div
            className={`cm-file-wrapper ${
              authUser?.user?.userType === "Lover" && statuses.includes(status)
                ? "blur-image"
                : ""
            } ${page === "tracker" ? "tracker-wrapper" : ""}`}
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          >
            {isPending && authUser.user.userType === "Lover" ? (
              <p
                style={{
                  color: status === "rejected" ? "red" : "blue",
                  marginTop: 0,
                }}
              >
                {handleCapitalize(status)}
              </p>
            ) : null}
            {lazyLoadImage({ url })}
          </div>
        )
      ) : type && type === "video" ? (
        <div
          className={`${
            status !== "approved"
              ? "blur-image cm-file-wrapper"
              : "cm-file-wrapper"
          }`}
        >
          {!loader && status !== "approved" ? (
            <div
              className="cm-file-banner-video"
              style={{
                color: status === "rejected" ? "red" : "blue",
              }}
            >
              {handleCapitalize(status)}
            </div>
          ) : null}
          {!loader ? (
            <div
              className={`cm-video-wrapper ${
                status === "approved" ? "cm-video-wrapper-mt15" : ""
              } ${page === "tracker" ? "tracker-video-wrapper" : ""}`}
            >
              <img
                src={VideoImg}
                alt="VideoIcon"
                className={`cm-video-icon ${
                  page === "tracker" ? "tracker-video-icon" : ""
                }`}
              />
              <VideoThumbnail videoUrl={url} snapshotAtTime={2} />
              {status === "approved" && videoLength ? (
                <p style={{ fontSize: "12px", color: "#fff" }}>{videoLength}</p>
              ) : null}
            </div>
          ) : (
            <div className="cm-file-loader">video loading...</div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default FetchFileWithAuthentication;
