import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const buyTickles = async (id, data) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const response = await axiosInstance.post(
      `/v1/users/${id}/buyTickles`,
      data,
      { headers }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

const subscribeToUser = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const id = getAuthSession().user._id;

    const response = await axiosInstance.post(
      `/v1/users/${id}/subscribe`,
      data,
      { headers }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

const TransactionService = { buyTickles, subscribeToUser };

export default TransactionService;
