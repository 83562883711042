import axios from "axios";
import { selector } from "recoil";

import { getAuthSession } from "../../helpers/auth.helpers";
import feetModelAtom from "../atoms/feetModelAtom";

const { REACT_APP_API_BASE_URL } = process.env;

const feetModelProfileQuery = selector({
  key: "feetModelProfile",
  get: async ({ get }) => {
    try {
      const url = new URL(window.location.href);
      const userId = url.searchParams.get("user");

      const user = await get(feetModelAtom);

      const feetModelId = (user && user._id) || userId;

      if (feetModelId) {
        const response = await axios.get(
          `${REACT_APP_API_BASE_URL}/v1/feet-models/profile/${feetModelId}`,
          {
            headers: {
              Authorization: `Bearer ${getAuthSession().user.access_token}`,
            },
          }
        );

        return response.data;
      }
      return;
    } catch (e) {
      console.error("ERROR GET /api/v1/feet-models/profile", e);
    }
  },
});

export default feetModelProfileQuery;
