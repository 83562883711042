import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChevronLeft } from "@material-ui/icons";
import { ToastContainer } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./feetLoverProfile.css";

import LogoText from "../../../assets/images/logo-text@2x.png";
import ticklesLog from "../../../assets/images/menu/tickles.svg";
import defaultAvatar from "../../../assets/images/generic-user-avatar.png";
import menu from "../../../assets/images/view-feet-models-profile/menu.svg";

import useSessionStorage from "../../../components/forms/services/storageHooks/useSessionStorage";

import BlockService from "../../../_services/block.service";
import PictyService from "../../../_services/picty.service";
import FeetLoverService from "../../../_services/feet-lovers.service";
import FollowService from "../../../_services/follow.service";
import HistoryService from "../../../_services/history.service";

import KebabMenuOptionsLovers from "../../loversComponents/KebabMenuOptionsLovers/KebabMenuOptionsLovers";
import ContentNotAvailable from "../../ContentNotAvailable";
import searchFilterPictyAtom from "../../../stateManagement/atoms/searchFilterPictyAtom";
import { useRecoilState } from "recoil";

const VisitFeetLoverProfile = () => {
  const { t } = useTranslation();
  let { id } = useParams();
  let history = useHistory();

  const [authenticated] = useSessionStorage({}, "infosUser");
  const [isAnotherUser, setIsAnotherUser] = useState(false);
  const [feetLover, setFeetLover] = useState({});
  const [preferredPicties, setPreferredPicites] = useState([]);
  const [isFollower, setIsFollower] = React.useState({});
  const [openProfile, setOpenProfile] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isBlockedOrBlocker, setIsBlockedOrBlocker] = React.useState(false);
  const [, setSearchFilterPicty] = useRecoilState(searchFilterPictyAtom);
  // const [subscribers, setSubscribers] = useState([]);

  useEffect(() => {
    if (id) {
      /* Check if current authenticated user is not equal to the visited feet lover profile id */
      if (authenticated.user._id !== id) {
        setIsAnotherUser(true);
      }

      setLoading(true);

      async function fetchFeetLoverProfile() {
        const response = await FeetLoverService.getFeetLoverProfile(id);
        setFeetLover(response);
      }

      async function checkIfBlockedOrBlocker() {
        const response = await BlockService.findBlockedOrBlocker(id);

        setLoading(false);
        if (response && response.block) {
          setIsBlockedOrBlocker(true);
        } else {
          setIsBlockedOrBlocker(false);
        }
      }

      fetchFeetLoverProfile();
      checkIfBlockedOrBlocker();
    }
  }, [id, authenticated]);

  // useEffect(() => {
  //   if (authenticated && authenticated.user.id) {
  //     async function fetchSubscribers() {
  //       const subscriber = await SubscribeService.getSubscriberList(authenticated.user.id);
  //       setSubscribers(subscriber.data.subscriptions);
  //     }
  //     fetchSubscribers();
  //   }
  // }, [authenticated.user.id]);

  /* Fetch Preferred Picties & identify if authenticated user is already a follower. */
  useEffect(() => {
    if (
      feetLover &&
      feetLover.profile &&
      feetLover.profile.preferredPicties &&
      feetLover.profile.preferredPicties.length
    ) {
      async function fetchPreferredPicties() {
        const preferredPicties = await Promise.all(
          feetLover.profile.preferredPicties.map(async (picty) => {
            const response = await PictyService.getPicty(picty);

            return response.data.picty;
          })
        );

        setPreferredPicites(preferredPicties);
      }
      setIsFollower(feetLover.profile.isFollower);
      // async function checkIfAuthenticatedUserIsAFollower() {
      //   const response = await FollowService.checkFollower(
      //     feetLover.profile._id
      //   );
      //
      //   if (response && response.follower) {
      //     setIsFollower(response.follower);
      //   }
      // }
      fetchPreferredPicties();
      // checkIfAuthenticatedUserIsAFollower();
    }

    if (feetLover && feetLover.profile && feetLover.profile._id) {
      const query = new URLSearchParams(history.location?.search);

      if (query && query.get("fromSearchPage")) {
        // trigger search history api endpoint
        HistoryService.postSearchHistory({
          searchedUser: feetLover.profile._id,
        });
      }
    }
  }, [feetLover, history]);

  const handleFollowOrUnfollow = async (data) => {
    try {
      if (data && data._id) {
        // un-follow
        await FollowService.remove(isFollower.followedUser);
        setIsFollower({});
      } else {
        // follow
        const response = await FollowService.followUser({
          followedUser: feetLover.profile._id,
        });
        setIsFollower(response.follow);
      }
    } catch (error) {
      console.error("ERROR POST /api/v1/follows/:id", error);
    }
  };

  // const lengthOfSubscriptions = (dataSubscribers, userId) =>
  //     dataSubscribers
  //         .filter((subscriptions) => subscriptions.subscriber._id === userId)
  //         .map((resSubscribe) => resSubscribe);

  return (
    <div className="feetLoverProfile__container">
      <div className="feetLoverProfile__top flex-center header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => {
            history.goBack();
          }}
        >
          <ChevronLeft />
        </IconButton>
        <img className="logo" src={LogoText} alt="" />

        {isBlockedOrBlocker ? (
          ""
        ) : (
          <IconButton
            className="viewFeetModelsProfiles__IconButton header__rightButtton"
            onClick={() => setOpenProfile(true)}
          >
            <img src={menu} alt="menu" className="viewPodelsProfileTop__img" />
          </IconButton>
        )}

        <KebabMenuOptionsLovers
          user={feetLover.profile}
          open={openProfile}
          setOpen={setOpenProfile}
          isFollower={isFollower}
          setFollower={setIsFollower}
          showReportButtonForUser={true}
        />
      </div>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "400px",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : isBlockedOrBlocker ? (
        <ContentNotAvailable />
      ) : (
        <div className="feetLoverProfile__content content__withHeader">
          <div className="feetLoverProfileContent__top">
            <img
              src={
                feetLover && feetLover.profile && feetLover.profile.image
                  ? feetLover.profile.image.url
                  : defaultAvatar
              }
              alt=""
            />

            {isAnotherUser && (
              <div className="feetLoverProfile__buttonsTopBloc">
                <Button
                  onClick={() => handleFollowOrUnfollow(isFollower)}
                  className={
                    isFollower && isFollower._id
                      ? "normalize-text white__button"
                      : "normalize-text pink__button"
                  }
                  variant="contained"
                >
                  {isFollower && isFollower._id
                    ? t("unfollowKebabMenuLover")
                    : t("followButtonFeetModelView")}
                </Button>
                <Button
                  variant="contained"
                  className="message-button white__button"
                  onClick={() =>
                    history.push({
                      pathname: "/create-messages",
                      state: { receiver: feetLover.profile },
                    })
                  }
                >
                  {t("message")}
                </Button>
              </div>
            )}

            <h2 style={
              feetLover &&
              feetLover.profile &&
              feetLover.profile.isSubscriber
                  ? { color: "#ff2775", fontWeight: "bold" }
                  : {}
            }>
              {(feetLover && feetLover.profile && feetLover.profile.username) ||
                (feetLover &&
                  feetLover.profile &&
                  feetLover.profile.facebook &&
                  feetLover.profile.facebook.displayName)}
            </h2>
            <h3>{feetLover && feetLover.profile && feetLover.profile.title}</h3>
            <p>
              {feetLover && feetLover.profile && feetLover.profile.description}
            </p>
          </div>
          <div className="feetLoverProfileContent__bloc">
            {!isAnotherUser && (
              <div className="feetLoversProfile__button-content">
                <Button
                  onClick={() => history.push("/buy-tickles-credit")}
                  className="buyTicklesButton pink__button"
                  variant="contained"
                >
                  <img
                    className="feetLoverProfile__ticklesIcon"
                    src={ticklesLog}
                    alt=""
                  />{" "}
                  {t("feetLoverProfileBuyTickles")}
                </Button>
              </div>
            )}
            <div className="profile_counts">
              {!isAnotherUser && (
                <div>
                  <h4>
                    {feetLover &&
                      feetLover.profile &&
                      feetLover.profile.tickleBalance}
                  </h4>
                  <p>{t("feetLoverProfileTickleBalance")}</p>
                </div>
              )}
              <div
                onClick={() => {
                  history.push({
                    pathname: `/follow-page-lover/${feetLover.profile._id}`,
                    state: { data: "following" },
                  });
                }}
              >
                <h4>
                  {feetLover &&
                    feetLover.profile &&
                    feetLover.profile?.activeFollowings}
                </h4>
                <p className="contentBlocText__follower">
                  {t("profileFollowing")}
                </p>
              </div>
              <div
                onClick={() => {
                  history.push({
                    pathname: `/follow-page-lover/${feetLover.profile._id}`,
                    state: { data: "followers" },
                  });
                }}
              >
                <h4>
                  {feetLover &&
                    feetLover.profile &&
                    feetLover.profile?.activeFollowers}
                </h4>
                <p>{t("followersFeetModelView")}</p>
              </div>
            </div>
          </div>

          <div className="feetLoverProfileContent__picty">
            <h2 className="feetLoverContentPictyTitle">
              {t("feetLoverProfileFavPicty")}
            </h2>
            <div className="picty__container">
              {preferredPicties && preferredPicties.length ? (
                preferredPicties.map((picty) => (
                  <div
                    key={picty._id}
                    onClick={() => {
                      setSearchFilterPicty([picty]);
                      history.push("/search-lover");
                    }}
                  >
                    <img
                      key={picty._id}
                      src={picty.url}
                      alt=""
                      style={{ width: "42px" }}
                    />
                    <p>{picty.label}</p>
                  </div>
                ))
              ) : (
                <p>No favorite picty selected yet</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VisitFeetLoverProfile;
