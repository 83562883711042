import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const getTicklePrices = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/tickles-prices`, {
    headers,
  });

  return response.data;
};

const getEpochProductLists = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/product/list-tickle-products`, {
    headers,
  });

  return response.data;
};

const TicklePricesService = { getTicklePrices, getEpochProductLists };

export default TicklePricesService;
