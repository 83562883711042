import React from "react";

function EpochPrices(props) {
  const {
    epochProductLists,
    selectedTickleProduct,
    handleSelectedTickleProduct,
    tickleIcons,
    useFor,
    ...rest
  } = props;

  const convertTickles = (ticklePrice) => {
    if (ticklePrice) {
      const ht = ticklePrice / 100;
      const ttc = ht * 1.2;
      const epoch = ttc * 0.15;
      const tva = (ttc / 1.2) * 0.2;
      const feety = (ttc - epoch - tva) * 0.2;
      const converted =
        useFor && useFor === "ProposeTickles"
          ? ht // ttc - tva - epoch - feety
          : ticklePrice;
      return converted.toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    } else {
      return 0.0;
    }
  };

  return (
    <div className="tickles-prices" {...rest}>
      {epochProductLists
        ?.sort((a, b) => a.tickles - b.tickles)
        .map((product, index) => (
          <div
            key={product._id}
            className={`tickles-prices-item ${
              selectedTickleProduct._id === product._id &&
              "selected-tickle-product"
            }`}
            onClick={(e) => handleSelectedTickleProduct(product)}
          >
            <div className="tickles-prices-item-icon">
              {/* tickle image */}
              <img
                width="45"
                height="auto"
                src={tickleIcons[product.tickles <= 5000 ? index : 5]}
                alt="tickle-price-icon"
              />
              {/* number of tickles */}
              <span>{product.tickles}</span>
            </div>
            {/* price of tickles */}
            <span>
              {useFor && useFor === "ProposeTickles"
                ? convertTickles(product.tickles)
                : convertTickles(product.taxedPrice)}{" "}
              &euro;
            </span>
          </div>
        )) || null}
    </div>
  );
}

export default EpochPrices;
