import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  accordion: {
    boxShadow: "0 0 10px #00000040",
    borderRadius: "10px",
    "& .MuiAccordion-root:before": {
      background: "none !important",
    },
    "& .Mui-expanded.MuiIconButton-edgeEnd": {
      marginTop: "0",
    },
    "& .picties": {
      display: "flex",
      padding: "10px",
      justifyContent: "space-around",
    },
    "& .selected-picty": {
      border: "5px solid #FF5768",
      borderRadius: "50%",
    },
    "& .picty": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: "10px !important",
      marginTop: "0px !important",
    },
    "& .MuiCollapse-wrapper": {
      //   height: "150px !important",
      "overflow-y": "scroll",
      //   padding: "0 8px",
      padding: "0 8px 15px 8px",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2.5rem",
    },
    "& .MuiAccordionSummary-root": {
      height: "50px !important",
    },
    // "& .MuiAccordionSummary-root.Mui-expanded": {
    //   height: "50px !important",
    // },

    "& .MuiAccordionSummary-root.Mui-expanded": {
      height: "50px !important",
      minHeight: "50px !important",
    },
    "& .pTag": {
      margin: "5px 10px",
    },
    "& .selected-shoe-size": {
      color: "rgb(229, 88, 110)",
    },
    "& #panel1a-content": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "10px",
      justifyContent: "space-between",
    },
  },
}));
