import React, { useEffect, useState } from "react";
import axios from "axios";

function HomeTest() {
  const [hello, setHello] = useState("");

  useEffect(() => {
    axios.get(`http://localhost:5000/api/healthcheck`).then((res) => {
      const response = res.data;
      setHello(response);
    });
  });
  return (
    <div>
      <h3>Message from server</h3>
      <h2>{hello}</h2>
    </div>
  );
}

export default HomeTest;
