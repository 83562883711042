import "./SwipeableListItem.css";
import React from "react";

class SwipeableListItem extends React.Component {
  // DOM Refs
  listElement;
  wrapper;
  background;

  // Drag & Drop
  dragStartX = 0;
  left = 0;
  dragged = false;

  // FPS Limit
  startTime;
  fpsInterval = 1000 / 60;

  constructor(props) {
    super(props);

    this.listElement = null;
    this.wrapper = null;
    this.background = null;

    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onDragStartMouse = this.onDragStartMouse.bind(this);
    this.onDragStartTouch = this.onDragStartTouch.bind(this);
    this.onDragEndMouse = this.onDragEndMouse.bind(this);
    this.onDragEndTouch = this.onDragEndTouch.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.onClicked = this.onClicked.bind(this);

    this.onSwiped = this.onSwiped.bind(this);

    this.getSiblings = this.getSiblings.bind(this);
  }

  componentDidMount() {
    window.addEventListener("mouseup", this.onDragEndMouse);
    window.addEventListener("touchend", this.onDragEndTouch);
  }

  componentWillUnmount() {
    window.removeEventListener("mouseup", this.onDragEndMouse);
    window.removeEventListener("touchend", this.onDragEndTouch);
  }

  onDragStartMouse(evt) {
    this.onDragStart(evt.clientX);
    window.addEventListener("mousemove", this.onMouseMove);
  }

  onDragStartTouch(evt) {
    const touch = evt.targetTouches[0];
    this.onDragStart(touch.clientX);
    window.addEventListener("touchmove", this.onTouchMove);
  }

  getSiblings(e) {
    // for collecting siblings
    let siblings = [];
    // if no parent, return no sibling
    if (!e.parentNode) {
      return siblings;
    }
    // first child of the parent node
    let sibling = e.parentNode.firstChild;
    // collecting siblings
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== e) {
        siblings.push(sibling);
      }
      sibling = sibling.nextSibling;
    }
    return siblings;
  }

  onDragStart(clientX) {
    /* Reset all message element style left to 0. */
    this.getSiblings(this.listElement.closest(".List")).map((elem) => {
      const prevBouncingListItem = elem.querySelector(".BouncingListItem");
      const prevBouncingListItemTrashBg = elem.querySelector(".Background");

      if (prevBouncingListItem) {
        this.left = 0;
        prevBouncingListItem.className = "ListItem";

        // reset transform left style to 0.
        prevBouncingListItem.style.transform = `translateX(${this.left}px)`;
        // hide trash background
        prevBouncingListItemTrashBg.style.opacity = 0;
      }
    });

    this.dragged = true;
    this.dragStartX = clientX;
    this.listElement.className = "ListItem";
    this.startTime = Date.now();
    requestAnimationFrame(this.updatePosition);
  }

  onDragEndMouse(evt) {
    window.removeEventListener("mousemove", this.onMouseMove);
    this.onDragEnd();
  }

  onDragEndTouch(evt) {
    window.removeEventListener("touchmove", this.onTouchMove);
    this.onDragEnd();
  }

  onDragEnd() {
    if (this.dragged) {
      this.dragged = false;

      const threshold = this.props.threshold || 0.3;

      if (this.left < this.listElement.offsetWidth * threshold * -1) {
        // this.left = -this.listElement.offsetWidth * 2;

        // set to fixed value so that it wil not totally hide the message to the left.
        this.left = -70;

        // This will hide the message element
        // this.wrapper.style.maxHeight = 0;
        // this.onSwiped();
      } else {
        this.left = 0;
        // hide trash after slide to the right.
        this.background.style.opacity = 0;
      }

      this.listElement.className = "BouncingListItem";
      this.listElement.style.transform = `translateX(${this.left}px)`;
    }
  }

  onMouseMove(evt) {
    const left = evt.clientX - this.dragStartX;
    if (left < 0) {
      this.left = left;
    }
  }

  onTouchMove(evt) {
    const touch = evt.targetTouches[0];
    const left = touch.clientX - this.dragStartX;
    if (left < 0) {
      this.left = left;
    }
  }

  updatePosition() {
    if (this.dragged) requestAnimationFrame(this.updatePosition);

    const now = Date.now();
    const elapsed = now - this.startTime;

    if (this.dragged && elapsed > this.fpsInterval) {
      this.listElement.style.transform = `translateX(${this.left}px)`;

      const opacity = (Math.abs(this.left) / 100).toFixed(2);
      if (opacity < 1 && opacity.toString() !== this.background.style.opacity) {
        this.background.style.opacity = opacity.toString();
      }
      if (opacity >= 1) {
        this.background.style.opacity = "1";
      }

      this.startTime = Date.now();
    }
  }

  onClicked() {
    if (this.props.onSwipe) {
      this.props.onSwipe();
    }
  }

  onSwiped() {
    // execute handle delete message conversations
    // this.props.handleDeleteMessageConversation(this.props.user);

    if (this.props.onSwipe) {
      this.props.onSwipe();
    }
  }

  render() {
    return (
      <>
        <div className="Wrapper" ref={(div) => (this.wrapper = div)}>
          <div
            ref={(div) => (this.background = div)}
            className="Background"
            onClick={async () => {
              try {
                await this.props.handleDeleteMessageConversation(
                  this.props.user
                );

                this.wrapper.style.maxHeight = 0;
              } catch (error) {
                // do not delete the message.
              }
            }}
          >
            {this.props.background ? (
              this.props.background
            ) : (
              <span>Delete</span>
            )}
          </div>
          <div
            onClick={this.onClicked}
            ref={(div) => {
              this.listElement = div;
            }}
            onMouseDown={this.onDragStartMouse}
            onTouchStart={this.onDragStartTouch}
            className="ListItem"
          >
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
}

export default SwipeableListItem;

// import './SwipeableListItem.css'
// import React, {useRef, useEffect} from 'react'

// function SwipeableListItem(props) {
//     const listElementRef = useRef()
//     const wrapperRef = useRef()
//     const backgroundRef = useRef()

//     const dragStartXRef = useRef(0)
//     const leftRef = useRef(0)
//     const draggedRef = useRef(false)

//     useEffect(() => {
//         window.addEventListener('mouseup', onDragEndMouse)
//         window.addEventListener('touchend', onDragEndTouch)
//         return () => {
//             window.removeEventListener("mouseup", onDragEndMouse);
//             window.removeEventListener("touchend", onDragEndTouch);
//         }
//     }, [])

//     function onDragStartMouse(evt) {
//         onDragStart(evt.clientX)
//         window.addEventListener('mousemove', onMouseMove)
//     }

//     function onDragStartTouch(evt) {
//         const touch = evt.targetTouches[0]
//         onDragStart(touch.clientX)
//         window.addEventListener('touchmove', onTouchMove)
//     }

//     function onDragStart(clientX) {
//         draggedRef.current = true
//         dragStartXRef.current = clientX

//         listElementRef.current.className = 'ListItem'

//         requestAnimationFrame(updatePosition)
//     }

//     function updatePosition() {
//         if (draggedRef.current) {
//             requestAnimationFrame(updatePosition);
//         }

//         listElementRef.current.style.transform = `translateX(${leftRef.current}px)`;

//         // Fade the opacity
//         const opacity = (Math.abs(leftRef.current) / 100).toFixed(2);
//         if (opacity < 1 && opacity.toString() !== backgroundRef.current.style.opacity) {
//             backgroundRef.current.style.opacity = opacity.toString();
//         }
//         if (opacity >= 1) {
//             backgroundRef.current.style.opacity = "1";
//         }
//     }

//     function onMouseMove(evt) {
//         const left = evt.clientX - dragStartXRef.current
//         if (left < 0) {
//             leftRef.current = left
//         }
//     }

//     function onTouchMove(evt) {
//         const touch = evt.targetTouches[0]
//         const left = touch.clientX - dragStartXRef.current
//         if (left < 0) {
//             leftRef.current = left
//         }
//     }

//     function onDragEndMouse(evt) {
//         window.removeEventListener('mousemove', onMouseMove)
//         onDragEnd()
//     }

//     function onDragEndTouch(evt) {
//         window.removeEventListener('touchmove', onTouchMove)
//         onDragEnd()
//     }

//     function onDragEnd() {
//         if (draggedRef.current) {
//             draggedRef.current = false
//             const threshold = props.threshold || 0.3

//             if (leftRef.current < listElementRef.current.offsetWidth * threshold * -1) {
//                 leftRef.current = (-listElementRef.current.offsetWidth * 2)

//                 wrapperRef.current.style.maxHeight = 0
//                 onSwiped()
//             } else {
//                 leftRef.current =0
//             }

//             listElementRef.current.className = 'BouncingListItem'
//             listElementRef.current.style.transform = `translateX(${leftRef.current}px)`
//         }
//     }

//     function onSwiped() {
//         if (props.onSwipe) {
//             props.onSwipe()
//         }
//     }

//     return (
//         <>
//             <div className='Wrapper' ref={wrapperRef}>
//                 <div className='Background' ref={backgroundRef}>
//                     <span>Delete</span>
//                 </div>
//                 <div className='ListItem' ref={listElementRef}
//                      onMouseDown={onDragStartMouse}
//                      onTouchStart={onDragStartTouch}>
//                     {props.children}
//                 </div>
//             </div>
//         </>
//     )
// }

// export default SwipeableListItem
