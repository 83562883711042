import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const suppressAccount = async (data, language = "en") => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
    "Accept-Language": language,
  };

  await axiosInstance.post("/v1/suppress/account", data, { headers });
  return;
};

const SuppressAccountService = { suppressAccount };

export default SuppressAccountService;
