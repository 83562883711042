import React, { Fragment } from "react";
import PublicationElem from "./elem/PublicationElem";
import IdCheckElem from "./elem/IdcheckElem";

const GeneralNotificationList = ({ data, isInvisibleGeneralBadge }) => (
  <Fragment>
    <div
      className="notificationsGeneral swipped user_list"
      style={{ textAlign: "center", gap: "20px" }}
    >
      {data.map((elem) => {
        if (elem) {
            if (elem.type === "Publication")
                return <PublicationElem elem={elem} key={elem._id} isInvisibleGeneralBadge={isInvisibleGeneralBadge} />;
            if (elem.type === "Id-check")
                return <IdCheckElem elem={elem} key={elem._id} isInvisibleGeneralBadge={isInvisibleGeneralBadge} />;
            return <div key={elem._id}></div>;
        } else {
            return null;
        }
      })}
    </div>
  </Fragment>
);

export default GeneralNotificationList;
