/* eslint-disable no-unused-vars */
import React from "react";
import { MessageText } from "./MessageText";
import { MessageFile } from "./MessageFile";
import { MessageTickle } from "./MessageTickle";
import { MessageHeart } from "./MessageHeart";
import { MessageCustomMedia } from "./MessageCustomMedia";

export const MessageInfo = (props) => {
  return (
    <div className="messagesSectionInfos">
      {props.message.type === "Files" && <MessageFile {...props} />}
      {props.message.type === "Tickles" && <MessageTickle {...props} />}
      {props.message.type === "Text" && <MessageText {...props} />}
      {props.message.type === "Heart" && <MessageHeart {...props} />}

      {props.message.type === "Custom-Media-Request" && (
        <MessageCustomMedia {...props} />
      )}
    </div>
  );
};
