import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {firebaseStorage} from "../helpers/firebase";
import {getAuthSession} from "../helpers/auth.helpers";
import {axiosInstance} from "./base";

export const uploadMedias = async (file, reportFn, cbUploadFn, folder) => {
    const date = new Date().getTime()
    const filename = file.name.toLowerCase()
    const formatted_filename = `${date}-${filename}`
    const formatted_path = `${folder}/${formatted_filename}`

    const storageRef = ref(firebaseStorage, formatted_path);
    const metadata = {
        contentType: file.type,
        customMetadata: { isAuth: true }
    };
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    await uploadTask.on('state_changed',
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            reportFn(progress)
            switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running');
                    break;
            }
        },
        (error) => {
        console.log("Error: ", error)
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;
                case 'storage/canceled':
                    // User canceled the upload
                    break;
                case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    break;
            }
            cbUploadFn(false, error);
            throw error
        },
        async () => {
            // Upload completed successfully, now we can get the download URL
            try {
                const validate = file && file.type.includes('video') && await validateUploadedMedias(formatted_path, folder)
                console.log('Validate data', validate);
                if ((file && file.type.includes("image")) || (validate && validate.data.duration)) {
                    await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        cbUploadFn(true, formatted_path);

                        return downloadURL;
                    });
                }
            } catch(e) {
                console.log('error validate media: ', e.response)
                cbUploadFn(false, formatted_path);
            }
        });
}

export const uploadMultipleMedias = async (files, progressFn, callBackFn, folder) => {
    // Commented codes are for processing multiple uploads to firebase storage
    const date = new Date().getTime()
    console.log("files: ", files)
    const res = files.map(async (file) => {
        console.log('loop');
        const filename = file.files.name.toLowerCase()
        const formatted_filename = `${date}-${filename}`
        const formatted_path = `${folder}/${formatted_filename}`

        const storageRef = ref(firebaseStorage, formatted_path);
        const metadata = {
            contentType: file.files.type,
            customMetadata: { isAuth: true }
        };

        const uploadTask = uploadBytesResumable(storageRef, file.files, metadata);

        const upload = new Promise((resolve, reject) => {
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    progressFn(prog);
                },
                (error) => console.log(error),
                async () => {
                    try {
                        const validate = file.files && file.files.type.includes("video") && await validateUploadedMedias(formatted_path, folder)
                        console.log('Validate data', validate);
                        if ((file.files && file.files.type.includes("image")) || (validate && validate.data.duration)) {
                            await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                console.log('File available at', downloadURL);
                                // callBackFn(true, formatted_path);
                                resolve({error: false, formatted_path});
                            });
                        }
                    } catch(e) {
                        console.log('error validate media: ', e.response)
                        // callBackFn(false, formatted_path);
                        resolve({error: true, formatted_path})
                    }
                }

            );
        })
        return upload;
    })
    Promise.all(res).then((result) => {
        console.log('Media uploaded');
        console.log(result)
        if (result && result.length > 0) {
            callBackFn(result);
        }
    });
}

const validateUploadedMedias = async (formatted_path, type) => {
    const headers = {
        Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const { data } = await axiosInstance.get(`/v2/medias/validate/media?url=${formatted_path}&type=${type}`, {
        headers,
    });
    return data;
}