// import axios from "axios";

import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

/**
 *
 * @param {*} data  :: publication id; tickle amount
 * @param {*} accessToken
 */
const sendTickleViaPublication = async (data, accessToken = undefined) => {
  try {
    const headers = {
      Authorization: `Bearer ${
        accessToken || getAuthSession().user.access_token
      }`,
    };

    const response = await axiosInstance.post("/v1/tickles", data, {
      headers,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateTickle = async (id, data) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.put(`/v1/tickles/${id}`, data, {
    headers,
  });

  return response.data;
};

const getSentTickles = async (offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/tickles?offset=${offset}`, {
    headers,
  });

  return response;
};

const getNotificationTickles = async (offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
    `/v1/notifications/tickles?offset=${offset}`,
    {
      headers,
    }
  );

  return response;
};
const getNotificationTicklesV2 = async (offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
      `/v2/notifications/tickles?offset=${offset}`,
      {
        headers,
      }
  );

  return response;
};

const TickleService = {
  sendTickleViaPublication,
  updateTickle,
  getSentTickles,
  getNotificationTickles,
  getNotificationTicklesV2
};

export default TickleService;
