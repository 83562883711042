import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  popover: {
    "& .MuiPopover-paper": {
      margin: "0 !important",
    },
    "& button": {
      //   backgroundColor: "red !important",
      padding: "0px 15px !important",
      fontFamily: "Roboto",
      // fontSize: "1.0416666666666667vw",
    },
  },
}));
