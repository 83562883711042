/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";

export default function useInfiniteQuery(name, fetchCallback, refetch = 0) {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [offset, setNextOffset] = useState(0);
  const [prevOffset, setPreviousOffset] = useState(0);
  const [status, setStatus] = useState("init");
  const [error, setError] = useState({});
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
  const [isFetchingPreviousPage, setIsFetchingPreviousPage] = useState(false);

  const [isDataFromPreviousPage, setIsDataFromPreviousPage] = useState(false);

  const offSetRef = useRef();
  const prevOffSetRef = useRef();
  offSetRef.current = offset;
  prevOffSetRef.current = prevOffset;

  // initial load
  React.useEffect(() => {
    setStatus("loading");

    // GET request
    fetchCallback({ next_offset: 0 })
      .then((response) => {
        setStatus("");
        setData(response.data);
        setNextOffset(response.next_offset);
        setTotal(response.total);

        // if prev offset = 10 but data is less than 10? set previous offset to false still...
        if (response && response.prev_offset) {
          if (response && !response.data.length <= 10)
            setPreviousOffset(response.prev_offset);
        }
      })
      .catch((error) => {
        setStatus("error");
        setError(error);
      });
  }, [name, refetch]);

  // fetch next page
  React.useEffect(() => {
    if (isFetchingNextPage)
      fetchCallback({ next_offset: offSetRef.current })
        .then((response) => {
          setIsFetchingNextPage(false);
          setData([...data, ...response.data]);
          setNextOffset(response.next_offset);
        })
        .catch((error) => error);
  }, [isFetchingNextPage]);

  // trigger fetch next page
  const fetchNextPage = React.useCallback(() => setIsFetchingNextPage(true), [
    setIsFetchingNextPage,
  ]);

  // fetch previous page
  React.useEffect(() => {
    if (isFetchingPreviousPage)
      fetchCallback({ next_offset: prevOffSetRef.current })
        .then((response) => {
          setIsDataFromPreviousPage(true);
          setIsFetchingPreviousPage(false);
          setData([...response.data, ...data]);
          setPreviousOffset(response.prev_offset);
        })
        .catch((error) => error);
  }, [isFetchingPreviousPage]);

  // trigger fetch previous page
  const fetchPreviousPage = React.useCallback(() => {
    setIsFetchingPreviousPage(true);
  }, [setIsFetchingPreviousPage]);

  return {
    data,
    error,
    status,
    hasNextPage: !!offset,
    fetchNextPage,
    isFetchingNextPage,
    setData,
    fetchPreviousPage,
    hasPrevPage: !!prevOffset,
    isFetchingPreviousPage,
    isDataFromPreviousPage,
    setIsDataFromPreviousPage,
    total,
  };
}
