import "react-toastify/dist/ReactToastify.css";
import "./homePageModels.css";
import "react-lazy-load-image-component/src/effects/blur.css";

import { Button, IconButton } from "@material-ui/core";
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { isAndroid, isIOS } from "react-device-detect";
import {
  studioAtom,
  userTypeAtom,
} from "../../../stateManagement/atoms/studioAtom";
import { useHistory, useLocation } from "react-router-dom";

import AddIcon from "../../../assets/images/iphone-install-notification/add-icon.png";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CertifiedIcon from "../../../assets/images__v3/Certification_solid_ribbon.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import CommentModal from "../../commentsSection/CommentModal";
import FeetModelService from "../../../_services/feet-models.service";
import InstallIcon from "../../../assets/images/iphone-install-notification/install-icon.png";
import KebabMenuOptionsLovers from "../../loversComponents/KebabMenuOptionsLovers/KebabMenuOptionsLovers";
import LikeService from "../../../_services/like.service";
import LogoText from "../../../assets/images/logo-text@2x.png";
import MessagesIcon from "../../../assets/images/home-page-models/messages-icon.svg";
import PublicationMedia from "../../PublicationMedia";
import PublicationService from "../../../_services/publication.service";
import ReadMore from "../../ReadMore";
import Skeleton from "@material-ui/lab/Skeleton";
import comments from "../../../assets/images/menu/comment.svg";
import dateLocale from "../../../dateLocale.js";
import defaultAvatar from "../../../assets/images/generic-user-avatar.png";
// FAKEDATA NEED TO BE REMPLACED BY BACKEND ARRAY
import fakeCommentsData from "../../commentsSection/fakeCommentsData";
import feetModelAtom from "../../../stateManagement/atoms/feetModelAtom.js";
import { getAuthSession } from "../../../helpers/auth.helpers";
import heart from "../../../assets/images/menu/heart.svg";
import likedHeart from "../../../assets/images/notifications-models/likes.png";
import { makeStyles } from "@material-ui/core/styles";
import menuPoints from "../../../assets/images/menu/menu.svg";
import newMessageBadgeAtom from "../../../stateManagement/atoms/newMessageBadgeAtom";
import publicationItemAtom from "../../../stateManagement/atoms/publicationItemAtom";
import { saveComment } from "../../../_services/comment.service";
import { trackWindowScroll } from "react-lazy-load-image-component";
import useHandleClickChangeIcons from "../../layoutApp/shared/hooks/useHandleClickChangeIcons";
import useInfiniteQuery from "../../../hooks/useInfiniteQuery";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import { useRecoilState } from "recoil";
import useSessionStorage from "../../forms/services/storageHooks/useSessionStorage";
import { useTranslation } from "react-i18next";
import writeCommentsContentAtomAtom from "../../../stateManagement/atoms/writeCommentsContentAtom";
import tickles from "../../../assets/images/menu/tickles.svg";

const HomePageModels = (props) => {
  let history = useHistory();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const { clickedHome, setClickedHome } = useHandleClickChangeIcons();
  const alreadyInstall = "yes";
  const [_, setFeetModel] = useRecoilState(feetModelAtom);
  const [newMessageBadge] = useRecoilState(newMessageBadgeAtom);
  const [userType, setUserType] = useRecoilState(userTypeAtom);
  const [onStudio, setOnStudio] = useRecoilState(studioAtom);
  const [isPushNotification, setIsPushNotification] = useState(
    localStorage.getItem("isPush")
  );
  const [imgFilterStyleFormLocalStorage] = useState(
    JSON.parse(localStorage.getItem("stylePublication"))
  );

  const [authUser] = useSessionStorage({}, "infosUser");
  const [refetch, setRefetch] = useState(0);

  const {
    data,
    total,
    error,
    status,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    "home-page-models-publications",
    async ({ next_offset = 0 }) => {
      const response = await FeetModelService.getFeetModelsPublications(
        next_offset
      );

      return {
        data: response.data.publications,
        next_offset: response.data.next_offset,
        total: response.data.meta.count,
      };
    },
    refetch
  );

  const loadMorePublicationsReference = useRef();

  useIntersectionObserver({
    target: loadMorePublicationsReference,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  useEffect(() => {
    const lang = localStorage.getItem("language");
    const info = {
      ...authUser,
      user: {
        ...authUser.user,
        preferredLanguage: lang,
      },
    };
    localStorage.setItem("infosUser", JSON.stringify(info));
  }, []);

  useEffect(() => {
    if (location) setRefetch(refetch + 1);
    if (location.pathname === "/home-page-models" && total === 0)
      history.push("/home-lover-first-login");
  }, [location, total, history]);

  useEffect(() => {
    setUserType("Model");
    setOnStudio(false);
    setClickedHome(true);
  }, [setUserType, setOnStudio, setClickedHome]);

  useEffect(() => {
    if (isAndroid === true && isPushNotification) {
      // alert("This is an Android")
      Notification.requestPermission((result) => {
        if (result === "granted") {
          showNotification("Bienvenue sur l'application Feety!");
        }
      });

      function showNotification(title, message) {
        if ("Notification" in window) {
          navigator.serviceWorker.ready.then((registration) => {
            registration.showNotification(title, {
              body: message,
              tag: "vibration-sample",
            });
          });
        }
      }
    }
    if (isIOS === true) {
      //  alert("it's an IPhone")
      const Msg = () => (
        <div className="messageIPhoneContent">
          <div className="firstColumn">
            <img src={AddIcon} alt="add icon" />
            <b style={{ color: "black" }}>
              Install this WebApp on your IPhone:{" "}
            </b>
            <br />
          </div>
          <div className="secondColumn">
            <b>tap</b>
            <img src={InstallIcon} alt="install icon" />
            <b> and then Add to homescreen</b>
          </div>
          <div className="alreadyInstalled">
            <b>
              {t("If you have already installed the app, close this message")}
            </b>
          </div>
        </div>
      );
      if (localStorage.getItem("alreadyInstalled") === null) {
        // Function for displaying notification to screen
        const displayMsg = () => {
          toast(<Msg />);
        };
        displayMsg();
      }
    }
  }, [isPushNotification]);

  const handleClickViewFeetModel = (user) => {
    if (authUser.user._id === user._id) {
      history.push("/view-own-profile-models");
    } else {
      setFeetModel(user);
      history.push({
        pathname: "/feet-models-view",
        search: `?user=${user._id}`,
      });
    }
  };

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  return (
    <div className="homePageModels__container">
      <ToastContainer
        position="top-center"
        autoClose={false}
        hideProgressBar={true}
        newestOnTop={true}
        // closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 999, top: "5em" }}
        onClick={() => localStorage.setItem("alreadyInstalled", alreadyInstall)}
      />
      <div className="homePageModels__header header page_fixed_header">
        <img src={LogoText} alt="" className="logo" />

        {userType !== "Lover" ? (
            <IconButton
                className="homePageModels__messages header__rightButtton"
                onClick={() => history.push("/messages")}
            >
              <img src={MessagesIcon} alt="message icon" />
              {newMessageBadge && (
                  <span className="homePageModels__onMessages"></span>
              )}
            </IconButton>
        ) : null}
      </div>

      <div className="feet_grid_list">
        {status === "loading" ? (
          <HomePageSkeleton />
        ) : status === "error" ? (
          <span>Error: {error.message}</span>
        ) : (
          <>
            {data.map((publication) => (
              <Publication
                key={publication._id}
                id={publication._id}
                publication={publication}
                i18n={i18n}
                t={t}
                handleClickViewFeetModel={handleClickViewFeetModel}
                history={history}
                setRefetch={setRefetch}
                refetch={refetch}
              />
            ))}
            <div className="space-filler post-item"></div>
            <div className="space-filler post-item"></div>
            <div className="space-filler post-item"></div>
          </>
        )}
      </div>
      <div ref={loadMorePublicationsReference}>
        {isFetchingNextPage ? (
          <CircularProgress color="secondary" size={10} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const Publication = trackWindowScroll(
  // eslint-disable-next-line react/display-name
  React.memo(
    ({
      id,
      publication: publicationData,
      i18n,
      t,
      handleClickViewFeetModel,
      history,
      scrollPosition,
      setRefetch,
      refetch
    }) => {
      const [isDisplayCommentsList, setIseDisplayCommentsList] = useState(
        false
      );
      // The content of comment textArea from Atom state
      const [writeComment, setWriteComment] = useState(false);
      // For displaying writing comment message component
      const [isWriteComment, setIsWriteComment] = useRecoilState(
        writeCommentsContentAtomAtom
      );
      const [authUser] = useSessionStorage({}, "infosUser");

      const [addComment, setAddComment] = useState(fakeCommentsData);
      const [openProfile, setOpenProfile] = useState(false);
      const [picties, setPicties] = useState([]);

      // publication state
      const [publication, setPublication] = useRecoilState(
        publicationItemAtom({
          id,
          publication: {
            ...publicationData,
            heart: publicationData.isLiked
              ? likedHeart
              : heart,
          },
        })
      );

      useEffect(() => {
        if (publicationData && publicationData.picties) {
          setPicties(publicationData.picties)
        }
      }, [publicationData]);

      const handleLikeOrUnlikePublication = () => {
        // DELETE :: unlike publication endpoint
        if (
          publication.isLiked
        ) {
          LikeService.unLikePublication({
            publication: publication._id,
          })
            .then((result) => result)
            .catch((error) => error);

          setPublication((item) => ({
            ...item,
            likesCount: item.likesCount - 1,
            heart,
            isLiked: false
          }));
        } else {
          // POST :: like publication endpoint
          LikeService.likePublication({
            publication: publication._id,
          })
            .then((result) => result)
            .catch((error) => error);

          setPublication((item) => ({
            ...item,
            likesCount: item.likesCount + 1,
            heart: likedHeart,
            isLiked: true
          }));
        }
      };

      const handleDisplayCommentsList = (publication) => {
        /* Old implementation by rodolphe */
        // if (isDisplayCommentsList) {
        //   setIseDisplayCommentsList(false);
        // }
        // if (!isDisplayCommentsList) {
        //   setIseDisplayCommentsList(true);
        // }

        history.push({
          pathname: `/publication/${publication._id}/comments`,
        });
      };

      const handleDisplayLikersList = (publication) => {
        history.push({
          pathname: `/publication/${publication._id}/likers`,
        });
      };

      // For displaying writing comment message component
      const handleDisplayCommentModal = () => {
        if (writeComment) {
          setWriteComment(false);
        }
        if (!writeComment) {
          setWriteComment(true);
        }
      };

      // For displaying all comments component
      const handleSendComment = async (_publication) => {
        if (isWriteComment !== "") {
          await saveComment({
            content: isWriteComment,
            publication: _publication.id,
          });
          // setIsWriteComment("");
          history.push({
            pathname: `/publication/${_publication._id}/comments`,
          });
        }
        if (!isDisplayCommentsList) {
          setWriteComment(false);
          setIseDisplayCommentsList(true);
        }
        if (isDisplayCommentsList) {
          setWriteComment(false);
        }
        /*
                                        NEED TO SEND one object like this in the comments array (backend)
                                        {
                                          id: ???,
                                          from: ???,
                                          to: publication.user,
                                          hour: ???,
                                          content: isWriteComment,
                                        },
                                        */
      };

      return (
        <div key={publication._id} className="post-item">
          <div
            className={
              publication.user.online
                ? "post-header"
                : "post-header hpost-offfline"
            }
          >
            <div className="post-user-details">
              <div className="post-user-avatar">
                <span
                  className={
                    publication.user.online
                      ? "allMessages__online post-online"
                      : ""
                  }
                ></span>
                <img
                  className="post-user-image"
                  src={
                    publication.user.image
                      ? publication.user.image.url
                      : defaultAvatar
                  }
                  alt="avatar"
                />
              </div>
              <div
                className="homePage__user__details"
                onClick={() => handleClickViewFeetModel(publication.user)}
              >
                <div className="homePage__user__details_username">
                  <p className="homePage__user__details_username_text">
                    {publication.user.username}
                  </p>
                  {publication?.user?.certified ? (
                    <img
                      src={CertifiedIcon}
                      alt="tickles"
                      width="16"
                      height="16"
                    />
                  ) : (
                    ""
                  )}
                </div>

                {/* ambassadress */}
                {publication.user?.ambassadress === true ? (
                  <p className="homePage__user__details_username_text_ambassadress">
                    {t("AmbassadressLabel")}
                  </p>
                ) : (
                  ""
                )}

                <sub style={{ fontSize: "12px", color: "#646464" }}>
                  {dateLocale.localize(publication.createdAt).fromNow()}
                </sub>
              </div>
            </div>
            <IconButton onClick={() => setOpenProfile(true)}>
              <img className="post-header-menu" src={menuPoints} alt="menu" />
            </IconButton>
          </div>
          <div className="post-picture">
            <PublicationMedia
              publication={publication}
              width="100%"
              effect="blur"
              height="440px"
              alt="publication"
              scrollPosition={scrollPosition}
              onClick={() => {
                history.push({
                  pathname: `/publication/${publication._id}`,
                });
              }}
            />
          </div>
          <div className="post-bottom">
            <div
              className={
                i18n.languages === "en"
                  ? "action__buttons homePageModels__stickers"
                  : "action__buttons homePageModelsFr__stickers"
              }
            >
              {authUser.user._id === publication.user._id ? (
                <Button>
                  <img src={tickles} alt="tickles" />
                  <sub>{`${publication.ticklesCount} ${t("feetGirlGuideTicklesTitle")}`}</sub>
                </Button>
              ) : null}
              <Button>
                <img
                  className={
                    publication.isLiked ? "fullHeart" : "emptyHeart"
                  }
                  key={publication.heart}
                  src={publication.heart}
                  alt="heart"
                  onClick={() => handleLikeOrUnlikePublication()}
                />
                <sub onClick={() => handleDisplayLikersList(publication)}>
                  {publication.likesCount} {t("likesHomePageLover")}
                </sub>
              </Button>
              <Button
                onClick={handleDisplayCommentModal}
                className="commentButton"
              >
                <img src={comments} alt="comments" />
                <sub>
                  {publication.commentCount} {t("commentHomePageLover")}
                </sub>
              </Button>
            </div>
            <div className="post-bottomContent">
              <div className="post-bottomText">
                <ReadMore>{{ ...publication, picties }}</ReadMore>
              </div>
              <div className="post-comments">
                {publication.commentCount > 0 ? (
                  <sub onClick={() => handleDisplayCommentsList(publication)}>
                    {t("viewCommentsHomePageLover")} {publication.commentCount}{" "}
                    {t("commentHomePageLover")}
                  </sub>
                ) : null}
                {writeComment ? (
                  <CommentModal
                    handleSendComment={() => handleSendComment(publication)}
                  />
                ) : (
                  ""
                )}
                {/* {isDisplayCommentsList
                  ? history.push({
                      pathname: `/publication/${publication._id}/comments`,
                
                    })
                  : ""} */}
              </div>
            </div>
            <hr className="post-hr" />
          </div>

          <KebabMenuOptionsLovers
            publication={publication}
            user={publication.user}
            open={openProfile}
            setOpen={setOpenProfile}
            setRefetch={setRefetch}
            refetch={refetch}
          />
        </div>
      );
    }
  )
);

Publication.displayName = "Publication";

const HomePageSkeleton = () => {
  const classes = useStyles();
  return Array.from(new Array(2)).map((item, index) => (
    <Card className={classes.card} key={index}>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div style={{ flexBasis: "90%" }}>
          <CardHeader
            avatar={
              <Skeleton
                animation="wave"
                variant="circle"
                width={40}
                height={40}
              />
            }
            title={
              <Skeleton
                animation="wave"
                height={10}
                width="80%"
                style={{ marginBottom: 6 }}
              />
            }
            subheader={<Skeleton animation="wave" height={10} width="40%" />}
          />
        </div>
        <div
          style={{
            flexBasis: "10%",
            alignSelf: "center",
          }}
        >
          <Skeleton
            animation="wave"
            height={15}
            width="70%"
            style={{ marginBottom: "15px" }}
          />
        </div>
      </div>
      {<Skeleton animation="wave" variant="rect" className={classes.media} />}
      <CardContent>
        {
          <>
            <div style={{ display: "flex" }}>
              <Skeleton
                animation="wave"
                height={20}
                width="20%"
                style={{ marginBottom: "10px", marginRight: "10px" }}
              />
              <Skeleton
                animation="wave"
                height={20}
                width="20%"
                style={{ marginBottom: "10px", marginRight: "10px" }}
              />
            </div>
            <>
              <Skeleton
                animation="wave"
                height={10}
                style={{ marginBottom: 6 }}
              />
              <Skeleton animation="wave" height={10} width="80%" />
            </>
          </>
        }
      </CardContent>
    </Card>
  ));
};

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: "100%",
    margin: theme.spacing(2),
  },
  media: {
    minHeight: 400,
  },
}));

export default HomePageModels;
