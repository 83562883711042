import "./createMessage.css";
import "react-toastify/dist/ReactToastify.css";

import { Avatar, Button, IconButton } from "@material-ui/core";
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  sendedTicklesAtom,
  userTypeAtom,
} from "../../../../stateManagement/atoms/studioAtom";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import Alert from "@material-ui/lab/Alert";
import BlockService from "../../../../_services/block.service";
import { ChevronLeft } from "@material-ui/icons";
import Heart from "../../../../assets/images/messages-section/Heart Line.png";
import MessageContentBottom from "./messagesAllComponents/MessageContentBottom";
import MessageContentCenter from "./messagesAllComponents/MessageContentCenter";
import MessageContentTop from "./messagesAllComponents/MessageContentTop";
import MessageService from "../../../../_services/message.service";
import NoMess from "../../../../assets/images/messages-section/Group 4299@2x.png";
import Picture from "../../../../assets/images/messages-section/Path 199724@2x.png";
import PictureModal from "../pictureModal/PictureModal";
import { SocketContext } from "../../../../context/socket";
import TicklesIcon from "../../../../assets/images/tickles/Intersection 3.png";
import VideoPreviewModal from "../../../Modals/VideoPreviewModal";
import { isEmpty as _isEmpty } from "lodash";
import crypto from "crypto-js";
import feetModelAtom from "../../../../stateManagement/atoms/feetModelAtom";
import genericAvatar from "../../../../assets/images/generic-user-avatar.png";
import { imagetoblob } from "../../../../helpers";
import newMessageBadgeAtom from "../../../../stateManagement/atoms/newMessageBadgeAtom";
import picturesMessagesAtom from "../../../../stateManagement/atoms/picturesMessagesAtom";
import receiveMediasMessageToUserAtom from "../../../../stateManagement/atoms/receiveMediasMessageToUserAtom";
import sendedHeartAtom from "../../../../stateManagement/atoms/sendedHeartAtom";
import ticklesAtom from "../../../../stateManagement/atoms/ticklesAtom";
import ticklesLogo from "../../../../assets/images/tickles/Intersection 3@2x.png";
import { useDropzone } from "react-dropzone";
import useMessageOrSpeech from "./hooks/useMessageOrSpeech";
import useSessionStorage from "../../../forms/services/storageHooks/useSessionStorage";
import { useTranslation } from "react-i18next";
import FeetModelService from "../../../../_services/feet-models.service";
import useNotificationAlert from "../../../../hooks/useNotificationAlert";
import TicklePricesService from "../../../../_services/tickle-prices.service";
import nanoMetadata from "nano-metadata";
import {uploadMultipleMedias} from "../../../../_services/firebase.service";

const CreateMessage = ({ e }) => {
  const location = useLocation();
  let history = useHistory();
  const socket = useContext(SocketContext);
  const { i18n, t } = useTranslation();

  const { addNotificationMessage } = useNotificationAlert();
  const [_, setNewMessageBadge] = useRecoilState(newMessageBadgeAtom);
  const [__, setFeetModel] = useRecoilState(feetModelAtom);
  const [authUser] = useSessionStorage({}, "infosUser");
  const [ticklesIHave, setTicklesIHave] = useRecoilState(ticklesAtom);
  const { ArrayMessagesList, setArrayMessagesList } = useMessageOrSpeech();
  const [numberTickles, setNumberTickles] = useState(
    window.localStorage.getItem("numberTickles")
  );
  const [messageUploadFile, setMessageUploadFile] = useState(
    window.localStorage.getItem("message-upload-file")
  );
  const [countMessages, setCountMessages] = useState(0);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
  const [showVideoThumb, setShowVideoThumb] = useState(false);
  const [videoFileName, setVideoFileName] = useState("");
  const [epochProductLists, setEpochProductLists] = useState([]);

  const [play, setPlay] = useState(false);
  const [sendedTickles, setSendedTickles] = useRecoilState(sendedTicklesAtom);
  const [picturesMessage, setPicturesMessage] = useRecoilState(
    picturesMessagesAtom
  );
  const userType = useRecoilValue(userTypeAtom);
  const [isReceiveMediaToUser] = useRecoilState(receiveMediasMessageToUserAtom);
  const {
    speechToText,
    setSpeechToText,
    inputValue,
    setInputValue,
    sendMess,
    setSendMess,
    sendedMess,
    setError,
    error,
    setSendedMess,
    clickedStop,
    setClickedStop,
    speechClicked,
    setSpeechClicked,
    handleSendMessages,
    handleSendedTickles,
    handleSendCMNotif,
  } = useMessageOrSpeech();

  // decrypt private conversation data from local storage. The private conversation data from local storage is needed specially when redirected from epoch payment gateway after purchasing of tickles.
  const decryptPrivateConversationData =
    localStorage.getItem("private_conversation_data") &&
    crypto.AES.decrypt(
      localStorage.getItem("private_conversation_data"),
      process.env.REACT_APP_API_PRIVATE_CONVERSATION_ENCRYPT_SECRET_KEY
    );

  const [receiverInfo] = useSessionStorage({}, "user_receiver_info");

  useEffect(() => {
    if (_isEmpty(receiverInfo)) {
      localStorage.setItem(
        "user_receiver_info",
        JSON.stringify(location.state?.receiver, null, 2)
      );
    }
  }, []);

  const decryptedPrivateConversationData =
    decryptPrivateConversationData &&
    JSON.parse(decryptPrivateConversationData.toString(crypto.enc.Utf8));

  const convoId =
    history?.location?.convoId || history.location.pathname.split("/")[2];

  const receiver =
    location.state?.receiver ||
    decryptedPrivateConversationData?.receiver ||
    receiverInfo;

  const fromFavorite =
    location.state?.fromFavorite ||
    decryptedPrivateConversationData?.fromFavorite;

  const [isBlockedByAuthenticatedUser, setIsBlockedByAuthenticatedUser] =
    useState(false);

  const fakeReceivedTickles = () => {
    if (userType === "Model") {
      setNumberTickles(localStorage.getItem("numberTickles"));
      setSendedTickles(true);
    }
  };

  const EraseFakeTickles = () => {
    if (userType === "Model") {
      setSendedTickles(false);
    }
  };

  function clear() {
    document.getElementById("mess-input").value = "";
  }

  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [fileToLocalStorage, setFileToLocalStorage] = useState([]);
  const [acceptFiles, setAcceptFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [urls, setURLs] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    noDrag: false,
    id: "file",
    type: "file",
    // accept: "image/jpeg, image/png, image/jpg, image/svg, video/*",
    onDrop: (acceptedFiles) => {
      setIsUploading((isUploading) => !isUploading);
      // setFiles(
      //   acceptedFiles.map((file) =>
      //     Object.assign(file, {
      //       previewMessage: URL.createObjectURL(file),
      //     })
      //   )
      // );
      /**
       *  Socket send message.
       *  1. handle upload file.
       *  2. send uploaded file url as content to send message via socket.
       */
      if (isReceiveMediaToUser) {
        setAcceptFiles(acceptedFiles)
        handleValidatingVideo(acceptedFiles)
      }
    },
  });

  const handleValidatingVideo = (acceptedFiles) => {
    const filesWithDuration = acceptedFiles.map((file) => {
      if (file && (file.type.includes("image"))) {
        return new Promise((resolve, reject) => {
          resolve({duration: null, files: file})
        })
      } else {
        return new Promise((resolve, reject) => {
          // nanoMetadata.video.duration(file).then((duration) => {
          //   console.log(duration) // will show you video duration in seconds
            resolve({duration: null, files: file})
          // })
          // setTimeout(reject, 1000);
        })
      }
    })
    Promise.all(filesWithDuration).then(async (results) => {
      console.log("duration: ", results)
      let proceed = false;
      let approvedFile = [];
      results.map((file) => {
          if (file && (file.files.type.includes("image") || (file.files.type.includes("video")))) {
            proceed = true;
            approvedFile.push(file);
            // addNotificationMessage("Uploaded Successfully!", "info");
          } else {
            addNotificationMessage(t("ppUploadingValidateCMError"), "error");
            setIsUploading(false);
            setIsMessageSent(false);
            setURLs([]);
            approvedFile = [];
            proceed = false;
          }
      })
      if (proceed === true && approvedFile.length === acceptedFiles.length) {
        console.log("cm_progress: ", progress)
        await uploadMultipleMedias(approvedFile, setProgress, uploadWithFirebase, 'message')
      }
    }).catch((e) => {
      console.log("Upload Chat Media Error: Video has a bad format!", e)
      setIsUploading(false);
      setIsMessageSent(false);
      setURLs([]);
      addNotificationMessage(t("ppUploadingBadFormatError"), "error");
    })
  }

  useEffect(() => {
    if ((urls.length > 0 && acceptFiles.length > 0)) {
      updateCM(true, urls).then()
    }
  }, [urls, acceptFiles])

  const uploadWithFirebase = async (responseUrl) => {
    console.log("messageCenter_responseUrl: ", responseUrl)
    if (responseUrl && responseUrl.length > 0) {
      responseUrl.map((result_url) => {
        if (result_url.error === false) {
          setURLs((url) => [...url, result_url.formatted_path])
        } else {
          addNotificationMessage(`${t("ppUploadingValidateCMError")} Filename: ${result_url.formatted_path}`, "error");
          setIsUploading(false);
        }
      })
    }
  }

  const updateCM = async (success, url) => {
    if (success) {
      url.map(async (file) => {
        // if (file && (file.type.includes("image") || file.type.includes("video"))) {
          try {
            const array = [];
            array.push(file);
            const data = {
              urls: array,
              type: 'message',
              recipient: receiver._id
            }
            // new FormData();
            // data.append('image', file);
            // data.append('type', 'message');
            // data.append('recipient', receiver._id);

            const response = await MessageService.uploadMessage(data);
            const image = response.data.data.reduce((media) => media);
            setArrayMessagesList((data) =>
                data.concat({
                  from: authUser.user,
                  to: receiver._id,
                  type: "Files",
                  content: image.content,
                  createdAt: new Date().toISOString(),
                  updatedAt: new Date().toISOString(),
                  status: "pending",
                })
            );
            // socket.sendMessage(
            //   {
            //     content: response.data.previewUrl,
            //     from: authUser.user._id,
            //     to: receiver._id,
            //     type: "Files",
            //   },
            //   (err, result) => {
            //     if (!err) {
            //       setArrayMessagesList((data) =>
            //         data.concat({
            //           from: authUser.user,
            //           to: receiver._id,
            //           type: "Files",
            //           content: response.data.previewUrl,
            //           createdAt: new Date().toISOString(),
            //           updatedAt: new Date().toISOString(),
            //           status: "pending",
            //         })
            //       );
            //     } else {
            //       setError(err.message);
            //     }
            //  }
            //);

            setIsUploading(false);
            setIsMessageSent((isMessageSent) => !isMessageSent);
          } catch (error) {
            setIsUploading((isUploading) => !isUploading);
            setIsMessageSent((isMessageSent) => !isMessageSent);
            console.log("ERROR POST /v1/messages/upload-files: ", error);
          }
        // } else {
        //   setIsUploading(false);
        //   setIsMessageSent(false);
        //   console.log("ERROR INVALID FORMAT");
        // }
      });
    }

    setFileToLocalStorage(
        acceptFiles.map((file) =>
            Object.assign(file, {
              previewMessage: URL.createObjectURL(file),
            })
        )
    );
    setPicturesMessage((picturesMessage) =>
        picturesMessage.concat(
            acceptFiles.map((file) => file.previewMessage)
        )
    );
    setSendedMess(true);
    setIsMessageSent((isMessageSent) => !isMessageSent);
    setURLs([]);
  }

  window.localStorage.setItem(
    "previewMessage",
    fileToLocalStorage.map((file) => file.previewMessage)
  );

  const [sendedHeart, setSendedHeart] = useRecoilState(sendedHeartAtom);
  const handleHeart = (userRecipient) => {
    if (isReceiveMediaToUser) {
      // if (sendedHeart === "") {
        // setSendedHeart("heartSending");
        // setSendedMess(true);

        socket.sendMessage(
          {
            from: authUser.user._id,
            to: receiver._id,
            type: "Heart",
          },
          (err, result) => {
            if (!err) {
              setArrayMessagesList((data) =>
                data.concat({
                  from: authUser.user,
                  to: receiver._id,
                  type: "Heart",
                  createdAt: new Date().toISOString(),
                  updatedAt: new Date().toISOString(),
                })
              );
              setSendedHeart("heartSending");
            } else {
              setError(err.message);
              setSendedHeart("heartSending");
            }
          }
        );
        
        setSendedHeart("");
        setTimeout(() => {
          setSendedHeart("");
        }, 2000);
      // } else {
      //   setSendedHeart("");
      // }
    }
  };
  //

  const [sendingTicklesMessage, setSendingTicklesMessage] = useState("");

  const handleTicklesMessages = () => {
    if (sendedTickles === true) {
      setSendingTicklesMessage("sendingTickles");
    } else {
      setSendingTicklesMessage("");
    }
  };

  const MessagePict = fileToLocalStorage.map((file) => file.previewMessage);

  const reSendHeart = () => {
    setSendedHeart(false);
    setTimeout(() => {
      setSendedHeart(true);
    }, 10);
  };

  // send message file from camera
  useEffect(() => {
    if (location && location.state && location.state.sendMessageUpload) {
    
      async function sendFile() {
        try {
          const data = new FormData();
          data.append('image', file);
          data.append('type', 'message');
          data.append('recipient', receiver._id);

          const response = await MessageService.uploadMessage(data);
          const image = response.data.data.reduce((media) => media);
          setArrayMessagesList((data) =>
            data.concat({
              from: authUser.user,
              to: receiver._id,
              type: "Files",
              content: image.content,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
            })
          );

          // socket.sendMessage(
          //   {
          //     content: response.data.previewUrl,
          //     from: authUser.user._id,
          //     to: receiver._id,
          //     type: "Files",
          //   },
          //   (err, result) => {
          //     if (!err) {
          //       setArrayMessagesList((data) =>
          //         data.concat({
          //           from: authUser.user,
          //           to: receiver._id,
          //           type: "Files",
          //           content: response.data.previewUrl,
          //           createdAt: new Date().toISOString(),
          //           updatedAt: new Date().toISOString(),
          //         })
          //       );
          //     } else {
          //       setError(err.message);
          //     }
          //   }
          // );
        } catch (error) {
          console.log("ERROR POST /v1/messages/upload-files: ", error);
        }
      }
      sendFile();
    }
  }, [location, socket]);

  useEffect(() => {
    if (error) {
      toast(error);
      // setTimeout(() => {
      //   history.goBack();
      // }, 3000);
    }
  }, [error]);

  // on mount create message route
  useEffect(() => {
    // socket trigger to inform backend that the feet model open the private conversation; this is for possible tickles sent and update status to pending
    if (authUser && authUser.user && authUser.user.userType === "Model") {
      if (
        history.location.state &&
        history.location.state.socketSendOnOpenConversation
      ) {
        // socket will fail on reload since socket is not initialized yet because this component will render first before the Main APP which has the socket init.
        socket.sendOnOpenPrivateConversation(
          {
            feetModel: authUser.user._id,
            feetLover: receiver._id,
          },
          (err, result) => {
            if (!err) {
              console.log("response on open private conversation:", result);
            } else {
              console.log("error on open private conversation: ", err);
            }
          }
        );

        const state = {
          ...history.location.state,
          socketSendOnOpenConversation: false, // fix: identifier not to use socket send on open private conversation after reload or refresh
        };

        history.replace({ ...history.location, state });
      }
    }

    return () => {};
  }, []);

  // maybe trigger this if previous route is from /messages?

  // on mount receiver -> check if authenticated user blocked the receiver.
  useEffect(() => {
    if (receiver) {
      BlockService.findBlockUser(receiver._id)
        .then((response) => {
          if (response && response.block) {
            setIsBlockedByAuthenticatedUser(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [receiver]);

  useEffect(() => {
    return () => {
      setNewMessageBadge(false);
    };
  }, [setNewMessageBadge]);

  useEffect(() => {
    (async () => {
      const response = await TicklePricesService.getEpochProductLists();
      setEpochProductLists(response.data);
    })();
  }, [authUser]);

  const showVideoModal = (fsUrl, status) => {
    setVideoFileName(fsUrl);
    setPlay(true);
    if (authUser && authUser.user) {
      authUser.user.userType === "Model"
        ? setIsVideoModalVisible(true)
        : status === "approved" && setIsVideoModalVisible(true);
    }
  };

  const handleCancelVideo = async () => {
    await setPlay(false);
    setVideoFileName("");
    setIsVideoModalVisible(false);
  };

  return (
    <div className="createMessage__container">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{top: "5em"}}
      />
      <div className="createMessage__header header page_fixed_header">
        <IconButton
          onClick={() => {
            history.push({
              pathname: "/messages",
              state: {
                fromFavorite,
              },
            });
          }}
          className="back__button"
        >
          <ChevronLeft />
        </IconButton>
        <h4>Messages</h4>
      </div>
      <div className="createMessage__content content__withHeader">
        <MessageContentTop
          userType={userType}
          authUser={authUser}
          i18n={i18n}
          Avatar={Avatar}
          user={receiver}
          genericAvatar={genericAvatar}
          history={history}
          TicklesIcon={TicklesIcon}
          setFeetModel={setFeetModel}
          countMessages={countMessages}
          epochProducts={epochProductLists}
        />
        <div className="createMessage__contentMessage">
          <MessageContentCenter
            sendedTickles={sendedTickles}
            clickedStop={clickedStop}
            speechToText={speechToText}
            MessagePict={MessagePict}
            PictureModal={PictureModal}
            reSendHeart={reSendHeart}
            sendMess={sendMess}
            sendedMess={sendedMess}
            fileToLocalStorage={fileToLocalStorage}
            sendedHeart={sendedHeart}
            fakeReceivedTickles={fakeReceivedTickles}
            NoMess={NoMess}
            t={t}
            convoId={convoId}
            user={receiver}
            userType={userType}
            authUser={authUser}
            numberTickles={numberTickles}
            EraseFakeTickles={EraseFakeTickles}
            ticklesLogo={ticklesLogo}
            picturesMessage={picturesMessage}
            setPicturesMessage={setPicturesMessage}
            setCountMessages={setCountMessages}
            showVideoModal={showVideoModal}
            NewMessagesList={ArrayMessagesList}
            isMessageSent={isMessageSent}
            handleSendCMNotif={handleSendCMNotif}
            epochProducts={epochProductLists}
          />
        </div>
        {error !== "" && <Alert severity="error">{error}</Alert>}
        <MessageContentBottom
          sendMess={sendMess}
          getRootProps={getRootProps}
          setClickedStop={setClickedStop}
          handleHeart={handleHeart}
          Heart={Heart}
          Button={Button}
          getInputProps={getInputProps}
          IconButton={IconButton}
          inputValue={inputValue}
          speechToText={speechToText}
          setInputValue={setInputValue}
          disable={error !== ""}
          t={t}
          Fragment={Fragment}
          Picture={Picture}
          clickedStop={clickedStop}
          handleSendMessages={handleSendMessages}
          toUser={receiver}
          isBlockedByAuthenticatedUser={isBlockedByAuthenticatedUser}
          setIsBlockedByAuthenticatedUser={setIsBlockedByAuthenticatedUser}
          authUser={authUser}
          TicklesIcon={TicklesIcon}
          handleTicklesMessages={handleTicklesMessages}
          user={receiver}
          handleSendedTickles={handleSendedTickles}
          isUploading={isUploading}
          epochProducts={epochProductLists}
        />
      </div>
      {/* Video Preview Modal  */}
      <VideoPreviewModal
        isVideoModalVisible={isVideoModalVisible}
        handleCancelVideo={handleCancelVideo}
        videoFileName={videoFileName}
        play={play}
      />
      {/* Video Preview Modal  */}
    </div>
  );
};

export default CreateMessage;
