import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Roboto",

    "& .change-feet-model-subscription-amount-content-container": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "23px 10px",

      "& h3": {
        textAlign: "center",
      },

      "& h4": {
        textAlign: "center",
      },
    },

    "& .epoch-subscription-product-lists": {
      display: "flex",
      gap: "10px",
      flexWrap: "wrap",
      "justify-content": "center",
      margin: "70px 0 40px",
    },

    "& .epoch-subscription-product-wrapper": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: "0 0 23.3333333333%",

      "& .popular-message": {
        color: "#656565",
        fontSize: "14px",
        fontStyle: "italic",
        fontFamily: "Roboto",
        marginTop: "4px",
        textAlign: "center",
      },

      "& .popular-text-container": {
        height: "30px",
        width: "103px",
        "border-radius": "7px",
        padding: "3px 5px",
        position: "relative",
        bottom: "-2px",
      },

      "& .popular-text": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        backgroundColor: "#E54E70",
        fontSize: "18px",
        fontWeight: "500",
      },
    },

    "& .epoch-subscription-amount-wrapper": {
      width: "111px",
      height: "158px",
      backgroundColor: "white",
      border: "2px solid #C8C8C8",
      "border-radius": "18px",
      display: "flex",
      "justify-content": "center",
      "align-items": "center",

      "& span": {
        "text-align": "center",

        "& .amount": {
          "font-size": "18px",
          "font-weight": "700",
        },
      },
    },

    "& .popular-subscription-product": {
      "border-color": "#FF5768",
    },

    "& .selected-subscription-product": {
      backgroundColor: "#E54E70",
      borderColor: "#E54E70",
    },

    "& .button-test": {
      "flex-grow": 1,
      display: "flex",
      "justify-content": "flex-end",
      "align-items": "flex-start",
    },
  },
}));
