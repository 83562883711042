/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import { has as _has } from "lodash";

import "./dropzoneComponent.css";
import setIsFromCameraImage from "../../../stateManagement/selectors/publication/setIsFromCamera";
import useNotificationAlert from "../../../hooks/useNotificationAlert";
import uploadFilePublicationSelector from "../../../stateManagement/selectors/publication/setUploadFile";
import { StyledBadge } from "../../../pages/EditProfile/FeetModel/styled";
import { BottomNavigationAction } from "@material-ui/core";
import { getAuthSession } from "../../../helpers/auth.helpers";

export default function DropzoneComponent({
  isConfirm,
  clickedStudio,
  handleClickStudio,
  setOpenModal,
  dontShowRules,
}) {
  let history = useHistory();
  const inputRef = useRef(null);
  const { addNotificationMessage } = useNotificationAlert();

  const setIsFromCameraPublicationImage = useSetRecoilState(
    setIsFromCameraImage
  );

  const setUploadFilePublication = useSetRecoilState(
    uploadFilePublicationSelector
  );

  const [files, setFiles] = useState([]);
  const [fileToLocalStorage, setFileToLocalStorage] = useState([]);
  const [fileBase64Media] = useState(window.localStorage.getItem("resultPost"));
  const { getRootProps, getInputProps } = useDropzone({
    noDrag: false,
    multiple: false,
    maxFiles: 1,
    id: "file",
    type: "file",
    // accept: "image/jpeg, image/png",
    // accept: "image/*, video/*",
    getFilesFromEvent: async (event) => {
      const fileList = event.dataTransfer
        ? event.dataTransfer.files
        : event.target.files;
        
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);

        // current identifier if taken from camera is if file.name is one of the ff:
        // "IMG_"
        // "image"
        const isFromCamera = !!(
          file.name &&
          (file.name.includes("IMG_") || file.name.includes("image"))
        );

        // identifier if file taken from camera or library
        Object.defineProperty(file, "fromCamera", {
          value: isFromCamera,
        });

        if (file && (file.type.includes("image") || file.type.includes("video"))) {
          files.push(file);
        } else {
          setFiles([])
          setFileToLocalStorage([])
          return;
        }
      }

      return files;
    },
    onDrop: async (acceptedFiles) => {
      // function isVideo(file) {
      //   return !!file.type.match("video.*");
      // }

      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setFileToLocalStorage(
        acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        })
      );

      /* Old implementation. This has the crop and filter option... */
      // history.push("/hello-picture");

      /* New implementation. Without crop and filter option... */
      // if (acceptedFiles && acceptedFiles.length && isVideo(acceptedFiles[0])) {
      //   // update global state for upload publication file
      //   setUploadFilePublication(acceptedFiles[0]);
      //   history.push(`/post-publication`);
      // } else {
      //   setUploadFilePublication(null);
      //   history.push("/hello-picture");
      // }

      if (acceptedFiles && acceptedFiles.length) {
        // update global state for upload publication file
        // setUploadFilePublication(acceptedFiles[0]);
        history.push({
          pathname: '/post-publication',
          state: files
        });
        setFiles([]);
        setFileToLocalStorage([]);
      }
    },
  });

  window.localStorage.setItem(
    "preview",
    fileToLocalStorage.map((file) => file.preview)
  );

  useEffect(() => {
    if (fileToLocalStorage.length > 0) {
      if (_has(fileToLocalStorage[0], "fromCamera"))
        setIsFromCameraPublicationImage(fileToLocalStorage[0].fromCamera);
    }
  }, [fileToLocalStorage, setIsFromCameraPublicationImage]);

  // useEffect(() => () => {
  //   // Make sure to revoke the data uris to avoid memory leaks
  //   files.forEach(file => URL.revokeObjectURL(file.preview));
  // }, [files]);

  useEffect(() => {
    if (isConfirm > 0) inputRef.current.click();
  }, [isConfirm]);

  return (
    <StyledBadge
      {...getRootProps({ className: "dropzone__studio" })}
      style={{ outline: "none" }}
      color="secondary"
      variant="dot"
      invisible={true}
      overlap={"rectangular"}
    >
      <input {...getInputProps()} ref={inputRef} />
      <BottomNavigationAction
        className={`${
          clickedStudio === false
            ? "bottomNavigation__actions bottomNavigation__studio"
            : "bottomNavigation__actions bottomNavigation__studio-selected"
        }
           bottomNavigation`}
        value="upload"
        disableRipple
        onClick={() => {
          if (!getAuthSession().user?.idCheckDetails?.verified) {
            history.replace("/identity-verification");
          } else {
            if (dontShowRules === true) {
              inputRef.current.click();
              handleClickStudio();
            } else setOpenModal(true);
          }
        }}
      />
    </StyledBadge>
  );
}
