/* eslint-disable no-unused-vars */
import { ChevronLeft } from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {Button, IconButton} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import "./tickles.css";
import TicklesImg from "../../../assets/images/tickles/Intersection 3@2x.png";
import genericAvatar from "../../../assets/images/generic-user-avatar.png";

import useInfiniteQuery from "../../../hooks/useInfiniteQuery";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";

import FeetLoverService from "../../../_services/feet-lovers.service";
import TickleService from "../../../_services/tickle.service";

import feetModelAtom from "../../../stateManagement/atoms/feetModelAtom";
import BuyTickle from "../../BuyTickleModal";
import TicklePricesService from "../../../_services/tickle-prices.service";

const Tickles = () => {
  let history = useHistory();
  const { t } = useTranslation();

  const [openBuyTickleModal, setOpenBuyTickleModal] = React.useState(false);
  const [__, setFeetModel] = useRecoilState(feetModelAtom);

  const {
    data,
    error,
    status,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery("users-sent-tickles-to", async ({ next_offset = 0 }) => {
    const response = await TickleService.getSentTickles(next_offset);

    return {
      data: response.data.users,
      next_offset: response.data.next_offset,
    };
  });

  const [feetLoverProfile, setFeetLoverProfile] = React.useState({});
  const [epochProductLists, setEpochProductLists] = useState([]);

  React.useEffect(() => {
    FeetLoverService.getOwnFeetLoverProfile().then((response) => {
      setFeetLoverProfile(response);
    });
  }, []);

  useEffect(() => {
    (async () => {
        if (feetLoverProfile.user.userType === "Lover" && openBuyTickleModal === true) {
            const response = await TicklePricesService.getEpochProductLists();
            setEpochProductLists(response.data);
        }
    })();
  }, [openBuyTickleModal, feetLoverProfile]);

  const loadMoreReference = React.useRef();

  useIntersectionObserver({
    target: loadMoreReference,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  return (
    <div className="ticklesLover__container">
      <div className="ticklesLover__header header header-height">
          <IconButton
              className="back__button"
              onClick={() => history.push("/settings-lovers")}
          >
              <ChevronLeft style={{ fill: "black" }} />
          </IconButton>
          <h4>{t("feetLoverProfileTickleBalance")}</h4>
      </div>
      <div className="ticklesLover__top">
        <img src={TicklesImg} alt="tickles" className="ticklesLover__img" />
        <div className="ticklesLoverTop__text">
          <h2>
            {feetLoverProfile &&
            feetLoverProfile.profile &&
            feetLoverProfile.profile.tickles
              ? feetLoverProfile.profile.tickles
              : "0"}
          </h2>
          <p>{t("ticklesTotalTickles")}</p>
        </div>
      </div>
        <div className={"ticklesLover__button__container"}>
          <Button
            className="ticklesLover__button"
            // onClick={() => history.push("/buy-tickles-credit")}
            onClick={() => setOpenBuyTickleModal(true)}
          >
            {t("ticklesBuyCredit")}
          </Button>
        </div>
      <div className="ticklesLover__content">
        <h4>{t("ticklesSendTickles")}</h4>
        {data && data.length
          ? data.map((user) => (
              <div
                key={user._id}
                className="ticklesLover__modelList"
                onClick={() => {
                  setFeetModel(user);
                  history.push({
                    pathname: "/feet-models-view",
                    search: `?user=${user._id}`,
                  });
                }}
              >
                <img
                  src={user.image ? user.image.url : genericAvatar}
                  alt="models"
                />
                <div className="ticklesLover__modelListText">
                  <h4>{user.username}</h4>
                </div>
              </div>
            ))
          : ""}
      </div>
      <div ref={loadMoreReference}></div>

      <BuyTickle
        openBuyTickleModal={openBuyTickleModal}
        setOpenBuyTickleModal={setOpenBuyTickleModal}
        epochPrices={epochProductLists}
      />
    </div>
  );
};

export default Tickles;
