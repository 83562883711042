export const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // padding: "40px",
  // borderRadius: 30,
  borderWidth: 1,
  borderColor: "#777B8680",
  borderStyle: "dashed",
  outline: "none",
  transition: "border .24s ease-in-out",
  opacity: 0.9,
  height: "100%",
  justifyContent: "space-between",
  marginBottom: 15,
  height: "300px",
};

export const activeStyle = {
  borderColor: "#2196f3",
};

export const acceptStyle = {
  borderColor: "#00e676",
};

export const rejectStyle = {
  borderColor: "#ff1744",
  borderWidth: 2,
};

export const buttonPrimaryStyle = {
  color: "#F50057",
};

export const buttonSecondaryStyle = {
  color: "#DDDDDD",
};
