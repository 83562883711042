import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const requestCertification = async (data) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.post("/v1/certifications", data, {
    headers,
  });

  return response;
};

const CertificationService = { requestCertification };

export default CertificationService;
