import { Dialog, Divider } from "@material-ui/core";
import React from "react";

import DialogTitle from "../../../BuyTickleModal/DialogTitle";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../pages/SuppressAccount/styled";

function BankAccountConfirmationModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openModal, setOpenModal, message } = props;
  return (
    <Dialog
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      className={classes.suppressDialog}
    >
      <DialogTitle>
        <p style={{ marginTop: "10px" }}>{message}</p>
      </DialogTitle>
      <Divider />
      <div className={classes.suppressModalButtonsContainer}>
        <button
          type={"button"}
          onClick={() => {
            setOpenModal(false);
          }}
          style={{ color: "#FF5768" }}
        >
          {t("Ok")}
        </button>
      </div>
    </Dialog>
  );
}

export default BankAccountConfirmationModal;
