/* eslint-disable no-unused-vars */
import { Avatar, Box, Button, IconButton, Snackbar } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import {useRecoilState, useRecoilValue} from "recoil";
import "./commentSection.css";
import {
  deleteComment,
  getPublicationComments,
  saveComment,
} from "../../_services/comment.service";
import React, { useEffect, useState } from "react";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import { Alert } from "@material-ui/lab";
import _, { uniqBy as _uniqBy } from "lodash";

import genericAvatar from "../../assets/images/generic-user-avatar.png";
import CommentModal from "../commentsSection/CommentModal";
import writeCommentsContentAtomAtom from "../../stateManagement/atoms/writeCommentsContentAtom";
import useSessionStorage from "../forms/services/storageHooks/useSessionStorage";
import Trash from "../../assets/images/messages-section/Group 54807@2x.png";
import feetModelAtom from "../../stateManagement/atoms/feetModelAtom";
import { useTranslation } from "react-i18next";
import dateLocale from "../../dateLocale.js";
import SubscribeService from "../../_services/subscribe.service";

const CommentsComponent = (props) => {
  let history = useHistory();
  let { id } = useParams();

  const [authenticated] = useSessionStorage({}, "infosUser");
  const [__, setFeetModel] = useRecoilState(feetModelAtom);
  const [isWriteComment, setIsWriteComment] = useRecoilState(
    writeCommentsContentAtomAtom
  );
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subscribers, setSubscribers] = useState([]);

  const [isErrorDeletingComment, setIsErrorDeletingComment] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (id) {
      async function fetchData() {
        setLoading(true);
        const publicationComments = await getPublicationComments({
          id: id,
        });
        setComments(() =>
        _uniqBy(
            [...publicationComments.comments].sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            ),
            "_id"
        ));
        setLoading(false);
      }
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (authenticated && authenticated.user.id) {
      async function fetchSubscribers() {
        const subscriber = await SubscribeService.getSubscriberList(authenticated.user.id);
        setSubscribers(subscriber.data.subscriptions);
      }
      fetchSubscribers();
    }
  }, [authenticated.user.id]);

  const handleSendComment = async () => {
    if (isWriteComment !== "") {
      const response = await saveComment({
        publication: id,
        content: isWriteComment,
      });

      setComments((prev) => [
        ...prev,
        {
          ...response.comment,
        },
      ]);

      setComments((prev) =>
      _uniqBy(
          [...prev].sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          ),
          "_id"
      ));
      setIsWriteComment("");
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const response = await deleteComment(commentId);
    } catch (error) {
      setIsErrorDeletingComment(true);
    }
  };

  const lengthOfSubscriptions = (dataSubscribers, userId) =>
      dataSubscribers
        .filter((subscriptions) => subscriptions.subscriber._id === userId)
        .map((resSubscribe) => resSubscribe);

  const trailingActions = (comment) => (
    <TrailingActions>
      <SwipeAction
        destructive={
          comment?.publication?.user === authenticated.user.id
            ? true
            : comment.writer.id === authenticated.user.id
        }
        onClick={() => {
          handleDeleteComment(comment._id);
        }}
      >
        <Box component="span" sx={{ p: 1 }}>
          <Button>
            <img width="50" src={Trash} alt="" />
          </Button>
        </Box>
      </SwipeAction>
    </TrailingActions>
  );

  const closeSnackbar = () => {
    setIsErrorDeletingComment(false);
  };

  const redirectToProfile = (comment) => {
    if (comment.writer.userType === "Lover") {
      if (authenticated.user._id === comment.writer.id) {
        history.push("/feet-lover-profile");
      } else {
        history.push({
          pathname: `/visit-feet-lover/${comment.writer.id}`,
        });
      }
    } else {
      if (authenticated.user._id === comment.writer.id) {
        history.push("/view-own-profile-models");
      } else {
        setFeetModel(comment.writer);
        history.push({
          pathname: "/feet-models-view",
          search: `?user=${comment.writer.id}`,
        });
      }
    }
  };
  return (
    <div className="commentSection__container">
      <div className="commentsList__head header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => {
            history.goBack();
          }}
        >
          <ChevronLeft />
        </IconButton>
        <h4>{t("commentHomePageLover")}</h4>
      </div>
      <div className="commentsSection__innerContent content__withHeader minmax__wrapper">
        <div className="commentBubbles_list">
          {loading ? (
            ""
          ) : (
            <SwipeableList type={ListType.IOS} threshold={0.50} fullSwipe={false}>
              {comments.map((comment) => (
                  <SwipeableListItem
                    key={comment._id}
                    trailingActions={trailingActions(comment)}
                    blockSwipe={
                      comment?.publication?.user === authenticated.user.id
                      ? false
                      : comment.writer.id !== authenticated.user.id
                    }
                  >
                    <div className="commentBubbles">
                      <div className={
                        comment &&
                        comment.writer &&
                        subscribers &&
                        subscribers.length > 0 &&
                        lengthOfSubscriptions(subscribers, comment.writer._id)
                            .length > 0
                            ? 'subscriberName commentBubbleTop'
                            : 'commentBubbleTop'
                      }>
                        <Avatar
                          src={
                            comment.writer && comment.writer.image
                              ? comment.writer.image.url
                              : genericAvatar
                          }
                          onClick={() => redirectToProfile(comment)}
                        />
                        <b onClick={() => redirectToProfile(comment)}>
                          {comment.writer.username}
                        </b>
                        <p>{dateLocale.localize(comment.createdAt).fromNow()}</p>
                      </div>
                      <div className="commentBubbleMessContent">
                        <p>{comment.content}</p>
                      </div>
                    </div>
                  </SwipeableListItem>
              ))}
            </SwipeableList>
          )}

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={isErrorDeletingComment}
            onClose={closeSnackbar}
            autoHideDuration={6000}
            // message="Sent Report"
            key={"top" + "center"}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              Unauthorized
            </Alert>
          </Snackbar>
        </div>
        <CommentModal handleSendComment={() => handleSendComment()} />
      </div>
    </div>
  );
};

export default CommentsComponent;
