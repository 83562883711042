import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    background: "#E7E7E7",
    padding: "70px 16px 0 16px",
  },

  form: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginBottom: "20px",
  },
  label: {
    fontSize: "clamp(1rem, 4vw, 1rem)",
    color: "#000000",
    marginBottom: "8px",
    marginLeft: "8px",
  },
  textInput: {
    width: "100%",

    "& .MuiInputBase-input": {
      height: "45px",
      borderRadius: "15px",
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
  },
  inputNumber: {
    width: "128px",
    "& input[type=number]": {
      lineHeight: "30px",
      fontSize: "16px",
      padding: "0 0 0 8px",
      backgroundColor: "#D7D7D7",
      borderRadius: "12px",
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      opacity: 1,
      "-webkit-appearance": "none",
      cursor: "pointer",
      display: "block",
      width: "8px",
      color: "#333",
      textAlign: "center",
      position: "relative",
      background: `#636363 url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAASCAYAAABrXO8xAAAAAXNSR0\
      IArs4c6QAAAUxJREFUOE/Vk7FKxEAQhpNL0Iws65EyXep0IQjaWApBLcRco5VvkCfJG1hpY8RCJW\
      JpI5yEdKmvSxmOZcmeIXeRjayuZxC9zu32n/nY2Zl/VKXnBEGgRVF0zkNhGJ7FcTxfTlOXhTzP1z\
      DGF8PhMOCx6XQaE0JOHcep5dwvYJIk667rXmGMDxeLRZc3GAwUQshtlmUj3/dfBfwBFkWxgTG+AY\
      C9pmmU+fy9Ok3TFF3XFcbYIyHkyLKsiusdmOc5sm37HgB2+Z2D8osc5Icx9jSZTPYdx6FqWZabhm\
      EkALAj/0EGZZ0x9jybzXy1qqoxAGyJYNu2XZlyqbxcVf1sB2Ps5VtX+8bTp/0ncJXm1HU9FuN4AI\
      DtP41jZQOIV1aynIC5yT3PixFCB7JzKKV3aZoGvSYXMF8rhNClaZrHXCvL8ppSevLjWgn4N4v8Bm\
      YKyyMJVre1AAAAAElFTkSuQmCC") no-repeat center center`,
      backgroundSize: "12px 12px",
      // background:
      //   "#636363 url('http://i.stack.imgur.com/YYySO.png') no-repeat 50% 50%",
      // width: "14px",
      height: "128px",
      padding: "4px",
      right: "0px",
      borderRadius: "28px",
    },
  },
  select: {
    height: "45px",
    color: "#545454",
    borderRadius: "15px",
  },
  textArea: {
    width: "100%",
    minHeight: "158px",
    height: "158px",
    "& .MuiFilledInput-multiline": {
      padding: "10px 15px",
      backgroundColor: "white",
      borderRadius: "clamp(15px, 4vw, 25px)",
      height: "100%",
    },
    "& .MuiFilledInput-inputMultiline": {
      height: "100%",
    },

    "& .MuiFilledInput-underline:before": {
      borderBottom: 0,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: 0,
    },
  },
  materialUiDatePicker: {
    "& .MuiInput-root": {
      backgroundColor: "#636363",
      borderRadius: "12px",
      width: "128px",
    },

    "& input[type=text]": {
      padding: "0 0 0 10px",
      backgroundColor: "#D7D7D7",
      borderRadius: "11px 0 0 11px",
    },

    "& .MuiInputAdornment-positionEnd": {
      marginLeft: "0px",
    },

    "& .MuiIconButton-root": {
      padding: 0,
    },

    "& .MuiFormControl-marginNormal": {
      marginTop: 0,
    },
  },
  editProfileMoreSettingsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  editProfileSettingsButtonRounded: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "clamp(15px, 4vw, 25px)",
    padding: "8px 13px",

    "& .MuiButton-text": {
      fontSize: "clamp(0.875rem, 4vw, 1rem)",
      textTransform: "none",
    },
  },
  accordion: {
    "& .MuiAccordion-root:before": {
      background: "none !important",
    },
    "& .Mui-expanded.MuiIconButton-edgeEnd": {
      marginTop: "0",
    },
    "& .picties": {
      display: "flex",
      padding: "10px",
      justifyContent: "space-around",
    },
    "& .selected-picty": {
      border: "5px solid #FF5768",
      borderRadius: "50%",
    },
    "& .picty": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: "15px !important",
      marginTop: "0px !important",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& option": {
      maxWidth: "50%",
    },
    "& .MuiInputBase-formControl": {
      backgroundColor: "#d6d6d6",
      borderRadius: "5px",
      padding: "10px",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      padding: 0,
      height: "100%",
    },

    "& .MuiInput-root": {
      height: "42px",
    },
    "& .MuiSelect-nativeInput": {
      height: "100%",
    },
    "& .MuiSelect-icon": {
      top: 0,
      height: "100%",
      backgroundColor: "gray",
      borderRadius: "0 5px 5px 0",
      color: "white",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "#d6d6d6 !important",
    },
  },
  content: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    marginBottom: "40px",
    "& h4,h5": {
      margin: 0,
      fontSize: "clamp(16px, 4vw, 24px)",
      fontWeight: "900",
    },
    "& .warning": {
      color: "#F5385A",
    },
    "& span": {
      fontSize: "clamp(14px, 3vw, 18px)",
    },
    "& a": {
      color: "#F5385A",
      fontWeight: "700",
    },
  },
  menuItem: {
    whiteSpace: "unset",
    wordBreak: "break-all",
  },
  dialogWarning: {
    color: "#FF5768",
    fontWeight: "700",
  },
  dialogTitleMessage: {
    fontWeight: "700",
  },
  suppressDialog: {
    textAlign: "center",
    "& .MuiPaper-root": {
      borderRadius: "15px",
    },
    "& p": {
      margin: 0,
      fontSize: "clamp(16px, 3vw, 18px)",
    },
    "& button": {
      backgroundColor: "white",
      padding: "10px",
      border: "none",
      color: "#ff2775",
      fontSize: "	1.1rem",
      fontWeight: "700",
      fontFamily: "Roboto",
    },
  },
  suppressModalButtonsContainer: {
    display: "flex",
    justifyContent: "space-around",
    "& button": {
      flex: "1",
    },
    "& button:first-child": {
      borderRight: "1px solid gray",
    },
  },

  selectReasonOpt: {
    "& div": {
      opacity: 0.7
    }
  }
}));
