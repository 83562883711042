import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import FollowersList from "./followersList/FollowersList";
import FollowingList from "./followingList/FollowingList";

import './followPage.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabChoiceFollow({
  value,
  setValue,
  users,
  user,
  id,
  loadMoreUsersReference,
  setUsers,
  loading,
  setIsFetchingNextPage,
  isFetchingNextPage
}) {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();

  const [followersCount, setFollowersCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleReduceFollowersCount = () => {
    setFollowersCount((prev) => prev - 1);
  };

  const handleReduceFollowingCount = () => {
    setFollowingCount((prev) => prev - 1);
  };

  const handleAddFollowingCount = () => {
    setFollowingCount((prev) => prev + 1);
  };

  useEffect(() => {
    if (user && user.profile) {
      setFollowersCount(user.profile?.activeFollowers || 0);
      setFollowingCount(user.profile?.activeFollowings || 0);
    }
  }, [user]);

  const tab2Label = () => `${followingCount} ${t("followingFeetModelView")}`;

  return (
    <div className="tabChoiceFollow__root">
      <Paper className="tabChoiceFollowPaper__root">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          className="follow-tabs"
        >
          <Tab
            label={`${followersCount} ${t("followersFeetModelView")}`}
            {...a11yProps(0)}
          />
          <Tab
            className={"follow-page-lover-tab"}
            label={tab2Label()}
            {...a11yProps(0)}
          />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <FollowersList
          users={users}
          id={id}
          loadMoreUsersReference={loadMoreUsersReference}
          setUsers={setUsers}
          handleReduceFollowersCount={handleReduceFollowersCount}
          loading={loading}
          setIsFetchingNextPage={setIsFetchingNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FollowingList
          users={users}
          loadMoreUsersReference={loadMoreUsersReference}
          id={id}
          setUsers={setUsers}
          handleReduceFollowingCount={handleReduceFollowingCount}
          handleAddFollowingCount={handleAddFollowingCount}
          loading={loading}
          setIsFetchingNextPage={setIsFetchingNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </TabPanel>
    </div>
  );
}
