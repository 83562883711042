import React, { Fragment } from "react";
import Modal from "@material-ui/core/Modal";
import "./BuyTicklesModal.css";
import ModalContent from "./BuyTicklesModalContent";
import { useHistory } from "react-router-dom";

function getModalStyle() {
  const top = 47;
  const left = 48;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    border: "none !important",
  };
}

export default function BuyTicklesModal({ userType, setUserType }) {
  let history = useHistory();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    history.push("/create-messages");
  };

  // Boby of the modal content
  const body = (
    <div
      style={modalStyle}
      className="BuyTicklesModal__paper modal-BuyTicklesModal"
    >
      <ModalContent userType={userType} setUserType={setUserType} />
    </div>
  );

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="BuyTicklesModal-simple-modal-title"
        aria-describedby="BuyTicklesModal-simple-modal-description"
      >
        {body}
      </Modal>
    </Fragment>
  );
}
