/* eslint-disable array-callback-return */
import useFavoritePicty from "../../../favoritePicty/hooks/useFavoritePicty";

const useCumulAndPersist = () => {
  const { favoritePictyList } = useFavoritePicty();
  const calculTransitionZonePictyFavorite = [];
  const NewTotalCount = [];
  let favListFromLocalStorage = [];

  const handleSubmitPictyCount = () => {
    const valueEgyptianFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Egyptian
    );
    const valueEgyptianFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Egyptian
    );
    const sumEg =
      valueEgyptianFromFavPictyList + valueEgyptianFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Egyptian !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Egyptian = sumEg;
      });
    }

    const valueGreekFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Greek
    );
    const valueGreekFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Greek
    );
    const sumGr =
      valueGreekFromFavPictyList + valueGreekFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Greek !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Greek = sumGr;
      });
    }

    const valueRomanFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Roman
    );
    const valueRomanFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Roman
    );
    const sumRo =
      valueRomanFromFavPictyList + valueRomanFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Roman !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Roman = sumRo;
      });
    }

    const valuevoyageFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].voyage
    );
    const valuevoyageFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].voyage
    );
    const sumVo =
      valuevoyageFromFavPictyList + valuevoyageFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].voyage !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.voyage = sumVo;
      });
    }

    const valueAiguilleFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Aiguille
    );
    const valueAiguilleFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Aiguille
    );
    const sumAi =
      valueAiguilleFromFavPictyList + valueAiguilleFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Aiguille !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Aiguille = sumAi;
      });
    }

    const valueArcheFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Arche
    );
    const valueArcheFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Arche
    );
    const sumAr =
      valueArcheFromFavPictyList + valueArcheFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Arche !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Arche = sumAr;
      });
    }

    const valueBasFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Bas
    );
    const valueBasFromPictyTransitionCalcul = Number(NewTotalCount[0][0].Bas);
    const sumBa = valueBasFromFavPictyList + valueBasFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Bas !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Bas = sumBa;
      });
    }

    const valueBasketFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Basket
    );
    const valueBasketFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Basket
    );
    const sumBask =
      valueBasketFromFavPictyList + valueBasketFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Basket !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Basket = sumBask;
      });
    }

    const valueBijouxFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Bijoux
    );
    const valueBijouxFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Bijoux
    );
    const sumBij =
      valueBijouxFromFavPictyList + valueBijouxFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Bijoux !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Bijoux = sumBij;
      });
    }

    const valueChatouillesFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Chatouilles
    );
    const valueChatouillesFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Chatouilles
    );
    const sumCha =
      valueChatouillesFromFavPictyList +
      valueChatouillesFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Chatouilles !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Chatouilles = sumCha;
      });
    }

    const valueCopinesFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Copines
    );
    const valueCopinesFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Copines
    );
    const sumCop =
      valueCopinesFromFavPictyList + valueCopinesFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Copines !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Copines = sumCop;
      });
    }

    const valueGenereuxFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Genereux
    );
    const valueGenereuxFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Genereux
    );
    const sumGen =
      valueGenereuxFromFavPictyList + valueGenereuxFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Genereux !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Genereux = sumGen;
      });
    }

    const valueJambeFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Jambe
    );
    const valueJambeFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Jambe
    );
    const sumJam =
      valueJambeFromFavPictyList + valueJambeFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Jambe !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Jambe = sumJam;
      });
    }

    const valueJouerFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Jouer
    );
    const valueJouerFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Jouer
    );
    const sumJou =
      valueJouerFromFavPictyList + valueJouerFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Jouer !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Jouer = sumJou;
      });
    }

    const valueMirrorFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Mirror
    );
    const valueMirrorFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Mirror
    );
    const sumMir =
      valueMirrorFromFavPictyList + valueMirrorFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Mirror !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Mirror = sumMir;
      });
    }

    const valueOnglesLongsFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].OnglesLongs
    );
    const valueOnglesLongsFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].OnglesLongs
    );
    const sumOngLo =
      valueOnglesLongsFromFavPictyList +
      valueOnglesLongsFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].OnglesLongs !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.OnglesLongs = sumOngLo;
      });
    }

    const valueOrteilsFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Orteils
    );
    const valueOrteilsFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Orteils
    );
    const sumOrt =
      valueOrteilsFromFavPictyList + valueOrteilsFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Orteils !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Orteils = sumOrt;
      });
    }

    const valueOrteilsCourtsFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].OrteilsCourts
    );
    const valueOrteilsCourtsFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].OrteilsCourts
    );
    const sumOrtCo =
      valueOrteilsCourtsFromFavPictyList +
      valueOrteilsCourtsFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].OrteilsCourts !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.OrteilsCourts = sumOrtCo;
      });
    }

    const valueOrteilsEcartesFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].OrteilsEcartes
    );
    const valueOrteilsEcartesFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].OrteilsEcartes
    );
    const sumOrtEc =
      valueOrteilsEcartesFromFavPictyList +
      valueOrteilsEcartesFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].OrteilsEcartes !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.OrteilsEcartes = sumOrtEc;
      });
    }

    const valueOrteilsLongsFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].OrteilsLongs
    );
    const valueOrteilsLongsFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].OrteilsLongs
    );
    const sumOrtLo =
      valueOrteilsLongsFromFavPictyList +
      valueOrteilsLongsFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].OrteilsLongs !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.OrteilsLongs = sumOrtLo;
      });
    }

    const valueOsseuxFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Osseux
    );
    const valueOsseuxFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Osseux
    );
    const sumOss =
      valueOsseuxFromFavPictyList + valueOsseuxFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Osseux !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Osseux = sumOss;
      });
    }

    const valuePedalesFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Pedales
    );
    const valuePedalesFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Pedales
    );
    const sumPe =
      valuePedalesFromFavPictyList + valuePedalesFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Pedales !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Pedales = sumPe;
      });
    }

    const valuePiedMainFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].PiedMain
    );
    const valuePiedMainFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].PiedMain
    );
    const sumPieM =
      valuePiedMainFromFavPictyList + valuePiedMainFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].PiedMain !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.PiedMain = sumPieM;
      });
    }

    const valuePlantePiedFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].PlantePied
    );
    const valuePlantePiedFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].PlantePied
    );
    const sumPlPie =
      valuePlantePiedFromFavPictyList +
      valuePlantePiedFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].PlantePied !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.PlantePied = sumPlPie;
      });
    }

    const valuePlissesFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Plisses
    );
    const valuePlissesFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Plisses
    );
    const sumPlis =
      valuePlissesFromFavPictyList + valuePlissesFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Plisses !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Plisses = sumPlis;
      });
    }

    const valuePublicFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Public
    );
    const valuePublicFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Public
    );
    const sumPub =
      valuePublicFromFavPictyList + valuePublicFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Public !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Public = sumPub;
      });
    }

    const valueSelfeetFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Selfeet
    );
    const valueSelfeetFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Selfeet
    );
    const sumSelf =
      valueSelfeetFromFavPictyList + valueSelfeetFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Selfeet !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Selfeet = sumSelf;
      });
    }

    const valueTalonsFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Talons
    );
    const valueTalonsFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Talons
    );
    const sumTal =
      valueTalonsFromFavPictyList + valueTalonsFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Talons !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Talons = sumTal;
      });
    }

    const valueTatouageFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Tatouage
    );
    const valueTatouageFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Tatouage
    );
    const sumTato =
      valueTatouageFromFavPictyList + valueTatouageFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Tatouage !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Tatouage = sumTato;
      });
    }

    const valueVernisFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Vernis
    );
    const valueVernisFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].Vernis
    );
    const sumVern =
      valueVernisFromFavPictyList + valueVernisFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Vernis !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Vernis = sumVern;
      });
    }

    const valueVueFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].Vue
    );
    const valueVueFromPictyTransitionCalcul = Number(NewTotalCount[0][0].Vue);
    const sumVue = valueVueFromFavPictyList + valueVueFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].Vue !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.Vue = sumVue;
      });
    }

    const valueArtisteFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].artiste
    );
    const valueArtisteFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].artiste
    );
    const sumArt =
      valueArtisteFromFavPictyList + valueArtisteFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].artiste !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.artiste = sumArt;
      });
    }

    const valueChaussetteFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].chaussette
    );
    const valueChaussetteFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].chaussette
    );
    const sumChaus =
      valueChaussetteFromFavPictyList +
      valueChaussetteFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].chaussette !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.chaussette = sumChaus;
      });
    }

    const valueCocooningFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].cooconing
    );
    const valueCocooningFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].cooconing
    );
    const sumCocoo =
      valueCocooningFromFavPictyList + valueCocooningFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].cooconing !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.cooconing = sumCocoo;
      });
    }

    const valueModeFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].mode
    );
    const valueModeFromPictyTransitionCalcul = Number(NewTotalCount[0][0].mode);
    const sumMod =
      valueModeFromFavPictyList + valueModeFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].mode !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.mode = sumMod;
      });
    }

    const valueSportFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].sport
    );
    const valueSportFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].sport
    );
    const sumSpo =
      valueSportFromFavPictyList + valueSportFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].sport !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.sport = sumSpo;
      });
    }

    const valueWaterFromFavPictyList = Number(
      calculTransitionZonePictyFavorite[0][0].water
    );
    const valueWaterFromPictyTransitionCalcul = Number(
      NewTotalCount[0][0].water
    );
    const sumWat =
      valueWaterFromFavPictyList + valueWaterFromPictyTransitionCalcul;

    if (NewTotalCount[0][0].water !== 0) {
      calculTransitionZonePictyFavorite[0].map((items, i) => {
        items.water = sumWat;
      });
    }

    localStorage.setItem(
      "Final",
      JSON.stringify(calculTransitionZonePictyFavorite[0])
    );
  };

  return {
    handleSubmitPictyCount,
    favoritePictyList,
    calculTransitionZonePictyFavorite,
    favListFromLocalStorage,
    NewTotalCount,
  };
};

export default useCumulAndPersist;
