import React, { Fragment } from "react";

import Heart from "../../../../../assets/images/notifications-models/likes.png";
import dateLocale from "../../../../../dateLocale";

export const MessageHeart = (props) => {
  return (
    <Fragment>
      <p className="createMessage__sendMessageDate">
        {dateLocale.localize(props.message.createdAt).fromNow()}
      </p>
      <div>
        <img src={Heart} alt="heart Icon" className="createMessage__heart" />
      </div>
    </Fragment>
  );
};
