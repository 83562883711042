import PublicationMedia from "../../../PublicationMedia";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import {StyledBadge} from "../NotificationsModels";

const dateTimeFormat = "DD/MM/YYYY H[h]mm";
const PublicationElem = ({ elem, isInvisibleGeneralBadge }) => {
  const { t } = useTranslation();
  return (
    <div key={elem._id} className="marginIphone" onClick={() => {}}>
        <StyledBadge
            color="secondary"
            variant="dot"
            invisible={isInvisibleGeneralBadge || elem.readAt}
            overlap={"rectangular"}
        />
      <div className="user_details">
        {elem?.publication || elem?.details?.publication ? (
          <PublicationMedia
            publication={elem.publication || elem?.details?.publication}
            height={100}
            width={100}
          />
        ) : (
          ""
        )}

        <p style={{ margin: "0", fontSize: "0.75rem" }}>
          {t(elem.details.message)}
        </p>
      </div>
      <sub style={{ fontSize: "0.70rem" }}>
        {moment(elem.createdAt).format(dateTimeFormat)}
      </sub>
    </div>
  );
};

export default PublicationElem;
