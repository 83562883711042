import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { useStyles } from "./styled";

const DialogTitle = (props) => {
  const { classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.dialogTitleRoot}
      {...other}
    >
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

function UpdateEmailModal(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const { openUpdateEmailModal, setOpenUpdateEmailModal, ...rest } = props;

  return (
    <Dialog
      className={classes.dialogRoot}
      open={openUpdateEmailModal}
      onClose={() => setOpenUpdateEmailModal(false)}
      {...rest}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setOpenUpdateEmailModal(false)}
        classes={classes}
      >
        {t("Modify your email")}
      </DialogTitle>
      <DialogContent className={classes.dialogContentRoot}>
        {t("Click on the link in the email")}
      </DialogContent>
      <DialogActions className={classes.dialogActionsRoot}>
        <Button
          autoFocus
          onClick={async () => history.push("/edit-profile")}
          color="primary"
          className="buy-tickles-confirm"
        >
          {t("Ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateEmailModal;
