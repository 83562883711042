import CacheRoute, { CacheSwitch } from "react-router-cache-route";
import { Redirect, Route } from "react-router";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import AutoLoginRoute from "./components/AutoLoginRoute";
import BankAccount from "./components/modelsComponents/bankAccount/BankAccount";
import BankAccountGuides from "./components/modelsComponents/bankAccount/guide/BankAccountGuide";
import BlockedAccounts from "./components/BlockedAccounts";
import BuyTicklesCredit from "./components/loversComponents/BuyTicklesCredit/BuyTicklesCredit";
import BuyTicklesModal from "./components/loversComponents/buyTicklesModal/BuyTicklesModal";
import Camera from "./pages/Camera/CameraPage";
import CameraPicture from "./components/modelsComponents/pictureFromCamera/CameraPicture";
import ChangeFeetModelSubscriptionAmountPage from "./pages/ChangeFeetModelSubscriptionAmount";
import ChangePassword from "./pages/EditProfile/ChangePassword";
import ChooseAccount from "./components/chooseAccount/ChooseAccount";
import ChoosePictyInscription from "./components/choosePictyInscription/ChoosePictyInscription";
import CommentsComponent from "./components/commentsSection/CommentsComponent";
import ConfirmRegistration from "./pages/ConfirmRegistration";
import CreateMessage from "./components/loversComponents/messageSection/createMessage/CreateMessage";
import CropperView from "./components/modelsComponents/photoStudioModels/pages/CropperView";
import CustomMediaTrackerPage from "./components/modelsComponents/customMediaTracker/trackerPage";
import CustomMediaTrackingList from "./components/modelsComponents/customMediaTracker/customMediaTrackingList";
import DropzoneComponent from "./components/modelsComponents/pictureFromFilesystem/DropzoneComponent";
import EditProfilePage from "./pages/EditProfile";
import FacebookLogin from "./components/forms/services/facebook/FacebookLogin";
import FeetDetails from "./components/feetDetails/FeetDetails";
import FeetLoverProfile from "./components/loversComponents/feetLoverProfile/FeetLoverProfile";
import FeetModelGuides from "./pages/Guides/FeetModel";
import FeetloverGuides from "./components/feetloverGuides/FeetloverGuides";
import FeetyCertification from "./components/modelsComponents/feetyCertification/FeetyCertification";
import FollowPage from "./components/loversComponents/followSection/FollowPage";
import ForgotPassword from "./components/forms/ForgotPassword";
import FormSignIn from "./components/forms/formSignIn/FormSignIn";
import FormSuccess from "./components/forms/formSuccess/FormSuccess";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import HeartAnimations from "./components/loversComponents/messageSection/heartAnimations/HeartAnimations";
import HelloPicture from "./components/modelsComponents/pictureFromFilesystem/HelloPicture";
import HelpLover from "./components/loversComponents/helpLover/HelpLover";
import HomeLoverFirstLogin from "./components/homeLover/firstLogin/HomeLoverFirstLogin";
import HomePage from "./components/homeLover/homePage/HomePage";
import HomePageModels from "./components/modelsComponents/homePageModels/HomePageModels";
import HomeTest from "./components/HomeTest";
import IdCheckStatusPage from "./pages/IdCheckStatus/IdCheckStatusPage/IdCheckStatusPage";
import IdentifyMySelfPage from "./pages/IdentifyMySelfPage";
import IdentityVerificationPage from "./pages/IdentityVerification/IdentityVerificationPage/IdentityVerificationPage";
import KebabMenuOptionsLovers from "./components/loversComponents/KebabMenuOptionsLovers/KebabMenuOptionsLovers";
import Language from "./components/loversComponents/Language/Language";
import Likes from "./components/Likes";
import Loader from "./components/loading-page/Loader";
import LoaderSuccess from "./components/loversComponents/successPayment/LoaderSuccess";
import MessageSearch from "./components/loversComponents/messageSection/messageSearch/MessageSearch";
import Messages from "./components/loversComponents/messageSection/AllMessages/Messages";
import ModalCropper from "./components/profilePictureResize/ModalCropper";
import ModalFilters from "./components/profilePictureResize/ModalFilters";
import NewEmailUpdates from "./pages/LandingPages/newEmalUpdate";
import NotFoundPage from "./components/notFoundPage/NotFoundPage";
import NotificationsModels from "./components/modelsComponents/notificationsModels/NotificationsModels";
import PictySearchPage from "./components/pictyFilters/PictySearchPage";
import PostPublication from "./components/modelsComponents/postPublications/PostPublication";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import Publication from "./components/publications";
import PublicationLikers from "./components/PublicationLikers";
import React from "react";
import RejectedEmailUpdates from "./pages/LandingPages/rejectedEmailUpdate";
import ResetPassword from "./components/forms/formResetPassword";
import SearchDefaultView from "./components/loversComponents/search-DefaultView/SearchDefaultView";
import SearchHistory from "./components/loversComponents/searchHistory/SearchHistory";
import SendTickles from "./components/loversComponents/sendTickles/SendTickles";
import SettingFeetLovers from "./components/loversComponents/settingsFeetLovers/SettingFeetLovers";
import SettingFeetModels from "./components/modelsComponents/settingsFeetModels/SettingFeetModels";
import SignUp from "./pages/SignUp";
import SocialLoginWelcome from "./components/socialLoginWelcome/SocialLoginWelcome";
import SoftChooseAccount from "./components/chooseAccount/SoftLaunchChooseAccount";
import SubscribeToUnlockPublication from "./components/loversComponents/SubscribeToUnlockPublication/SubscribeToUnlockPublication";
import Subscribers from "./components/Subscribers";
import SubscriptionDefault from "./components/loversComponents/subscriptionDefault/SubscriptionDefault";
import SubscriptionLover from "./components/loversComponents/subscriptionLover/SubscriptionLover";
import SubscriptionPayment from "./components/loversComponents/subscriptionPayment/SubscriptionPayment";
import SuccessEmailUpdates from "./pages/LandingPages/successEmailUpdate";
import SuccessPayment from "./components/loversComponents/successPayment/SuccessPayment";
import SuppressAccount from "./components/loversComponents/suppressAccount/SuppressAccount";
import SuppressAccountPage from "./pages/SuppressAccount";
import TermsConditions from "./components/termsConditions/TermsConditions";
import Tickles from "./components/loversComponents/tickles/Tickles";
import TicklesFeetModelsAccount from "./components/modelsComponents/ticklesFeetModelsAccount/TicklesFeetModelsAccount";
import TicklesPayment from "./components/loversComponents/TicklesPayment/TicklesPayment";
import UpdateEmail from "./pages/EditProfile/UpdateEmail";
import VideoStudioModels from "./components/modelsComponents/videoStudioModels/VideoStudioModels";
import ViewFeetModelsProfile from "./components/loversComponents/viewFeetModelsProfile/ViewFeetModelsProfile";
import ViewOwnProfileModels from "./components/modelsComponents/viewOwnProfileModels/ViewOwnProfileModels";
import Views from "./components/ViewsPage";
import VisitFeetLoverProfile from "./components/loversComponents/visitFeetLoverProfile";
import WelcomePage from "./pages/WelcomePage";
import Withdraw from "./components/modelsComponents/withdrawTickles/Withdraw";
import i18n from "i18next";
import { isAuthenticated } from "./helpers/auth.helpers";

const ROUTES = [
  {
    path: "/loading",
    // eslint-disable-next-line react/display-name
    component: () => <Loader />,
  },
  {
    path: "/",
    exact: true,
    login: true,
    // eslint-disable-next-line react/display-name
    component: () => <FormSignIn />,
  },
  {
    path: "/sign-in",
    login: true,
    // eslint-disable-next-line react/display-name
    component: () => <FormSignIn />,
  },
  {
    path: "/choose-picty",
    // eslint-disable-next-line react/display-name
    component: () => <ChoosePictyInscription />,
  },
  {
    path: "/choose-account",
    // eslint-disable-next-line react/display-name
    component: () => <ChooseAccount />,
  },
  {
    path: "/soft-choose-account",
    // eslint-disable-next-line react/display-name
    component: () => <SoftChooseAccount />,
  },
  {
    path: "/feet-details",
    // eslint-disable-next-line react/display-name
    component: () => <FeetDetails />,
  },
  {
    path: "/test",
    // eslint-disable-next-line react/display-name
    component: () => <HomeTest />,
  },
  {
    path: "/sign-up",
    // eslint-disable-next-line react/display-name
    component: () => <SignUp />, // applyReCaptcha(<SignUp />),
  },
  {
    path: "/success",
    // eslint-disable-next-line react/display-name
    component: () => <FormSuccess />,
  },
  {
    path: "/facebook",
    // eslint-disable-next-line react/display-name
    component: () => <FacebookLogin />,
  },
  {
    path: "/cropper-modal",
    // eslint-disable-next-line react/display-name
    component: () => <ModalCropper />,
  },
  {
    path: "/filters-tool",
    // eslint-disable-next-line react/display-name
    component: () => <ModalFilters />,
  },
  {
    path: "/social-welcome",
    // eslint-disable-next-line react/display-name
    component: () => <SocialLoginWelcome />,
  },
  {
    path: "/home-lover-first-login",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <HomeLoverFirstLogin />,
  },
  {
    path: "/filter",
    // eslint-disable-next-line react/display-name
    component: () => <PictySearchPage />,
  },
  {
    path: "/home-page",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <HomePage />,
    cache: true,
  },
  {
    path: "/picture-camera",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <CameraPicture />,
  },
  {
    path: "/studio-cropper-step",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <CropperView />,
  },
  {
    path: "/studio-video",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <VideoStudioModels />,
  },
  {
    path: "/upload",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <DropzoneComponent />,
  },
  {
    path: "/hello-picture",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <HelloPicture />,
  },
  {
    path: "/feet-models-view", // todo: might need to refactor instead of passing the user as query pass it as param. ex: from feet-models-view?user="" to /feet-models-view/:id
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <ViewFeetModelsProfile />,
  },
  {
    path: "/feet-lover-profile",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <FeetLoverProfile />,
  },
  {
    path: "/kebab-menu-lover",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <KebabMenuOptionsLovers />,
  },
  {
    path: "/search-lover",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <SearchDefaultView />,
  },
  {
    path: "/publication/:id",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <Publication />,
    exact: true,
  },
  {
    path: "/subscription-lover",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <SubscriptionLover />,
  },
  {
    path: "/messages",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <Messages />,
  },
  {
    path: "/messages-search",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <MessageSearch />,
  },
  {
    path: "/subscribe-unlock-publication",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <SubscribeToUnlockPublication />,
  },
  {
    path: "/subscription-payment",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <SubscriptionPayment />,
  },
  {
    path: "/success-payment",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <SuccessPayment />,
  },
  {
    path: "/settings-lovers",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <SettingFeetLovers />,
  },
  {
    path: "/tickles-lovers",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <Tickles />,
  },
  {
    path: "/buy-tickles-credit",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <BuyTicklesCredit />,
  },
  {
    path: "/tickles-payment",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <TicklesPayment />,
  },
  {
    path: "/subscription-default",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <SubscriptionDefault />,
  },
  {
    path: "/language",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <Language />,
  },
  {
    path: "/loader-success",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <LoaderSuccess />,
  },
  {
    path: "/edit-profile",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <EditProfilePage />,
  },
  {
    path: "/change-password",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <ChangePassword />,
  },
  {
    path: "/update-email",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <UpdateEmail />,
  },
  {
    path: "/help",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <HelpLover />,
  },
  {
    path: "/old-suppress-account",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <SuppressAccount />,
  },
  {
    path: "/suppress-account",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <SuppressAccountPage />,
  },
  {
    path: "/create-messages",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <CreateMessage />,
  },
  {
    path: "/create-messages/:id",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <CreateMessage />,
  },
  {
    path: "/search-history",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <SearchHistory />,
  },
  {
    path: "/blocked-accounts",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <BlockedAccounts />,
  },
  {
    path: "/send-tickles-lover",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <SendTickles />,
  },
  {
    path: "/modal-buy-tickles-lover",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <BuyTicklesModal />,
  },
  {
    path: "/follow-page-lover/:id",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <FollowPage />,
  },
  {
    path: "/notifications-page-models",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <NotificationsModels />,
  },
  {
    path: "/view-own-profile-models",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <ViewOwnProfileModels />,
  },
  {
    path: "/with-draw-tickles",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <Withdraw />,
  },
  {
    path: "/setting-models",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <SettingFeetModels />,
  },
  {
    path: "/tickles-account",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <TicklesFeetModelsAccount />,
  },
  {
    path: "/privacy-policy",
    auth: false,
    // eslint-disable-next-line react/display-name
    component: () => <PrivacyPolicy />,
  },
  {
    path: "/terms-conditions",
    auth: false,
    // eslint-disable-next-line react/display-name
    component: () => <TermsConditions />,
  },
  {
    path: "/update-subscriptions",
    auth: false,
    // eslint-disable-next-line react/display-name
    component: () => <ChangeFeetModelSubscriptionAmountPage />,
  },
  {
    path: "/bank-account",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <BankAccountGuides />,
  },
  {
    path: "/bank-account-form",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <BankAccount />,
  },
  {
    path: "/feety-certification",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <FeetyCertification />,
  },
  {
    path: "/home-page-models",
    auth: true,
    cache: true,
    // eslint-disable-next-line react/display-name
    component: () => <HomePageModels />,
  },
  {
    path: "/post-publication",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <PostPublication />,
  },
  {
    path: "/heart",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <HeartAnimations />,
  },
  {
    path: "/comments-list",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <CommentsComponent />,
  },
  {
    path: "/publication/:id/comments",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <CommentsComponent />,
    exact: true,
  },
  {
    path: "/visit-feet-lover/:id",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <VisitFeetLoverProfile />,
  },
  {
    path: "/reset-password/token/:token",
    auth: false,
    // eslint-disable-next-line react/display-name
    component: () => <ResetPassword />,
  },
  {
    path: "/forgot-password",
    auth: false,
    // eslint-disable-next-line react/display-name
    component: () => <ForgotPassword />,
  },
  {
    path: "/publication/:id/likers",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <PublicationLikers />,
    exact: true,
  },
  {
    path: "/likes/:id",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <Likes />,
  },
  {
    path: "/subscribers/:id",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <Subscribers />,
  },
  {
    path: "/views/:id",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <Views />,
  },
  {
    path: "/identity-verification",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <IdentityVerificationPage />,
  },
  {
    path: "/id-check-status",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <IdCheckStatusPage />,
  },
  {
    path: "/camera",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <Camera />,
  },
  {
    path: "/feetlover-guides",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <FeetloverGuides />,
  },
  {
    path: "/feet-model-guides",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <FeetModelGuides />,
  },
  {
    path: "/confirm-email",
    auth: false,
    // eslint-disable-next-line react/display-name
    component: () => <SuccessEmailUpdates />,
  },
  {
    path: "/cancel-email",
    auth: false,
    // eslint-disable-next-line react/display-name
    component: () => <RejectedEmailUpdates />,
  },
  {
    path: "/transfer-current-email-to-new-email",
    auth: false,
    // eslint-disable-next-line react/display-name
    component: () => <NewEmailUpdates />,
  },
  {
    path: "/identify-myself",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <IdentifyMySelfPage />,
  },
  {
    path: "/confirm-registration",
    auth: false,
    // eslint-disable-next-line react/display-name
    component: () => <ConfirmRegistration />,
  },
  {
    path: "/welcome-to-feety",
    auth: false,
    // eslint-disable-next-line react/display-name
    component: () => <WelcomePage />,
  },
  {
    path: "/custom-media-tracking",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <CustomMediaTrackingList />,
    exact: true,
  },
  {
    path: "/custom-media-page/:id",
    auth: true,
    // eslint-disable-next-line react/display-name
    component: () => <CustomMediaTrackerPage />,
    exact: true,
  },
];

export default ROUTES;

function RouteWithSubRoutes(route) {
  if (route.auth) {
    return (
      <AuthenticatedRoute
        path={route.path}
        exact={route.exact}
        render={(props) => <route.component {...props} routes={route.routes} />}
      />
    );
  }

  if (route.login) {
    return (
      <AutoLoginRoute
        path={route.path}
        exact={route.exact}
        render={(props) => <route.component {...props} routes={route.routes} />}
      />
    );
  }
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
}

const applyReCaptcha = (route) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
      language={i18n.language === "en" ? "en" : "fr"}
      useRecaptchaNet={false}
      useEnterprise={false}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: "head",
        nonce: undefined,
      }}
    >
      {route}
    </GoogleReCaptchaProvider>
  );
};

function AuthenticatedRouteWithCache(route) {
  if (route.auth) {
    return (
      <CacheRoute
        when="forward"
        cacheKey={route.path}
        saveScrollPosition={true}
        path={route.path}
        exact={route.exact}
        render={(props) => {
          if (isAuthenticated()) {
            return <route.component {...props} routes={route.routes} />;
          } else {
            return <Redirect to="/" />;
          }
        }}
      />
    );
  }

  return (
    <CacheRoute
      when="forward"
      cacheKey="list"
      saveScrollPosition={true}
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
}

export function RenderRoutes({ routes }) {
  return (
    <CacheSwitch
      which={(element) => element.type === AuthenticatedRouteWithCache}
    >
      {routes.map((route, i) => {
        return route.cache ? (
          <AuthenticatedRouteWithCache key={i} {...route} />
        ) : (
          <RouteWithSubRoutes key={i} {...route} />
        );
      })}
      <Route path="*">
        <NotFoundPage />
      </Route>
    </CacheSwitch>
  );
}
