import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { SocketContext } from "../../../context/socket";
import useInfiniteQuery from "../../../hooks/useInfiniteQuery";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import SubscribeService from "../../../_services/subscribe.service";
import { uniqBy as _uniqBy } from "lodash";
import { StyledBadge } from "./NotificationsModels";
import useSessionStorage from "../../forms/services/storageHooks/useSessionStorage";
import feetModelAtom from "../../../stateManagement/atoms/feetModelAtom";
import feetModelOwnProfileQuery from "../../../stateManagement/selectors/feetModelOwnProfileSelector";
import SubscriptionsIcon from "../../../assets/images/notifications-models/Path 201000@2x.png";
import subscriptionBadgeAtom from "../../../stateManagement/atoms/notifications/badge/subscription";
import useNotificationsModels from "./hooks/useNotificationsModels";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import NotificationService from "../../../_services/notification.service";

const NotificationSubscriptions = () => {
    const socket = useContext(SocketContext);
    const [isInvisibleSubscriptionBadge, setInvisibleSubscriptionBadge] = useRecoilState(subscriptionBadgeAtom);
    const [allSubscribers, setAllSubscribers] = useState([]);
    const [count, setCount] = useState(0);
    const { t } = useTranslation();
    const [authUser] = useSessionStorage({}, "infosUser");
    const feetModel = useRecoilValue(feetModelOwnProfileQuery);
    const [__, setFeetModel] = useRecoilState(feetModelAtom);
    const [isReadAll, setIsReadAll] = useState(false);

    const {
        data: subscribers,
        hasNextPage: subscribersHasNextPage,
        fetchNextPage: subscribersFetchNextPage,
    } = useInfiniteQuery("notification-subscribers", async () => {
        const response = count > 0 ? await SubscribeService.getSubscriptionsV2() : null;

        return {
            data: response.data.subscriptions,
            next_offset: response.data.next_offset,
        };
    }, count);

    const newSubscriptionHandler = useCallback(
        (newSubscription) => {
            setCount(count+1)
        },
        [subscribers]
    );

    useEffect(() => {
        // observer > new message
        const message = socket.onSubscription()
        const onSubscription = message.subscribe(newSubscriptionHandler);

        // clean up observer
        return () => onSubscription.unsubscribe();
    }, [socket, newSubscriptionHandler]);

    const {
        noSubscriptionsYet,
        setNoSubscriptionsYet,
        clickedSubscriptions,
        NoNotificationsYet,
        SubscriptionsNotifications,
        deploySubscriptions,
        handleDeploySubscriptions,
        // handleCheckReadAllNotifications,
    } = useNotificationsModels();

    const loadMoreSubscriberReference = useRef();

    useEffect(() => {
        setNoSubscriptionsYet(!subscribers || subscribers.length === 0);
        setAllSubscribers(() =>
            _uniqBy(
                [...subscribers].sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                ),
                "_id"
            )
        );
    }, [subscribers]);

    useIntersectionObserver({
        target: loadMoreSubscriberReference,
        onIntersect: subscribersFetchNextPage,
        enabled: subscribersHasNextPage,
    });

    return (
        <div className="notificationsModelsContentSection__subscritions">
        <div
            className={
                deploySubscriptions === false
                    ? "notificationsModels__subscritionsList hidden-body"
                    : "notificationsModels__subscritionsList"
            }
            onClick={
                deploySubscriptions === true ? handleDeploySubscriptions : 
                async () => {
                    handleDeploySubscriptions()
                    await NotificationService.readAllNotificationsWithType("Subscription")
                    await NotificationService.readAllNotificationsWithType("Renewal")
                    setInvisibleSubscriptionBadge(true)
                    setCount(count+1)
                    // handleCheckReadAllNotifications()
                }
            }
        >
            <div className="notificationsModelsSection__head">
                <div className="notif-title">
                    <StyledBadge
                        color="secondary"
                        variant="dot"
                        invisible={isInvisibleSubscriptionBadge}
                        overlap={"rectangular"}
                    >
                        <img
                            src={SubscriptionsIcon}
                            alt=""
                            className="notificationsModels__Icon"
                        />
                    </StyledBadge>
                    <b>{t("notificationHeaderSubscription")}</b>
                </div>
                {deploySubscriptions === false ? (
                    <KeyboardArrowDownIcon
                        className="subscriptionsNotificationsTopChevron"
                    />
                ) : (
                    <KeyboardArrowUpIcon
                        className="subscriptionsNotificationsTopChevron"
                    />
                )}
            </div>
            <hr className="hr-sep-notifications" />
            <div className="notification-body">
                {noSubscriptionsYet === true ? (
                    <NoNotificationsYet />
                ) : (
                    <SubscriptionsNotifications
                        subscribers={allSubscribers}
                        authUser={authUser}
                        setFeetModel={setFeetModel}
                        loadMoreSubscribersReference={loadMoreSubscriberReference}
                        isInvisibleSubscriptionBadge={isInvisibleSubscriptionBadge}
                    />
                )}
            </div>
        </div>
    </div>)
}

export default NotificationSubscriptions