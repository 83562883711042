import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Paper } from "@material-ui/core";
import AllMessages from "./AllMessages";
import FavMessages from "./FavMessages";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabChoise({ userType }) {
  const location = useLocation();
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fromFavorite = location.state?.fromFavorite;

  useEffect(() => {
    if (fromFavorite) {
      setValue(1);
    }
  }, [fromFavorite]);

  return (
    <div className="tabChoice__root">
      <Paper className="tabChoicePaper__root sticky-tab">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          style={{ marginBottom: "10px" }}
          className="messages-tabs"
        >
          <Tab label={t("tabChoiceAllMess")} {...a11yProps(0)} />
          <Tab label={t("tabChoiceFavMess")} {...a11yProps(1)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0} className="message__tabPanel">
        <AllMessages userType={userType} />
      </TabPanel>
      <TabPanel value={value} index={1} className="message__tabPanel">
        <FavMessages userType={userType} />
      </TabPanel>
    </div>
  );
}
