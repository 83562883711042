import axios from "axios";
import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const getUserSuggestions = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get("/v1/messages/suggestions", {
    headers,
  });

  return response;
};

const getMessageConversations = async (params) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get("/v1/messages", {
    params,
    headers,
  });

  return response;
};

const getPrivateMessages = async (params, offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/messages?offset=${offset}`, {
    params,
    headers,
  });

  return response;
};

const deleteMessageConversation = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const response = await axiosInstance.delete("/v1/messages", {
      headers,
      data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

const uploadMessage = async (data) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  // const response = await axiosInstance.post("/v1/messages/upload-files", data, {
  //   headers,
  // });

  const response = await axiosInstance.post("/v2/medias", data, {
    headers
  });
  return response;
};

const setOrRemoveAsFavoriteMessage = async (data) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.post("/v1/messages/favorite", data, {
    headers,
  });
  return response;
};

const getMessageFileFromFileSystem = async (url) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const { data } = await axios.get(url, {
    headers,
    responseType: "blob",
  });

  return data;
};

const updateMessage = async (id, data) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.put(`/v1/messages/${id}`, data, {
    headers,
  });

  return response.data;
};

const setAsReadMessage = async (data) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.post("/v1/messages/read", data, {
    headers,
  });
  return response;
};

const getCustomMedia = async (params) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };
  const response = await axiosInstance.get(`/v1/messages/custom-media`, {
    params,
    headers,
  });
  return response;
};

const MessageService = {
  getMessageConversations,
  getUserSuggestions,
  deleteMessageConversation,
  uploadMessage,
  setOrRemoveAsFavoriteMessage,
  getMessageFileFromFileSystem,
  getPrivateMessages,
  updateMessage,
  setAsReadMessage,
  getCustomMedia,
};

export default MessageService;
