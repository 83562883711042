/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import {
  Brightness5,
  Colorize,
  ColorLens,
  Compare,
  Gradient,
  InvertColors,
  PhotoSizeSelectActual,
} from "@material-ui/icons/";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import "./pictureFilters.css";
import SidebarItem from "./SidebarItem";
import Slider from "./Slider";
import useLocalStorage from "../../forms/services/storageHooks/useLocalStorage";

function PictureFilters({
  onSignup,
  SetOnSignUp,
  editProfile,
  setEditProfile,
  userType,
  setUserType,
  setOnStudio,
}) {
  const { t } = useTranslation();
  const [userTypeFromLocalStore] = useState(sessionStorage.getItem("userType"));

  // Set filter options in a array
  const DEFAULT_OPTIONS = [
    {
      name: t("brightnessFilter"),
      icon: <Brightness5 />,
      property: "brightness",
      value: 100,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
    },
    {
      name: t("contrastFilter"),
      icon: <Compare />,
      property: "contrast",
      value: 100,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
    },
    {
      name: t("saturateFilter"),
      icon: <Colorize />,
      property: "saturate",
      value: 100,
      range: {
        min: 0,
        max: 200,
      },
      unit: "%",
    },
    {
      name: t("grayscaleFilter"),
      icon: <Gradient />,
      property: "grayscale",
      value: 0,
      range: {
        min: 0,
        max: 100,
      },
      unit: "%",
    },
    {
      name: t("sepiaFilter"),
      icon: <PhotoSizeSelectActual />,
      property: "sepia",
      value: 0,
      range: {
        min: 0,
        max: 100,
      },
      unit: "%",
    },
    {
      name: t("hueRotateFilter"),
      icon: <ColorLens />,
      property: "hue-rotate",
      value: 0,
      range: {
        min: 0,
        max: 360,
      },
      unit: "deg",
    },
    {
      name: t("invertFilter"),
      icon: <InvertColors />,
      property: "invert",
      value: 0,
      range: {
        min: 0,
        max: 100,
      },
      unit: "%",
    },
  ];

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [options, setOptions] = useState(DEFAULT_OPTIONS);
  const selectedOption = options[selectedOptionIndex];
  // Get cropped image from localstorage
  const [croppedImg, setCroppedImg] = useLocalStorage({}, "imgBase64Cropped");
  // Post style to Local Storage
  const [ImageStyle, setImageStyle] = useLocalStorage({}, "styleImg");

  let history = useHistory();

  useEffect(() => {
    if (userTypeFromLocalStore === "Model") {
      setUserType("Model");
    }
    if (userTypeFromLocalStore === "Lover") {
      setUserType("Lover");
    }
    if (onSignup === true) {
      setEditProfile(false);
    }
    if (editProfile === true) {
      SetOnSignUp(false);
    }
  }, [
    userTypeFromLocalStore,
    userType,
    setUserType,
    editProfile,
    setEditProfile,
    onSignup,
    SetOnSignUp,
  ]);

  function handleSliderChange({ target }) {
    setOptions((prevOptions) => {
      return prevOptions.map((option, index) => {
        if (index !== selectedOptionIndex) return option;
        return { ...option, value: target.value };
      });
    });
  }

  function getImageStyle() {
    const filters = options.map((option) => {
      return `${option.property}(${option.value}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  const handleRedirect = () => {
    setTimeout(() => {
      editProfile === true && onSignup === false
        ? history.push("/edit-profile")
        : history.push("/sign-up");
    }, 1000);
  };

  return (
    <div className="pictureFilter__container">
      <div className="pictureFilter__mainImage">
        <img
          style={getImageStyle()}
          className="pictureFilter__mainImage__picture"
          src={croppedImg.imageDestination}
          alt="profil"
        />
      </div>
      <h3 style={{ marginLeft: 4 }}>{selectedOption.name}</h3>
      <Slider
        min={selectedOption.range.min}
        max={selectedOption.range.max}
        value={selectedOption.value}
        handleChange={handleSliderChange}
        style={{ marginLeft: -15 }}
      />

      <div className="pictureFilter__sidebar">
        {options.map((option, index) => (
          <SidebarItem
            key={index}
            name={option.name}
            icon={option.icon}
            active={index === selectedOptionIndex}
            handleClick={() => setSelectedOptionIndex(index)}
          />
        ))}
      </div>

      <Button
        className="btn-imageEditor"
        variant="contained"
        onClick={() => {
          setOnStudio(false);
          setImageStyle(getImageStyle());
          handleRedirect();
        }}
      >
        {t("closeButton")}
      </Button>
    </div>
  );
}

export default PictureFilters;
