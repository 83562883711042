import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import VideoPlayer from "../../VideoPlayer";
import React from "react";

function VideoPreviewModal(props) {
  const { isVideoModalVisible, handleCancelVideo, videoFileName, play } = props;
  return (
    <Dialog open={isVideoModalVisible} onClose={handleCancelVideo} fullWidth>
      <DialogTitle id="customized-dialog-title">Video Player</DialogTitle>
      <DialogContent>
        <VideoPlayer fsUrl={videoFileName} playing={play} />
      </DialogContent>
      <DialogActions>
        <button
          type={"button"}
          onClick={handleCancelVideo}
          style={{
            backgroundColor: "white",
            padding: "10px",
            border: "none",
            color: "#ff2775",
            fontSize: "1.1rem",
            fontWeight: "700",
            fontFamily: "Roboto",
          }}
        >
          Close
        </button>
      </DialogActions>
    </Dialog>
  );
}
export default VideoPreviewModal;
