/* eslint-disable no-unused-vars */
import React from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import tickles from "../../assets/images/menu/tickles.svg";
import heartTickles from "../../assets/images/menu/heart_tickles.png";


export const PublicationTickle = (props) => {
  const { handleSendTicklesPublication, userType } = props;
  const { t } = useTranslation();

  return (
    <div>
      <Button
        onClick={(e) => {
            if (userType === "Lover")
                handleSendTicklesPublication(props.publication);
        }}
      >
        {userType === "Lover" ? (
          <img src={heartTickles} className="tickle_icon" alt="tickles" />
        ) : (
          <img src={tickles} alt="tickles" />
        )}
        <sub>{userType === "Lover" ? t("homePageTicklesLover") : `${props.publication.ticklesCount} ${t("feetGirlGuideTicklesTitle")}`}</sub>
      </Button>
    </div>
  );
};
