import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import { BrowserRouter as Router } from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import App from "./App";
import "./assets/css/styles.css";
import "./assets/css/responsive.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./i18n";
import Loader from "./components/loading-page/Loader";
import { SocketContext } from "./context/socket";
import { SocketService } from "./_services/socket.service";
import { SocketEventsProvider } from "./context/SocketProvider";

import NotificationAlertProvider from "./context/NotificationAlertProvider";

Bugsnag.start({
  apiKey: process.env.REACT_APP_API_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_NODE_ENV,
  onError: function (event) {
    const userSession = window.sessionStorage.getItem("infosUser");

    if (userSession) {
      const user = JSON.parse(userSession);
      event.setUser(user?.user?._id, user?.user?.email, user?.user?.username);
    }
  },
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const socket = new SocketService();

ReactDOM.render(
  <ErrorBoundary>
    <NotificationAlertProvider>
      <Router>
        <Suspense fallback={<Loader />}>
          <RecoilRoot>
            <SocketContext.Provider value={socket}>
              <SocketEventsProvider>
                <App />
              </SocketEventsProvider>
            </SocketContext.Provider>
          </RecoilRoot>
        </Suspense>
      </Router>
    </NotificationAlertProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();

/* <React.StrictMode>*/
