import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import useSelectAndRemove from "../../modelsComponents/postPublications/hooks/useSelectAndRemove";
import favoritePictyAtom from "../../../stateManagement/atoms/favoritePictyAtom";

const useFavoritePicty = () => {
  // eslint-disable-next-line no-unused-vars
  const { selectedPictiesList } = useSelectAndRemove();
  const [countEgyptian, setCountEgyptian] = useState(0);
  const [countRoman, setCountRoman] = useState(0);
  const [countGreek, setCountGreek] = useState(0);
  const [countVoyage, setCountVoyage] = useState(0);
  const [countMode, setCountMode] = useState(0);
  const [countSport, setCountSport] = useState(0);
  const [countWater, setCountWater] = useState(0);
  const [countCocooning, setCountCocooning] = useState(0);
  const [countArtiste, setCountArtiste] = useState(0);
  const [countAiguille, setCountAiguille] = useState(0);
  const [countBijoux, setCountBijoux] = useState(0);
  const [countChaussette, setCountChaussette] = useState(0);
  const [countBas, setCountBas] = useState(0);
  const [countBasket, setCountBasket] = useState(0);
  const [countTatouage, setCountTatouage] = useState(0);
  const [countVernis, setCountVernis] = useState(0);
  const [countMirror, setCountMirror] = useState(0);
  const [countPedales, setCountPedales] = useState(0);
  const [countJouer, setCountJouer] = useState(0);
  const [countVue, setCountVue] = useState(0);
  const [countJambe, setCountJambe] = useState(0);
  const [countPiedMain, setCountPiedMain] = useState(0);
  const [countChatouilles, setCountChatouilles] = useState(0);
  const [countCopines, setCountCopines] = useState(0);
  const [countPublic, setCountPublic] = useState(0);
  const [countArche, setCountArche] = useState(0);
  const [countOrteils, setCountOrteils] = useState(0);
  const [countTalons, setCountTalons] = useState(0);
  const [countSelfeet, setCountSelfeet] = useState(0);
  const [countPlisses, setCountPlisses] = useState(0);
  const [countPlantePied, setCountPlantePied] = useState(0);
  const [countOrteilsEcartes, setCountOrteilsEcartes] = useState(0);
  const [countOnglesLongs, setCountOnglesLongs] = useState(0);
  const [countOsseux, setCountOsseux] = useState(0);
  const [countGenereux, setCountGenereux] = useState(0);
  const [countOrteilsCourts, setCountOrteilsCourts] = useState(0);
  const [countOrteilsLongs, setCountOrteilsLongs] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [favoritePictyList, setFavoritePictyList] = useRecoilState(
    favoritePictyAtom
  );

  const HandleCountEgyptian = () => {
    setCountEgyptian((countEgyptian) => countEgyptian + 1);
  };
  const HandleCountGreek = () => {
    setCountGreek((countGreek) => countGreek + 1);
  };
  const HandleCountRoman = () => {
    setCountRoman((countRoman) => countRoman + 1);
  };
  const HandleCountVoyage = () => {
    setCountVoyage((countVoyage) => countVoyage + 1);
  };
  const HandleCountMode = () => {
    setCountMode((countMode) => countMode + 1);
  };
  const HandleCountSport = () => {
    setCountSport((countSport) => countSport + 1);
  };
  const HandleCountWater = () => {
    setCountWater((countWater) => countWater + 1);
  };
  const HandleCountCocooning = () => {
    setCountCocooning((countCocooning) => countCocooning + 1);
  };
  const HandleCountArtiste = () => {
    setCountArtiste((countArtiste) => countArtiste + 1);
  };
  const HandleCountAiguille = () => {
    setCountAiguille((countAiguille) => countAiguille + 1);
  };
  const HandleCountBijoux = () => {
    setCountBijoux((countBijoux) => countBijoux + 1);
  };
  const HandleCountChaussette = () => {
    setCountChaussette((countChaussette) => countChaussette + 1);
  };
  const HandleCountBas = () => {
    setCountBas((countBas) => countBas + 1);
  };
  const HandleCountBasket = () => {
    setCountBasket((countBasket) => countBasket + 1);
  };
  const HandleCountTatouage = () => {
    setCountTatouage((countTatouage) => countTatouage + 1);
  };
  const HandleCountVernis = () => {
    setCountVernis((countVernis) => countVernis + 1);
  };
  const HandleCountMirror = () => {
    setCountMirror((countMirror) => countMirror + 1);
  };
  const HandleCountPedales = () => {
    setCountPedales((countPedales) => countPedales + 1);
  };
  const HandleCountJouer = () => {
    setCountJouer((countJouer) => countJouer + 1);
  };
  const HandleCountVue = () => {
    setCountVue((countVue) => countVue + 1);
  };
  const HandleCountJambe = () => {
    setCountJambe((countJambe) => countJambe + 1);
  };
  const HandleCountPiedMain = () => {
    setCountPiedMain((countPiedMain) => countPiedMain + 1);
  };
  const HandleCountChatouilles = () => {
    setCountChatouilles((countChatouilles) => countChatouilles + 1);
  };
  const HandleCountCopines = () => {
    setCountCopines((countCopines) => countCopines + 1);
  };
  const HandleCountPublic = () => {
    setCountPublic((countPublic) => countPublic + 1);
  };
  const HandleCountArche = () => {
    setCountArche((countArche) => countArche + 1);
  };
  const HandleCountOrteils = () => {
    setCountOrteils((countOrteils) => countOrteils + 1);
  };
  const HandleCountTalons = () => {
    setCountTalons((countTalons) => countTalons + 1);
  };
  const HandleCountSelfeet = () => {
    setCountSelfeet((countSelfeet) => countSelfeet + 1);
  };
  const HandleCountPlisses = () => {
    setCountPlisses((countPlisses) => countPlisses + 1);
  };
  const HandleCountPlantePied = () => {
    setCountPlantePied((countPlantePied) => countPlantePied + 1);
  };
  const HandleCountOrteilsEcartes = () => {
    setCountOrteilsEcartes((countOrteilsEcartes) => countOrteilsEcartes + 1);
  };
  const HandleCountOnglesLongs = () => {
    setCountOnglesLongs((countOnglesLongs) => countOnglesLongs + 1);
  };
  const HandleCountOsseux = () => {
    setCountOsseux((countOsseux) => countOsseux + 1);
  };
  const HandleCountGenereux = () => {
    setCountGenereux((countGenereux) => countGenereux + 1);
  };
  const HandleCountOrteilsCourts = () => {
    setCountOrteilsCourts((countOrteilsCourts) => countOrteilsCourts + 1);
  };
  const HandleCountOrteilsLongs = () => {
    setCountOrteilsLongs((countOrteilsLongs) => countOrteilsLongs + 1);
  };

  useEffect(() => {
    setFavoritePictyList([
      {
        Egyptian: countEgyptian,
        Roman: countRoman,
        Greek: countGreek,
        voyage: countVoyage,
        mode: countMode,
        sport: countSport,
        water: countWater,
        cooconing: countCocooning,
        artiste: countArtiste,
        Aiguille: countAiguille,
        Bijoux: countBijoux,
        chaussette: countChaussette,
        Bas: countBas,
        Basket: countBasket,
        Tatouage: countTatouage,
        Vernis: countVernis,
        Mirror: countMirror,
        Pedales: countPedales,
        Jouer: countJouer,
        Vue: countVue,
        Jambe: countJambe,
        PiedMain: countPiedMain,
        Chatouilles: countChatouilles,
        Copines: countCopines,
        Public: countPublic,
        Arche: countArche,
        Orteils: countOrteils,
        Talons: countTalons,
        Selfeet: countSelfeet,
        Plisses: countPlisses,
        PlantePied: countPlantePied,
        OrteilsEcartes: countOrteilsEcartes,
        OnglesLongs: countOnglesLongs,
        Osseux: countOsseux,
        Genereux: countGenereux,
        OrteilsCourts: countOrteilsCourts,
        OrteilsLongs: countOrteilsLongs,
      },
    ]);
  }, [
    setFavoritePictyList,
    countEgyptian,
    countGreek,
    countMode,
    countRoman,
    countVoyage,
    countAiguille,
    countArtiste,
    countCocooning,
    countSport,
    countWater,
    countArche,
    countBas,
    countBasket,
    countBijoux,
    countChatouilles,
    countChaussette,
    countCopines,
    countGenereux,
    countJambe,
    countJouer,
    countMirror,
    countOnglesLongs,
    countOrteils,
    countOrteilsCourts,
    countOrteilsEcartes,
    countOrteilsLongs,
    countOsseux,
    countPedales,
    countPiedMain,
    countPlantePied,
    countPlisses,
    countPublic,
    countSelfeet,
    countTalons,
    countTatouage,
    countVernis,
    countVue,
  ]);

  useEffect(() => {
    localStorage.setItem("favorite", JSON.stringify(favoritePictyList));
  }, [favoritePictyList]);

  return {
    favoritePictyList,
    setFavoritePictyList,
    countEgyptian,
    setCountEgyptian,
    countAiguille,
    countArche,
    countArtiste,
    countBas,
    countBasket,
    countBijoux,
    countChatouilles,
    countChaussette,
    countCocooning,
    countCopines,
    countGenereux,
    countGreek,
    clicked,
    setClicked,
    HandleCountEgyptian,
    HandleCountGreek,
    HandleCountRoman,
    HandleCountVoyage,
    HandleCountMode,
    HandleCountSport,
    HandleCountWater,
    HandleCountCocooning,
    HandleCountArtiste,
    HandleCountAiguille,
    HandleCountBijoux,
    HandleCountChaussette,
    HandleCountBas,
    HandleCountBasket,
    HandleCountTatouage,
    HandleCountVernis,
    HandleCountMirror,
    HandleCountPedales,
    HandleCountJouer,
    HandleCountVue,
    HandleCountJambe,
    HandleCountPiedMain,
    HandleCountChatouilles,
    HandleCountCopines,
    HandleCountPublic,
    HandleCountArche,
    HandleCountOrteils,
    HandleCountTalons,
    HandleCountSelfeet,
    HandleCountPlisses,
    HandleCountPlantePied,
    HandleCountOrteilsEcartes,
    HandleCountOnglesLongs,
    HandleCountOsseux,
    HandleCountGenereux,
    HandleCountOrteilsCourts,
    HandleCountOrteilsLongs,
  };
};

export default useFavoritePicty;
