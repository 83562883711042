import { selector } from "recoil";

import isFromCamera from "../../atoms/publication/isFromCamera";

const setIsFromCameraPublicationImage = selector({
  key: "setIsFromCameraPublicationImageSelector",
  set: ({ set }, value) => {
    set(isFromCamera, value);
  },
});

export default setIsFromCameraPublicationImage;
