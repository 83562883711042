import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  editProfileAtom,
  onSignupAtom,
  studioAtom,
  userTypeAtom,
} from "../../stateManagement/atoms/studioAtom";
import PictureCropper from "./pictureCropper/PictureCropper";

function ModalCropper(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [onStudio, setOnStudio] = useRecoilState(studioAtom);
  const [userType, setUserType] = useRecoilState(userTypeAtom);
  const editProfile = useRecoilValue(editProfileAtom);
  const onSignUp = useRecoilValue(onSignupAtom);

  let history = useHistory();

  // Custom message for toast notification
  const Msg = () => (
    <div>
      <b style={{ color: "black" }}>{t("cropperToastMessage")}</b>
    </div>
  );
  // Function for displaying notification to screen
  useEffect(() => {
    const displayMsg = () => {
      toast(<Msg />);
    };
    if (onStudio === false) {
      setOnStudio(true);
    }
    displayMsg();
  }, [onStudio, setOnStudio]);

  return (
    <div>
      <div>
        <ToastContainer
          position="top-center"
          autoClose={15000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{top: "5em"}}
        />
        <Modal
          classNames="cropper-modal"
          open={open}
          onClose={() => {
            setOpen(false);
            history.push("/filters-tool");
          }}
          center
        >
          <PictureCropper
            editProfile={editProfile}
            onSignUp={onSignUp}
            onStudio={onStudio}
            setOnStudio={setOnStudio}
            userType={userType}
            setUserType={setUserType}
          />
        </Modal>
      </div>
    </div>
  );
}

export default ModalCropper;
