import React from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

import pictPhone from "../../../assets/images/view-feet-models-profile/picto/authentic_transparent.svg";
import MoviePhone from "../../../assets/images/view-feet-models-profile/picto/video_transparent.svg";

import PublicationMedia from "../../PublicationMedia";

import FeetModelService from "../../../_services/feet-models.service";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import useInfiniteQuery from "../../../hooks/useInfiniteQuery";
import useSessionStorage from "../../forms/services/storageHooks/useSessionStorage";
import { useTranslation } from "react-i18next";

const PublicMediaComponent = () => {
  const history = useHistory();
  let query = useQuery();
  const { t } = useTranslation();


  const [authenticated] = useSessionStorage({}, "infosUser");

  const { data, status, hasNextPage, fetchNextPage } = useInfiniteQuery(
    "feet-model-public-publications",
    async ({ next_offset = 0 }) => {
      const response = await FeetModelService.getFeetModelPublicPublications(
        query.get("user"),
        next_offset
      );

      return {
        data: response.data.publications,
        next_offset: response.data.next_offset,
      };
    }
  );

  const loadMorePublicationsReference = React.useRef();

  useIntersectionObserver({
    target: loadMorePublicationsReference,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  return (
    <div className="public mediaContainer">
      {data && data.length
        ? data.map((publication) => (
            <div
              className="viewFeetModelsProfiles__photos-content"
              key={publication._id}
            >
              {!publication.private && (
                <div
                  className="viewFeetModelsProfiles__pictures"
                  onClick={() =>
                    history.push({
                      pathname: `/publication/${publication._id}`,
                      state: {
                        mediaTab: 0,
                        feetModelsViewRoute: history.location.pathname,
                        search: `?user=${query.get("user")}`,
                      },
                    })
                  }
                >
                  <PublicationMedia
                    publication={publication}
                    className="img-profile-pict"
                    alt="Models profiles"
                    effect="blur"
                    width="100%"
                  />
                  {/*<div className="profileMediaIcons">
                    {publication.type.includes("image") &&
                    publication.isFromCamera ? (
                      <img
                        className="viewFeetModelsProfiles__icon-absol image"
                        src={pictPhone}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                    {publication.type.includes("video") ? (
                      <img
                        className="viewFeetModelsProfiles__icon-absol video"
                        src={MoviePhone}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                  </div>*/}
                </div>
              )}
            </div>
          ))
        : ""}

        {data && data.length ? (
          <>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          </>
        ) : null}

        {status === "loading" && !(data && data.length) ? (
          ""
        ) : data &&
          data.length === 0 &&
          authenticated &&
          authenticated?.user &&
          authenticated.user.userType !== "Model" ? (
          <span style={{ fontSize: "clamp(12px,3vw,16px)" }}>
            {t("No public media yet")}
          </span>
        ) : (
          ""
        )}
  
        <div ref={loadMorePublicationsReference}></div>
    </div>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default PublicMediaComponent;
