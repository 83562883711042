import { useState } from "react";

const useHandleClickChangeIcons = () => {
  const [value, setValue] = useState("home");
  const [clickedStudio, setClickedStudio] = useState(false);
  const [clickedHome, setClickedHome] = useState(false);
  const [clickedSearch, setClickedSearch] = useState(false);
  const [clickedNotifications, setClickedNotifications] = useState(false);
  const [clickedAccount, setClickedAccount] = useState(false);
  const [clickedMessages, setClickedMessages] = useState(false);
  const [clickedSubscriptions, setClickedSubscriptions] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClickHome = () => {
    if (clickedHome !== true) {
      setClickedHome(true);
      setClickedSearch(false);
      setClickedStudio(false);
      setClickedNotifications(false);
      setClickedAccount(false);
      setClickedMessages(false);
      setClickedSubscriptions(false);
    }
  };
  const handleClickSearch = () => {
    if (clickedSearch !== true) {
      setClickedHome(false);
      setClickedSearch(true);
      setClickedStudio(false);
      setClickedNotifications(false);
      setClickedAccount(false);
      setClickedMessages(false);
      setClickedSubscriptions(false);
    }
  };
  const handleClickStudio = () => {
    if (clickedStudio !== true) {
      setClickedHome(false);
      setClickedSearch(false);
      setClickedStudio(true);
      setClickedNotifications(false);
      setClickedAccount(false);
    }
  };
  const handleClickNotifications = () => {
    if (clickedNotifications !== true) {
      setClickedHome(false);
      setClickedSearch(false);
      setClickedStudio(false);
      setClickedNotifications(true);
      setClickedAccount(false);
    }
  };
  const handleClickAccount = () => {
    if (clickedAccount !== true) {
      setClickedHome(false);
      setClickedSearch(false);
      setClickedStudio(false);
      setClickedNotifications(false);
      setClickedAccount(true);
      setClickedMessages(false);
      setClickedSubscriptions(false);
    }
  };
  const handleClickSubscriptions = () => {
    if (clickedSubscriptions !== true) {
      setClickedHome(false);
      setClickedSearch(false);
      setClickedSubscriptions(true);
      setClickedMessages(false);
      setClickedAccount(false);
    }
  };
  const handleClickMessages = () => {
    if (clickedMessages !== true) {
      setClickedHome(false);
      setClickedSearch(false);
      setClickedSubscriptions(false);
      setClickedMessages(true);
      setClickedAccount(false);
    }
  };

  return {
    value,
    clickedHome,
    setClickedHome,
    clickedSearch,
    clickedNotifications,
    clickedAccount,
    handleChange,
    handleClickHome,
    handleClickSearch,
    handleClickStudio,
    handleClickNotifications,
    handleClickAccount,
    clickedMessages,
    clickedSubscriptions,
    handleClickSubscriptions,
    handleClickMessages,
  };
};

export default useHandleClickChangeIcons;
