import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { Button } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import "./followPage.css";
import TabChoiceFollow from "./TabChoiceFollow";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SearchIcon from "../../../assets/images__v3/Recherche_outline_2.png";
import { debounce as _debounce } from "lodash";

import UserService from "../../../_services/user.service";
import FeetLoverService from "../../../_services/feet-lovers.service";
import FeetModelService from "../../../_services/feet-models.service";

import fromFollowersLinkAtom from "../../../stateManagement/atoms/fromFollowersLinkAtom";

const FollowPage = () => {
  let history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();

  let { id } = useParams();

  const [fromFollowersLink] = useRecoilState(fromFollowersLinkAtom);

  //tab choice value
  const [value, setValue] = useState(0);

  const [query, setQuery] = useState(""); // this query will now be for users...
  const [, setSearchQuery] = useState({});
  const [offset, setNextOffset] = useState(0);
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const offSetRef = useRef();
  offSetRef.current = offset;

  /** fetch next page callback function */
  // const fetchNextPage = useCallback(() => setIsFetchingNextPage(true), [
  //   setIsFetchingNextPage,
  // ]);

  const loadMoreUsersReference = useRef();

  // useIntersectionObserver({
  //   target: loadMoreUsersReference,
  //   onIntersect: fetchNextPage,
  //   enabled: offSetRef.current,
  // });

  const onChange = ({ target: { value } }) => {
    setQuery(value);

    const search = _debounce(memoizedSendQueryCallback, 200);

    setSearchQuery((prevSearch) => {
      if (prevSearch.cancel) {
        prevSearch.cancel();
      }
      return search;
    });

    search(value);
  };

  // send query call back for searching user
  const memoizedSendQueryCallback = useCallback(
    async (queryValue = "") => {
      setLoading(true)
      try {
        setIsFetchingNextPage(false);
        // setStatus("loading");

        const response = await UserService.searchFollowersOrFollowing(
          queryValue,
          id,
          value === 0 ? "follower" : "following"
        );
        if (response.cancelPrevQuery) return;

        if (response && response.users) {
          // setStatus("success");
          setUsers([...response.users]);
          //.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          setNextOffset(response.next_offset);

          // if (response.meta.count > 10) {
          //   setIsFetchingNextPage(true)
          // }
          setLoading(false)
        }
      } catch (error) {
        // setStatus("error");
        setLoading(false)
        throw new Error(error);
      }
    },
    [id, value]
  );

  //on mount
  useEffect(() => {
    // setStatus("loading");

    if (id) {
      setLoading(true)
      FeetModelService.getFeetModelProfileById(id)
        .then((response) => {
          setUser(response);
          setLoading(false)
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status === 404
          ) {
            FeetLoverService.getFeetLoverProfile(id).then((response) => {
              setUser(response);
              setLoading(false)
            });
          }
        });
    }
  }, [id]);

  //watch if value or tab panel is changed from followers to following and vice versa.
  useEffect(async () => {
    // setStatus("loading");
    setLoading(true)

    if (id) {
        try {
          offSetRef.current = 0;

          const response = await UserService.searchFollowersOrFollowing(
            query,
            id,
            value === 0 ? "follower" : "following",
            offSetRef.current
          );

          if (response && !response.cancelPrevQuery) {
            // setStatus("success");
            setUsers([...response.users]);
            //.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            setNextOffset(response.next_offset);
            setTotalCount(response.meta.count);
            // if (response.meta.count > 10) {
            //   setIsFetchingNextPage(true)
            // }
            setLoading(false)
          }
        } catch (error) {
          setLoading(false)
          throw new Error(error);
        }
    }
  }, [value, location]);

  useEffect(() => {
    if (fromFollowersLink === true) {
      return setValue(0);
    }
    if (fromFollowersLink === false) {
      return setValue(1);
    }
  }, [fromFollowersLink]);

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.data &&
      location.state.data === "followers"
    ) {
      setValue(0);
    }

    if (
      location &&
      location.state &&
      location.state.data &&
      location.state.data === "following"
    ) {
      setValue(1);
    }
  }, [location]);

  // fetch more followers or following if it has next page
  React.useEffect(() => {
    // setLoading(true)
    if (isFetchingNextPage && (totalCount === 0 || totalCount > users.length)) {
      UserService.searchFollowersOrFollowing(
        query,
        id,
        value === 0 ? "follower" : "following",
        offSetRef.current
      ).then((response) => {
        setIsFetchingNextPage(false);
        // if (response && !response.cancelPrevQuery) {
        //   // setStatus("success");
        //   setUsers([...users, ...response.users].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)));
        //   setNextOffset(response.next_offset);
        // }
        if (response && response.users && response.users.length) {
          setUsers([...users, ...response.users]);
          //.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          setTotalCount(response.meta.count);
          if (response.meta.count > offSetRef.current) {
            setNextOffset(response.next_offset);
          }


          // if (response.meta.count > offSetRef.current) {
          //   setIsFetchingNextPage(true)
          // }
        }
        // setLoading(false)
      });
    } else setIsFetchingNextPage(false)
  }, [isFetchingNextPage]);

  return (
    <div className="FollowPage__container">
      <div className="FollowPage__Header page_fixed_header">
        <Button onClick={() => history.goBack()} className="back__button">
          <ChevronLeft />
        </Button>
        <h4>{user && user.profile && user.profile.username}</h4>
      </div>
      <div className="content__withHeader minmax__wrapper">
        <div className="FollowPage__inputContainer searchInput__wrapper">
          <input
            className="FollowPage__input"
            type="text"
            placeholder="rechercher"
            value={query}
            onChange={onChange}
          />
          <img id="searchIcon" src={SearchIcon} alt="search" />
        </div>
        <div className="FollowPage__tabChoice">
          <TabChoiceFollow
            value={value}
            setValue={setValue}
            users={users}
            user={user}
            id={id}
            loadMoreUsersReference={loadMoreUsersReference}
            setUsers={setUsers}
            loading={loading}
            setIsFetchingNextPage={setIsFetchingNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </div>
      </div>
    </div>
  );
};

export default FollowPage;
