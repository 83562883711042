import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const getNotifications = async (offset = 0, limit = 100) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
    `/v1/notifications?offset=${offset}&limit=${limit}`,
    {
      headers,
    }
  );

  return response;
};

const countGeneralNotifications = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
      `/v2/notifications/general/count-unread`,
      {
        headers,
      }
  );

  return response;
};

const countCommentsUnreadNotification = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
      `/v2/notifications/comments/count-unread`,
      {
        headers,
      }
  );

  return response;
};
const countLikesUnreadNotification = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
      `/v2/notifications/likes/count-unread`,
      {
        headers,
      }
  );

  return response;
};

const countMessagesUnreadNotification = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
      `/v2/notifications/messages/count-unread`,
      {
        headers,
      }
  );

  return response;
};
const countTicklesUnreadNotification = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
      `/v2/notifications/tickles/count-unread`,
      {
        headers,
      }
  );

  return response;
};

const countSubscriptionsUnreadNotification = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
      `/v2/notifications/subscriptions/count-unread`,
      {
        headers,
      }
  );

  return response;
};

const readAllNotifications = async (offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.post(
    `/v1/notifications/readAll`,
    {},
    { headers }
  );

  return response;
};

const readAllNotificationsWithType = async (type) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.post(
      `/v2/notifications/readAll`,
      {type},
      { headers }
  );

  return response;
};

const NotificationService = {
  getNotifications,
  readAllNotifications,
  readAllNotificationsWithType,
  countTicklesUnreadNotification,
  countCommentsUnreadNotification,
  countLikesUnreadNotification,
  countMessagesUnreadNotification,
  countGeneralNotifications,
  countSubscriptionsUnreadNotification
};

export default NotificationService;
