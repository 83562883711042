import { useState } from "react";

import { useRecoilState } from "recoil";
import passwordVisibilityAtom from "../../../stateManagement/atoms/passwordVisibilityAtom";

const usePasswordVisibility = () => {
  const [
    togglePasswordVisibility,
    setTogglePasswordVisibility,
  ] = useRecoilState(passwordVisibilityAtom);

  const [
    toggleConfirmNewPasswordVisibility,
    setToggleConfirmNewPasswordVisibility,
  ] = useState(false);

  function handlePasswordVisibility() {
    if (!togglePasswordVisibility) {
      setTogglePasswordVisibility(true);
    } else {
      setTogglePasswordVisibility(false);
    }
    var x = document.getElementById("signin-input");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  function handleConfirmNewPasswordVisibility() {
    if (!toggleConfirmNewPasswordVisibility) {
      setToggleConfirmNewPasswordVisibility(true);
    } else {
      setToggleConfirmNewPasswordVisibility(false);
    }

    var confirmNewPassword = document.getElementById(
      "confirm-new-password-input"
    );
    if (confirmNewPassword.type === "password") {
      confirmNewPassword.type = "text";
    } else {
      confirmNewPassword.type = "password";
    }
  }

  return {
    togglePasswordVisibility,
    setTogglePasswordVisibility,
    handlePasswordVisibility,
    toggleConfirmNewPasswordVisibility,
    handleConfirmNewPasswordVisibility,
  };
};

export default usePasswordVisibility;
