import {
  isEqual as _isEqual,
  isObject as _isObject,
  transform as _transform,
} from "lodash";
import { not } from "ramda";

export const truncate = (input) =>
  input.length > 20 ? `${input.substring(0, 20)}...` : input;

export const _objectDiffer = (modified, original) => {
  function changes(modified, original) {
    return _transform(modified, function (result, value, key) {
      if (not(_isEqual(value, original[key]))) {
        result[key] =
          _isObject(value) && _isObject(original[key])
            ? changes(value, original[key])
            : value;
      }
    });
  }
  return changes(modified, original);
};

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function imagetoblob(ImageURL) {
  // Split the base64 string in data and contentType
  var block = ImageURL.split(";");
  // Get the content type of the image
  var contentType = block[0].split(":")[1]; // In this case "image/gif"
  // get the real base64 content of the file
  var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

  // Convert it to a blob to upload
  return b64toBlob(realData, contentType);
}

export function isVideo(file) {
  return !!file.type.match("video.*");
}
