import React from "react";
import { useRecoilValue } from "recoil";
import { ToastContainer } from "react-toastify";

import "./baseLayoutApp.css";
import {
  studioAtom,
  userTypeAtom,
} from "../../../stateManagement/atoms/studioAtom";
import BottomNavigationLover from "../shared/bottomNavigationLovers/BottomNavigationLovers";
import BottomNavigationModel from "../shared/bottomNavigationModels/BottomNavigationModels";
import { getAuthSession } from "../../../helpers/auth.helpers";

const BaseLayoutApp = (props) => {
  const { children, location } = props;
  const onStudio = useRecoilValue(studioAtom);
  const userType = useRecoilValue(userTypeAtom);

  return (
    <div className="layout-container-App">
      {/* <HeaderApp />*/}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{top: "5em"}}
      />
      <main className="cover-App">
        <div className="wrapper-App">{children}</div>
      </main>

      {/* Check if there is authenticated user first before showing the bottom navigation */}
      {getAuthSession() &&
        getAuthSession()?.user &&
        getAuthSession()?.user._id && (
          <div
            className={
              onStudio === false ? "bottomAppLayout" : "bottomLayout-none"
            }
          >
            {userType === "Model" ||
            getAuthSession()?.user?.userType === "Model" ? (
              location && location !== "/hello-picture" ? (
                <BottomNavigationModel />
              ) : null
            ) : (
              <BottomNavigationLover />
            )}
          </div>
        )}
    </div>
  );
};

export default BaseLayoutApp;
