import React, { Fragment, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { useHistory, useLocation } from "react-router-dom";

import "./subscribeToUnlockPublication.css";
import ModalContent from "./SubscribeToUnlockPublicationModalContent";

function getModalStyle() {
  const top = 47;
  const left = 48;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    border: "none !important",
  };
}

export default function SubscribeToUnlockPublication({
  userType,
  setUserType,
}) {
  // getModalStyle is not a pure function, we roll the style only on the first render
  let history = useHistory();
  const location = useLocation();

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(true);
  const [feetModelProfile, setFeetModelProfile] = React.useState({});

  useEffect(() => {
    if (location.state?.feetModelProfile) {
      setFeetModelProfile(location.state.feetModelProfile);
    }
  }, [location]);

  const handleClose = () => {
    setOpen(false);
    feetModelProfile.profile
      ? history.push({
          pathname: "/feet-models-view",
          search: `?user=${feetModelProfile.profile._id}`,
        })
      : history.push("/sign-up");
  };

  // Boby of the modal content
  const body = (
    <div
      style={modalStyle}
      className="SubscribeToUnlockPublication__paper modal-Subscribe"
    >
      <ModalContent
        userType={userType}
        setUserType={setUserType}
        feetModelProfile={feetModelProfile}
        handleClose={handleClose}
      />
    </div>
  );

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="SubscribeToUnlockPublication-simple-modal-title"
        aria-describedby="SubscribeToUnlockPublication-simple-modal-description"
      >
        {body}
      </Modal>
    </Fragment>
  );
}
