/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { ChevronLeft } from "@material-ui/icons";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { uniqBy as _uniqBy } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { Badge } from "@material-ui/core/";

import "./notificationsModels.css";
import LikesIcon from "../../../assets/images/notifications-models/likes.png";
import CommentsIcon from "../../../assets/images/notifications-models/commentaires.png";
import TicklesIcon from "../../../assets/images/notifications-models/Group 54498@2x.png";
import SubscriptionsIcon from "../../../assets/images/notifications-models/Path 201000@2x.png";
import { IconButton } from "@material-ui/core";
import useNotificationsModels from "./hooks/useNotificationsModels";
import commentsNotificationsQuery from "../../../stateManagement/selectors/commentsNotificationsSelector";
import likesNotificationsQuery from "../../../stateManagement/selectors/likesNotificationsSelector";
import ticklesNotificationsQuery from "../../../stateManagement/selectors/ticklesNotificationsSelector";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import likesNotificationsAtom from "../../../stateManagement/atoms/notifications/likes";
import commentsNotificationsAtom from "../../../stateManagement/atoms/notifications/comments";
import ticklesNotificationsAtom from "../../../stateManagement/atoms/notifications/tickles";
import countNotificationAtom from "../../../stateManagement/atoms/notifications/count";
import { userTypeAtom } from "../../../stateManagement/atoms/studioAtom";
import { useHistory } from "react-router-dom";

import setBadgeInvisibleSelector from "../../../stateManagement/selectors/notifications/setBadgeVisible";
import setNotificationLikeBadgeInVisibleSelector from "../../../stateManagement/selectors/notifications/badge/setLikeBadgeVisible";
import setNotificationCommentBadgeInVisibleSelector from "../../../stateManagement/selectors/notifications/badge/setCommentBadgeInvisible";
import setNotificationTickleBadgeInVisibleSelector from "../../../stateManagement/selectors/notifications/badge/setTickleBadgeInvisible";

import useInfiniteQuery from "../../../hooks/useInfiniteQuery";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";

import LikeService from "../../../_services/like.service";
import { getNotificationComments } from "../../../_services/comment.service";
import TickleService from "../../../_services/tickle.service";
import likeBadgeAtom from "../../../stateManagement/atoms/notifications/badge/like";
import commentBadgeAtom from "../../../stateManagement/atoms/notifications/badge/comment";
import tickleBadgeAtom from "../../../stateManagement/atoms/notifications/badge/tickle";
import SubscribeService from "../../../_services/subscribe.service";
import feetModelOwnProfileQuery from "../../../stateManagement/selectors/feetModelOwnProfileSelector";
import useSessionStorage from "../../forms/services/storageHooks/useSessionStorage";
import feetModelAtom from "../../../stateManagement/atoms/feetModelAtom";
import MessagesIcon from "../../../assets/images/home-page-models/messages-icon.svg";
import newMessageBadgeAtom from "../../../stateManagement/atoms/newMessageBadgeAtom";
import GeneralNotificationModels from "./GeneralNotificationModels";
import generalNotificationsQuery from "../../../stateManagement/selectors/generalNotificationSelector";
import NotificationService from "../../../_services/notification.service";
import { useTranslation } from "react-i18next";
import CommentNotificationModels from "./CommentNotificationModels";
import NotificationTickles from "./NotificationTickles";
import NotificationLikes from "./NotificationLikes";
import NotificationContent from "./NotificationContent";

export const StyledBadge = withStyles((theme) => ({
  badge: {
    padding: "0",
    borderRadius: "50%",
    width: "6px",
    lineHeight: "8px",
    height: "6px",
    // bottom:'-5px !important'
    top: "10px",
    left: "-18px",
    minWidth: "6px !important"
  },
}))(Badge);

const NotificationsModels = (props) => {
  let history = useHistory();

  const [authUser] = useSessionStorage({}, "infosUser");
  const [__, setFeetModel] = useRecoilState(feetModelAtom);

  const {
    clickedLikes,
    clickedComments,
    clickedTickles,
    noSubscriptionsYet,
    setNoSubscriptionsYet,
    clickedSubscriptions,
    NoNotificationsYet,
    LikesNotifications,
    CommentsNotifications,
    TicklesNotifications,
    SubscriptionsNotifications,
    deployComments,
    handleDeployComments,
    deployTickles,
    handleDeployTickles,
    deployLikes,
    handleDeployLikes,
    deploySubscriptions,
    handleDeploySubscriptions,
    handleCheckReadAllNotifications
  } = useNotificationsModels();

  // remote state notifications
  const [isInvisibleLikeBadge] = useRecoilState(likeBadgeAtom);
  const [isInvisibleTickleBadge] = useRecoilState(tickleBadgeAtom);
  const [newMessageBadge] = useRecoilState(newMessageBadgeAtom);



  const { t } = useTranslation();
  // const {
  //   data: comments,
  //   hasNextPage: commentsHasNextPage,
  //   fetchNextPage: commentsFetchNextPage,
  // } = useInfiniteQuery("notification-comments", async ({ next_offset = 0 }) => {
  //   const response = await getNotificationComments(next_offset);
  //
  //   return {
  //     data: response.data.comments,
  //     next_offset: response.data.next_offset,
  //   };
  // });







  // global state notifications
  const [userType, setUserType] = useRecoilState(userTypeAtom);
  const [likesNotifications] = useRecoilState(likesNotificationsAtom);
  const [ticklesNotifications] = useRecoilState(ticklesNotificationsAtom);

  // local state notifications
  const [allTickles, setAllTickles] = useState([]);
  const [allSubscribers, setAllSubscribers] = useState([]);

  const [_, setCount] = useRecoilState(countNotificationAtom);

  const setNotificationBadgeInVisible = useSetRecoilState(
    setBadgeInvisibleSelector
  );
  const setNotificationLikeBadgeInVisible = useSetRecoilState(
    setNotificationLikeBadgeInVisibleSelector
  );
  const setNotificationCommentBadgeInVisible = useSetRecoilState(
    setNotificationCommentBadgeInVisibleSelector
  );
  const setNotificationTickleBadgeInVisible = useSetRecoilState(
    setNotificationTickleBadgeInVisibleSelector
  );








  // const loadMoreNotificationReference = useRef();
  //
  // useIntersectionObserver({
  //   target: loadMoreNotificationReference,
  //   onIntersect: notificationFetchNextPage(),
  //   enabled: notificationHasNextPage,
  // });












  // reset badge notification to invisible
  useEffect(() => {
    return () => {
      setNotificationBadgeInVisible(true);
      setNotificationLikeBadgeInVisible(true); // todo: move to when likes drop down is clicked?
      setNotificationCommentBadgeInVisible(true);
      setNotificationTickleBadgeInVisible(true);
    };
  }, [
    setNotificationBadgeInVisible,
    setNotificationLikeBadgeInVisible,
    setNotificationCommentBadgeInVisible,
    setNotificationTickleBadgeInVisible,
  ]);

  useEffect(() => {
    if (userType !== "Model") {
      setUserType("Model");
    }
  }, [setUserType, userType]);

  return (
    <div
      // className={
      //   likesMeta.count <= 0
      //     ? "notificationsModels__container emptyPadding"
      //     : "notificationsModels__container alternatePadding"
      // }
        className="notificationsModels__container alternatePadding"
      //notificationsModels__container emptyPadding
      //notificationsModels__container alternatePadding
    >
      <div className="notificationsModels__header header page_fixed_header">
        <IconButton className="back__button" onClick={() => history.goBack()}>
          <ChevronLeft />
        </IconButton>
        <h4>Notifications</h4>
        <IconButton
          className="homePageModels__messages header__rightButtton"
          onClick={() => history.push("/messages")}
        >
          <img src={MessagesIcon} alt="message icon" />
          {newMessageBadge && (
            <span className="homePageModels__onMessages"></span>
          )}
        </IconButton>
      </div>
      <NotificationContent handleCheckReadAllNotifications={handleCheckReadAllNotifications}/>
    </div>
  );
};

export default NotificationsModels;
