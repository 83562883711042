import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import { IconButton } from "@material-ui/core/";
import { ChevronLeft } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import "./styled.css";
import Button from "../../components/Button";
import { studioAtom } from "../../stateManagement/atoms/studioAtom";
import getUserQuery from "../../stateManagement/selectors/getUserSelector";

function IdentifyMySelfPage() {
  const history = useHistory();
  const { t } = useTranslation();

  const [_, setOnStudio] = useRecoilState(studioAtom);
  const { user } = useRecoilValue(getUserQuery);

  useEffect(() => {
    setOnStudio(true);

    return () => {
      setOnStudio(false);
    };
  }, [setOnStudio]);

  useEffect(() => {
    if (user && user?.idCheckDetails && !user?.idCheckDetails?.verified)
      history.replace("/identity-verification");
  }, [user]);

  return (
    <div className="identify-myself-container">
      {user && user?.idCheckDetails && user?.idCheckDetails?.verified ? (
        <>
          <div className="profileForm__header header page_fixed_header edit-profile-header__v3">
            <IconButton
              className="back__button"
              onClick={() =>
                user.userType === "Lover"
                  ? history.push("/settings-lovers")
                  : history.push("/setting-models")
              }
            >
              <ChevronLeft style={{ fill: "black" }} />
            </IconButton>
            <h4>{t("Check your profile")}</h4>
          </div>
          <h4>{t("Your profile has already been verified")}</h4>
        </>
      ) : (
        <div className="identify-myself-content">
          <span>
            We are concerned about the safety of our members. To download your
            first media please login here
          </span>

          <Button
            onClick={() => history.push("/identity-verification")}
            type="submit"
            width="clamp(100%, 4vw, 24.5rem)"
            background="#F35162"
            height="clamp(42px, 4vw, 52px)"
          >
            {t("I identify myself")}
          </Button>
        </div>
      )}
    </div>
  );
}

export default IdentifyMySelfPage;
