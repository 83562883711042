import { makeStyles } from "@material-ui/core/styles";

export const useStyles = (props) =>
  makeStyles((theme) => ({
    root: {
      "& .button": {
        background: props.background,
        color: props.color,
        width: `${props.width}`,
        padding: props.padding,
        borderRadius: props.borderRadius,
        "text-transform": "none",
        "font-size": props.fontSize,
        height: `${props.height}`,
        ...(props.boxShadow && { boxShadow: props.boxShadow }),
      },
    },
  }));
