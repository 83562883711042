/* eslint-disable no-mixed-operators */
import { atom } from "recoil";

const isReceivedMediasAtom = atom({
  key: "isReceivedMediasState",
  default: true,
  /*     localStorage.getItem("isReceivedMedias") &&
    localStorage.getItem("isReceivedMedias") === "true"
      ? true
      : false, */
});

export default isReceivedMediasAtom;
