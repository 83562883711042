import imageCompression from "browser-image-compression";
import Bugsnag from "@bugsnag/js";
import loadImage from "blueimp-load-image";

// this will fix the orientation issue of images specially from iphone devices.
const loadImageAndAutomaticOrientation = (file) =>
  new Promise((resolve) => {
    loadImage(
      file,
      (img) => {
        if (img.toBlob) {
          img.toBlob((blob) => {
            resolve(blob);
          });
        }
      },
      { meta: true, orientation: true, canvas: true }
    );
  });

/* This reduced the quality of the image a little bit.  */
export const handleImageCompression = async (file) => {
  const options = {
    maxSizeMB: 1,
    useWebWorker: true,
  };

  try {
    // fix rotate issue before compression and upload to server.
    const data = await loadImageAndAutomaticOrientation(file);

    const compressedFile = await imageCompression(data, options);

    return compressedFile;
  } catch (error) {
    Bugsnag.notify(error);

    // if image compression fails. Just return the original file with image rotated
    const data = await loadImageAndAutomaticOrientation(file);

    return data;
  }
};
