import React from "react";

import "./pictureModal.css";

const PictureModal = ({ messagePicture }) => {
  return (
    <div className="PictureModal__Container">
      <div className="modal-container">
        <input id="modal-toggle" type="checkbox" />
        <button style={{ color: "#333", fontSize: 11, fontWeight: "bold" }}>
          Click To Open
        </button>
        <div className="modal-backdrop">
          <div className="modal-content">
            <label className="modal-close" htmlFor="modal-toggle">
              x
            </label>
            <img
              className="createMessage__pictureSended"
              src={messagePicture}
              alt="message"
            />
            {/* <label className="modal-close button" htmlFor="modal-toggle">OK</label>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PictureModal;
