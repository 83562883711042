import { selector } from "recoil";

import signUpUserDataAtom from "../../atoms/auth/sign-up-user-data-atom";

const setSignUpUserData = selector({
  key: "set-sign-up-user-data-selector",
  set: async ({ set }, value) => {
    sessionStorage.setItem("sign-up-user-data", JSON.stringify(value));
    set(signUpUserDataAtom, value);
  },
});

export default setSignUpUserData;
