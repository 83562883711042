import { ChevronLeft } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import "./subscriptionPayment.css";
import Visa from "../../../assets/images/subscription-payment/visa@2x.png";
import Master from "../../../assets/images/subscription-payment/mastercard@2x.png";
import AmExp from "../../../assets/images/subscription-payment/american-express@2x.png";
import { Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import PaymentService from "../../../_services/payment.service";
import SubscribeService from "../../../_services/subscribe.service";

const SubscriptionPayment = () => {
  let history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const [feetModelProfile, setFeetModelProfile] = React.useState({});
  const [paymentData, setPaymentData] = useState({
    type: "Subscription",
    paymentDetails: {},
  });
  const [paymentCardDetails, setPaymentCardDetails] = useState({
    rememberCard: false,
  });

  useEffect(() => {
    if (location.state?.feetModelProfile) {
      setFeetModelProfile(location.state.feetModelProfile);
      setPaymentData((prev) => ({
        ...prev,
        paymentDetails: { ...location.state.feetModelProfile.profile },
      }));
    }
  }, [location]);

  const handleChangeCardDetails = (e) => {
    const { id, value } = e.target;
    setPaymentCardDetails((prevState) => ({
      ...prevState,
      [id]: value,
      ...(id === "rememberCard" && {
        rememberCard: !prevState.rememberCard,
      }),
    }));
  };

  const handlePay = async () => {
    try {
      const response = await PaymentService.sendPayment({
        ...paymentData,
        paymentCardDetails,
      });
      // successful payment -> subscribe api.
      await SubscribeService.sendSubscribe({
        subscribedTo: feetModelProfile.profile._id,
        subscribedAmount: feetModelProfile.profile.subscriptionRate,
        autoRenewed: true,
        validTill: "",
        startDate: moment().format("DD MMM YYYY"),
        endDate: moment().add(30, "days").format("DD MMM YYYY"),
      });

      history.push("/success-payment");
    } catch (error) {
      console.log("tickle payment error: ", error);
    }
  };

  return (
    <div className="subscriptionPayment__container">
      <div className="subscriptionPayment__header header header-height">
        <ChevronLeft />
        <h4>{t("subscriptionPaymentTitle")}</h4>
      </div>
      <div className="subscriptionPayment__card">
        <div className="subscriptionPayment__cardTextTop">
          <b>{t("monthlySubscriptionTop")}</b>
          <b>
            €
            {feetModelProfile &&
              feetModelProfile.profile &&
              feetModelProfile.profile.subscriptionRate}
          </b>
        </div>
        <div className="subscriptionPayment__cardDate">
          <p>
            {t("startDateSubscription")} ( {moment().format("DD MMM YYYY")} )
          </p>
          <p>
            {t("endDateSubscription")} (
            {moment().add(30, "days").format("DD MMM YYYY")})
          </p>
        </div>
      </div>
      <div className="subscriptionPayment__cardImg">
        <img src={Visa} alt="visa" className="subscriptionPayment__visa" />
        <img src={Master} alt="visa" className="subscriptionPayment__master" />
        <img src={AmExp} alt="visa" className="subscriptionPayment__amExp" />
      </div>
      <form className="subscriptionPayment__form">
        <label>{t("nameTitleSignup")}</label>
        <input
          type="text"
          placeholder={t("nameTitleSignup")}
          id="name"
          onChange={handleChangeCardDetails}
        />
        <label>{t("cardNumberSubscriptionPayment")}</label>
        <input
          type="number"
          placeholder={t("cardNumberSubscriptionPayment")}
          onChange={handleChangeCardDetails}
          id="cardNumber"
        />
        <div className="subscriptionPayment__expireDateLabel">
          <label>{t("expireDateCardSubscriptionPayment")}</label>
          <label>CVV</label>
        </div>
        <div className="subscriptionPayment__expireDateInput">
          <input
            type="date"
            className="subsciptionPayment__date"
            onChange={handleChangeCardDetails}
            id="expirationDate"
          />
          <input
            type="number"
            placeholder="CVV"
            onChange={handleChangeCardDetails}
            id="cvv"
          />
        </div>
        <div className="subscriptionPayment__checkbox">
          <input
            type="checkbox"
            onChange={handleChangeCardDetails}
            id="rememberCard"
            value={paymentCardDetails.rememberCard}
          />
          <p>{t("rememberCardSubscriptionPayment")}</p>
        </div>
      </form>
      <br />
      <div className="subscriptionPayment__button">
        <p>{t("securedPaymentSubscriptionPayment")}</p>
        <Button
          className="subscriptionPaymentButton__button"
          variant="contained"
          onClick={() => handlePay()}
        >
          {t("paySubscriptionPayment")}
        </Button>
      </div>
      <br />
      <br />
    </div>
  );
};

export default SubscriptionPayment;
