import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const getFeetLoverProfile = async (id) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/feet-lovers/${id}/profile`, {
    headers,
  });

  return response.data;
};

const getOwnFeetLoverProfile = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/feet-lovers/profile`, {
    headers,
  });

  return response.data;
};

const checkIfSubscribed = async (id) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
    `/v1/feet-lovers/subscriptions/user/${id}`,
    {
      headers,
    }
  );

  return response;
};

const requestCustomMedia = async (id, data) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const response = await axiosInstance.post(
      `/v1/feet-lovers/${id}/request-custom-media`,
      data,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const FeetLoverService = {
  getFeetLoverProfile,
  getOwnFeetLoverProfile,
  checkIfSubscribed,
  requestCustomMedia,
};

export default FeetLoverService;
