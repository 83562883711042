import React from "react";
import "./SwipeableList.css";

const SwipeableList = (props) => {
  const { children } = props;

  const childrenWithProps = React.Children.map(children, (child) => {
    if (!child.props.background) {
      return React.cloneElement(child, { background: props.background });
    }
    return child;
  });

  return <div className="List">{childrenWithProps}</div>;
};

export default SwipeableList;
