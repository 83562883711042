/* eslint-disable no-mixed-operators */
import { atom } from "recoil";

const isPushNotificationAtom = atom({
  key: "isPushNotificationState",
  default:
    (localStorage.getItem("isPush") === null && false) ||
    localStorage.getItem("isPush") === "true"
      ? true
      : true,
});

export default isPushNotificationAtom;
