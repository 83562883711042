import { axiosInstance } from "./base";

const signIn = async (data) => {
  const response = await axiosInstance.post("/auth/signin", data);

  return response;
};

const signUp = async (data, code) => {
  const response = await axiosInstance.post("/auth/signup", data, {params: {code}});

  return response;
};

const resetPassword = async (token, data) => {
  const response = await axiosInstance.put(
    `/auth/reset-password/token/${token}`,
    data
  );

  return response;
};

const forgotPassword = async (data) => {
  const response = await axiosInstance.post(`/auth/reset-password`, data);

  return response;
};

const getRegistrationStatus = async () => {
  const response = await axiosInstance.get(`/auth/check-registration-status`);

  return response;
};

const subscribeEmail = async (data) => {
  const response = await axiosInstance.post(
    "/auth/subscribe_official_launch",
    data
  );

  return response;
};

const verifyCaptcha = async (data) => {
  return await axiosInstance.post("auth/captcha-verification", data);
};

const AuthService = {
  signIn,
  signUp,
  resetPassword,
  forgotPassword,
  getRegistrationStatus,
  subscribeEmail,
  verifyCaptcha,
};

export default AuthService;
