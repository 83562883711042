/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useCamera } from "@ionic/react-hooks/camera";
import { base64FromPath, useFilesystem } from "@ionic/react-hooks/filesystem";

import {
  CameraResultType,
  CameraSource,
  FilesystemDirectory,
} from "@capacitor/core";
import { useHistory } from "react-router-dom";

export function usePhotoGallery() {
  let history = useHistory();
  const { deleteFile, getUri, readFile, writeFile } = useFilesystem();
  const { getPhoto } = useCamera();
  const [photos, setPhotos] = useState([]);

  const takePhoto = async () => {
    const cameraPhoto = await getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 200,
    });
    const fileName = new Date().getTime() + ".jpeg";
    const savedFileImage = await savePicture(cameraPhoto, fileName);
    const newPhotos = [
      {
        filepath: fileName,
        webviewPath: cameraPhoto.webPath,
      },
      ...photos,
    ];
    setPhotos(newPhotos);
  };

  const savePicture = async (photo, fileName) => {
    const base64Data = await base64FromPath(photo.webPath);
    const savedFile = await writeFile({
      path: fileName,
      data: base64Data,
      directory: FilesystemDirectory.Data,
    });

    window.sessionStorage.setItem("publicationPicture", base64Data);
    history.push("/studio-cropper-step");

    // Use webPath to display the new image instead of base64 since it's
    // already loaded into memory
    return {
      filepath: fileName,
      webviewPath: photo.webPath,
    };
  };

  return {
    photos,
    takePhoto,
    savePicture,
  };
}
