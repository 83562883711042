import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./loader.css";
import { useStyles } from "./styled";
import logo from "../../assets/images/logo.png";

function Loader({ userType, progress }) {
  const classes = useStyles();

  return (
    <div className="loader-container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
        }}
      >
          {progress ? <CircularProgress
          color="secondary"
          size="52vmin"
          thickness={1}
          variant="indeterminate"
          value={progress}
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
        /> : <CircularProgress
              color="secondary"
              size="52vmin"
              thickness={1}
              variant="indeterminate"
              disableShrink
              className={classes.top}
              classes={{
                  circle: classes.circle,
              }}
          />}
      </div>
      <div className="App-logo">
        <img src={logo} className="logo-picture" alt="logo" />
          {progress && <div style={{color: '#000', position: 'fixed', bottom: '150px'}}><p>{parseInt(progress)}%</p></div>}
      </div>
    </div>
  );
}

export default Loader;
