import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { useStyles } from "./styled";
import CheckSolidIcon from "../../assets/images__v3/Certification_solid.png";
import TicklesOne from "../../assets/images__v3/tickles_1.png";
import TicklesTwo from "../../assets/images__v3/tickles_2.png";
import TicklesThree from "../../assets/images__v3/tickles_3.png";
import TicklesFour from "../../assets/images__v3/tickles_4.png";
import TicklesFive from "../../assets/images__v3/tickles_5.png";
import TicklesSix from "../../assets/images__v3/tickles_6.png";
import TransactionService from "../../_services/transaction.service";
import useSessionStorage from "../forms/services/storageHooks/useSessionStorage";

import DialogTitle from "./DialogTitle";
import EpochPrices from "../../common/EpochPrices";
import { useTranslation } from "react-i18next";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function BuyTickle(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const tickleIcons = [
    TicklesOne,
    TicklesTwo,
    TicklesThree,
    TicklesFour,
    TicklesFive,
    TicklesSix,
  ];

  const [authenticated] = useSessionStorage({}, "infosUser");

  const { openBuyTickleModal, setOpenBuyTickleModal, epochPrices, ...rest } =
    props;

  const [selectedTickleProduct, setSelectedTickleProduct] = useState("");
  const [epochProductLists, setEpochProductLists] = useState([]);
  const [openInfoMessage, setOpenInfoMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPriceSelected, setPriceSelected] = useState(false);

  useEffect(() => {
    if (
      epochPrices &&
      epochPrices.length > 0 &&
      authenticated.user.userType === "Lover"
    ) {
      setIsLoading(true);
      setEpochProductLists(epochPrices);
      setIsLoading(false);
    }
  }, [epochPrices]);

  const handleSelectedTickleProduct = (product = null) => {
    setSelectedTickleProduct(product);
    setPriceSelected(true);
  };

  const handleCloseInfoMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenInfoMessage(false);
  };

  const checklistGuide = () => {
    const checklist = [
      {
        label: t("ptInst1"),
        subLabel: null,
        icon: CheckSolidIcon,
      },
      {
        label: t("ptInst2"),
        subLabel: null,
        icon: CheckSolidIcon,
      },
      {
        label: t("ptInst3"),
        subLabel: t("ptInstSub3"),
        icon: CheckSolidIcon,
      },
    ];
    const liMap = (data) => {
      return data?.map((item) => (
        <li key={item.label}>
          <img
            width="17.8"
            height="13.45"
            src={item.icon}
            alt="check-icon"
            className="check-icon"
          />
          {item.label}
          {item.subLabel ? (
            <p
              style={{
                fontSize: "0.688rem",
                paddingLeft: "2.25rem",
              }}
            >
              {item.subLabel}
            </p>
          ) : null}
        </li>
      ));
    };

    return <ul className={classes.tickelsGuide}>{liMap(checklist)}</ul>;
  };

  const handleCloseModal = () => {
    setOpenBuyTickleModal(false);
    setSelectedTickleProduct("");
    setPriceSelected(false);
  };

  return (
    <div {...rest}>
      <Dialog
        className={classes.dialogRoot}
        open={openBuyTickleModal}
        onClose={() => handleCloseModal()}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => handleCloseModal()}
          iconClassName={classes.closeButton}
          className={classes.dialogTitleRoot}
          page={"buyTickles"}
        >
          {t("buyTicklesTitle")}
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          {checklistGuide()}
          {!isLoading ? (
            <EpochPrices
              epochProductLists={epochProductLists}
              selectedTickleProduct={selectedTickleProduct}
              handleSelectedTickleProduct={handleSelectedTickleProduct}
              tickleIcons={tickleIcons}
            />
          ) : (
            <p
              style={{
                width: "80%",
                margin: "auto",
                textAlign: "center",
                paddingTop: "1.5rem",
                paddingBottom: "1.5rem",
                fontStyle: "italic",
              }}
            >
              {t("loadingTicklePrices")}
            </p>
          )}
        </DialogContent>
        <DialogActions
          className={classes.dialogActionsRoot}
          style={{
            padding: "18px 0",
            justifyContent: "center",
          }}
        >
          <Button
            autoFocus
            disabled={!isPriceSelected}
            onClick={async () => {
              try {
                //confirm payment

                const response = await TransactionService.buyTickles(
                  authenticated.user._id,
                  {
                    code: selectedTickleProduct.code,
                    return_url: window.location.href,
                  }
                );

                if (response.status === 200) {
                  setOpenBuyTickleModal(false);

                  setOpenInfoMessage(true);

                  window.location.href = response.data.url;
                }
              } catch (error) {
                throw new Error(error);
              }
            }}
            color="primary"
            className={classes.confirmButton}
            style={{
              opacity: !isPriceSelected ? "0.7" : "1",
              cursor: !isPriceSelected ? "not-allowed" : "pointer",
              pointerEvents: "unset",
            }}
          >
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openInfoMessage}
        autoHideDuration={60000}
        onClose={handleCloseInfoMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseInfoMessage} severity="info">
          {t("redirectingGateway")}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default BuyTickle;
