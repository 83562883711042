import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

/**
 * @param {*}
 * @param {*} accessToken
 */
const sendPayment = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const response = await axiosInstance.post("/v1/payment", data, {
      headers,
    });

    return response.data;
  } catch (error) {
    console.log("error data: ", error);
    throw error;
  }
};

const PaymentService = {
  sendPayment,
};

export default PaymentService;
