import { selector } from "recoil";

import PictyService from "../../_services/picty.service";

const getPictiesQuery = selector({
  key: "picties",
  get: async () => {
    try {
      const response = await PictyService.getPicties();

      return response.data;
    } catch (e) {
      console.error("ERROR GET /api/v1/picties", e);
    }
  },
});

export default getPictiesQuery;
