import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},
  top: {
    // color: "#f50057",
    color: "#FF7E9D",
    // animationDuration: "550ms",
    // position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));
