import { selector } from "recoil";

import tickleBadge from "../../../atoms/notifications/badge/tickle";

const setNotificationTickleBadgeInVisible = selector({
  key: "setNotificationTickleBadgeInVisibleSelector",
  set: ({ set }, value) => {
    set(tickleBadge, value);
  },
});

export default setNotificationTickleBadgeInVisible;
