import TicklesIcon from "../../../assets/images/notifications-models/Group 54498@2x.png";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import useNotificationsModels from "./hooks/useNotificationsModels";
import {StyledBadge} from "./NotificationsModels";
import {useRecoilState, useRecoilValue} from "recoil";
import { uniqBy as _uniqBy } from "lodash";
import tickleBadgeAtom from "../../../stateManagement/atoms/notifications/badge/tickle";
import ticklesNotificationsQuery from "../../../stateManagement/selectors/ticklesNotificationsSelector";
import useInfiniteQuery from "../../../hooks/useInfiniteQuery";
import TickleService from "../../../_services/tickle.service";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import ticklesNotificationsAtom from "../../../stateManagement/atoms/notifications/tickles";
import NotificationService from "../../../_services/notification.service";
import {SocketContext} from "../../../context/socket";

const NotificationTickles = ({subscribers}) => {

    const socket = useContext(SocketContext);
    const [allTickles, setAllTickles] = useState([]);
    const [ticklesNotifications] = useRecoilState(ticklesNotificationsAtom);
    const [count, setCount] = useState(0);
    const [isInvisibleTickleBadge, setInvisibleTickleBadge] = useRecoilState(tickleBadgeAtom);
    const {
        clickedLikes,
        clickedComments,
        clickedTickles,
        noSubscriptionsYet,
        setNoSubscriptionsYet,
        clickedSubscriptions,
        NoNotificationsYet,
        LikesNotifications,
        CommentsNotifications,
        TicklesNotifications,
        SubscriptionsNotifications,
        deployComments,
        handleDeployComments,
        deployTickles,
        handleDeployTickles,
        deployLikes,
        handleDeployLikes,
        deploySubscriptions,
        handleDeploySubscriptions,
        // handleCheckReadAllNotifications,
    } = useNotificationsModels();

    const {
        data: tickles,
        hasNextPage: ticklesHasNextPage,
        fetchNextPage: ticklesFetchNextPage,
        status
    } = useInfiniteQuery("notification-tickles", async ({ next_offset = 0 }) => {
        const response = count > 0 ? await TickleService.getNotificationTicklesV2(next_offset) : null;

        return {
            data: response.data.tickles,
            next_offset: response.data.next_offset,
        };
    }, count);

    const loadMoreTicklesReference = useRef();

    useIntersectionObserver({
        target: loadMoreTicklesReference,
        onIntersect: ticklesFetchNextPage,
        enabled: ticklesHasNextPage,
    });

    useEffect(() => {
        setAllTickles(() =>
            _uniqBy(
                [...ticklesNotifications, ...tickles].sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                ),
                "_id"
            )
        );
    }, [tickles]);


    const newTickleHandler = useCallback(
        (newMessage) => {
            setCount(count+1)
        },
        [tickles]
    );

    useEffect(() => {
        // observer > new message
        const message = socket.onTickle();
        const onTickle = message.subscribe(newTickleHandler);

        // clean up observer
        return () => onTickle.unsubscribe();
    }, [socket, newTickleHandler]);

    // tickles notifications
    useEffect(() => {
        if (ticklesNotifications) {
            setAllTickles(() =>
                _uniqBy(
                    [...ticklesNotifications, ...tickles].sort(
                        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    ),
                    "_id"
                )
            );
        }

        return () => {};
    }, [tickles, ticklesNotifications]);



    return (<div className="notificationsModelsContentSection__tickles">
        <div
            className={
                deployTickles === false
                    ? "notificationsModels__ticklesList hidden-body"
                    : "notificationsModels__ticklesList"
            }
            onClick={
                deployTickles === true ? handleDeployTickles : 
                async () => {
                    handleDeployTickles()
                    await NotificationService.readAllNotificationsWithType("Tickles")
                    setInvisibleTickleBadge(true)
                    setCount(count+1)
                    // handleCheckReadAllNotifications();
                }
            }
        >
            <div className="notificationsModelsSection__head">
                <div className="notif-title">
                    <StyledBadge
                        color="secondary"
                        variant="dot"
                        invisible={isInvisibleTickleBadge}
                        overlap={"rectangular"}
                    >
                        <img
                            src={TicklesIcon}
                            alt=""
                            className="notificationsModels__Icon"
                        />
                    </StyledBadge>
                    <b>Tickles</b>
                </div>
                {deployTickles === true ? (
                    <KeyboardArrowUpIcon
                        className="ticklesNotificationsTopChevron"
                    />
                ) : (
                    <KeyboardArrowDownIcon
                        className="ticklesNotificationsTopChevron"
                    />
                )}
            </div>
            <hr className="hr-sep-notifications" />
            <div className="notification-body">
                {status === "init" || status === "loading" || allTickles.length <= 0 ? (
                    <NoNotificationsYet />
                ) : (
                    <TicklesNotifications
                        subscribers={subscribers}
                        tickles={allTickles}
                        loadMoreTicklesReference={loadMoreTicklesReference}
                        ticklesNotifications={ticklesNotifications}
                        isInvisibleTickleBadge={isInvisibleTickleBadge}
                    />
                )}
            </div>
        </div>
    </div>)
}

export default NotificationTickles;