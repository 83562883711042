import { Dialog, Divider } from "@material-ui/core";
import React from "react";

import DialogTitle from "../../../../../BuyTickleModal/DialogTitle";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../../../pages/SuppressAccount/styled";
import UserService from "../../../../../../_services/user.service";

function AcceptCustomMedias(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { openModal, setOpenModal, authUser, message, addNotificationMessage } =
    props;
  return (
    <Dialog
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      className={classes.suppressDialog}
    >
      <DialogTitle>
        <p style={{ marginTop: "10px" }}>{t("modalYesNo")}</p>
      </DialogTitle>
      <Divider />
      <div className={classes.suppressModalButtonsContainer}>
        <button
          type={"button"}
          onClick={() => {
            setOpenModal(false);
          }}
          style={{ color: "#000000" }}
        >
          {t("No")}
        </button>

        <button
          type={"button"}
          onClick={async () => {
            setOpenModal(false);
            try {
              await UserService.acceptOrRejectProposedCustomMedia(
                authUser.user._id,
                {
                  action: "accept",
                  messageId: message?.messageId || message?._id,
                }
              );
            } catch (err) {
              addNotificationMessage(
                `${
                  err.response.data.error || err.response.error || err.message
                }`,
                "error"
              );
            }
          }}
        >
          {t("Yes")}
        </button>
      </div>
    </Dialog>
  );
}

export default AcceptCustomMedias;
