import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

/**
 * @param {*}
 * @param {*} accessToken
 */
const sendSubscribe = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const response = await axiosInstance.post("/v1/subscribes", data, {
      headers,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const getSubscriptions = async (offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/subscribes?offset=${offset}`, {
    headers,
  });

  return response;
};

const cancelSubscription = async (id) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.delete(`/v1/subscribes/${id}`, {
    headers,
  });

  return response.data;
};

const getSubscriberList = async (id = "", offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/users/${id}/subscriberList?offset=${offset}`, {
    headers,
  });

  return response;
};

const getSubscriptionsV2 = async (offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
      `/v2/notifications/subscriptions?offset=${offset}`,
      {
        headers,
      }
  );

  return response;
};

const SubscribeService = {
  sendSubscribe,
  getSubscriptions,
  cancelSubscription,
  getSubscriberList,
  getSubscriptionsV2,
};

export default SubscribeService;
