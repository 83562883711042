/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { useRecoilState } from "recoil";
import Checkbox from "@material-ui/core/Checkbox";

import "./feetModelGuides.css";
import { studioAtom } from "../../../stateManagement/atoms/studioAtom";
import { useTranslation } from "react-i18next";
import TicklesImg from "../../../assets/images/tickles/Intersection 3@2x.png";
import StarImg from "../../../assets/images__v3/Abonnement_1.png";
import ChatImg from "../../../assets/images__v3/Message_1.png";
import CMImg from "../../../assets/images__v3/Media Personnalise.png";
import FavImg from "../../../assets/images__v3/Favoris.png";
import RulesModal from "../../../components/modelsComponents/postingRules/rulesModal";

const FeetModelGuides = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [_, setOnStudio] = useRecoilState(studioAtom);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setOnStudio(true);

    return () => {
      setOnStudio(false);
    };
  }, [setOnStudio]);

  return (
    <div className="feetloverGuides__container">
      <div className="feetloverGuides__header header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => {
            history.goBack();
          }}
        >
          <ChevronLeft />
        </IconButton>
        <h4>{t("FeetGirls Guide")}</h4>
      </div>
      <div className="feetloverGuides__content minmax__wrapper content__withHeader feet-model-container-guides">
        <p>
          {t("feetGirlGuideFirstSection")}
          <div>
            <h2>
              {t("feetGirlGuideTicklesTitle")}
              <img
                src={TicklesImg}
                alt="tickles"
                width="31"
                height="31"
                style={{ verticalAlign: "middle", marginLeft: "10px" }}
              />
            </h2>
            <span>{t("feetGirlGuideTicklesSection")}</span>
            <ul>
              <li>{t("feetGirlGuideTicklesOne")}</li>
              <li>{t("feetGirlGuideTicklesTwo")}</li>
            </ul>
            <span>{t("feetGirlGuideTicklesEndSection")}</span>
          </div>
          <div>
            <h2>
              {t("feetGirlGuidePublicPrivateTitle")}
              <img
                src={StarImg}
                alt="tickles"
                width="31"
                height="31"
                style={{ verticalAlign: "middle", marginLeft: "10px" }}
              />
            </h2>
            <p>{t("feetGirlGuidePublicSection")}</p>
            <p>{t("feetGirlGuidePrivateSection")}</p>
            <a
              style={{ textAlign: "left", color: "blue", cursor: "pointer" }}
              onClick={() => setOpenModal(true)}
            >
              <p>{t("feetGirlGuidePublicPrivateEndSection")}</p>
            </a>
          </div>
          <div>
            <h2>
              {t("feetGirlGuideChatTitle")}
              <img
                src={ChatImg}
                alt="tickles"
                width="41"
                height="31"
                style={{ verticalAlign: "middle", marginLeft: "10px" }}
              />
            </h2>
            <span>{t("feetGirlGuideChatSection")}</span>
            <ul>
              <li>{t("feetGirlGuideChatOne")}</li>
              <li>
                {t("feetGirlGuideChatTwo")}
                <img
                  src={FavImg}
                  alt="tickles"
                  width="31"
                  height="31"
                  style={{ verticalAlign: "middle", marginLeft: "10px" }}
                />
              </li>
              <li>{t("feetGirlGuideChatThird")}</li>
              <li>{t("feetGirlGuideChatFourth")}</li>
            </ul>
          </div>
          <div>
            <h2>
              {t("feetGirlGuideCMTitle")}
              <img
                src={CMImg}
                alt="tickles"
                width="41"
                height="31"
                style={{ verticalAlign: "middle", marginLeft: "10px" }}
              />
            </h2>
            <span>{t("feetGirlGuideCMSection")}</span>
            <ul>
              <li>{t("feetGirlGuideCMOne")}</li>
              <li>{t("feetGirlGuideCMTwo")}</li>
              <li>{t("feetGirlGuideCMTree")}</li>
              <li>{t("feetGirlGuideCMFour")}</li>
              <li>{t("feetGirlGuideCMFive")}</li>
              <li>{t("feetGirlGuideCMSix")}</li>
              <li>{t("feetGirlGuideCMSeven")}</li>
            </ul>
          </div>
          <p>
            <b>{t("feetGirlGuideReminderPart1")}</b>{" "}
            {t("feetGirlGuideReminderPart2")}
          </p>
          <p>
            {t("feetGirlGuideCMEndSection")}
            <a href={`mailto: ${t("feetGirlGuideCMEndMailSection")}`}>
              <b>{t("feetGirlGuideCMEndMailSection")}</b>
            </a>
          </p>
          <p>{t("feetGirlGuideEndSection")}</p>
          <i>
            <b>{t("feetGirlGuideFrom")}</b>
          </i>
        </p>
        <br />
        <br />
        <div className="i-have-agreed">
          <Checkbox inputProps={{ "aria-label": "uncontrolled-checkbox" }} />
          <span>{t("I have read the Feetgirl’s guide")}</span>
        </div>
      </div>
      <RulesModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        pageType={"guides"}
      />
    </div>
  );
};

export default FeetModelGuides;
