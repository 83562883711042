import React from "react";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";

import FeetModelService from "../../../../_services/feet-models.service";
import useIntersectionObserver from "../../../../hooks/useIntersectionObserver";
import useInfiniteQuery from "../../../../hooks/useInfiniteQuery";

import PublicationMedia from "../../../PublicationMedia";
import MoviePhone from "../../../../assets/images/view-feet-models-profile/picto/video_transparent.svg";
import useSessionStorage from "../../../forms/services/storageHooks/useSessionStorage";

const PrivateMediaComponent = ({ feetModelProfile }) => {
  let history = useHistory();
  const { t } = useTranslation();

  const [authenticated] = useSessionStorage({}, "infosUser");

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      "feet-model-private-publications",
      async ({ next_offset = 0 }) => {
        const response = await FeetModelService.getFeetModelPrivatePublications(
          feetModelProfile.profile._id,
          next_offset
        );

        return {
          data: response.data.publications,
          next_offset: response.data.next_offset,
        };
      }
    );

  const loadMorePublicationsReference = React.useRef();

  useIntersectionObserver({
    target: loadMorePublicationsReference,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  return (
    <div className="private mediaContainer">
      {data && data.length
        ? data.map((publication) => (
            <div
              className="viewFeetModelsProfiles__photos-content"
              key={publication._id}
            >
              {publication.private ? (
                <div
                  className="viewFeetModelsProfiles__pictures"
                  onClick={() =>
                    history.push({
                      pathname: `/publication/${publication._id}`,
                      state: { mediaTab: 1 },
                    })
                  }
                >
                  {publication.private ? (
                    <>
                      <PublicationMedia
                        publication={publication}
                        className="noFree"
                        alt="Models profiles"
                        effect="blur"
                        width="100%"
                        isOwner={
                          authenticated.user._id ===
                          feetModelProfile.profile._id
                        }
                      />
                      <div className="profileMediaIcons">
                        {publication.type?.includes("video") ? (
                          <img
                            className="viewFeetModelsProfiles__icon-absol video"
                            src={MoviePhone}
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </div>

                      {/* publication moderation banner if status is pending */}
                      {publication.status === "pending" ? (
                        <div className="view_own_profile_publication_moderation_banner">
                          <p>{t("moderationAwaiting")}</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : null}
            </div>
          ))
        : ""}

      {data && data.length ? (
        <>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
        </>
      ) : null}

      <div className="pagination-loader" ref={loadMorePublicationsReference}>
        {isFetchingNextPage ? (
          <CircularProgress color="secondary" size={10} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PrivateMediaComponent;
