import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import { useRecoilState } from "recoil";
import writeCommentsContentAtomAtom from "../../stateManagement/atoms/writeCommentsContentAtom";
import "./commentModal.css";
import { useTranslation } from "react-i18next";

const CommentModal = (props) => {
  const { handleSendComment } = props;
  const [isWriteComment, setIsWriteComment] = useRecoilState(
    writeCommentsContentAtomAtom
  );
  const { t } = useTranslation();
  useEffect(() => {
    return () => {
      setIsWriteComment("");
    };
  }, [setIsWriteComment]);

  return (
    <div className="commentModal__container">
      <br />
      <textarea
        value={isWriteComment}
        onChange={(e) => setIsWriteComment(e.target.value)}
        className="commentModal__textArea"
        placeholder={t("Write your comment") + "..."}
      ></textarea>
      <Button onClick={handleSendComment} className="pink__button">
        {t("publish")}
      </Button>
    </div>
  );
};

export default CommentModal;
