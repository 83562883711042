import React from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isIOS } from "react-device-detect";

import "./pictyFilters.css";
import PictyFilters from "./PictyFilters";

const PictySearchPage = ({ userType }) => {
  let history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="searchFilter__content">
      <div className="searchFilter__header header page_fixed_header">
        <Button className="back__button">
          <ChevronLeftIcon
            onClick={() => history.push("/search-lover")}
            fontSize="large"
          />
        </Button>
        <h4>{t("pictyFilterTitle")}</h4>
        <Button
          onClick={() => history.push("/search-lover")}
          className="header__rightButtton"
        >
          <h4 className="searchFilter__search">
            {t("searchDefaultViewTitle")}
          </h4>
        </Button>
      </div>
      <div className="content__withHeader">
        {isIOS === true ? (
          <div className="filtersContainerIphone">
            <PictyFilters userType={userType} />
          </div>
        ) : (
          <PictyFilters userType={userType} />
        )}
      </div>
    </div>
  );
};

export default PictySearchPage;
