import React from "react";
import "./baseLayout.css";
import BottomNavigationStudio from "./shared/BottomNavigationStudio";
import Header from "./shared/Header";

const BaseLayout = (props) => {
  const { children, setLoading } = props;

  return (
    <div className="layout-container">
      <Header />
      <main className="cover">
        <div className="wrapper">{children}</div>
      </main>
      <BottomNavigationStudio setLoading={setLoading} />
    </div>
  );
};

export default BaseLayout;
