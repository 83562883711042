/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { Avatar, IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

import "./index.css";

import genericAvatar from "../../assets/images/generic-user-avatar.png";
import feetModelAtom from "../../stateManagement/atoms/feetModelAtom";
import useInfiniteQuery from "../../hooks/useInfiniteQuery";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import useSessionStorage from "../forms/services/storageHooks/useSessionStorage";
import ViewService from "../../_services/view.service";

const Views = (props) => {
  let history = useHistory();
  const { t } = useTranslation();

  let { id } = useParams();

  const [authUser] = useSessionStorage({}, "infosUser");
  const [__, setFeetModel] = useRecoilState(feetModelAtom);

  const {
    data,
    error,
    status,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery("views-page", async ({ next_offset = 0 }) => {
    const response = await ViewService.getViews(next_offset, id);

    return {
      data: response.data.views,
      next_offset: response.data.next_offset,
    };
  });

  const loadMoreReference = useRef();

  useIntersectionObserver({
    target: loadMoreReference,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  return (
    <div>
      <div className="header page_fixed_header">
        <IconButton className="back__button" onClick={() => history.goBack()}>
          <ChevronLeft />
        </IconButton>
        <h4>Views</h4>
      </div>
      <div className="blockedAccounts__content content__withHeader minmax__wrapper">
        <div className="blocked_account_list">
          {data.map((view) => (
            <div
              className="likes_item flex-center"
              key={view._id}
              onClick={() => {
                if (view.viewer.userType === "Lover") {
                  if (authUser.user._id === view.viewer._id) {
                    history.push("/feet-lover-profile");
                  } else {
                    history.push({
                      pathname: `/visit-feet-lover/${view.viewer._id}`,
                    });
                  }
                } else {
                  if (authUser.user._id === view.viewer._id) {
                    history.push("/view-own-profile-models");
                  } else {
                    setFeetModel(view.viewer);
                    history.push({
                      pathname: "/feet-models-view",
                      search: `?user=${view.viewer._id}`,
                    });
                  }
                }
              }}
            >
              <div className="user_thumbnail_details">
                <div className="avatar">
                  <Avatar
                    className="blockedAccounts__avatar"
                    src={
                      view && view.viewer && view.viewer.image
                        ? view.viewer.image.url
                        : genericAvatar
                    }
                    alt="profile pict"
                  />
                </div>
                <div className="user_details">
                  <h2>{view.viewer.username}</h2>
                </div>
              </div>
              <sub
                style={{
                  fontSize: ".7rem",
                  width: "70px",
                }}
              >
                {moment(view.createdAt).format("h:mm a")}
              </sub>
            </div>
          ))}
          {/*use to get fill gaps and have even cells*/}
          <div className="user_thumbnail_details space_filler"></div>
          <div className="user_thumbnail_details space_filler"></div>
          <div className="user_thumbnail_details space_filler"></div>
        </div>
      </div>
      <div ref={loadMoreReference}>
        {isFetchingNextPage ? (
          <CircularProgress color="secondary" size={10} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Views;
