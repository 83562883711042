/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "../createMessage.css";

import Button from "../../../../Button";
import BuyTickleModal from "../../../../BuyTickleModal";
import FeetModelService from "../../../../../_services/feet-models.service";
import ProposeTickles from "../../../../Modals/ProposeTickles";
import RequestHasTicklesCustomMedias from "../modals/requestHasTicklesCustomMedias";
import RequestNoTicklesCustomMedias from "../modals/requestNoTicklesCustomMedias";
import Subscription from "../modals/subscription";
import UserService from "../../../../../_services/user.service";
import useMessageOrSpeech from "../hooks/useMessageOrSpeech";
import useNotificationAlert from "../../../../../hooks/useNotificationAlert";
import { useTranslation } from "react-i18next";

const MessageContentTop = (props) => {
  const {
    userType,
    authUser,
    user,
    i18n,
    Avatar,
    genericAvatar,
    history,
    TicklesIcon,
    setFeetModel,
    countMessages,
    epochProducts
  } = props;

  const { addNotificationMessage } = useNotificationAlert();

  const { setArrayMessagesList } = useMessageOrSpeech();
  const [openTermsOfUseModal, setOpenTermsOfUseModal] = useState(false);
  const [
    openRequestNoTicklesCustomMediaModal,
    setOpenRequestNoTicklesCustomMediaModal,
  ] = useState(false);
  const [
    openRequestHasTicklesCustomMediaModal,
    setOpenRequestHasTicklesCustomMediaModal,
  ] = useState(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [openBuyTickleModal, setOpenBuyTickleModal] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [feetModelProfile, setFeetModelProfile] = React.useState({});
  const [isSubscriber, setIsSubscriber] = React.useState(false);
  const [epochProductLists, setEpochProductLists] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {

    if (user) {
      const fn = async () => {
          if (user?.userType === "Model") {
            const response = await FeetModelService.getFeetModelProfileById(
              user._id
            );
            setFeetModelProfile(response);
            setIsSubscriber(response.profile?.isSubscriber)
          }
          if (user?.idCheckDetails)
            setVerified(user?.idCheckDetails?.verified)
      };

      async function checkIfAuthenticatedUserIsASubscriber() {
        if (user?.userType === "Model") {
          // const response = await FeetLoverService.checkIfSubscribed(
          //   user._id
          // );

          // if (response && response.data && response.data.subscriber) {
          //   setIsSubscriber(true);
          // } else {
          //   setIsSubscriber(false);
          // }
        } else {
          FeetModelService.getFeetModelProfile().then((response) => {
            const subscribed = () => {
              return !!(
                  response.profile?.subscribers?.filter(
                      (subscriber) => subscriber === user._id
                  ).length
              );
            };
            setIsSubscriber(subscribed)
          });
        }
      }
      
      checkIfAuthenticatedUserIsASubscriber();
      fn().then();
    }
  }, [user]);

  useEffect(() => {
    if (epochProducts && (openTermsOfUseModal === true || openBuyTickleModal === true)) {
      setEpochProductLists(epochProducts);
    }
  }, [epochProducts, openBuyTickleModal, openTermsOfUseModal]);

  const handleSubmitProposal = async (value) => {
    if (value && value > 0) {
      const response = await UserService.proposeCustomMediaPrice(
        authUser.user?._id,
        {
          lover: user._id,

          tickles: value,
        }
      );

      setArrayMessagesList((prev) =>
        prev.concat({
          ...response.data?.data,
        })
      );
    }
  };

  const handleRequestCustomMedia = async () => {
    // request custom media
    const userData = await UserService.getUser(authUser.user?._id);

    if (
      userData.user &&
      ((!isSubscriber && userData.user.tickles < 50) || (!isSubscriber && !userData.user.tickles))
    ) {
      setOpenRequestNoTicklesCustomMediaModal(true);
    } else {
      setOpenRequestHasTicklesCustomMediaModal(true);
    }
  };

  return (
    <div
      className={
        i18n.language === "en"
          ? "createMessage__top"
          : "createMessage__top createMessage__topfr"
      }
    >
      <div
        className="user_thumbnail_details"
        onClick={() => {
          if (user.userType === "Lover") {
            if (authUser.user._id === user._id) {
              history.push("/feet-lover-profile");
            } else {
              history.push({
                pathname: `/visit-feet-lover/${user._id}`,
              });
            }
          } else {
            if (authUser.user._id === user._id) {
              history.push("/view-own-profile-models");
            } else {
              setFeetModel(user);
              history.push({
                pathname: "/feet-models-view",
                search: `?user=${user._id}`,
              });
            }
          }
        }}
      >
        <div className="avatar">
          <Avatar
            src={user.image ? user.image.url : genericAvatar}
            alt="pict profile"
            className={"createMessageAvatar"}
          />
        </div>
        <div className={"createMessage__text user_details"}>
          <h2
            className={"createMessage__username"}
            style={
              isSubscriber && user?.userType === "Lover"
                ? { color: "#ff2775", fontWeight: "bold" }
                : {}
            }
          >{user.username}</h2>
          {/* <p>{user.username}</p> */}
        </div>
      </div>

      {authUser &&
        authUser?.user &&
        authUser.user?.userType &&
        authUser.user?.userType === "Lover" &&
        user &&
        user?.userType !== "Lover" && isVerified && (
          <Button
            type="submit"
            width="clamp(145px, 3vw, 24.5rem)"
            background="linear-gradient(90deg, #FF2775 0%, #FF5768 100%)"
            height="clamp(38px, 3vw, 52px)"
            color="white"
            boxShadow="none"
            padding="0"
            fontSize="clamp(14px, 3vw, 14px)"
            borderRadius="18px"
            cursor="pointer"
            onClick={handleRequestCustomMedia}
          >
            {t("acceptCMTitle")}
          </Button>
        )}

      {authUser &&
        authUser?.user &&
        authUser.user?.userType &&
        authUser.user?.userType === "Model" &&
        user &&
        user?.userType === "Lover" && (
          <Button
            background="linear-gradient(90deg, #FF2775 0%, #FF5768 100%);"
            width={ i18n.language === "en" ? "clamp(170px, 3vw, 170px)" : "clamp(180px, 3vw, 180px)"}
            height="clamp(38px, 3vw, 52px)"
            padding="0 20px 0 0"
            fontSize="clamp(14px, 3vw, 14px)"
            borderRadius="18px"
            onClick={async () => {
              if (!authUser?.user?.idCheckDetails?.verified)
                history.push("/identity-verification");
              else {
                // open propose tickles modal.
                setOpenTermsOfUseModal(true);
              }
            }}
            variant="contained"
          >
            <div className={"propose-text"}>
              <span>{t("Propose a price")}</span>
              <span className={"sub-text"}>{t("forCustomMedia")}</span>
            </div>
            <img
              src={TicklesIcon}
              className={"createMessageTop__TicklesIcon"}
              alt="tickles icon"
              width="20"
              height="20"
              style={{ position: "absolute", right: "8px", top: "5px", cursor: "pointer" }}
            />
          </Button>
        )}
      {/* request a custom media modal with no tickles */}
      <RequestNoTicklesCustomMedias
        openRequestModal={openRequestNoTicklesCustomMediaModal}
        setOpenRequestModal={setOpenRequestNoTicklesCustomMediaModal}
        setOpenBuyTickleModal={setOpenBuyTickleModal}
        setOpenSubscriptionModal={setOpenSubscriptionModal}
      />
      {/* request a custom media modal with no tickles */}
      <RequestHasTicklesCustomMedias
        openModal={openRequestHasTicklesCustomMediaModal}
        setOpenModal={setOpenRequestHasTicklesCustomMediaModal}
        setArrayMessagesList={setArrayMessagesList}
        authUser={authUser}
        user={user}
        addNotificationMessage={addNotificationMessage}
      />
      {/* buy tickle modal */}
      <BuyTickleModal
        openBuyTickleModal={openBuyTickleModal}
        setOpenBuyTickleModal={setOpenBuyTickleModal}
        epochPrices={epochProductLists}
      />
      <Subscription
        openSubscriptionModal={openSubscriptionModal}
        setOpenSubscriptionModal={setOpenSubscriptionModal}
        feetModelProfile={feetModelProfile}
      />
      {/* todo: update states for propose tickles modal */}
      {/* propose tickle modal */}
      <ProposeTickles
        openTermsOfUseModal={openTermsOfUseModal}
        setOpenTermsOfUseModal={setOpenTermsOfUseModal}
        handleSubmitProposal={handleSubmitProposal}
        countMessages={countMessages}
        epochPrices={epochProductLists}
      />
      {/* todo: another yes/no modal before sending the propose price. */}
    </div>
  );
};

export default MessageContentTop;
