import React from "react";

const FormSuccess = () => {
  return (
    <div className="form-content-right">
      <h1
        className="form-success"
        style={{ paddingTop: 50, fontSize: 18, color: "blue" }}
      >
        We have received your request!
      </h1>
    </div>
  );
};

export default FormSuccess;
