/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import "./language.css";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { onSettingModelsAtom } from "../../../stateManagement/atoms/studioAtom";
import check from "../../../assets/images/success-payment/success-check.png";
import useSessionStorage from "../../forms/services/storageHooks/useSessionStorage";
import UserService from "../../../_services/user.service";

const useStyles = makeStyles({
  fullList: {
    width: "auto",
  },
});

const Language = (props) => {

  const { i18n, t } = useTranslation();
  const [authUser] = useSessionStorage({}, "infosUser");
  const [preferredLanguage, setLanguage] = useState("fr");

  // function for changing languages
  const changeLanguage = async (lang) => {
    // i18n.changeLanguage(lng);
    
    localStorage.setItem("i18nextLng", lang);
    localStorage.setItem("language", lang);

    const info = {
      ...authUser,
      user: {
        ...authUser.user,
        preferredLanguage: lang,
      },
    };
    await UserService.updateLanguage(authUser.user.id, lang);
    localStorage.setItem("infosUser", JSON.stringify(info));
  };

  const [onSettingModels, setOnSettingModels] = useRecoilState(
    onSettingModelsAtom
  );
  let history = useHistory();
  const classes = useStyles();
  const [state, setState] = useState({
    bottom: true,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    if (authUser && authUser.user.preferredLanguage)
      setLanguage(authUser.user.preferredLanguage);
  }, [authUser.user.preferredLanguage])

  const list = (anchor) => (
    <div
      className={clsx({
        [classes.fullList]: anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="Language-head">
        <hr size="2" className="Language__roundedHr" />
        <div className="Language__content">
          <div className="LanguageContent__title">
            <h4>{t("languageSettingLover")}</h4>
          </div>
        </div>
      </div>
      <div className="Language__buttons">
        <Button
          onClick={() => {
            changeLanguage("fr");
            onSettingModels === false
              ? history.replace("/settings-lovers")
              : history.replace("/setting-models");
          }}
        >
          {preferredLanguage === "fr" ? (
            <img
              src={check}
              alt="lang-fr"
              style={{
                width: "18px",
                paddingRight: "20px",
              }}
            />
          ) : null}
          {t("frenchSettingLover")}
        </Button>
        <Button
          onClick={() => {
            changeLanguage("en");
            onSettingModels === false
              ? history.replace("/settings-lovers")
              : history.replace("/setting-models");
          }}
        >
          {preferredLanguage === "en" ? (
            <img
              src={check}
              alt="lang-en"
              style={{
                width: "18px",
                paddingRight: "20px",
              }}
            />
          ) : null}
          {t("englishSettingLover")}
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          anchor="bottom"
          open={state.bottom}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
};

export default Language;
