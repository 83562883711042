import { selector } from "recoil";

import FeetModelService from "../../_services/feet-models.service";

const feetModelToSubscribeSuggestionsQuery = selector({
  key: "feetModelToSubscribeSuggestions",
  get: async () => {
    try {
      const response = await FeetModelService.getSubscribeSuggestions();

      return response.data;
    } catch (e) {
      console.error("ERROR GET /api/v1/feet-models/subscribe-suggestions", e);
    }
  },
});

export default feetModelToSubscribeSuggestionsQuery;
