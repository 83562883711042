/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import Link from "@material-ui/core/Link";

import UserService from "../../_services/user.service";
import useSessionStorage from "../../components/forms/services/storageHooks/useSessionStorage";
import authUserAtom from "../../stateManagement/atoms/auth/user";

const ModalContent = ({
  userType,
  setUserType,
  onStudio,
  setOnStudio,
  query,
}) => {
  const { t } = useTranslation();

  let history = useHistory();

  const [__, setResponseInfo] = useSessionStorage({}, "infosUser");
  const [_, setAuthenticatedUser] = useRecoilState(authUserAtom);

  return (
    <Fragment>
      <div className="socialLoginWelcome__content">
        <div className="socialLoginWelcome__header">
          <h2 id="simple-modal-title">{t("socialLoginWelcomeTitle")}</h2>
        </div>
        <div className="socialLoginWelcome__body">
          <div className="socialLoginWelcome__top">
            <p>{t("socialLoginWelcomeChoose")}</p>
          </div>
          <div className="socialLoginWelcome__center">
            <Link
              onClick={async () => {
                const userData = JSON.parse(decodeURI(query.get("user")));

                const response = await UserService.updateUser(
                  userData._id,
                  { userType: "Model" },
                  userData.access_token
                );

                setResponseInfo({ user: response.data });
                setAuthenticatedUser(response.data);
                setOnStudio(false);
                setUserType("Model");

                if (response.data.userType === "Model")
                  history.push("/home-page-models");
              }}
              className="socialLoginWelcome__center-button"
            >
              <h3>{t("socialLoginWelcomeFeetModelUser")}</h3>
            </Link>
            <p className="socialLoginWelcome__center-p">
              {t("feetModelSubtitleButton")} {t("feetModelSubtitle2Button")}
            </p>
          </div>
          <div className="socialLoginWelcome__bottom">
            <Link
              onClick={async () => {
                const userData = JSON.parse(decodeURI(query.get("user")));

                const response = await UserService.updateUser(
                  userData._id,
                  { userType: "Lover" },
                  userData.access_token
                );

                setResponseInfo({ user: response.data });
                setAuthenticatedUser(response.data);
                setOnStudio(false);
                setUserType("Lover");

                if (response.data.userType === "Lover")
                  history.push("/home-page");
              }}
              className="socialLoginWelcome__center-button"
            >
              <h3>{t("feetLoverTitleButton")}</h3>
            </Link>
            <p className="socialLoginWelcome__center-p">
              {t("feetLoverSubtitle")} {t("socialLoginWelcomePublications")}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ModalContent;
