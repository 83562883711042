/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { Avatar, Button, IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./style.css";

import genericAvatar from "../../assets/images/generic-user-avatar.png";
import feetModelAtom from "../../stateManagement/atoms/feetModelAtom";
import useInfiniteQuery from "../../hooks/useInfiniteQuery";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import useSessionStorage from "../forms/services/storageHooks/useSessionStorage";
import LikeService from "../../_services/like.service";
import FollowService from "../../_services/follow.service";
import { uniqBy as _uniqBy } from "lodash";

const PublicationLikers = (props) => {
  let history = useHistory();
  const { t } = useTranslation();

  let { id } = useParams();

  const [authUser] = useSessionStorage({}, "infosUser");
  const [__, setFeetModel] = useRecoilState(feetModelAtom);
  const [refetch, setRefetch] = React.useState(0);
  const [allLikers, setAllLikers] = useState([]);

  const {
    data,
    error,
    status,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    "likers-page",
    async ({ next_offset = 0 }) => {
      const response = await LikeService.getPublicationLikes(id);

      return {
        data: response.data.publication,
        next_offset: response.data.next_offset,
      };
    },
    refetch
  );

  useEffect(() => {
    setAllLikers(() =>
      _uniqBy(
        [...data].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
        "_id"
      )
    );
  }, [data]);

  const loadMoreReference = useRef();

  useIntersectionObserver({
    target: loadMoreReference,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  const handleFollowOrUnfollow = async (data) => {
    try {
      if (!data.isFollowed) {
        // follow
        await FollowService.followUser({
          followedUser: data.user._id,
        });
        setRefetch(refetch + 1);
      } else {
        // un-follow
        await FollowService.remove(data.user._id);
        setRefetch(refetch + 1);
      }
    } catch (error) {
      console.error("ERROR handleFollowOrUnfollow", error);
    }
  };

  const handleRedirectProfile = (liker) => {
    if (liker.user.userType === "Lover") {
      if (authUser.user._id === liker.user._id) {
        history.push("/feet-lover-profile");
      } else {
        history.push({
          pathname: `/visit-feet-lover/${liker.user._id}`,
        });
      }
    } else {
      if (authUser.user._id === liker.user._id) {
        history.push("/view-own-profile-models");
      } else {
        setFeetModel(liker.user);
        history.push({
          pathname: "/feet-models-view",
          search: `?user=${liker.user._id}`,
        });
      }
    }
  };

  return (
    <div>
      <div className="header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => {
            if (authUser.user.userType === "Lover")
              window.history.pushState(null, null, "/home-page");
            else window.history.pushState(null, null, "/home-page-models");
            window.history.go();
          }}
        >
          <ChevronLeft />
        </IconButton>
        <h4>{t("Like")}</h4>
      </div>
      <div className="blockedAccounts__content content__withHeader minmax__wrapper">
        <div className="blocked_account_list">
          {allLikers.map((liker) => (
            <div className="likes_item flex-center" key={liker._id}>
              <div className="user_thumbnail_details">
                <div className="avatar">
                  <Avatar
                    className="blockedAccounts__avatar"
                    src={
                      liker && liker.user && liker.user.image
                        ? liker.user.image.url
                        : genericAvatar
                    }
                    alt="profile pict"
                    onClick={() => handleRedirectProfile(liker)}
                  />
                </div>
                <div
                  className="user_details"
                  onClick={() => handleRedirectProfile(liker)}
                >
                  <h2
                    style={
                      liker &&
                      liker.user &&
                      liker.user.userType !== "Model" &&
                      liker.isSubscriber
                        ? { color: "#ff2775", fontWeight: "bold" }
                        : {}
                    }
                  >
                    {liker.user.username}
                  </h2>
                </div>
              </div>
              {authUser.user.id !== liker.user.id ? (
                <sub
                  style={{
                    fontSize: ".7rem",
                    width: "80px",
                  }}
                >
                  <Button
                    className={`normalize-text${
                      liker.isFollowed
                        ? "followList__Button default__button"
                        : "followList__Button pink__button"
                    }`}
                    onClick={() => {
                      handleFollowOrUnfollow(liker);
                    }}
                  >
                    {liker.isFollowed
                      ? t("unfollowKebabMenuLover")
                      : t("followButtonFeetModelView")}
                  </Button>
                </sub>
              ) : null}
            </div>
          ))}
          {/*use to get fill gaps and have even cells*/}
          <div className="user_thumbnail_details space_filler"></div>
          <div className="user_thumbnail_details space_filler"></div>
          <div className="user_thumbnail_details space_filler"></div>
        </div>
      </div>
      <div ref={loadMoreReference}>
        {isFetchingNextPage ? (
          <CircularProgress color="secondary" size={10} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PublicationLikers;
