import { selector } from "recoil";
import {
  Aiguille,
  Arche,
  artiste,
  Bas,
  Basket,
  Bijoux,
  Chatouilles,
  chaussette,
  cooconing,
  Copines,
  Genereux,
  Jambe,
  Jouer,
  Mirror,
  mode,
  OnglesLongs,
  Orteils,
  OrteilsCourts,
  OrteilsEcartes,
  OrteilsLongs,
  Osseux,
  Pedales,
  PictEgyptian,
  PictGreek,
  PictRoman,
  PiedMain,
  PlantePied,
  Plisses,
  Public,
  Selfeet,
  sport,
  Talons,
  Tatouage,
  Vernis,
  voyage,
  Vue,
  water,
} from "../../components/pictyFilters/dataPictyFilter";

const numberClickFromLocalstorageSelector = selector({
  key: "numberClickFromLocalstorageSelector",
  get: async () => {
    try {
      const allValues = JSON.parse(localStorage.getItem("Final"));
      const response = await allValues;

      return [
        {
          id: 0,
          name: "Egyptian",
          number: response[0].Egyptian,
          icon: PictEgyptian,
          code: "egyptian",
        },
        {
          id: 1,
          name: "Roman",
          number: response[0].Roman,
          icon: PictGreek,
          code: "roman",
        },
        {
          id: 2,
          name: "Greek",
          number: response[0].Greek,
          icon: PictRoman,
          code: "greek",
        },
        {
          id: 3,
          name: "voyage",
          number: response[0].voyage,
          icon: voyage,
          code: "envoyage",
        },
        {
          id: 4,
          name: "mode",
          number: response[0].mode,
          icon: mode,
          code: "mode",
        },
        {
          id: 5,
          name: "sport",
          number: response[0].sport,
          icon: sport,
          code: "sport",
        },
        {
          id: 6,
          name: "water",
          number: response[0].water,
          icon: water,
          code: "eau",
        },
        {
          id: 7,
          name: "cooconing",
          number: response[0].cooconing,
          icon: cooconing,
          code: "cooconing",
        },
        {
          id: 8,
          name: "artiste",
          number: response[0].artiste,
          icon: artiste,
          code: "solartisque",
        },
        {
          id: 9,
          name: "Aiguille",
          number: response[0].Aiguille,
          icon: Aiguille,
          code: "talonsaiguille",
        },
        {
          id: 10,
          name: "Bijoux",
          number: response[0].Bijoux,
          icon: Bijoux,
          code: "bijoux",
        },
        {
          id: 11,
          name: "chaussette",
          number: response[0].chaussette,
          icon: chaussette,
          code: "chaussettes",
        },
        {
          id: 12,
          name: "Bas",
          number: response[0].Bas,
          icon: Bas,
          code: "collant&bas",
        },
        {
          id: 13,
          name: "Basket",
          number: response[0].Basket,
          icon: Basket,
          code: "basket",
        },
        {
          id: 14,
          name: "Tatouage",
          number: response[0].Tatouage,
          icon: Tatouage,
          code: "tatouage",
        },
        {
          id: 15,
          name: "Vernis",
          number: response[0].Vernis,
          icon: Vernis,
          code: "vernis",
        },
        {
          id: 16,
          name: "Mirror",
          number: response[0].Mirror,
          icon: Mirror,
          code: "miroir",
        },
        {
          id: 17,
          name: "Pedales",
          number: response[0].Pedales,
          icon: Pedales,
          code: "pedales",
        },
        {
          id: 18,
          name: "Jouer",
          number: response[0].Jouer,
          icon: Jouer,
          code: "jouer",
        },
        {
          id: 19,
          name: "Vue",
          number: response[0].Vue,
          icon: Vue,
          code: "vue",
        },
        {
          id: 20,
          name: "Jambe",
          number: response[0].Jambe,
          icon: Jambe,
          code: "jambe",
        },
        {
          id: 21,
          name: "PiedMain",
          number: response[0].PiedMain,
          icon: PiedMain,
          code: "piedmain",
        },
        {
          id: 22,
          name: "Chatouilles",
          number: response[0].Chatouilles,
          icon: Chatouilles,
          code: "chatouilles",
        },
        {
          id: 23,
          name: "Copines",
          number: response[0].Copines,
          icon: Copines,
          code: "copines",
        },
        {
          id: 24,
          name: "Public",
          number: response[0].Public,
          icon: Public,
          code: "public",
        },
        {
          id: 25,
          name: "Arche",
          number: response[0].Arche,
          icon: Arche,
          code: "arche",
        },
        {
          id: 26,
          name: "Orteils",
          number: response[0].Orteils,
          icon: Orteils,
          code: "orteils",
        },
        {
          id: 27,
          name: "Talons",
          number: response[0].Talons,
          icon: Talons,
          code: "talons",
        },
        {
          id: 28,
          name: "Selfeet",
          number: response[0].Selfeet,
          icon: Selfeet,
          code: "selfeet",
        },
        {
          id: 29,
          name: "Plisses",
          number: response[0].Plisses,
          icon: Plisses,
          code: "plisses",
        },
        {
          id: 30,
          name: "PlantePied",
          number: response[0].PlantePied,
          icon: PlantePied,
          code: "plantepied",
        },
        {
          id: 31,
          name: "OrteilsEcartes",
          number: response[0].OrteilsEcartes,
          icon: OrteilsEcartes,
          code: "orteilsecartes",
        },
        {
          id: 32,
          name: "OnglesLongs",
          number: response[0].OnglesLongs,
          icon: OnglesLongs,
          code: "ongleslongs",
        },
        {
          id: 33,
          name: "Osseux",
          number: response[0].Osseux,
          icon: Osseux,
          code: "osseux",
        },
        {
          id: 34,
          name: "Genereux",
          number: response[0].Genereux,
          icon: Genereux,
          code: "genereux",
        },
        {
          id: 35,
          name: "OrteilsCourts",
          number: response[0].OrteilsCourts,
          icon: OrteilsCourts,
          code: "orteilscourts",
        },
        {
          id: 36,
          name: "OrteilsLongs",
          number: response[0].OrteilsLongs,
          icon: OrteilsLongs,
          code: "orteilslongs",
        },
      ];
    } catch (e) {
      throw new Error(error);
    }
  },
});

export default numberClickFromLocalstorageSelector;
