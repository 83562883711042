/* eslint-disable no-unused-vars */
import React from "react";
import BlockIcon from "@material-ui/icons/Block";
import { useTranslation } from "react-i18next";
import "./index.css";

const ContentNotAvailable = () => {
  const { t } = useTranslation();

  return (
    <div className="content_not_available__container">
      <div className="box">
        <BlockIcon color="secondary" style={{ fontSize: 60 }} />
        <h3>{t("This Content Isn't Available")}</h3>
      </div>
    </div>
  );
};

export default ContentNotAvailable;
