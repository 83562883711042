import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import MoviePhone from "../../../../assets/images/view-feet-models-profile/picto/video_transparent.svg";
import PublicationMedia from "../../../PublicationMedia";
import FeetModelService from "../../../../_services/feet-models.service";
import useIntersectionObserver from "../../../../hooks/useIntersectionObserver";
import useInfiniteQuery from "../../../../hooks/useInfiniteQuery";
import useSessionStorage from "../../../forms/services/storageHooks/useSessionStorage";

const PublicMediaComponent = ({ feetModelProfile }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [authenticated] = useSessionStorage({}, "infosUser");

  const {
    data,
    error,
    status,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    "feet-model-own-public-publications",
    async ({ next_offset = 0 }) => {
      const response = await FeetModelService.getFeetModelPublicPublications(
        feetModelProfile.profile._id,
        next_offset
      );

      return {
        data: response.data.publications,
        next_offset: response.data.next_offset,
      };
    }
  );

  const loadMorePublicationsReference = React.useRef();

  useIntersectionObserver({
    target: loadMorePublicationsReference,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  return (
    <div className="public mediaContainer">
      {data && data.length
        ? data.map((publication) => (
            <div
              className="viewFeetModelsProfiles__photos-content"
              key={publication._id}
            >
              {!publication.private && (
                <div
                  className="viewFeetModelsProfiles__pictures"
                  onClick={() =>
                    history.push({
                      pathname: `/publication/${publication._id}`,
                      state: { mediaTab: 0 },
                    })
                  }
                >
                  <PublicationMedia
                    publication={publication}
                    className="img-profile-pict"
                    alt="Models profiles"
                    effect="blur"
                    width="100%"
                  />
                  <div className="profileMediaIcons">
                    {publication.type?.includes("video") ? (
                      <img
                        className="viewFeetModelsProfiles__icon-absol video"
                        src={MoviePhone}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  {/* publication moderation banner if status is pending */}
                  {publication.status === "pending" ? (
                    <div className="view_own_profile_publication_moderation_banner">
                      <p>{t("moderationAwaiting")}</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          ))
        : ""}

      {data && data.length ? (
        <>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
          <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
        </>
      ) : null}

      {status === "loading" && !(data && data.length) ? (
        ""
      ) : data &&
        data.length === 0 &&
        authenticated &&
        authenticated?.user &&
        authenticated.user.userType !== "Model" ? (
        <span style={{ fontSize: "clamp(12px,3vw,16px)" }}>
          {t("No public media yet")}
        </span>
      ) : (
        ""
      )}

      <div ref={loadMorePublicationsReference}></div>
    </div>
  );
};

export default PublicMediaComponent;
