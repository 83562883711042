import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const sendHelpMessage = async (data) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  await axiosInstance.post("/v1/help", data, { headers });
  return;
};

const HelpService = { sendHelpMessage };

export default HelpService;
