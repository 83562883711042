/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Button, IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import genericAvatar from "../../assets/images/generic-user-avatar.png";

import useInfiniteQuery from "../../hooks/useInfiniteQuery";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";

import BlockService from "../../_services/block.service";
import UserService from "../../_services/user.service";

const BlockedAccounts = (props) => {
  let history = useHistory();
  const { t } = useTranslation();

  const {
    data,
    error,
    status,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    setData,
  } = useInfiniteQuery("blocked-accounts", async ({ next_offset = 0 }) => {
    const response = await UserService.getBlockedUsers(next_offset);

    return {
      data: response.data.users,
      next_offset: response.data.next_offset,
    };
  });

  const loadMoreReference = useRef();

  useIntersectionObserver({
    target: loadMoreReference,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  return (
    <div className="blockedAccounts__container">
      <div className="blockedAccounts__header header page_fixed_header">
        <IconButton className="back__button" onClick={() => history.goBack()}>
          <ChevronLeft />
        </IconButton>
        <h4>{t("settingsBlockedAccounts")}</h4>
      </div>
      <div className="blockedAccounts__content content__withHeader minmax__wrapper">
        <div className="blocked_account_list">
          {data.map((user) => (
            <div className="blocked_account_item flex-center" key={user._id}>
              <div className="user_thumbnail_details">
                <div className="avatar">
                  <Avatar
                    className="blockedAccounts__avatar"
                    src={
                      user && user.blocked && user.blocked.image
                        ? user.blocked.image.url
                        : genericAvatar
                    }
                    alt="profile pict"
                  />
                </div>
                <div className="user_details">
                  <h2>{user.blocked.username}</h2>
                </div>
              </div>
              <Button
                className="unblock__Button white__button"
                onClick={async () => {
                  setData((prev) =>
                    prev.filter((userData) => userData._id !== user._id)
                  );

                  await BlockService.unblockUser(user.blocked._id);
                }}
              >
                Unblock
              </Button>
            </div>
          ))}
          {/*use to get fill gaps and have even cells*/}
          <div className="user_thumbnail_details space_filler"></div>
          <div className="user_thumbnail_details space_filler"></div>
          <div className="user_thumbnail_details space_filler"></div>
        </div>
      </div>
      <div ref={loadMoreReference}>
        {isFetchingNextPage ? (
          <CircularProgress color="secondary" size={10} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default BlockedAccounts;
