/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { Button, IconButton } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useRecoilState, useRecoilValue } from "recoil";

import "./bankAccount.css";
import authUserAtom from "../../../stateManagement/atoms/auth/user";
import getUserQuery from "../../../stateManagement/selectors/getUserSelector";
import useNotificationAlert from "../../../hooks/useNotificationAlert";
import FeetModelService from "../../../_services/feet-models.service";
import BankAccountConfirmation from "./guide/BankAccountConfirmation";
import { useTranslation } from "react-i18next";
import BankAccountConfirmationModal from "./guide/BankAccountModal";

const BankAccount = ({ onSettingModels, setOnSettingModels }) => {
  let history = useHistory();
  const { t } = useTranslation();
  // user state in atom state management
  const [_, setUpdateAuthenticatedUser] = useRecoilState(authUserAtom);
  const [openModal, setOpenModal] = useState(false);
  const [content, setContent] = useState("");

  // GET user
  const { user } = useRecoilValue(getUserQuery);
  const { addNotificationMessage } = useNotificationAlert();

  const bankAccountSchema = Yup.object().shape({
    firstName: Yup.string().required(t("errFirstNameRequiredAddBank")),
    lastName: Yup.string().required(t("errLastNameRequiredAddBank")),
    bankName: Yup.string().required(t("errBankNameRequiredAddBank")),
    bankAddress: Yup.string().required(t("errBankAddressRequiredAddBank")),
    iban: Yup.string().required(t("errIBANRequiredAddBank")),
    bic_swift: Yup.string().required(t("errBICSWIFTRequiredAddBank")),
  });

  const formik = useFormik({
    initialValues: user.bankDetails
      ? {
          firstName: user.bankDetails?.firstName,
          lastName: user.bankDetails?.lastName,
          bankName: user.bankDetails?.bankName,
          bankAddress: user.bankDetails?.bankAddress,
          iban: user.bankDetails?.iban,
          bic_swift: user.bankDetails?.bic_swift,
        }
      : {
          firstName: "",
          lastName: "",
          bankName: "",
          bankAddress: "",
          iban: "",
          bic_swift: "",
        },
    validationSchema: bankAccountSchema,
    validate: (values) => {
      const errors = {};
      const regex = /([à-ü]|[À-Ü])/g;
      if (values.firstName.match(regex)?.length) {
        values.firstName = t("fieldMustNotBeAccent");
      }
      if (values.lastName.match(regex)?.length) {
        values.lastName = t("fieldMustNotBeAccent");
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        const response = await FeetModelService.setupBank(values);

        setUpdateAuthenticatedUser(response.user);
        addNotificationMessage(t("bankAddedSuccessfully"), "success");
        setContent(t("addBankSubmitSuccess"));
        setOpenModal(true);
        // history.push("/setting-models");
      } catch (error) {
        //console.error("ERROR PUT /api/v1/feet-models/bank-account", error);
        addNotificationMessage(error.response.data.error, "error");
        setContent(t("addBankSubmitFailed"));
        setOpenModal(true);
      }
    },
  });

  return (
    <div className="bankAccount__container">
      <div className="bankAccount__header header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => history.push("/setting-models")}
        >
          <ChevronLeft />
        </IconButton>
        <h4>{t("addABankAccount")}</h4>
      </div>
      <div className="content__withHeader minmax__wrapper display-table">
        <div className="cell-vertical-center">
          <BankAccountConfirmation user={user} />
          <form className="bankAccount__content" onSubmit={formik.handleSubmit}>
            <label>
              {t("addBankLastNamePlaceholder")}&nbsp;
              <span style={{ color: "#FF5768" }}>*</span>
            </label>
            <div className="input-wrapper">
              <input
                type="text"
                placeholder={t("addBankLastNamePlaceholder")}
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
              {formik.errors.lastName && formik.touched.lastName ? (
                <p className="error-message">{formik.errors.lastName}</p>
              ) : null}
            </div>
            <label>
              {t("addBankFirstNamePlaceholder")}&nbsp;
              <span style={{ color: "#FF5768" }}>*</span>
            </label>
            <div className="input-wrapper">
              <input
                type="text"
                placeholder={t("addBankFirstNamePlaceholder")}
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
              {formik.errors.firstName && formik.touched.firstName ? (
                <p className="error-message">{formik.errors.firstName}</p>
              ) : null}
            </div>
            <label>
              {t("addBankBankNamePlaceholder")}&nbsp;
              <span style={{ color: "#FF5768" }}>*</span>
            </label>
            <div className="input-wrapper">
              <input
                type="text"
                placeholder={t("addBankBankNamePlaceholder")}
                name="bankName"
                value={formik.values.bankName}
                onChange={formik.handleChange}
              />
              {formik.errors.bankName && formik.touched.bankName ? (
                <p className="error-message">{formik.errors.bankName}</p>
              ) : null}
            </div>
            <label>
              {t("addBankBankAddressPlaceholder")}&nbsp;
              <span style={{ color: "#FF5768" }}>*</span>
            </label>
            <div className="input-wrapper">
              <input
                type="text"
                placeholder={t("addBankBankAddressPlaceholder")}
                name="bankAddress"
                value={formik.values.bankAddress}
                onChange={formik.handleChange}
              />
              {formik.errors.bankAddress && formik.touched.bankAddress ? (
                <p className="error-message">{formik.errors.bankAddress}</p>
              ) : null}
            </div>
            <label>
              {t("addBankIBANPlaceholder")}&nbsp;
              <span style={{ color: "#FF5768" }}>*</span>
            </label>
            <div className="input-wrapper">
              <input
                type="text"
                placeholder={t("addBankIBANPlaceholder")}
                name="iban"
                value={formik.values.iban}
                onChange={formik.handleChange}
              />
              {formik.errors.iban && formik.touched.iban ? (
                <p className="error-message">{formik.errors.iban}</p>
              ) : null}
            </div>
            <label>
              {t("addBankBICSWIFTPlaceholder")}&nbsp;
              <span style={{ color: "#FF5768" }}>*</span>
            </label>
            <div className="input-wrapper">
              <input
                type="text"
                placeholder={t("addBankBICSWIFTPlaceholder")}
                name="bic_swift"
                value={formik.values.bic_swift}
                onChange={formik.handleChange}
              />
              {formik.errors.bic_swift && formik.touched.bic_swift ? (
                <p className="error-message">{formik.errors.bic_swift}</p>
              ) : null}
            </div>
            <Button
              className="pink__button full-width"
              variant="contained"
              type="submit"
            >
              {t("addBankSubmitButton")}
            </Button>
          </form>
        </div>
      </div>
      <BankAccountConfirmationModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        message={content}
      />
    </div>
  );
};

export default BankAccount;
