/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import { Button } from "@material-ui/core";
import { CheckCircleOutlineOutlined } from "@material-ui/icons";

import { useHistory } from "react-router-dom";

import "./camera.css";

import IdVerificationService from "../../_services/id-verification.service";
import { IonLoading, IonToast } from "@ionic/react";
import { getAuthSession, setAuthSession } from "../../helpers/auth.helpers";
import UserService from "../../_services/user.service";
import { useTranslation } from "react-i18next";

//convert base64 to blob
function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

const Camera = (props) => {
  let history = useHistory();
  const { t } = useTranslation();
  const webcamRef = React.useRef(null);
  const [clickedPicture, setClickedPicture] = React.useState(false);
  const [previewImg, setPreviewImg] = React.useState(null);
  const [validating, setValidating] = React.useState(false);
  const [validatedId, setValidatedId] = useState(false);

  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPreviewImg(imageSrc);
  }, [webcamRef]);

  const handleClickedPicture = () => {
    if (clickedPicture === false) {
      setClickedPicture(true);
    }
    if (clickedPicture === true) {
      setClickedPicture(false);
    }
  };

  const replaceSelfieImage = () => {
    setPreviewImg(null);
  };

  const confirmSelfieImage = async () => {
    try {
      setValidating(true);

      const file = DataURIToBlob(previewImg);

      const formData = new FormData();

      formData.append("file", file);
      formData.append("type", "selfieImage");

      await Promise.all([
        IdVerificationService.uploadDocument(formData),
        IdVerificationService.validate("selfieImage"),
      ]);

      const user_id = (await getAuthSession()).user._id;
      const { user } = await UserService.getUser(user_id, null);
      await setAuthSession(JSON.stringify({ user }));
      props.cameraSuccess(user);
    } catch (err) {
      setValidatedId(true);
      setPreviewImg(null);
    } finally {
      setValidating(false);
    }

    //TODO: after response either success or failure, proceed to next page.
  };

  useEffect(() => {
    if (clickedPicture === true) {
      setTimeout(() => {
        return setClickedPicture(false) && history.push("/studio");
      }, 1500);
    }
  }, [clickedPicture, history]);

  return (
    <div className="camera-page-wrapper">
      <IonLoading
        cssClass="my-custom-class"
        isOpen={validating}
        message={"Validating"}
        duration={5000}
      />
      <IonToast
        isOpen={validatedId}
        position={"top"}
        onDidDismiss={() => setValidatedId(false)}
        message={"failed to validate frontImage. please retake picture"}
        duration={2000}
      />
      <div className="camera-page-header">
        <h3>{t("Selfie Header")}</h3>
        <h5>{t("Selfie Action")}</h5>
        <div style={{ display: "inline-flex", marginBottom: "6px" }}>
          <CheckCircleOutlineOutlined style={{ marginRight: "10px" }} />
          <span>{t("Selfie Caution")}</span>
        </div>
      </div>

      {!previewImg ? (
        <div className="camera-component-wrapper">
          <div className="camera-viewfinder-wrapper">
            <Webcam
              className="camera-id-verification"
              height={480}
              ref={webcamRef}
              mirrored={true}
              screenshotFormat="image/jpeg"
            />
          </div>

          <div className="camera-page-bottom-options">
            <div className="capture">
              <button
                type={"button"}
                className={
                  clickedPicture === true
                    ? "button-capture blink"
                    : "button-capture"
                }
                onClick={() => {
                  handleClickedPicture();
                  capture();
                }}
              >
                {""}
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {previewImg ? (
        <div
          className="camera-selfie-preview-wrapper"
          style={{ height: "500px" }}
        >
          <div
            style={{
              display: "inline-grid",
              margin: "20px 0 25px 0",
            }}
          >
            <span>{t("selfie preview")}</span>
            <img
              src={previewImg}
              style={{ height: "200px", marginTop: "15px" }}
              alt={t("selfie preview")}
            />
          </div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => replaceSelfieImage()}
          >
            {t("selfie retake label")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => confirmSelfieImage()}
          >
            {t("selfie confirm label")}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default Camera;
