import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import InputLabel from "@material-ui/core/InputLabel";
import { useTranslation } from "react-i18next";
import { ChevronLeft } from "@material-ui/icons";
import { IconButton } from "@material-ui/core/";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";

import { useStyles } from "./styled";
import useNotificationAlert from "../../../hooks/useNotificationAlert";
import getUserQuery from "../../../stateManagement/selectors/getUserSelector";
import Button from "../../../components/Button";
import UserService from "../../../_services/user.service";
import useSessionStorage from "../../../components/forms/services/storageHooks/useSessionStorage";
import authUserAtom from "../../../stateManagement/atoms/auth/user";
import UpdateEmailModal from "../../../components/UpdateEmailModal";

function UpdateEmail() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [authUser] = useSessionStorage({}, "infosUser");

  const { addNotificationMessage } = useNotificationAlert();

  const [setUpdateAuthenticatedUser] = useRecoilState(authUserAtom);
  const { user } = useRecoilValue(getUserQuery);
  const [openUpdateEmailModal, setOpenUpdateEmailModal] = useState(false);

  // Formik parameters and Yup validation inputs
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("errEmailInvalidFormatSignup"))
        .required(t("errEmailRequiredSignup")),
    }),
    onSubmit: async (values) => {
      try {
        const response = await UserService.updateEmail(
          authUser.user._id,
          values
        );
        setOpenUpdateEmailModal(true);
      } catch (error) {
        addNotificationMessage(error.response.data.error, "error");
      }
    },
  });

  return (
    <div className={classes.root}>
      {/* todo: profile image */}
      <div className="profileForm__header header page_fixed_header edit-profile-header__v3">
        <IconButton
          className="back__button"
          onClick={() =>
            user.userType === "Lover"
              ? history.push("/settings-lovers")
              : history.push("/setting-models")
          }
        >
          <ChevronLeft style={{ fill: "black" }} />
        </IconButton>
        <h4>{t("Modify your email")}</h4>
      </div>
      <form
        className="formSignin__form"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <div>
          <InputLabel htmlFor="current-email" className={classes.label}>
            {t("emailTitleCurrent")}
          </InputLabel>
          <TextField
            id="current-email"
            value={user.email}
            type="email"
            disabled={true}
            className={classes.textInput}
            InputProps={{ disableUnderline: true }}
          />
          <InputLabel htmlFor="email" className={classes.label}>
            {t("emailTitleSignup")}
          </InputLabel>
          <TextField
            id="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            type="email"
            className={classes.textInput}
            InputProps={{ disableUnderline: true }}
          />
          {formik.errors.email && formik.touched.email ? (
            <p style={{ color: "red" }}>{formik.errors.email}</p>
          ) : null}
        </div>
        <br />
        <Button
          type="submit"
          width="clamp(100%, 4vw, 24.5rem)"
          background="#F35162"
          height="clamp(42px, 4vw, 52px)"
        >
          {t("Save")}
        </Button>
        <UpdateEmailModal
          openUpdateEmailModal={openUpdateEmailModal}
          setOpenUpdateEmailModal={setOpenUpdateEmailModal}
        />
      </form>
    </div>
  );
}

export default UpdateEmail;
