/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import { ChevronLeft } from "@material-ui/icons";
import "./termsConditions.css";
import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { useRecoilState } from "recoil";
import {
  onSettingModelsAtom,
  studioAtom,
} from "../../stateManagement/atoms/studioAtom";

const TermsConditions = (props) => {
  let history = useHistory();

  const [onSettingModels, setOnSettingModels] = useRecoilState(
    onSettingModelsAtom
  );
  const [onStudio, setOnStudio] = useRecoilState(studioAtom);

  //hide/show Bottom Navigation
  useEffect(() => {
    if (onStudio === false) {
      setOnStudio(true);
    }
  }, [onStudio, setOnStudio]);

  return (
    <div className="termsConditions__container">
      <div className="termsConditions__header header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() =>
            onSettingModels === true
              ? history.push("/setting-models")
              : history.push("/settings-lovers")
          }
        >
          <ChevronLeft />
        </IconButton>
        <h4>Terms & Conditions</h4>
      </div>
      <div className="termsConditions__content content__withHeader minmax__wrapper">
        <p className="termsConditionsContent__parag1">
          Conditions générales de vente
        </p>

        <p className="termsConditionsContent__parag1">
          https://feety.app - CONDITIONS GÉNÉRALES DE VENTE - VERSION 0.1 -
          JUILLET 2021
        </p>

        <p className="termsConditionsContent__parag1">
          IMPORTANT – VEUILLEZ LIRE ATTENTIVEMENT LE PRÉSENT DOCUMENT AVANT
          TOUTE CRÉATION D’UN COMPTE ET UTILISATION DES SERVICES DU SITE
          INTERNET ET EN CONSERVER UNE COPIE.
        </p>

        <p className="termsConditionsContent__parag1">
          EN POURSUIVANT LA NAVIGATION, VOUS COMPRENEZ ET ACCEPTEZ QUE LA
          NAVIGATION SUR LE SITE, AINSI QUE LES PRÉSENTES CONDITIONS GÉNÉRALES
          D’UTILISATION SOIENT SOUMISES AU DROIT FRANÇAIS, AINSI QU’À LA
          COMPÉTENCE DES TRIBUNAUX FRANÇAIS.
        </p>

        <h3>PREAMBULE</h3>

        <p className="termsConditionsContent__parag1">
          La société LMCF SAS est une société par action simplifiée au capital
          social de 1200 euros, dont le siège est sis 2 rue Beaussier – 83000 –
          Toulon - France, inscrite au RCS de Toulon sous le numéro 845 132 596.
        </p>

        <p className="termsConditionsContent__parag1">
          Elle fournit des services de partage de contenus entre Modèles et
          Utilisateurs par le biais de son site internet disponible à l’adresse
          suivante https://feety.app.
        </p>

        <p className="termsConditionsContent__parag1">
          Les Utilisateurs peuvent ainsi consulter les Contenus publiés par les
          Modèles, tandis que les Modèles peuvent publier ces Contenus via leur
          Compte, de manière publique ou restreinte et dans le cadre de
          l’utilisation des Services.
        </p>

        <p className="termsConditionsContent__parag1">
          Les présentes ont pour objet de définir les conditions d’utilisation
          des Services du Site Internet par les Utilisateurs.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur doit, préalablement à son Inscription vérifier que les
          Services proposés par LMCF SAS lui conviennent par :
        </p>

        <ul>
          <li>la lecture complète des présentes Conditions Générales ;</li>
          <li>
            la consultation des informations disponibles sur le Site Internet.
          </li>
        </ul>

        <p className="termsConditionsContent__parag1">
          Par ailleurs, l’Utilisateur reconnait expressément avoir bénéficié
          d’une information précontractuelle complète de la part d’LMCF SAS,
          conforme aux dispositions des articles L111-1 à L111-8 du Code de la
          consommation, notamment par la mise à disposition préalable des
          Conditions Générales.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur comprend et accepte notamment qu’LMCF SAS n’a pas de
          contrôle sur la fréquence d’activité, ou le type de contenu proposé
          par les Modèles, qui exercent leur activité de façon indépendante.
        </p>

        <h3>ARTICLE 1. Définitions</h3>

        <p className="termsConditionsContent__parag1">
          Les termes et expressions visés ci-après reçoivent, lorsqu'ils sont
          précédés d'une lettre majuscule, pour les besoins de l'interprétation
          et de l'exécution des présentes, la définition qui suit :
        </p>

        <table>
          <tr>
            <td>Utilisateur</td>
            <td>
              Désigne tout visiteur majeur inscrit ou non sur le Site Internet,
              et souhaitant avoir accès aux Services ainsi qu’aux Contenus
            </td>
          </tr>
          <tr>
            <td>Compte</td>
            <td>
              Désigne l’espace réservé à l’Utilisateur sur le Site Internet,
              créé à partir de la création du compte
            </td>
          </tr>
          <tr>
            <td>Conditions</td>
            <td>
              Désigne les conditions générales d’utilisation qui font l’objet
              des présentes
            </td>
          </tr>
          <tr>
            <td>Générales</td>
            <td></td>
          </tr>
          <tr>
            <td>Inscription</td>
            <td>
              Désigne l’acte pour un Utilisateur de s’inscrire en cette qualité
              sur le Site Internet aux fins de bénéficier des Services
            </td>
          </tr>
          <tr>
            <td>LMCF SAS</td>
            <td>
              Désigne la société LMCF SAS, société par action simplifiée au
              capital social de 1200 euros, dont le siège est sis 2 rue
              Beaussier – 83000 – TOULON - France, inscrite au RCS de Toulon
              sous le numéro 845 132 596
            </td>
          </tr>
          <tr>
            <td>Modèle</td>
            <td>
              Désigne toute personne physique ayant créé un compte de type «
              Modèle » sur la page réservée à cet effet, et publiant des
              Contenus en ligne sur le Site Internet 
            </td>
          </tr>
          <tr>
            <td>Parties</td>
            <td>Désigne LMCF SAS et l’Utilisateur</td>
          </tr>
          <tr>
            <td>Services</td>
            <td>
              Désigne les services proposés par LMCF SAS aux Utilisateurs sur le
              Site Internet
            </td>
          </tr>
          <tr>
            <td>Contenu</td>
            <td>
              Désigne les photographies et vidéos mises en ligne sur le Site
              Internet par les Modèles
            </td>
          </tr>
          <tr>
            <td>Site Internet</td>
            <td>
              Désigne le site internet accessible à l’URL suivante :
              https://feety.app ainsi que l’ensemble des fonctionnalités
              accessibles sur celui-ci
            </td>
          </tr>
        </table>

        <h3>ARTICLE 2. Champ d'application</h3>

        <p className="termsConditionsContent__parag1">
          Les présentes Conditions Générales du Site Internet, régissent et
          définissent les conditions et modalités d’utilisation des Services
          proposés par LMCF SAS aux Utilisateurs sur le Site Internet, d’accès
          au Contenu par le biais du Site Internet, et plus généralement toute
          utilisation du Site Internet par l’Utilisateur. 
        </p>

        <p className="termsConditionsContent__parag1">
          Ces Conditions Générales sont accessibles à tout moment sur le Site
          Internet à l’adresse suivante : https://feety.app/terms .
        </p>

        <p className="termsConditionsContent__parag1">
          Elles sont soumises à l’Utilisateur préalablement à l’Inscription sur
          le Site Internet.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur est tenu de prendre connaissance des Conditions
          Générales et garantit les avoir acceptées, préalablement à son
          Inscription, en cochant la case prévue à cet effet.
        </p>

        <p className="termsConditionsContent__parag1">
          Le défaut d’acceptation des Conditions Générales empêche toute
          Inscription.
        </p>

        <p className="termsConditionsContent__parag1">
          Les Parties conviennent que leurs relations seront régies
          exclusivement par les présentes Conditions Générales.
        </p>

        <p className="termsConditionsContent__parag1">
          Toute dérogation aux Conditions Générales ne peut être opposable aux
          Parties que si elle a été dûment acceptée et formalisée par un écrit
          signé par ces dernières.
        </p>

        <p className="termsConditionsContent__parag1">
          Le fait pour l’une des Parties de ne pas sanctionner un comportement
          dérogeant aux Conditions Générales n’emporte pas acceptation du
          comportement dérogatoire, ni renoncement à en poursuivre l’auteur.
        </p>

        <p className="termsConditionsContent__parag1">
          Ces Conditions Générales pourront faire l'objet de modifications
          ultérieures. LMCF SAS se réserve le droit de les modifier à tout
          moment.
        </p>

        <p className="termsConditionsContent__parag1">
          En cas de mise à jour des Conditions Générales, celles-ci devront de
          nouveau être acceptées par l’Utilisateur préalablement à l’accès aux
          Services. 
        </p>

        <p className="termsConditionsContent__parag1">
          Toute modification sera directement opposable à l’Utilisateur dès
          qu'elle sera portée à sa connaissance. 
        </p>

        <p className="termsConditionsContent__parag1">
          Le Site Internet est disponible gratuitement sur internet et est
          accessible à tous à condition de disposer d’un accès à Internet. 
        </p>

        <p className="termsConditionsContent__parag1">
          L’accès à certains Services ou Contenus est cependant soumis au
          paiement d’une somme indiquée sur le Site Internet.
        </p>

        <p className="termsConditionsContent__parag1">
          Le règlement des sommes prévues dans cette hypothèse est soumis aux
          dispositions des présentes Conditions Générales.
        </p>

        <p className="termsConditionsContent__parag1">
          Tous les coûts afférents à l’accès au Site Internet tels que les frais
          matériels, logiciels ou d’accès à Internet, sont exclusivement à la
          charge des visiteurs du Site Internet qui sont seuls responsables du
          bon fonctionnement de leur équipement ainsi que de leur accès à
          Internet.
        </p>

        <p className="termsConditionsContent__parag1">
          Il leur appartient notamment de vérifier la désactivation de tous les
          filtres, et autres logiciels de nature à pouvoir bloquer l’accès au
          Site Internet, ou de les mettre en place le cas échéant après la
          consultation du Site Internet aux fins de protection des mineurs.
        </p>

        <p className="termsConditionsContent__parag1">
          Dans l'hypothèse où une ou plusieurs des dispositions des présentes
          seraient considérées comme illégales, inopposables ou inapplicables
          par décision de justice, les autres mentions resteront en vigueur.
        </p>

        <p className="termsConditionsContent__parag1">
          Le fait pour l'une des Parties de ne pas se prévaloir d'un engagement
          par l'autre Partie à l'une quelconque des obligations visées aux
          Conditions Générales, ne saurait être interprété pour l'avenir comme
          une renonciation à l'obligation en cause.
        </p>

        <h3>ARTICLE 3. Modalité d’accès au Site Internet</h3>

        <h4>3.1. Objet du Site Internet </h4>

        <p className="termsConditionsContent__parag1">
          Le Site Internet a pour objet de proposer des Services à l’Utilisateur
          ainsi que de lui permettre d’accéder à des Contenus dans les
          conditions figurant ci-après.
        </p>

        <h4>3.2. Accès au Site Internet</h4>

        <p className="termsConditionsContent__parag1">
          <b>
            L’accès au Site Internet est strictement réservé aux personnes
            majeures.
          </b>
        </p>

        <p className="termsConditionsContent__parag1">
          <b>
            Son accès est strictement interdit aux personnes mineures, telles
            que définies par la législation nationale applicable à
            l’Utilisateur, ou en tout état de cause, d’un âge inférieur à 18
            ans.
          </b>
        </p>

        <p className="termsConditionsContent__parag1">
          <b>
            LMCF SAS encourage les parents à surveiller l’utilisation qui est
            faîte de tout accès internet par leurs enfants, et recommande la
            mise en place de mesures de protection parentales, filtres de
            contenu et autres logiciels de protection.
          </b>
        </p>

        <p className="termsConditionsContent__parag1">
          LMCF SAS s’efforce de permettre, mais ne garantit pas, l’accès au Site
          Internet 24 heures sur 24 et 7 jours sur 7, sauf en cas de force
          majeure ou d’un événement hors de son contrôle, et sous réserve des
          éventuelles interventions de maintenance nécessaires au bon
          fonctionnement du Site Internet et/ou d’interventions aux fins
          d’amélioration et/ou modifications du Site Internet.
        </p>

        <p className="termsConditionsContent__parag1">
          Lorsque cela sera techniquement possible, LMCF SAS préviendra
          l’Utilisateur de toute interruption prévisible vingt-quatre heures à
          l’avance. En aucun cas LMCF SAS ne saurait être tenu responsable des
          interruptions d’accès au Site Internet et de leurs conséquences,
          quelle qu’en soit l’étendue, sauf faute lourde ou dolosive d’LMCF SAS
          ayant directement entrainé ladite interruption d’accès.
        </p>

        <h4>3.3. Sécurité et virus</h4>

        <p className="termsConditionsContent__parag1">
          Il appartient à chaque Utilisateur et plus généralement à toute
          personne visitant le Site Internet, de prendre toutes les mesures
          appropriées afin de se protéger contre la contamination de ses
          données, logiciels ou matériels par des virus circulant éventuellement
          à travers le Site Internet ou les informations qui y sont publiées, et
          de gérer ses sauvegardes de données.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur accepte expressément et reconnaît que l’utilisation du
          Site Internet est effectuée sous sa propre surveillance uniquement, et
          qu'il est entièrement responsable de tout dégât ou dommage causé à son
          système informatique ou à tout terminal d’accès au réseau internet et
          de toute perte de données qui pourrait résulter d’un téléchargement ou
          d’une utilisation du Site Internet.
        </p>

        <h4>3.4. Utilisation du Site Internet</h4>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur s’engage à utiliser le Site Internet dans le respect de
          l'ensemble des stipulations des présentes.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur accepte expressément que l'utilisation du Site Internet,
          des informations et des outils inclus ou accessibles via le Site
          Internet s'effectue sous sa propre responsabilité.
        </p>
        <p className="termsConditionsContent__parag1">
          L’Utilisateur s'interdit de procéder à tout téléchargement ou
          opérations, ou d’adopter tout comportement de toute nature sur le Site
          Internet qui pourrait avoir pour conséquence d’en entraver le bon
          fonctionnement, que quelque manière que ce soit.
        </p>

        <p className="termsConditionsContent__parag1">
          En tout état de cause, l’Utilisateur est seul responsable des dommages
          de quelque nature qu'ils soient qui pourraient être occasionnés par
          ses actions sur le Site Internet.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur s’interdit tout agissement susceptible de porter
          atteinte à la réputation du Site Internet, d’LMCF SAS, ou de la
          personnalité de ses dirigeants, salariés, partenaires, et notamment
          des Modèles inscrits sur le Site Internet.
        </p>

        <h3>ARTICLE 4. Accès aux Services </h3>

        <p className="termsConditionsContent__parag1">
          Dans le cadre de l’utilisation des Services, l’Utilisateur garantit
          fournir à LMCF SAS exclusivement des informations véridiques, à jour
          et, plus généralement se comporter de bonne foi.
        </p>

        <p className="termsConditionsContent__parag1">
          Cet engagement est déterminant de son droit de poursuivre
          l’utilisation du Site Internet et des Services, ainsi que son accès au
          Contenu, indépendamment de tout paiement que l’Utilisateur aurait pu
          effectuer.
        </p>

        <p className="termsConditionsContent__parag1">
          La communication d’une fausse adresse, ou d’un domicile fiscal fictif,
          notamment à des fins d’évitement des taux de TVA applicables,
          constitue un délit de fraude à la TVA, tel que défini à l’article 1741
          du Code général des impôts, passible d’une peine de 5 ans
          d’emprisonnement, et d’une amende de 500 000 €.
        </p>

        <p className="termsConditionsContent__parag1">
          En outre, toute tentative décelée par LMCF SAS de la part d’un
          Utilisateur de se soustraire à l’application du taux de TVA en vigueur
          dans son pays de résidence conduira à la fermeture du Compte de
          l’Utilisateur, à son bannissement définitif, outre d’éventuelles
          poursuites judiciaires aux fins de réparer le préjudice subi par LMCF
          SAS.
        </p>

        <h4>4.1. Procédure d’Inscription  </h4>

        <p className="termsConditionsContent__parag1">
          Pour pouvoir utiliser l’ensemble des Services, l’Utilisateur doit
          s’inscrire et se créer un Compte.
        </p>

        <p className="termsConditionsContent__parag1">
          La création du compte est gratuite, et s’effectue par l’intermédiaire
          du formulaire disponible à l’adresse suivante :
          <a style={{ color: "blue" }} href="#">
            https://feety.app/terms
          </a>
          , accessible depuis la page d’accueil du Site Internet. 
        </p>

        <p className="termsConditionsContent__parag1">
          L’utilisateur a alors le choix entre une connexion par l’intermédiaire
          de son compte FACEBOOK, INSTAGRAM ou bien la création d’un compte
          auprès de FEETY directement.
        </p>

        <h4>4.1.1. Inscription via Facebook</h4>

        <p className="termsConditionsContent__parag1">
          La création du Compte par le biais d’un compte Facebook se fait par
          l’intermédiaire du bouton dédié « connectez vous en utilisant Facebook
          / Instagram ».
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur est alors renvoyé sur le site FACEBOOK.COM ou
          INSTAGRAM.COM, afin de s’authentifier.
        </p>

        <p className="termsConditionsContent__parag1">
          Il est alors informé des informations transmises par la société
          FACEBOOK à LMCF SAS.
        </p>

        <p className="termsConditionsContent__parag1">
          Ces informations comprennent :
        </p>

        <ul>
          <li>l’adresse e-mail.</li>
          <li>Mot de passe</li>
        </ul>

        <p className="termsConditionsContent__parag1">
          Il est également informé que son Inscription ne permet pas à FEETY de
          publier sur Facebook ou Instagram
        </p>

        <p className="termsConditionsContent__parag1">
          En cas d’inscription par le biais d’un compte FACEBOOK, l’Utilisateur
          garantit que le compte FACEBOOK ayant servi à son inscription est son
          compte personnel et nominatif, et que toutes les informations
          transmises par FACEBOOK à FEETY sont exactes et complètes.
        </p>

        <h4>4.1.2. Inscription classique</h4>

        <p className="termsConditionsContent__parag1">
          S’il choisit l’Inscription classique, l’Utilisateur est alors invité à
          renseigner un pseudonyme, qui servira à l’identifier, ainsi qu’une
          adresse e-mail.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur doit, en outre, choisir un mot de passe disposant d’un
          niveau de sécurité suffisant et qu’il doit conserver strictement
          secret.
        </p>

        <p className="termsConditionsContent__parag1">
          La CNIL considère qu’un bon mot de passe doit comporter 12 caractères
          et 4 types différents : des minuscules, des majuscules, des chiffres
          et des caractères spéciaux.
        </p>

        <p className="termsConditionsContent__parag1">
          Ce mot de passe ne doit rien divulguer de l’Utilisateur et être propre
          au Site Internet et à son Compte.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur doit ensuite accepter les présentes Conditions
          Générales.
        </p>

        <p className="termsConditionsContent__parag1">
          Un e-mail de confirmation, contenant un lien de confirmation de
          l’Inscription est alors envoyé à l’adresse renseignée.
        </p>

        <p className="termsConditionsContent__parag1">
          En effectuant clic sur le lien de confirmation, l’Utilisateur termine
          son Inscription au Site Internet.
        </p>

        <h3>4.2. Abonnement à des Modèles</h3>

        <h4>4.2.1. Contenu de l’abonnement</h4>

        <p className="termsConditionsContent__parag1">
          Suite à son Inscription, l’Utilisateur peut s’abonner à des Modèles
          actives sur le Site Internet.
        </p>

        <p className="termsConditionsContent__parag1">
          Pour cela, il doit cliquer sur le bouton « S’abonner » apparaissant
          sur leur profil des Modèles ou en cliquant sur “Médias privés” et sur
          un des contenus floutés.
        </p>

        <p className="termsConditionsContent__parag1">
          Il est alors renvoyé sur une page décrivant le prix de l’abonnement à
          ce Modèle.
        </p>

        <p className="termsConditionsContent__parag1">
          Par le biais de l’abonnement, l’Utilisateur obtient l’accès à des
          Contenus exclusifs, un accès à des médias non censurés (retrait du
          « floutage » sur les Contenus du Modèle).
        </p>

        <p className="termsConditionsContent__parag1">
          Chaque abonnement n’est valable que pour le seul Modèle auquel
          l’Utilisateur s’est abonné.
        </p>

        <p className="termsConditionsContent__parag1">
          Chaque abonnement ne donne ainsi accès qu’aux Contenus exclusifs non
          censurés du seul Modèle auquel l’Utilisateur s’est abonné.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur comprend et accepte que FEETY n’est pas responsable et
          n’exerce aucun contrôle sur la fréquence, la qualité et la quantité de
          partage de Contenus par les Modèles, qui exercent leur activité en
          toute indépendance.
        </p>

        <p className="termsConditionsContent__parag1">
          Il appartient à l’Utilisateur d’effectuer des recherches sur chaque
          Modèle, relatives notamment à leur activité sur le Site Internet,
          avant de souscrire à tout abonnement.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur peut à tout moment suspendre le renouvellement de son
          abonnement, pour toute raison, et notamment en cas d’absence
          d’activité de la part d’un Modèle. 
        </p>

        <h4>4.2.2. Choix de l’offre d’abonnement</h4>

        <p className="termsConditionsContent__parag1">
          Conformément aux articles L. 111-1 et L. 111-4 du Code de la
          consommation, les caractéristiques essentielles et les prix des
          Services proposés par FEETY dans le cadre de l’abonnement sont
          disponibles sur le Site Internet sous forme d’un descriptif reprenant
          ses principales caractéristiques ainsi que dans les stipulations des
          Conditions Générales.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur peut, à tout moment, obtenir toute information
          complémentaire ou une réponse à ses questions sur les caractéristiques
          des Services en contactant FEETY par email à l’adresse suivante :
          contact@feety.app.
        </p>

        <p className="termsConditionsContent__parag1">
          Le prix TTC sera généré automatiquement en fonction du pays de
          résidence déclaré, et lui sera communiqué avant tout achat sur le Site
          Internet.
        </p>

        <p className="termsConditionsContent__parag1">
          En application des dispositions de l'article 259 B 12e du Code Général
          des Impôts, la société LMCF SAS collecte la TVA Française.
        </p>

        <p className="termsConditionsContent__parag1">
          L’offre « mensuelle » constitue en un prélèvement mensuel sur le moyen
          de paiement choisi par l’Utilisateur, dont le renouvellement peut être
          interrompu à tout moment.
        </p>

        <p className="termsConditionsContent__parag1">
          En cas d’interruption de l’abonnement, l’Utilisateur perd l’accès aux
          Contenus exclusifs du Modèle choisi 1 mois après le paiement de la
          dernière mensualité.
        </p>

        <p className="termsConditionsContent__parag1">
          Le renouvellement peut être interrompu par l’Utilisateur à tout
          moment. 
        </p>

        <p className="termsConditionsContent__parag1">
          Dans ce cas, l’Utilisateur perd l’accès aux Contenus exclusifs du
          Modèle choisi 1 mois après le paiement de la dernière mensualité.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur est ensuite invité à renseigner ses informations de
          facturation, comprenant son prénom, son nom, son adresse, son code
          postal, sa ville et son pays.
        </p>

        <p className="termsConditionsContent__parag1">
          Un récapitulatif du prix lui est alors présenté, comprenant le prix
          HT, le taux de TVA applicable selon le pays de résidence fiscale
          déclaré par l’Utilisateur, et le total qui sera facturé par LMCF SAS.
        </p>

        <p className="termsConditionsContent__parag1">
          Il est ensuite invité à accepter les Conditions Générales, avant de
          procéder au paiement.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur manifeste son consentement en choisissant de cliquer sur
          « J’accepte les Conditions Générales de de Vente ».
        </p>

        <p className="termsConditionsContent__parag1">
          A défaut, la commande d’un abonnement n’est pas possible.
        </p>

        <h3>4.3. Achat</h3>

        <h4>4.3.1. Paiement en ligne</h4>

        <p className="termsConditionsContent__parag1">
          Lorsque l’Utilisateur décide d’effectuer un paiement, il est renvoyé
          vers une page sécurisée de paiement.
        </p>

        <p className="termsConditionsContent__parag1">
          Un choix lui est alors offert entre plusieurs moyens de paiement.
        </p>

        <p className="termsConditionsContent__parag1">
          Il doit alors cliquer sur « J’accepte les Conditions générales de
          vente ».
        </p>

        <p className="termsConditionsContent__parag1">
          Si l’Utilisateur décide d’utiliser le module de paiement par Carte
          bleue ou par son compte PayPal déployé sur le Site Internet, ses
          données de paiement seront directement transmises à la société EPOCH,
          qui sera en charge de la transaction.
        </p>

        <p className="termsConditionsContent__parag1">
          Toutes les données bancaires sont traitées par un module de paiement
          sécurisé, qui ne transmet aucune information de paiement à LMCF SAS.
        </p>

        <p className="termsConditionsContent__parag1">
          Pour des raisons de sécurité et de confidentialité, l’Utilisateur est
          averti que le paiement peut nécessiter une validation par la connexion
          à des services tiers.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur est averti que tout usage frauduleux des procédures
          dites de « litige » sur PAYPAL pourra entrainer la résiliation du
          Compte aux torts exclusifs de l’Utilisateur, sans remboursement des
          sommes versées, et sans préjudice des indemnisations qui pourraient
          être demandées par LMCF SAS.
        </p>

        <p className="termsConditionsContent__parag1">
          Si le paiement est accepté, l’Utilisateur obtient alors un accès aux
          Contenus exclusifs diffusés par le Modèle auquel il s’est abonné. 
        </p>

        <h4>4.3.2. Fraude</h4>

        <p className="termsConditionsContent__parag1">
          En cas de transaction financière signalée comme potentiellement
          frauduleuse par les organismes de paiement ou les établissements
          bancaires, ou bien en cas d’activité suspicieuse sur le compte de tout
          Utilisateur, LMCF SAS pourra qualifier le Compte de l’Utilisateur de
          « Compte Surveillé ».
        </p>

        <p className="termsConditionsContent__parag1">
          Par dérogation aux stipulations du présent Article, LMCF SAS se
          réserve le droit de retenir les sommes versées par le biais d’un
          Compte Surveillé pour une durée maximale ne pouvant excéder 6 mois, à
          compter de la date du versement, ou jusqu’à l’établissement du
          caractère légitime des paiements.
        </p>

        <p className="termsConditionsContent__parag1">
          Les sommes ainsi retenues ne donneront aucun accès aux Contenus
          jusqu’à l’expiration du délai, ou l’établissement de leur caractère
          légitime.
        </p>

        <p className="termsConditionsContent__parag1">
          Dans le cas où une quantité supérieure à 1/3 des Utilisateurs Abonnés
          à un Modèle serait de Comptes Surveillés, LMCF SAS pourra procéder à
          la suspension du compte du Modèle, et pourra traiter l’intégralité des
          sommes versées au Modèle comme des sommes versées par des Utilisateurs
          Surveillés.
        </p>

        <h4>4.3.3. Droit de rétractation</h4>

        <p className="termsConditionsContent__parag1">
          En accédant aux Contenus, à la suite de toute commande sur le Site
          Internet, conformément aux articles L. 221-18 et suivants du Code de
          la consommation, l’Utilisateur reconnait qu’LMCF SAS a pleinement
          exécuté ses obligations, et il renonce au bénéfice du délai de
          rétractation de quatorze (14) jours issu des dispositions du Code de
          la consommation.
        </p>

        <p className="termsConditionsContent__parag1">
          En effet, l’accès aux Contenus exclusifs étant immédiat, l’Utilisateur
          comprend et accepte que les dispositions du 1° de l’article L221-28 du
          Code de la consommation s’appliquent :
        </p>

        <p className="termsConditionsContent__parag1">
          « Le droit de rétractation ne peut être exercé pour les contrats :
        </p>

        <p className="termsConditionsContent__parag1">
          1° De fourniture de services pleinement exécutés avant la fin du délai
          de rétractation et dont l'exécution a commencé après accord préalable
          exprès du consommateur et renoncement exprès à son droit de
          rétractation ».
        </p>

        <h3>4.4. Médias privés</h3>

        <h4>4.4.1. Procédure de demande de communication de médias privés</h4>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur a l’opportunité de formuler une demande de communication
          de médias privés à un Modèle.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur a la possibilité de demander la communication de
          Contenus suivant un format spécifique, ou incluant des aspects de
          contenu particuliers.
        </p>

        <p className="termsConditionsContent__parag1">
          Le Modèle a la possibilité, mais en tout état de cause, aucune
          obligation, de répondre à la demande, et de proposer un prix hors
          taxes pour la communication du média privé à l’Utilisateur.
        </p>

        <p className="termsConditionsContent__parag1">
          Si l’Utilisateur accepte l’offre de prix hors taxes formulée par le
          Modèle, il procède alors au paiement par le biais du Site Internet du
          prix hors taxes, ainsi que des taxes applicables à la transaction, en
          fonction de son pays de résidence.
        </p>

        <p className="termsConditionsContent__parag1">
          Le Modèle procédera alors à la communication à l’Utilisateur du média
          privé dans les 72 heures suivant le paiement de la somme convenue par
          l’Utilisateur.
        </p>

        <p className="termsConditionsContent__parag1">
          Le Modèle mettra à la disposition de l’Utilisateur le média privé par
          le biais du Site Internet.
        </p>

        <p className="termsConditionsContent__parag1">
          Les Contenus communiqués dans ce cadre devront être conformes aux
          spécifications fournies par l’Utilisateur, sous réserve de leur
          acceptation par le Modèle.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur reconnait qu’un média privé n’est pas nécessairement un
          média exclusivement destiné à l’Utilisateur mais un média qui
          correspond exactement à la demande de l’Utilisateur et qui n’est pas
          gratuitement accessible sur tout autre support.
        </p>

        <p className="termsConditionsContent__parag1">
          Le Modèle pourra donc réutiliser tout média privé communiqué à
          l’Utilisateur, à condition toutefois de ne pas le rendre gratuitement
          accessible, et ce, sur tout support pendant une durée minimale de deux
          ans.
        </p>

        <p className="termsConditionsContent__parag1">
          En cas de non communication par le Modèle du média privé dans les
          délais prévus, l’Utilisateur pourra demander un remboursement à LMCF
          SAS de l’intégralité de la somme versée.
        </p>

        <p className="termsConditionsContent__parag1">
          En cas de non-conformité du Contenu réalisé avec la demande formulée
          par l’Utilisateur, celui-ci pourra formuler une réclamation auprès
          d’LMCF SAS, qui examinera sa demande.
        </p>

        <p className="termsConditionsContent__parag1">
          Si la demande est considérée comme bien fondée, LMCF SAS procédera au
          remboursement de l’intégralité de la somme versée par l’Utilisateur.
        </p>

        <h4>4.4.2. Propriété des médias privés</h4>

        <p className="termsConditionsContent__parag1">
          La mise à disposition du média privé ne constitue pas une cession des
          droits d’auteurs, ou une licence sur le contenu du média.
        </p>

        <p className="termsConditionsContent__parag1">
          Elle ne constitue pas non plus un contrat relatif au droit à l’image
          du Modèle ou de l’Utilisateur, qui restent pleinement propriétaires du
          média privé, et de l’intégralité des droits afférents.
        </p>

        <p className="termsConditionsContent__parag1">
          La mise à disposition d’un média privé réalisé par un Modèle est
          effectuée uniquement pour l’usage personnel de l’Utilisateur, dans le
          cadre de la navigation Site Internet.
        </p>

        <p className="termsConditionsContent__parag1">
          La mise à disposition d’un média privé par un Utilisateur à un Modèle
          est effectuée uniquement à l’attention du Modèle, dans le cadre de la
          réalisation des Services.
        </p>

        <p className="termsConditionsContent__parag1">
          Tout téléchargement, partage, mise à disposition, reproduction, ou
          toute autre forme d’utilisation ou de diffusion à l’exclusion de la
          consultation sur le Site Internet porte atteinte aux droits du Modèle
          ou de l’Utilisateur, et d’LMCF SAS, et engage la responsabilité de
          l’Utilisateur ou du Modèle.
        </p>

        <p className="termsConditionsContent__parag1">
          LMCF SAS sera alors libre de procéder à la suppression du compte de
          l’Utilisateur à ses torts exclusifs, sans qu’aucun remboursement pour
          les sommes versées par l’Utilisateur ne soit exigible par ce dernier.
        </p>

        <p className="termsConditionsContent__parag1">
          LMCF SAS sera alors également libre de facturer à l’Utilisateur une
          somme égale à 10 fois le montant du prix de communication du média
          privé convenu avec le Modèle, au titre de la violation par
          l’Utilisateur de son engagement contractuel de non-communication des
          médias privés.
        </p>

        <p className="termsConditionsContent__parag1">
          Le versement de cette somme n’exclut pas la mise en cause de la
          responsabilité de l’Utilisateur au titre de la violation des droits du
          Modèle, qui conservera en tout état de cause la faculté de demander la
          réparation de son entier préjudice en découlant.
        </p>

        <p className="termsConditionsContent__parag1">
          Le versement de cette somme n’exclut également pas la mise en cause de
          la responsabilité de l’Utilisateur au titre de la violation d’autres
          droits d’LMCF SAS, qui conservera en tout état de cause la faculté de
          demander la réparation de son entier préjudice découlant des
          violations contractuelles commises par l’Utilisateur.
        </p>

        <p className="termsConditionsContent__parag1">
          L’intégralité de la somme ainsi perçue sera reversée par LMCF SAS au
          Modèle.
        </p>

        <p className="termsConditionsContent__parag1">
          Outre cette capacité de suppression du Compte, et la facturation de
          cette somme supplémentaire, LMCF SAS se réserve le droit d’exercer
          toute autre action judiciaire à l’encontre de l’Utilisateur afin de
          faire valoir ses droits, ou d’assister le Modèle ou tout tiers
          intéressé, dans leurs propres actions à l’encontre de l’Utilisateur,
          sur le fondement notamment des droits d’auteur, du droit à l’image,
          des droits de la personnalité du Modèle et de la responsabilité
          contractuelle.
        </p>

        <p className="termsConditionsContent__parag1">
          Dans le cas de la mise à disposition de médias privés par
          l’Utilisateur au Modèle, celui-ci s’est engagé à ne pas en effectuer
          de reproduction, mise à disposition, partage ou téléchargement hors du
          cadre de la réalisation des Services.
        </p>

        <h3>4.5. Mise à jour du compte de l’Utilisateur</h3>

        <p className="termsConditionsContent__parag1">
          L’Inscription donne accès à l’Utilisateur aux pages qui lui sont
          dédiées sur le Site Internet à l’adresse suivante :
          https://feety.app/parameters.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur peut mettre à jour les informations de son Compte quand
          il le souhaite.
        </p>

        <p className="termsConditionsContent__parag1">
          Il peut également accéder à ses informations de facturation, ses
          demandes de médias privés, ainsi que l’historique de ses achats.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur s’engage toutefois à maintenir en permanence sur son
          Compte des informations complètes et fidèles à sa situation, et, par
          conséquent, à modifier son Compte à chaque changement de situation.
        </p>

        <h4>4.5.1. Personnalisation du Compte de l’Utilisateur</h4>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur peut également personnaliser son Compte s’il le souhaite
          :
        </p>

        <ul>
          <li>En modifiant son pseudo, ou son mot de passe ;</li>
          <li>En intégrant une photo de profil.</li>
        </ul>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur s’engage à ne jamais utiliser de photographie de nature
          à porter atteinte à l’ordre public de quelque façon que ce soit,
          contenant des photos nues ou topless notamment, ou portant atteinte
          aux droits d’autrui, ou présentant un caractère illégal. 
        </p>

        <p className="termsConditionsContent__parag1">
          Le Compte de l’Utilisateur est strictement personnel, et celui-ci
          s’engage à conserver son mot de passe secret et à ne pas le divulguer
          à un quelconque tiers à quelque titre que ce soit.
        </p>

        <p className="termsConditionsContent__parag1">
          Toute connexion ou utilisation du Compte de l’Utilisateur par un tiers
          de son fait pourra entraîner la suspension ou la suppression du Compte
          par LMCF SAS, aux torts exclusifs de l’Utilisateur, sans qu’aucun
          remboursement pour les sommes déjà versées par celui-ci ne puisse être
          exigé.
        </p>

        <p className="termsConditionsContent__parag1">
          En cas de perte ou d'oubli de son mot de passe, l’Utilisateur devra le
          réinitialiser sans délai en utilisant le bouton « mot de passe oublié
          » puis en saisissant l’adresse électronique précédemment indiquée et
          liée à son Compte. 
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur est seul responsable des conséquences de l’utilisation
          de son Compte et reconnaît que toute connexion ou transmission de
          données effectuée en utilisant son Compte sera réputée avoir été
          effectuée par lui.
        </p>

        <h4>4.5.2. Suppression du Compte de l’Utilisateur</h4>

        <p className="termsConditionsContent__parag1">
          À tout moment, l’Utilisateur peut adresser une demande à LMCF SAS pour
          obtenir la suppression de son Compte.
        </p>

        <p className="termsConditionsContent__parag1">
          Cette demande peut être effectuée de façon automatisée par le biais de
          la rubrique « suppression de compte » du profil de l’utilisateur.
        </p>

        <p className="termsConditionsContent__parag1">
          Cette demande peut également être formulée par email à l’adresse :
          contact@feety.app, en indiquant « SUPPRESSION » dans l’objet de
          l’e-mail, et devra être expédiée depuis l’adresse e-mail associée au
          Compte.
        </p>

        <p className="termsConditionsContent__parag1">
          En cas d’envoi d’une demande dans les formes prescrites au présent
          article, LMCF SAS s’engage à procéder à la suppression du compte, et
          de toutes les informations afférentes dans les quinze jours suivant la
          réception de l’e-mail.
        </p>

        <p className="termsConditionsContent__parag1">
          Toute demande de suppression du compte est définitive, et ne donne
          lieu à aucun remboursement, quelles que soient les périodes
          d’abonnement réglées par avance.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur comprend et accepte que le recours à la suppression du
          compte par demande adressée par email entraine nécessairement un délai
          de traitement supérieur à la suppression automatisée par le biais du
          formulaire présent sur le profil de l’Utilisateur.
        </p>

        <h3>ARTICLE 5. Dispositions générales</h3>
        <h4>5.1. Code de bonne conduite</h4>

        <p className="termsConditionsContent__parag1">
          Lors de la navigation sur le Site Internet, et notamment lors de
          l’envoi de demandes de communication de médias privés, l’Utilisateur
          s’engage et garantit qu’il n’enfreindra aucune disposition légale ou
          réglementaire, et qu’il n’aura aucun comportement contraire à l’ordre
          public et aux bonnes mœurs et notamment :
        </p>

        <ul>
          <li>
            <b>
              visant à obtenir des relations sexuelles tarifées avec un Modèle ;
            </b>
          </li>
          <li>
            <b>encourageant ou cautionnant la violence ;</b>
          </li>
          <li>
            <b>
              cautionnant ou encourageant toute forme de discrimination en
              raison de la race, de l’origine ethnique, de la religion, du
              handicap, du sexe, de l’âge, de l’orientation/identité sexuelle ; 
            </b>
          </li>
          <li>
            <b>incitant à la haine ;</b>
          </li>
          <li>
            <b>
              présentant un caractère raciste, homophobe ou discriminatoire ;
            </b>
          </li>
          <li>
            <b>constituant une diffamation ou une insulte ;</b>
          </li>
          <li>
            <b>
              constituant une menace quelconque notamment proférée à l’encontre
              d’un Modèle (ou de quiconque) ; 
            </b>
          </li>
          <li>
            <b>constitutif de harcèlement ;</b>
          </li>
          <li>
            <b>
              constitutif d’une divulgation non autorisée d'informations
              personnelles et/ou confidentielles de tiers, et notamment des
              Modèles et autres Utilisateurs, et ce, y compris les noms,
              prénoms, adresses, médias sociaux, numéros de téléphone,
            </b>
          </li>
          <li>
            <b>
              adresse e-mail de personnes physiques identifiées par des
              pseudonymes, ou de tout autre élément permettant leur
              identification, ou portant atteinte à leur vie privée. 
            </b>
          </li>
        </ul>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur garantit au contraire qu’il utilisera les outils de mise
          en contact avec les Modèles et LMCF SAS de bonne foi, dans l’unique
          but de permettre la réalisation des Services, conformément aux
          présentes Conditions Générales.
        </p>

        <p className="termsConditionsContent__parag1">
          Pour toutes les prestations réalisées dans le cadre du Site Internet,
          l’Utilisateur s’engage irrévocablement à utiliser exclusivement les
          Services mis en place par LMCF SAS et de ne jamais agir aux fins de
          contourner le système de versement des sommes dues mis en place sur le
          Site Internet.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur s’interdit ainsi par exemple de verser des sommes
          d’argent au Modèle pour des prestations réalisées sur le Site Internet
          par un biais autre que le Site Internet.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur est informé qu’LMCF SAS peut procéder à la vérification
          du contenu des communications à la demande des Modèles.
        </p>

        <p className="termsConditionsContent__parag1">
          LMCF SAS porte une attention particulière à l’instauration d’un climat
          de confiance entre les Modèles et les Utilisateurs.
        </p>

        <p className="termsConditionsContent__parag1">
          Par conséquent, tout comportement abusif à l’encontre des Modèles,
          notamment lors des échanges relatifs à la communication de médias
          privés, entrainera une suspension immédiate du compte de
          l’Utilisateur, sans préjudice de toute action judiciaire qui pourrait
          être intentée, et des dommages et intérêts qui pourraient être
          demandés par le Modèle ou LMCF SAS, le cas échéant.
        </p>

        <p className="termsConditionsContent__parag1">
          <b>
            L’Utilisateur devra notamment s’abstenir de toute atteinte à la
            législation protégeant les mineurs, et leur image.
          </b>
        </p>

        <p className="termsConditionsContent__parag1">
          <b>
            Toute atteinte à cette législation qui sera portée à la connaissance
            d’LMCF SAS fera l’objet d’une dénonciation immédiate auprès des
            autorités compétentes, et d’une communication aux autorités de
            l’intégralité des informations en la possession d’LMCF SAS.
          </b>
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur s’engage à signaler toute violation constatée des
          stipulations du présent article, ou de la législation en vigueur à
          LMCF SAS, par le biais du bouton « signalement » adjoint aux Contenus,
          ou en contactant directement LMCF SAS à l’adresse : contact@feety.app.
        </p>

        <h4>5.2. Non-respect des Conditions Générales</h4>

        <p className="termsConditionsContent__parag1">
          En cas de non-respect par tout Utilisateur des Conditions Générales
          entrainant la mise en place de procédures spécifiques par LMCF SAS,
          une somme forfaitaire de 100 euros H.T. pourra être facturée par LMCF
          SAS à l’Utilisateur, en compensation des frais de traitement du
          dossier par LMCF SAS, sans préjudice d’éventuels dommages et intérêts,
          ou indemnités de recouvrement.
        </p>

        <h4>5.3. Propriété intellectuelle</h4>

        <p className="termsConditionsContent__parag1">
          Tous les droits de propriété intellectuelle, en particulier les droits
          d’auteur, marques déposées, droits à l’image et droits voisins portant
          sur la structure générale du Site Internet ainsi que sur les textes,
          logos, et tout autre élément composant le Site Internet, appartenant à
          LMCF SAS, aux sociétés de son groupe ou à leurs dirigeants demeurent
          la propriété exclusive d’LMCF SAS ou de leurs titulaires respectifs.
        </p>

        <p className="termsConditionsContent__parag1">
          Toute exploitation, commerciale ou non, notamment tout téléchargement,
          copie, reproduction, distribution, transmission, diffusion,
          adaptation, traduction ou représentation, intégrale ou partielle du
          Site Internet, et notamment de sa structure générale ou de son logo,
          des illustrations et Contenus présents sur le Site Internet, par tout
          moyen et procédé actuel ou futur, sur tout support actuel ou futur,
          sans l’autorisation préalable et écrite d’LMCF SAS ou de leurs
          titulaires respectifs est interdite et est susceptible de donner lieu
          à des poursuites judiciaires, notamment au titre de la contrefaçon.
        </p>

        <p className="termsConditionsContent__parag1">
          De manière générale, toute reproduction totale ou partielle,
          modification ou utilisation de ces marques, illustrations, images et
          logotypes, des Contenus etc. pour quelque motif et sur quelque support
          que ce soit, sans accord exprès et préalable d’LMCF SAS, ou de leurs
          titulaires est strictement interdite. 
        </p>

        <p className="termsConditionsContent__parag1">
          Il en est de même de toute combinaison ou conjonction avec toute autre
          marque, symbole, logotype et plus généralement tout signe distinctif
          destiné à former un logo composite. Il en est de même pour tout droit
          d'auteur, dessin, modèle et brevet qui sont la propriété d’LMCF SAS,
          des sociétés de son groupe, de leurs dirigeants ou de leurs
          partenaires, notamment des Modèles. 
        </p>

        <p className="termsConditionsContent__parag1">
          Les bases de données figurant sur le Site Internet sont protégées par
          les dispositions des articles L341-1 et suivants du Code de la
          propriété intellectuelle. Sont notamment interdites l'extraction et la
          réutilisation, quantitativement ou qualitativement substantielles, du
          contenu des bases de données contenues sur le Site Internet. Tout
          contrevenant s'expose aux sanctions visées aux articles L 343-1 et
          suivants du Code de la propriété intellectuelle.
        </p>

        <p className="termsConditionsContent__parag1">
          Il est strictement interdit d’utiliser tout programme destiné à
          aspirer le contenu du Site Internet, à l’exception des robots utilisés
          par les sociétés exploitant des moteurs de recherche.
        </p>

        <p className="termsConditionsContent__parag1">
          Les droits de propriété littéraire et artistique afférents aux
          Contenus, les droits à l’image des Modèles sont la propriété des
          Modèles et de leurs auteurs.
        </p>

        <p className="termsConditionsContent__parag1">
          La diffusion des Contenus sur le Site Internet ne constituent pas une
          cession ou une licence au profit des Utilisateurs, et n’accordent
          aucuns droits aux Utilisateurs, sans distinction d’abonnement, ou de
          sommes dépensées.
        </p>

        <p className="termsConditionsContent__parag1">
          Le paiement d’un abonnement ne confère aucun droit à l’Utilisateur sur
          les Contenus auxquels un accès lui est autorisé, autre que le droit
          d’accès et de consultation des Contenus dans le cadre de la navigation
          sur le Site Internet.
        </p>

        <p className="termsConditionsContent__parag1">
          Il est expressément prohibé de procéder à tout téléchargement,
          reproduction par tous moyens, ou diffusion des Contenus partagés par
          les Modèles sur le Site Internet sur tout autres support.
        </p>

        <h3>ARTICLE 6. Données personnelles</h3>

        <p className="termsConditionsContent__parag1">
          FFETY, dans le cadre des Services, est amené à recueillir un certain
          nombre de données personnelles des Utilisateurs, nécessaires au bon
          fonctionnement du Site Internet et l’exécution des Services.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur reconnait avoir pris connaissance de la charte de
          gestion des données personnelles disponible à l’adresse suivante, et
          avoir été informé de ses droits, ainsi que des modalités de leur
          exercice qui y sont exposées : https://feety.app/privacy. 
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur reconnait qu’à chaque fois qu’une donnée personnelle le
          concernant a été recueillie, il en a été informé par LMCF SAS.
        </p>

        <p className="termsConditionsContent__parag1">
          Par la création d’un Compte, l’Utilisateur accepte que les données
          personnelles qu’il communique à LMCF SAS fassent l’objet du traitement
          nécessaire à la mise en œuvre des Services, notamment conformément aux
          lois, règlements et autres dispositions impératives applicables.
        </p>

        <p className="termsConditionsContent__parag1">
          LMCF SAS ne transmet ces données personnelles à aucun tiers, sauf
          consentement exprès de l’Utilisateur. 
        </p>

        <p className="termsConditionsContent__parag1">
          Les données personnelles collectées par LMCF SAS ne sont ni vendues,
          ni communiquées à des tiers hors des conditions prévues au présent
          article.
        </p>

        <p className="termsConditionsContent__parag1">
          Les données personnelles de l’Utilisateur ne seront utilisées à des
          fins de prospection commerciale qu’avec son consentement explicite.
        </p>

        <p className="termsConditionsContent__parag1">
          Conformément à la loi « Informatique et Libertés » du 6 janvier 1978,
          et du ‎Règlement (UE) 2016/679 « RGPD », l’Utilisateur dispose à tout
          moment d'un droit d’accès, d’interrogation, de modification, de
          rectification et de suppression des données personnelles le
          concernant.
        </p>

        <p className="termsConditionsContent__parag1">
          Si l’Utilisateur souhaite ne plus recevoir d’informations par courrier
          électronique de la part d’LMCF SAS, autres que les informations
          nécessaires à l’utilisation des Services, il peut :
        </p>

        <ul>
          <li>
            cliquer sur le lien de désabonnement présent au pied des courriers
            électroniques adressés par LMCF SAS ;
          </li>
          <li>
            ou, s’il le préfère, le signaler à tout moment à LMCF SAS par
            courrier électronique adressé à contact@feety.app étant entendu que
            le délai de traitement est plus long par ce biais que par le lien de
            désabonnement.
          </li>
        </ul>

        <p className="termsConditionsContent__parag1">
          LMCF SAS conserve ces informations pendant un an à compter de la fin
          des relations contractuelles avec l’Utilisateur. Tout compte créé
          restant inactif pendant une période ininterrompue de un an verra les
          données personnelles collectées et liées à ce compte supprimées et son
          compte anonymisé.
        </p>

        <p className="termsConditionsContent__parag1">
          Les données personnelles dont la conservation est obligatoire
          (documents comptables, etc…) seront conservées sous forme d’archives
          pour toute la durée obligatoire en application du régime applicable.
        </p>

        <p className="termsConditionsContent__parag1">
          L’ensemble des droits relatifs aux données personnelles s’exerce
          auprès d’LMCF SAS par E-mail à l’adresse : contact@feety.app.
        </p>

        <h3>ARTICLE 7. Cookies</h3>

        <p className="termsConditionsContent__parag1">
          Pour la bonne opération du Site Internet, LMCF SAS peut être amené à
          déposer des cookies dans le navigateur de l’Utilisateur.
        </p>

        <p className="termsConditionsContent__parag1">
          Pour des besoins statistiques, LMCF SAS peut être amené à recueillir
          des informations de navigation via l'utilisation de cookies. 
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur est libre d’accepter ou de refuser les cookies en
          configurant son navigateur (désactivation de tout ou partie des
          cookies – voir manuel du navigateur Web ou fonction d’aide).
        </p>

        <p className="termsConditionsContent__parag1">
          La désactivation des cookies peut entraîner l’indisponibilité de
          certains Services du Site.
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur peut, par ailleurs, à tout moment supprimer
          l’enregistrement des cookies, ou les cookies déjà enregistrés sur son
          ordinateur, en paramétrant les options de protection de la vie privée
          de son navigateur Internet (par exemple : Outils > Effacer mes traces
          > Cookies, sur Mozilla Firefox et Outils > Supprimer l’historique de
          navigation > Supprimer les cookies, sur Microsoft Internet Explorer).
        </p>

        <p className="termsConditionsContent__parag1">
          Le site internet de la CNIL indique également la marche à suivre pour
          gérer et supprimer les cookies sur son navigateur.
        </p>

        <p className="termsConditionsContent__parag1">
          Ces explications sont disponibles à l’adresse suivante :
          https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser. 
        </p>

        <p className="termsConditionsContent__parag1">
          Les cookies déposés, le cas échéant, ont une durée d’activité de douze
          (12) mois au maximum.
        </p>

        <h3>ARTICLE 8. Droit applicable et juridiction compétente</h3>

        <p className="termsConditionsContent__parag1">
          <b>
            Les présentes Conditions Générales sont rédigées en langue française
            et les opérations qui en découlent sont régies et soumises au droit
            français.
          </b>
        </p>

        <p className="termsConditionsContent__parag1">
          <b>
            Dans le cas où elles seraient traduites en une ou plusieurs langues
            étrangères, seul le texte français ferait foi en cas de litige.
          </b>
        </p>

        <p className="termsConditionsContent__parag1">
          En cas de différend, les Parties s’engagent, avant toute saisine d’une
          juridiction, à tenter de trouver un accord amiable.
        </p>

        <p className="termsConditionsContent__parag1">
          Conformément aux articles du code de la consommation L611-1 et
          suivants et R612-1 et suivants, il est prévu que pour tout litige de
          nature contractuelle portant sur l'exécution du contrat de vente et/ou
          la prestation de services n'ayant pu être résolu dans le cadre d'une
          réclamation préalablement introduite auprès d’LMCF SAS, le
          Consommateur pourra recourir gratuitement à la médiation.
        </p>

        <p className="termsConditionsContent__parag1">
          Il contactera l'Association Nationale des Médiateurs (ANM) soit :
        </p>

        <ul>
          <li>par courrier, en écrivant au 62 rue Tiquetonne 75002 PARIS</li>
          <li>
            par e-mail en remplissant le formulaire de saisine en ligne à
            l'adresse suivante www.anm-conso.com
          </li>
        </ul>

        <p className="termsConditionsContent__parag1">
          <b>
            En tout état de cause, l’Utilisateur est informé que le recours à un
            médiateur, ou à toute forme de tentative de résolution amiable d’un
            éventuel litige n’est pas obligatoire, qu’il peut à tout moment y
            mettre fin, et que cela ne le prive pas du droit d’intenter une
            action judiciaire.
          </b>
        </p>

        <p className="termsConditionsContent__parag1">
          L’Utilisateur reconnait avoir pris connaissance des mentions légales
          accessibles depuis l'onglet du même nom disponibles sur le Site
          Internet à l’adresse suivante : https://feety.app/terms.
        </p>
      </div>
    </div>
  );
};

export default TermsConditions;
