import React, { useState, useEffect } from "react";

import "./subscriptionLover.css";
import { ChevronLeft } from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Avatar, Button, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";

import useInfiniteQuery from "../../../hooks/useInfiniteQuery";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import SubscribeService from "../../../_services/subscribe.service";

import genericUserIcon from "../../../assets/images/generic-user-avatar.png";
import CancelSubscribe from "../../CancelSubscribeModal";
import feetModelAtom from "../../../stateManagement/atoms/feetModelAtom";
import { useRecoilState } from "recoil";

const SubscriptionLover = () => {
  const { t } = useTranslation();
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [, setFeetModel] = useRecoilState(feetModelAtom);

  const [openCancelSubscribeModal, setOpenCancelSubscribeModal] =
    useState(false);
  const [selectedSubscriber, setSelectedSubscriber] =
    useState({});

  const {
    data,
    error,
    status,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    setData,
  } = useInfiniteQuery(
    "feet-lover-subscriptions",
    async ({ next_offset = 0 }) => {
      const response = await SubscribeService.getSubscriptions(next_offset);
      return {
        data: response.data.subscriptions,
        next_offset: response.data.next_offset,
      };
    }
  );

  useEffect(() => {
    setIsLoading((isLoading) => status === "loading");
  }, [status]);


  const loadMorePublicationsReference = React.useRef();

  const fetchingDataPanel = (text) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {text}
      </div>
    );
  };

  const handleClickViewFeetModel = (user) => {
    history.push({
      pathname: "/feet-models-view",
      search: `?user=${user._id}`,
    });
  };

  const mapSubscriptions = (subscriptions) => {
    return subscriptions?.map((subscription) => (
      <Card
        key={subscription._id}
        className="subscriptionLover__card"
        variant="outlined"
      >
        <CardContent>
          <div className="subscriptionLoverCard__header">
            <div className="user_thumbnail_details">
              <div className="avatar">
                <Avatar
                  src={
                    subscription &&
                    subscription.subscribedTo &&
                    subscription.subscribedTo.image
                      ? subscription.subscribedTo.image.url
                      : genericUserIcon
                  }
                />
              </div>
              <div 
                className="user_details"
                onClick={() => {
                  setFeetModel(subscription.subscribedTo);
                  history.push({
                  pathname: "/feet-models-view",
                  search: `?user=${subscription.subscribedTo._id}`,
                })
                }}
              >
                <Typography
                  className="subscriptionLoverCardHeader__h2"
                  variant="h5"
                  component="h2"
                >
                  {subscription &&
                    subscription.subscribedTo &&
                    subscription.subscribedTo.username}
                </Typography>
              </div>
            </div>
            <Typography
              className="subscriptionLover__prices"
              variant="body2"
              component="p"
            >
              {`${subscription.subscribedAmount.toFixed(2)} (€) ${t(
                "monthUnlockPublicationLover"
              )}`}
            </Typography>
          </div>
          <div className="subscriptionLoverCard__text">
            <Typography
              className="subscriptionLoverCard__date"
              variant="body2"
              component="p"
            >
              {moment(subscription.startDate).format("MMMM DD, YYYY")}
            </Typography>
            <div className="subscriptionLoverCard__sub">
              <Typography
                className="subscriptionLoverCard__dateText"
                variant="body2"
                component="p"
              >
                {t("subscriptionLoverTitle")} {t("startDateSubscription")}
              </Typography>
              <Typography
                className="subscriptionLoverCard__renewal"
                variant="body2"
                component="p"
              >
                {t("subscriptionLoverRenewal")}
              </Typography>
            </div>
            {subscription.suspendedAt && (
              <div className="subscriptionLoverCard__suspended">
                <small>{t("suspendedSubscription")}</small>
              </div>
            )}
          </div>
          <CardActions className="cancelSubscription_button_container">
            <Button
              className={`subscriptionLoverCard__button ${subscription && subscription.cancelled ? "default__button" : "pink__button"}`}
              variant="outlined"
              size="small"
              disabled={subscription && subscription.cancelled}
              onClick={async () => {
                setOpenCancelSubscribeModal(true);
                setSelectedSubscriber(subscription)
              }}
            >
              {subscription && subscription.cancelled ? `${t("subscriptionLoverCancelDisabled")} ${moment(subscription.cancelledAt).format("MMMM DD, YYYY")}`: t("subscriptionLoverCancelSub")}
            </Button>
          </CardActions>
        </CardContent>
        <CancelSubscribe
          openCancelSubscribeModal={openCancelSubscribeModal}
          setOpenCancelSubscribeModal={setOpenCancelSubscribeModal}
          subscription={selectedSubscriber}
          setData={setData}
          setIsLoading={setIsLoading}
        />
      </Card>
    ));
  };

  useIntersectionObserver({
    target: loadMorePublicationsReference,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  return (
    <div className="subscriptionLover__container">
      <div className="subscriptionLover__head header page_fixed_header">
        <IconButton className="back__button" onClick={() => history.goBack()}>
          <ChevronLeft />
        </IconButton>
        <h4>{t("subscriptionLoverTitle")}</h4>
      </div>
      <div className="subscriptionLover__content content__withHeader">
        {isLoading
          ? fetchingDataPanel("Fetching Subscriptions...")
          : data && data.length === 0
          ? fetchingDataPanel("No Subscriptions")
          : mapSubscriptions(data)}
      </div>
      <div ref={loadMorePublicationsReference}>
        {isFetchingNextPage ? (
          <CircularProgress color="secondary" size={10} />
        ) : null}
      </div>
    </div>
  );
};

export default SubscriptionLover;
