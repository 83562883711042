import { Dialog, DialogTitle, Divider } from "@material-ui/core";
import React, { useState } from "react";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import './style.css'

import { useTranslation } from "react-i18next";
import { useStyles } from "../../SuppressAccount/styled";

function ImageCropperModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    openModal,
    setOpenModal,
    values,
    setFieldValue,
  } = props;

  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({
    unit: '%', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    aspect: 1,
  })
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  
  const selectImage = (file) => {
    setSrc(URL.createObjectURL(file));

    setImageFile(file);
  };
  
  const cropImageNow = () => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
  
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
  
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
      
    // Converting to base64
    const base64Image = canvas.toDataURL('image/jpeg');
    canvas.toBlob((blob) => {
      let file = new File([blob], imageFile.name, { type: "image/jpeg" })

      setFieldValue("image", {
        _id: imageFile.name,
        url: base64Image,
        file,
      });
    }, 'image/jpeg');

    setOpenModal(false)
  };
  
  return (
    <Dialog
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      className={classes.suppressDialog + " image-cropper-dialog"}
    >
        <DialogTitle>
            <p style={{ marginTop: "10px" }}>{t("changeProfileImage")}</p>
        </DialogTitle>
        <Divider />
        <div className="image-cropper-content">
            <center>
                <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                    selectImage(e.target.files[0]);
                }}
                />
                <div>
                    {src && (
                        <div>
                        <br />
                        <ReactCrop src={src} onImageLoaded={setImage}
                            crop={crop} onChange={setCrop} circularCrop={true} />
                        <br />
                        </div>
                    )}
                </div>
            </center>
        </div>
        {src && (
          <>
          <Divider />
          <button onClick={cropImageNow}>{t("done")}</button>
          </>
        )}
    </Dialog>
  );
}

export default ImageCropperModal;
