import axios from "axios";
import { selector } from "recoil";

import { getAuthSession } from "../../helpers/auth.helpers";

const { REACT_APP_API_BASE_URL } = process.env;

const generalNotificationsQuery = selector({
  key: "generalNotifications",
  get: async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/v1/notifications`,
        {
          headers: {
            Authorization: `Bearer ${getAuthSession().user.access_token}`,
          },
        }
      );

      return response.data;
    } catch (e) {
      console.error("ERROR GET /api/v1/notifications", e);
    }
  },
});

export default generalNotificationsQuery;
