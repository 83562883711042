import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router";
import { IonAlert, IonLoading } from "@ionic/react";

import "./IdentityVerificationSteps.css";
import DropZone from "../../../components/Dropzone";
import Camera from "../../Camera/CameraPage";
import { getAuthSession, setAuthSession } from "../../../helpers/auth.helpers";
import IdVerificationService from "../../../_services/id-verification.service";
import { useTranslation } from "react-i18next";
import selfie from "../../../assets/images/identity-verification/take-selfie-with-id.jpg";
import UserService from "../../../_services/user.service";
import CommonAlertModal from "../../../components/Modals/AlertModals/CommonAlertModal";
import { Typography } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import DoneAllIcon from "@material-ui/icons/DoneAll";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
    paddingTop: "60px",
    paddingBottom: "100px",
    height: "100%",
    margin: "auto",
  },

  stepper: {
    marginTop: "0px !important",
  },
}));

export default function IdentityVerificationStepper({
  step = 0,
  onFinalizeId,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(step);
  const [idCardType, setIdCardType] = useState(null);
  const [cameraImage, setCameraImage] = useState(null);
  const [documentImage, setDocumentImage] = useState(null);
  const [documentBackImage, setDocumentBackImage] = useState(null);
  const [isIdValidating, setIdValidating] = useState(false);
  const [showErrorIdValidation, setShowIdValidation] = useState(false);
  const [idInvalidMsg, setIdInvalidMsg] = useState("");
  const [titleCount, setTitleCount] = useState(0);
  const [helperOpen, setHelperOpen] = useState(false);
  const [isAlreadyOpen, setIsAlreadyOpen] = useState(false);
  const [instruction, setInstruction] = useState([]);
  const [selected, setSelected] = useState(null);

  const titleSteps = [
    "National Identity Card",
    "Passport",
    "Residence Permit",
    "Driver's License",
  ];

  const cardTitle = {
    title: "Identity Verification",
    subTitle: "Government Issued ID",
    submittedTitle: "Submitted For Admin Validation",
    verifiedTitle: "Identification Verified",
    validIdTitle: "Use a valid Government Issued Document",
  };

  const cardType = [
    "national-id",
    "passport",
    "residence-permit",
    "drivers-license",
  ];

  const [user, setUser] = React.useState(null);
  const maxSteps = 6;

  const {} = useDropzone({
    getFilesFromEvent: async (event) => {
      const files = [];
      const fileList = event.dataTransfer
        ? event.dataTransfer.files
        : event.target.files;

      for (var i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);

        // current identifier if taken from camera is if file.name is one of the ff:
        // "IMG_"
        // "image"
        const isFromCamera = !!(
          file.name &&
          (file.name.includes("IMG_") || file.name.includes("image"))
        );

        // identifier if file taken from camera or library
        Object.defineProperty(file, "fromCamera", {
          value: isFromCamera,
        });

        files.push(file);
      }

      return files;
    },
    onDrop: (acceptedFiles) => {
      // Upload document.
      // once successful skip facial verification for now.
      // process backend - receive the document -> then send to ID CHECK API to validate the document.
    },
  });

  const handleNext = async () => {
    if (activeStep === 2 && idCardType === "passport") {
      await setActiveStep(3);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (!isAlreadyOpen) {
      setHelperOpen((helperOpen) => !helperOpen);
    }
    setIsAlreadyOpen(true);
    /* Validation on every step.
      Ex: MUST not proceed when did not select a document
    */
  };

  const handleBack = async () => {
    if (activeStep === 3 && idCardType === "passport") {
      await setActiveStep(1);
    } else {
      await setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleUploadDocumentImage = async (props) => {
    try {
      if (idCardType === "passport") {
        setIdValidating(true);
        await IdVerificationService.validate("idImage");
        const user_id = (await getAuthSession()).user._id;
        const { user } = await UserService.getUser(user_id, null);
        await setAuthSession(JSON.stringify({ user }));
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
        onFinalizeId();
      }
      setDocumentImage(props);
    } catch (err) {
      await Promise.all([
        setIdInvalidMsg(err?.response?.data?.message || err?.message),
        setShowIdValidation(true),
      ]);
    } finally {
      setIdValidating(false);
    }

    // handleNext();
  };
  const handleUploadDocumentBackImage = async (props) => {
    try {
      setIdValidating(true);
      await IdVerificationService.validate("idImage");
      const user_id = (await getAuthSession()).user._id;
      const { user } = await UserService.getUser(user_id, null);
      await setAuthSession(JSON.stringify({ user }));
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setDocumentBackImage(props);
      onFinalizeId();
    } catch (err) {
      await Promise.all([
        setIdInvalidMsg(err?.response?.data?.message || err?.message),
        setShowIdValidation(true),
      ]);
    } finally {
      setIdValidating(false);
    }
  };

  useEffect(() => {
    if (!user) {
      const auth = getAuthSession();
      setUser(auth);
      if (auth?.user?.idCheckDetails?.verified) {
        history.replace("/");
      }
      // const userDetails =
    }
  }, [user]);

  useEffect(() => {
    if (activeStep === 5) {
      setTimeout(() => {
        history.replace("/home-page");
      }, 3000);
    }
    if (activeStep === 6) {
      setTimeout(() => {
        history.replace("/home-page");
      }, 3000);
    }
    if (idCardType === "passport" && activeStep === 2) {
      setActiveStep(3);
    }
  }, [activeStep]);

  useEffect(() => {
    const selfie = ["selfieInst1", "selfieInst2"];
    const upload = [
      "uploadIdInst1",
      "uploadIdInst2",
      "uploadIdInst3",
      "uploadIdInst4",
      "uploadIdInst5",
    ];
    setInstruction(activeStep === 3 ? selfie : upload);
  }, []);

  const instructionList = (keys) => {
    const listItems = keys.map((trans, key) => (
      <li key={key} className={"instruction-lists"}>
        {t(trans)}
      </li>
    ));
    return <ul className={"instruction-ul"}>{listItems}</ul>;
  };

  const uploadInstruction = (keys) => {
    const instructionLists = instructionList(keys);
    return (
      <CommonAlertModal
        title={titleSteps[titleCount]}
        lists={instructionLists}
        subTitle={t("uploadIdInstructionTitle")}
        setHelperOpen={handleHelperAction}
        open={helperOpen}
      />
    );
  };

  const handleHelperAction = () => setHelperOpen((helperOpen) => !helperOpen);

  const pageInstruction = () => {
    return (
      <Typography
        component={"p"}
        style={{
          paddingBottom: "10px",
          opacity: "0.7",
        }}
      >
        {t("uploadIdInstructionTitle")}
        <HelpIcon
          style={{
            cursor: "pointer",
            display: "inline-flex",
            marginBottom: "-5px",
            marginLeft: "5px",
          }}
          onClick={handleHelperAction}
        />
      </Typography>
    );
  };

  const handleMenus = () => {
    const fieldIds = ["a25", "a50", "a75", "a76", "a77"];
    return titleSteps.map((item, key) => (
      <div className={"button"} key={key}>
        <label
          className={`btn btn-default`}
          htmlFor={fieldIds[key]}
          onClick={() => {
            setSelected(cardType[key]);
            setIdCardType(cardType[key]);
            setTitleCount(key);
          }}
          style={{
            cursor: "pointer",
            background: cardType[key] === selected && "#ffaebc",
          }}
        >
          {item}
        </label>
      </div>
    ));
  };

  const stepOne = () => {
    return (
      <div style={{ textAlign: "center", minHeight: "500px" }}>
        {uploadInstruction(instruction)}
        <h3>{cardTitle.title}</h3>
        <h5>{cardTitle.subTitle}</h5>
        {pageInstruction()}
        <span>Front ID</span>
        <DropZone
          idType={idCardType}
          successUpload={handleUploadDocumentImage}
          idSide={"idImage"}
        />
      </div>
    );
  };

  const stepTwo = () => {
    return (
      <div style={{ textAlign: "center", minHeight: "500px" }}>
        {uploadInstruction(instruction)}
        <h3>{cardTitle.title}</h3>
        <h5>{cardTitle.subTitle}</h5>
        <span>Back ID</span>
        {pageInstruction()}
        <DropZone
          idType={idCardType}
          successUpload={handleUploadDocumentBackImage}
          idSide={"idBackImage"}
        />
      </div>
    );
  };

  const stepThree = () => {
    return (
      <div
        style={{
          textAlign: "center",
          minHeight: "500px",
          marginTop: "60px",
        }}
      >
        <h3 style={{ color: "red", marginTop: "-10px" }}>
          {t("Selfie warning")}
        </h3>
        {t("selfieInstructionTitle")}
        {instructionList(instruction)}
        <img
          src={selfie}
          style={{ width: "150px", height: "150px" }}
          alt={"selfie"}
        />
      </div>
    );
  };

  const stepFour = () => {
    return (
      <div style={{ textAlign: "center", minHeight: "500px" }}>
        <Camera
          cameraSuccess={(e) => setActiveStep(5)}
          style={{ textAlign: "center" }}
        />
      </div>
    );
  };

  const stepFive = () => {
    return (
      <div style={{ textAlign: "center", minHeight: "500px" }}>
        <h3>{cardTitle.title}</h3>
        <div>
          <CheckCircleOutlineIcon
            style={{
              color: "rgb(131 231 153)",
              fontSize: "14rem",
            }}
          />
        </div>
        <div>
          <h4
            style={{
              opacity: ".9",
            }}
          >
            {t("thankYou")}!
          </h4>
          <p>{cardTitle.submittedTitle}</p>
        </div>
      </div>
    );
  };

  const stepSix = () => {
    return (
      <div style={{ textAlign: "center", minHeight: "500px" }}>
        <h3>{cardTitle.title}</h3>
        <div>
          <DoneAllIcon
            style={{
              color: "rgb(131 231 153)",
              fontSize: "14rem",
            }}
          />
        </div>
        <div>
          <h4
            style={{
              opacity: ".9",
            }}
          >
            {t("allGood")}!
          </h4>
          <p>{cardTitle.verifiedTitle}</p>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={isIdValidating}
        message={"Validating"}
      />
      <IonAlert
        isOpen={showErrorIdValidation}
        onDidDismiss={() => setShowIdValidation(false)}
        header={"Error"}
        message={idInvalidMsg}
        buttons={["OK"]}
      />
      {activeStep === 0 ? (
        <div style={{ textAlign: "center" }}>
          <h3>{cardTitle.title}</h3>
          <p>{cardTitle.validIdTitle}</p>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {handleMenus()}
          </div>
        </div>
      ) : null}
      {activeStep === 1 && stepOne()}
      {activeStep === 2 && stepTwo()}
      {activeStep === 3 && stepThree()}
      {activeStep === 4 && stepFour()}
      {activeStep === 5 && stepFive()}
      {activeStep === 6 && stepSix()}

      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        className={classes.stepper}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={
              activeStep === maxSteps - 1 ||
              (activeStep === 0 && idCardType == null) ||
              (activeStep === 1 && documentImage == null) ||
              (activeStep === 2 && documentBackImage == null) ||
              (activeStep === 4 && cameraImage == null) ||
              activeStep === 5 ||
              activeStep === 6
            }
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={
              activeStep === step || activeStep === 5 || activeStep === 6
            }
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </div>
  );
}
