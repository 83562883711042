import "../styles.css";

import { IonAlert } from "@ionic/react";
import React from "react";

const CustomMediaModal = (props) => {
  const { title, message, ok, cancel, hasCustomMediaRejected, history, url } =
    props;

  const formatMessage = (message) => {
    const msg = message.split(".");
    return `<p><span>${msg[0]}.</span>${msg[1]}.</p>`;
  };

  return (
    <IonAlert
      isOpen={hasCustomMediaRejected}
      cssClass={"custom-media-modal"}
      header={title}
      message={formatMessage(message)}
      buttons={[
        {
          text: cancel,
          handler: () => {},
        },
        {
          text: ok,
          handler: () => {
            history.push(url);
          },
        },
      ]}
    />
  );
};

export default CustomMediaModal;
