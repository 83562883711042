export function getAuthSession() {
  const auth_data = window.localStorage.getItem("infosUser");

  if (auth_data) {
    return JSON.parse(auth_data);
  } else {
    return {};
  }
}
export function setAuthSession(data) {
  const auth_data = window.localStorage.setItem("infosUser", data);
  return JSON.parse(data);
}

export function isAuthenticated() {
  try {
    return Object.keys(getAuthSession()).length > 0;
  } catch (error) {
    return false;
  }
}
