/* eslint-disable no-unused-vars */
import { Divider, IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import "./settingFeetModels.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isAndroid, isIOS } from "react-device-detect";
import {useRecoilState, useRecoilValue} from "recoil";
import {
  onSettingModelsAtom,
  userTypeAtom,
} from "../../../stateManagement/atoms/studioAtom";
import isPushNotificationAtom from "../../../stateManagement/atoms/isPushNotificationAtom";
import isReceivedMediasAtom from "../../../stateManagement/atoms/isReceivedMediasAtom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSessionStorage from "../../forms/services/storageHooks/useSessionStorage";
import receiveMediasMessageToUserAtom from "../../../stateManagement/atoms/receiveMediasMessageToUserAtom";
import LogoutModal from "./logoutModal/LogoutModal";
import newMessageBadgeAtom from "../../../stateManagement/atoms/newMessageBadgeAtom";
import MessagesIcon from "../../../assets/images/home-page-models/messages-icon.svg";
import getUserQuery from "../../../stateManagement/selectors/getUserSelector";
import FeetModelService from "../../../_services/feet-models.service";

const SettingFeetModels = (props) => {
  let history = useHistory();
  const { i18n, t } = useTranslation();
  const [newMessageBadge] = useRecoilState(newMessageBadgeAtom);
  const [userType, setUserType] = useRecoilState(userTypeAtom);
  const [onSettingModels, setOnSettingModels] = useRecoilState(
    onSettingModelsAtom
  );
  const [isPushNotification, setIsPushNotification] = useRecoilState(
    isPushNotificationAtom
  );
  const [isConnectionMobile, setIsConnectionMobile] = useState(true);
  const [toggleBlockSubscription, setToggleBlockSubscription] = useState(false);
  const [isReceivedMedias, setIsReceivedMedias] = useRecoilState(
    isReceivedMediasAtom
  );
  const [authUser] = useSessionStorage({}, "infosUser");
  const [_, setIsReceiveMediaToUser] = useRecoilState(
    receiveMediasMessageToUserAtom
  );
  const userModel = useRecoilValue(getUserQuery)

  useEffect(() => {
    if (onSettingModels === false) {
      setOnSettingModels(true);
    }
    if (userType !== "Model") {
      setUserType("Model");
    }
    return () => {};
  }, [onSettingModels, setOnSettingModels, userType, setUserType]);

  // CODE FOR CONNECTION DETECTION
  // useEffect(() => {
  //   const connection =
  //     navigator.connection ||
  //     navigator.mozConnection ||
  //     navigator.webkitConnection;

  //   function updateConnectionStatus() {
  //     setIsConnectionMobile(connection.effectiveType);
  //   }

  //   connection.addEventListener("change", updateConnectionStatus);
  //   updateConnectionStatus();
  //   sessionStorage.setItem("connection", isConnectionMobile);
  // }, [isConnectionMobile]);

  // const handleSetConnetionSwitch = () => {
  //   if (isConnectionMobile === "3g" || "4g" || "5g") {
  //     setToggleSwitchConnection(true);
  //   } else if (isConnectionMobile !== "3g" || "4g" || "5g") {
  //     setToggleSwitchConnection(false);
  //   }
  // };

  // PUSH AND TOAST NOTIFICATION FOR ANDROID & IPHONE MOBILE
  const handleToggleConnectionSwitch = () => {
    if (isAndroid && !isPushNotification) {
      toast(
        `your are in ${isConnectionMobile.toUpperCase()} connection in your Android phone. You are using mobile network`,
        {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
    if (isAndroid && isPushNotification) {
      alert(
        `your are in ${isConnectionMobile.toUpperCase()} connection to your Android phone. You are using mobile network`
      );
      // alert("This is an Android")
      Notification.requestPermission((result) => {
        if (result === "granted") {
          showNotification(
            `your are in ${isConnectionMobile.toUpperCase()} connection. You are using mobile network`
          );
        }
      });

      function showNotification(title, message) {
        if ("Notification" in window) {
          navigator.serviceWorker.ready.then((registration) => {
            registration.showNotification(title, {
              body: message,
              tag: "vibration-sample",
            });
          });
        }
      }
    }
    if (isIOS === true) {
      //  alert(`your are in ${isConnectionMobile.toUpperCase()} connection to your Iphone. You are using mobile network`)
      // Function for toast notification if user type is not selected
      toast(
        `your are in ${isConnectionMobile.toUpperCase()} connection in your Iphone. You are using mobile network`,
        {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  // CODE FOR PUSH NOTIFICATIONS
  useEffect(() => {
    if (localStorage.getItem("isPush") === null) {
      localStorage.setItem("isPush", isPushNotification);
    }
    if (!isPushNotification) {
      localStorage.setItem("isPush", false);
    } else if (isPushNotification) {
      localStorage.setItem("isPush", true);
    }
    return () => {};
  }, [isPushNotification, setIsPushNotification]);

  const handleIsPushNotifications = () => {
    if (!isPushNotification) {
      setIsPushNotification(true);
      localStorage.setItem("isPush", true);
    } else if (isPushNotification) {
      setIsPushNotification(false);
      localStorage.setItem("isPush", false);
    }
  };

  const sentToastForNotifiedActivePushNotifications = () => {
    if (!isPushNotification) {
      toast("You have just activated the reception of push notifications", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  //BOOLEAN CODE FOR RECEIVED MEDIAS
  const handleReceivedMediasSwitch = () => {
    if (isReceivedMedias) {
      setIsReceivedMedias(false);
      setIsReceiveMediaToUser(false);
    }
    if (!isReceivedMedias) {
      setIsReceivedMedias(true);
      setIsReceiveMediaToUser(true);
    }
  };

  useEffect(() => {
    if (isReceivedMedias) {
      authUser.user["receiveMedias"] = true;
    }
    if (!isReceivedMedias) {
      authUser.user["receiveMedias"] = false;
    }
    sessionStorage.setItem("infosUser", JSON.stringify(authUser));
    return () => {};
  }, [authUser, isReceivedMedias]);

  const handleToastReceiveMediasOption = () => {
    if (!isReceivedMedias) {
      toast("You have just activated the media reception options", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (isReceivedMedias) {
      toast("You have just deactivated the media reception options", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    localStorage.setItem("isReceivedMedias", isReceivedMedias);
    return () => {};
  }, [isReceivedMedias]);

  const handleBlockedSubscription = async (toggle) => {
    const response = await FeetModelService.blockSubscription({status: toggle});
    if (response && response.user) {
      setToggleBlockSubscription(response.user?.acceptSubscribers);
    }
  }

  useEffect(() => {
    setToggleBlockSubscription(userModel &&
        userModel.user && userModel.user?.acceptSubscribers);
    return () => {};
  }, [userModel])

  return (
    <div className="settingFeetModels__container">
      <ToastContainer
        position="top-center"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{top: "5em"}}
      />
      <div className="settingFeetModels__header header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => history.push("/view-own-profile-models")}
        >
          <ChevronLeft />
        </IconButton>
        <h4>{t("settingTitleFeetLover")}</h4>
        <IconButton
          className="homePageModels__messages header__rightButtton"
          onClick={() => history.push("/messages")}
        >
          <img src={MessagesIcon} alt="message icon" />
          {newMessageBadge && (
            <span className="homePageModels__onMessages"></span>
          )}
        </IconButton>
      </div>
      <div
        className={
          isIOS === true
            ? "settingFeetModels__content content__withHeader settingFeetModels__contentIphone"
            : "settingFeetModels__content content__withHeader"
        }
      >
        <Divider />
        {/* check your profile. This will be the ID API Check process... */}

        {authUser &&
            authUser.user &&
            authUser.user?.idCheckDetails &&
            !authUser.user?.idCheckDetails?.verified && <div className="setting_item">
          <p onClick={() => history.push("/identify-myself")}>
            {t("checkYourProfile")}
          </p>
        </div>}
        <Divider />
        {/* my earnings */}
        <div className="setting_item">
          <p onClick={() => history.push("/tickles-account")}>
            {t("myEarnings")}
          </p>
        </div>
        <Divider />
        {/* edit profile */}
        <div className="setting_item">
          <p onClick={() => history.push("/edit-profile")}>
            {t("editProfileSettingLover")}
          </p>
        </div>
        <Divider />
        {/* add a bank account */}
        <div className="setting_item">
          <p onClick={() => history.push("/bank-account-form")}>
            {t("addABankAccount")}
          </p>
        </div>
        <Divider />
        {/* customized media tracking */}
        <div className="setting_item">
          <p
            onClick={() => history.push("/custom-media-tracking")}
            className="cursor-pointer"
          >
            {t("customizedMediaTracking")}
          </p>
        </div>
        <Divider />
        {/* Feetlovers Guide */}
        <div className="setting_item">
          <p
            onClick={() => {
              const path = i18n.language === "en"
                  ? "/en/guide/feetgirls"
                  : "/guide/feetgirls"
              window.open(`https://feety.com${path}`, "_blank");
            }}
            className="cursor-pointer"
          >
            {t("Feetgirls Guide")}
          </p>
        </div>
        <Divider />
        <div className="setting_item">
          <p>{t("pushNotificationSettingLover")}</p>
          <div className="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              className="onoffswitch-checkbox"
              id="myonoffswitch1"
              tabIndex="0"
              onClick={() => {
                handleIsPushNotifications();
                sentToastForNotifiedActivePushNotifications();
              }}
              checked={Boolean(isPushNotification)}
              onChange={() => handleIsPushNotifications()}
            />
            <label
              className="onoffswitch-label"
              htmlFor="myonoffswitch1"
            ></label>
          </div>
        </div>
        {/* <Divider />
        <div className="setting_item">
          <p>{t("receiveMediaSettingLover")}</p>
          <div className="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              className="onoffswitch-checkbox"
              id="myonoffswitch2"
              tabIndex="0"
              checked={Boolean(isReceivedMedias)}
              onClick={() => {
                handleReceivedMediasSwitch();
                handleToastReceiveMediasOption();
              }}
              onChange={() => handleReceivedMediasSwitch()}
            />
            <label
              className="onoffswitch-label"
              htmlFor="myonoffswitch2"
            ></label>
          </div>
        </div> */}
        <Divider />
        <div
          className="setting_item languageSettings"
          onClick={() => history.push("/language")}
        >
          <p>{t("languageSettingLover")}</p>
          <p>
            {i18n.language === "en"
              ? t("englishSettingLover")
              : t("frenchSettingLover")}
          </p>
        </div>
        <Divider />
        <div className="setting_item">
          <p onClick={() => history.push("/feety-certification")}>
            {t("certification")}
          </p>
        </div>
        <Divider />
        <div className="setting_item">
          <p onClick={() => history.push("/search-history")}>
            {t("searchHistory")}
          </p>
        </div>
        <Divider />
        <div className="setting_item">
          <p onClick={() => history.push("/blocked-accounts")}>
            {t("settingsBlockedAccounts")}
          </p>
        </div>
        {/* <Divider />
        <div className="setting_item">
          <p>{t("cellularDataSettingLover")}</p>
          <div className="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              className="onoffswitch-checkbox"
              id="myonoffswitch3"
              tabIndex="0"
              onClick={() => setIsConnectionMobile(false)}
              defaultChecked={isConnectionMobile}

              // onChange={handleSetConnetionSwitch}
            />
            <label
              className="onoffswitch-label"
              htmlFor="myonoffswitch3"
            ></label>
          </div>
        </div> */}
        <Divider />
        <div className="setting_item">
          <p
            onClick={() => {
              const url =
                i18n.language === "en"
                  ? "https://feety.com/en/help-center"
                  : "https://feety.com/aide";
              window.open(url, "_blank");
            }}
          >
            {t("helpSettingLover")}
          </p>
        </div>
        <Divider />
        <div className="setting_item">
          <p>{t("blockSubscriptionSettingLover")}</p>
          <div className="onoffswitch">
            <input
                type="checkbox"
                name="onoffswitch2"
                className="onoffswitch-checkbox"
                id="myonoffswitch2"
                tabIndex="0"
                onClick={() => handleBlockedSubscription(!toggleBlockSubscription)}
                checked={Boolean(toggleBlockSubscription)}
                // onChange={() => handleBlockedSubscription(!toggleBlockSubscription)}
            />
            <label
                className="onoffswitch-label"
                htmlFor="myonoffswitch2"
            ></label>
          </div>
        </div>
        <Divider />
        <div className="setting_item">
          <p onClick={() => history.push("/suppress-account")}>
            {t("suppressAccount")}
          </p>
        </div>
        <Divider />
        <div className={"setting_item"}>
          <p onClick={() => {
            const url = i18n.language === "en"
                ? "/en/content-providers-written"
                : "/conditions-utilisation-feetgirl";
            window.open(`https://feety.com${url}`, "_blank");
          }}>
            {t("contentCreatorTermsOfUse")}
          </p>
        </div>
        <Divider />
        <div className="setting_item">
          <p
            onClick={() => {
              const url =
                i18n.language === "en"
                  ? "https://feety.com/en/privacy-policy"
                  : "https://feety.com/confidentialite";
              window.open(url, "_blank");
            }}
          >
            {t("privacyPolicySettingLover")}
          </p>
        </div>
        <Divider />
        <div className="setting_item">
          <p
            onClick={() => {
              const url =
                i18n.language === "en"
                  ? "https://feety.com/en/terms-of-service"
                  : "https://feety.com/conditions-generales-utilisation";
              window.open(url, "_blank");
            }}
          >
            {t("conditionsAndTermsOfUse")}
          </p>
        </div>
        <Divider />
        <div className="setting_item">
          {/*  <p
            onClick={() => {
              sessionStorage.clear();
              window.location.href = "/";
            }}
          >
            {t("logoutSettingLover")}
          </p>*/}
          <LogoutModal />
        </div>
        <Divider />
      </div>
    </div>
  );
};

export default SettingFeetModels;
