import CommentsIcon from "../../../assets/images/notifications-models/commentaires.png";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import useNotificationsModels from "./hooks/useNotificationsModels";
import useInfiniteQuery from "../../../hooks/useInfiniteQuery";
import {countUnreadNotificationComments, getNotificationCommentsV2} from "../../../_services/comment.service";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import commentBadgeAtom from "../../../stateManagement/atoms/notifications/badge/comment";
import commentsNotificationsAtom from "../../../stateManagement/atoms/notifications/comments";
import commentsNotificationsQueryV2 from "../../../stateManagement/selectors/commentsNotificationSelectorV2";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import {useTranslation} from "react-i18next";
import _, { uniqBy as _uniqBy } from "lodash";
import {withStyles} from "@material-ui/core/styles";
import {Badge} from "@material-ui/core";
import NotificationService from "../../../_services/notification.service";
import {SocketContext} from "../../../context/socket";

const StyledBadge = withStyles((theme) => ({
    badge: {
        padding: "0",
        borderRadius: "50%",
        width: "6px",
        lineHeight: "8px",
        height: "6px",
        // bottom:'-5px !important'
        top: "10px",
        left: "-18px",
        minWidth: "6px !important",
    },
}))(Badge);

const CommentNotificationModels = ({subscribers = []}) => {

    const socket = useContext(SocketContext);
    const [isInvisibleCommentBadge, setInvisibleCommentBadge] = useRecoilState(commentBadgeAtom);
    const [commentsNotifications] = useRecoilState(commentsNotificationsAtom);
    const [allComments, setAllComments] = useState([]);
    const { t } = useTranslation();
    const [count, incrementCount] = useState(0)

    const {
        data: comments,
        hasNextPage: commentsHasNextPage,
        fetchNextPage: commentsFetchNextPage,
        status
    } = useInfiniteQuery("notification-comments", async ({ next_offset = 0 }, ) => {
        const response = count > 0 ? await getNotificationCommentsV2(next_offset) : null;

        return {
            data: response.data.comments,
            next_offset: response.data.next_offset,
        };
    }, count);

    const newCommentHandler = useCallback(
        (newMessage) => {
            incrementCount(count+1)
        },
        [comments]
    );

    useEffect(() => {
        // observer > new message
        const message = socket.onComment();
        const onComment = message.subscribe(newCommentHandler);

        // clean up observer
        return () => onComment.unsubscribe();
    }, [socket, newCommentHandler]);
    // comment notifications
    useEffect(() => {
        if (commentsNotifications) {
            setAllComments(() =>
                _uniqBy(
                    [...commentsNotifications, ...comments].sort(
                        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    ),
                    "_id"
                )
            );
        }

        return () => {};
    }, [comments, commentsNotifications]);


    const loadMoreCommentsReference = useRef();


    const {
        clickedLikes,
        clickedComments,
        clickedTickles,
        noSubscriptionsYet,
        setNoSubscriptionsYet,
        clickedSubscriptions,
        NoNotificationsYet,
        LikesNotifications,
        CommentsNotifications,
        TicklesNotifications,
        SubscriptionsNotifications,
        deployComments,
        handleDeployComments,
        deployTickles,
        handleDeployTickles,
        deployLikes,
        handleDeployLikes,
        deploySubscriptions,
        handleDeploySubscriptions,
        handleCheckReadAllNotifications,
    } = useNotificationsModels();
    useIntersectionObserver({
        target: loadMoreCommentsReference,
        onIntersect: commentsFetchNextPage,
        enabled: commentsHasNextPage,
    });

    return (
    <div className="notificationsModelsContentSection__comments">
        <div
            className={
                deployComments === false
                    ? "notificationsModels__commentsList hidden-body"
                    : "notificationsModels__commentsList"
            }
            onClick={
                deployComments === true ? handleDeployComments : 
                async () => {
                    handleDeployComments()
                    await NotificationService.readAllNotificationsWithType("Comment")
                    setInvisibleCommentBadge(true);
                    incrementCount(count+1)
                    // handleCheckReadAllNotifications();
                }
            }
        >
            <div className="notificationsModelsSection__head">
                <div className="notif-title">
                    <StyledBadge
                        color="secondary"
                        variant="dot"
                        invisible={isInvisibleCommentBadge}
                        overlap={"rectangular"}
                    >
                        <img
                            src={CommentsIcon}
                            alt=""
                            className="notificationsModels__Icon"
                        />
                    </StyledBadge>
                    <b>{t("commentHomePageLover")}</b>
                </div>
                {deployComments === true ? (
                    <KeyboardArrowUpIcon
                        className="commentsNotificationsTopChevron"
                    />
                ) : (
                    <KeyboardArrowDownIcon
                        className="commentsNotificationsTopChevron"
                    />
                )}
            </div>
            <hr className="hr-sep-notifications" />
            <div className="notification-body">
                {status === "init" || status === "loading" || allComments.length <= 0 ? (
                    <NoNotificationsYet />
                ) : (
                    <CommentsNotifications
                        subscribers={subscribers}
                        comments={allComments}
                        loadMoreCommentsReference={loadMoreCommentsReference}
                        commentsNotifications={commentsNotifications}
                        isInvisibleCommentBadge={isInvisibleCommentBadge}
                    />
                )}
            </div>
        </div>
    </div>)
}
export default CommentNotificationModels