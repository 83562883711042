/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { ChevronLeft } from "@material-ui/icons";
import "./ticklesFeetModelsAccount.css";
import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userTypeAtom } from "../../../stateManagement/atoms/studioAtom";

import useInfiniteQuery from "../../../hooks/useInfiniteQuery";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";

import FeetModelService from "../../../_services/feet-models.service";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles } from "../../../pages/SuppressAccount/styled";
import WithdrawalService from "../../../_services/withdrawal.service";
import moment from "moment";
import useNotificationAlert from "../../../hooks/useNotificationAlert";

const TicklesFeetModelsAccount = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const classes = useStyles();

  const { addNotificationMessage } = useNotificationAlert();
  const [feetModelProfile, setFeetModelProfile] = useState({});
  const [isTransferring, setIsTransferring] = useState(false);
  const [userType, setUserType] = useRecoilState(userTypeAtom);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [gained, setGained] = React.useState(0);
  const [pending, setPending] = React.useState(0);

  const [allWithdrawals, setAllWithdrawals] = useState([]);

  const { data, hasNextPage, fetchNextPage } = useInfiniteQuery(
    "users-withdrawal",
    async ({ next_offset = 0 }) => {
      const response = await WithdrawalService.getWithdrawals();

      return {
        data: response.data.data,
        next_offset: response.data.next_offset,
      };
    }
  );

  const loadMoreReference = React.useRef();

  useIntersectionObserver({
    target: loadMoreReference,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });
  
  useEffect(() => {
    setAllWithdrawals(() =>
      _.uniqBy(
        [...data].sort(
          (a, b) => new Date(b.datePaymentWithdrawn || b.datedRequested) - new Date(a.datePaymentWithdrawn || a.datedRequested)
        ),
        "_id"
      )
    );
  }, [data]);

  useEffect(() => {
    FeetModelService.getFeetModelProfile().then((response) => {
      setFeetModelProfile(response);
      // if (response && response.profile && response.profile.gainedTicklesCount) {
      //   setGained(response.profile.gainedTicklesCount);
      // }

      setGained(response?.profile?.gainedCommissionCount.toFixed(2));
      setPending(response?.profile?.pendingCommissionCount.toFixed(2));
    });
  }, []);

  useEffect(() => {
    if (userType !== "Model") {
      setUserType("Model");
    }
  }, [setUserType, userType]);

  const handleTransferRequest = async () => {
    setIsTransferring(true);
    setOpenConfirmationModal(false);

    if(gained >= 50) {
      try {
        await WithdrawalService.transferRequest();
        
        setGained(0);
        setIsTransferring(false);

        location.reload()
      } catch (error) {
        setIsTransferring(false);
        addNotificationMessage("Transfer request failed. Please try again.", "error");
      }
    } else {
      setIsTransferring(false);
      setOpenModal(true);
    }
  }

  const convertPriceToEuro = (price) => (price / 100).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
 });

  return (
    <div className="ticklesFeetModelsAccount__container">
      <div className="ticklesFeetModelsAccount__header header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => {
            // onSettingModels === false
            //   ? history.push("/view-own-profile-models")
            //   : history.push("/setting-models")
            history.goBack();
          }}
        >
          <ChevronLeft />
        </IconButton>
        <h4>{t("myEarnings")}</h4>
      </div>
      <div className="ticklesFeetModelsAccount__content content__withHeader minmax__wrapper">
        <div className="ticklesFeetModelsAccountContent__top">
          <div className="ticklesFeetModelsAccountContentTop__tickles">
            <div className="ticklesFeetModelsAccountContentTopTickles__text">
              <b style={{ color: "#ff2775" }}>
                {pending} &euro;
              </b>
              <p>{t("Pending")}</p>
            </div>
            <div className="ticklesFeetModelsAccountContentTopTickles__text">
              <b style={{ color: "green" }}>
                {gained} &euro;
              </b>
              <p>{t("Gained")}</p>
            </div>
          </div>
        </div>
        {/* Message to inform users that wallet is not yet available for withdrawing */}
        {/*<b style={{ color: "#ff2775", marginBottom: "1px" }}>*/}
        {/*  <p className="disabled_withdrawal_button_text">{t("disabledWithdrawalBtnMessage")}</p>*/}
        {/*</b>*/}
        <Button
          onClick={() => setOpenConfirmationModal(true)}
          variant="contained"
          className="pink__button full-width"
          // disabled={true}
        >
          {isTransferring ? (
            <CircularProgress color="inherit" size={15} />
          ) : (
            t("Transfer to Bank")
          )}
        </Button>
        <div className="ticklesFeetModelsAccount__list">
          <div className="ticklesFeetModelsAccountList__head">
            <p>{t("Payment History")} :</p>
          </div>
          <div className="user_list">
            {allWithdrawals && allWithdrawals.length
              ? allWithdrawals.map((user) => (
                  <div key={user._id}>
                    <div className="user_details">
                      {user.datePaymentWithdrawn ?
                        (
                          <p>{moment(user.datePaymentWithdrawn).format("DD/MM/yyyy")} at 
                            {moment(user.datePaymentWithdrawn).format(" hh[h] mm[min]")}</p>
                        ) : (
                          <p>{moment(user.datedRequested).format("DD/MM/yyyy")} at 
                            {moment(user.datedRequested).format(" hh[h] mm[min]")}</p>
                        )
                      } 
                      <p>
                        Transfer request: {user.amount.toFixed(2)}&euro; 
                        <span
                          style={{
                            color: user.datePaymentWithdrawn ? '#008000' : '#ff5768',
                            textTransform: 'capitalize'
                          }}
                        > {user.datePaymentWithdrawn ? t("done") : t("pending")}</span>
                      </p>
                    </div>
                  </div>
                ))
              : ""}
          </div>
          <div ref={loadMoreReference}></div>
        </div>
      </div>
      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        className={classes.suppressDialog}
      >
        <DialogTitle>
          <p className={classes.dialogWarning}>INFORMATION</p>
          <p className={classes.dialogTitleMessage}>
            {t("transferFailed")}
          </p>

          <p style={{ marginTop: "10px" }}>
            {t("minimumEarningsWarning")}
          </p>
        </DialogTitle>
        <Divider />
        <div className={classes.suppressModalButtonsContainer}>
          <button
            type={"button"}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            {t("OK")}
          </button>
        </div>
      </Dialog>
      <Dialog
        open={openConfirmationModal}
        onClose={() => {
          setOpenConfirmationModal(false);
        }}
        className={classes.suppressDialog}
      >
        <DialogTitle>
          <p className={classes.dialogTitleMessage}>Confirmation</p>

          <p style={{ marginTop: "10px" }}>{t("modalYesNo")}</p>
        </DialogTitle>
        <Divider />
        <div className={classes.suppressModalButtonsContainer}>
          <button
            type={"button"}
            onClick={handleTransferRequest}
          >
            {t("proposeTicklesConfirmDialogBtnYes")}
          </button>
          <button
            type={"button"}
            onClick={() => {
              setOpenConfirmationModal(false);
            }}
          >
            {t("proposeTicklesConfirmDialogBtnNo")}
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default TicklesFeetModelsAccount;
