import React, {useRef} from "react";
import { Avatar, Button, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";

import "../followList.css";
import defaultAvatar from "../../../../assets/images/generic-user-avatar.png";

import feetModelAtom from "../../../../stateManagement/atoms/feetModelAtom";

import useSessionStorage from "../../../forms/services/storageHooks/useSessionStorage";

import FollowService from "../../../../_services/follow.service";
import { useTranslation } from "react-i18next";

const FollowingList = ({
  users,
  loadMoreUsersReference,
  id,
  setUsers,
  handleReduceFollowingCount,
  handleAddFollowingCount,
  loading,
  setIsFetchingNextPage,
  isFetchingNextPage
}) => {
  let history = useHistory();

  const [authenticated] = useSessionStorage({}, "infosUser");
  const [__, setFeetModel] = useRecoilState(feetModelAtom);
  const { t } = useTranslation();

  const myRef = useRef();

  const handleUnfollow = async (data) => {
    try {
      // setUsers((prev) => [...prev.filter((data) => data._id !== user._id)]);
      const elementsIndex = users.findIndex((user) => user._id === data._id);

      const newUsers = [...users];

      const followed = !newUsers[elementsIndex].followed;

      newUsers[elementsIndex] = { ...newUsers[elementsIndex], followed };

      setUsers([...newUsers]);

      if (!followed) {
        // follow
        await FollowService.followUser({
          followedUser: data._id,
        });
        handleAddFollowingCount();
      } else {
        // un-follow
        await FollowService.remove(data._id);
        handleReduceFollowingCount();
      }
    } catch (error) {
      console.error("ERROR unfollow in follow-page-lover page: ", error);
    }
  };

  const handleFollowOrUnfollow = async (data) => {
    try {
      const elementsIndex = users.findIndex((user) => user._id === data._id);

      const newUsers = [...users];

      const followed = !newUsers[elementsIndex].followed;

      newUsers[elementsIndex] = { ...newUsers[elementsIndex], followed };

      setUsers([...newUsers]);

      if (followed) {
        console.log(data)
        // follow
        await FollowService.followUser({
          followedUser: data._id,
        });
        handleAddFollowingCount();
      } else {
        // un-follow
        await FollowService.remove(data._id);
        handleReduceFollowingCount();
      }
    } catch (error) {
      console.error("ERROR handleFollowOrUnfollow", error);
    }
  };

  const handleScroll = () => {
      const bottom =
          myRef.current?.scrollHeight -
          myRef.current?.scrollTop ===
          myRef.current?.clientHeight;
      console.log(`is bottom now: ${bottom}`)
     if (bottom) {
       console.log("Following Reached bottom");
       setIsFetchingNextPage(true)
     }
  }

  return (
    <div className="followList__container">
      <div
          className="followList__content"
           ref={myRef}
           onScroll={handleScroll}
           style={{
             height: '700px',
             overflow: 'scroll',
           }}
      >
        {loading ? (
          <div
            style={{
              position: "absolute",
              left: "calc(50% - 20px)",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            {users && users.length
              ? users.map((user) => (
                  <div className="follow-user-list" key={user._id}>
                    <div
                      className="user_thumbnail_details"
                      onClick={() => {
                        if (user.userType === "Lover" && !user.suppress && !user.accountSoftDeleted) {
                          history.push({
                            pathname: `/visit-feet-lover/${user._id}`,
                          });
                        } else if (!user.suppress && !user.accountSoftDeleted) {
                          setFeetModel(user);
                          history.push({
                            pathname: "/feet-models-view",
                            search: `?user=${user._id}`,
                          });
                        }
                      }}
                    >
                      <div className="avatar">
                        {!user.suppress && !user.accountSoftDeleted ? <Avatar
                          src={user && user.image ? user.image.url : defaultAvatar}
                          alt="models"
                        /> : <Avatar style={{backgroundColor: "gray"}}>D</Avatar>}
                      </div>
                      <div className="user_details">
                        <h2
                          style={
                            user.subscriber && !user.suppress && !user.accountSoftDeleted
                              ? { color: "#ff2775", fontWeight: "bold" }
                                : user.suppress || user.accountSoftDeleted ? { textDecorationLine: 'line-through', backgroundColor: "gray" } : {}
                          }
                        >
                          {user && user.username}
                        </h2>
                        {/* <p>
                        {user && user.followedUser && user.followedUser.username}
                      </p> */}
                      </div>
                    </div>
                    <div className="followList__buttonContainer">
                      {authenticated.user._id === id && !user.suppress && !user.accountSoftDeleted ? (
                        <Button
                          className={`normalize-text ${
                            user.followed
                              ? "followList__Button pink__button"
                              : "followList__Button default__button"
                          }`}
                          onClick={() => {
                            handleUnfollow(user);
                          }}
                        >
                          {user.followed
                            ? t("followButtonFeetModelView")
                            : t("unfollowKebabMenuLover")}
                        </Button>
                      ) : authenticated.user._id !== user._id && !user.suppress && !user.accountSoftDeleted ? (
                        <Button
                          className={`normalize-text ${
                            user.followed
                              ? "followList__Button default__button"
                              : "followList__Button pink__button"
                          }`}
                          onClick={() => {
                            handleFollowOrUnfollow(user);
                          }}
                        >
                          {user.followed
                            ? t("unfollowKebabMenuLover")
                            : t("followButtonFeetModelView")}
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))
              : " "}
          </>
        )}
      </div>
      <div ref={loadMoreUsersReference}>
        {isFetchingNextPage ? (
            <CircularProgress color="secondary" size={10} />
        ) : (
            ""
        )}
      </div>
    </div>
  );
};

export default FollowingList;
