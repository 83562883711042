import CommentModal from "../commentsSection/CommentModal";
import React from "react";
import { saveComment } from "../../_services/comment.service";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import writeCommentsContentAtomAtom from "../../stateManagement/atoms/writeCommentsContentAtom";

export const PublicationCommentModal = (props) => {
  const history = useHistory();
  // For displaying all comments component
  const { t } = useTranslation();
  const { publication, showComment } = props;
  const [comment, setComment] = useRecoilState(writeCommentsContentAtomAtom);
  const handleSendComment = async (_publication) => {
    if (comment) {
      await saveComment({ publication: _publication.id, content: comment });
      setComment("");
      history.push({
        pathname: `/publication/${publication._id}/comments`,
        /* state: { user: publication.user._id },
        publication, */
      });
    }
  };
  const handleDisplayCommentsList = () => {
    history.push({
      pathname: `/publication/${publication._id}/comments`,
      /* state: { user: publication.user._id },
      publication, */
    });
  };

  return (
    <div className="post-comments">
      <sub onClick={handleDisplayCommentsList}>
        {t("viewCommentsHomePageLover")} {publication.commentCount}{" "}
        {t("commentHomePageLover")}
      </sub>
      {showComment && (
        <CommentModal
          handleSendComment={() => handleSendComment(publication)}
        />
      )}
    </div>
  );
};
