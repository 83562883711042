import { selector } from "recoil";

import subscriptionBadge from "../../../atoms/notifications/badge/subscription";

const setNotificationSubscriptionBadgeInVisible = selector({
  key: "setNotificationSubscriptionBadgeInVisibleSelector",
  set: ({ set }, value) => {
    set(subscriptionBadge, value);
  },
});

export default setNotificationSubscriptionBadgeInVisible;
