import React, {Fragment, useEffect, useState} from "react";
import Popover from "@material-ui/core/Popover";

import { useStyles } from "../styled";
import FetchFileWithAuthentication from "../../../../FetchFileWithAuthentication";
import ReportAccount from "../../../../ReportAccount/ReportAccount";
import useSessionStorage from "../../../../forms/services/storageHooks/useSessionStorage";
import dateLocale from "../../../../../dateLocale";
import { useTranslation } from "react-i18next";

export const MessageFile = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [authenticated] = useSessionStorage({}, "infosUser");
  const [contentType, setContentType] = useState("");
  const handleReportSucceed = (props) => {};
  const videoFormats = ["mp4", "mov", "wmv", "avi", "ogg", "webm", "mkv", "mpeg"];
  const imageFormats = ["jpeg", "png", "jpg", "svg"];
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleContentType = (message) => {
      if (message.content && message.content.includes("?")) {
          const urlContent = message.content.split("?");
          if (urlContent[0] && urlContent[0].toString().includes(".")) {
              const extension = urlContent[0].split('.').pop();
              if (videoFormats.toString().includes(extension.toLowerCase())) {
                  setContentType("video")
              } else if (imageFormats.toString().includes(extension.toLowerCase())) {
                  setContentType("image")
              }
          }
      }
      if (message.contentV2 && message.contentV2.includes("?")) {
          const urlContent2 = message.contentV2.split("?");
          if (urlContent2[0] && urlContent2[0].toString().includes(".")) {
              const extension2 = urlContent2[0].split('.').pop();
              if (videoFormats.toString().includes(extension2.toLowerCase())) {
                  setContentType("video")
              } else if (imageFormats.toString().includes(extension2.toLowerCase())) {
                  setContentType("image")
              }
          }
      }
  }
  useEffect(() => handleContentType(props.message),[props.message]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Fragment>
      <p className="createMessage__sendMessageDate">
        {dateLocale.localize(props.message.createdAt).fromNow()}
      </p>
      <div className="createMessage__SentMessage">
        <FetchFileWithAuthentication
          alt="message"
          className="swing-in-left-fwd createMessage__pictureSended"
          messageFsUrl={props.message.content || props.message.contentV2}
          onLoad={() => {
            props.scrollToBottom();
          }}
          type={contentType}
          authUser={authenticated}
          status={props?.message?.status}
          showVideoModal={props?.showVideoModal}
        />

        {/* ... menu button for reporting media file */}
        {props?.message?.from?._id !== authenticated.user._id && (
          <>
            <Popover
              className={classes.popover}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <div>
                <ReportAccount
                  type={"message"}
                  message={props.message?.messageId || props?.message?._id}
                  to={props.message?.from?._id}
                  success={handleReportSucceed}
                />
              </div>
            </Popover>
          </>
        )}

        {/* if message file status is pending props?.message?.from?._id === authenticated.user._id &&*/}
        {props?.message?.status &&
        props?.message?.status.includes("pending") ? (
          <span style={{ color: "#f50057", fontSize: "clamp(12px,4vw,16px)" }}>
            {t("moderationAwaiting")}
          </span>
        ) : null}
        {props?.message?.status &&
        props?.message?.status.includes("rejected") ? (
          <span style={{ color: "#f50057", fontSize: "clamp(12px,4vw,16px)" }}>
            Rejected
          </span>
        ) : null}
      </div>
    </Fragment>
  );
};
