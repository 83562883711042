/* eslint-disable no-unused-vars */

import React from "react";

import "./heartAnimations.css";

const HeartAnimations = () => {
  const handleHeart = () => {
    var rain = document.getElementById("rain");

    function createHearts() {
      const heart = document.createElement("div");
      heart.classList.add("heart", "fa", "fa-heart");
      heart.style.left = Math.floor(Math.random() * 100) + "vw";
      heart.style.animationDuration = Math.floor(Math.random() * 2) + 2 + "s";
      document.body.appendChild(heart);
    }
    setInterval(createHearts, 100);

    var heart = document.getElementsByClassName("heart");
    setInterval(() => {
      for (let i = 0; i < heart.length; i++) {
        if (i > 30) {
          heart[0].remove();
        }
      }
    }, 50);
  };

  return (
    <div className="heartAnimation__container">
      <div id="container" onClick={handleHeart}>
        <h2>Do you like this??</h2>
      </div>
    </div>
  );
};

export default HeartAnimations;
