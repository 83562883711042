import axios from "axios";
import { selector } from "recoil";

import { getAuthSession } from "../../helpers/auth.helpers";

const { REACT_APP_API_BASE_URL } = process.env;

const commentsNotificationsQueryV2 = selector({
    key: "commentsNotificationsV2",
    get: async () => {
        try {
            const response = await axios.get(
                `${REACT_APP_API_BASE_URL}/v2/notifications/comments`,
                {
                    headers: {
                        Authorization: `Bearer ${getAuthSession().user.access_token}`,
                    },
                }
            );

            return response.data;
        } catch (e) {
            console.error("ERROR GET /api/v1/notifications/comments", e);
        }
    },
});

export default commentsNotificationsQueryV2;
