import { selector } from "recoil";

import badge from "../../atoms/notifications/badge";

const setNotificationBadgeVisible = selector({
  key: "setNotificationBadgeInvisibleSelector",
  set: ({ set }, value) => {
    set(badge, value);
  },
});

export default setNotificationBadgeVisible;
