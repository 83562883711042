const fakeCommentsData = [
  {
    id: 1,
    idPublication: "",
    from: "Denis",
    to: "bellerose",
    hour: "16h50",
    content: "Yeah,love this.",
  },
  {
    id: 2,
    idPublication: "",
    from: "Marie",
    to: "bellerose",
    hour: "13h20",
    content: "Beautiful picture!!!",
  },
  {
    id: 3,
    idPublication: "",
    from: "Bruno",
    to: "bellerose",
    hour: "11h42",
    content: "Great's....",
  },
  {
    id: 4,
    idPublication: "",
    from: "Jean",
    to: "bellerose",
    hour: "10h20",
    content: "Just wouah!!!",
  },
  {
    id: 5,
    idPublication: "",
    from: "pierre",
    to: "bellerose",
    hour: "9h35",
    content: "Woaw, very clever",
  },
  {
    id: 6,
    idPublication: "",
    from: "rodolphe",
    to: "bellerose",
    hour: "9h20",
    content: "Hello BelleRose, this picture is really beautiful",
  },
];

export default fakeCommentsData;
