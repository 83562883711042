/* eslint-disable no-unused-vars */
import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import { useHistory } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { isIOS } from "react-device-detect";

import "./bottomNavigationStudio.css";
import useHandleChangeIcons from "./hooks/useHandleChangeIcons";
import { studioAtom } from "../../../../stateManagement/atoms/studioAtom";
import setIsFromCameraPublicationImage from "../../../../stateManagement/selectors/publication/setIsFromCamera";

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

const BottomNavigationStudio = (props) => {
  const { setLoading } = props;

  const {
    value,
    setClicked,
    clickedVideo,
    setClickedVideo,
    clickedPicture,
    setClickedPicture,
    clickedGallery,
    setClickedGallery,
    handleChangeVideoIcon,
    handleChangePictureIcon,
  } = useHandleChangeIcons();
  let history = useHistory();

  const [, setOnStudio] = useRecoilState(studioAtom);
  const setIsFromCameraPublicationImageSelector = useSetRecoilState(
    setIsFromCameraPublicationImage
  );

  return (
    <div
      className={
        isIOS === true
          ? "bottomNavigationStudioModels__content bottomNavigationStudioModels__contentIphone"
          : "bottomNavigationStudioModels__content"
      }
    >
      <div value={value} className="bottomNavigation__root">
        {/*<DropzoneComponent
          clickedGallery={clickedGallery}
          setClickedGallery={setClickedGallery}
          clickedVideo={clickedVideo}
          setClickedVideo={setClickedVideo}
          clickedPicture={clickedPicture}
          setClickedPicture={setClickedPicture}
          setLoading={setLoading}
        />*/}
        {/*<CropperFromFile />*/}
        {/* <div className="button-take-picture">
          <img
            onClick={() => {
              setIsFromCameraPublicationImageSelector(true)
              handleChangePictureIcon();
              history.push("/picture-camera");
            }}
            className="icons"
            src={clickedPicture === true ? BlackPictureIcon : WhitePictureIcon}
            alt="pict"
          />
        </div>
        <div className="button-take-video">
          <img
            onClick={() => {
              handleChangeVideoIcon();
              history.push("/studio-video#top");
            }}
            className="icons"
            src={clickedVideo === true ? BlackCamera : WhiteCamera}
            alt="cam"
          />
        </div> */}
        <div
          onClick={() => {
            setClicked(false);
            setOnStudio(false);
            history.replace("/home-page-models");
          }}
          className="bottomNavigation__close"
          value="account"
        >
          <CancelIcon fontSize="large" color="secondary" />
        </div>
      </div>
    </div>
  );
};

export default BottomNavigationStudio;
