import { Dialog, DialogActions } from "@material-ui/core";
import React from "react";
import { useStyles } from "../../../../../BuyTickleModal/styled";

import DialogTitle from "../../../../../BuyTickleModal/DialogTitle";
import TicklesImg from "../../../../../../assets/images/tickles/Intersection 3@2x.png";

function RequestNoTicklesCustomMedias(props) {
  const classes = useStyles();
  const {
    openRequestModal,
    setOpenRequestModal,
    setOpenBuyTickleModal,
    setOpenSubscriptionModal,
  } = props;
  return (
    <Dialog
      className={classes.dialogRoot}
      open={openRequestModal}
      onClose={() => setOpenRequestModal(false)}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setOpenRequestModal(false)}
        iconClassName={classes.closeButton}
        className={classes.dialogTitleRoot}
        page={"requestNoTickles"}
      >
        <strong>
          To request a <span style={{ color: "#ff2775" }}>custom media</span>{" "}
          you must have at least:
        </strong>
        <ul>
          <li>50 tickles</li>
          <li>Or be subscribed to the Feetgirl private wall</li>
        </ul>
      </DialogTitle>
      <DialogActions style={{ justifyContent: "center" }}>
        <button
          type={"button"}
          onClick={() => {
            setOpenSubscriptionModal(true);
            setOpenRequestModal(false);
          }}
          style={{
            backgroundColor: "#f8cbd0",
            padding: "10px",
            border: "none",
            borderRadius: "15px",
            // borderTop: "0.02rem solid #9F9F9F",
            color: "#000",
            fontSize: "1.1rem",
            fontFamily: "Roboto",
          }}
        >
          Subscribe
        </button>
        <button
          type={"button"}
          onClick={() => {
            setOpenBuyTickleModal(true);
            setOpenRequestModal(false);
          }}
          style={{
            backgroundColor: "#f8cbd0",
            padding: "10px 30px 10px 10px",
            border: "none",
            borderRadius: "15px",
            // borderTop: "0.02rem solid #9F9F9F",
            color: "#000",
            fontSize: "1.1rem",
            fontFamily: "Roboto",
            textAlign: "center",
          }}
        >
          <span>Buy Tickles</span>
          &nbsp;
          <img
            src={TicklesImg}
            alt="tickles icon"
            width="20"
            height="20"
            style={{ position: "absolute" }}
          />
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default RequestNoTicklesCustomMedias;
