/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { useRecoilState } from "recoil";

import "./bankAccountGuide.css";
import { studioAtom } from "../../../../stateManagement/atoms/studioAtom";
import { useTranslation } from "react-i18next";

const BankAccountGuides = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [_, setOnStudio] = useRecoilState(studioAtom);

  useEffect(() => {
    setOnStudio(true);

    return () => {
      setOnStudio(false);
    };
  }, [setOnStudio]);

  return (
    <div className="feetloverGuides__container">
      <div className="feetloverGuides__header header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => {
            history.push("/setting-models");
          }}
        >
          <ChevronLeft />
        </IconButton>
        <h4>Bank Account</h4>
      </div>
      <div className="feetloverGuides__content minmax__wrapper content__withHeader feet-model-guides-container">
        <p>{t("RegisterBankAccount")}</p>

        <div className="i-have-agreed">
          <span>{t("thankYou")} !</span>
        </div>
      </div>
    </div>
  );
};

export default BankAccountGuides;
