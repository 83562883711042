import "./style.css";

import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";

import CheckSolidIcon from "../../../assets/images__v3/green-tick.png";
import ExSolidIcon from "../../../assets/images__v3/red-x.png";
import React from "react";
import { publication } from "../../../files/modals/publication-rules.config.json";
import { useStyles } from "../../BuyTickleModal/styled";
import { useTranslation } from "react-i18next";

const RulesModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    openModal,
    setOpenModal,
    isConfirm,
    setIsConfirm,
    setDontShowRules,
    pageType,
    ...rest
  } = props;

  const handleCheckChange = (value) => {
    setDontShowRules(value.target.checked);
  };

  const handlePublicationRules = () => {
    return publication?.map((item, key) => {
      return (
        <Grid container className="publication-rules" key={key} {...rest}>
          <Grid item xs={6} className={"rules-publication-grid-1"}>
            {t(item.label)}
          </Grid>
          <Grid
            item
            xs
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRight: "1px solid #c2bdbd",
              borderLeft: "1px solid #c2bdbd",
            }}
          >
            <img
              width="17.8"
              height="17.8"
              src={item.isPublic ? CheckSolidIcon : ExSolidIcon}
              alt="check-icon"
              className="check-icon"
            />
          </Grid>
          <Grid
            item
            xs
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              width="17.8"
              height="17.8"
              src={item.isPrivate ? CheckSolidIcon : ExSolidIcon}
              alt="check-icon"
              className="check-icon"
            />
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <div>
      <Dialog
        className={classes.dialogRoot}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.dialogTitleRoot}
          style={{ padding: "35px 0px" }}
        >
          {t("rulesPublicationTitle")}
        </DialogTitle>
        <DialogContent>
          <p className="rules-publication-sub-title">
            {t("rulesPublicationContentPart1")}
            <q>{t("rulesPublicationContentPart2")}</q>.
          </p>
          <p className="rules-publication-sub-title">
            {t("rulesPublicationContentPart3")}
          </p>
          <br />
          <Grid
            container
            spacing={3}
            className="rules-publication-button-title"
          >
            <Grid item xs={6} className={"rules-publication-grid-1"}></Grid>
            <Grid
              item
              xs
              className={"rules-grid-container rules-grid-container-title"}
            >
              {t("rulesPublicationPublicButtonLabel")}
            </Grid>
            <Grid
              item
              xs
              className={"rules-grid-container rules-grid-container-title"}
            >
              {t("rulesPublicationPrivateButtonLabel")}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginBottom: "2px",
              flexWrap: "nowrap",
            }}
          >
            <Grid item xs={6} className={"rules-publication-grid-1"}></Grid>
            <Grid
              item
              xs
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  fontSize: "x-small",
                  color: "#FF5768",
                  textAlign: "center",
                }}
              >
                Photos
              </p>
            </Grid>
            <Grid
              item
              xs
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  fontSize: "x-small",
                  color: "#FF5768",
                  textAlign: "center",
                }}
              >
                Photos & Videos
              </p>
            </Grid>
          </Grid>
          <Container className="rules-publication-content-container">
            {handlePublicationRules()}
          </Container>
        </DialogContent>
        <DialogActions
          className={classes.dialogActionsRoot}
          style={{
            padding: "18px 0",
            justifyContent: "center",
            display: "grid",
          }}
        >
          <Grid
            style={{
              marginTop: "5px",
            }}
          >
            {pageType !== "guides" ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <label style={{ fontSize: "x-small" }}>
                  <input
                    type="checkbox"
                    name="dontShow"
                    onClick={handleCheckChange}
                    style={{
                      width: "21px",
                      height: "21px",
                      verticalAlign: "middle",
                      marginTop: "5px",
                    }}
                  />
                  {t("rulesPublicationItemDontShow")}
                </label>
              </div>
            ) : null}
          </Grid>
          <Grid
            style={{
              margin: "auto",
              marginTop: "5px",
            }}
          >
            <Button
              onClick={async () => {
                setIsConfirm && setIsConfirm(isConfirm + 1);
                setOpenModal(false);
              }}
              className="rules-publication-modal-action"
            >
              {t("Ok")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RulesModal;
