import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { has as _has } from "lodash";
import { useTranslation } from "react-i18next";

import pictPhone from "../../../assets/images/view-feet-models-profile/picto/authentic_transparent.svg";
import starIcon from "../../../assets/images/view-feet-models-profile/picto/Calque_1.svg";
import MoviePhone from "../../../assets/images/view-feet-models-profile/picto/video_transparent.svg";

import FeetModelService from "../../../_services/feet-models.service";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import useInfiniteQuery from "../../../hooks/useInfiniteQuery";

import PublicationMedia from "../../PublicationMedia";

import useSessionStorage from "../../../components/forms/services/storageHooks/useSessionStorage";
import { Dialog, DialogTitle, Divider } from "@material-ui/core";
import { useStyles } from "../../../pages/SuppressAccount/styled";

const PrivateMediaComponent = (props) => {
  const { feetModelProfile, isSubscriptionSuspended, isBlogger } = props;
  const history = useHistory();
  let query = useQuery();
  const { t } = useTranslation();
  const classes = useStyles();

  const [authenticated] = useSessionStorage({}, "infosUser");
  const [openModal, setOpenModal] = useState(false);

  const {
    data,
    error,
    status,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    "feet-model-private-publications",
    async ({ next_offset = 0 }) => {
      const response = await FeetModelService.getFeetModelPrivatePublications(
        query.get("user"),
        next_offset
      );

      return {
        data: response.data.publications,
        next_offset: response.data.next_offset,
      };
    }
  );

  const loadMorePublicationsReference = React.useRef();

  useEffect(() => {
    if(isSubscriptionSuspended) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [feetModelProfile]);

  useIntersectionObserver({
    target: loadMorePublicationsReference,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  const isSubscriber = () => {
    return !!(
      feetModelProfile &&
      feetModelProfile.profile &&
      feetModelProfile.profile.subscribers &&
      feetModelProfile.profile.subscribers.length &&
      feetModelProfile.profile.subscribers.filter(
        (subscriber) => subscriber === authenticated.user._id
      ).length
    );
  };

  return (
    <div className="private mediaContainer">
      {/* authenticated user is model and currently viewing another feet model private wall */}
      {authenticated &&
      authenticated?.user &&
      authenticated.user.userType === "Model" ? (
        <span style={{ fontSize: "clamp(12px,3vw,16px)" }}>
          {t("You cannot subscribe to another feet model")}
        </span>
      ) : data && data.length && !isBlogger ? (
        data.map((publication) => (
          <div
            className="viewFeetModelsProfiles__photos-content"
            key={publication._id}
          >
            {publication.private && (
              <div className="viewFeetModelsProfiles__pictures">
                {/* User (visitor) is a subscriber */}
                {publication.private && isSubscriber() && !isSubscriptionSuspended ? (
                  <div
                    onClick={() => {
                      history.push({
                        pathname: `/publication/${publication._id}`,
                        state: {
                          mediaTab: 1,
                          feetModelsViewRoute: history.location.pathname,
                          search: `?user=${query.get("user")}`,
                        },
                      });
                    }}
                  >
                    <PublicationMedia
                      publication={publication}
                      className="noFree img-profile-pict"
                      alt="Models profiles"
                      effect="blur"
                      width="100%"
                      isSubscribe={isSubscriber()}
                    />

                    <div className="profileMediaIcons w100">
                      {publication.type.includes("image") &&
                      publication.isFromCamera ? (
                        <>
                          <img
                            className="viewFeetModelsProfiles__icon-absol image"
                            src={pictPhone}
                            alt=""
                          />
                        </>
                      ) : (
                        ""
                      )}

                      {publication.type.includes("video") ? (
                        <>
                          <img
                            className="viewFeetModelsProfiles__icon-absol video"
                            src={MoviePhone}
                            alt=""
                          />
                        </>
                      ) : (
                        ""
                      )}
                      {/*
                      {publication.type.includes("image") &&
                      (publication.isFromCamera === false ||
                        !_has(publication, "isFromCamera")) ? (
                        <>
                          <img
                            className="viewFeetModelsProfiles__icon-absol star"
                            src={starIcon}
                            alt=""
                          />
                        </>
                      ) : (
                        ""
                      )}*/}
                    </div>
                  </div>
                ) : (
                  <div
                  onClick={!isSubscriptionSuspended ? () => {
                    //todo: bug -> after subscribing to another feet model, this list is not updated.
                    history.push({
                      pathname: `/subscribe-unlock-publication`,
                      state: {
                        feetModelProfile,
                      },
                    });
                  } : null}>
                    {/* User (visitor) is NOT a subscriber */}
                    <PublicationMedia
                      publication={publication}
                      className="noFree non-subscriber"
                      alt="Models profiles"
                      effect="blur"
                      width="100%"
                      isSubscribe={false}
                    />

                    <div className="profileMediaIcons private-icons-non-subscriber">
                      {publication.type.includes("image") &&
                      publication.isFromCamera ? (
                        <>
                          <img
                            className="viewFeetModelsProfiles__icon-absol image"
                            src={pictPhone}
                            alt=""
                          />
                        </>
                      ) : (
                        ""
                      )}

                      {publication.type.includes("video") ? (
                        <>
                          <img
                            className="viewFeetModelsProfiles__icon-absol video"
                            src={MoviePhone}
                            alt=""
                          />
                        </>
                      ) : (
                        ""
                      )}
                      {/*
                      {publication.type.includes("image") &&
                      (publication.isFromCamera === false ||
                        !_has(publication, "isFromCamera")) ? (
                        <>
                          <img
                            className="viewFeetModelsProfiles__icon-absol star"
                            src={starIcon}
                            alt=""
                          />
                        </>
                      ) : (
                        ""
                      )}*/}
                    </div>

                    <img className="BlurnoFree" src={starIcon} alt="" />

                  </div>
                )}
              </div>
            )}
          </div>
        ))
      ) : isBlogger && (
          <span style={{ fontSize: "clamp(12px,3vw,16px)" }}>
          {t("You cannot subscribe to this blogger feet model")}
        </span>
      )}

      {data && data.length ? (
        <>
        <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
        <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
        <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
        <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
        <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
        <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
        <div className="viewFeetModelsProfiles__photos-content space-filler"></div>
        </>
      ) : null}

      {status === "loading" && !(data && data.length) ? (
        ""
      ) : data &&
        data.length === 0 &&
        authenticated &&
        authenticated?.user &&
        authenticated.user.userType !== "Model" ? (
        <span style={{ fontSize: "clamp(12px,3vw,16px)" }}>
          {t("No private media yet")}
        </span>
      ) : (
        ""
      )}

      <div className="pagination-loader" ref={loadMorePublicationsReference}>
        {isFetchingNextPage ? (
          <CircularProgress color="secondary" size={10} />
        ) : (
          ""
        )}
      </div>
      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        className={classes.suppressDialog}
      >
        <DialogTitle>
          <p style={{ marginTop: "10px" }}>{t("suspendedSubscription")}</p>
        </DialogTitle>
        <Divider />
        <div className={classes.suppressModalButtonsContainer}>
          <button
            type={"button"}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            {t("Okay")}
          </button>
        </div>
      </Dialog>
    </div>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default PrivateMediaComponent;
