import React, { useCallback, useState } from "react";

export const NotificationAlertContext = React.createContext({
  message: null,
  severity: "",
  addNotificationMessage: () => {},
  removeNotificationMessage: () => {},
});

export default function NotificationAlertProvider({ children }) {
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState("");

  const removeNotificationMessage = () => {
    setMessage(null);
    setSeverity("");
  };

  const addNotificationMessage = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
  };

  const contextValue = {
    message,
    severity,
    addNotificationMessage: useCallback(
      (message, severity) => addNotificationMessage(message, severity),
      []
    ),
    removeNotificationMessage: useCallback(
      () => removeNotificationMessage(),
      []
    ),
  };

  return (
    <NotificationAlertContext.Provider value={contextValue}>
      {children}
    </NotificationAlertContext.Provider>
  );
}
