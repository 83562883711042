import axios from "axios";
import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";
import { imagetoblob } from "../helpers";

const updateUser = async (id, data, accessToken = undefined) => {
  const headers = {
    Authorization: `Bearer ${
      accessToken || getAuthSession().user.access_token
    }`,
  };
  const response = await axiosInstance.put(`/v1/users/${id}`, data, {
    headers,
  });

  return response.data;
};

const uploadProfileImage = async (image, accessToken = undefined) => {
  const headers = {
    Authorization: `Bearer ${
      accessToken || getAuthSession().user.access_token
    }`,
  };

  const data = new FormData();

  data.append("profile-image", imagetoblob(image));

  const response = await axiosInstance.post("/v1/users/profile-image", data, {
    headers,
  });

  return response;
};

const uploadProfileImageBlobFile = async (file) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const data = {
      url: file
    };
    // const data = new FormData();
    //
    // data.append("profile-image", file);

    const response = await axiosInstance.post("/v2/users/profile-image", data, {
      headers,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

const deleteProfileImage = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.delete("/v1/users/profile-image", {
    headers,
  });

  return response;
};

const getUserMessages = async (offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const userId = getAuthSession().user._id;

  const response = await axiosInstance.get(
    `/v1/users/${userId}/messages?offset=${offset}`,
    {
      headers,
    }
  );

  return response;
};

const getFavoriteMessages = async (offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const userId = getAuthSession().user._id;

  const response = await axiosInstance.get(
    `/v1/users/${userId}/messages/favorites?offset=${offset}`,
    {
      headers,
    }
  );

  return response;
};

let tokenSource;
const searchUsers = async (query, offset = 0) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel("Operation canceled due to new request.");
    }

    // save the new request for cancellation
    tokenSource = axios.CancelToken.source();

    const response = await axiosInstance.get(
      `/v1/users/search?q=${query}&offset=${offset}`,
      {
        cancelToken: tokenSource.token,
        headers,
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) return { cancelPrevQuery: true };
    return [error];
  }
};

let tokenSourceForSearchFollow;
const searchFollowersOrFollowing = async (
  query,
  id = "",
  type = "",
  offset = 0
) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    if (typeof tokenSourceForSearchFollow !== typeof undefined) {
      tokenSourceForSearchFollow.cancel(
        "Operation canceled due to new request."
      );
    }

    // save the new request for cancellation
    tokenSourceForSearchFollow = axios.CancelToken.source();

    const response = await axiosInstance.get(
      `/v1/users/${id}/follow/search?q=${query}&offset=${offset}&type=${type}`,
      {
        cancelToken: tokenSourceForSearchFollow.token,
        headers,
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) return { cancelPrevQuery: true };
    return [error];
  }
};

const getUser = async (id, token) => {
  const headers = {
    Authorization: `Bearer ${token || getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/users/${id}`, {
    headers,
  });

  return response.data;
};

const changePassword = async (id, data, accessToken = undefined) => {
  const headers = {
    Authorization: `Bearer ${
      accessToken || getAuthSession().user.access_token
    }`,
  };
  const response = await axiosInstance.post(
    `/v1/users/${id}/changePassword`,
    data,
    {
      headers,
    }
  );

  return response.data;
};
const updateEmail = async (id, data, accessToken = undefined) => {
  const headers = {
    Authorization: `Bearer ${
      accessToken || getAuthSession().user.access_token
    }`,
  };
  const response = await axiosInstance.put(
    `/v1/users/${id}/updateEmail`,
    data,
    {
      headers,
    }
  );

  return response.data;
};
const cancelEmail = async (id) => {
  // const headers = {
  //   Authorization: `Bearer ${
  //     accessToken || getAuthSession().user.access_token
  //   }`,
  // };
  const response = await axiosInstance.put(
    `auth/verify-email/token/${id}`,
    {}
    // {
    //   headers,
    // }
  );

  return response.data;
};
const successEmail = async (id) => {
  // const headers = {
  //   Authorization: `Bearer ${
  //     accessToken || getAuthSession().user.access_token
  //   }`,
  // };
  const response = await axiosInstance.put(
    `auth/verify-email/token/${id}`,
    {}
    // {
    //   headers,
    // }
  );

  return response.data;
};
const sendNewEmailConfirmation = async (id) => {
  // const headers = {
  //   Authorization: `Bearer ${
  //     accessToken || getAuthSession().user.access_token
  //   }`,
  // };
  const response = await axiosInstance.put(
    `auth/transfer-to-new-email/token/${id}`,
    {}
    // {
    //   headers,
    // }
  );

  return response.data;
};
const getFollowers = async (offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const userId = getAuthSession().user._id;

  const response = await axiosInstance.get(
    `/v1/users/${userId}/followers?offset=${offset}`,
    {
      headers,
    }
  );

  return response;
};

const getFollowing = async (offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const userId = getAuthSession().user._id;

  const response = await axiosInstance.get(
    `/v1/users/${userId}/following?offset=${offset}`,
    {
      headers,
    }
  );

  return response;
};

const getBlockedUsers = async (offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
    `/v1/users/blocks?offset=${offset}`,
    {
      headers,
    }
  );

  return response;
};

const proposeCustomMediaPrice = async (id, data) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const response = await axiosInstance.post(
      `/v1/users/${id}/propose-custom-media`,
      data,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const acceptOrRejectProposedCustomMedia = async (id, data) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const response = await axiosInstance.post(
      `/v1/users/${id}/accept-or-reject-custom-media`,
      data,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getCustomMedias = async (offset = 0, searchName = "") => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const userId = getAuthSession().user._id;

  const response = await axiosInstance.get(
    `/v1/users/${userId}/messages/custom-medias?q=${searchName}&offset=${offset}`,
    {
      headers,
    }
  );

  return response;
};

const searchCustomMedias = async (query, offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const userId = getAuthSession().user._id;

  const response = await axiosInstance.get(
    `/v1/users/${userId}/messages/custom-medias?q=${query}&offset=${offset}&limit=9999`,
    {
      headers,
    }
  );

  return response.data;
};

const getCustomMediasById = async (id = "") => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/messages/${id}`, {
    headers,
  });

  return response;
};

const getPendingProfileImage = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
    `/v1/users/get-pending-profile-image`,
    {
      headers,
    }
  );

  return response.data;
};

const processRefund = async (id, data) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.post(
    `/v1/users/${id}/custom-media-refund`,
    data,
    {
      headers,
    }
  );
  return response;
};

const updateLanguage = async (id, lang) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };
  const data = {
    preferredLanguage: lang,
  };
  
  const response = await axiosInstance.post(`/v1/users/${id}/language`, data, {
    headers,
  });
  return response;
};

const UserService = {
  updateUser,
  uploadProfileImage,
  getUserMessages,
  getFavoriteMessages,
  searchUsers,
  deleteProfileImage,
  getUser,
  getFollowers,
  getFollowing,
  searchFollowersOrFollowing,
  getBlockedUsers,
  uploadProfileImageBlobFile,
  changePassword,
  updateEmail,
  cancelEmail,
  successEmail,
  proposeCustomMediaPrice,
  acceptOrRejectProposedCustomMedia,
  sendNewEmailConfirmation,
  getCustomMedias,
  searchCustomMedias,
  getCustomMediasById,
  getPendingProfileImage,
  processRefund,
  updateLanguage,
};

export default UserService;
