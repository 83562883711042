import { useState } from "react";
import { usePhotoGallery } from "../../../photoStudioModels/hooks/usePhotoGallery";

const useHandleChangeIcons = () => {
  const [value, setValue] = useState("home");
  const [clicked, setClicked] = useState(false);
  const { photos, takePhoto } = usePhotoGallery();
  const [clickedVideo, setClickedVideo] = useState(false);
  const [clickedPicture, setClickedPicture] = useState(false);
  const [clickedGallery, setClickedGallery] = useState(false);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const handleChangeVideoIcon = () => {
    if (clickedVideo === false) {
      setClickedVideo(true);
    }
    if (clickedVideo === true) {
      setClickedVideo(false);
    }
    if (clickedGallery === true) {
      setClickedGallery(false);
    }
    if (clickedPicture === true) {
      setClickedPicture(false);
    }
  };

  const handleChangePictureIcon = () => {
    if (clickedPicture === false) {
      setClickedPicture(true);
    }
    if (clickedVideo === true) {
      setClickedVideo(false);
    }
    if (clickedGallery === true) {
      setClickedGallery(false);
    }
    if (clickedPicture === true) {
      setClickedPicture(false);
    }
  };
  return {
    value,
    setValue,
    clicked,
    setClicked,
    photos,
    takePhoto,
    clickedVideo,
    setClickedVideo,
    clickedPicture,
    setClickedPicture,
    clickedGallery,
    setClickedGallery,
    handleChangeVideoIcon,
    handleChangePictureIcon,
  };
};

export default useHandleChangeIcons;
