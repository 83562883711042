import "../bottomNavigationLovers/bottomNavigation.css";

import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core/";
import React, { useState } from "react";

import DropzoneComponent from "../../../modelsComponents/pictureFromFilesystem/DropzoneComponent";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import RulesModal from "../../../modelsComponents/postingRules/rulesModal";
import { clearCache } from "react-router-cache-route";
import newMessageBadgeAtom from "../../../../stateManagement/atoms/newMessageBadgeAtom";
import notificationBadgeAtom from "../../../../stateManagement/atoms/notifications/badge";
import notificationFollowerBadgeAtom from "../../../../stateManagement/atoms/notifications/followerBadge";
import useHandleClickChangeIcons from "../hooks/useHandleClickChangeIcons";
import { useHistory } from "react-router-dom";
import useLocalStorage from "../../../forms/services/storageHooks/useLocalStorage";
import { useRecoilState } from "recoil";
import { withStyles } from "@material-ui/core/styles";

const StyledBadge = withStyles((theme) => ({
  badge: {
    padding: "0 4px",
    borderRadius: "50%",
    width: "8px",
    lineHeight: "8px",
    height: "8px",
    bottom: "-5px !important",
  },
}))(Badge);

const BottomNavigationModelComponent = () => {
  const {
    value,
    clickedHome,
    clickedSearch,
    clickedNotifications,
    clickedAccount,
    clickedStudio,
    handleChange,
    handleClickHome,
    handleClickSearch,
    handleClickStudio,
    handleClickNotifications,
    handleClickAccount,
  } = useHandleClickChangeIcons();
  let history = useHistory();

  const [isInvisibleFollowerBadge] = useRecoilState(
    notificationFollowerBadgeAtom
  );
  const [newMessageBadge] = useRecoilState(newMessageBadgeAtom);

  const [isInvisible] = useRecoilState(notificationBadgeAtom);

  const [openModal, setOpenModal] = useState(false);
  const [isConfirm, setIsConfirm] = useState(0);
  const [dontShowRules, setDontShowRules] = useLocalStorage(
    {},
    "dontShowRules"
  );

  return (
    <div className="bottomNavigation__content">
      <div className="bottomNavigation__innerContent">
        <BottomNavigation
          value={value}
          onChange={handleChange}
          className="bottomNavigation__root"
        >
          <BottomNavigationAction
            className={`${
              clickedHome === false
                ? "bottomNavigation__actions bottomNavigation__actions-first"
                : "bottomNavigation__actions bottomNavigation__actions-first-selected"
            }
           bottomNavigation`}
            onClick={() => {
              clearCache();
              setOpenModal(false);
              handleClickHome();
              history.push("/home-page-models");
            }}
            value="home"
            icon={newMessageBadge && <FiberManualRecordIcon />}
            disableRipple
          />
          <BottomNavigationAction
            className={`${
              clickedSearch === false
                ? "bottomNavigation__actions bottomNavigation__actions-second"
                : "bottomNavigation__actions bottomNavigation__actions-second-selected"
            }
           bottomNavigation`}
            onClick={() => {
              handleClickSearch();
              setOpenModal(false);
              history.push("/search-lover");
            }}
            value="search"
            disableRipple
          />
          <DropzoneComponent
            isConfirm={isConfirm}
            setOpenModal={setOpenModal}
            clickedStudio={clickedStudio}
            handleClickStudio={handleClickStudio}
            dontShowRules={dontShowRules}
          />
          <BottomNavigationAction
            className={`${
              clickedNotifications === false
                ? "bottomNavigation__notification"
                : "bottomNavigation__notification-selected"
            }
           bottomNavigation__actions`}
            onClick={() => {
              handleClickNotifications();
              setOpenModal(false);
              history.push("/notifications-page-models");
            }}
            value={"message"}
            icon={!isInvisible && <FiberManualRecordIcon />}
            disableRipple
          />
          <BottomNavigationAction
            className={`${
              clickedAccount === false
                ? "bottomNavigation__actions-last"
                : "bottomNavigation__actions-last-selected"
            }  bottomNavigation__actions`}
            onClick={() => {
              handleClickAccount();
              setOpenModal(false);
              history.push("/view-own-profile-models");
            }}
            value="account"
            icon={!isInvisibleFollowerBadge && <FiberManualRecordIcon />}
            disableRipple
          />
        </BottomNavigation>
      </div>
      <RulesModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        isConfirm={isConfirm}
        setIsConfirm={setIsConfirm}
        setDontShowRules={setDontShowRules}
      />
    </div>
  );
};

export default BottomNavigationModelComponent;
