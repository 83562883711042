/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { pullAt as _pullAt } from "lodash";

import {
  Aiguille,
  Arche,
  artiste,
  Bas,
  Basket,
  Bijoux,
  Chatouilles,
  chaussette,
  cooconing,
  Copines,
  Genereux,
  Jambe,
  Jouer,
  Mirror,
  mode,
  OnglesLongs,
  Orteils,
  OrteilsCourts,
  OrteilsEcartes,
  OrteilsLongs,
  Osseux,
  Pedales,
  PictEgyptian,
  PictGreek,
  PictRoman,
  PiedMain,
  PlantePied,
  Plisses,
  Public,
  Selfeet,
  sport,
  Talons,
  Tatouage,
  Vernis,
  voyage,
  Vue,
  water,
} from "../../../pictyFilters/dataPictyFilter";
import selectedPictiesAtom from "../../../../stateManagement/atoms/selectedPictiesAtom";
import useLocalStorage from "../../../forms/services/storageHooks/useLocalStorage";
import postPublicationSelectedPictiesAtom from "../../../../stateManagement/atoms/postPublicationPictiesAtom";

const useSelectAndRemove = () => {
  const [selectedPictiesList, setSelectedPictiesList] = useRecoilState(
    selectedPictiesAtom
  );

  const [
    postPublicationSelectedPicties,
    setPostPublicationSelectedPicties,
  ] = useRecoilState(postPublicationSelectedPictiesAtom);

  const [selected1, setSelected1] = useState(undefined);
  const [selected2, setSelected2] = useState(undefined);
  const [selected3, setSelected3] = useState(undefined);
  const [selected4, setSelected4] = useState(undefined);
  const [selected5, setSelected5] = useState(undefined);
  const [croppedImg] = useLocalStorage({}, "imgBase64Cropped");
  const [ImageStyle] = useLocalStorage({}, "styleImg");
  const [removeTransition, setRemoveTransition] = useState([]);
  const [selectionPict, setSelectionPict] = useState([]);
  const [remove1, setRemove1] = useState(false);
  const [remove2, setRemove2] = useState(false);
  const [remove3, setRemove3] = useState(false);
  const [remove4, setRemove4] = useState(false);
  const [remove5, setRemove5] = useState(false);
  const [limitCompleted, setLimitCompleted] = useState(false);

  const [file, setFile] = useState(window.localStorage.getItem("resultPost"));

  useEffect(() => {
    const dataPictyIcon = [
      {
        name: "Egyptian",
        icon: PictEgyptian,
        code: "egyptian",
      },
      {
        name: "Greek",
        icon: PictGreek,
        code: "greek",
      },
      {
        name: "Roman",
        icon: PictRoman,
        code: "roman",
      },
      {
        name: "voyage",
        icon: voyage,
        code: "envoyage",
      },
      {
        name: "mode",
        icon: mode,
        code: "mode",
      },
      {
        name: "sport",
        icon: sport,
        code: "sport",
      },
      {
        name: "water",
        icon: water,
        code: "eau",
      },
      {
        name: "cooconing",
        icon: cooconing,
        code: "cooconing",
      },
      {
        name: "artiste",
        icon: artiste,
        code: "solartisque",
      },
      {
        name: "Aiguille",
        icon: Aiguille,
        code: "talonsaiguille",
      },
      {
        name: "Bijoux",
        icon: Bijoux,
        code: "bijoux",
      },
      {
        name: "chaussette",
        icon: chaussette,
        code: "chaussettes",
      },
      {
        name: "Bas",
        icon: Bas,
        code: "collant&bas",
      },
      {
        name: "Basket",
        icon: Basket,
        code: "basket",
      },
      {
        name: "Tatouage",
        icon: Tatouage,
        code: "tatouage",
      },
      {
        name: "Vernis",
        icon: Vernis,
        code: "vernis",
      },
      {
        name: "Mirror",
        icon: Mirror,
        code: "miroir",
      },
      {
        name: "Pedales",
        icon: Pedales,
        code: "pedales",
      },
      {
        name: "Jouer",
        icon: Jouer,
        code: "jouer",
      },
      {
        name: "Vue",
        icon: Vue,
        code: "vue",
      },
      {
        name: "Jambe",
        icon: Jambe,
        code: "jambe",
      },
      {
        name: "PiedMain",
        icon: PiedMain,
        code: "piedmain",
      },
      {
        name: "Chatouilles",
        icon: Chatouilles,
        code: "chatouilles",
      },
      {
        name: "Copines",
        icon: Copines,
        code: "copines",
      },
      {
        name: "Public",
        icon: Public,
        code: "public",
      },
      {
        name: "Arche",
        icon: Arche,
        code: "arche",
      },
      {
        name: "Orteils",
        icon: Orteils,
        code: "orteils",
      },
      {
        name: "Talons",
        icon: Talons,
        code: "talons",
      },
      {
        name: "Selfeet",
        icon: Selfeet,
        code: "selfeet",
      },
      {
        name: "Plisses",
        icon: Plisses,
        code: "plisses",
      },
      {
        name: "PlantePied",
        icon: PlantePied,
        code: "plantepied",
      },
      {
        name: "OrteilsEcartes",
        icon: OrteilsEcartes,
        code: "orteilsecartes",
      },
      {
        name: "OnglesLongs",
        icon: OnglesLongs,
        code: "ongleslongs",
      },
      {
        name: "Osseux",
        icon: Osseux,
        code: "osseux",
      },
      {
        name: "Genereux",
        icon: Genereux,
        code: "genereux",
      },
      {
        name: "OrteilsCourts",
        icon: OrteilsCourts,
        code: "orteilscourts",
      },
      {
        name: "OrteilsLongs",
        icon: OrteilsLongs,
        code: "orteilslongs",
      },
    ];

    if (selectedPictiesList.length === 5) {
      setLimitCompleted(true);
    }
    if (selectedPictiesList.length < 5) {
      setLimitCompleted(false);
    }

    if (selectedPictiesList[0] !== undefined) {
      const evens1 = dataPictyIcon.filter(
        (item) => item.name === selectedPictiesList[0]
      );
      setSelected1(evens1[0].icon);
    }
    if (selectedPictiesList[1] !== undefined) {
      const evens2 = dataPictyIcon.filter(
        (item) => item.name === selectedPictiesList[1]
      );
      setSelected2(evens2[0].icon);
    }
    if (selectedPictiesList[2] !== undefined) {
      const evens3 = dataPictyIcon.filter(
        (item) => item.name === selectedPictiesList[2]
      );
      setSelected3(evens3[0].icon);
    }
    if (selectedPictiesList[3] !== undefined) {
      const evens4 = dataPictyIcon.filter(
        (item) => item.name === selectedPictiesList[3]
      );
      setSelected4(evens4[0].icon);
    }
    if (selectedPictiesList[4] !== undefined) {
      const evens5 = dataPictyIcon.filter(
        (item) => item.name === selectedPictiesList[4]
      );
      setSelected5(evens5[0].icon);
    }
  }, [selectedPictiesList, setSelectedPictiesList]);

  useEffect(() => {
    setSelectedPictiesList(removeTransition);
  }, [removeTransition, setSelectedPictiesList]);

  const handleRemove1 = () => {
    setRemove1(true);
    if (selectedPictiesList[0] !== undefined) {
      const evens1 = selectedPictiesList.filter((item) => item[0]);
      const removeValue = evens1.splice(0, 1);
      setSelectionPict(removeValue);
      setRemoveTransition(evens1);
      setSelectedPictiesList(removeTransition);
    }

    const copyOfPublicationSelectedPicties = [
      ...postPublicationSelectedPicties,
    ];
    _pullAt(copyOfPublicationSelectedPicties, [0]);
    setPostPublicationSelectedPicties(copyOfPublicationSelectedPicties);
  };
  const handleRemove2 = () => {
    setRemove2(true);
    if (selectedPictiesList[1] !== undefined) {
      const evens2 = selectedPictiesList.filter((item) => item[0]);
      const removeValue = evens2.splice(1, 1);
      setSelectionPict(removeValue);
      setRemoveTransition(evens2);
      setSelectedPictiesList(removeTransition);
    }

    const copyOfPublicationSelectedPicties = [
      ...postPublicationSelectedPicties,
    ];
    _pullAt(copyOfPublicationSelectedPicties, [1]);
    setPostPublicationSelectedPicties(copyOfPublicationSelectedPicties);
  };
  const handleRemove3 = () => {
    setRemove3(true);
    if (selectedPictiesList[2] !== undefined) {
      const evens3 = selectedPictiesList.filter((item) => item[0]);
      const removeValue = evens3.splice(2, 1);
      setSelectionPict(removeValue);
      setRemoveTransition(evens3);
      setSelectedPictiesList(removeTransition);
    }

    const copyOfPublicationSelectedPicties = [
      ...postPublicationSelectedPicties,
    ];
    _pullAt(copyOfPublicationSelectedPicties, [2]);
    setPostPublicationSelectedPicties(copyOfPublicationSelectedPicties);
  };
  const handleRemove4 = () => {
    setRemove4(true);
    if (selectedPictiesList[3] !== undefined) {
      const evens4 = selectedPictiesList.filter((item) => item[0]);
      const removeValue = evens4.splice(3, 1);
      setSelectionPict(removeValue);
      setRemoveTransition(evens4);
      setSelectedPictiesList(removeTransition);
    }

    const copyOfPublicationSelectedPicties = [
      ...postPublicationSelectedPicties,
    ];
    _pullAt(copyOfPublicationSelectedPicties, [3]);
    setPostPublicationSelectedPicties(copyOfPublicationSelectedPicties);
  };
  const handleRemove5 = () => {
    setRemove5(true);
    if (selectedPictiesList[4] !== undefined) {
      const evens5 = selectedPictiesList.filter((item) => item[0]);
      const removeValue = evens5.splice(4, 1);
      setSelectionPict(removeValue);
      setRemoveTransition(evens5);
      setSelectedPictiesList(removeTransition);
    }

    const copyOfPublicationSelectedPicties = [
      ...postPublicationSelectedPicties,
    ];
    _pullAt(copyOfPublicationSelectedPicties, [4]);
    setPostPublicationSelectedPicties(copyOfPublicationSelectedPicties);
  };

  return {
    selectedPictiesList,
    setSelectedPictiesList,
    selected1,
    setSelected1,
    selected2,
    setSelected2,
    selected3,
    setSelected3,
    selected4,
    setSelected4,
    selected5,
    setSelected5,
    croppedImg,
    ImageStyle,
    handleRemove1,
    handleRemove2,
    handleRemove3,
    handleRemove4,
    handleRemove5,
    limitCompleted,
    setLimitCompleted,
    file,
    setFile,
  };
};

export default useSelectAndRemove;
