/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import moment from "moment";
import _ from "lodash";
import { Avatar, Dialog, DialogTitle, Divider } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Skeleton from "@material-ui/lab/Skeleton";
import CircularProgress from "@material-ui/core/CircularProgress";
import crypto from "crypto-js";
import { useTranslation } from "react-i18next";
import Bugsnag from "@bugsnag/js";

import "./messageList.css";
import FavIcon from "../../../../assets/images/messages-section/Group 54778@2x.png";
import SelectedFavIcon from "../../../../assets/images/messages-section/favorite.svg";
import SwipeableList from "../SwipeableList/SwipeableList";
import SwipeableListItem from "../SwipeableList/SwipeableListItem";
import Trash from "../../../../assets/images/messages-section/Group 54807@2x.png";
import TicklesIcon from "../../../../assets/images/tickles/Intersection 3.png";
import defaultAvatar from "../../../../assets/images/generic-user-avatar.png";
import { truncate } from "../../../../helpers";
import { getAuthSession } from "../../../../helpers/auth.helpers";
import { SocketContext } from "../../../../context/socket";
import UserService from "../../../../_services/user.service";
import MessageService from "../../../../_services/message.service";
import useSessionStorage from "../../../../components/forms/services/storageHooks/useSessionStorage";
import useIntersectionObserver from "../../../../hooks/useIntersectionObserver";
import useInfiniteQuery from "../../../../hooks/useInfiniteQuery";
import { useHistory } from "react-router-dom";

import heart from "../../../../assets/images/notifications-models/likes.png";
import MessageIcon from "../../../../assets/images/messages-section/CustomMedia Icon.png";

const AllMessages = ({ userType }) => {
  let history = useHistory();
  const { t } = useTranslation();
  const background = <img src={Trash} alt="" />;

  const socket = useContext(SocketContext);
  const [user] = useSessionStorage({}, "infosUser");

  const [allMessages, setAllMessages] = useState([]);

  const [
    isOpenCantDeleteMessageWithTickle,
    setIsOpenCantDeleteMessageWithTickle,
  ] = useState(false); 
  
  const [
    isOpenCantDeleteMessageWithCustomMedia,
    setIsOpenCantDeleteMessageWithCustomMedia,
  ] = useState(false);

  const [
    isOpenCantDeleteMessageWithCustomMediaRequest,
    setIsOpenCantDeleteMessageWithCustomMediaRequest,
  ] = useState(false);

  const [
    isOpenDeleteWithFavorite,
    setIsOpenDeleteWithFavorite,
  ] = useState(false); 

  const {
    data,
    error,
    status,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery("user-messages", async ({ next_offset = 0 }) => {
    const response = await UserService.getUserMessages(next_offset);

    return {
      data: response.data.messages,
      next_offset: response.data.next_offset,
    };
  });

  const loadMoreMessagesReference = useRef();
  
  const customMediaEntry = ['Custom-Media-Image', 'Custom-Media', 'Custom-Media-Request'];

  useIntersectionObserver({
    target: loadMoreMessagesReference,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  const newMessageHandler = useCallback(
    ({ favorite, ...newMessage }) => {
      const merged = _.merge(
        _.keyBy(JSON.parse(JSON.stringify(allMessages)), "_id"),
        _.keyBy(
          [
            {
              ...newMessage,
              readBy: null,
              hasUnreadTickles: newMessage.hasUnreadTickles,
              hasUnreadCustomMediaRequest: newMessage.hasUnreadCustomMediaRequest,
              ...(newMessage.type === "Tickles" && {
                ticklesData: { tickles: newMessage.ticklesAmount, status: "" },
                hasUnreadTickles: true,
              }),
              ...(newMessage.type === "Custom-Media-Request" && {
                hasUnreadCustomMediaRequest: true,
              }),
            },
          ],
          "_id"
        ) //readBy:null; reset readBy
      );
      const mergedMessages = _.values(merged);

      const latestMessages = mergedMessages.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setAllMessages(() => _.uniqBy(latestMessages, "_id"));
    },
    [allMessages]
  );

  useEffect(() => {
    // observer > new message
    const message = socket.onMessage();

    const onMessage = message.subscribe(newMessageHandler);

    // clean up observer
    return () => onMessage.unsubscribe();
  }, [socket, newMessageHandler]);

  /**
   * Watch data from infinite query.
   * merge data from infinite query and all current messages,
   * and make sure it's unique.
   */
  useEffect(() => {
    setAllMessages(() =>
      _.uniqBy(
        [...allMessages, ...data].sort(
          (a, b) => new Date(b.created) - new Date(a.createdAt)
        ),
        "_id"
      )
    );
  }, [data]);

  useEffect(() => {
    return () => {
      // clean up and prevent cant update error on unmount
      setAllMessages([]);
    };
  }, []);

  const handleDeleteMessageConversation = async (convoId = null) => {
    try {
      const sourceMessage = _.find(
        allMessages,
        (message) => message._id === convoId
      );
      const favorited = sourceMessage?.favoritedBy?.filter(
        (data) => data.favoritedBy === user.user._id
      ).length
      if (favorited < 1) {
        await MessageService.deleteMessageConversation({ convoId });
      } else {
        throw "CANT_DELETE_FAVORITE_MESSAGE"
      }
    } catch (error) {
      if (error === "CANT_DELETE_FAVORITE_MESSAGE") {
        Bugsnag.notify(error);
        setIsOpenDeleteWithFavorite(true);
      }

      if (error.response.data.error === "CANT_DELETE_MESSAGE_WITH_TICKLES") {
        Bugsnag.notify(error.response.data.error);
        setIsOpenCantDeleteMessageWithTickle(true);
      } else if (error.response.data.error === "CANT_DELETE_MESSAGE_WITH_UNPROCESSED_CUSTOM_MEDIA") {
        Bugsnag.notify(error.response.data.error);
        setIsOpenCantDeleteMessageWithCustomMedia(true);
      } else if (error.response.data.error === "CANT_DELETE_MESSAGE_WITH_UNREAD_CUSTOM_MEDIA_REQUEST") {
        Bugsnag.notify(error.response.data.error);
        setIsOpenCantDeleteMessageWithCustomMediaRequest(true);
      }

      throw error;
    }
  };

  const handleFavoriteMessage = async (convoId) => {
    try {
      const currentAllMessages = [...allMessages];

      const response = await MessageService.setOrRemoveAsFavoriteMessage({
        convoId,
      });

      const sourceMessage = _.find(
        currentAllMessages,
        (message) => message._id === convoId
      );

      const sourceMessageIndex = currentAllMessages.findIndex(
        (message) => message._id === convoId
      );

      // set message as favorite
      if (response.status === 201) {
        currentAllMessages[sourceMessageIndex] = _.mergeWith(
          {},
          sourceMessage,
          {
            favoritedBy: [
              ...sourceMessage.favoritedBy,
              { favoritedBy: user.user._id },
            ],
          }
        );

        setAllMessages([...currentAllMessages]);
      }

      // remove message as favorite
      if (response.status === 204) {
        currentAllMessages[sourceMessageIndex] = _.mergeWith(
          {},
          sourceMessage,
          {
            favoritedBy: sourceMessage.favoritedBy.filter(
              (data) => data.favoritedBy !== user.user._id
            ),
          },
          (objValue, srcValue) => {
            if (_.isArray(objValue)) return srcValue;
          }
        );

        setAllMessages([...currentAllMessages]);
      }
    } catch (error) {
      console.error(
        "ERROR Save or remove Favorite message /api/v1/messages",
        error
      );
    }
  };
  const isUserFeetModel = () =>
    userType === "Model" || getAuthSession().user.userType === "Model";
  return (
    <div className="message--container all">
      {status === "loading" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "400px",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : status === "error" ? (
        <span>Error: {error.message}</span>
      ) : (
        <>
          {allMessages.map((message) => (
            <SwipeableList key={message._id} background={background}>
              <SwipeableListItem
                key={message._id}
                user={message._id}
                handleDeleteMessageConversation={
                  handleDeleteMessageConversation
                }
              >
                <div
                  key={message._id}
                  className={
                    message &&
                    message.readBy &&
                    message.readBy.length &&
                    message.readBy.filter(
                      (data) => data.readBy === user.user._id
                    ).length
                      ? "messages__card"
                      : "messages__CardNoReaded"
                  }
                  onClick={() => {
                    // tag or set message as read
                    MessageService.setAsReadMessage({
                      messageId: message.messageId,
                    })
                      .then((response) =>
                        console.log("success set as read message", response)
                      )
                      .catch((error) => console.error(error));

                    const privateConversationData = {
                      convoId: message._id,
                      createMessage: user,
                      receiver:
                        message.from._id === user.user.id
                          ? message.to
                          : message.from,
                      socketSendOnOpenConversation: true,
                    };

                    // encrypt private conversation data and save to local storage to re-use later in private conversation.
                    const encryptPrivateConversationData = crypto.AES.encrypt(
                      JSON.stringify(privateConversationData),
                      process.env
                        .REACT_APP_API_PRIVATE_CONVERSATION_ENCRYPT_SECRET_KEY
                    );

                    localStorage.setItem(
                      "private_conversation_data",
                      encryptPrivateConversationData.toString()
                    );

                    history.push({
                      pathname: `/create-messages/${message._id}`,
                      state: {
                        createMessage: user,
                        receiver:
                          message.from._id === user.user.id
                            ? message.to
                            : message.from,
                        socketSendOnOpenConversation: true,
                      },
                      convoId: message._id,
                    });
                  }}
                >
                  <div className="messageUser_details">
                    <img
                      className={
                        message.favorite
                          ? "messageSelected__favIcon"
                          : "message__favIcon"
                      }
                      src={
                        message &&
                        message.favoritedBy &&
                        message.favoritedBy.length &&
                        message.favoritedBy.filter(
                          (favoritedByData) =>
                            favoritedByData.favoritedBy === user.user._id
                        ).length
                          ? SelectedFavIcon
                          : FavIcon
                      }
                      alt="fav"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFavoriteMessage(message._id);
                      }}
                    />
                    <div className="user_thumbnail_details">
                      <div className="avatar">
                        <Avatar
                          src={
                            message.from._id === user.user._id
                              ? message.to.image
                                ? message.to.image.url
                                : defaultAvatar
                              : message.from.image
                              ? message.from.image.url
                              : defaultAvatar
                          }
                          alt="messages pict"
                        />
                        <span
                          className={
                            message.from._id === user.user._id
                              ? message.to.online
                                ? "messages__online"
                                : "hidden"
                              : message.from.online
                              ? "messages__online"
                              : "hidden"
                          }
                        ></span>
                      </div>
                      <div className="user_details">
                        <h2
                          className={`
                              ${
                                message &&
                                message.readBy &&
                                message.readBy.length &&
                                message.readBy.filter(
                                  (data) => data.readBy === user.user._id
                                ).length
                                  ? "messagesCard__text "
                                  : "messagesCardNoRead__text"
                              }

                              ${
                                message &&
                                message.subscriber &&
                                " message__subscriber"
                              }

                              `}
                        >
                          {message.from._id === user.user._id
                            ? message.to.username
                            : message.from.username}
                        </h2>

                        {message.type === "Text" && message.content !== "custom media ready" && (
                          <p
                            className={
                              message &&
                              message.readBy &&
                              message.readBy.length &&
                              message.readBy.filter(
                                (data) => data.readBy === user.user._id
                              ).length
                                ? "messagesCard__text"
                                : "messagesCardNoRead__text"
                            }
                          >
                            {`${truncate(message.content)}`}
                          </p>
                        )}

                        {message.type === "Files" && (
                          <p
                            className={
                              message &&
                              message.readBy &&
                              message.readBy.length &&
                              message.readBy.filter(
                                (data) => data.readBy === user.user._id
                              ).length
                                ? "messagesCard__text"
                                : "messagesCardNoRead__text"
                            }
                          >
                            {`${message.from._id === user.user._id ? t("you") : ""} ${t('sentPhoto')}`}
                          </p>
                        )}

                        {message.type === "Heart" && (
                          <p
                            className={
                              message &&
                              message.readBy &&
                              message.readBy.length &&
                              message.readBy.filter(
                                (data) => data.readBy === user.user._id
                              ).length
                                ? "messagesCard__text"
                                : "messagesCardNoRead__text"
                            }
                          >
                            {" "}
                            <img
                              width="18"
                              src={heart}
                              alt="heart"
                              className="heart_allMessages"
                            />
                          </p>
                        )}

                        {message.type === "Tickles" && (
                          <p
                            className={
                              message &&
                              message.readBy &&
                              message.readBy.length &&
                              message.readBy.filter(
                                (data) => data.readBy === user.user._id
                              ).length
                                ? "messagesCard__text"
                                : "messagesCardNoRead__text"
                            }
                          >
                            {message.from._id === user.user._id
                              ? `${t("You've sent")} ${t(
                                  "buyTicklesTickles"
                                )}`
                              : `${t("You received")} ${t(
                                  "buyTicklesTickles"
                                )}`}
                          </p>
                        )}

                        {message.type === "Custom-Media-Request" && (
                          <p
                            className={
                              message &&
                              message.readBy &&
                              message.readBy.length &&
                              message.readBy.filter(
                                (data) => data.readBy === user.user._id
                              ).length
                                ? "messagesCard__text"
                                : "messagesCardNoRead__text"
                            }
                          >
                            {message.from._id === user.user._id
                              ? `${t("You've sent a custom media request")}`
                              : `${t("You received a custom media request")}`}
                          </p>
                        )}

                        {message.content === "custom media ready" && (
                          <p
                            className={
                              message &&
                              message.readBy &&
                              message.readBy.length &&
                              message.readBy.filter(
                                (data) => data.readBy === user.user._id
                              ).length
                                ? "messagesCard__text"
                                : "messagesCardNoRead__text"
                            }
                          >
                            {message.from._id === user.user._id
                              ? `${t("cmModelMessageNotification")}`
                              : `${t("cmLoverMessageNotification")}`}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      message.read
                        ? "messagesCard__text"
                        : "messagesCardNoRead__text"
                    }
                  >
                    {/* {message.type === "Tickles" && (
                        <p className="messages__message">
                          {message.from._id === user.user._id
                            ? `You've sent ${message.ticklesAmount} tickles`
                            : ""}
                        </p>
                      )} */}
                  </div>
                  <p className="messages__Date">
                    {moment(message.createdAt).format("H[h]mm")}
                  </p>
                  {isUserFeetModel() ? (
                    message.hasUnreadCustomMediaRequest ? (
                      <img
                        src={MessageIcon}
                        alt="requestCMIcon"
                        className="messagesModels__customMediaIcon"
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {isUserFeetModel() ? (
                    message.hasUnreadTickles ? (
                        <img
                          src={TicklesIcon}
                          alt="ticklesIcon"
                          className="messagesModels__ticklesIcon"
                        />
                      ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </SwipeableListItem>
            </SwipeableList>
          ))}

          <div ref={loadMoreMessagesReference}>
            {isFetchingNextPage ? (
              <div
                className="skeleton"
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div style={{ flexBasis: "90%" }}>
                  <CardHeader
                    avatar={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Skeleton
                          animation="wave"
                          variant="circle"
                          width={25}
                          height={25}
                          style={{ marginRight: "20px" }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="circle"
                          width={50}
                          height={50}
                        />
                      </div>
                    }
                    title={
                      <Skeleton
                        animation="wave"
                        height={20}
                        width="60%"
                        style={{ marginBottom: 6 }}
                      />
                    }
                    subheader={
                      <Skeleton animation="wave" height={10} width="40%" />
                    }
                  />
                </div>
                <div
                  style={{
                    flexBasis: "10%",
                    alignSelf: "center",
                  }}
                >
                  <Skeleton
                    animation="wave"
                    height={15}
                    width="70%"
                    style={{ marginBottom: "15px" }}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}

      {/* MODAL FOR MESSAGE DELETION WITH FAVORITES */}
      <Dialog
        open={isOpenDeleteWithFavorite}
        onClose={() => {
          setIsOpenDeleteWithFavorite(false);
        }}
      >
        <DialogTitle style={{ fontFamily: "Roboto", textAlign: "center" }}>
          {t("deleteMessageWithFavoriteWarning")}
        </DialogTitle>
        <Divider />
        <button
          onClick={() => {
            setIsOpenDeleteWithFavorite(false);
          }}
          style={{
            backgroundColor: "white",
            padding: "10px",
            border: "none",
            // borderTop: "0.02rem solid #9F9F9F",
            color: "#ff2775",
            fontSize: "	1.1rem",
            fontWeight: "700",
            fontFamily: "Roboto",
          }}
        >
          {t("Ok")}
        </button>
      </Dialog>
      <Dialog
        open={isOpenCantDeleteMessageWithTickle || isOpenCantDeleteMessageWithCustomMedia || isOpenCantDeleteMessageWithCustomMediaRequest}
        onClose={() => {
          setIsOpenCantDeleteMessageWithTickle(false);
          setIsOpenCantDeleteMessageWithCustomMedia(false);
          setIsOpenCantDeleteMessageWithCustomMediaRequest(false);
        }}
      >
        <DialogTitle style={{ fontFamily: "Roboto", textAlign: "center" }}>
          { isOpenCantDeleteMessageWithTickle ? t("deleteMessageWithTickleWarning") : (isOpenCantDeleteMessageWithCustomMedia ? t("deleteMessageWithCustomMediaWarning") : t("deleteMessageWithCustomMediaRequestWarning"))}
        </DialogTitle>
        <Divider />
        <button
          onClick={() => {
            setIsOpenCantDeleteMessageWithTickle(false);
            setIsOpenCantDeleteMessageWithCustomMedia(false);
            setIsOpenCantDeleteMessageWithCustomMediaRequest(false);
          }}
          style={{
            backgroundColor: "white",
            padding: "10px",
            border: "none",
            // borderTop: "0.02rem solid #9F9F9F",
            color: "#ff2775",
            fontSize: "	1.1rem",
            fontWeight: "700",
            fontFamily: "Roboto",
          }}
        >
          {t("Ok")}
        </button>
      </Dialog>
    </div>
  );
};

export default AllMessages;
