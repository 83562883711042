import React from "react";
import { Button as MuiButton } from "@material-ui/core";

import { useStyles } from "./styled";

function Button(props) {
  const {
    children,
    width = "219px",
    height = "48px",
    background = "blue",
    type = "button",
    color = "#FFFFFF",
    boxShadow = null,
    padding = "12px 70px",
    fontSize = "clamp(12px, 4vw, 1.2rem)",
    borderRadius = "25px",
    ...rest
  } = props;

  const classes = useStyles({
    width,
    height,
    background,
    color,
    boxShadow,
    padding,
    fontSize,
    borderRadius,
  })();

  return (
    <div className={classes.root} {...rest}>
      <MuiButton
        variant="contained"
        className="button"
        width={width}
        background={background}
        height={height}
        type={type}
      >
        {children}
      </MuiButton>
    </div>
  );
}

export default Button;
