import { selector } from "recoil";

import commentBadge from "../../../atoms/notifications/badge/comment";

const setNotificationCommentBadgeInVisible = selector({
  key: "setNotificationCommentBadgeInVisibleSelector",
  set: ({ set }, value) => {
    set(commentBadge, value);
  },
});

export default setNotificationCommentBadgeInVisible;
