import socketio from "socket.io-client";
import { fromEvent } from "rxjs";

const { REACT_APP_API_SERVER_URL } = process.env;

export class SocketService {
  #socket;

  init() {
    this.#socket = socketio.connect(REACT_APP_API_SERVER_URL);
    return this;
  }

  // user connect
  connect(userId) {
    this.#socket.emit("USER_CONNECTED", { user_id: userId });
  }

  sendMessage(data, cb) {
    this.#socket.emit("SEND_MESSAGE", data, (response) => {
      if (response.status === "Ok") {
        cb(null, response);
      } else {
        cb(new Error(response.message));
      }
    });
  }

  sendOnOpenPrivateConversation(data, cb) {
    this.#socket.emit("OPEN_PRIVATE_CONVERSATION", data, (response) => {
      if (response.status === "Ok") {
        cb(null, response);
      } else {
        cb(new Error(response.message));
      }
    });
  }

  //   message: "Failed to send message, he/she is in your block list", level: "error", timestamp: "2021-03-30 16:06:39"}
  // level: "error"
  // message: "Failed to send message, he/she is in your block list"
  // timestamp: "2021-03-30 16:06:39"
  onComment() {
    return fromEvent(this.#socket, "COMMENT_NOTIFICATION");
  }

  // link message event to rxjs data source
  onMessage() {
    return fromEvent(this.#socket, "NEW_MESSAGE");
  }
  onBannedMedia() {
    return fromEvent(this.#socket, "NEW_BANNED_MEDIA");
  }
  onLike() {
    return fromEvent(this.#socket, "LIKE_NOTIFICATION");
  }

  onNotification() {
    return fromEvent(this.#socket, "NEW_NOTIFICATION");
  }

  onTickle() {
    return fromEvent(this.#socket, "TICKLE_NOTIFICATION");
  }

  onFollow() {
    return fromEvent(this.#socket, "FOLLOW_NOTIFICATION_BADGE");
  }

  onSubscription() {
    return fromEvent(this.#socket, "SUBSCRIPTION_NOTIFICATION_BADGE");
  }

  // disconnect - used when unmounting
  disconnect() {
    this.#socket.disconnect();
  }
}
