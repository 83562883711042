import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { Container, CssBaseline, Typography } from "@material-ui/core/";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { useRecoilState, useSetRecoilState } from "recoil";

import "./chooseAccount.css";
import logo from "../../assets/images/logo-login.png";
import { userTypeAtom } from "../../stateManagement/atoms/studioAtom";
import signUpUserDataAtom from "../../stateManagement/atoms/auth/sign-up-user-data-atom";
import setSignUpUserData from "../../stateManagement/selectors/auth/sign-up-user-data-selector";
import moment from "moment";
import AuthService from "../../_services/auth.service";

//  All props come from App component
export default function ChooseAccount() {
  const { t } = useTranslation();
  const openingDate = "2022-11-21";

  const [, setDisabled] = useState(false);
  const [userType, setUserType] = useRecoilState(userTypeAtom);

  const [isLoverDisabled, setLoverDisabled] = useState(true);
  const [isModelDisabled, setModelDisabled] = useState(true);
  const [setUserTypeFromLocalStore] = useState(
    sessionStorage.setItem("userType", userType)
  );
  const [signUpUserData] = useRecoilState(signUpUserDataAtom);
  const setSignUpUserDataSelector = useSetRecoilState(setSignUpUserData);

  useEffect(() => {
    const dateFormat = "YYYY-MM-DD";
    const now = moment().format(dateFormat);
    setDisabled(!moment(now).isSameOrAfter(openingDate));
  }, []);

  useEffect(async () => {
    const { data } = await AuthService.getRegistrationStatus();
    console.log("data softlaunch: ", data);
    await setLoverDisabled(data.registration_status["lover-disabled"]);
    await setModelDisabled(data.registration_status["model-disabled"]);
    if (data.registration_status["lover-disabled"] || data.registration_status["model-disabled"])
      setUserType("");
  }, []);

  useEffect(() => {
    if (signUpUserData && signUpUserData?.userType) {
      if (!isLoverDisabled || !isModelDisabled)
        setUserType(signUpUserData?.userType);
    } else {
      const userSignUpDataFromLocalStorage = sessionStorage.getItem(
        "sign-up-user-data"
      );

      if (userSignUpDataFromLocalStorage) {
        const parsedSignUpUserData = JSON.parse(userSignUpDataFromLocalStorage);

        if (parsedSignUpUserData?.userType) {
          if (!isLoverDisabled || !isModelDisabled)
            setUserType(parsedSignUpUserData?.userType);
        }
      }
    }

    return () => {};
  }, [signUpUserData, setUserType]);

  // Function for toast notification if user type is not selected
  const onClickOption = () => {
    if (userType === "") {
      toast.dark(t("errChooseAccount"), {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setUserTypeFromLocalStore(userType);
    }
  };

  return (
    <div className="chooseAccount__content">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{top: "5em"}}
      />
      <Container
        className="chooseAccount__container AccountChoose"
        component="main"
        maxWidth="xs"
        id="chooseAccount"
      >
        <CssBaseline />
        <div className="chooseAccount__paper">
          <div className="chooseAccount__imageContainer">
            <img className="auth__imgLogo" src={logo} alt="" />
          </div>
          <Typography
            className="chooseAccount__Text "
            component="h1"
            variant="h5"
          >
            {t("createAccountTitle")}
          </Typography>
          <Typography
            className="chooseAccount__Text chooseAccount__TextRegular"
            component="h4"
            variant="h5"
          >
            {t("createAccountSubTitle")}
          </Typography>
          <div className="chooseAccount__Button">
            <Button
              type="submit"
              disabled={isModelDisabled}
              onClick={() => {
                setUserType("Model");

                setSignUpUserDataSelector({
                  ...signUpUserData,
                  userType: "Model",
                });
              }}
              fullWidth
              variant="contained"
              // color="secondary"
              className={`chooseAccount__option ${
                userType === "Model" ? "chooseAccount__option__selected" : ""
              }`}
              style={{ display: "flex" }}
            >
              <h3 style={{ fontSize: 15 }}>{t("createAccountTypeModel")}</h3>
              <h5 style={{ flex: 1, fontSize: 14, fontWeight: 400 }}>
                {t("createAccountTypeModelDesc")}
              </h5>
            </Button>

            <Button
              type="submit"
              onClick={() => {
                setUserType("Lover");
                setSignUpUserDataSelector({
                  ...signUpUserData,
                  userType: "Lover",
                });
              }}
              disabled={isLoverDisabled}
              fullWidth
              variant="contained"
              color="secondary"
              style={{ display: "flex" }}
              className={`chooseAccount__option ${
                userType === "Lover" ? "chooseAccount__option__selected" : ""
              }`}
            >
              <h3 style={{ fontSize: 15 }}>{t("createAccountTypeLover")}</h3>
              <h5 style={{ flex: 1, fontSize: 14, fontWeight: 400 }}>
              {t("createAccountTypeLoverDesc")}
              </h5>
            </Button>
          </div>
        </div>
        <div className="signin__redirect">
          <NavLink to="/sign-in">
            <span
              style={{
                color: "#323232",
                fontSize: 14,
                fontWeight: 400,
                marginTop: -50,
              }}
            >
              {t("alreadyAccountChoosePage")}
            </span>
          </NavLink>
          {userType !== "" ? (
            <NavLink
              className="full-width"
              to={userType === "Model" ? "/feet-details" : "/choose-picty"}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                className="pink__button full-width"
              >
                {t("createAccountButtonRegistering")}
              </Button>
            </NavLink>
          ) : (
            <Button
              onClick={() => onClickOption()}
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className="pink__button full-width"
            >
              {t("createAccountButtonRegistering")}
            </Button>
          )}
        </div>
      </Container>
    </div>
  );
}
