import { AppBar, Toolbar } from "@material-ui/core";
import "./header.css";
import LogoText from "../../../../assets/images/logo-text.png";
import React from "react";

const Header = () => {
  return (
    <AppBar className="appBarHomeStudio page_fixed_header" position="static">
      <Toolbar variant="dense">
        <div className="logo-crop-step">
          <img className="logo" src={LogoText} alt="logo-text" />
          <h3> Media Studio</h3>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
