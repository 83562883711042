import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { Badge } from "@material-ui/core/";
import { Avatar } from "@material-ui/core";
import { isIOS } from "react-device-detect";
import "../../notificationsModels/notificationsModels.css";
import defaultAvatar from "../../../../assets/images/generic-user-avatar.png";
import { useTranslation } from "react-i18next";
import useSessionStorage from "../../../forms/services/storageHooks/useSessionStorage";
import NotificationService from "../../../../_services/notification.service";
import newMessageBadgeAtom from "../../../../stateManagement/atoms/newMessageBadgeAtom";
import setBadgeInVisibleSelector from "../../../../stateManagement/selectors/notifications/setBadgeVisible";
import setNotificationCommentBadgeInVisibleSelector from "../../../../stateManagement/selectors/notifications/badge/setCommentBadgeInvisible";
import setNotificationFollowerBadgeInvisibleSelector from "../../../../stateManagement/selectors/notifications/setFollowerBadgeInvisible";
import setNotificationGeneralBadgeInVisibleSelector from "../../../../stateManagement/selectors/notifications/badge/setGeneralNotifcationBadgeInvisible";
import setNotificationLikeBadgeInVisibleSelector from "../../../../stateManagement/selectors/notifications/badge/setLikeBadgeVisible";
import setNotificationSubscriptionBadgeInVisibleSelector from "../../../../stateManagement/selectors/notifications/badge/setSubscriptionBadgeInvisible";
import setNotificationTickleBadgeInVisibleSelector from "../../../../stateManagement/selectors/notifications/badge/setTickleBadgeInvisible";
import { useRecoilState, useSetRecoilState } from "recoil";

const StyledBadge = withStyles((theme) => ({
  badge: {
    padding: "0 4px",
    borderRadius: "50%",
    width: "8px",
    lineHeight: "8px",
    height: "8px",
  },
}))(Badge);

const useNotificationsModels = () => {
  const [user] = useSessionStorage({}, "infosUser");
  let history = useHistory();
  const { t } = useTranslation();
  const [noLikesYet, setNoLikesYet] = useState(true);
  const [clickedLikes, setClickedLikes] = useState(false);
  const [noCommentsYet, setNoCommentsYet] = useState(true);
  const [clickedComments, setClickedComments] = useState(false);
  const [noTicklesYet, setNoTicklesYet] = useState(true);
  const [clickedTickles, setClickedTickles] = useState(false);
  const [noSubscriptionsYet, setNoSubscriptionsYet] = useState(true);
  const [clickedSubscriptions, setClickedSubscriptions] = useState(false);
  const [swippedLikes, setSwippedLikes] = useState(false);
  const [swippedComments, setSwippedComments] = useState(false);
  const [swippedTickles, setSwippedTickles] = useState(false);
  const [swippedSubscriptions, setSwippedSubscriptions] = useState(false);
  const [deployComments, setDeployComments] = useState(false);
  const [deployTickles, setDeployTickles] = useState(false);
  const [deployLikes, setDeployLikes] = useState(false);
  const [deploySubscriptions, setDeploySubscriptions] = useState(false);
  const dateTimeFormat = "DD/MM/YYYY H[h]mm";
  const [___, setNewMessageBadge] = useRecoilState(newMessageBadgeAtom);
  const setNotificationBadgeInVisible = useSetRecoilState(
    setBadgeInVisibleSelector
  );
  const setGeneralNotificationBadgeInVisible = useSetRecoilState(
    setNotificationGeneralBadgeInVisibleSelector
  );
  const setNotificationLikeBadgeInVisible = useSetRecoilState(
    setNotificationLikeBadgeInVisibleSelector
  );
  const setNotificationCommentBadgeInVisible = useSetRecoilState(
    setNotificationCommentBadgeInVisibleSelector
  );
  const setNotificationTickleBadgeInVisible = useSetRecoilState(
    setNotificationTickleBadgeInVisibleSelector
  );
  const setNotificationFollowerBadgeInVisible = useSetRecoilState(
    setNotificationFollowerBadgeInvisibleSelector
  );
  const setNotificationSubscriptionBadgeInVisible = useSetRecoilState(
    setNotificationSubscriptionBadgeInVisibleSelector
  );

  const NoNotificationsYet = () => (
    <div className="notificationsModels__noNotif">
      <sub>{t("No notifications yet")}</sub>
    </div>
  );

  const handleSwippedLikes = () => {
    if (swippedLikes === false) {
      setSwippedLikes(true);
    }
    if (swippedLikes === true) {
      setSwippedLikes(false);
    }
  };

  const handleSwippedComments = () => {
    if (swippedComments === false) {
      setSwippedComments(true);
    }
    if (swippedComments === true) {
      setSwippedComments(false);
    }
  };

  const handleSwippedTickles = () => {
    if (swippedTickles === false) {
      setSwippedTickles(true);
    }
    if (swippedTickles === true) {
      setSwippedTickles(false);
    }
  };

  const handleSwippedSubscriptions = () => {
    if (swippedSubscriptions === false) {
      setSwippedSubscriptions(true);
    }
    if (swippedSubscriptions === true) {
      setSwippedSubscriptions(false);
    }
  };
  const handleDeployComments = () => {
    setDeployComments(!deployComments)
  };
  const handleDeployTickles = () => {
    setDeployTickles(!deployTickles)
  };
  const handleDeployLikes = () => {
    setDeployLikes(!deployLikes);
  };
  const handleDeploySubscriptions = () => {
    if (deploySubscriptions === false) {
      setDeploySubscriptions(true);
    }
    if (deploySubscriptions === true) {
      setDeploySubscriptions(false);
    }
  };

  const handleCheckReadAllNotifications = async () => {
    const [{data},
      {data: tickles} ,
      { data: comments },
      { data: likes },
      { data: messages },
      { data: subscriptions }] = await Promise.all([NotificationService.countGeneralNotifications(),
      NotificationService.countTicklesUnreadNotification(),
      NotificationService.countCommentsUnreadNotification(),
      NotificationService.countLikesUnreadNotification(),
      NotificationService.countMessagesUnreadNotification(),
      NotificationService.countSubscriptionsUnreadNotification()
    ])

    const unreadNonMessagingNotifications = tickles.unread + comments.unread + likes.unread + data.unread + subscriptions.unread
    if (unreadNonMessagingNotifications > 0) {
      setNotificationBadgeInVisible(false);
    } else {
      setNotificationBadgeInVisible(true);
    }

    if(data.unread > 0){
      setGeneralNotificationBadgeInVisible(false);
    }

    if(messages.unread > 0){
      setNewMessageBadge(true)
    }

    if(tickles.unread > 0){
      setNotificationTickleBadgeInVisible(false);
    }

    if(likes.unread > 0){
      setNotificationLikeBadgeInVisible(false);
    }

    if(comments.unread > 0){
      setNotificationCommentBadgeInVisible(false)
    }

    if(subscriptions.unread > 0){
      setNotificationSubscriptionBadgeInVisible(false)
    }
  }

  // FAKE DATA FOR SUBSCRIPTIONS
  const lengthOfSubscriptions = (dataSubscribers, userId) =>
    dataSubscribers
      .filter((subscriptions) => subscriptions.details.user._id._id === userId)
      .map((resSubscribe) => resSubscribe);

  const LikesNotifications = ({
    subscribers,
    likes,
    loadMoreLikesReference,
    likesNotifications,
    isInvisibleLikeBadge,
  }) => (
    <Fragment>
      <div
        className="notificationsModels swipped user_list"
        // className={
        //   swippedLikes === false
        //     ? isIOS === true ? "notificationsModels__Likes notSwipped notSwippedIphone" : "notificationsModels__Likes notSwipped"
        //     : "notificationsModels__Likes swipped"
        // }
      >
        {likes.map((like) => (
          <div
            key={like._id}
            className={
              likes.length === 1
                ? `user_thumbnail_details marginNone ${
                    isIOS === true ? "marginIphone" : ""
                  }`
                : "user_thumbnail_details"
            }
            onClick={() => {
              history.push({
                pathname: `/publication/${like.like.publication}`,
              });
            }}
          >
            {/*{likesNotifications &&*/}
            {/*  likesNotifications.filter((item) => item._id === like._id)*/}
            {/*    .length > 0 && (*/}
            {/*    <StyledBadge*/}
            {/*      color="secondary"*/}
            {/*      variant="dot"*/}
            {/*      invisible={isInvisibleLikeBadge}*/}
            {/*    />*/}
            {/*  )}*/}
            <StyledBadge
                color="secondary"
                variant="dot"
                invisible={isInvisibleLikeBadge || like.readAt}
                overlap={"rectangular"}
            />
            <div className="avatar">
              <Avatar
                src={like?.details?.user?._id?.image?.url || defaultAvatar}
                alt="avatar"
              />
            </div>
            <div className="user_details">
              <h2
                style={
                  like.details &&
                  like.details.user &&
                  like.details.user?._id?._id &&
                  subscribers &&
                  lengthOfSubscriptions(subscribers, like.details.user?._id?._id).length > 0
                    ? { color: "#ff2775", fontWeight: "bold" }
                    : {}
                }
              >
                {like?.details?.user?.username}
              </h2>
              <p>{t("likedYourPhoto")}</p>
            </div>
            <sub>{moment(like?.createdAt).format(dateTimeFormat)}</sub>
          </div>
        ))}

        <div ref={loadMoreLikesReference} />
      </div>
      {/*{likesMeta.count >= 2 ? (*/}
      {/*  <div className={isIOS === true ? "notifications__footer notifications__footerIphone" : "notifications__footer"}>*/}
      {/*    <Button onClick={handleSwippedLikes}>*/}
      {/*      {swippedLikes === false ? (*/}
      {/*        <KeyboardArrowDownIcon />*/}
      {/*      ) : (*/}
      {/*        <KeyboardArrowUpIcon className="margin-swipped" />*/}
      {/*      )}*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*) : null}*/}
    </Fragment>
  );

  const CommentsNotifications = ({
    subscribers,
    comments,
    loadMoreCommentsReference,
    commentsNotifications,
    isInvisibleCommentBadge,
  }) => (
    <Fragment>
      <div
        className="notificationsModels swipped user_list"
        // className={
        //   swippedComments === false
        //     ? isIOS === true ? "notificationsModels__Comments notSwipped notSwippedIphone" : "notificationsModels__Comments notSwipped"
        //     : "notificationsModels__Comments swipped"
        // }
      >
        {comments.map((data) => {
          return (
          <div
            key={data._id}
            className={
              comments.length === 1
                ? `user_thumbnail_details marginNone ${
                    isIOS === true ? "marginIphone" : ""
                  }`
                : "user_thumbnail_details"
            }
            onClick={() => {
              history.push({
                pathname: `/publication/${data.comment.publication}`,
              });
            }}
          >
            {/*{commentsNotifications &&*/}
            {/*  commentsNotifications.filter((item) => item._id === data._id)*/}
            {/*    .length > 0 && (*/}
            {/*    <StyledBadge*/}
            {/*      color="secondary"*/}
            {/*      variant="dot"*/}
            {/*      invisible={isInvisibleCommentBadge}*/}
            {/*    />*/}
            {/*  )}*/}
            <StyledBadge
                color="secondary"
                variant="dot"
                invisible={isInvisibleCommentBadge || data.readAt}
            />
            <div className="avatar">
              <Avatar
                src={
                  data?.details?.user?.image?.url || defaultAvatar
                }
                alt="avatar"
              />
            </div>
            <div className="user_details">
              <h2
                style={
                  data.comment &&
                  data.comment?.writer &&
                  subscribers &&
                  lengthOfSubscriptions(subscribers, data.comment?.writer?._id)
                    .length > 0
                    ? { color: "#ff2775", fontWeight: "bold" }
                    : {}
                }
              >
                {data.comment?.writer?.username}
              </h2>
              <p>{t("Commented your photo")}</p>
            </div>
            <sub>{moment(data?.createdAt).format(dateTimeFormat)}</sub>
          </div>)
        })}
        <div ref={loadMoreCommentsReference} />
      </div>
      {/*{commentsMeta.count >= 2 ? (*/}
      {/*  <div className={isIOS === true ? "notifications__footer notifications__footerIphone" : "notifications__footer"}>*/}
      {/*    <Button onClick={handleSwippedComments}>*/}
      {/*      {swippedComments === false ? (*/}
      {/*        <KeyboardArrowDownIcon />*/}
      {/*      ) : (*/}
      {/*        <KeyboardArrowUpIcon className="margin-swipped" />*/}
      {/*      )}*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*) : null}*/}
    </Fragment>
  );

  const TicklesNotifications = ({
    subscribers,
    tickles,
    loadMoreTicklesReference,
    ticklesNotifications,
    isInvisibleTickleBadge,
  }) => (
    <Fragment>
      <div
        className="notificationsModels swipped user_list"
        // className={
        //   swippedTickles === false
        //     ? isIOS === true ? "notificationsModels__Tickles notSwipped notSwippedIphone" : "notificationsModels__Tickles notSwipped"
        //     : "notificationsModels__Tickles swipped"
        // }
      >
        {tickles.map((data) => (
          <div
            key={data._id}
            className={
              tickles.length === 1
                ? `user_thumbnail_details marginNone ${
                    isIOS === true ? "marginIphone" : ""
                  }`
                : "user_thumbnail_details"
            }
            onClick={() => {
              if (data.tickle.publication) {
                history.push({
                  pathname: `/publication/${data.tickle.publication._id}`,
                });
              } else {
                history.push({
                  pathname: `/create-messages/${data.tickle.message.convoId}`,
                  // state: { receiver: data.tickle.feetLover },
                  state: {
                    createMessage: user,
                    // receiver:
                    //     data.tickle.message.from._id === user.user.id
                    //         ? data.tickle.message.to
                    //         : data.tickle.message.from,
                    receicer: data.tickle.message.from,
                    socketSendOnOpenConversation: true,
                  },
                  convoId: data.tickle.message.convoId,
                });
              }
            }}
          >
            {/*{console.log('tickeNotification: ', ticklesNotifications)}*/}
            {/*{console.log('tickeNotification data: ', data)}*/}
            {/*{ticklesNotifications &&*/}
            {/*  ticklesNotifications.filter((item) => item.tickle._id === data.tickle._id)*/}
            {/*    .length > 0 && (*/}
            {/*    <StyledBadge*/}
            {/*      color="secondary"*/}
            {/*      variant="dot"*/}
            {/*      invisible={isInvisibleTickleBadge}*/}
            {/*    />*/}
            {/*  )}*/}
            <StyledBadge
                color="secondary"
                variant="dot"
                invisible={isInvisibleTickleBadge || data.readAt}
                overlap={"rectangular"}
            />
            <div className="avatar">
              <Avatar
                src={
                  data?.details?.user?._id?.image?.url || defaultAvatar
                }
                alt="avatar"
              />
            </div>
            <div className="user_details">
              <h2
                style={
                  data.details &&
                  data.details.user &&
                  subscribers &&
                  lengthOfSubscriptions(subscribers, data.details.user._id._id)
                    .length > 0
                    ? { color: "#ff2775", fontWeight: "bold" }
                    : {}
                }
              >
                {data.details.user._id.username}
              </h2>
              <p>{t("Sent you") + " " + data?.tickle?.tickles + t(" tickles")}</p>
            </div>
            <sub>{moment(data?.tickle?.createdAt).format(dateTimeFormat)}</sub>
          </div>
        ))}

        <div ref={loadMoreTicklesReference} />
      </div>
      {/*{ticklesMeta.count >= 2 ? (*/}
      {/*  <div className={isIOS === true ? "notifications__footer notifications__footerIphone" : "notifications__footer"}>*/}
      {/*    <Button onClick={handleSwippedTickles}>*/}
      {/*      {swippedTickles === false ? (*/}
      {/*        <KeyboardArrowDownIcon />*/}
      {/*      ) : (*/}
      {/*        <KeyboardArrowUpIcon className="margin-swipped" />*/}
      {/*      )}*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*) : null}*/}
    </Fragment>
  );

  const SubscriptionsNotifications = ({
    subscribers,
    authUser,
    setFeetModel,
    loadMoreSubscribersReference,
    isInvisibleSubscriptionBadge,
  }) => (
    <Fragment>
      <div
        className="notificationsModels swipped user_list"
        // className={
        //   swippedSubscriptions === false
        //     ? isIOS === true ? "notificationsModels__Subscriptions notSwipped notSwippedIphone" : "notificationsModels__Subscriptions notSwipped"
        //     : "notificationsModels__Subscriptions swipped"
        // }
      >
        {subscribers && subscribers.length > 0 && subscribers
          .filter((subscriptions) => subscriptions.product)
          .map((resSubscribe) => (
            <div
              key={resSubscribe._id}
              className={
                subscribers.length === 1
                  ? `user_thumbnail_details marginNone ${
                      isIOS === true ? "marginIphone" : ""
                    }`
                  : "user_thumbnail_details"
              }
              onClick={() => {
                if (authUser.user._id || resSubscribe.details.user?._id?._id) {
                  if (authUser.user._id === resSubscribe.details.user?._id?._id) {
                    history.push("/feet-lover-profile");
                  } else {
                    history.push({
                      pathname: `/visit-feet-lover/${resSubscribe.details.user?._id?._id}`,
                    });
                  }
                }
                // else {
                //   if (authUser.user._id === resSubscribe.subscriber._id) {
                //     history.push("/view-own-profile-models");
                //   } else {
                //     setFeetModel(resSubscribe.subscriber);
                //     history.push({
                //       pathname: "/feet-models-view",
                //       search: `?user=${resSubscribe.subscriber._id}`,
                //     });
                //   }
                // }
              }}
            >
              {/*TODO: <StyledBadge/> for subscriptions*/}
              <StyledBadge
                  color="secondary"
                  variant="dot"
                  invisible={isInvisibleSubscriptionBadge || resSubscribe.readAt}
                  overlap={"rectangular"}
              />
              <div className="avatar">
                <Avatar
                  src={
                    resSubscribe &&
                    resSubscribe.details &&
                    resSubscribe.details.user.image &&
                    resSubscribe.details.user?._id?.image?.url
                      ? resSubscribe.details.user?._id?.image?.url
                      : defaultAvatar
                  }
                  alt="avatar"
                />
              </div>
              <div className="user_details">
                <h2 style={{ color: "#ff2775", fontWeight: "bold" }}>
                  {resSubscribe.details.user.username}
                </h2>
                <p>{resSubscribe.type && resSubscribe.type.includes("Subscription") ? t("Subscribed to your profile") : t("Renew subscription to your profile")}</p>
              </div>
              <sub>{moment(resSubscribe?.createdAt).format(dateTimeFormat)}</sub>
            </div>
          ))}

        <div ref={loadMoreSubscribersReference} />
      </div>
      {/*<div className="notifications__footer">*/}
      {/*  <Button onClick={handleSwippedSubscriptions}>*/}
      {/*    {swippedSubscriptions === false ? (*/}
      {/*      <KeyboardArrowDownIcon />*/}
      {/*    ) : (*/}
      {/*      <KeyboardArrowUpIcon className="margin-swipped" />*/}
      {/*    )}*/}
      {/*  </Button>*/}
      {/*</div>*/}
    </Fragment>
  );

  return {
    noLikesYet,
    setNoLikesYet,
    clickedLikes,
    noCommentsYet,
    setNoCommentsYet,
    clickedComments,
    noTicklesYet,
    setNoTicklesYet,
    clickedTickles,
    noSubscriptionsYet,
    setNoSubscriptionsYet,
    clickedSubscriptions,
    NoNotificationsYet,
    LikesNotifications,
    CommentsNotifications,
    TicklesNotifications,
    SubscriptionsNotifications,
    deployComments,
    handleDeployComments,
    deployTickles,
    handleDeployTickles,
    deployLikes,
    handleDeployLikes,
    deploySubscriptions,
    handleDeploySubscriptions,
    handleCheckReadAllNotifications,
  };
};

export default useNotificationsModels;
