import axios from "axios";

import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const getFeetModelsPublications = async (offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
    `/v1/feet-models/publications?offset=${offset}`,
    {
      headers,
    }
  );

  return response;
};

const getFollowSuggestions = async (offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
    `/v1/feet-models/follow-suggestions?offset=${offset}`,
    { headers }
  );
  return response;
};

const getSubscribeSuggestions = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
    "/v1/feet-models/subscribe-suggestions",
    { headers }
  );
  return response;
};

const setupBank = async (data) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.put(
    `/v1/feet-models/set-up-bank`,
    data,
    {
      headers,
    }
  );

  return response.data;
};

let searchTokenSource;
const searchFeetModelPublications = async (query, params, offset = 0) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    if (typeof searchTokenSource !== typeof undefined) {
      searchTokenSource.cancel("Operation canceled due to new request.");
    }

    // save the new request for cancellation
    searchTokenSource = axios.CancelToken.source();

    const response = await axiosInstance.get(
      `/v1/feet-models/publications/search?q=${query}&offset=${offset}`,
      {
        cancelToken: searchTokenSource.token,
        headers,
        params,
      }
    );

    return { data: response.data };
  } catch (error) {
    if (axios.isCancel(error)) return { cancelPrevQuery: true };
    return [error];
  }
};

const getOwnPublications = async (offset = 0) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const id = getAuthSession().user._id;

    const response = await axiosInstance.get(
      `/v1/feet-models/${id}/publications?offset=${offset}`,
      {
        headers,
      }
    );

    return response;
  } catch (error) {
    console.error("ERROR GET /api/v1/feet-models/:id/publications", error);
  }
};

const getFeetModelProfile = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/feet-models/profile`, {
    headers,
  });

  return response.data;
};

const getFeetModelPrivatePublications = async (id, offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
    `/v1/feet-models/${id}/publications?offset=${offset}&private=true`,
    {
      headers,
    }
  );

  return response;
};

const getFeetModelPublicPublications = async (id, offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
    `/v1/feet-models/${id}/publications?offset=${offset}&private=false`,
    {
      headers,
    }
  );

  return response;
};

const getFeetModelProfileById = async (id) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/feet-models/profile/${id}`, {
    headers,
  });

  return response.data;
};

const getFavoritePictiesByPublications = async () => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const response = await axiosInstance.get(
      "/v1/feet-models/favorite-picties",
      { headers }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const uploadCustomMediaFile = async (recipientId, messageId, files) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };
    // For array of url string to be saved to db
    const data = {
      urls: files,
      type: 'Custom-Media-Image',
      recipient: recipientId,
      messageId: messageId
    }
    

    // files &&
    //   files.length &&
    //   files.map((file) => data.append("custom-media", file));

    // const response = await axiosInstance.put(
    //   `/v1/feet-models/upload-custom-media/${messageId}`,
    //   data,
    //   {
    //     headers,
    //   }
    // );

    // For actual array of files to be saved to db
    // let data = new FormData();
    // files?.map((file) => data.append('image', file));
    // data.append('type', 'Custom-Media-Image');
    // data.append('recipient', recipientId);
    // data.append('messageId', messageId);
    
    
    const response = await axiosInstance.post('/v2/medias', data, { headers });
    return response;
  } catch (error) {
    throw error;
  }
};

const blockSubscription = async (data) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.put(
      `/v1/feet-models/block-subscription`,
      data,
      {
        headers,
      }
  );

  return response.data;
};

const FeetModelService = {
  getFeetModelsPublications,
  getFollowSuggestions,
  getSubscribeSuggestions,
  setupBank,
  searchFeetModelPublications,
  getOwnPublications,
  getFeetModelProfile,
  getFeetModelPrivatePublications,
  getFeetModelPublicPublications,
  getFeetModelProfileById,
  getFavoritePictiesByPublications,
  uploadCustomMediaFile,
  blockSubscription
};

export default FeetModelService;
