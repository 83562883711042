import { selector } from "recoil";

import likes from "../../atoms/notifications/likes";

const addLikes = selector({
  key: "addLikes",
  set: ({ set, get }, newLikes) => {
    set(likes, [...get(likes), newLikes]);
  },
});

export default addLikes;
