import React, { useEffect, useState } from "react";
import { ChevronLeft } from "@material-ui/icons";
import "./ticklesPayment.css";
import Visa from "../../../assets/images/subscription-payment/visa@2x.png";
import Master from "../../../assets/images/subscription-payment/mastercard@2x.png";
import AmExp from "../../../assets/images/subscription-payment/american-express@2x.png";
import { Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import TicklesImg from "../../../assets/images/tickles/Intersection 3@2x.png";
import { useTranslation } from "react-i18next";

import PaymentService from "../../../_services/payment.service";

const TicklesPayment = () => {
  let history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const [selectedTicklePrice, setSelectedTicklePrice] = useState({});
  const [paymentData, setPaymentData] = useState({
    type: "Tickles",
    paymentDetails: {},
  });
  const [paymentCardDetails, setPaymentCardDetails] = useState({
    rememberCard: false,
  });

  useEffect(() => {
    if (location.state?.selectedTicklePrice) {
      setSelectedTicklePrice(location.state.selectedTicklePrice);
      setPaymentData((prev) => ({
        ...prev,
        paymentDetails: { ...location.state.selectedTicklePrice },
      }));
    }
  }, [location]);

  const handleChangeCardDetails = (e) => {
    const { id, value } = e.target;
    setPaymentCardDetails((prevState) => ({
      ...prevState,
      [id]: value,
      ...(id === "rememberCard" && {
        rememberCard: !prevState.rememberCard,
      }),
    }));
  };

  const handlePay = async () => {
    try {
      const response = await PaymentService.sendPayment({
        ...paymentData,
        paymentCardDetails,
      });
      // !!TODO: Check the response if the payment is successful that's the time we
      // !!TODO: redirect the user to successful payment page

      history.push("/success-payment");
    } catch (error) {
      console.log("tickle payment error: ", error);
    }
  };

  return (
    <div className="TicklesPayment__container">
      <div className="TicklesPayment__header header header-height">
        <ChevronLeft />
        <h4>{t("subscriptionPaymentTitle")}</h4>
      </div>
      <div className="TicklesPayment__card">
        <div className="TicklesPayment__cardDate">
          <img src={TicklesImg} alt="tickles" />
          <b>
            {selectedTicklePrice.tickles} {t("buyTicklesTickles")}
          </b>
          <p className={"TicklesPaymentCardDate_Amount"}>
            €{selectedTicklePrice.amount}
          </p>
        </div>
      </div>
      <div className="TicklesPayment__cardImg">
        <img src={Visa} alt="visa" className="TicklesPayment__visa" />
        <img src={Master} alt="visa" className="TicklesPayment__master" />
        <img src={AmExp} alt="visa" className="TicklesPayment__amExp" />
      </div>
      <form className="TicklesPayment__form">
        <label>{t("nameTitleSignup")}</label>
        <input
          type="text"
          placeholder={t("nameTitleSignup")}
          id="name"
          onChange={handleChangeCardDetails}
        />
        <label>{t("cardNumberSubscriptionPayment")}</label>
        <input
          type="number"
          placeholder={t("cardNumberSubscriptionPayment")}
          onChange={handleChangeCardDetails}
          id="cardNumber"
        />
        <div className="TicklesPayment__expireDateLabel">
          <label>{t("expireDateCardSubscriptionPayment")}</label>
          <label>CVV</label>
        </div>
        <div className="TicklesPayment__expireDateInput">
          <input
            type="date"
            className="subsciptionPayment__date"
            onChange={handleChangeCardDetails}
            id="expirationDate"
          />
          <input
            type="number"
            placeholder="CVV"
            onChange={handleChangeCardDetails}
            id="cvv"
          />
        </div>
        <div className="TicklesPayment__checkbox">
          <input
            type="checkbox"
            onChange={handleChangeCardDetails}
            id="rememberCard"
            value={paymentCardDetails.rememberCard}
          />
          <p>{t("rememberCardSubscriptionPayment")}</p>
        </div>
      </form>
      <br />
      <div className="TicklesPayment__button">
        <p>{t("securedPaymentSubscriptionPayment")}</p>
        <Button
          className="TicklesPaymentButton__button"
          variant="contained"
          onClick={() => handlePay()}
        >
          {t("paySubscriptionPayment")}
        </Button>
      </div>
      <br />
      <br />
    </div>
  );
};

export default TicklesPayment;
