import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {StyledBadge} from "../NotificationsModels";

const dateTimeFormat = "DD/MM/YYYY H[h]mm";
const IdCheckElem = ({ elem, isInvisibleGeneralBadge }) => {
  const history = useHistory();
  const processIdCheck = (e) => {
    e.preventDefault();
    if (elem?.details?.message === "resubmit selfie to revalidate admin") {
      history.push("/identity-verification?step=3");
    }
  };
  const { t } = useTranslation();
  return (
    <div key={elem._id} className="marginIphone" onClick={processIdCheck}>
        <StyledBadge
            color="secondary"
            variant="dot"
            invisible={isInvisibleGeneralBadge || elem.readAt}
            overlap={"rectangular"}
        />
      <div className="user_details">
        {t("Id verification status")}
        <p style={{ margin: "0", fontSize: "0.75rem" }}>
          {t(elem.details.message)}
        </p>
      </div>
      <sub style={{ fontSize: "0.70rem" }}>
        {moment(elem.createdAt).format(dateTimeFormat)}
      </sub>
    </div>
  );
};

export default IdCheckElem;
