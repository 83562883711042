import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const postPrePublicationBase64File = async (data) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const response = await axiosInstance.post("/v1/pre-publications", data, {
      headers,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

const findPrePublicationBase64File = async (id) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const response = await axiosInstance.get(`/v1/pre-publications/${id}`, {
      headers,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const PrePublicationBase64Service = {
  postPrePublicationBase64File,
  findPrePublicationBase64File,
};

export default PrePublicationBase64Service;
