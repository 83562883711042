import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "25px",
    textAlign: "justify",
    marginBottom: "25px",
    "& .change-subscription-amount-button": {
      display: "flex",
      background: "linear-gradient(90deg, #F35162 20.03%, #FF5768 76.15%);",
      borderRadius: "15px",
      padding: "clamp(10px, 3vw, 16px) clamp(8px, 2vw, 14px)",
      height: "62px",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "clamp(12px, 4vw, 1.2rem)",
      color: "white",
      marginTop: "11px",
    },

    "& .change-button-arrow": {
      display: "flex",
      alignItems: "center",
      color: "white",

      "& .MuiButton-text": {
        fontSize: "clamp(12px, 4vw, 1.2rem)",
        textTransform: "none",
        color: "white",
        fontWeight: "400",
        lineHeight: "1.3",
      },
    },
  },
}));
