import React from "react";
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core/";
import { useHistory } from "react-router-dom";
import { clearCache } from "react-router-cache-route";
import { useRecoilState } from "recoil";
import { withStyles } from "@material-ui/core/styles";

import "./bottomNavigation.css";

import newMessageBadgeAtom from "../../../../stateManagement/atoms/newMessageBadgeAtom";
import useHandleClickChangeIcons from "../hooks/useHandleClickChangeIcons";
import { getAuthSession } from "../../../../helpers/auth.helpers";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const StyledBadge = withStyles(() => ({
  badge: {
    padding: "0 4px",
    borderRadius: "50%",
    width: "8px",
    lineHeight: "8px",
    height: "8px",
    bottom: "-5px !important",
  },
}))(Badge);

const BottomNavigationLoversComponent = () => {
  const {
    value,
    clickedHome,
    clickedSearch,
    clickedAccount,
    handleChange,
    handleClickHome,
    handleClickSearch,
    handleClickAccount,
    clickedMessages,
    clickedSubscriptions,
    handleClickSubscriptions,
    handleClickMessages,
  } = useHandleClickChangeIcons();
  let history = useHistory();

  const [newMessageBadge] = useRecoilState(newMessageBadgeAtom);

  return (
    <div className="bottomNavigation__content">
      <div className="bottomNavigation__innerContent">
        <BottomNavigation
          value={value}
          onChange={handleChange}
          className="bottomNavigation__root"
        >
          <BottomNavigationAction
            onClick={() => {
              clearCache();

              getAuthSession().user.userType === "Model"
                ? history.push("/home-page-models")
                : history.push("/home-page");

              handleClickHome();
            }}
            className={`${
              clickedHome === false
                ? "bottomNavigation__actions bottomNavigation__actions-first"
                : "bottomNavigation__actions bottomNavigation__actions-first-selected"
            }
           bottomNavigation__actions`}
            value="home"
            /* comment out first so if client want's it back just uncomment */
            // icon={newMessageBadge && <FiberManualRecordIcon />}
          />
          <BottomNavigationAction
            onClick={() => {
              handleClickSearch();
              history.push("/search-lover");
            }}
            className={`${
              clickedSearch === false
                ? "bottomNavigation__actions bottomNavigation__actions-second"
                : "bottomNavigation__actions bottomNavigation__actions-second-selected"
            }
           bottomNavigation__actions`}
            value="search"
          />
          <BottomNavigationAction
            onClick={() => {
              handleClickSubscriptions();
              history.push("/subscription-lover");
            }}
            className={`${
              clickedSubscriptions === false
                ? "bottomNavigation__actions-center"
                : "bottomNavigation__actions-center-selected"
            }
            bottomNavigation__actions `}
            value="cross"
          />

          <BottomNavigationAction
              onClick={() => {
                handleClickMessages();
                history.push("/messages");
              }}
              className={`${
                clickedMessages === false
                  ? "bottomNavigation__actions  bottomNavigation__message"
                  : "bottomNavigation__actions  bottomNavigation__message-selected"
              } bottomNavigation__actions`}
              value="message"
              icon={newMessageBadge && <FiberManualRecordIcon />}
          />

          <BottomNavigationAction
            onClick={() => {
              handleClickAccount();
              history.push("/feet-lover-profile");
            }}
            className={`${
              clickedAccount === false
                ? "bottomNavigation__actions  bottomNavigation__actions-last"
                : "bottomNavigation__actions  bottomNavigation__actions-last-selected"
            }  bottomNavigation__actions`}
            value="account"
          />
        </BottomNavigation>
      </div>
    </div>
  );
};

export default BottomNavigationLoversComponent;
