/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { Avatar, IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./styles.css";

import feetModelAtom from "../../../stateManagement/atoms/feetModelAtom";
import useInfiniteQuery from "../../../hooks/useInfiniteQuery";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import useSessionStorage from "../../forms/services/storageHooks/useSessionStorage";
import UserService from "../../../_services/user.service";
import moment from "moment";
import TicklesImgGris from "../../../assets/images__v3/Tickles Gris.png";
import ClockGris from "../../../assets/images__v3/Horloge parametre.png";
import TickGris from "../../../assets/images__v3/Certification_solid.png";
import TicklesReimburseGris from "../../../assets/images__v3/Remboursment.png";
import SearchIcon from "../../../assets/images__v3/Recherche_outline_2.png";
import _ from "lodash";
import genericAvatar from "../../../assets/images/generic-user-avatar.png";
import dateLocale from "../../../dateLocale";
import WarningIcon from "../../../assets/images__v3/Feety Warning Icon.png";

const CustomMediaTrackingList = (props) => {
  let history = useHistory();
  const { t } = useTranslation();

  const [authUser] = useSessionStorage({}, "infosUser");
  const [__, setFeetModel] = useRecoilState(feetModelAtom);

  const [refetch, setRefetch] = useState(0);
  const [searchQuery, setSearchQuery] = useState({});
  const [query, setQuery] = useState("");
  const [cmData, setCustomMedias] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    data,
    error,
    status,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    "custom-media-tracking-list-page",
    async ({ next_offset = 0 }) => {
      const response = await UserService.getCustomMedias(next_offset);

      return {
        data: response.data.messages,
        next_offset: response.data.next_offset,
      };
    },
    refetch
  );

  const loadMoreReference = useRef();

  useIntersectionObserver({
    target: loadMoreReference,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  const onChange = ({ target: { value } }) => {
    setLoading(true);
    setQuery(value);

    const search = _.debounce(sendQuery, 200);

    setSearchQuery((prevSearch) => {
      if (prevSearch.cancel) {
        prevSearch.cancel();
      }
      return search;
    });

    search(value.toLowerCase());
  };

  const sendQuery = useCallback(async (value) => {
    const results = await UserService.searchCustomMedias(value);

    if (!results) return;

    if (results) {
      setLoading(false);
      setCustomMedias(results.messages);
    } else {
      setLoading(false);
      setCustomMedias([]);
    }
  }, []);

  useEffect(() => {
    if (data && query.length === 0) setResultData(data);
  }, [data, query]);
  useEffect(() => {
    if (cmData && query.length > 0) setResultData(cmData);
  }, [cmData, query]);

  const handleRedirectProfile = (custom) => {
    if (authUser.user._id === custom.from._id) {
      history.push("/view-own-profile-models");
    } else {
      setFeetModel(custom.from);
      history.push({
        pathname: "/feet-models-view",
        search: `?user=${custom.from._id}`,
      });
    }
  };

  const handleRedirectBaseOnCMStatus = (customMedia) => {
    localStorage.setItem("customId", customMedia._id);
    history.push(`/custom-media-page/${customMedia._id}`);
  };

  const handleTimeLeft = (date) => {
    let result = "";
    const dateFormat = "YYYY-MM-DD HH:mm:ss";
    const now = moment().format(dateFormat);
    const end = moment(date.updatedAt).format(dateFormat);
    const hoursLeft = 72 - Math.round(moment(now).diff(end, "hours", true));
    let formattedNumber = hoursLeft.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })

    if (hoursLeft < 72) {
      if (hoursLeft <= 0) {
        result = `il te reste 00H...`;
      } else {
        result = `il te reste ${formattedNumber}H...`;
      }
    } else if (hoursLeft === 72) {
      result = "il te reste 72H...";
    }
    return result;
  };

  return (
    <div>
      <div className="header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => {
            history.goBack();
          }}
        >
          <ChevronLeft />
        </IconButton>
        <h4>{t("customizedMediaTracking")}</h4>
      </div>
      <div className="blockedAccounts__content content__withHeader minmax__wrapper">
        <div className="messageSearch__search">
          <div className="messageSearch__inputContainer searchInput__wrapper">
            <input
              className="messageSearch__input rounded_input"
              type="text"
              placeholder={t("searchDefaultViewTitle")}
              value={query}
              onChange={onChange}
            />
            <img id="searchIcon" src={SearchIcon} alt="search" />
          </div>
        </div>

        <div className="blocked_account_list">
          {loading ? (
            <div
              style={{
                position: "absolute",
                left: "calc(50% - 20px)",
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          ) : (
            resultData.map((custom) => (
              <div
                className="custom_item flex-center"
                key={custom._id}
                onClick={() => handleRedirectBaseOnCMStatus(custom)}
              >
                <div className="user_thumbnail_details">
                  {authUser.user.userType === "Lover" ? (
                    <div className="avatar">
                      <Avatar
                        className="blockedAccounts__avatar"
                        src={
                          custom && custom.from && custom.from.image
                            ? custom.from.image.url
                            : genericAvatar
                        }
                        alt="profile pict"
                        onClick={() => handleRedirectProfile(custom)}
                      />
                    </div>
                  ) : null}
                  <div className="user_details">
                    {authUser.user.userType === "Lover" ? (
                      <h2
                        className={custom.subscriber ? "subscriber" : ""}
                        style={{ width: "10%" }}
                        onClick={() => handleRedirectProfile(custom)}
                      >
                        {custom.from.username}
                      </h2>
                    ) : (
                      <h2
                        className={custom.subscriber ? "subscriber" : ""}
                        style={{ width: "10%" }}
                        onClick={() => handleRedirectProfile(custom)}
                      >
                        {custom.to.username}
                      </h2>
                    )}
                    {authUser.user.userType === "Lover" ? (
                      <p>{dateLocale.localize(custom.createdAt).fromNow()}</p>
                    ) : (
                      <p style={{ color: "#A9A9A9" }}>
                        <span>
                          <b>{custom?.tickles}</b>{" "}
                          <img
                            src={TicklesImgGris}
                            alt="tickles icon"
                            width="20"
                            height="20"
                            style={{ verticalAlign: "bottom" }}
                          />{" "}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
                {custom?.customMediaStatus === "pending" && !custom?.customMediaDetails && authUser.user.userType !== "Lover" ? (
                  <div
                    style={{
                      marginRight: "20px",
                      color: "#fff",
                      width: "60%",
                    }}
                  >
                    <p
                      style={{
                        padding: "2px 10px",
                        backgroundColor: "#FF5768",
                        borderRadius: "15px",
                      }}
                    >
                      {handleTimeLeft(custom)}
                    </p>
                  </div>
                ) : null}
                {custom?.customMediaStatus === "accepted" && !custom?.customMediaDetails && authUser.user.userType !== "Lover" ? (
                  <div
                    style={{
                      marginRight: "20px",
                      color: "#fff",
                      width: "60%",
                    }}
                  >
                    <p
                      style={{
                        padding: "2px 10px",
                        backgroundColor: "#FF5768",
                        borderRadius: "15px",
                      }}
                    >
                      {handleTimeLeft(custom)}
                    </p>
                  </div>
                ) : null}
                {custom?.customMediaStatus === "reset" && custom?.customMediaDetails && authUser.user.userType !== "Lover" ? (
                    <div
                        style={{
                          marginRight: "20px",
                          color: "#fff",
                          width: "60%",
                        }}
                    >
                      <p
                          style={{
                            padding: "2px 10px",
                            backgroundColor: "#FF5768",
                            borderRadius: "15px",
                          }}
                      >
                        {handleTimeLeft(custom)}
                      </p>
                    </div>
                ) : null}
                {custom?.customMediaStatus === "reset" && custom?.customMediaDetails && authUser.user.userType !== "Lover" ? (
                    <div
                        style={{
                          marginRight: "20px",
                          color: "#000",
                        }}
                    >
                      <sub
                          style={{
                            width: "40px",
                            textAlign: "right",
                          }}
                      >
                        Media to be sent
                      </sub>
                    </div>
                ) : null}
                {authUser.user.id !== custom.to.id ? (
                  <sub
                    style={{
                      fontSize: ".7rem",
                      width: "40px",
                      textAlign: "right",
                    }}
                  >
                    {custom?.customMediaStatus === "pending" ? (
                      <img
                        src={ClockGris}
                        alt="pending icon"
                        width="20"
                        height="20"
                        style={{ verticalAlign: "bottom" }}
                      />
                    ) : null}
                    {custom?.customMediaStatus === "accepted" ? (
                      <img
                        src={ClockGris}
                        alt="pending icon"
                        width="20"
                        height="20"
                        style={{ verticalAlign: "bottom" }}
                      />
                    ) : null}
                    {custom?.customMediaStatus === "uploaded" && custom?.customMediaDetails ? (
                      <img
                        src={TickGris}
                        alt="accepted/uploaded icon"
                        width="20"
                        height="20"
                        style={{ verticalAlign: "bottom" }}
                      />
                    ) : null}
                    {custom?.customMediaStatus === "reset" && custom?.customMediaDetails ? (
                        <img
                            src={WarningIcon}
                            alt="accepted/reset icon"
                            width="20"
                            height="20"
                            style={{ verticalAlign: "bottom" }}
                        />
                    ) : null}
                    {custom?.customMediaStatus === "rejected" ? (
                      <img
                        src={TicklesReimburseGris}
                        alt="reimburse icon"
                        width="20"
                        height="20"
                        style={{ verticalAlign: "bottom" }}
                      />
                    ) : null}
                  </sub>
                ) : null}
              </div>
            ))
          )}
          {/*use to get fill gaps and have even cells*/}
          <div className="user_thumbnail_details space_filler"></div>
          <div className="user_thumbnail_details space_filler"></div>
          <div className="user_thumbnail_details space_filler"></div>
        </div>
      </div>
      <div ref={loadMoreReference}>
        {isFetchingNextPage ? (
          <CircularProgress color="secondary" size={10} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CustomMediaTrackingList;
