import React, { Fragment } from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import cross from "../../../assets/images/unlock-publication/Group 54930@2x.png";
import star from "../../../assets/images/menu/certified.svg";
import genericUserIcon from "../../../assets/images/generic-user-avatar.png";
import TransactionService from "../../../_services/transaction.service";
import useNotificationAlert from "../../../hooks/useNotificationAlert";
import CheckSolidIcon from "../../../assets/images__v3/Certification_solid.png";

const SubscribeToUnlockPublicationModalContent = ({
  feetModelProfile,
  handleClose,
}) => {
  let history = useHistory();
  const { t } = useTranslation();

  const { addNotificationMessage } = useNotificationAlert();

  const roundPrice = (num, decimalPlaces = 2) => {
    const p = Math.pow(10, decimalPlaces);
    const n = num * p * (1 + Number.EPSILON);
    return Math.round(n) / p;
  };

  const subscriptionPrice = (num) => {
    let amount = 0;
    if(num === 10.83) {
      amount = 13;
    } else if (num === 20.83) {
      amount = 25;
    } else if (num === 40.83) {
      amount = 49;
    }
    return amount;
  }

  const checklistGuide = () => {
    const checklist = [
      {
        label: t("subscriptionDescription1"),
        icon: CheckSolidIcon,
      },
      {
        label: t("subscriptionDescription2"),
        icon: CheckSolidIcon,
      },
      {
        label: t("subscriptionDescription3"),
        icon: CheckSolidIcon,
      },
    ];
    const liMap = (data) => {
      return data?.map((item) => (
        <li key={item.label}>
          <img
            width="17.8"
            height="13.45"
            src={item.icon}
            alt="check-icon"
            className="check-icon"
          />
          {item.label}
        </li>
      ));
    };

    return <ul className="subscription-desc">{liMap(checklist)}</ul>;
  };

  const isRoleTypeBlogger = () => {
    if (feetModelProfile && feetModelProfile.profile) {
      if (feetModelProfile?.profile?.roleType) {
        return feetModelProfile?.profile?.roleType.includes("blogger");
      }
      return false;
    }
  };

  return (
    <Fragment>
      <div className="SubscribeToUnlockPublication__content">
        {/* <div className="SubscribeToUnlockPublication__header">
          <h2 id="simple-modal-title">{t("unlockPublicationLover")}</h2>
          <img
            src={cross}
            alt="close"
            onClick={handleClose}
            style={{
              cursor: "pointer",
            }}
          />
        </div> */}
        <div className="SubscribeToUnlockPublication__close">
          <img
            src={cross}
            alt="close"
            onClick={handleClose}
            style={{
              cursor: "pointer",
            }}
          />
        </div>
        <div className="SubscribeToUnlockPublication__body">
          <div className="SubscribeToUnlockPublication__top">
            <h4>{t("privateWallSubscription")}</h4>
          </div>
          <div className="SubscribeToUnlockPublication__center">
            <div className="SubscribeToUnlockPublication__imgContainer">
              <img
                className="SubscribeToUnlockPublication__pict"
                src={
                  feetModelProfile &&
                  feetModelProfile.profile &&
                  feetModelProfile.profile.image
                    ? feetModelProfile.profile.image.url
                    : genericUserIcon
                }
                alt="profile-pict"
              />
              {feetModelProfile &&
                  feetModelProfile.profile &&
                  feetModelProfile.profile.certified ? <img
                className="SubscribeToUnlockPublication__pictyPict"
                src={star}
                alt="star"
              /> : null}
            </div>
            <div className="SubscribeToUnlockPublication__name">
              <h4>
                {feetModelProfile &&
                  feetModelProfile.profile &&
                  feetModelProfile.profile.username}
              </h4>
            </div>
            <div className="SubscribeToUnlockPublication__description">
              {checklistGuide()}
            </div>
          </div>
          <div className="SubscribeToUnlockPublication__bottom">
            <div className="SubscribeToUnlockPublication__center-button">
              <Button
                className="SubscribeToUnlockPublication__button user-profile-view"
                onClick={async () => {
                  if (
                    feetModelProfile &&
                    feetModelProfile.profile &&
                    feetModelProfile.profile?.currentProduct?.amount &&
                    feetModelProfile.profile?.acceptSubscribers === false &&
                    !isRoleTypeBlogger()
                  ) {
                    const response = await TransactionService.subscribeToUser({
                      modelToSubscribe: feetModelProfile.profile._id,
                      return_url: `${process.env.REACT_APP_FRONTEND_URL}/feet-models-view?user=${feetModelProfile.profile._id}`,
                    });

                    if (response.status === 200) {
                      addNotificationMessage(
                        t("subscriptionPaymentGateway"),
                        "info"
                      );

                      // redirect to epoch payment gateway
                      window.location.href = response.data.url;
                    }
                  }
                }}
                disabled={feetModelProfile.profile?.acceptSubscribers ||
                    (feetModelProfile?.profile?.roleType && feetModelProfile?.profile?.roleType.includes("blogger"))}
              >
                {feetModelProfile &&
                feetModelProfile.profile &&
                feetModelProfile.profile?.currentProduct &&
                feetModelProfile.profile?.currentProduct?.amount &&
                feetModelProfile.profile?.acceptSubscribers === false &&
                !isRoleTypeBlogger() ? (
                  <>
                    <h4 className={"subscription-user-profile-view"}>
                      {subscriptionPrice(roundPrice(feetModelProfile.profile?.currentProduct?.amount))}€ TTC /{" "}
                      {t("monthUnlockPublicationLover")}
                    </h4>
                    <p>{t("withoutEngagement")}</p>
                  </>
                ) : (
                  <h4 className={"subscription-user-profile-view"}>
                    {t("subscriptionNotSet")}
                  </h4>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SubscribeToUnlockPublicationModalContent;
