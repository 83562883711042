import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const getNotificationBadge = async (type) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
    `/v1/notification-badge?type=${type}`,
    {
      headers,
    }
  );

  return response;
};

const deleteNotificationBadge = async (type) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.delete(
    `/v1/notification-badge?type=${type}`,
    {
      headers,
    }
  );

  return response.data;
};

const NotificationBadgeService = {
  getNotificationBadge,
  deleteNotificationBadge,
};

export default NotificationBadgeService;
