import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const viewUser = async (userId) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/views/user/${userId}`, {
    headers,
  });

  return response.data;
};

const getViews = async (offset = 0, userId = "") => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
    `/v1/views?offset=${offset}&user=${userId}`,
    {
      headers,
    }
  );

  return response;
};

const ViewService = { viewUser, getViews };

export default ViewService;
