import axios from "axios";

import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";
import { imagetoblob } from "../helpers";

const postPublication = async (image, body) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const data = new FormData();

  // need to convert the image to blob from base64.
  data.append("publication", imagetoblob(image));
  data.append("description", body.description);
  data.append("private", body.private);
  data.append("isFromCamera", body.isFromCamera);

  body.picties &&
    body.picties.length &&
    body.picties.map((picty) => data.append("picties", picty));

  const response = await axiosInstance.post("/v1/publications", data, {
    headers,
  });
  return response;
};

const picties = (body) => {
  return body.picties &&
      body.picties.length &&
      body.picties.map((picty) => picty);
}

const postPublicationBlobFile = async (file, body, fbResponse) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const data = {
      url: fbResponse,
      description: body.description,
      private: body.private,
      isFromCamera: body.isFromCamera,
      picties: picties(body)
    }
    //
    // const data = new FormData();
    // data.append("publication", file);
    // data.append("description", body.description);
    // data.append("private", body.private);
    // data.append("isFromCamera", body.isFromCamera);
    //

    const response = await axiosInstance.post("/v2/publications", data, {
      headers,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const postPublicationBase64File = async (file, body) => {
  try {
    const headers = {
      Authorization: `Bearer ${getAuthSession().user.access_token}`,
    };

    const data = new FormData();
    // need to convert the file to blob from base64.
    data.append("publication", imagetoblob(file));
    data.append("description", body.description);
    data.append("private", body.private);
    data.append("isFromCamera", body.isFromCamera);

    body.picties &&
      body.picties.length &&
      body.picties.map((picty) => data.append("picties", picty));

    const response = await axiosInstance.post("/v1/publications", data, {
      headers,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const getPublication = async (id) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const { data } = await axiosInstance.get(`/v1/publications/${id}`, {
    headers,
  });
  return data;
};

const getPublicationByFileName = async (filename) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const { data } = await axiosInstance.get(
    `/v1/publications/filename/${filename}`,
    {
      headers,
      responseType: "blob",
    }
  );
  return data;
};

const getPublicationMediaFromGoogleBucket = async (filename) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const { data } = await axiosInstance.get(
      `/v2/publications/renderFile/${filename}`,
      {
        headers,
        responseType: "blob",
      }
  );
  return data;
};

const getPublicationFromFileSystem = async (url) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const { data } = await axios.get(url, {
    headers,
    responseType: "blob",
  });

  return data;
};

const remove = async (id) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.delete(`/v1/publications/${id}`, {
    headers,
  });

  return response.data;
};

const getPublicationPicties = async (id) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const { data } = await axiosInstance.get(`/v1/publications/${id}/picties`, {
    headers,
  });
  return data;
};

const PublicationService = {
  postPublication,
  getPublication,
  getPublicationByFileName,
  getPublicationFromFileSystem,
  remove,
  getPublicationPicties,
  getPublicationMediaFromGoogleBucket,
  postPublicationBlobFile,
  postPublicationBase64File,
};

export default PublicationService;
