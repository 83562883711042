import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import InputLabel from "@material-ui/core/InputLabel";
import { useTranslation } from "react-i18next";
import { ChevronLeft, Visibility, VisibilityOff } from "@material-ui/icons";
import { IconButton, InputAdornment } from "@material-ui/core/";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";

import { useStyles } from "./styled";
import useNotificationAlert from "../../../hooks/useNotificationAlert";
import getUserQuery from "../../../stateManagement/selectors/getUserSelector";
import Button from "../../../components/Button";
import UserService from "../../../_services/user.service";
import useSessionStorage from "../../../components/forms/services/storageHooks/useSessionStorage";

function ChangePassword() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [authUser] = useSessionStorage({}, "infosUser");

  const { addNotificationMessage } = useNotificationAlert();
  const { user } = useRecoilValue(getUserQuery);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  // Formik parameters and Yup validation inputs
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string()
        .min(8, t("errPasswordShortSignin"))
        .required(t("errPasswordInvalidCurrentPassword")),
      newPassword: Yup.string()
        .min(8, t("errPasswordShortSignin"))
        .required(t("errNewPasswordRequired")),
      confirmNewPassword: Yup.string()
        .when("newPassword", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("newPassword")],
            t("errPasswordDonotMatch")
          ),
        })
        .min(8, t("errPasswordShortSignin"))
        .required(t("errConfirmNewPassword")),
    }),
    onSubmit: async (values) => {
      try {
        const response = await UserService.changePassword(
          authUser.user._id,
          values
        );
        addNotificationMessage("Password updated successfully", "success");
        history.push("/edit-profile");
      } catch (error) {
        addNotificationMessage(error.response.data.error, "error");
      }
    },
  });

  return (
    <div className={classes.root}>
      {/* todo: profile image */}
      <div className="profileForm__header header page_fixed_header edit-profile-header__v3">
        <IconButton
          className="back__button"
          onClick={() =>
            user.userType === "Lover"
              ? history.push("/settings-lovers")
              : history.push("/setting-models")
          }
        >
          <ChevronLeft style={{ fill: "black" }} />
        </IconButton>
        <h4>{t("changeYourPassword")}</h4>
      </div>
      <form
        className="formSignin__form"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <div>
          <InputLabel htmlFor="currentPassword" className={classes.label}>
            {t("currentPasswordTitle")}
          </InputLabel>
          <TextField
            id="currentPassword"
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            type={showPassword ? "text" : "password"}
            className={classes.textInput}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end" className="password-view">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.currentPassword && formik.touched.currentPassword ? (
            <p style={{ color: "red" }}>{formik.errors.currentPassword}</p>
          ) : null}
        </div>
        <div>
          <InputLabel htmlFor="newPassword" className={classes.label}>
            {t("newPasswordTitle")}
          </InputLabel>
          <TextField
            id="newPassword"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            type={showNewPassword ? "text" : "password"}
            className={classes.textInput}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end" className="password-view">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.newPassword && formik.touched.newPassword ? (
            <p style={{ color: "red" }}>{formik.errors.newPassword}</p>
          ) : null}
        </div>
        <div>
          <InputLabel htmlFor="confirmNewPassword" className={classes.label}>
            {t("newConfirmPasswordTitle")}
          </InputLabel>
          <TextField
            id="confirmNewPassword"
            value={formik.values.confirmNewPassword}
            onChange={formik.handleChange}
            type={showConfirmPassword ? "text" : "password"}
            className={classes.textInput}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end" className="password-view">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.confirmNewPassword &&
          formik.touched.confirmNewPassword ? (
            <p style={{ color: "red" }}>{formik.errors.confirmNewPassword}</p>
          ) : null}
        </div>
        <br />
        <Button
          type="submit"
          width="clamp(100%, 4vw, 24.5rem)"
          background="#F35162"
          height="clamp(42px, 4vw, 52px)"
        >
          {t("Save")}
        </Button>
      </form>
    </div>
  );
}

export default ChangePassword;
