import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    background: "#E7E7E7",
    padding: "70px 16px 0 16px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginBottom: "20px",
  },
  label: {
    fontSize: "clamp(1rem, 4vw, 1rem)",
    color: "#000000",
    marginBottom: "8px",
    marginLeft: "8px",
  },
  bio: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  textInput: {
    width: "100%",

    "& .MuiInputBase-input": {
      height: "45px",
      borderRadius: "15px",
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
  },

  select: {
    height: "45px",
    color: "#545454",
    borderRadius: "15px",
  },
  textArea: {
    width: "100%",
    minHeight: "108px",
    "& .MuiFilledInput-multiline": {
      padding: "10px 15px",
      backgroundColor: "white",
      borderRadius: "clamp(15px, 4vw, 25px)",
    },

    "& .MuiFilledInput-underline:before": {
      borderBottom: 0,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: 0,
    },
  },
  datePicker: {
    "& .react-datepicker__input-container": {
      width: "40% !important",
      height: "76px",
      "& .custom-date-picker": {
        display: "flex",

        "& input[type=text]": {
          backgroundColor: "#D7D7D7",
          borderRadius: "12px 0 0 12px",
        },

        "& .custom-date-picker-icon": {
          display: "flex",
          alignItems: "center",
          borderTopRightRadius: "12px",
          borderBottomRightRadius: "12px",
          backgroundColor: "#636363",
        },
      },
    },
  },

  materialUiDatePicker: {
    "& .MuiInput-root": {
      backgroundColor: "#636363",
      borderRadius: "12px",
      width: "128px",
    },

    "& input[type=text]": {
      padding: "0 0 0 10px",
      backgroundColor: "#D7D7D7",
      borderRadius: "11px 0 0 11px",
    },

    "& .MuiInputAdornment-positionEnd": {
      marginLeft: "0px",
    },

    "& .MuiIconButton-root": {
      padding: 0,
    },

    "& .MuiFormControl-marginNormal": {
      marginTop: 0,
    },
  },
  editProfileMoreSettingsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  editProfileSettingsButtonRounded: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "clamp(15px, 4vw, 25px)",
    padding: "8px 13px",

    "& .MuiButton-text": {
      fontSize: "clamp(0.875rem, 4vw, 1rem)",
      textTransform: "none",
    },
  },
}));
