import { Dialog, DialogContent, Divider } from "@material-ui/core";
import React from "react";
import "./styles.css";
import DialogTitle from "../../../../../BuyTickleModal/DialogTitle";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../../../pages/SuppressAccount/styled";
import FeetLoverService from "../../../../../../_services/feet-lovers.service";
import UserService from "../../../../../../_services/user.service";
import MessageIcon from "../../../../../../assets/images__v3/Media Personnalise.png";
import PropTypes from "prop-types";

function RequestHasTicklesCustomMedias(props) {
  const {
    openModal,
    setOpenModal,
    authUser,
    user,
    setArrayMessagesList,
    addNotificationMessage,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const handleSendRequest = async () => {
    setOpenModal(false);
    try {
      const userData = await UserService.getUser(authUser.user?._id);
      const result = await FeetLoverService.requestCustomMedia(
        authUser.user?._id,
        {
          model: user._id,
        }
      );
      if (result?.status === 200) {
        setArrayMessagesList((prev) =>
          prev.concat({
            from: userData.user,
            to: user,
            type: "Custom-Media-Request",
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          })
        );
      }
    } catch (err) {
      addNotificationMessage(
        `${err.response.data.error || err.response.error || err.message}`,
        "error"
      );
    }
  };
  const handleCloseModal = () => setOpenModal(false);
  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      className={classes.suppressDialog}
    >
      <DialogTitle className="RequestCM__header">
        <h2 id="request-cm-has-tickles">{t("acceptCMTitle")}</h2>
        <img src={MessageIcon} alt="message icon" id="message-icon" />
      </DialogTitle>
      <DialogContent>
        <p>
          {t("acceptCMDesc1")}{" "}
          <span style={{ color: "#ff2775", fontWeight: "bold" }}>
            {t("acceptCMDesc2")}
          </span>{" "}
          {t("acceptCMDesc3")}
        </p>
      </DialogContent>
      <Divider />
      <div className={classes.suppressModalButtonsContainer}>
        <button
          type="button"
          onClick={handleCloseModal}
          style={{ color: "#000000" }}
        >
          {t("sendTicklesCancelButton")}
        </button>

        <button type="button" onClick={handleSendRequest}>
          {t("confirm")}
        </button>
      </div>
    </Dialog>
  );
}

RequestHasTicklesCustomMedias.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.any.isRequired,
  authUser: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  setArrayMessagesList: PropTypes.any.isRequired,
  addNotificationMessage: PropTypes.any.isRequired,
};

export default RequestHasTicklesCustomMedias;
