/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  SocketEventContext,
  useSocketEventContext,
} from "../../../../../context/socketEvent";
import {
  convertModelsTickles,
  convertTickles,
} from "../../../../../common/ConvertTickles";

import AcceptCustomMedias from "../modals/acceptCustomMedias";
import BuyTickleModal from "../../../../BuyTickleModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import FeetModelService from "../../../../../_services/feet-models.service";
import FetchFileWithAuthentication from "../../../../FetchFileWithAuthentication";
import { IconButton } from "@material-ui/core";
import { MessageInfo } from "./Message";
import MessageService from "../../../../../_services/message.service";
import NoMessagesYetComponent from "./messagesCenterComponents/NoMessagesYetComponent";
import Picture from "../../../../../assets/images__v3/Planche_boutons-55.png";
import { SocketContext } from "../../../../../context/socket";
import TickleService from "../../../../../_services/tickle.service";
import TicklesImg from "../../../../../assets/images__v3/Tickles.png";
import TicklesImgGris from "../../../../../assets/images__v3/Tickles Gris.png";
import UserService from "../../../../../_services/user.service";
import { findIndex as _findIndex } from "lodash";
import { handleImageCompression } from "../../../../../helpers/browser-image-compression";
import receiveMediasMessageToUserAtom from "../../../../../stateManagement/atoms/receiveMediasMessageToUserAtom";
import update from "immutability-helper";
import { useDropzone } from "react-dropzone";
import useInfiniteQuery from "../../../../../hooks/useInfiniteQuery";
import useIntersectionObserver from "../../../../../hooks/useIntersectionObserver";
import useMessageOrSpeech from "../hooks/useMessageOrSpeech";
import useNotificationAlert from "../../../../../hooks/useNotificationAlert";
import { useRecoilState } from "recoil";
import arrayMessagesListAtom from "../../../../../stateManagement/atoms/arrayMessagesListAtom";
import {useHistory} from "react-router-dom";
import nanoMetadata from "nano-metadata";
import {uploadMultipleMedias} from "../../../../../_services/firebase.service";
import CommonAlertModal from "../../../../Modals/AlertModals/CommonAlertModal";

const MessageContentCenter = (props) => {
  const {
    sendedTickles,
    picturesMessage,
    setPicturesMessage,
    clickedStop,
    speechToText,
    MessagePict,
    PictureModal,
    reSendHeart,
    sendMess,
    sendedMess,
    fileToLocalStorage,
    sendedHeart,
    fakeReceivedTickles,
    NoMess,
    t,
    userType,
    authUser,
    convoId,
    user,
    numberTickles,
    EraseFakeTickles,
    ticklesLogo,
    setCountMessages,
    showVideoModal,
    NewMessagesList,
    isMessageSent,
    handleSendCMNotif,
    epochProducts
  } = props;

  let history = useHistory();
  // get new message from global socket event context
  const { newMessage } = useSocketEventContext(SocketEventContext);

  const socket = useContext(SocketContext);
  // const { ArrayMessagesList, setArrayMessagesList } = useMessageOrSpeech();
  const [ArrayMessagesList, setArrayMessagesList] = useRecoilState(
    arrayMessagesListAtom
  );
  const [isReceiveMediaToUser] = useRecoilState(receiveMediasMessageToUserAtom);
  const now = new Date();
  const [loaded, setLoaded] = useState(false);
  const [isReadyToFetchPreviousPage, setIsReadyToFetchPreviousPage] =
    useState(false);
  const [openBuyTickleModal, setOpenBuyTickleModal] = useState(false);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [openCommonAlertModal, setOpenCommonAlertModal] = useState(false);

  const [currentMessage, setCurrentMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [cmResponse, setCMResponse] = useState(null);
  const [cmImage, setCMImage] = useState(null);
  const [progress, setProgress] = useState(0);

  const { addNotificationMessage } = useNotificationAlert();

  let lastMessText = sendMess[sendMess.length - 1];
  let lastMessImg = picturesMessage[picturesMessage.length - 1];
  const [newMessageReceived, setNewMessageReceived] = useState(null);
  const [epochProductLists, setEpochProductLists] = useState([]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const {
    data,
    error,
    status,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    fetchPreviousPage,
    hasPrevPage,
    isFetchingPreviousPage,
    isDataFromPreviousPage,
    setIsDataFromPreviousPage,
    setData,
  } = useInfiniteQuery("private-conversations", async ({ next_offset = 0 }) => {
    const response = await MessageService.getPrivateMessages(
      {
        ...(convoId
          ? {
              convoId,
            }
          : {
              user: user._id,
            }),
      },
      next_offset
    );

    return {
      data: response.data.conversation,
      next_offset: response.data.next_offset,
      prev_offset: response.data.prev_offset,
    };
  });

  const loadMoreMessagesReference = useRef();

  useIntersectionObserver({
    target: loadMoreMessagesReference,
    onIntersect: fetchPreviousPage,
    enabled: hasPrevPage && isReadyToFetchPreviousPage,
  });

  useIntersectionObserver({
    target: messagesEndRef,
    onIntersect: () => {
      setIsReadyToFetchPreviousPage(true);
    },
    enabled: hasPrevPage,
  });

  const [activeCustomMediaMessage, setActiveCustomMediaMessage] =
    useState(null);
  const [files, setFiles] = useState([]);
  const [acceptFiles, setAcceptFiles] = useState([]);
  const [urls, setURLs] = useState([]);
  const { getInputProps, getRootProps } = useDropzone({
    noDrag: true,
    id: "file",
    type: "file",
    onDrop: async (acceptedFiles) => {
      // const compressedFiles = await Promise.all(
      //   acceptedFiles.map(async (file) => await handleImageCompression(file))
      // );
      // To check if the custom media is a video or image then if it's a video it will validate the video length must not exceed to 5 mins
      setAcceptFiles(acceptedFiles);
      setIsUploading((isUploading) => !isUploading);
      handleValidatingVideo(acceptedFiles);
    },
  });

  const handleValidatingVideo = (acceptedFiles) => {
    const filesWithDuration = acceptedFiles.map((file) => {
      if (file && (file.type.includes("image"))) {
        return new Promise((resolve, reject) => {
          resolve({duration: null, files: file})
        })
      } else {
        return new Promise((resolve, reject) => {
          // nanoMetadata.video.duration(file).then((duration) => {
          //   console.log(duration) // will show you video duration in seconds
            resolve({duration: null, files: file})
          // })
          // setTimeout(reject, 1000);
        })
      }
    })
    Promise.all(filesWithDuration).then(async (results) => {
      let proceed = false;
      let approvedFile = [];
      console.log("duration: ", results)
      results.map((file) => {
          if (file && (file.files.type.includes("image") || (file.files.type.includes("video")))) {
            proceed = true;
            approvedFile.push(file);
            // addNotificationMessage("Uploaded Successfully!", "info");
          } else {
            addNotificationMessage(t("ppUploadingValidateCMError"), "error");
            setIsUploading(false);
            setURLs([]);
            approvedFile = [];
            proceed = false;
          }
      })
      if (proceed === true && approvedFile.length === acceptedFiles.length) {
        console.log("cm_progress: ", progress)
        await uploadMultipleMedias(approvedFile, setProgress, uploadWithFirebase, 'message')
      }
    }).catch((e) => {
      console.log("Upload CM Error: Video has a bad format!", e)
      setIsUploading(false);
      setURLs([]);
      addNotificationMessage(t("ppUploadingBadFormatError"), "error");
    })
  }

  const uploadWithFirebase = async (responseUrl) => {
    console.log("messageCenter_responseUrl: ", responseUrl)
    if (responseUrl && responseUrl.length > 0) {
      responseUrl.map((result_url) => {
        if (result_url.error === false) {
          setURLs((url) => [...url, result_url.formatted_path])
        } else {
          addNotificationMessage(`${t("ppUploadingValidateCMError")} Filename: ${result_url.formatted_path}`, "error");
          setIsUploading(false);
        }
      })
    }
  }

  useEffect(() => {
    if ((urls.length > 0 && acceptFiles.length > 0)) {
      updateCM(true, urls).then()
    }
  }, [urls, acceptFiles])

  const updateCM = async (success, url) => {
    if (success) {
    const uploadCustomMediaResponse =
        await FeetModelService.uploadCustomMediaFile(
            activeCustomMediaMessage.to._id,
            activeCustomMediaMessage?.messageId || activeCustomMediaMessage?._id,
            url
        ).catch(() => setIsUploading((isUploading) => !isUploading));

    setCMResponse(uploadCustomMediaResponse.data?.data);

    // setup preview.
    // setFiles(
    //     acceptFiles.map(
    //         (file) =>
    //             Object.assign(file, {
    //               messageId:
    //                   activeCustomMediaMessage?.messageId ||
    //                   activeCustomMediaMessage?._id,
    //               ...(!file.preview && {
    //                 preview: uploadCustomMediaResponse.data?.data?.map(
    //                     (file) => file.content
    //                 ),
    //               }),
    //             }) // uploadCustomMediaResponse.data.previewUrl
    //     )
    // );
      setIsUploading(false);
      setURLs([]);
    } else {
      setIsUploading(false);
      setURLs([])
    }
  }


  // watch for data changes after fetching next/previous page.
  useEffect(() => {
    if (data && data.length) {
      setArrayMessagesList(data);
    }
    setLoaded(true);
  }, [data, setArrayMessagesList, setLoaded]);

  // watch for data changes after fetching next/previous page.
  useEffect(() => {
    if (NewMessagesList && (isMessageSent || sendMess || sendedHeart)) {
      setData(NewMessagesList);
      setArrayMessagesList(NewMessagesList);
    }

    setLoaded(true);
  }, [isMessageSent, NewMessagesList, setLoaded, sendMess, sendedHeart, setArrayMessagesList]);

  // watch local state of array messages list; scroll to bottom if data is not coming from previous page.
  useEffect(() => {
    setCountMessages(ArrayMessagesList.length);
    if (isDataFromPreviousPage === false && ArrayMessagesList.length) {
      scrollToBottom();
    } else {
      setIsDataFromPreviousPage(false);
    }
  }, [ArrayMessagesList]);

  useEffect(() => {
    return () => {
      // clean up and prevent cant update error on unmount
      setArrayMessagesList([]);
    };
  }, [setArrayMessagesList]);

  useEffect(() => {
    if (cmResponse) {
      let newMsg = ArrayMessagesList.find(
        (msg) => (msg.messageId || msg._id) === cmResponse?.[0].parent
      );
      if (newMsg) {
        const currIndx = _findIndex(
          ArrayMessagesList,
          (message) =>
            (message?.messageId || message._id) === cmResponse?.[0].parent
        );

        const oldImages = newMsg.images

        newMsg = {
          ...newMsg,
          images: newMsg.images
            ? newMsg.images?.concat(cmResponse)
            : cmResponse.map(images => ({...images})),
        };

        const updatedList = update(ArrayMessagesList, {
          [currIndx]: { $set: newMsg },
        });
        setArrayMessagesList(updatedList);
        setData(updatedList);        

        setTimeout(() => {
          if(!oldImages || oldImages.length < 1 && newMsg.images.length >= 1) {
            handleSendCMNotif(user)
          }
        }, 300);
      }
    }

    return () => {
      setCMResponse(null);
    };
  }, [ArrayMessagesList, cmResponse, setArrayMessagesList, setData]);

  /* 
    listen for new message. 
    This will execute every time there is a new message.
  */
  useEffect(() => {
    setNewMessageReceived(newMessage);
    // todo: clean up newMessage here???
  }, [newMessage]);

  useEffect(() => {
    let updatedMessagesList = [];
    // only if newMessage matches the convoid
    if (newMessageReceived && newMessageReceived._id === convoId) {
      if (newMessageReceived && newMessageReceived.tickles && newMessageReceived.tickleId) {
        TickleService.updateTickle(newMessageReceived.tickleId, { status: "pending" })
          .then((response) => response)
          .catch((error) => error);
      }

      // tag/set message as read
      MessageService.setAsReadMessage({ messageId: newMessageReceived.messageId })
        .then((response) => response)
        .catch((error) => error);

      // find index to check if the new message already exists in the global messages list.
      const currentIndexMessage = _findIndex(
        ArrayMessagesList,
        (message) =>
          (message?.messageId || message._id) === (newMessageReceived?.parent || newMessageReceived?.messageId || newMessageReceived?._id)
      );

      // new message does not exist yet in the global messages list. Append the new message
      if (currentIndexMessage < 0) {
        setArrayMessagesList((prev) => [
          ...prev,
          {
            ...newMessageReceived,
            ...(newMessageReceived.type === "Tickles" && {
              tickles: parseInt(newMessageReceived.tickles),
            }),
          },
        ]);
      } else {
        // new message already exist in the global messages list, most likely for custom-media. Update message from the global list of messages.
        if(ArrayMessagesList[currentIndexMessage]?.type === "Custom-Media" && ArrayMessagesList[currentIndexMessage]?.customMediaStatus !== 'accepted') {
          updatedMessagesList = update(ArrayMessagesList, {
            [currentIndexMessage]: { $set: newMessageReceived },
          });
          setArrayMessagesList(updatedMessagesList);
          setData(updatedMessagesList);
        }

        if(newMessageReceived.type === "Custom-Media-Image" && newMessageReceived.from && newMessageReceived.from._id !== authUser.user._id) {
          if(!cmImage?.includes(newMessageReceived?.content)) {
            let newMsg = ArrayMessagesList.find(
              (msg) => (msg.messageId || msg._id) === newMessageReceived?.parent
            );
            if (newMsg) {
              const cmIndex = _findIndex(
                ArrayMessagesList,
                (message) =>
                (message.messageId || message._id) === newMessageReceived?.parent
              );
  
              newMsg = {
                ...newMsg,
                images: newMsg.images
                ? newMsg.images?.concat(newMessageReceived)
                : [newMessageReceived],
              };
    
              updatedMessagesList = update(ArrayMessagesList, {
                [cmIndex]: { $set: newMsg },
              });
            }
            setCMImage([newMessageReceived.content]);
            setArrayMessagesList(updatedMessagesList);
            setData(updatedMessagesList);
          }
        }
      }
    }
  }, [newMessageReceived, convoId, ArrayMessagesList, setData, setArrayMessagesList])

  useEffect(() => {
    if (openBuyTickleModal === true && epochProducts) {
      setEpochProductLists(epochProducts)
    }
  }, [openBuyTickleModal, epochProducts]);

  const handleAcceptProposal = (message) => {
    return (
      <AcceptCustomMedias
        openModal={openAcceptModal}
        setOpenModal={setOpenAcceptModal}
        authUser={authUser}
        message={message}
        addNotificationMessage={addNotificationMessage}
      />
    );
  };

  return (
    <div>
      {loaded && (
        <Fragment>
          {ArrayMessagesList.length === 0 && (
            <NoMessagesYetComponent
              fakeReceivedTickles={fakeReceivedTickles}
              NoMess={NoMess}
              t={t}
            />
          )}

          {isReadyToFetchPreviousPage && (
            <div
              className="load-more-private-conversation-reference"
              ref={loadMoreMessagesReference}
            >
              {isFetchingPreviousPage ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress color="secondary" size={10} />
                </div>
              ) : (
                ""
              )}
            </div>
          )}

          {ArrayMessagesList && (
            <div className="messagesContainer">
              {ArrayMessagesList.map((message, i) => (
                <React.Fragment key={i}>
                  {message && message?.type === "Custom-Media" && message.content !== "custom media ready" && (
                    <div
                      style={{
                        backgroundColor: "#E7E7E7",
                        textAlign: "center",
                        padding: "10px 30px",
                        borderRadius: "15px",
                        margin: "10px",
                      }}
                    >
                      {authUser?.user?.userType === "Lover" ? (
                        <>
                          {message?.customMediaStatus === "rejected" ? (
                            <p style={{ color: "#A9A9A9" }}>
                              {message?.from?.username} {t("proposalCMLover")}{" "}
                              <del>
                                <b>{message?.tickles}</b>{" "}
                              </del>
                              <img
                                src={TicklesImgGris}
                                alt="tickles icon"
                                width="20"
                                height="20"
                                style={{ verticalAlign: "bottom" }}
                              />
                            </p>
                          ) : (
                            <p>
                              {message?.from?.username} {t("proposalCMLover")}{" "}
                              <b style={{ color: "blue" }}>
                                {message?.tickles}
                              </b>{" "}
                              <img
                                src={TicklesImg}
                                alt="tickles icon"
                                width="20"
                                height="20"
                                style={{ verticalAlign: "bottom" }}
                              />
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          {message?.customMediaStatus === "rejected" ? (
                            <p style={{ color: "#A9A9A9" }}>
                              {t("proposalCMModel")}
                              <del>
                                <b>{message?.tickles}</b>{" "}
                                <img
                                  src={TicklesImgGris}
                                  alt="tickles icon"
                                  width="20"
                                  height="20"
                                  style={{ verticalAlign: "bottom" }}
                                />{" "}
                              </del>
                            </p>
                          ) : (
                            <p>
                              {t("proposalCMModel")}{" "}
                              <b style={{ color: "blue" }}>
                                {message?.tickles}
                              </b>{" "}
                              <img
                                src={TicklesImg}
                                alt="tickles icon"
                                width="20"
                                height="20"
                                style={{ verticalAlign: "bottom" }}
                              />{" "}
                            </p>
                          )}
                        </>
                      )}

                      {/* for media upload. */}
                      <div>
                        {message?.customMediaStatus === "accepted" || message?.customMediaStatus === "uploaded" || message?.customMediaStatus === "reset" ? (
                          <>
                            {/* upload custom media feature for feet model only */}
                            {authUser?.user?.userType === "Model" && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <span
                                  {...getRootProps({
                                    className: "dropzone__messagePicture",
                                  })}
                                  style={{ outline: "none" }}
                                >
                                  {!isUploading ? (
                                    <>
                                      <input {...getInputProps()} disabled={!authUser?.user?.idCheckDetails?.verified} />
                                      <IconButton
                                        onClick={() => {
                                          if (!authUser?.user?.idCheckDetails?.verified)
                                            history.push("/identity-verification");
                                          else
                                            setActiveCustomMediaMessage(message);
                                        }}
                                      >
                                        {message?.images &&
                                        message?.images.length === 0 ? (
                                          <div className="blinking delay">
                                            <img
                                              src={Picture}
                                              alt="pict Icon"
                                              width="60px"
                                              height="60px"
                                            />
                                            <p style={{ fontSize: "0.5em" }}>
                                              &nbsp;
                                              {t("acceptCMAcceptedUpload1")}
                                              <br />
                                              {t("acceptCMAcceptedUpload2")}
                                            </p>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <img
                                              src={Picture}
                                              alt="pict Icon"
                                              width="60px"
                                              height="60px"
                                            />
                                            <p style={{ fontSize: "0.5em" }}>
                                              &nbsp;
                                              {t("acceptCMAcceptedUpload1")}
                                              <br />
                                              {t("acceptCMAcceptedUpload2")}
                                            </p>
                                          </div>
                                        )}
                                      </IconButton>
                                    </>
                                  ) : (
                                    <CircularProgress
                                      color="secondary"
                                      size={50}
                                    />
                                  )}
                                </span>
                              </div>
                            )}

                            {/* custom medias thumbnails preview */}
                            {message?.images && message?.images.length ? (
                              <>
                                <p>{t("acceptCMAcceptedUpload3")}</p>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "10px",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {message?.images.map((file, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={"custom-media-wrapper"}
                                      >
                                        <FetchFileWithAuthentication
                                          alt="custom-media"
                                          className=""
                                          messageFsUrl={file.content}
                                          width="50px"
                                          height="50px"
                                          type={file.customMediaType}
                                          status={file.status}
                                          style={{ objectFit: "cover" }}
                                          showVideoModal={showVideoModal}
                                          authUser={authUser}
                                          images={message.images}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      {message?.customMediaStatus === "accepted" || message?.customMediaStatus === "uploaded" || message?.customMediaStatus === "reset" ? (
                        <>
                          {" "}
                          {authUser?.user?.userType === "Lover" ? (
                            <p>{t("acceptCMStatusAccept")}</p>
                          ) : (
                            <p>
                              {message?.to?.username}{" "}
                              {t("acceptCMLoverAccepted")}
                              <br />
                              {t("acceptCMStatusMessage1")}&nbsp;
                              <a
                                style={{ color: "#f72c3f" }}
                                href={"/tickles-account"}
                              >
                                {t("acceptCMStatusMessage2")}
                              </a>{" "}
                              {t("acceptCMStatusMessage3")}
                              <br />
                            </p>
                          )}
                          <p>
                            {`${t("acceptCMStatusMessage4")} `}
                            <a
                              style={{ color: "#f72c3f" }}
                              href={"/custom-media-tracking"}
                            >
                              {t("acceptCMStatusMessage5")}
                            </a>{" "}
                            {t("acceptCMStatusMessage6")}
                          </p>
                        </>
                      ) : message?.customMediaStatus === "rejected" ? (
                        <>
                          {" "}
                          {authUser?.user?.userType === "Lover" ? (
                            <p>{t("acceptCMStatusRefuse")}</p>
                          ) : (
                            <p>{`${message?.to?.username} ${t(
                              "acceptCMRefused"
                            )}`}</p>
                          )}
                        </>
                      ) : (
                        <>
                          {authUser?.user?.userType === "Lover" ? (
                            <div>
                              {!loading ? (
                                <div>
                                  <button
                                    style={{
                                      background: "none",
                                      border: "none",
                                      padding: "0",
                                      color: "gray",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={async () => {
                                      setLoading((loading) => !loading);
                                      await UserService.acceptOrRejectProposedCustomMedia(
                                        authUser.user._id,
                                        {
                                          action: "reject",
                                          messageId:
                                            message?.messageId || message?._id,
                                        }
                                      );
                                      setLoading((loading) => !loading);
                                    }}
                                  >
                                    {t("actionRefuse")}
                                  </button>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <button
                                    style={{
                                      background: "none",
                                      border: "none",
                                      padding: "0",
                                      color: "#f72c3f",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={async () => {
                                      setLoading((loading) => !loading);
                                      setCurrentMessage(message);
                                      const userData =
                                        await UserService.getUser(
                                          authUser.user?._id
                                        );
                                      const toUserData =
                                          await UserService.getUser(
                                              message?.from?._id
                                          );
                                      if (toUserData.user && (!toUserData.user?.suppress && !toUserData.user?.accountSoftDeleted)) {
                                        if (
                                            userData.user &&
                                            (userData.user.tickles === 0 ||
                                                userData.user.tickles <
                                                message.tickles ||
                                                !userData.user.tickles)
                                        ) {
                                          setOpenBuyTickleModal(true);
                                        } else {
                                          setOpenAcceptModal(true);
                                        }
                                      } else {setOpenCommonAlertModal(true);}
                                      setLoading((loading) => !loading);
                                    }}
                                  >
                                    {t("acceptsAction")}
                                  </button>
                                </div>
                              ) : (
                                <CircularProgress color="secondary" size={20} />
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  )} 
                  
                  {message && message?.type !== "Custom-Media" && message.content !== "custom media ready" && (
                    <div
                      key={i}
                      className={
                        ArrayMessagesList &&
                        message?.from?._id !== authUser.user._id
                          ? "MessagesList_UsersLeft"
                          : "MessagesList_UsersRight"
                      }
                    >
                      <MessageInfo
                        authUser={authUser}
                        message={message}
                        key={i}
                        scrollToBottom={scrollToBottom}
                        convertTickles={convertTickles}
                        convertModelsTickles={convertModelsTickles}
                        showVideoModal={showVideoModal}
                      />
                    </div>
                  )}

                  {message && message.content === "custom media ready" && (
                    <div
                      key={i}
                    >
                      <div className="messageTextSection">
                        <div className="cm-message-notification">
                          <p>{message.from._id === authUser.user._id ? t("cmModelMessageNotification") : t("cmLoverMessageNotification")}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
          <div id="end-of-message-reference" ref={messagesEndRef} />
        </Fragment>
      )}
      {!loaded && <CircularProgress color="inherit" />}
      {handleAcceptProposal(currentMessage)}
      {/* buy tickle modal */}
      <BuyTickleModal
        openBuyTickleModal={openBuyTickleModal}
        setOpenBuyTickleModal={setOpenBuyTickleModal}
        epochPrices={epochProductLists}
      />
      {/* validation alert modal*/}
      <CommonAlertModal
          title={"Operation Failed!"}
          subTitle={"Model is currently inactive or deactivated."}
          lists={"Thank you."}
          setHelperOpen={setOpenCommonAlertModal}
          open={openCommonAlertModal}
      />
    </div>
  );
};

export default MessageContentCenter;
