import React from "react";
import ModalContent from "../../loversComponents/SubscribeToUnlockPublication/SubscribeToUnlockPublicationModalContent";
import Modal from "@material-ui/core/Modal";

function getModalStyle() {
  const top = 47;
  const left = 48;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    border: "none !important",
  };
}

const UserViewModal = (props) => {
  const { feetModelProfile, open, handleClose, userType, setUserType } = props;

  const [modalStyle] = React.useState(getModalStyle);

  const modalBody = () => {
    return (
      <div
        style={modalStyle}
        className="SubscribeToUnlockPublication__paper modal-Subscribe"
      >
        <ModalContent
          userType={userType}
          setUserType={setUserType}
          feetModelProfile={feetModelProfile}
          handleClose={handleClose}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        className={"SubscribeToUnlockPublication__UserView"}
        onClose={handleClose}
        aria-labelledby="SubscribeToUnlockPublication-simple-modal-title"
        aria-describedby="SubscribeToUnlockPublication-simple-modal-description"
      >
        {modalBody()}
      </Modal>
    </React.Fragment>
  );
};

export default UserViewModal;
