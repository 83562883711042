import { Dialog, DialogContent, DialogTitle, Divider } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

import EpochPrices from "../../../common/EpochPrices";
import TicklesFive from "../../../assets/images__v3/tickles_5.png";
import TicklesFour from "../../../assets/images__v3/tickles_4.png";
import TicklesImg from "../../../assets/images/tickles/Intersection 3@2x.png";
import TicklesOne from "../../../assets/images__v3/tickles_1.png";
import TicklesSix from "../../../assets/images__v3/tickles_6.png";
import TicklesThree from "../../../assets/images__v3/tickles_3.png";
import TicklesTwo from "../../../assets/images__v3/tickles_2.png";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./styled";
import { useTranslation } from "react-i18next";

function ProposeTickles(props) {
  const classes = useStyles();
  const {
    openTermsOfUseModal,
    setOpenTermsOfUseModal,
    handleSubmitProposal,
    countMessages,
    epochPrices,
  } = props;
  const { t } = useTranslation();
  const tickleIcons = [
    TicklesOne,
    TicklesTwo,
    TicklesThree,
    TicklesFour,
    TicklesFive,
    TicklesSix,
  ];
  const [numberOfTickles, setNumberOfTickles] = useState();
  const [selectedTickleProduct, setSelectedTickleProduct] = useState("");
  const [epochProductLists, setEpochProductLists] = useState([]);
  const [openInfoMessage, setOpenInfoMessage] = useState(false);
  const [openConfirmMessage, setOpenConfirmMessage] = useState(false);

  useEffect(() => {
    if (epochPrices && epochPrices.length > 0) {
      setEpochProductLists(epochPrices);
    }
  }, [epochPrices]);

  const handleSelectedTickleProduct = (product = null) => {
    setSelectedTickleProduct(product);
    setNumberOfTickles(product.tickles);
  };

  const handleCloseModal = () => {
    setOpenTermsOfUseModal(false);
    setNumberOfTickles(null);
  };

  const convertTickles = (tickles) => {
    if (tickles) {
      const ht = tickles / 100;
      const ttc = ht * 1.2;
      const epoch = ttc * 0.15;
      const tva = (ttc / 1.2) * 0.2;
      const feety = (ttc - epoch - tva) * 0.2;
      const converted = ht; //ttc - epoch - tva - feety;

      return converted.toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    } else {
      return 0.0;
    }
  };
  return (
    <Fragment>
      <Dialog
        className={classes.proposeDialog}
        open={openTermsOfUseModal}
        onClose={handleCloseModal}
      >
        {countMessages === 0 ? (
          <DialogTitle style={{ fontFamily: "Roboto", textAlign: "center" }}>
            <span style={{ color: "#ff2775" }}>
              {t("proposeTickles1stTitle1")}
            </span>
            {t("proposeTickles1stTitle2")}
          </DialogTitle>
        ) : (
          <>
            <DialogTitle
              disableTypography
              className={classes.proposeDialogTitleRoot}
              id="customized-dialog-title"
            >
              <div className={"propose-text"}>
                <Typography variant="h6">
                  {t("proposeTickles2ndTitle")}
                </Typography>
                <span className={"sub-text"}>{t("forCustomMedia")}</span>
              </div>
              <img src={TicklesImg} alt="tickles" width="50" height="50" />
            </DialogTitle>
            <DialogContent className={classes.proposeDialogContentRoot}>
              {t("proposeTicklesContent1")}&nbsp;
              <a href="/feet-model-guides">
                {t("proposeTicklesContent2Link")}
              </a>{" "}
              {t("proposeTicklesContent3")}
              <br />
              <br />
              <i>{t("proposeTicklesContent4")}</i>
              <br />
              <EpochPrices
                epochProductLists={epochProductLists}
                selectedTickleProduct={selectedTickleProduct}
                handleSelectedTickleProduct={handleSelectedTickleProduct}
                tickleIcons={tickleIcons}
                useFor={"ProposeTickles"}
              />
              <div className="input-section">
                <h4>{t("proposeTicklesContent5")}</h4>
                <input
                  type="text"
                  id="tickles"
                  name="tickles"
                  className="propose-tickle-input"
                  value={numberOfTickles}
                  placeholder={"0"}
                  onChange={(e) => {
                    const result = e.target.value.replace(/\D/g, "");
                    setNumberOfTickles(result);
                    setSelectedTickleProduct("");
                  }}
                />
                <h4>&nbsp;{convertTickles(numberOfTickles)}&euro;</h4>
              </div>
            </DialogContent>
          </>
        )}
        <Divider />
        <div
          className={
            countMessages !== 0 ? classes.proposeModalButtonsContainer : ""
          }
        >
          {countMessages !== 0 ? (
            <button
              type="button"
              onClick={handleCloseModal}
              style={{
                backgroundColor: "white",
                padding: "10px",
                color: "#000",
                fontSize: "1.1rem",
                fontWeight: "700",
                fontFamily: "Roboto",
              }}
            >
              {t(`sendTicklesCancelButton`)}
            </button>
          ) : null}
          <button
            type="button"
            onClick={() => {
              if (countMessages > 0 && !numberOfTickles) {
                setOpenInfoMessage(true);
              } else {
                countMessages > 0
                  ? setOpenConfirmMessage(true)
                  : handleCloseModal();
              }
            }}
            style={{
              backgroundColor: "white",
              padding: "10px",
              color: "#ff2775",
              fontSize: "1.1rem",
              fontWeight: "700",
              fontFamily: "Roboto",
            }}
          >
            {t(countMessages === 0 ? `OK` : `confirm`)}
          </button>
        </div>
      </Dialog>
      <Dialog
        className={classes.proposeDialog}
        open={openInfoMessage}
        onClose={() => {
          setOpenInfoMessage(false);
        }}
      >
        <DialogTitle style={{ fontFamily: "Roboto", textAlign: "center" }}>
          {t("proposeTicklesWarnDialogTitle")}
        </DialogTitle>
        <Divider />
        <button
          type="button"
          onClick={() => {
            setOpenInfoMessage(false);
          }}
          style={{
            backgroundColor: "white",
            padding: "10px",
            border: "none",
            // borderTop: "0.02rem solid #9F9F9F",
            color: "#ff2775",
            fontSize: "	1.1rem",
            fontWeight: "700",
            fontFamily: "Roboto",
          }}
        >
          {t("Ok")}
        </button>
      </Dialog>
      <Dialog
        className={classes.proposeDialog}
        open={openConfirmMessage}
        onClose={() => {
          setOpenConfirmMessage(false);
        }}
      >
        <DialogTitle style={{ fontFamily: "Roboto", textAlign: "center" }}>
          {t("proposeTicklesConfirmDialogTitle")}
        </DialogTitle>
        <DialogContent className={classes.confirmDialogContentRoot}>
          <div className="confirm-tickles-section">
            <h4>{numberOfTickles}</h4>
            <img src={TicklesImg} alt="tickles" width="25" height="25" />
            <h4>&nbsp;{convertTickles(numberOfTickles)}&euro;</h4>
          </div>
        </DialogContent>
        <Divider />
        <div className={classes.proposeModalButtonsContainer}>
          {countMessages !== 0 ? (
            <button
              type="button"
              onClick={() => {
                setOpenConfirmMessage(false);
              }}
              style={{
                backgroundColor: "white",
                padding: "10px",
                color: "#000",
                fontSize: "1.1rem",
                fontWeight: "700",
                fontFamily: "Roboto",
              }}
            >
              {t("proposeTicklesConfirmDialogBtnNo")}
            </button>
          ) : null}
          <button
            type="button"
            onClick={() => {
              handleSubmitProposal(numberOfTickles);
              setOpenConfirmMessage(false);
              handleCloseModal();
            }}
            style={{
              backgroundColor: "white",
              padding: "10px",
              color: "#ff2775",
              fontSize: "1.1rem",
              fontWeight: "700",
              fontFamily: "Roboto",
            }}
          >
            {t("proposeTicklesConfirmDialogBtnYes")}
          </button>
        </div>
      </Dialog>
    </Fragment>
  );
}

export default ProposeTickles;
