/* eslint-disable no-unused-vars */
import React from "react";
import {
  CheckCircle,
  Crop,
  DeleteForever,
  PhotoFilter,
} from "@material-ui/icons/";
import "./bottomNavigationCropTool.css";
import { useHistory } from "react-router-dom";
import { isIOS } from "react-device-detect";
import { useRecoilState } from "recoil";

import { studioAtom } from "../../../../stateManagement/atoms/studioAtom";

const BottomNavigationCropTool = ({
  getCroppedImg,
  clickCrop,
  setClickCrop,
  clickFilters,
  setClickFilters,
  isFromCreateMessage,
  isFromCreateMessageData,
  authUser,
}) => {
  let history = useHistory();

  const [onStudio, setOnStudio] = useRecoilState(studioAtom);

  const handleClickedCrop = () => {
    setClickCrop(true) &&
      setTimeout(() => {
        return setClickCrop(false);
      }, 1000);
  };

  return (
    <div
      className={
        isIOS === true
          ? "bottom-crop-menu bottom-crop-menuIphone"
          : "bottom-crop-menu"
      }
    >
      <div className="bottom-crop-tab">
        <button className="crop-icon">
          <CheckCircle
            fontSize="large"
            style={{ color: "green" }}
            onClick={async () => {
              await getCroppedImg(isFromCreateMessage);
              handleClickedCrop();
              if (isFromCreateMessage) {
                setOnStudio(false);
                history.push({
                  pathname: `/create-messages/${isFromCreateMessageData.convoId}`,
                  state: {
                    receiver: isFromCreateMessageData.receiver,
                    sendMessageUpload: true,
                  },
                  convoId: isFromCreateMessageData.convoId,
                });
              } else {
                setTimeout(() => {
                  history.push("/post-publication");
                }, 1000);
              }
            }}
          />
        </button>
        <button className="cropMenu">
          <DeleteForever
            fontSize="large"
            style={{ color: "red" }}
            onClick={() => {
              window.localStorage.removeItem("preview");
              window.sessionStorage.removeItem("publicationPicture");
              authUser?.user?.userType === "Lover"
                ? history.push("/home-page")
                : history.push("/home-page-models");
            }}
          />
        </button>
      </div>
    </div>
  );
};

export default BottomNavigationCropTool;
