/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Badge, Button, IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isIOS } from "react-device-detect";
import { useRecoilState, useRecoilValue } from "recoil";
import { withStyles } from "@material-ui/core/styles";

import "./viewOwnProfileModels.css";
import menu from "../../../assets/images/setting-icon.png";
import ticklesLog from "../../../assets/images/menu/tickles.svg";
import feetModelOwnProfileQuery from "../../../stateManagement/selectors/feetModelOwnProfileSelector";
import defaultAvatar from "../../../assets/images/generic-user-avatar.png";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import LogoText from "../../../assets/images/logo-text@2x.png";
import FeetModelService from "../../../_services/feet-models.service";
import {
  onSettingModelsAtom,
  userTypeAtom,
} from "../../../stateManagement/atoms/studioAtom";
import notificationFollowerBadgeAtom from "../../../stateManagement/atoms/notifications/followerBadge";
import Publications from "./Publications";
import UserService from "../../../_services/user.service";
import certifiedIcon from "../../../assets/images__v3/Certification_solid_ribbon.png";
import { getAuthSession } from "../../../helpers/auth.helpers";

const StyledBadge = withStyles((theme) => ({
  badge: {
    left: 15,
    top: 42,
    padding: "0",
    borderRadius: "50%",
    width: "6px",
    lineHeight: "8px",
    height: "6px",
    minWidth: "6px !important",
  },
}))(Badge);

const ViewOwnProfileModels = (props) => {
  let history = useHistory();
  const { t } = useTranslation();

  const [toggleAccordion, setToggleAccordion] = useState(false);
  const [hasPendingProfileImage, setHasPendingProfileImage] = useState(false);
  const [userType, setUserType] = useRecoilState(userTypeAtom);
  const [onSettingModels, setOnSettingModels] = useRecoilState(
    onSettingModelsAtom
  );
  const [isInvisible] = useRecoilState(notificationFollowerBadgeAtom);

  const [feetModelProfile, setFeetModelProfile] = useState({});

  // feet model profile
  const [feetModel, setFeetModel] = useState({
    profile: getAuthSession().user,
  });

  useEffect(() => {
    FeetModelService.getFeetModelProfile().then((response) => {
      // setFeetModel(response);
      console.log("feetModel: ", feetModel);
      setFeetModelProfile(response);
    });

    UserService.getPendingProfileImage().then((response) => {
      if (response && response?.image) {
        setHasPendingProfileImage(true);
      }
    });
  }, []);

  useEffect(() => {
    if (userType !== "Model") {
      setUserType("Model");
    }
    if (onSettingModels === true) {
      setOnSettingModels(false);
    }
  }, [setUserType, userType, onSettingModels, setOnSettingModels]);

  const HandleToggleDescription = () => {
    if (toggleAccordion === true) {
      setToggleAccordion(false);
    }
    if (toggleAccordion === false) {
      setToggleAccordion(true);
    }
  };

  return (
    <div
      className={
        isIOS === true
          ? "ViewOwnProfileModels__container ViewOwnProfileModels__containerIphone"
          : "ViewOwnProfileModels__container"
      }
    >
      <div className="profile_header flex-center page_fixed_header">
        <img className="profile__logo logo" src={LogoText} alt="feety logo" />
        <IconButton
          className="header__rightButtton settings-button"
          onClick={() => history.push("/setting-models")}
        >
          <img src={menu} alt="menu" className="ViewOwnProfileModels__img" />
        </IconButton>
      </div>
      <div className="ViewOwnProfileModels__content content__withHeader">
        <div className="ViewOwnProfileModelsContent__top">
          <div className="ViewOwnProfileModelsContentTop__bloc">
            {hasPendingProfileImage && (
              <p className="has_profile_picture_waiting_moderation">
                {t("moderationPictureAwaiting")}
              </p>
            )}
            {/* user details */}
            <div className="ViewOwnProfileModelsTop__picture">
              <img
                src={
                  feetModelProfile &&
                  feetModelProfile.profile &&
                  feetModelProfile.profile.image
                    ? feetModelProfile.profile.image.url
                    : defaultAvatar
                }
                alt="icon"
              />

              <div className="ViewOwnProfileModelsTop__text">
                {feetModel.profile?.ambassadress === true ? (
                  <span className="ViewOwnProfileContentBloc__text">
                    <h5 style={{ color: "#ff5768" }}>
                      {t("AmbassadressLabel")}
                    </h5>
                  </span>
                ) : null}
                <div className="profile__username">
                  <span className="ViewOwnProfileContentBloc__text">
                    <h4>{feetModel?.profile?.username}</h4>
                    {feetModel.profile?.certified ? (
                      <img
                        src={certifiedIcon}
                        alt="tickles"
                        width="25"
                        height="25"
                      />
                    ) : (
                      ""
                    )}
                  </span>
                  {toggleAccordion === false ? (
                    <KeyboardArrowDownIcon
                      onClick={HandleToggleDescription}
                      className="ViewOwnProfileModels__chevron"
                    />
                  ) : (
                    <KeyboardArrowUpIcon
                      onClick={HandleToggleDescription}
                      className="ViewOwnProfileModels__chevron"
                    />
                  )}
                </div>
                <span style={{ fontWeight: "bold" }}>
                  {feetModelProfile &&
                    feetModelProfile.profile &&
                    feetModelProfile.profile.title}
                </span>
                <div
                  className={
                    toggleAccordion === false
                      ? "ViewOwnProfileContentBloc__description"
                      : "ViewOwnProfileContentBloc__description diplaying"
                  }
                >
                  <p className="ViewOwnProfileContentBlocDescription__text">
                    <span>
                      {feetModelProfile &&
                        feetModelProfile.profile &&
                        feetModelProfile.profile.description}
                    </span>
                  </p>
                </div>
                <div className="ViewOwnProfileModels__buttonsTopBloc">
                  <Button
                    variant="contained"
                    className="follow-button pink__button"
                    onClick={() => history.push("/tickles-account")}
                  >
                    <img
                      className="ViewOwnProfileModels__ticklesIcon"
                      src={ticklesLog}
                      alt=""
                    />{" "}
                    {feetModelProfile &&
                      feetModelProfile.profile &&
                      parseFloat(
                        feetModelProfile.profile?.tickleCommission?.pending || 0
                      ) +
                        parseFloat(
                          feetModelProfile.profile?.tickleCommission?.gained ||
                            0
                        )}{" "}
                    Tickles
                  </Button>
                  <Button
                    onClick={() => history.push("/edit-profile")}
                    variant="contained"
                    className="message-button white__button"
                  >
                    {t("Edit Profile")}
                  </Button>
                </div>
              </div>
            </div>

            {/* views,likes,followers and following */}
            <div className="ViewOwnProfileModels__bloc">
              <div className="profile_counts">
                {/*<div*/}
                {/*  onClick={() => {*/}
                {/*    history.push({*/}
                {/*      pathname: `/views/${feetModel.profile._id}`,*/}
                {/*    });*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <h4 className="viewsNumbr">*/}
                {/*    {feetModelProfile &&*/}
                {/*      feetModelProfile.profile &&*/}
                {/*      feetModelProfile.profile.viewsCount}*/}
                {/*  </h4>*/}
                {/*  <p className="viewsText">{t("viewsFeetModelView")}</p>*/}
                {/*</div>*/}
                <div
                  onClick={() => {
                    // history.push({
                    //   pathname: `/likes/${feetModel.profile._id}`,
                    // });
                  }}
                >
                  <h4 className="likes">
                    {feetModelProfile &&
                      feetModelProfile.profile &&
                      feetModelProfile.profile.likesCount}
                  </h4>
                  <p className="likesText">{t("likesFeetModelProfileView")}</p>
                </div>
                <div
                  onClick={() => {
                    history.push({
                      pathname: `/follow-page-lover/${feetModel.profile._id}`,
                      state: { data: "following" },
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <h4 className="following">
                    {feetModelProfile &&
                      feetModelProfile.profile &&
                      feetModelProfile.profile?.activeFollowings}
                  </h4>
                  <p className="followingText">{t("profileFollowing")}</p>
                </div>
                <div
                  onClick={() => {
                    history.push({
                      pathname: `/follow-page-lover/${feetModel.profile._id}`,
                      state: { data: "followers" },
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <h4 className="followers">
                    {feetModelProfile &&
                      feetModelProfile.profile &&
                      feetModelProfile.profile?.activeFollowers}
                  </h4>
                  <StyledBadge
                    color="secondary"
                    variant="dot"
                    invisible={isInvisible}
                    overlap={"rectangular"}
                  >
                    <p className="followersText">{t("profileAdmirers")}</p>
                  </StyledBadge>
                </div>
                <div
                  onClick={() => {
                    history.push({
                      pathname: `/subscribers/${feetModel.profile._id}`,
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <h4 className="subscribers">
                    {feetModelProfile &&
                    feetModelProfile.profile &&
                    feetModelProfile.profile.subscribers
                      ? feetModelProfile.profile.subscribers.length
                      : 0}
                  </h4>
                  <p className="subscribersText">{t("profileSubscribers")}</p>
                </div>
              </div>
            </div>

            <div
              className={
                isIOS === true
                  ? "ViewOwnProfileModels__photoContainer ViewOwnProfileModels__photoContainerIphone"
                  : "ViewOwnProfileModels__photoContainer"
              }
            >
              <Publications feetModel={feetModel} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOwnProfileModels;
