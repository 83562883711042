/* eslint-disable no-unused-vars */
import React from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import feetModelAtom from "../../stateManagement/atoms/feetModelAtom";
import useSessionStorage from "../forms/services/storageHooks/useSessionStorage";
import CertifiedIcon from "../../assets/images__v3/Certification_solid_ribbon.png";
import dateLocale from "../../dateLocale";
import "./public.css";

export const PublicationFeetModel = ({ publication }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [authUser] = useSessionStorage({}, "infosUser");

  const [_, setFeetModel] = useRecoilState(feetModelAtom);

  const handleClickViewFeetModel = (user) => {
    if (authUser.user._id === user._id) {
      history.push("/view-own-profile-models");
    } else {
      setFeetModel(user);
      history.push({
        pathname: "/feet-models-view",
        search: `?user=${user._id}`,
      });
    }
  };

  return (
    <div
      className="homePage__user__details"
      onClick={() => handleClickViewFeetModel(publication.user)}
    >
      {/* username and certified icons if certified */}
      <div className="homePage__user__details_username">
        <p className="homePage__user__details_username_text">
          {publication.user.username}
        </p>
        {publication?.user?.certified ? (
          <img src={CertifiedIcon} alt="tickles" width="16" height="16" />
        ) : (
          ""
        )}
      </div>

      {/* ambassadress */}
      {publication.user?.ambassadress === true ? (
        <p className="homePage__user__details_username_text_ambassadress">
          {t("AmbassadressLabel")}
        </p>
      ) : (
        ""
      )}

      <sub style={{ fontSize: "12px", color: "#646464" }}>
        {dateLocale.localize(publication.createdAt).fromNow()}
      </sub>
    </div>
  );
};
