import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const getSubscriptionPricing = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/subscription-pricing`, {
    headers,
  });

  return response.data;
};

const getEpochSubscriptionProducts = async () => {
  try {
    const response = await axiosInstance.get(
      `/v1/product/list-subscription-products`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const SubscriptionPricingService = {
  getSubscriptionPricing,
  getEpochSubscriptionProducts,
};

export default SubscriptionPricingService;
