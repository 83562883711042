import ROUTES, { RenderRoutes } from "./routes";
/* eslint-disable no-unused-vars */
// import "./App.css";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  SocketEventContext,
  useSocketEventContext,
} from "./context/socketEvent";
import { getAuthSession, setAuthSession } from "./helpers/auth.helpers";
import { studioAtom, userTypeAtom } from "./stateManagement/atoms/studioAtom";
import { useRecoilState, useSetRecoilState } from "recoil";

import AlertNotification from "./components/NotificationAlert";
import BaseLayoutApp from "./components/layoutApp/baseLayoutApp/BaseLayoutApp";
import FeetModelService from "./_services/feet-models.service";
import { IonAlert } from "@ionic/react";
import NotificationBadgeService from "./_services/notification-badge.service";
import NotificationService from "./_services/notification.service";
import { SocketContext } from "./context/socket";
import UserService from "./_services/user.service";
import addCommentSelector from "./stateManagement/selectors/notifications/addComment";
import addLikesSelector from "./stateManagement/selectors/notifications/addLike";
import addTickleSelector from "./stateManagement/selectors/notifications/addTickle";
import authUserAtom from "./stateManagement/atoms/auth/user";
import dateLocale from "./dateLocale";
import newMessageBadgeAtom from "./stateManagement/atoms/newMessageBadgeAtom";
import setBadgeInVisibleSelector from "./stateManagement/selectors/notifications/setBadgeVisible";
import setNotificationCommentBadgeInVisibleSelector from "./stateManagement/selectors/notifications/badge/setCommentBadgeInvisible";
import setNotificationFollowerBadgeInvisibleSelector from "./stateManagement/selectors/notifications/setFollowerBadgeInvisible";
import setNotificationGeneralBadgeInVisibleSelector from "./stateManagement/selectors/notifications/badge/setGeneralNotifcationBadgeInvisible";
import setNotificationLikeBadgeInVisibleSelector from "./stateManagement/selectors/notifications/badge/setLikeBadgeVisible";
import setNotificationSubscriptionBadgeInVisibleSelector from "./stateManagement/selectors/notifications/badge/setSubscriptionBadgeInvisible";
import setNotificationTickleBadgeInVisibleSelector from "./stateManagement/selectors/notifications/badge/setTickleBadgeInvisible";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import useNotificationAlert from "./hooks/useNotificationAlert";
import useSessionStorage from "./components/forms/services/storageHooks/useSessionStorage";
import { useTranslation } from "react-i18next";

function App() {
  // Use react-i18next for the change language buttons
  const { i18n, t } = useTranslation();
  const socket = useContext(SocketContext);
  // Instancie state for stocking user type at chooseAccount component
  const [userType, setUserType] = useRecoilState(userTypeAtom);
  const [onStudio, setOnStudio] = useRecoilState(studioAtom);
  const history = useHistory();
  const location = useLocation();

  const { addNotificationMessage } = useNotificationAlert();

  /* socket events  */
  const { newMessage, setNewMessage } = useSocketEventContext(
    SocketEventContext
  );

  const [user] = useSessionStorage({}, "infosUser");
  const [__, setUpdateAuthenticatedUser] = useRecoilState(authUserAtom);

  // writable selectors to update global state for notifications
  const setAddLike = useSetRecoilState(addLikesSelector);
  const setAddComment = useSetRecoilState(addCommentSelector);
  const setAddTickle = useSetRecoilState(addTickleSelector);
  const [___, setNewMessageBadge] = useRecoilState(newMessageBadgeAtom);
  const setNotificationBadgeInVisible = useSetRecoilState(
    setBadgeInVisibleSelector
  );
  const setGeneralNotificationBadgeInVisible = useSetRecoilState(
    setNotificationGeneralBadgeInVisibleSelector
  );
  const setNotificationLikeBadgeInVisible = useSetRecoilState(
    setNotificationLikeBadgeInVisibleSelector
  );
  const setNotificationCommentBadgeInVisible = useSetRecoilState(
    setNotificationCommentBadgeInVisibleSelector
  );
  const setNotificationTickleBadgeInVisible = useSetRecoilState(
    setNotificationTickleBadgeInVisibleSelector
  );
  const setNotificationFollowerBadgeInVisible = useSetRecoilState(
    setNotificationFollowerBadgeInvisibleSelector
  );
  const setNotificationSubscriptionBadgeInVisible = useSetRecoilState(
    setNotificationSubscriptionBadgeInVisibleSelector
  );
  const [openBannedAlert, setOpenBannedAlert] = useState(false);

  const [pathName, setPathname] = useState("false");

  const likeHandler = useCallback(
    (data) => {
      // setAddLike(data);
      setNotificationBadgeInVisible(false);
      setNotificationLikeBadgeInVisible(false);
    },
    [
      // setAddLike,
      setNotificationBadgeInVisible,
      setNotificationLikeBadgeInVisible,
    ]
  );

  const subscriptionHandler = useCallback(
    (data) => {
      // setAddLike(data);
      setNotificationBadgeInVisible(false);
      setNotificationSubscriptionBadgeInVisible(false);
    },
    [
      // setAddLike,
      setNotificationBadgeInVisible,
      setNotificationSubscriptionBadgeInVisible,
    ]
  );

  const notificationHandler = useCallback(
    async (_data) => {
      // const { data } = await NotificationService.getNotifications(0);
      // const unreadNotifications = data.notifications.filter(
      //   (elem) => elem.readAt == null
      // );
      setNotificationBadgeInVisible(false);
      setGeneralNotificationBadgeInVisible(false);
      // if (unreadNotifications.length > 0) {
      //
      // }
      if (_data.type === "Id-check") {
        const user_id = (await getAuthSession()).user._id;
        const { user } = await UserService.getUser(user_id, null);
        await setAuthSession(JSON.stringify({ user }));
      }
    },
    [
      // setAddLike,
      setNotificationBadgeInVisible,
      setGeneralNotificationBadgeInVisible,
    ]
  );

  const commentHandler = useCallback(
    (data) => {
      // setAddComment(data);
      setNotificationBadgeInVisible(false);
      setNotificationCommentBadgeInVisible(false);
    },
    [
      // setAddComment,
      setNotificationBadgeInVisible,
      setNotificationCommentBadgeInVisible,
    ]
  );
  const newBannedMediaHandler = useCallback(
    async (_data) => {
      await setOpenBannedAlert(true);
      const { data } = await NotificationService.getNotifications(0);
      const unreadNotifications = data.notifications.filter(
        (elem) => elem.readAt == null
      );
      if (unreadNotifications.length > 0) {
        setNotificationBadgeInVisible(false);
        setGeneralNotificationBadgeInVisible(false);
      }
    },
    [
      setOpenBannedAlert,
      setGeneralNotificationBadgeInVisible,
      setNotificationBadgeInVisible,
    ]
  );

  const tickleHandler = useCallback(
    (data) => {
      // setAddTickle(data);
      setNotificationBadgeInVisible(false);
      setNotificationTickleBadgeInVisible(false);
    },
    [
      // setAddTickle,
      setNotificationBadgeInVisible,
      setNotificationTickleBadgeInVisible,
    ]
  );

  const newMessageHandler = useCallback(
    (newMessage) => {
      setNewMessageBadge(true);

      // this will update the socket global context
      setNewMessage(newMessage);
    },
    [setNewMessageBadge, setNewMessage]
  );

  const newFollowHandler = useCallback(() => {
    NotificationBadgeService.getNotificationBadge("Follower").then(
      (response) => {
        if (response.data.badge) {
          setNotificationFollowerBadgeInVisible(false);
        }
      }
    );
  }, [setNotificationFollowerBadgeInVisible]);

  //note: if user from session storage with key 'infoUser' is updated, this will re-trigger; causing socket to disconnect and reconnect.
  useEffect(() => {
    // initialize socket service
    socket.init();

    if (user && user.user && user.user._id) {
      socket.connect(user.user._id);
    }

    const comment = socket.onComment();
    const like = socket.onLike();
    const tickle = socket.onTickle();
    const message = socket.onMessage();
    const follow = socket.onFollow();
    const notif = socket.onNotification();
    const subscription = socket.onSubscription();

    const bannedMedia = socket.onBannedMedia();

    const onComment = comment.subscribe(commentHandler);
    const onLike = like.subscribe(likeHandler);
    const onNotif = notif.subscribe(notificationHandler);
    const onTickle = tickle.subscribe(tickleHandler);
    const onMessage = message.subscribe(newMessageHandler);
    const onFollow = follow.subscribe(newFollowHandler);
    const onBannedMedia = bannedMedia.subscribe(newBannedMediaHandler);
    const onSubscription = subscription.subscribe(subscriptionHandler);

    // before the component is destroyed
    // unbind all event handlers used in this component
    return () => {
      onComment.unsubscribe();
      onLike.unsubscribe();
      onTickle.unsubscribe();
      onMessage.unsubscribe();
      onFollow.unsubscribe();
      socket.disconnect();
      onBannedMedia.unsubscribe();
      onNotif.unsubscribe();
      onSubscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    socket,
    // user, // todo: maybe not listen for user here from session storage with key of infosUser, so that it won't re-trigger when it's value is updated everywhere in the application.
    likeHandler,
    commentHandler,
    tickleHandler,
    newMessageHandler,
    newFollowHandler,
    newBannedMediaHandler,
    notificationHandler,
    subscriptionHandler,
  ]);

  useEffect(() => {
    if (user && user.user && user.user._id) {
      NotificationBadgeService.getNotificationBadge("Follower").then(
        (response) => {
          if (response.data.badge) {
            setNotificationFollowerBadgeInVisible(false);
          }
        }
      );
    }
  }, [user, setNotificationFollowerBadgeInVisible]);

  // function for changing languages
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const changeDateLanguage = async () => {
    await dateLocale.setup();
  };

  useEffect(() => {
    if (localStorage.getItem("language")) {
      changeLanguage(localStorage.getItem("language"));
      changeDateLanguage();
    } else localStorage.setItem("language", "fr");
  }, [localStorage.getItem("language")]);

  // watch epoch transactions here from url location.
  useEffect(() => {
    // changeLanguage("fr");
    if (location) {
      if (location?.pathname) {
        setPathname(location?.pathname);
      }
    }

    const query = new URLSearchParams(location.search);
    const ans = query.get('ans');

    /* Currently the identifier that the epoch payment was successful is if ans starts with letter Y, N if payment failed*/
    if (location && location.search && ans && ans.substring(0, 1) === 'Y') {
      if (location.search.includes("type=subscription")) {
        addNotificationMessage("Subscription successful", "success");

        // if subscription transaction -> update the parameter with user
        history.replace({ search: `?user=${query.get("user").split("?")[0]}` });
      } else {
        // payment except subscription. tickles...

        // it's important to clear the query related to epoch so that the next transaction will work.
        history.replace({ search: "" });

        addNotificationMessage("Payment successful", "success");
      }
    } else if (location && location.search && ans && ans.substring(0, 1) === 'N') {
      if (location.search.includes("type=subscription")) {
        addNotificationMessage(t("notificationPaymentFailed"), "failed");

        // if subscription transaction -> update the parameter with user
        history.replace({ search: `?user=${query.get("user").split("?")[0]}` });
      } else {
        // payment except subscription. tickles...

        // it's important to clear the query related to epoch so that the next transaction will work.
        history.replace({ search: "" });

        addNotificationMessage(t("notificationPaymentFailed"), "failed");
      }
    }
  }, [location, history, setPathname]);

  // listen history change here if feet model and lover home page
  // fetch total

  useEffect(() => {
    const unlisten = history.listen(async (location) => {
      setPathname(location?.pathname);
      // do your magic things here
      // reset the search: clear the results and the search input field

      if (location.pathname === "/home-page-models" || location.pathname === "/home-page") {
        const [{data},
          {data: tickles} ,
          { data: comments },
          { data: likes },
          { data: messages },
          { data: subscriptions }] = await Promise.all([NotificationService.countGeneralNotifications(),
          NotificationService.countTicklesUnreadNotification(),
          NotificationService.countCommentsUnreadNotification(),
          NotificationService.countLikesUnreadNotification(),
          NotificationService.countMessagesUnreadNotification(),
          NotificationService.countSubscriptionsUnreadNotification()
        ])

        // const { data } = await NotificationService.getNotifications(0);
        // const { data: tickles } = await NotificationService.countTicklesUnreadNotification();
        // const { data: comments } = await ;
        // const  = await NotificationService.countLikesUnreadNotification();
        // const  = await NotificationService.countMessagesUnreadNotification();
        const unreadNonMessagingNotifications = tickles.unread + comments.unread + likes.unread + data.unread + subscriptions.unread
        if (unreadNonMessagingNotifications > 0) {
          setNotificationBadgeInVisible(false);
        }
        if(data.unread > 0){
          setGeneralNotificationBadgeInVisible(false);
        }
        if(messages.unread > 0){
          setNewMessageBadge(true)
        }
        if(tickles.unread > 0){
          setNotificationTickleBadgeInVisible(false);
        }
        if(likes.unread > 0){
          setNotificationLikeBadgeInVisible(false);
        }
        if(comments.unread > 0){
          setNotificationCommentBadgeInVisible(false)
        }
        if(subscriptions.unread > 0){
          setNotificationSubscriptionBadgeInVisible(false)
        }

        const response = await FeetModelService.getFeetModelsPublications();

        if (response?.data?.meta?.count === 0) {
          //feet model does not have following user yet or the feet model he/she follows does not have a publications yet. Redirect to suggestions page.
          history.push("/home-lover-first-login");
        }
      }
    });
    return function cleanup() {
      unlisten();
    };
  }, [setPathname]);

  /*  
               - When user from session storage changes, this will trigger and update the global state of authenticated user atom. 
               - This will also trigger on reload or refresh to make sure that the global state of authenticated user atom will be updated.
            */
  useEffect(() => {
    if (user && user.user && user.user._id) {
      // update global state of user atom from session storage
      setUpdateAuthenticatedUser(user.user);
    }
  }, [user, setUpdateAuthenticatedUser]);

  // To disable right click anywhere in the app
  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  });
  // Redirect user if its account soft deleted or suppressed
  useEffect(() => {
    const fn = async () => {
      try {
        const user_id = (await getAuthSession()).user._id;
        const { user } = await UserService.getUser(user_id, null);
        if (user.suppress || user.accountSoftDeleted) {
          history.push("/")
        }
      } catch (error) {
        console.log("ERROR: ", error)
        history.push("/")
      }
    }
    if (pathName !== "false" && pathName !== "/" && pathName !== "/sign-in" &&
        pathName !== "/sign-up" && pathName !== "/soft-choose-account" && pathName !== "/choose-account" &&
        pathName !== "/feet-details" && pathName !== "/choose-picty" && pathName !== "/update-subscriptions" &&
        pathName !== "/forgot-password" && !pathName.includes("/reset-password") && pathName !== "/confirm-registration") {
      fn().then();
    }
  }, [history, pathName])

  return (
    <div
      className={`App ${
        (pathName && pathName === "/edit-profile") || pathName === "/sign-up"
          ? "App-V3-bg"
          : ""
      }`}
    >
      <IonAlert
        isOpen={openBannedAlert}
        onDidDismiss={() => setOpenBannedAlert(false)}
        cssClass="my-custom-class"
        header={"Content deleted"}
        // subHeader={'Content deleted'}
        message={
          "One of your content has been deleted because it did not respect the Terms & Conditions"
        }
        buttons={[
          {
            text: t("termsAndConditions"),
            handler: () => {
              const url =
                i18n.language === "en"
                  ? "https://feety.com/en/terms-of-service"
                  : "https://feety.com/conditions-generales-utilisation";
              window.open(url, "_self");
            },
          },
          "OK",
        ]}
      />
      <BaseLayoutApp
        userType={userType}
        onStudio={onStudio}
        setOnStudio={setOnStudio}
        location={pathName}
      ></BaseLayoutApp>
      <RenderRoutes routes={ROUTES} />

      {/* global alert notification */}
      <AlertNotification />
    </div>
  );
}

export default App;
