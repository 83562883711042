import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const deleteSearchHistory = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.delete(`/v1/histories/search-history`, {
    headers,
  });

  return response.data;
};

const getSearchHistory = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get("/v1/histories/search-history", {
    headers,
  });
  return response;
};

const postSearchHistory = async (data) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  await axiosInstance.post("/v1/histories/search-history", data, { headers });
  return;
};

const HistoryService = {
  deleteSearchHistory,
  getSearchHistory,
  postSearchHistory,
};

export default HistoryService;
