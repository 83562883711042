import React from "react";
import { Button, IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useRecoilState } from "recoil";

import "./suppressAccount.css";
import SuppressAccountService from "../../../_services/suppress-account.service";
import { userTypeAtom } from "../../../stateManagement/atoms/studioAtom";

const suppressAccountSchema = Yup.object().shape({
  reason: Yup.string().required(),
});

const SuppressAccount = () => {
  let history = useHistory();
  const [userType] = useRecoilState(userTypeAtom);

  const formik = useFormik({
    initialValues: {
      reason: "",
      note: "",
    },
    validationSchema: suppressAccountSchema,
    onSubmit: async (values) => {
      try {
        await SuppressAccountService.suppressAccount(values);
        history.push("/");
      } catch (error) {
        console.log("error suppress account api: ", error);
      }
    },
  });

  return (
    <div className="SuppressAccount__container">
      <div className="SuppressAccount__header header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={
            userType === "Model"
              ? () => history.push("/setting-models")
              : () => history.push("/settings-lovers")
          }
        >
          <ChevronLeft />
        </IconButton>
        <h4>Suppress Account</h4>
      </div>
      <div className="SuppressAccount__content content__withHeader minmax__wrapper">
        <form onSubmit={formik.handleSubmit}>
          <div>
            <h4>Why are you suppresing your account?</h4>
            {formik.errors.reason && formik.touched.reason && (
              <p>{formik.errors.reason}</p>
            )}
            <select
              className="SuppressAccount__select"
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
            >
              <option value="">Select</option>
              <option value="reason one">But...Why???</option>
              <option value="reason two">Ok... But... why???</option>
              <option value="reason three">If you want.. but...why???</option>
            </select>
            <div className="SuppressAccount__textareaZone">
              <h4>Note</h4>
              <textarea
                placeholder="Write your note"
                className="SuppressAccount__textarea"
                name="note"
                value={formik.values.note}
                onChange={formik.handleChange}
              />
              {formik.errors.note && formik.touched.note && (
                <p>{formik.errors.note}</p>
              )}
            </div>
          </div>
          <div className="SuppressAccount__bottom">
            <Button
              className="SuppressAccountBottom__button pink__button"
              variant="contained"
              type="submit"
            >
              {t("suppressAccountAction")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SuppressAccount;
