import { makeStyles, withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";

export const useStyles = makeStyles((theme) => ({
  root: {
    background: "#E7E7E7",
    padding: "70px 16px 0 16px",
  },
  profileImage: {
    marginTop: "36px",
    marginBottom: "72.49px",
    "& .dropzone": {
      display: "flex",
      justifyContent: "center",
    },
    "& .MuiAvatar-root": {
      overflow: "visible",
      width: "117.45px",
      height: "117.51px",
    },
    "& .MuiAvatar-img": {
      width: "117.45px",
      height: "117.51px",
      borderRadius: "50%",
      boxShadow: "0 0 13px #636363",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginBottom: "20px",
  },
  label: {
    fontSize: "clamp(1rem, 4vw, 1rem)",
    color: "#000000",
    marginBottom: "8px",
    marginLeft: "8px",
  },
  textInput: {
    width: "100%",

    "& .MuiInputBase-input": {
      height: "45px",
      borderRadius: "15px",
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
  },
  inputNumber: {
    width: "128px",
    "& input[type=number]": {
      lineHeight: "30px",
      fontSize: "16px",
      padding: "0 0 0 8px",
      backgroundColor: "#D7D7D7",
      borderRadius: "12px",
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      opacity: 1,
      "-webkit-appearance": "none",
      cursor: "pointer",
      display: "block",
      color: "#333",
      textAlign: "center",
      position: "relative",
      background: `#636363 url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAASCAYAAABrXO8xAAAAAXNSR0\
      IArs4c6QAAAUxJREFUOE/Vk7FKxEAQhpNL0Iws65EyXep0IQjaWApBLcRco5VvkCfJG1hpY8RCJW\
      JpI5yEdKmvSxmOZcmeIXeRjayuZxC9zu32n/nY2Zl/VKXnBEGgRVF0zkNhGJ7FcTxfTlOXhTzP1z\
      DGF8PhMOCx6XQaE0JOHcep5dwvYJIk667rXmGMDxeLRZc3GAwUQshtlmUj3/dfBfwBFkWxgTG+AY\
      C9pmmU+fy9Ok3TFF3XFcbYIyHkyLKsiusdmOc5sm37HgB2+Z2D8osc5Icx9jSZTPYdx6FqWZabhm\
      EkALAj/0EGZZ0x9jybzXy1qqoxAGyJYNu2XZlyqbxcVf1sB2Ps5VtX+8bTp/0ncJXm1HU9FuN4AI\
      DtP41jZQOIV1aynIC5yT3PixFCB7JzKKV3aZoGvSYXMF8rhNClaZrHXCvL8ppSevLjWgn4N4v8Bm\
      YKyyMJVre1AAAAAElFTkSuQmCC") no-repeat center center`,
      backgroundSize: "12px 12px",
      // background:
      //   "#636363 url('http://i.stack.imgur.com/YYySO.png') no-repeat 50% 50%",
      width: "14px",
      height: "128px",
      padding: "4px",
      right: "0px",
      borderRadius: "28px",
    },
  },
  select: {
    height: "45px",
    color: "#545454",
    borderRadius: "15px",
  },
  textArea: {
    width: "100%",
    minHeight: "108px",
    "& .MuiFilledInput-multiline": {
      padding: "10px 15px",
      backgroundColor: "white",
      borderRadius: "clamp(15px, 4vw, 25px)",
    },

    "& .MuiFilledInput-underline:before": {
      borderBottom: 0,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: 0,
    },
  },
  materialUiDatePicker: {
    "& .MuiInput-root": {
      backgroundColor: "#636363",
      borderRadius: "12px",
      width: "128px",
    },

    "& input[type=text]": {
      padding: "0 0 0 10px",
      backgroundColor: "#D7D7D7",
      borderRadius: "11px 0 0 11px",
    },

    "& .MuiInputAdornment-positionEnd": {
      marginLeft: "0px",
    },

    "& .MuiIconButton-root": {
      padding: 0,
    },

    "& .MuiFormControl-marginNormal": {
      marginTop: 0,
    },
  },
  editProfileMoreSettingsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  editProfileSettingsButtonRounded: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "clamp(15px, 4vw, 25px)",
    padding: "8px 13px",

    "& .MuiButton-text": {
      fontSize: "clamp(0.875rem, 4vw, 1rem)",
      textTransform: "none",
    },
  },
  accordion: {
    "& .MuiAccordion-root:before": {
      background: "none !important",
    },
    "& .Mui-expanded.MuiIconButton-edgeEnd": {
      marginTop: "0",
    },
    "& .picties": {
      display: "flex",
      padding: "10px",
      justifyContent: "space-around",
    },
    "& .selected-picty": {
      border: "5px solid #FF5768",
      borderRadius: "50%",
    },
    "& .picty": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: "15px !important",
      marginTop: "0px !important",
    },
  },
}));

export const StyledBadge = withStyles((theme) => ({
  badge: {
    top: "75px",
    right: "20px",
    padding: "0 4px",
    width: "34px",
    height: "34px",
    borderRadius: "50%",
    background: "linear-gradient(0deg, #FF2775 -0.16%, #FF5768 100%)",
  },
}))(Badge);
