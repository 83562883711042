import React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { IconButton } from "@material-ui/core/";
import { ChevronLeft } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import EditFeetLoverProfilePage from "./FeetLover";
import EditFeetModelProfilePage from "./FeetModel";
import getUserQuery from "../../stateManagement/selectors/getUserSelector";

function EditProfilePage() {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useRecoilValue(getUserQuery);
  return (
    <div>
      <div className="profileForm__header header page_fixed_header edit-profile-header__v3">
        <IconButton
          className="back__button"
          onClick={() =>
            user.userType === "Lover"
              ? history.push("/settings-lovers")
              : history.push("/setting-models")
          }
        >
          <ChevronLeft style={{ fill: "black" }} />
        </IconButton>
        <h4>{t("Edit Profile")}</h4>
      </div>
      {user && user?.userType === "Lover" ? <EditFeetLoverProfilePage /> : null}
      {user && user?.userType === "Model" ? <EditFeetModelProfilePage /> : null}
    </div>
  );
}

export default EditProfilePage;
