/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import "./kebabMenuOptionsLovers.css";
import Divider from "@material-ui/core/Divider";
import { Button, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import BlockService from "../../../_services/block.service";
import FollowService from "../../../_services/follow.service";
import PublicationService from "../../../_services/publication.service";
import UserService from "../../../_services/user.service";

import useSessionStorage from "../../../components/forms/services/storageHooks/useSessionStorage";
import ReportAccount from "../../ReportAccount/ReportAccount";
import { useRecoilState } from "recoil";
import searchFilterPictyAtom from "../../../stateManagement/atoms/searchFilterPictyAtom";
import DeletePublication from "../messageSection/createMessage/modals/deletePublication";

const useStyles = makeStyles({
  fullList: {
    width: "auto",
  },
});

const KebabMenuOptionsLovers = (props) => {
  let history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [state, setState] = useState({
    bottom: false,
  });

  //setFollower is from ViewFeetModelsProfile page
  const {
    open,
    setOpen,
    user,
    publication,
    isFollower,
    setFollower,
    showReportButtonForUser,
    setRefetch,
    refetch
  } = props;

  const [authenticated] = useSessionStorage({}, "infosUser");
  const [isFollowerUser, setIsFollower] = useState("");
  const [
    isBlockedByAuthenticatedUser,
    setIsBlockedByAuthenticatedUser,
  ] = useState(false);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [, setSearchFilterPicty] = useRecoilState(searchFilterPictyAtom);

  useEffect(() => {
    setIsFollower(
      isFollower && isFollower.followedUser ? isFollower.followedUser : ""
    );
  }, [isFollower]);

  // const user = location.state.user;
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const toggleClose = (event) => {
    if (event) setOpen(false);
  };

  // watch when 3 dot vertical menu is open.
  useEffect(() => {
    if (open) {
      setLoading(true);
      UserService.getUser(user._id).then((response) => {
        setUserProfile(response.user);
        const matchFollower =
          response &&
          response.user &&
          response.user.listOfFollowers &&
          response.user.listOfFollowers.filter(
            (user) => user === authenticated.user._id
          );

        setIsFollower(matchFollower.length ? matchFollower[0] : "");

        setIsBlockedByAuthenticatedUser(
          !!(
            response &&
            response.user &&
            response.user.listOfBlockers &&
            response.user.listOfBlockers.filter(
              (user) => user === authenticated.user._id
            ).length
          )
        );
        setLoading(false);
      });
    }
  }, [open]);

  const handleFollowOrUnfollow = async () => {
    try {
      if (isFollowerUser) {
        // un-follow
        setIsFollower("");
        setFollower && setFollower({});
        await FollowService.remove(user._id);
      } else {
        // follow
        setIsFollower(user._id);
        setFollower && setFollower({ _id: user._id, followedUser: user._id });
        await FollowService.followUser({
          followedUser: user._id,
        });
      }
      setOpen(false)
      if (setRefetch && refetch)
        setRefetch(refetch + 1)
    } catch (error) {
      console.error("ERROR DELETE /api/v1/follows/:id", error);
    }
  };

  const handleBlockOrUnblock = async () => {
    try {
      if (isBlockedByAuthenticatedUser) {
        // un-block
        setIsBlockedByAuthenticatedUser(false);

        await BlockService.unblockUser(user._id);
      } else {
        // block

        await BlockService.blockUser(user.id);

        setIsBlockedByAuthenticatedUser(true);
      }
    } catch (error) {
      console.error("ERROR POST /api/v1/block/user/:id", error);
      toast.error(
        `${
          error.response?.data?.error || "Error blocking or unblocking user"
        } `,
        {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const handleDeletePublication = async () => {
    setOpenAcceptModal(true);
  };

  const list = (anchor) => (
    <div
      className={clsx({
        [classes.fullList]: anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {userProfile && userProfile.userType === "Model" && (
        <div className="kebabMenu-head">
          <hr onClick={toggleClose} size="2" className="kebabMenu__roundedHr" />
          <div className="kebaMenuHead two-col-block feet-details">
            <div>
              <div>
                <h4>{t("feetSizeKebabMenuLover")}</h4>
                <p>{userProfile.footSize}</p>
              </div>
            </div>
            <div>
              <div>
                <h4>{t("feetParticularityKebabMenuLover")}</h4>
                <div
                  key={userProfile?.userFootType?._id}
                  onClick={() => {
                    setSearchFilterPicty([userProfile?.userFootType]);
                    history.push("/search-lover");
                  }}
                >
                  <img
                    key={userProfile?.userFootType?._id}
                    src={userProfile?.userFootType?.url}
                    alt={userProfile?.userFootType?.label}
                    style={{ width: "42px" }}
                  />
                  <p style={{margin: 0}}>{userProfile?.userFootType?.label}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Divider />
      <div className="kebabMenu__buttons">
        {/* Delete Button */}
        {publication &&
          authenticated &&
          publication.user &&
          authenticated.user &&
          publication.user._id === authenticated.user._id && (
            <Button className="delete-btn" onClick={handleDeletePublication}>
              {t("Delete")}
            </Button>
          )}

        {/* 
        Follow/Un-follow Button
        Note: 
          - follow/un-follow button will not show when feet model visit his/her own publication.
          - show follow/un-follow button for user if showReportButtonForUser is provided as prop.
        */}
        {publication &&
        authenticated &&
        publication.user &&
        authenticated.user &&
        publication.user._id !== authenticated.user._id ? (
          <Button className="normalize-text" onClick={handleFollowOrUnfollow}>
            {isFollowerUser
              ? t("unfollowKebabMenuLover")
              : t("followButtonFeetModelView")}
          </Button>
        ) : (
          showReportButtonForUser &&
          user && (
            <Button className="normalize-text" onClick={handleFollowOrUnfollow}>
              {isFollowerUser
                ? t("unfollowKebabMenuLover")
                : t("followButtonFeetModelView")}
            </Button>
          )
        )}

        {/* Report Button for publication */}
        {publication &&
          authenticated &&
          publication.user &&
          authenticated.user &&
          publication.user._id !== authenticated.user._id && (
            <ReportAccount
              to={publication.user._id}
              success={(e) => setOpen(false)}
              type={"publication"}
              publication={publication._id}
            />
          )}

        {/* Report button for user */}
        {showReportButtonForUser && user && (
          <ReportAccount
            to={user._id}
            success={(e) => setOpen(false)}
            type={"user"}
          />
        )}

        {/* 
        Block/Unblock Button.
        Note: 
          - block will not show when feet model visit his/her own publication.
          - show block button for user if showReportButtonForUser is provided as prop.
        */}
        {publication &&
        authenticated &&
        publication.user &&
        authenticated.user &&
        publication.user._id !== authenticated.user._id ? (
          <Button className="default-btn-case" onClick={handleBlockOrUnblock}>
            {isBlockedByAuthenticatedUser
              ? t("unblockKebabMenuLover")
              : t("blockKebabMenuLover")}
          </Button>
        ) : (
          showReportButtonForUser &&
          user && (
            <Button className="default-btn-case" onClick={handleBlockOrUnblock}>
              {isBlockedByAuthenticatedUser
                ? t("unblockKebabMenuLover")
                : t("blockKebabMenuLover")}
            </Button>
          )
        )}
      </div>

      <DeletePublication
        openModal={openAcceptModal}
        setOpenModal={setOpenAcceptModal}
        publication={publication}
        setOpen={setOpen}
      />
    </div>
  );

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={toggleClose}
          onOpen={toggleDrawer("bottom", true)}
        >
          {loading ? (
              <div
                style={{
                  position: "relative",
                  left: "calc(50% - 20px)",
                  padding: "20px 0"
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <>
                {list()}
              </>
            )
          }
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
};

export default KebabMenuOptionsLovers;
