import React from "react";
import { Avatar } from "@material-ui/core";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router-dom";

import searchFilterPictyAtom from "../../stateManagement/atoms/searchFilterPictyAtom";

const ReadMore = ({ children }) => {
  let history = useHistory();

  const text = children.description || " ";
  const picties = children.picties;

  const [, setSearchFilterPicty] = useRecoilState(searchFilterPictyAtom);

  const [isReadMore, setIsReadMore] = React.useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div>
      <p
        style={{
          display: "inline",
        }}
      >
        {isReadMore && text.length > 30 ? `${text.slice(0, 30)}...` : text}
        <span
          onClick={toggleReadMore}
          style={{
            cursor: "pointer",
            fontSize: "18px",
            color: "gray",
          }}
        >
          {isReadMore && text.length > 30 ? " suite" : ""}
        </span>
      </p>
      {!isReadMore && (
        <div style={{ marginTop: "15px" }}>
          <div
            className="postPublicationSelectedPicty__list picty__container"
            style={{ justifyContent: "start" }}
          >
            {picties.length !== 0 &&
              picties.map((pubPicty, index) => (
                <div
                  key={pubPicty._id}
                  onClick={() => {
                    setSearchFilterPicty([pubPicty.picty]);
                    history.push("/search-lover");
                  }}
                >
                  {pubPicty.picty && pubPicty.picty.url ? (
                    <div className="picty__with_delete">
                      <Avatar src={pubPicty.picty.url} />
                    </div>
                  ) : null}
                </div>
              ))}
          </div>
        </div>
      )}
      {text.length < 30 && picties.length ? (
        <div style={{ marginTop: "15px" }}>
          <div
            className="postPublicationSelectedPicty__list picty__container"
            style={{ justifyContent: "start" }}
          >
            {picties.length !== 0 &&
              picties.map((pubPicty, index) => (
                <div
                  key={pubPicty._id}
                  onClick={() => {
                    setSearchFilterPicty([pubPicty.picty]);
                    history.push("/search-lover");
                  }}
                >
                  {pubPicty.picty && pubPicty.picty.url ? (
                    <div className="picty__with_delete">
                      <Avatar src={pubPicty.picty.url} />
                    </div>
                  ) : null}
                </div>
              ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ReadMore;
