import axios from "axios";
import { selector } from "recoil";

import { getAuthSession } from "../../helpers/auth.helpers";

const { REACT_APP_API_BASE_URL } = process.env;

const feetModelOwnProfileQuery = selector({
  key: "feetModelOwnProfile",
  get: async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/v1/feet-models/profile`,
        {
          headers: {
            Authorization: `Bearer ${getAuthSession().user.access_token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("ERROR GET /api/v1/feet-models/profile", error);
    }
  },
});

export default feetModelOwnProfileQuery;
