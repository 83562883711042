import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, Divider, IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { useStyles } from "./styled";
import Button from "../../components/Button";
import getUserQuery from "../../stateManagement/selectors/getUserSelector";
import SuppressAccountService from "../../_services/suppress-account.service";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import SubscribeService from "../../_services/subscribe.service";
import WithdrawalService from "../../_services/withdrawal.service";
import FeetModelService from "../../_services/feet-models.service";

function SuppressAccountPage() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { addNotificationMessage } = useNotificationAlert();

  const classes = useStyles();
  const { user } = useRecoilValue(getUserQuery);

  const [reasons, setReasons] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openNotAllowedModal, setOpenNotAllowedModal] = useState(false);
  const [subscriptions, setSubscriptions] = useState(0);
  const [gained, setGained] = React.useState(0);
  const [pending, setPending] = React.useState(0);

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      reason: "",
      note: "",
    },

    onSubmit: async (values) => {
      if (values && values?.reason) {
        setOpenModal(true);
      }
    },
  });

  const tmpReasons = {
    lover: [
      t("acLoverRes2Opt1"),
      t("acLoverRes2Opt2"),
      t("acLoverRes2Opt3"),
      t("acLoverRes2Opt4"),
    ],
    model: [
      t("acModelRes1Opt1"),
      t("acModelRes1Opt2"),
      t("acModelRes1Opt3"),
      t("acModelRes1Opt4"),
    ],
  };

  const handleWithdrawal = async () =>
    await WithdrawalService.transferRequest("suppressed");

  const handleSuppressAccount = async () => {
    await SuppressAccountService.suppressAccount(values, i18n.language);
    addNotificationMessage(t("acNotificationSuccess"), "success");
    localStorage.clear();
    sessionStorage.clear();
    history.push("/");
  };

  useEffect(() => {
    if (user && user.userType === "Model") {
      FeetModelService.getFeetModelProfile().then((response) => {
        setGained(response?.profile?.gainedCommissionCount.toFixed(2));
        setPending(response?.profile?.pendingCommissionCount.toFixed(2));
      });
    }
  }, [user]);

  useEffect(() => {
    setReasons(
      user && user.userType === "Lover" ? tmpReasons.lover : tmpReasons.model
    );
    return () => {
      setReasons([]);
    };
  }, [user, setReasons]);

  useEffect(async () => {
    const response = await SubscribeService.getSubscriptions();
    if (response && response.data.subscriptions) {
      setSubscriptions(
        response.data.subscriptions.filter(
          (subs) => subs.validTill === null || !subs.validTill
        ).length
      );
    }
  }, [user, setSubscriptions]);
  return (
    <div className="SuppressAccount__container">
      <div className="SuppressAccount__header header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={
            user?.userType === "Model"
              ? () => history.push("/setting-models")
              : () => history.push("/settings-lovers")
          }
        >
          <ChevronLeft />
        </IconButton>
        <h4>{t("suppressAccountTitle")}</h4>
      </div>
      <div className="content__withHeader minmax__wrapper">
        <div className={classes.content}>
          {user && user?.userType === "Lover" ? (
            <>
              <h5>{t("accountDeletionTitle")}</h5>

              <div>
                <h5 className="warning">{t("accountDeletionWarning")}</h5>
                <h5>{t("accountDeletionSubTitle")}</h5>
              </div>

              <span>{t("accountDeletionDescription")}</span>
            </>
          ) : (
            <>
              <h5>{t("accountDeletionTitle1")}</h5>

              <div>
                <h5 className="warning">{t("accountDeletionWarning")}</h5>
                <h5>{t("accountDeletionSubTitle1")}</h5>
              </div>

              <span>
                {t("accountDeletionDescription1")}{" "}
                <a
                  href={
                    i18n.language === "en"
                      ? "https://feety.com/en/help-center"
                      : "https://feety.com/aide"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  page
                </a>
              </span>
            </>
          )}
        </div>
        <form noValidate className={classes.form} onSubmit={handleSubmit}>
          <FormControl className={classes.formControl}>
            <InputLabel
              id={t("accountDeletionDropdownComponentPlaceholder")}
              shrink
            >
              {t("accountDeletionDropdownComponentLabel")}
            </InputLabel>
            <Select
              labelId={t("accountDeletionDropdownComponentPlaceholder")}
              id={t("accountDeletionDropdownComponentPlaceholder")}
              value={values?.reason}
              displayEmpty
              onChange={handleChange}
              inputProps={{
                name: "reason",
                id: t("accountDeletionDropdownComponentPlaceholder"),
                placeholder: t("accountDeletionDropdownComponentPlaceholder"),
              }}
            >
              <MenuItem value="" disabled>
                {t("accountDeletionDropdownComponentPlaceholder")}
              </MenuItem>
              {reasons && reasons.length
                ? reasons.map((reason) => (
                    <MenuItem
                      value={reason}
                      key={reason}
                      classes={{ root: classes.menuItem }}
                    >
                      {reason}
                    </MenuItem>
                  ))
                : ""}
            </Select>
          </FormControl>

          <div>
            <InputLabel htmlFor="note" className={classes.label}>
              {t("accountDeletionTextareaLabel")}
            </InputLabel>
            <TextField
              id="note"
              multiline
              minRows={4}
              value={values?.note}
              onChange={handleChange}
              variant="filled"
              className={classes.textArea}
              placeholder={t("accountDeletionTextareaPlaceholder")}
            />
          </div>

          <Button
            type="submit"
            width="clamp(100%, 4vw, 24.5rem)"
            background="#F35162"
            height="clamp(42px, 4vw, 52px)"
          >
            {t("accountDeletionAction")}
          </Button>
        </form>
      </div>

      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        className={classes.suppressDialog}
      >
        <DialogTitle>
          <p className={classes.dialogWarning}>{t("acModalTitle")}</p>
          <p className={classes.dialogTitleMessage}>
            {user?.userType === "Model" ||
            (subscriptions === 0 && user?.userType === "Lover")
              ? t("acModalDescription")
              : t("suppressRunningSubscribers")}
          </p>

          <p style={{ marginTop: "10px" }}>
            {user?.userType === "Model" ||
            (subscriptions === 0 && user?.userType === "Lover")
              ? t("acModalParagraph")
              : t("suppressRunningSubscribersParagraph")}
          </p>
        </DialogTitle>
        <Divider />
        <div className={classes.suppressModalButtonsContainer}>
          {user?.userType === "Model" ||
          (subscriptions === 0 && user?.userType === "Lover") ? (
            <button
              type={"button"}
              onClick={async () => {
                setOpenModal(false);
                try {
                  if (user?.userType === "Model") {
                    if (pending.toString() === "0.00" && gained > 0.0) {
                      await handleWithdrawal();
                      await handleSuppressAccount();
                    } else if (
                      (pending > 0.0 && gained.toString() === "0.00") ||
                      (gained > 0.0 && pending > 0.0)
                    ) {
                      // Pop up message here
                      setOpenNotAllowedModal(true);
                    } else {
                      await handleSuppressAccount();
                    }
                  } else {
                    await handleSuppressAccount();
                  }
                } catch (error) {
                  addNotificationMessage(t("acNotificationFailed"), "error");
                }
              }}
              style={{ color: "#000000" }}
            >
              {t("Yes")}
            </button>
          ) : null}

          <button
            type={"button"}
            onClick={() => {
              if (
                user?.userType === "Model" ||
                (subscriptions === 0 && user?.userType === "Lover")
              )
                setOpenModal(false);
              else {
                setOpenModal(false);
                history.push("/subscription-lover");
              }
            }}
          >
            {user?.userType === "Model" ||
            (subscriptions === 0 && user?.userType === "Lover")
              ? t("No")
              : t("Ok")}
          </button>
        </div>
      </Dialog>

      {/* Pop-up message for not allowing deletion account for models - Start */}
      <Dialog
        open={openNotAllowedModal}
        onClose={() => {
          setOpenNotAllowedModal(false);
        }}
        className={classes.suppressDialog}
      >
        <DialogTitle>
          <p className={classes.dialogWarning}>{t("acModalTitle")}</p>
          <p className={classes.dialogTitleMessage}>
            {t("suppressStillHasMoneyInWallet")}
          </p>
          <a href={`mailto:${t("suppressContactEmail")}`}>
            {t("suppressContactEmail")}
          </a>
        </DialogTitle>
        <Divider />
        <div className={classes.suppressModalButtonsContainer}>
          <button type={"button"} onClick={() => setOpenNotAllowedModal(false)}>
            {t("Ok")}
          </button>
        </div>
      </Dialog>
      {/* Pop-up message for not allowing deletion account for models - End */}
    </div>
  );
}

export default SuppressAccountPage;
