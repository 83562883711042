import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import moment from "moment";
import Bugsnag from "@bugsnag/js";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Button as MuiButton } from "@material-ui/core";
import { CountryDropdown } from "react-country-region-selector";
import { useRecoilState, useRecoilValue } from "recoil";
import { useFormik } from "formik";
import { isEmpty as _isEmpty } from "lodash";
import { not } from "ramda";
import { useHistory } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { useStyles } from "./styled";
import Button from "../../../components/Button";
import getUserQuery from "../../../stateManagement/selectors/getUserSelector";
import UserService from "../../../_services/user.service";
import authUserAtom from "../../../stateManagement/atoms/auth/user";
import useNotificationAlert from "../../../hooks/useNotificationAlert";
import { _objectDiffer } from "../../../helpers";
import Checkbox from "@material-ui/core/Checkbox";

import "../styles.css";
import { setAuthSession } from "../../../helpers/auth.helpers";

function EditFeetLoverProfilePage() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { addNotificationMessage } = useNotificationAlert();

  const [_, setUpdateAuthenticatedUser] = useRecoilState(authUserAtom);
  const { user } = useRecoilValue(getUserQuery);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);

  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleBlur,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      ...user,
      receiveNewsletter: user.receiveNewsletter,
      birthday:
        user && user.birthday
          ? new Date(moment(user.birthday).format("MM/DD/yyyy"))
          : new Date(),
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(2, t("errUsernameShortSignup"))
        .max(20, t("errUsernameLongSignup"))
        .required(t("errUsernameRequiredSignup")),
      nationality: Yup.string().required(t("errNationalityRequiredSignup")),
      birthday: Yup.string()
        .required(t("errBirthdayRequiredSignup"))
        .test("birthday", t("errRegistrationDateAbove18Signup"), (value) => {
          return (
            moment().diff(moment(value).format("MM/DD/yyyy"), "years") >= 18
          );
        }),
      title: Yup.string()
        .min(5, "5 caractères min")
        .max(40, "40 caractères max"),
      description: Yup.string()
        .min(10, "10 caractères min")
        .max(400, "400 caractères max"),
    }),
    onSubmit: async (values) => {
      setIsUpdatingUser(true);

      // initial values
      const {
        username: initialUsername,
        title: initialTitle,
        description: initialDescription,
        nationality: initialNationality,
        birthday: initialBirthday,
        receiveNewsletter: initialReceiveNewsletter,
      } = user;

      // new values
      const {
        username,
        title,
        description,
        nationality,
        birthday,
        receiveNewsletter,
      } = values;

      // differentiate or check if user data has been updated or changed
      const updatedUserValues = _objectDiffer(
        {
          username,
          title,
          description,
          nationality,
          birthday: moment(birthday).format("MM/DD/yyyy"),
          receiveNewsletter: receiveNewsletter,
        },
        {
          username: initialUsername,
          title: initialTitle,
          description: initialDescription,
          nationality: initialNationality,
          birthday: moment(initialBirthday).format("MM/DD/yyyy"),
          receiveNewsletter: initialReceiveNewsletter || false,
        }
      );

      // user data has been changed.
      if (not(_isEmpty(updatedUserValues))) {
        try {
          const data = {
            ...updatedUserValues,
            ...(updatedUserValues?.birthday && { birthday }),
          };

          const response = await UserService.updateUser(values._id, data);
          addNotificationMessage(t("Successful update profile"), "success");
          setUpdateAuthenticatedUser(response.data);

          await setAuthSession(JSON.stringify({ user: response.data }));
        } catch (error) {
          if (error?.response?.data?.error === "Username already exists") {
            addNotificationMessage(t("Username already exists"), "error");
            Bugsnag.notify(error?.response?.data?.error);
          } else if (
            error?.response?.data.validation?.body?.keys[0] === "username"
          ) {
            addNotificationMessage(
              t("username only supports alpha numeric"),
              "error"
            );
            Bugsnag.notify(error?.response?.validation?.body);
          } else {
            addNotificationMessage(error.message, "error");
          }
        } finally {
          setIsUpdatingUser(false);
        }
      } else {
        user.userType === "Lover"
          ? history.push("/settings-lovers")
          : history.push("/setting-models");
      }
    },
  });

  return (
    <div className={classes.root}>
      <form noValidate onSubmit={handleSubmit} className={classes.form}>
        <div>
          <InputLabel htmlFor="username" className={classes.label}>
            {t("username")}
          </InputLabel>
          <TextField
            id="username"
            value={values.username}
            onChange={handleChange}
            className={classes.textInput}
            InputProps={{ disableUnderline: true }}
          />
          {errors?.username && touched?.username ? (
            <p className="formik-errors">{errors?.username}</p>
          ) : null}
        </div>

        <div>
          <InputLabel htmlFor="nationality" className={classes.label}>
            {t("Country")}
          </InputLabel>
          <CountryDropdown
            id="nationality"
            name="nationality"
            value={values.nationality}
            onChange={(_, e) => handleChange(e)}
            className={classes.select}
          />
          {errors?.nationality && touched?.nationality ? (
            <p className="formik-errors">{errors?.nationality}</p>
          ) : null}
        </div>

        <div className={classes.materialUiDatePicker}>
          <InputLabel htmlFor="birthday" className={classes.label}>
            {t("Date of birth")}
          </InputLabel>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="birthday"
              name="birthday"
              format="DD/MM/yyyy"
              value={values.birthday}
              onChange={(date) => setFieldValue("birthday", date)}
              onBlur={handleBlur}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ disableUnderline: true }}
              keyboardIcon={<ArrowDropDownIcon style={{ fill: "white" }} />}
            />
          </MuiPickersUtilsProvider>
          {errors?.birthday && touched?.birthday ? (
            <p className="formik-errors">{errors?.birthday}</p>
          ) : null}
        </div>

        <div className={classes.bio}>
          <div>
            <InputLabel htmlFor="title" className={classes.label}>
              {t("Title of your bio")}
            </InputLabel>
            <TextField
              id="title"
              multiline
              minRows={4}
              value={values.title}
              onChange={handleChange}
              variant="filled"
              className={classes.textArea}
            />
            {errors?.title && touched?.title ? (
              <p className="formik-errors">{errors?.title}</p>
            ) : null}
          </div>

          <div>
            <InputLabel htmlFor="description" className={classes.label}>
              {t("Bio ")}
            </InputLabel>
            <TextField
              id="description"
              multiline
              minRows={4}
              defaultValue=""
              value={values.description}
              onChange={handleChange}
              variant="filled"
              className={classes.textArea}
            />
            {errors?.description && touched?.description ? (
              <p className="formik-errors">{errors?.description}</p>
            ) : null}
          </div>
        </div>

        <div className={classes.editProfileMoreSettingsContainer}>
          <div
            className={classes.editProfileSettingsButtonRounded}
            onClick={() => {
              history.push({
                pathname: "/choose-picty",
                state: {
                  editProfile: true,
                  preferredPicties: user.preferredPicties,
                  user,
                },
              });
            }}
          >
            <MuiButton>{t("Edit your favorite pictys")}</MuiButton>
            <ArrowForwardIosIcon />
          </div>

          <div
            className={classes.editProfileSettingsButtonRounded}
            onClick={() => history.push("/update-email")}
          >
            <MuiButton>{t("Modify your email")}</MuiButton>
            <ArrowForwardIosIcon />
          </div>

          <div
            className={classes.editProfileSettingsButtonRounded}
            onClick={() => history.push("/change-password")}
          >
            <MuiButton>{t("Change your password")}</MuiButton>
            <ArrowForwardIosIcon />
          </div>
        </div>

        <div>
          <Checkbox
            checked={values.receiveNewsletter}
            onChange={handleChange}
            name="receiveNewsletter"
            id="receiveNewsletter"
          />
          <span
            onClick={() =>
              setFieldValue("receiveNewsletter", !values.receiveNewsletter)
            }
          >
            {t("News Letters Email")}
          </span>
        </div>

        <Button
          type="submit"
          width="clamp(100%, 4vw, 24.5rem)"
          background="#F35162"
          height="clamp(42px, 4vw, 52px)"
        >
          {isUpdatingUser ? (
            <CircularProgress color="inherit" size={15} />
          ) : (
            t("Save")
          )}
        </Button>
      </form>
    </div>
  );
}

export default EditFeetLoverProfilePage;
