import "./homeLoverFirstLogin.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-toastify/dist/ReactToastify.css";

import { Button, IconButton } from "@material-ui/core";
import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { isAndroid, isIOS } from "react-device-detect";

import AddIcon from "../../../assets/images/iphone-install-notification/add-icon.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CustomMediaModal from "../../Modals/CustomMedia/index";
import FeetModelService from "../../../_services/feet-models.service";
import FollowService from "../../../_services/follow.service";
import InstallIcon from "../../../assets/images/iphone-install-notification/install-icon.png";
import LogoText from "../../../assets/images/logo-text.png";
import MessageService from "../../../_services/message.service";
import MessagesIcon from "../../../assets/images/home-page-models/messages-icon.svg";
import PublicationMedia from "../../PublicationMedia";
import RedLogo from "../../../assets/images/red-logo.png";
import Tooltip from "@material-ui/core/Tooltip";
import authUserAtom from "../../../stateManagement/atoms/auth/user";
import { fallBackImageBase64 } from "../../../helpers/constant";
import feetModelAtom from "../../../stateManagement/atoms/feetModelAtom";
import genericAvatar from "../../../assets/images/generic-user-avatar.png";
import genericPublicationImage from "../../../assets/images/generic-publication-color.png";
import newMessageBadgeAtom from "../../../stateManagement/atoms/newMessageBadgeAtom";
import { studioAtom } from "../../../stateManagement/atoms/studioAtom";
import { useHistory } from "react-router-dom";
import useInfiniteQuery from "../../../hooks/useInfiniteQuery";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

const CustomToolTip = withStyles({
  tooltip: {
    color: "white",
    backgroundColor: "#152238",

    fontSize: "0.8em",
    padding: "3px 10px",
    "& ::before": {
      backgroundColor: "#152238",
    },
  },
})(Tooltip);

const HomeLoverFirstLogin = (props) => {
  let history = useHistory();
  const { t } = useTranslation();
  const alreadyInstall = "yes";
  const [isPushNotification] = React.useState(localStorage.getItem("isPush"));
  const [onStudio, setOnStudio] = useRecoilState(studioAtom);
  const [newMessageBadge] = useRecoilState(newMessageBadgeAtom);
  const [followed, setFollowed] = React.useState([]);
  const [open, setOpen] = React.useState("");
  const [, setFeetModel] = useRecoilState(feetModelAtom);
  const [authenticatedUser] = useRecoilState(authUserAtom);
  const [hasRejectedMedia, setHasRejectedMedia] = React.useState(false);
  const [isNotified, setIsNotified] = React.useState(false);

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery("feet-model-suggestions", async ({ next_offset = 0 }) => {
      const response = await FeetModelService.getFollowSuggestions(next_offset);

      return {
        data: response.data.suggestions,
        next_offset: response.data.next_offset,
        total: response.data.meta.count,
      };
    });

  const loadMoreSuggestionsReference = React.useRef();

  useIntersectionObserver({
    target: loadMoreSuggestionsReference,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  useEffect(() => {
    (async () => {
      const response = await MessageService.getCustomMedia({
        status: "refuse",
      });
      setHasRejectedMedia(response.data.response.length > 0);
      localStorage.setItem("isNotified", true);
    })();
  }, [hasRejectedMedia]);

  useEffect(() => {
    const isTrue = localStorage.getItem("isNotified");
    if (isTrue === "true") {
      setIsNotified(true);
    }
  }, []);

  useEffect(() => {
    if (onStudio === true) {
      setOnStudio(false);
    }
  });

  useEffect(() => {
    if (isAndroid === true && isPushNotification) {
      // alert("This is an Android")
      Notification.requestPermission((result) => {
        if (result === "granted") {
          showNotification(t("homeLoverFirstLoginNotification"));
        }
      });

      function showNotification(title, message) {
        if ("Notification" in window) {
          navigator.serviceWorker.ready.then((registration) => {
            registration.showNotification(title, {
              body: message,
              tag: "vibration-sample",
            });
          });
        }
      }
    }
    if (isIOS === true) {
      //  alert("it's an IPhone")
      const Msg = () => (
        <div className="messageIPhoneContent">
          <div className="firstColumn">
            <img src={AddIcon} alt="add icon" />
            <b style={{ color: "black" }}>
              Install this WebApp on your IPhone:{" "}
            </b>
            <br />
          </div>
          <div className="secondColumn">
            <b>tap</b>
            <img src={InstallIcon} alt="install icon" />
            <b> and then Add to homescreen</b>
          </div>
          <div className="alreadyInstalled">
            <b>
              {t("If you have already installed the app, close this message")}
            </b>
          </div>
        </div>
      );
      if (localStorage.getItem("alreadyInstalled") === null) {
        // Function for displaying notification to screen
        const displayMsg = () => {
          toast(<Msg />);
        };
        displayMsg();
      }
    }
  }, [t, isPushNotification]);

  const isFollowed = (user) => {
    return !!followed.filter((data) => data._id === user._id).length;
  };

  const handleFollow = async (user) => {
    try {
      // already followed
      if (isFollowed(user)) {
        await FollowService.remove(user._id);
        setFollowed((prev) => prev.filter((data) => data._id !== user._id));
      } else {
        await FollowService.followUser({ followedUser: user._id });
        setFollowed((prev) => [...prev, user]);
      }
    } catch (error) {
      console.error("ERROR POST /api/v1/follows", error);
    }
  };

  const handleTooltipOpen = (username) => {
    setOpen(username);
  };

  const handleTooltipClose = () => {
    setOpen("");
  };

  return (
    <div className="homeLover__container">
      <ToastContainer
        position="top-center"
        autoClose={false}
        hideProgressBar={true}
        newestOnTop={true}
        // closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 999, top: "5em" }}
        onClick={() => localStorage.setItem("alreadyInstalled", alreadyInstall)}
      />
      <div
        style={{ color: "black" }}
        className="homeLover__header header page_fixed_header"
      >
        <img src={LogoText} alt="" />
        {authenticatedUser &&
        authenticatedUser?.userType &&
        authenticatedUser?.userType === "Model" ? (
          <IconButton
            className="homePageModels__messages header__rightButtton"
            onClick={() => history.push("/messages")}
          >
            <img src={MessagesIcon} alt="message icon" />
            {newMessageBadge && (
              <span className="homePageModels__onMessages"></span>
            )}
          </IconButton>
        ) : (
          ""
        )}
      </div>
      <div
        className="homeLover__top"
        onClick={() => history.push("/home-page")}
      >
        <img src={RedLogo} alt="" />
        <div className="homeLover__top-text">
          <h4>{t("homeLoverFirstLoginNoFollowed")}</h4>
          <p>({t("homeLoverFirstLoginSearch")})</p>
        </div>
      </div>
      <div className="homeLover__content">
        {hasRejectedMedia ? (
          <CustomMediaModal
            title={t("acModalTitle")}
            message={t("invalidCustomMedia")}
            ok={t("Ok")}
            cancel={t("buyTicklesModalCancelButton")}
            hasCustomMediaRejected={hasRejectedMedia && !isNotified}
            history={history}
            url={"/custom-media-tracking"}
          />
        ) : null}
        <h3>Suggestions</h3>
        <div className="homeLover__grid-picture">
          {data.map((user) => (
            <div key={user._id} className="homeLover__pictures-publications">
              <div className="homeLover__picture">
                {user.publication ? (
                  <PublicationMedia
                    publication={user.publication}
                    width="100%"
                    effect="blur"
                    className="homeLover__photo"
                    alt="publictions"
                    preview={genericPublicationImage}
                    onClick={() => {
                      history.push({
                        pathname: `/publication/${user.publication._id}`,
                      });
                    }}
                  />
                ) : (
                  <img
                    src={fallBackImageBase64}
                    alt="no publication yet"
                    className="homeLover__photo"
                  />
                )}
              </div>
              <div className="homeLover__bottom-publication">
                <img
                  className="homeLover__avatar"
                  src={user.image ? user.image.url : genericAvatar}
                  alt="avatar"
                />

                {user.username && user.username.length > 13 ? (
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div
                      onClick={() => {
                        if (user?.userType === "Lover") {
                          history.push({
                            pathname: `/visit-feet-lover/${user._id}`,
                          });
                        } else {
                          setFeetModel(user);
                          history.push({
                            pathname: "/feet-models-view",
                            search: `?user=${user._id}`,
                          });
                        }
                      }}
                    >
                      <CustomToolTip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={open === user.username}
                        title={user.username}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                        placement="top"
                      >
                        <h4 className="homeLover__avatarName">
                          {`${user.username.slice(0, 13)}`}
                          <b
                            style={{ color: "#f24ec6", fontWeight: "900" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleTooltipOpen(user.username);
                            }}
                          >
                            ...
                          </b>
                        </h4>
                      </CustomToolTip>
                      {user?.ambassadress && (
                        <div>
                          <p className="ambassadress">Ambassadress</p>
                          {/* certified and star icon here... */}
                        </div>
                      )}
                    </div>
                  </ClickAwayListener>
                ) : (
                  <div
                    onClick={() => {
                      if (user?.userType === "Lover") {
                        history.push({
                          pathname: `/visit-feet-lover/${user._id}`,
                        });
                      } else {
                        setFeetModel(user);
                        history.push({
                          pathname: "/feet-models-view",
                          search: `?user=${user._id}`,
                        });
                      }
                    }}
                  >
                    <h4 className="homeLover__avatarName">{user.username}</h4>
                    {user?.ambassadress && (
                      <div>
                        <p className="ambassadress">{t("AmbassadressLabel")}</p>
                        {/* certified and star icon here... */}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <Button
                variant="contained"
                className={`normalize-text homeLover__follow-button ${
                  isFollowed(user) && "unfollow-button"
                }`}
                onClick={() => handleFollow(user)}
              >
                {isFollowed(user)
                  ? t("unfollowKebabMenuLover")
                  : t("homeLoverFirstLoginFollowButton")}
              </Button>
            </div>
          ))}
        </div>
        <div className="pagination-loader" ref={loadMoreSuggestionsReference}>
          {isFetchingNextPage ? (
            <CircularProgress color="secondary" size={10} />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeLoverFirstLogin;
