import "./homePage.css";
import "react-lazy-load-image-component/src/effects/blur.css";

import { Button, IconButton } from "@material-ui/core";
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { useDidCache, useDidRecover } from "react-router-cache-route";
import { useHistory, useLocation } from "react-router-dom";

import Alert from "../../Alert";
import BuyTickleModal from "../../../components/BuyTickleModal";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CertifiedIcon from "../../../assets/images__v3/Certification_solid_ribbon.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import CommentModal from "../../commentsSection/CommentModal";
import CustomMediaModal from "../../Modals/CustomMedia";
import FeetModelService from "../../../_services/feet-models.service";
import KebabMenuOptionsLovers from "../../loversComponents/KebabMenuOptionsLovers/KebabMenuOptionsLovers";
import LikeService from "../../../_services/like.service";
import LogoText from "../../../assets/images/logo-text@2x.png";
import MessageService from "../../../_services/message.service";
import PublicationMedia from "../../PublicationMedia";
import PublicationService from "../../../_services/publication.service";
import ReadMore from "../../ReadMore";
import SendTickles from "../../loversComponents/sendTickles/SendTickles";
import Skeleton from "@material-ui/lab/Skeleton";
import Snackbar from "@material-ui/core/Snackbar";
import TicklesIcon from "../../../assets/images/notifications-models/Group 54498@2x.png";
import { ToastContainer } from "react-toastify";
import UserService from "../../../_services/user.service";
import comments from "../../../assets/images/menu/comment.svg";
import dateLocale from "../../../dateLocale";
import feetModelAtom from "../../../stateManagement/atoms/feetModelAtom";
import genericAvatar from "../../../assets/images/generic-user-avatar.png";
import { getAuthSession } from "../../../helpers/auth.helpers";
import heart from "../../../assets/images/menu/heart.svg";
import likedHeart from "../../../assets/images/notifications-models/likes.png";
import { makeStyles } from "@material-ui/core/styles";
import menuPoints from "../../../assets/images/menu/menu.svg";
import publicationItemAtom from "../../../stateManagement/atoms/publicationItemAtom";
import { saveComment } from "../../../_services/comment.service";
import tickles from "../../../assets/images/menu/heart_tickles.png";
import { trackWindowScroll } from "react-lazy-load-image-component";
import useInfiniteQuery from "../../../hooks/useInfiniteQuery";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import { useRecoilState } from "recoil";
import useSessionStorage from "../../forms/services/storageHooks/useSessionStorage";
import { useTranslation } from "react-i18next";
import writeCommentsContentAtomAtom from "../../../stateManagement/atoms/writeCommentsContentAtom";
import TicklePricesService from "../../../_services/tickle-prices.service";

const HomePage = (props) => {
  let history = useHistory();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const [_, setFeetModel] = useRecoilState(feetModelAtom);
  const [isPushNotification] = useState(localStorage.getItem("isPush"));
  const [refetch, setRefetch] = useState(0);
  const [hasRejectedMedia, setHasRejectedMedia] = React.useState(false);
  const [isNotified, setIsNotified] = React.useState(false);
  // const userType = useRecoilValue(userTypeAtom);

  const {
    data,
    total,
    error,
    status,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    "feet-model-publications",
    async ({ next_offset = 0 }) => {
      const response = await FeetModelService.getFeetModelsPublications(
        next_offset
      );

      return {
        data: response.data.publications,
        next_offset: response.data.next_offset,
        total: response.data.meta.count,
      };
    },
    refetch
  );

  useEffect(() => {
    if (error?.response?.status === 401) {
      sessionStorage.clear();
      localStorage.clear();
      history.push("/");
    }
  }, [error]);

  const [authUser] = useSessionStorage({}, "infosUser");

  useEffect(() => {
    (async () => {
      if (authUser.user.userType !== "Lover") {
        const response = await MessageService.getCustomMedia({
          status: "refuse",
        });
        setHasRejectedMedia(response.data.response.length > 0);
        localStorage.setItem("isNotified", true);
      }
    })();
  }, [hasRejectedMedia, authUser]);

  useEffect(() => {
    const isTrue = localStorage.getItem("isNotified");
    if (isTrue === "true") {
      setIsNotified(true);
    }

    const lang = localStorage.getItem("language");
    const info = {
      ...authUser,
      user: {
        ...authUser.user,
        preferredLanguage: lang,
      },
    };
    localStorage.setItem("infosUser", JSON.stringify(info));
  }, []);

  const loadMorePublicationsReference = React.useRef();

  useIntersectionObserver({
    target: loadMorePublicationsReference,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  useEffect(() => {
    if (isAndroid === true && isPushNotification) {
      // alert("This is an Android")
      Notification.requestPermission((result) => {
        if (result === "granted") {
          showNotification("Bienvenue sur l'application Feety!");
        }
      });

      function showNotification(title, message) {
        if ("Notification" in window) {
          navigator.serviceWorker.ready.then((registration) => {
            registration.showNotification(title, {
              body: message,
              tag: "vibration-sample",
            });
          });
        }
      }
    }
    if (isIOS === true) {
      // alert("This is an Iphone")
    }
  }, [isPushNotification]);

  useEffect(() => {
    if (location) setRefetch(refetch + 1);
    if (location.pathname === "/home-page" && total === 0)
      history.push("/home-lover-first-login");
  }, [location, total, history]);

  const handleClickViewFeetModel = (user) => {
    setFeetModel(user);
    history.push({
      pathname: "/feet-models-view",
      search: `?user=${user._id}`,
    });
  };

  useDidCache(() => {});

  useDidRecover(() => {});

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  return (
    <div className="homePage__container">
      <div className="homePage__header header page_fixed_header">
        <img src={LogoText} alt="" className="logo" />
      </div>
      {authUser.user.userType !== "Lover" && hasRejectedMedia ? (
        <CustomMediaModal
          title={t("acModalTitle")}
          message={t("invalidCustomMedia")}
          ok={t("Ok")}
          cancel={t("buyTicklesModalCancelButton")}
          hasCustomMediaRejected={hasRejectedMedia && !isNotified}
          history={history}
          url={"/custom-media-tracking"}
        />
      ) : null}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{top: "5em"}}
      />
      <div className="feet_grid_list">
        {status === "loading" ? (
          <HomePageSkeleton />
        ) : status === "error" ? (
          <span>Error: {error.message}</span>
        ) : (
          <>
            {data.map((publication) => (
              <Publication
                key={publication._id}
                id={publication._id}
                publication={publication}
                history={history}
                handleClickViewFeetModel={handleClickViewFeetModel}
                i18n={i18n}
                t={t}
                setRefetch={setRefetch}
                refetch={refetch}
              />
            ))}
            <div className="space-filler post-item"></div>
            <div className="space-filler post-item"></div>
            <div className="space-filler post-item"></div>
          </>
        )}
      </div>
      <div className="pagination-loader" ref={loadMorePublicationsReference}>
        {isFetchingNextPage ? (
          <CircularProgress color="secondary" size={10} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const Publication = trackWindowScroll(
  // eslint-disable-next-line react/display-name
  React.memo(
    ({
      id,
      publication: publicationData,
      handleClickViewFeetModel,
      history,
      i18n,
      t,
      scrollPosition,
      setRefetch,
      refetch
    }) => {
      const [isDisplayCommentsList, setIseDisplayCommentsList] =
        useState(false);
      // The content of comment textArea from Atom state
      const [writeComment, setWriteComment] = useState(false);
      const [isTickleModalOpen, setTickleModalOpen] = useState(false);
      const [openProfile, setOpenProfile] = useState(false);
      const [picties, setPicties] = useState([]);
      const [epochProductLists, setEpochProductLists] = useState([]);

      // alert message
      const [openAlertMessage, setOpenAlertMessage] = useState(false);
      const [alertMessage, setAlertMessage] = useState({
        reason: "",
        alertStatus: "info",
      });
      const [authenticated] = useSessionStorage({}, "infosUser");

      const [authenticatedUser, setAuthenticatedUser] = useState(null);

      const [openBuyTickleModal, setOpenBuyTickleModal] = useState(false);

      // For displaying writing comment message component
      const [isWriteComment, setIsWriteComment] = useRecoilState(
        writeCommentsContentAtomAtom
      );

      // publication state
      const [publication, setPublication] = useRecoilState(
        publicationItemAtom({
          id,
          publication: {
            ...publicationData,
            heart: publicationData.isLiked
              ? likedHeart
              : heart,
          },
        })
      );

      const [publicationForTickle, setPublicationForTickle] =
        React.useState("");

      useEffect(() => {
        if (publicationData && publicationData.picties) {
          setPicties(publicationData.picties)
        }
      }, [publicationData]);

      useEffect(() => {
        (async () => {
          if (authenticated.user.userType === "Lover" && openBuyTickleModal === true) {
            const response = await TicklePricesService.getEpochProductLists();
            setEpochProductLists(response.data);
          }
        })();
      }, [openBuyTickleModal, authenticated]);

      const handleLikeOrUnlikePublication = () => {
        // DELETE unlike publication endpoint
        if (
          publication.isLiked
        ) {
          LikeService.unLikePublication({
            publication: publication._id,
          })
            .then((result) => result)
            .catch((error) => error);

          setPublication((item) => ({
            ...item,
            likesCount: item.likesCount - 1,
            heart,
            // likes: item.likes.filter(
            //   (data) => data.user !== getAuthSession().user._id
            // ),
            isLiked: false
          }));
        } else {
          // POST like publication endpoint
          LikeService.likePublication({
            publication: publication._id,
          })
            .then((result) => result)
            .catch((error) => error);

          setPublication((item) => ({
            ...item,
            likesCount: item.likesCount + 1,
            heart: likedHeart,
            // likes: [...item.likes, { user: getAuthSession().user._id }],
            isLiked: true
          }));
        }
      };

      const handleDisplayCommentsList = (publication) => {
        /* old implementation by rodolphe */
        // if (isDisplayCommentsList) {
        //   setIseDisplayCommentsList(false);
        // }
        // if (!isDisplayCommentsList) {
        //   setIseDisplayCommentsList(true);
        // }

        history.push({
          pathname: `/publication/${publication._id}/comments`,
        });
      };

      const handleDisplayLikersList = (publication) => {
        history.push({
          pathname: `/publication/${publication._id}/likers`,
        });
      };

      // For displaying writing comment message component
      const handleDisplayCommentModal = () => {
        if (writeComment) {
          setWriteComment(false);
        }
        if (!writeComment) {
          setWriteComment(true);
        }
      };

      // For displaying all comments component
      const handleSendComment = async (_publication) => {
        if (isWriteComment !== "") {
          await saveComment({
            publication: _publication.id,
            content: isWriteComment,
          });
          // setIsWriteComment("");

          history.push({
            pathname: `/publication/${_publication._id}/comments`,
          });
        }
        if (!isDisplayCommentsList) {
          setWriteComment(false);
          setIseDisplayCommentsList(true);
        }
        if (isDisplayCommentsList) {
          setWriteComment(false);
        }
      };

      // NEED TO DEBUG NUMBER OF TICKLES STATE AND REDIRECTION WITH HISTORY BECAUSE RIGHT NOW IT'S BROKEN
      const handleSendTicklesPublication = async (publication) => {
        const { _id } = getAuthSession().user;

        const { user } = await UserService.getUser(_id);

        setAuthenticatedUser(user);

        // open buy tickle modal
        if (user && (user.tickles === 0 || !user.tickles)) {
          setOpenBuyTickleModal(true);
        }

        // open modal to send tickle
        if (user && user.tickles >= 1) {
          setTickleModalOpen(true);
          setPublicationForTickle(publication._id);
        }
      };

      const handleCloseAlertMessage = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }

        setOpenAlertMessage(false);
      };

      return (
        <div key={publication._id} className="post-item">
          <div
            className={
              publication.user.online
                ? "post-header"
                : "post-header post-offline"
            }
          >
            <div className="post-user-details">
              <div className="post-user-avatar">
                <span
                  className={
                    publication.user.online
                      ? "allMessages__online post-online"
                      : ""
                  }
                ></span>
                <img
                  className="post-user-image"
                  src={
                    publication.user && publication.user.image
                      ? publication.user.image.url
                      : genericAvatar
                  }
                  alt="avatar"
                  onClick={() => handleClickViewFeetModel(publication.user)}
                />
              </div>
              <div
                className="homePage__user__details"
                onClick={() => handleClickViewFeetModel(publication.user)}
              >
                <div className="homePage__user__details_username">
                  <p className="homePage__user__details_username_text">
                    {publication.user.username}
                  </p>
                  {publication?.user?.certified ? (
                    <img
                      src={CertifiedIcon}
                      alt="tickles"
                      width="16"
                      height="16"
                    />
                  ) : (
                    ""
                  )}
                </div>

                {/* ambassadress */}
                {publication.user?.ambassadress === true ? (
                  <p className="homePage__user__details_username_text_ambassadress">
                    {t("AmbassadressLabel")}
                  </p>
                ) : (
                  ""
                )}

                <sub style={{ fontSize: "12px", color: "#646464" }}>
                  {dateLocale.localize(publication.createdAt).fromNow()}
                </sub>
              </div>
            </div>
            <IconButton onClick={() => setOpenProfile(true)}>
              <img className="post-header-menu" src={menuPoints} alt="menu" />
            </IconButton>
          </div>
          <div className="post-picture">
            <PublicationMedia
              scrollPosition={scrollPosition}
              publication={publication}
              width="100%"
              effect="blur"
              height="440px"
              onClick={() => {
                history.push({
                  pathname: `/publication/${publication._id}`,
                });
              }}
            />
          </div>
          <div className="post-bottom">
            <div
              className={
                i18n.languages === "en"
                  ? "action__buttons homePage__stickers"
                  : "action__buttons homePageFr__stickers"
              }
              style={{
                display:
                  authenticated.user.userType !== "Lover" && "inline-block",
              }}
            >
              {authenticated.user.userType === "Lover" ? (
                <Button
                  onClick={(e) => handleSendTicklesPublication(publication)}
                >
                  <img className="ticle_icon" src={tickles} alt="tickles" />
                  <sub>{t("homePageTicklesLover")}</sub>
                </Button>
              ) : null}
              <Button
                style={{
                  paddingRight:
                    authenticated.user.userType !== "Lover" && "45px",
                }}
              >
                <img
                  className={
                    publication.isLiked ? "fullHeart" : "emptyHeart"
                  }
                  key={publication.heart}
                  src={publication.heart}
                  alt="heart"
                  onClick={() => handleLikeOrUnlikePublication()}
                />
                <sub onClick={() => handleDisplayLikersList(publication)}>
                  {publication.likesCount} {t("likesHomePageLover")}
                </sub>
              </Button>
              <Button onClick={handleDisplayCommentModal}>
                <img className="fullHeart" src={comments} alt="comments" />
                <sub>
                  {publication.commentCount} {t("commentHomePageLover")}
                </sub>
              </Button>
            </div>
            <div className="post-bottomContent">
              <div className="post-bottomText">
                <ReadMore>{{ ...publication, picties }}</ReadMore>
              </div>
              <div className="post-comments">
                {publication.commentCount > 0 ? (
                  <sub onClick={() => handleDisplayCommentsList(publication)}>
                    {t("viewCommentsHomePageLover")} {publication.commentCount}{" "}
                    {t("commentHomePageLover")}
                  </sub>
                ) : null}
                {writeComment ? (
                  <CommentModal
                    handleSendComment={() => handleSendComment(publication)}
                  />
                ) : (
                  ""
                )}
                {/* {isDisplayCommentsList
                  ? history.push({
                      pathname: `/publication/${publication._id}/comments`,
                      
                    })
                  : ""} */}
              </div>
            </div>
          </div>
          <SendTickles
            recipient={publication.user}
            isOpen={isTickleModalOpen}
            setOpen={setTickleModalOpen}
            publicationForTickle={publicationForTickle}
            authenticatedUser={authenticatedUser}
            setOpenBuyTickleModal={setOpenBuyTickleModal}
            setOpenAlertMessage={setOpenAlertMessage}
            setAlertMessage={setAlertMessage}
            ticklesIcon={TicklesIcon}
          />
          <KebabMenuOptionsLovers
            publication={publication}
            user={publication.user}
            open={openProfile}
            setOpen={setOpenProfile}
            setRefetch={setRefetch}
            refetch={refetch}
          />
          <hr className="post-hr" />

          {/* buy tickle modals from epoch product lists */}

          <BuyTickleModal
            openBuyTickleModal={openBuyTickleModal}
            setOpenBuyTickleModal={setOpenBuyTickleModal}
            epochPrices={epochProductLists}
          />

          {/* alert component here. in case insufficient tickles... */}
          {/* TODO: might need to make the alert global */}
          <Snackbar
            open={openAlertMessage}
            autoHideDuration={6000}
            onClose={handleCloseAlertMessage}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseAlertMessage}
              severity={alertMessage.alertStatus}
            >
              {alertMessage.reason}
            </Alert>
          </Snackbar>
        </div>
      );
    }
  )
);

const HomePageSkeleton = () => {
  const classes = useStyles();
  return Array.from(new Array(2)).map((item, index) => (
    <Card className={classes.card} key={index}>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div style={{ flexBasis: "90%" }}>
          <CardHeader
            avatar={
              <Skeleton
                animation="wave"
                variant="circle"
                width={40}
                height={40}
              />
            }
            title={
              <Skeleton
                animation="wave"
                height={10}
                width="80%"
                style={{ marginBottom: 6 }}
              />
            }
            subheader={<Skeleton animation="wave" height={10} width="40%" />}
          />
        </div>
        <div
          style={{
            flexBasis: "10%",
            alignSelf: "center",
          }}
        >
          <Skeleton
            animation="wave"
            height={15}
            width="70%"
            style={{ marginBottom: "15px" }}
          />
        </div>
      </div>
      {<Skeleton animation="wave" variant="rect" className={classes.media} />}
      <CardContent>
        {
          <>
            <div style={{ display: "flex" }}>
              <Skeleton
                animation="wave"
                height={20}
                width="20%"
                style={{ marginBottom: "10px", marginRight: "10px" }}
              />
              <Skeleton
                animation="wave"
                height={20}
                width="20%"
                style={{ marginBottom: "10px", marginRight: "10px" }}
              />
              <Skeleton
                animation="wave"
                height={20}
                width="20%"
                style={{ marginBottom: "10px", marginRight: "10px" }}
              />
            </div>
            <>
              <Skeleton
                animation="wave"
                height={10}
                style={{ marginBottom: 6 }}
              />
              <Skeleton animation="wave" height={10} width="80%" />
            </>
          </>
        }
      </CardContent>
    </Card>
  ));
};

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: "100%",
    margin: theme.spacing(2),
  },
  media: {
    minHeight: 400,
  },
}));

export default HomePage;
