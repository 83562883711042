import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router-dom";

import "./index.css";
import PublicationService from "../../_services/publication.service";

/**
 *
 * @param {*} publication media :: image || video || audio
 * @param {*} preview :: generic publication image if publication filename does not exist
 * @returns
 *
 */
const PublicationMedia = (props) => {
  const {
    publication,
    preview,
    isSubscribe,
    authenticated,
    feetModelProfile,
    isOwner,
    ...rest
  } = props;
  const location = useLocation();

  const [url, setUrl] = useState("");

  const [play, setPlay] = useState(false);

  useEffect(() => {
    (async () => {
      let objectUrl = "";
      if (publication && publication.url.includes("storage.googleapis.com"))
        return;
      if (publication && publication.fsUrl) {
        const resFileSys =
          await PublicationService.getPublicationFromFileSystem(
            publication.fsUrl
          );
        const text = await new Response(resFileSys).text();
        if (text === "Input file is missing") {
          const resFileName = await PublicationService.getPublicationByFileName(
            publication.filename
          );
          objectUrl = URL.createObjectURL(resFileName);
        } else {
          objectUrl = URL.createObjectURL(resFileSys);
        }
      } else if (publication && publication.filename) {
        let resFileName1;
        if (publication.url.includes("v2")) {
          resFileName1 =
            await PublicationService.getPublicationMediaFromGoogleBucket(
              publication.filename
            );
        } else {
          resFileName1 = await PublicationService.getPublicationByFileName(
            publication.filename
          );
        }

        objectUrl = URL.createObjectURL(resFileName1);
      } else {
        setUrl(preview);
      }
      setUrl(objectUrl);
    })();
    return () => {
      setUrl("");
    };
  }, [publication, preview]);
  /*
  useEffect(() => {
    // render file from fs (file system storage) and not from database.
    if (publication && publication.fsUrl) {
      async function fetchPublication() {
        try {
          const fsUrl = !publication?.firstPublication
            ? publication.fsUrl
            : publication?.firstPublication.user?.ambassadress
            ? publication.firstPublication.fsUrl
            : publication.lastPublication.fsUrl;
          const response =
            await PublicationService.getPublicationFromFileSystem(
              publication.fsUrl
            );
          const text = await new Response(response).text();

          if (text === "Input file is missing") {
            // If file not found from fs; render file from database.

            const filename = !publication?.firstPublication
              ? publication.filename
              : publication?.firstPublication.user?.ambassadress
              ? publication.firstPublication.filename
              : publication.lastPublication.filename;
            const response = await PublicationService.getPublicationByFileName(
              publication.filename
            );
            const objectURL = URL.createObjectURL(response);

            setUrl(objectURL);
          } else {
            const objectURL = URL.createObjectURL(response);

            setUrl(objectURL);
          }
        } catch (error) {
        }
      }
      fetchPublication();
    } else if (publication && publication.filename) {
      //render file from database
      async function fetchPublication() {
        const filename = !publication?.firstPublication
          ? publication.filename
          : publication?.firstPublication.user?.ambassadress
          ? publication.firstPublication.filename
          : publication.lastPublication.filename;
        const response = await PublicationService.getPublicationByFileName(
          publication.filename
        );
        const objectURL = URL.createObjectURL(response);

        setUrl(objectURL);
      }

      fetchPublication();
    } else {
      if (preview) setUrl(preview);
    }
  }, [publication, preview]);
  */
  return (
    <>
      {publication && !publication.type?.includes("video") ? (
        <LazyLoadImage
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
          {...rest}
          src={
            publication.url?.includes("storage.googleapis.com")
              ? publication.url
              : url
          }
        />
      ) : null}

      {/* todo: need to improve style for video component */}
      {/* todo: it currently affects search-lover & view-own-profile-models page */}
      {publication && publication.type?.includes("video") ? (
        <ReactPlayer
          className={`video-container ${
            !isSubscribe && !isOwner ? "blur-video" : null
          }`}
          playsinline={true}
          // light={true}
          playing={play}
          url={
            publication.url?.includes("storage.googleapis.com")
              ? publication.url
              : url
          }
          onPause={() => setPlay(false)}
          onClick={() => isSubscribe || (isOwner && setPlay((prev) => !prev))}
          controls={
            !!location.pathname.includes(`/publication/${publication._id}`)
          }
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
        />
      ) : null}
    </>
  );
};

export default PublicationMedia;
