/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import speechToTextAtom from "../../../../../stateManagement/atoms/speechToTextAtom";
import messagesAtom from "../../../../../stateManagement/atoms/messagesAtom";
import { SocketContext } from "../../../../../context/socket";
import useSessionStorage from "../../../../forms/services/storageHooks/useSessionStorage";
import arrayMessagesListAtom from "../../../../../stateManagement/atoms/arrayMessagesListAtom";
import useNotificationAlert from "../../../../../hooks/useNotificationAlert";

const useMessageOrSpeech = () => {
  const { t } = useTranslation();
  const [speechToText, setSpeechToText] = useRecoilState(speechToTextAtom);
  const [inputValue, setInputValue] = useState("" || speechToText);
  const [error, setError] = useState("");
  const [sendMess, setSendMess] = useRecoilState(messagesAtom);
  const [sendedMess, setSendedMess] = useState(false);
  const [clickedStop, setClickedStop] = useState(false);
  const [speechClicked, setSpeechClicked] = useState(false);
  const [ArrayMessagesList, setArrayMessagesList] = useRecoilState(
    arrayMessagesListAtom
  );

  const [user] = useSessionStorage({}, "infosUser");
  const socket = useContext(SocketContext);

  const { addNotificationMessage } = useNotificationAlert();

  //  const arrayAllMessagesPictures = ArrayMessagesList.map(items => items).filter(pictItem => pictItem.includes('blob'))
  //  const arrayAllMessagesText = ArrayMessagesList.map(items => items).filter(pictItem => !pictItem.includes('blob'))
  //  const arrayAllMessagesHeart = ArrayMessagesList.map(items => items).filter(pictItem => pictItem.includes('heartSending'))

  const handleSendedTickles = (data) => {
    socket.sendMessage(data, (err, result) => {
      if (!err) {
        setArrayMessagesList((prev) =>
          prev.concat({
            from: { id: data.from, _id: data.from },
            to: { id: data.to },
            type: "Tickles",
            tickles: data.tickles,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          })
        );
      } else {
        addNotificationMessage(`${err}`, "error");
      }
    });
  };

  const handleSendMessages = async (userRecipient) => {
    if (inputValue !== "") {
      const content = inputValue;
      setSendedMess(true);
      setInputValue("");
      setSpeechToText("");

      socket.sendMessage(
        {
          content: inputValue,
          from: user.user?._id,
          to: userRecipient?._id,
          type: "Text",
        },
        (err, result) => {
          if (!err) {
            //populate message
            setArrayMessagesList((data) =>
              data.concat({
                from: user.user,
                to: userRecipient,
                type: "Text",
                content,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
              })
            );
            setSendMess((sendMess) => sendMess.concat(inputValue));
          } else {
            setError(err.message);
          }
        }
      );
      setSendMess('');
    }

    // if (sendedHeart === "heartSending") {

    //   socket.sendMessage({
    //     content: sendedHeart,
    //     from: user.user?._id,
    //     to: userRecipient?._id,
    //     type: "Text",
    //   });
    // }

    // if (ArrayMessagesList.map(items => items).filter(pictItem => pictItem.includes('heartSending'))){
    //   socket.sendMessage({
    //     content: 'heartSending',
    //     from: user.user?._id,
    //     to: userRecipient?._id,
    //     type: "Text",
    //   });
    // }

    // if (speechToText !== "") {
    //   setInputValue(speechToText)
    //   setSendedMess(true);
    //   setSendMess((sendMess) => sendMess.concat(inputValue));
    //   setSendedMess(true);
    //   setSpeechToText("");
    //   setInputValue("");

    //   socket.sendMessage({
    //     content: speechToText,
    //     from: user.user?._id,
    //     to: userRecipient?._id,
    //     type: "Text",
    //   });
    // }
  };

  const handleSendCMNotif = async (userRecipient) => {
    const content = 'custom media ready';
    setSendedMess(true);
    setInputValue("");
    setSpeechToText("");

    socket.sendMessage(
      {
        content: content,
        from: user.user?._id,
        to: userRecipient?._id,
        type: "Text",
      },
      (err, result) => {
        if (!err) {
          //populate message
          setArrayMessagesList((data) =>
            data.concat({
              from: user.user,
              to: userRecipient,
              type: "Text",
              content,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
            })
          );
          setSendMess((sendMess) => sendMess.concat(content));
        } else {
          setError(err.message);
        }
      }
    );
    setSendMess('');
  };

  useEffect(() => {
    if (speechClicked === true) {
      toast.dark(t("useMessageOrSpeechAction"), {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (speechClicked === false && clickedStop === true) {
      toast.dark(t("useMessageOrSpeechEndAction"), {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [clickedStop, speechClicked, t]);

  return {
    speechToText,
    setSpeechToText,
    inputValue,
    setInputValue,
    sendMess,
    setSendMess,
    sendedMess,
    setSendedMess,
    clickedStop,
    setClickedStop,
    speechClicked,
    error,
    setError,
    setSpeechClicked,
    handleSendMessages,
    ArrayMessagesList,
    setArrayMessagesList,
    handleSendedTickles,
    handleSendCMNotif,
    addNotificationMessage
  };
};

export default useMessageOrSpeech;
