import "./index.css";

import React, { useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import FR from "../../assets/images/country-flag/FR.e4779f9.png";
import { Popover } from "@material-ui/core";
import UK from "../../assets/images/country-flag/UK.73a7fb4.png";

const LanguageSelector = (props) => {
  const { locale, handleChangeLang, setOpen, open } = props;

  const handleLangClick = () => {
    setOpen((open) => !open);
  };

  const id = open ? "simple-popover" : undefined;

  return (
    <div className="lang-selector">
      <img
        src={locale === "fr" ? FR : UK}
        alt="lang-selector"
        style={{
          position: "fixed",
          right: 13,
          bottom: 20,
          border: "1px solid #ccc",
          borderRadius: "50%",
          width: 40,
          padding: 2,
          boxShadow: "0px 1px #ccc",
          cursor: "pointer",
          backgroundColor: "#F9C8D9",
        }}
        onClick={() => handleLangClick()}
      />
      <Popover
        id={id}
        open={open}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {locale === "fr" ? (
          <Avatar
            alt="lang-uk"
            src={UK}
            style={{ backgroundColor: "#FFFFFF" }}
            onClick={() => handleChangeLang("en")}
          />
        ) : (
          <Avatar
            alt="lang-fr"
            src={FR}
            style={{ backgroundColor: "#FFFFFF" }}
            onClick={() => handleChangeLang("fr")}
          />
        )}
      </Popover>
    </div>
  );
};

export default LanguageSelector;
