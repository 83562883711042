import { atom } from "recoil";

const favoritePictyAtom = atom({
  key: "favoritePictyState",
  default: [
    {
      Egyptian: 0,
      Roman: 0,
      Greek: 0,
      voyage: 0,
      mode: 0,
      sport: 0,
      water: 0,
      cooconing: 0,
      artiste: 0,
      Aiguille: 0,
      Bijoux: 0,
      chaussette: 0,
      Bas: 0,
      Basket: 0,
      Tatouage: 0,
      Vernis: 0,
      Mirror: 0,
      Pedales: 0,
      Jouer: 0,
      Vue: 0,
      Jambe: 0,
      PiedMain: 0,
      Chatouilles: 0,
      Copines: 0,
      Public: 0,
      Arche: 0,
      Orteils: 0,
      Talons: 0,
      Selfeet: 0,
      Plisses: 0,
      PlantePied: 0,
      OrteilsEcartes: 0,
      OnglesLongs: 0,
      Osseux: 0,
      Genereux: 0,
      OrteilsCourts: 0,
      OrteilsLongs: 0,
    },
  ],
});

export default favoritePictyAtom;
