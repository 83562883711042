import React from "react";

const NoMessagesYetComponent = (props) => {
  const { fakeReceivedTickles, NoMess, t } = props;

  return (
    <div className="createMessage_no_mess_yet">
      <img
        onClick={() => fakeReceivedTickles()}
        src={NoMess}
        alt="no message"
        className="createMessage__noMessPict"
      />
      <b>{t("createMessageNoMessYet")}</b>
    </div>
  );
};

export default NoMessagesYetComponent;
