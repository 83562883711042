import "./styled.css";

import { ChevronLeft } from "@material-ui/icons";
import { Container } from "@material-ui/core";
import { IconButton } from "@material-ui/core/";
import React from "react";
import signUpUserDataAtom from "../../stateManagement/atoms/auth/sign-up-user-data-atom";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

function ConfirmRegistration() {
  const history = useHistory();
  const { t } = useTranslation();

  const [signUpUserData] = useRecoilState(signUpUserDataAtom);

  const handleRegisteredEmail = (email) => {
    const translatedText = t("confirmationMessage").replace(
      "__registered_email__",
      email
    );
    return <p>{translatedText}</p>;
  };

  return (
    <div className="confirm-registration-container">
      <div className="profileForm__header header page_fixed_header edit-profile-header__v3">
        <IconButton
          className="back__button"
          onClick={() => {
            history.goBack();
          }}
        >
          <ChevronLeft style={{ fill: "black" }} />
        </IconButton>
        <h4>{t("confirmationPageTitle")}</h4>
      </div>
      <Container>
        {handleRegisteredEmail(signUpUserData?.email || "")}
      </Container>
    </div>
  );
}

export default ConfirmRegistration;
