/* eslint-disable no-unused-vars */
import React, {Fragment, useEffect, useRef, useState} from "react";
import { Avatar, Button } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useRecoilState, useSetRecoilState } from "recoil";
import { isIOS } from "react-device-detect";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Bugsnag from "@bugsnag/js";

import "./postPublication.css";
import useSelectAndRemove from "./hooks/useSelectAndRemove";
import FavoritePicty from "../../favoritePicty/FavoritePicty";
import useCumulAndPersist from "./hooks/useCumulAndPersist";
import PublicationService from "../../../_services/publication.service";
import Loader from "../../../components/loading-page/Loader";
import postPublicationSelectedPictiesAtom from "../../../stateManagement/atoms/postPublicationPictiesAtom";
import PictyFilters from "../../pictyFilters/PictyFilters";
import {
  studioAtom,
  userTypeAtom,
} from "../../../stateManagement/atoms/studioAtom";
import isFromCameraAtom from "../../../stateManagement/atoms/publication/isFromCamera";
import setIsFromCameraPublicationImage from "../../../stateManagement/selectors/publication/setIsFromCamera";
import CloseIcon from "../../../assets/images/pict/close.svg";
import useSessionStorage from "../../forms/services/storageHooks/useSessionStorage";
import PrePublicationBase64Service from "../../../_services/pre-publication-base64.service";
import uploadFileAtom from "../../../stateManagement/atoms/publication/uploadFileAtom";
import useNotificationAlert from "../../../hooks/useNotificationAlert";
import { handleImageCompression } from "../../../helpers/browser-image-compression";
import { isVideo } from "../../../helpers";
import { useTranslation } from "react-i18next";
import {uploadMedias} from "../../../_services/firebase.service";

const PostPublication = (props) => {
  const { i18n, t } = useTranslation();
  const history = useHistory();

  const { addNotificationMessage } = useNotificationAlert();
  const [__] = useSessionStorage({}, "infosUser");
  const [isFromCameraPublicationImage] = useRecoilState(isFromCameraAtom);
  const setIsFromCameraPublicationImageSelector = useSetRecoilState(
    setIsFromCameraPublicationImage
  );
  const [uploadFilePublicationAtom] = useRecoilState(uploadFileAtom);

  // Get style from Local Storage
  const [imgstylePublication] = useState(
    JSON.parse(localStorage.getItem("stylePublication"))
  );

  const [userType, setUserType] = useRecoilState(userTypeAtom);
  const [onStudio, setOnStudio] = useRecoilState(studioAtom);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [
    selectedPictiesForPostPublication,
    setSelectedPictiesForPostPublication,
  ] = useRecoilState(postPublicationSelectedPictiesAtom);
  const [privatePublication, setPrivatePublication] = useState(false);

  const {
    selectedPictiesList,
    selected1,
    selected2,
    selected3,
    selected4,
    selected5,
    handleRemove1,
    handleRemove2,
    handleRemove3,
    handleRemove4,
    handleRemove5,
    limitCompleted,
  } = useSelectAndRemove();

  const [base64File, setBase64File] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadFileUrl, setUploadFileUrl] = useState(null);
  const [uploadFileLoaded, setUploadFileLoaded] = useState(false);
  const [duration, setDuration] = useState(0);

  const videoEl = useRef(null);

  const {
    handleSubmitPictyCount,
    favoritePictyList,
    calculTransitionZonePictyFavorite,
    NewTotalCount,
  } = useCumulAndPersist();

  useEffect(() => {
    setBase64File(history.location.state[0]);
    setUploadFile(history.location.state[0]);
    setUploadFileUrl(URL.createObjectURL(history.location.state[0]));
  }, [history.location.state]);

  useEffect(() => {
    setUserType("Model");
    // if (onStudio === true) {
    //   setOnStudio(false);
    // }
    if (favoritePictyList !== calculTransitionZonePictyFavorite) {
      calculTransitionZonePictyFavorite.push(
        JSON.parse(localStorage.getItem("Final"))
      );
    }
  }, [
    setUserType,
    setOnStudio,
    onStudio,
    favoritePictyList,
    calculTransitionZonePictyFavorite,
    NewTotalCount,
  ]);

  useEffect(() => {
    localStorage.setItem("calculTransition", JSON.stringify(favoritePictyList));

    if (localStorage.getItem("Final") === null) {
      localStorage.setItem("Final", JSON.stringify(favoritePictyList));
    }

    if (NewTotalCount.length === 0) {
      NewTotalCount.push(JSON.parse(localStorage.getItem("calculTransition")));
    }
  }, [
    setUserType,
    setOnStudio,
    onStudio,
    NewTotalCount,
    favoritePictyList,
    calculTransitionZonePictyFavorite,
  ]);

  const handleCleanLocalStorage = () => {
    localStorage.removeItem("resultPost");
    localStorage.removeItem("styleImg");
    localStorage.removeItem("favorite");
    localStorage.removeItem("calculTransition");
  };

  useEffect(() => {
    return () => setSelectedPictiesForPostPublication([]);
  }, [setSelectedPictiesForPostPublication]);

  /*
  useEffect(() => {
    if (base64File) {
      // set publication private to true if file is video
      base64File.split(",")[0].includes("video") && setPrivatePublication(true);
    }
  }, [base64File]);
  */

  useEffect(() => {
    if (uploadFile) {
      // set publication private to true if file is video
      uploadFile?.type.includes("video") && setPrivatePublication(true);
    }
  }, [uploadFile]);

  const handleCBUpload = async (success, response_url) => {
    success && await PublicationService.postPublicationBlobFile(uploadFile, {
      description,
      ...(selectedPictiesForPostPublication &&
          selectedPictiesForPostPublication.length && {
            picties: selectedPictiesForPostPublication.map(
                (picty) => picty._id
            ),
          }),
      private: privatePublication,
      isFromCamera: isFromCameraPublicationImage,
    }, response_url);

      setIsFromCameraPublicationImageSelector(false);
      handleSubmitPictyCount();
      handleCleanLocalStorage();
      setLoading(false);
      setSelectedPictiesForPostPublication([]);
      setOnStudio(false);
      success ? addNotificationMessage(t("ppUploadingSuccess"), "success") : addNotificationMessage(t("ppUploadingValidateError"), "error");

      history.push({
        pathname: "/home-page-models",
        state: {
          reload: true, // quick hack to make sure the home-page-models will reload after posting publication.
          // todo: maybe pass the publication here so that we can just pre-pend the data inside home-page-models
        },
      });
  }

  const handlePostPublication = async () => {
    try {
      setLoading(true);

      // instance of Blob type
      if (uploadFile) {
        // if (uploadFile?.type.includes("image") || (duration && duration > 0)) {
          if (uploadFile?.type.includes("image") || (uploadFile?.type.includes("video"))) { // && (duration <= 121)
            console.log("videoDuration: ", duration)
            addNotificationMessage(t("ppUploadingInProgress"), "info");
            await uploadMedias(uploadFile, setProgress, handleCBUpload, 'publication');
          } else {
            console.log("Error Uploading Video: Video length exceed the max requirement which invalid.")
            setLoading(false);
            history.push({
              pathname: "/home-page-models",
              state: {
                reload: true,
              },
            });
            addNotificationMessage(t(" ppUploadingFailed"), "error");
          }
        // } else {
        //   console.log("Post Publication Error: Video has a bad format!")
        //   setLoading(false);
        //   addNotificationMessage(t("ppUploadingBadFormatError"), "error");
        // }


        // compress file. if compression fail it will just return the original file.
        // const compressedFile =
        //   !isVideo(uploadFile) && (await handleImageCompression(uploadFile));
        // const publicationFileToUpload = compressedFile || uploadFile;
      }
      /*
       else {
        //base64 file. (already compressed)
        await PublicationService.postPublicationBase64File(base64File, {
          description,
          ...(selectedPictiesForPostPublication &&
            selectedPictiesForPostPublication.length && {
              picties: selectedPictiesForPostPublication.map(
                (picty) => picty._id
              ),
            }),
          private: privatePublication,
          isFromCamera: isFromCameraPublicationImage,
        });
      }
      */
    } catch (error) {
      console.error("Post Publication Error: ", { error });
      Bugsnag.notify(error?.message);
      addNotificationMessage(t("ppUploadingFailed"), "error");
    }
  };

  const handlePrivatePublication = () => {
    if (privatePublication) {
      setPrivatePublication(false);
    } else if (!privatePublication) {
      setPrivatePublication(true);
    }
  };

  useEffect((e) => {
    if (!__?.user?.idCheckDetails?.verified) {
      history.replace("/identity-verification");
    }
  }, []);

  /*
  // we're not using the pre-publication anymore to prevent freezing
  useEffect(() => {
    (async () => {
      // check if upload file for publication is available in global state atom
      if (uploadFilePublicationAtom) {
        setUploadFile(uploadFilePublicationAtom);
        setUploadFileUrl(URL.createObjectURL(uploadFilePublicationAtom));
        setBase64File(null);
      } else {
        // else if upload file for publication is not available in global state atom anymore -
        // due to refresh or reload. use the backup from the session storage with key pre-publication-base64-file-id and fetch that id from db to return the base64 file.

        const fetchBase64PublicationFile = async () => {
          const response = await PrePublicationBase64Service.findPrePublicationBase64File(
            window.sessionStorage.getItem("pre-publication-base64-file-id")
          );
          setBase64File(response.base64);
          setUploadFile(null);
          setUploadFileUrl(null);
        };

        if (window.sessionStorage.getItem("pre-publication-base64-file-id")) {
          fetchBase64PublicationFile();
        }

        // if (window.localStorage.getItem("resultPost")) {
        //   setBase64File(window.localStorage.getItem("resultPost"));
        //   setUploadFile(null);
        //   setUploadFileUrl(null);
        // }
      }
    })();
  }, [uploadFilePublicationAtom]);
*/
  const handleLoadedMetadata = () => {
    const video = videoEl.current;
    if (!video) return;
    setDuration(video.duration)
    console.log(`The video is ${video.duration} seconds long.`);
  };
  useEffect(() => {
    if (uploadFileLoaded)
      handleLoadedMetadata()
  }, [uploadFileLoaded])

  return (
    <>
      {loading ? (
        <Loader progress={progress}/>
      ) : (
        <div className="postPublication__container">
          <div className="postPublication__header header page_fixed_header">
            <Button className="back__button" onClick={() => history.goBack()}>
              <ChevronLeft />
            </Button>
            <h4>{t("postPublicationTitle")}</h4>
            <Button
              onClick={handlePostPublication}
              className="header__rightButtton"
            >
              {t("postPublicationButton")}
            </Button>
          </div>
          <div className="content__withHeader minmax__wrapper">
            <div
              className={
                isIOS === true
                  ? "postPublication__content postPublication__contentIphone"
                  : "postPublication__content"
              }
            >
              {uploadFile && uploadFile?.type.includes("video") && (
                <div className="postPublication__withWarningText">
                  <div className="dummy-element"></div>
                  <div className="warning-text"><span>{t("onlyOnPrivateWall")}</span></div>
                </div>
              )}
              <div className="postPublicationContent__top">
                <div className="postPublicationContentTop__img">
                  {/* This is just a loader while the file is not yet fully loaded */}
                  {!uploadFileLoaded && (
                    <div
                      style={{
                        display: "flex",
                        top: "50%",
                        left: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                      }}
                    >
                      <CircularProgress color="secondary" size={10} />
                    </div>
                  )}

                  {/* if using File type an instance of Blob */}
                  {uploadFile && uploadFile?.type.includes("image") && (
                    <img
                      src={uploadFileUrl}
                      alt="publication"
                      style={imgstylePublication}
                      width="100%"
                      height="100%"
                      onLoad={() => setUploadFileLoaded(true)}
                    />
                  )}

                  {/* if using File type an instance of Blob */}
                  {uploadFile && uploadFile?.type.includes("video") && (
                    <video
                      src={uploadFileUrl}
                      width="100%"
                      height="100%"
                      controls
                      ref={videoEl}
                      onLoadedData={() => setUploadFileLoaded(true)}
                    ></video>
                  )}

                  {/* if using base64 file */}
                  {/* {base64File && base64File.split(",")[0].includes("image") && (
                    <img
                      src={base64File}
                      alt="publication"
                      style={imgstylePublication}
                      width="100%"
                      height="100%"
                      onLoad={() => setUploadFileLoaded(true)}
                    />
                  )} */}

                  {/* if using base64 file */}
                  {/* {base64File && base64File.split(",")[0].includes("video") && (
                    <video
                      src={base64File}
                      width="100%"
                      height="100%"
                      controls
                      onLoadedData={() => setUploadFileLoaded(true)}
                    ></video>
                  )} */}
                </div>

                <div className="postDetailSettings">
                  <div className="postPublicationContentTop__privatePublicationContent">
                    <p>{privatePublication ? t("ppPrivate") : t("ppPublic")}</p>
                    <div className="onoffswitch postPublicationContentTop__privatePublication">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        className="onoffswitch-checkbox"
                        id="myonoffswitch1"
                        tabIndex="0"
                        checked={privatePublication}
                        onClick={handlePrivatePublication}
                        disabled={
                          uploadFile && uploadFile?.type.includes("video")
                        }
                        // disabled={
                        //   base64File
                        //     ? base64File.split(",")[0].includes("video")
                        //     : uploadFile && uploadFile?.type.includes("video")
                        // }
                      />
                      <label
                        className="onoffswitch-label"
                        htmlFor="myonoffswitch1"
                      ></label>
                    </div>
                    <p className="advicePrivatePublication">{privatePublication && (uploadFile && uploadFile?.type.includes("video")) ? t("ppAdvicePrivate") : null}</p>
                  </div>
                  <textarea
                    placeholder={t("ppPlaceholder")}
                    className="postPublication__textArea"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              <div className="postPublication__withWarningText">
                <div className="dummy-element"></div>
                <div className="warning-text">
                  <a href={
                    i18n.languages === "en" ? 
                    "https://feety.com/en/publishing-rules" :
                    "https://feety.com/regles-publication"}>{t("publishingRules")}</a>
                </div>
              </div>
              <div className="postPublicationcontent__selectedPicty">
                <div className="postPublicationcontent__selectedPictyText">
                  <b>{t("selectedPicty")}</b>
                  <p>{`( ${selectedPictiesList.length}/5 )`}</p>
                </div>
                <div className="postPublicationSelectedPicty__list picty__container">
                  {selectedPictiesList[0] !== undefined ? (
                    <div>
                      <Fragment>
                        <Avatar
                          src={selected1}
                          className="postPublication__selectedAvatar"
                        />
                        <span className="postPublicationPictylist__button">
                          <img
                            onClick={() => handleRemove1()}
                            className="postPublicationPictyList__deleteButton"
                            src={CloseIcon}
                          />
                        </span>
                      </Fragment>
                      {selectedPictiesList[0] !== undefined ? (
                        <p>{t(selectedPictiesList[0])}</p>
                      ) : (
                        <p style={{ opacity: 0 }}>{"     "}</p>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Avatar style={{ opacity: 0.1 }} />
                    </div>
                  )}
                  {selectedPictiesList[1] !== undefined ? (
                    <div>
                      <Fragment>
                        <Avatar
                          src={selected2}
                          className="postPublication__selectedAvatar"
                        />
                        <span className="postPublicationPictylist__button">
                          <img
                            onClick={() => handleRemove2()}
                            className="postPublicationPictyList__deleteButton"
                            src={CloseIcon}
                          />
                        </span>
                      </Fragment>
                      {selectedPictiesList[1] !== undefined ? (
                        <p>{t(selectedPictiesList[1])}</p>
                      ) : (
                        <p style={{ opacity: 0 }}>{"     "}</p>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Avatar style={{ opacity: 0.1 }} />
                    </div>
                  )}
                  {selectedPictiesList[2] !== undefined ? (
                    <div>
                      <Fragment>
                        <Avatar
                          src={selected3}
                          className="postPublication__selectedAvatar"
                        />
                        <span className="postPublicationPictylist__button">
                          <img
                            onClick={() => handleRemove3()}
                            className="postPublicationPictyList__deleteButton"
                            src={CloseIcon}
                          />
                        </span>
                      </Fragment>
                      {selectedPictiesList[2] !== undefined ? (
                        <p>{t(selectedPictiesList[2])}</p>
                      ) : (
                        <p style={{ opacity: 0 }}>{"     "}</p>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Avatar style={{ opacity: 0.1 }} />
                    </div>
                  )}
                  {selectedPictiesList[3] !== undefined ? (
                    <div>
                      <Fragment>
                        <Avatar
                          src={selected4}
                          className="postPublication__selectedAvatar"
                        />
                        <span className="postPublicationPictylist__button">
                          <img
                            onClick={() => handleRemove4()}
                            className="postPublicationPictyList__deleteButton"
                            src={CloseIcon}
                          />
                        </span>
                      </Fragment>
                      {selectedPictiesList[3] !== undefined ? (
                        <p>{t(selectedPictiesList[3])}</p>
                      ) : (
                        <p style={{ opacity: 0 }}>{"     "}</p>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Avatar style={{ opacity: 0.1 }} />
                    </div>
                  )}
                  {selectedPictiesList[4] !== undefined ? (
                    <div>
                      <Fragment>
                        <Avatar
                          src={selected5}
                          className="postPublication__selectedAvatar"
                        />
                        <span className="postPublicationPictylist__button">
                          <img
                            onClick={() => handleRemove5()}
                            className="postPublicationPictyList__deleteButton"
                            src={CloseIcon}
                          />
                        </span>
                      </Fragment>
                      {selectedPictiesList[4] !== undefined ? (
                        <p>{t(selectedPictiesList[4])}</p>
                      ) : (
                        <p style={{ opacity: 0 }}>{"     "}</p>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Avatar style={{ opacity: 0.1 }} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="postPublication__PictiesChoice">
              <div
                className={
                  isIOS === true
                    ? "postPublicationPictiesChoice__favorites postPublicationPictiesChoice__favoritesIphone"
                    : "postPublicationPictiesChoice__favorites"
                }
              >
                <FavoritePicty isDisabled={limitCompleted} />

                <PictyFilters isDisabled={limitCompleted} userType={userType} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PostPublication;
