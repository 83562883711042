import { Button } from "@material-ui/core";
import React, { Fragment } from "react";
import cross from "../../../assets/images/unlock-publication/Group 54930@2x.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BuyTicklesModalContent = () => {
  let history = useHistory();
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="BuyTicklesModal__content">
        <div className="BuyTicklesModal__header">
          <h2 id="simple-modal-title">{t("buyTicklesModalTitle")}</h2>
          <img
            src={cross}
            alt="close"
            onClick={() => history.push("/create-messages")}
          />
        </div>
        <div className="BuyTicklesModal__body">
          <div className="BuyTicklesModal__top">
            <p>{t("buyTicklesModalText")}</p>
          </div>
          <div className="BuyTicklesModal__center">
            <div className="BuyTicklesModal__center-button">
              <Button
                variant="contained"
                className="BuyTicklesModal__button"
                onClick={() => history.push("/buy-tickles-credit")}
              >
                <h3>{t("buyTicklesModalSendButton")}</h3>
              </Button>
              <Button
                variant="contained"
                className="BuyTicklesModal__button"
                onClick={() => history.push("/create-messages")}
              >
                <h3>{t("buyTicklesModalCancelButton")}</h3>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BuyTicklesModalContent;
