import React, { Fragment } from "react";
import Modal from "@material-ui/core/Modal";
import "./Withdraw.css";
import ModalContent from "./WithdrawModalContent";
import { useHistory } from "react-router-dom";

function getModalStyle() {
  const top = 47;
  const left = 48;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    border: "none !important",
  };
}

export default function Withdraw({
  userType,
  setUserType,
  sendedTickles,
  setSendedTickles,
}) {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(true);
  let history = useHistory();

  const handleClose = () => {
    setOpen(false);
    history.push("/tickles-account");
  };

  // Boby of the modal content
  const body = (
    <div style={modalStyle} className="Withdraw__paper modal-Withdraw">
      <ModalContent
        sendedTickles={sendedTickles}
        setSendedTickles={setSendedTickles}
        userType={userType}
        setUserType={setUserType}
      />
    </div>
  );

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Withdraw-simple-modal-title"
        aria-describedby="Withdraw-simple-modal-description"
      >
        {body}
      </Modal>
    </Fragment>
  );
}
