import { selector } from "recoil";

import likeBadge from "../../../atoms/notifications/badge/like";

const setNotificationLikeBadgeInVisible = selector({
  key: "setNotificationLikeBadgeInVisibleSelector",
  set: ({ set }, value) => {
    set(likeBadge, value);
  },
});

export default setNotificationLikeBadgeInVisible;
