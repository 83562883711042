export const convertTickles = (tickles) => {
  const ht = tickles / 100;
  const tva = ht * 0.2;
  const converted = ht + tva;
  let result = 0;
  if (!isNaN(converted)) result = converted;

  return result.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

export const convertModelsTickles = (tickles) => {
  const ht = tickles / 100;
  const ttc = ht * 1.2;
  const epoch = ttc * 0.15;
  const tva = (ttc / 1.2) * 0.2;
  const feety = (ttc - epoch - tva) * 0.2;
  const converted = ttc - epoch - tva - feety;
  let result = 0;
  if (!isNaN(converted)) result = converted;

  return result.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};
