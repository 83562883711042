import { selector } from "recoil";

import comments from "../../atoms/notifications/comments";

const addComment = selector({
  key: "addComment",
  set: ({ set, get }, newComment) => {
    set(comments, [...get(comments), newComment]);
  },
});

export default addComment;
