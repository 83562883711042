/* eslint-disable no-unused-vars */
import { Button, Dialog, DialogTitle, Divider } from "@material-ui/core";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import ticklesAtom from "../../../stateManagement/atoms/ticklesAtom";
import { SocketContext } from "../../../context/socket";
import useSessionStorage from "../../forms/services/storageHooks/useSessionStorage";
import { getAuthSession } from "../../../helpers/auth.helpers";
import TickleService from "../../../_services/tickle.service";
import UserService from "../../../_services/user.service";
import useNotificationAlert from "../../../hooks/useNotificationAlert";
import { useStyles } from "../../BuyTickleModal/styled";

const SendTicklesModalContent = ({
  sendedTickles,
  setSendedTickles,
  recipient,
  handleClose,
  handleSendedTickles,
  publicationForTickle,
  authenticatedUser,
  setOpenBuyTickleModal,
  ticklesIcon,
}) => {
  const [ticklesHave, setTicklesHave] = useRecoilState(ticklesAtom);
  const [numberTickles, setNumberTickles] = useState("");
  const [isTickleEnabled, setTickleEnabled] = useState(false);
  const [activeTickles, setActiveTickles] = useState(0);
  const [openInfoMessage, setOpenInfoMessage] = useState(false);
  let history = useHistory();
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const classes = useStyles();
  const [authenticated, setAuthenticated] = useSessionStorage({}, "infosUser");

  const { addNotificationMessage } = useNotificationAlert();

  const staticTickles = [1000, 200, 50];

  const decrementTickles = () => {
    if (Number(numberTickles) <= Number(ticklesHave)) {
      setTicklesHave(ticklesHave - numberTickles);
      window.localStorage.setItem("tickles", ticklesHave - numberTickles);
      window.localStorage.setItem("numberTickles", numberTickles);
      history.push("/create-messages");
    }
    if (Number(ticklesHave) < Number(numberTickles)) {
      setTicklesHave(ticklesHave);
      return alert(
        `Vous n'avez pas assez de Tickles, il vous en reste seulement ${ticklesHave}`
      );
    }
  };

  const handleSendTickle = async () => {
    if (publicationForTickle) {
      try {
        const response = await TickleService.sendTickleViaPublication({
          publication: publicationForTickle,
          tickles: numberTickles,
        });

        addNotificationMessage("Success send tickles", "success");
      } catch (error) {
        addNotificationMessage(error.response.data.error, "error");
      }
    } else {
      if (!numberTickles) {
        setOpenInfoMessage(true);
      }

      if (activeTickles < numberTickles || 0) {
        addNotificationMessage("Insufficient Tickles", "error");
        return;
      } else {
        handleSendedTickles({
          from: authenticated.user._id,
          to: recipient._id,
          tickles: numberTickles,
          type: "Tickles",
        });
      }
    }

    handleClose();
  };

  useEffect(() => {
    const { _id } = getAuthSession().user;
    UserService.getUser(_id)
      .then((response) => {
        const tickles = response.user?.tickles || 0;
        setActiveTickles(tickles);
      })
      .catch((error) => error);
  }, []);

  return (
    <Fragment>
      <div className="SendTickles__content">
        <div className="SendTickles__header">
          <h2 id="simple-modal-title">{t("sendTicklesTitle")}</h2>
          <img
            src={ticklesIcon}
            alt="tickles icon"
            style={{ position: "absolute", right: "100px" }}
          />
        </div>
        <div className="SendTickles__body">
          <div className="SendTickles__top">
            {/*<p>{recipient.username}</p>*/}
            <p>
              {`${t("sendTicklesText")} `}
              <span style={{ color: "#ff2775" }}>{t("sendTicklesTextInfo")}</span>
            </p>
          </div>
          <div className="SendTickles__center">
            <div className="send-tickles-prices">
              {staticTickles.map((item, index) => (
                <div
                  key={index}
                  className={`send-tickles-prices-item ${
                    item.toString() === numberTickles &&
                    "send-selected-tickle-product"
                  }`}
                  onClick={(e) => setNumberTickles(item.toString())}
                >
                  <div className="send-tickles-prices-item-icon">
                    <span>{item.toLocaleString()}</span>
                    <img
                      width="20"
                      height="20"
                      src={ticklesIcon}
                      alt="tickle-price-icon"
                    />
                  </div>
                </div>
              ))}
            </div>
            <h4>Other amount</h4>
            <input
              value={numberTickles}
              onChange={(e) =>
                setNumberTickles(e.target.value.replace(/\D/g, ""))
              }
              className="SendTickles__input"
              type="text"
              placeholder="Number of tickles"
            />
          </div>
          <div className="SendTickles__bottom">
            <div className="SendTickles__center-button">
              <Button
                variant="contained"
                className="SendTickles__button"
                onClick={() => handleClose()}
              >
                <h3>{t("sendTicklesCancelButton")}</h3>
              </Button>
              <Button
                variant="contained"
                className="SendTickles__button"
                // disabled={!isTickleEnabled}
                onClick={() => {
                  if (numberTickles) {
                    if (
                      authenticatedUser &&
                      authenticatedUser.tickles &&
                      authenticatedUser.tickles < numberTickles
                    ) {
                      // not enough tickles. show buy tickles modal
                      setOpenBuyTickleModal(true);

                      addNotificationMessage("Not enough tickles", "warning");
                    } else {
                      // enough tickles. proceed send tickles
                      handleSendTickle();
                    }
                  } else {
                    setOpenInfoMessage(true);
                  }
                }}
              >
                <h3>{t("sendTicklesSendButton")}</h3>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        className={classes.dialogRoot}
        open={openInfoMessage}
        onClose={() => {
          setOpenInfoMessage(false);
        }}
      >
        <DialogTitle style={{ fontFamily: "Roboto", textAlign: "center" }}>
          You have to pick an amount to continue
        </DialogTitle>
        <Divider />
        <button
          onClick={() => {
            setOpenInfoMessage(false);
          }}
          style={{
            backgroundColor: "white",
            padding: "10px",
            border: "none",
            // borderTop: "0.02rem solid #9F9F9F",
            color: "#ff2775",
            fontSize: "	1.1rem",
            fontWeight: "700",
            fontFamily: "Roboto",
          }}
        >
          {t("Ok")}
        </button>
      </Dialog>
    </Fragment>
  );
};

export default SendTicklesModalContent;
