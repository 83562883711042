import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Paper } from "@material-ui/core";
import PublicMediaComponent from "./PublicMediaComponent";
import PrivateMediaComponent from "./PrivateMediaComponent";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import starIcon from "../../../assets/images/view-feet-models-profile/picto/Calque_1.svg";
import "./viewFeetModelsProfile.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`all-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="panel-box" p={3}>
          <div className="children-encapsulation">{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `all-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "inherit !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
}));

export default function TabChoiceModelProfile(props) {
  const location = useLocation();
  const { feetModelProfile, isSubscriptionSuspended, isBlogger } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (location && location.state) {
      if (location.state.mediaTab === 0) {
        setValue(0);
      } else {
        setValue(1);
      }
    }
  }, [location]);

  return (
    <div className={classes.root + " tab-choice-feet-models"}>
      <Paper className={classes.root}>
      <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Media Choice Tabs"
          className="profile-wall-tabs"
        >
          <Tab label={t("publicWall")} {...a11yProps(0)} />
          <Tab
            label={t("privateWall")}
            icon={<img className="coralStar" src={starIcon} alt="" />}
            {...a11yProps(1)}
          />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <PublicMediaComponent />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PrivateMediaComponent feetModelProfile={feetModelProfile} isSubscriptionSuspended={isSubscriptionSuspended} isBlogger={isBlogger}/>
      </TabPanel>
    </div>
  );
}
