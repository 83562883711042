import axios from "axios";
import { useState } from "react";

const FacebookLogin = () => {
  const [facebook, setFacebook] = useState(null);
  const { REACT_APP_API_AUTH_URL } = process.env;

  const LoginWithFacebook = (access_token) =>
    axios
      .get(`${REACT_APP_API_AUTH_URL}/facebook`, { access_token })
      .then((res) => {
        const response = res.data;
        setFacebook(response);
      });

  LoginWithFacebook();

  return [facebook];
};

export default FacebookLogin;
