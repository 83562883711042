/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { Autorenew, CloseOutlined } from "@material-ui/icons/";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import "./cameraPicture.css";

import { studioAtom } from "../../../stateManagement/atoms/studioAtom";

const CameraPicture = () => {
  const location = useLocation();
  let history = useHistory();
  const [onStudio, setOnStudio] = useRecoilState(studioAtom);
  const [, setImgSource] = useState(null);
  const webcamRef = useRef(null);
  const [clickedFlip, setClickedFlip] = useState(true);
  const [clickedPicture, setClickedPicture] = useState(false);
  const [isFromCreateMessage, setIsFromCreateMessage] = useState(false);
  const [isFromCreateMessageData, setIsFromCreateMessageData] = useState({});

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSource(imageSrc);
    window.localStorage.setItem("preview", imageSrc);
    // window.sessionStorage.setItem('publicationPictureCamera', imageSrc)

    setTimeout(() => {
      history.push({
        pathname: `/hello-picture`,
        state: {
          isFromCreateMessage,
          receiver: isFromCreateMessageData.receiver,
          convoId: isFromCreateMessageData.convoId,
        },
      });
    }, 2000);
  }, [webcamRef, history, isFromCreateMessage, isFromCreateMessageData]);

  useEffect(() => {
    if (location && location.state && location.state.fromCreateMessage) {
      setIsFromCreateMessage(true);
      setIsFromCreateMessageData({
        receiver: location.state.receiver,
        convoId: location.state.convoId,
      });
    }
  }, [location]);

  useEffect(() => {
    if (onStudio === false && isFromCreateMessage === false) {
      setOnStudio(true);
    }
  }, [onStudio, setOnStudio, isFromCreateMessage]);

  const handleClickedFlip = () => {
    setClickedFlip(clickedFlip !== true);
  };

  const handleClickedPicture = () => {
    setClickedPicture(clickedPicture !== true);
  };

  useEffect(() => {
    if (clickedPicture === true) {
      setTimeout(() => {
        return setClickedPicture(false) && history.push("/studio");
      }, 1500);
    }
  }, [clickedPicture, history]);

  function closeCurrentWindow() {
    // window.close();

    if (isFromCreateMessage) {
      setOnStudio(false);
      history.goBack();
    } else {
      history.push("/studio");
    }
  }

  const videoConstraints = (flip) => {
    const defaultProps = {
      facingMode: { exact: "environment" },
      width: { min: 480 },
      height: { min: 480 },
      aspectRatio: 200,
      quality: 200,
    };
    if (flip) {
      return {
        ...defaultProps,
        screenshotQuality: 1,
      };
    }
    return {
      ...defaultProps,
      facingMode: { exact: "user" },
      mirrored: true,
    };
  };

  return (
    <div className="camera-picture-content">
      <Webcam
        className="video-picture"
        audio={false}
        // height={480}
        ref={webcamRef}
        screenshotFormat="image/webp"
        screenshotQuality={1}
        // width={480}
        videoConstraints={videoConstraints(clickedFlip)}
        forceScreenshotSourceSize={true}
      />
      <div>
        <div className="div-buttons-picture-component">
          <div className="div-button-close-component">
            {clickedPicture === true ? (
              ""
            ) : (
              <button
                style={{ fontSize: 18 }}
                onClick={() => {
                  closeCurrentWindow();
                }}
                className="button-close-component"
              >
                <CloseOutlined style={{ color: "white" }} />
              </button>
            )}
          </div>
          <div className="div-button-take-picture-component">
            <button
              className={
                clickedPicture === true
                  ? "button-take-picture-component blink_me_pict"
                  : "button-take-picture-component"
              }
              onClick={() => {
                handleClickedPicture();
                capture();
              }}
            >
              {""}
            </button>
          </div>
          <div className="div-button-flip-component">
            {clickedPicture === true ? (
              ""
            ) : (
              <button
                className="button-flip-camera-component"
                onClick={() => handleClickedFlip()}
              >
                <Autorenew />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraPicture;
