import { selector } from "recoil";

import followerBadge from "../../atoms/notifications/followerBadge";

const setNotificationFollowerBadgeVisible = selector({
  key: "setNotificationFollowerBadgeInvisibleSelector",
  set: ({ set }, value) => {
    set(followerBadge, value);
  },
});

export default setNotificationFollowerBadgeVisible;
