import { Dialog, Divider } from "@material-ui/core";
import React from "react";

import DialogTitle from "../../../../../BuyTickleModal/DialogTitle";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../../../pages/SuppressAccount/styled";
import FollowService from "../../../../../../_services/follow.service";

function DeleteFollower(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    openModal,
    setOpenModal,
    data,
    handleReduceFollowersCount,
    setUsers,
  } = props;
  return (
    <Dialog
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      className={classes.suppressDialog}
    >
      <DialogTitle>
        <p style={{ marginTop: "10px" }}>{t("modalYesNo")}</p>
      </DialogTitle>
      <Divider />
      <div className={classes.suppressModalButtonsContainer}>
        <button
          type={"button"}
          onClick={() => {
            setOpenModal(false);
          }}
          style={{ color: "#000000" }}
        >
          {t("No")}
        </button>

        <button
          type={"button"}
          onClick={async () => {
            setOpenModal(false);
            try {
              // DELETE follower api
              await FollowService.remove(data._id);

              setUsers((prev) => [
                ...prev.filter((user) => user._id !== data._id),
              ]);
              handleReduceFollowersCount();
            } catch (error) {
              throw new Error(error);
            }
          }}
        >
          {t("Yes")}
        </button>
      </div>
    </Dialog>
  );
}

export default DeleteFollower;
