import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { IonAlert, IonLoading } from "@ionic/react";
import { MdCameraEnhance } from "react-icons/md";
import { useRecoilState } from "recoil";

import { acceptStyle, activeStyle, baseStyle, rejectStyle } from "./styled";

import IdVerificationService from "../../_services/id-verification.service";

import idVerificationAtom from "../../stateManagement/atoms/id-verification/idVerification";

function DropZone(props) {
  const [files, setFiles] = useState([]);
  const [preview, setPreview] = useState("");
  const [hover, setHover] = useState(false);
  const [validating, setValidating] = useState(false);
  const [validatedId, setValidatedId] = useState(false);
  const [idVerificationAtomData, setIdVerificationDataAtom] =
    useRecoilState(idVerificationAtom);
  const [idValidationErrorMessage, setIdValidationErrorMessage] =
    React.useState("failed to validate id. please re-upload");

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    // acceptedFiles,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    if (files && files.length) setPreview(files[0].preview);
    (async () => {
      try {
        const formData = new FormData();

        if (!files.length) {
          return;
        }

        setValidating(true);

        formData.append("file", files[0]);
        formData.append("type", props.idSide);
        formData.append("idImageType", props.idType);

        const response = await IdVerificationService.uploadDocument(formData);

        setIdVerificationDataAtom(() => {
          return {
            ...idVerificationAtomData,
            frontImageFileName: response.data.fileName,
            frontImageFileId: response.data.fileId,
          };
        });
        props.successUpload({
          ...idVerificationAtomData,
          frontImageFileName: response.data.fileName,
          frontImageFileId: response.data.fileId,
        });
      } catch (err) {
        setValidatedId(true);
        setIdValidationErrorMessage(
          err?.response?.data?.message || err.message
        );
      } finally {
        setValidating(false);
      }
    })();

    // Might need to store the files in a global state, since I might need to upload when the next button is clicked.

    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  const style = useMemo(() => {
    return {
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    };
  }, [isDragActive, isDragReject, isDragAccept]);

  return (
    <div
      className="component-experience-dropzone"
      style={{
        ...(preview && {
          backgroundSize: "100%",
          backgroundImage: `url(${preview})`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          width: "auto",
          height: "300px",
        }),
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseOver={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <IonLoading
        cssClass="my-custom-class"
        isOpen={validating}
        // onDidDismiss={() => setShowLoading(false)}
        message={"Validating"}
      />
      {/*<IonToast*/}
      {/*    isOpen={validatedId}*/}
      {/*    position={'top'}*/}
      {/*    onDidDismiss={() => setValidatedId(false)}*/}
      {/*    message={idValidationErrorMessage}*/}
      {/*    duration={4000}*/}
      {/*/>*/}
      <IonAlert
        isOpen={validatedId}
        onDidDismiss={() => setValidatedId(false)}
        header={"Error"}
        message={idValidationErrorMessage}
        buttons={["OK"]}
      />
      <div
        {...getRootProps({
          style: {
            ...style,
            ...(hover &&
              preview !== "" && {
                // backgroundColor: "#626f99ad",
                color: "white",
              }),
            ...(preview !== "" &&
              hover === false && {
                opacity: 0,
              }),
            ...(preview !== "" &&
              isDragReject && {
                backgroundColor: "#626f99ad",
                color: "white",
                opacity: 0.9,
              }),
          },
        })}
        onClick={open}
      >
        <input {...getInputProps()} />

        {preview !== "" ? (
          <Button
            variant="contained"
            style={{
              marginTop: "auto",
              alignSelf: "end",
              marginBottom: "5px",
              marginRight: "5px",
            }}
          >
            Replace image
          </Button>
        ) : (
          <div style={{ marginTop: "auto", marginBottom: "auto" }}>
            <MdCameraEnhance />
            <p style={{ fontWeight: "bold" }}>Upload File</p>
          </div>
        )}
      </div>

      {/* {preview !== "" &&
       <div>
          <Button 
            variant="contained" 
            color="secondary" 
            >Use this image</Button>
        </div>
      }
      */}

      {isDragReject ? (
        <p style={{ color: "#FD6A78" }}>*Oops! Invalid Format</p>
      ) : null}
    </div>
  );
}

export default DropZone;
