import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

export const saveReport = async (body) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.post(`/v1/reports`, body, { headers });
  return response;
};
