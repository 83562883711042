import "../styles.css";

import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import React from "react";
import { useTranslation } from "react-i18next";

function ContentCreatorTermsOfUseModal(props) {
  const { t } = useTranslation();

  const {
    openContentCreatorTermsOfUseModal,
    setOpenContentCreatorTermsOfUseModal,
    handleAcceptContentCreatorTermsOfUse,
  } = props;

  return (
    <Dialog
      // className={classes.dialogRoot}
      open={openContentCreatorTermsOfUseModal}
      onClose={() => setOpenContentCreatorTermsOfUseModal(false)}
      className="accept-terms-dialog"
    >
      <DialogTitle
        id="customized-dialog-title"

        //   classes={classes}
      >
        {t("creatorTerms")}
      </DialogTitle>
      <DialogContent /* className={classes.dialogContentRoot} */>
        <Container>{t("creatorContent")}</Container>
      </DialogContent>
      <DialogActions /* className={classes.dialogActionsRoot} */>
        <Button
          onClick={() => handleAcceptContentCreatorTermsOfUse()}
          className="accept-terms-actions"
        >
          {t("acceptsAction")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ContentCreatorTermsOfUseModal;
