import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const likePublication = async (data) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.post("/v1/likes", data, { headers });

  return response;
};

const unLikePublication = async (data) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.delete("/v1/likes", { headers, data });

  return response;
};

const getLikes = async (offset = 0, userId = "") => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
    `/v1/notifications/likes?offset=${offset}&user=${userId}`,
    {
      headers,
    }
  );

  return response;
};


const getLikesV2 = async (offset = 0) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
      `/v2/notifications/likes?offset=${offset}`,
      {
        headers,
      }
  );

  return response;
};

const getPublicationLikes = async (id = "") => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/publications/${id}/likers`, {
    headers,
  });

  return response;
};

const LikeService = {
  likePublication,
  unLikePublication,
  getLikes,
  getLikesV2,
  getPublicationLikes,
};

export default LikeService;
