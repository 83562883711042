/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "./kebabMenuOptionsLovers.css";
import {
  Button,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { saveReport } from "../../_services/report.service";
// import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
const useStyles = makeStyles({
  fullList: {
    width: "auto",
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiDialogContent);

const ReportAccount = ({ success, label = null, ...props }) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const [isOpenReportModal, setIsOpenReportModal] = useState(false);
  const [, setReportSubmitted] = useState(false);
  const [, setShowNotification] = useState(false);

  //setFollower is from ViewFeetModelsProfile page
  const reportMessages = [t("Inappropriate")];
  // const { open, setOpen, user, publication, isFollower, setFollower } = props;

  const handleReportSubmit = async (reason) => {
    const response = await saveReport({
      remarks: `${
        props && props.type && props.type === "user"
          ? t("User")
          : t("Inappropriate Content")
      }`,
      ...props,
    });

    // success
    if (response.status === 201) {
      // close report modal
      setOpen(false);
      // open success sent report modal
      setIsOpenReportModal(true);
    }
  };
  const closeSnackbar = () => {
    setReportSubmitted(false);
  };
  // watch when 3 dot vertical menu is open.

  // const handleDeletePublication = async () => {
  //   try {
  //     // await PublicationService.remove(publication._id);
  //
  //     setOpen(false);
  //
  //     if (window.location.pathname === `/publication/${publication._id}`) {
  //       history.goBack();
  //     } else {
  //       window.location.reload();
  //     }
  //   } catch (error) {
  //   }
  // };

  const action = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        onClick={() => setShowNotification(false)}
      >
        {t("UNDO")}
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setShowNotification(false)}
      >
        x
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        {label || t("reportKebabMenuLover")}{" "}
      </Button>
      <React.Fragment>
        <Dialog open={isOpen} onClose={() => setOpen(false)}>
          <DialogTitle>
            {props && props.type && props.type === "user"
              ? t("User")
              : t("Report Inappropriate Content")}
          </DialogTitle>
          <Divider />
          <DialogContent>
            {reportMessages.map((message) => (
              <Button
                onClick={() => handleReportSubmit(message)}
                key={message}
                variant="outlined"
                color="secondary"
              >
                {props && props.type && props.type === "user"
                  ? t("User")
                  : t("Inappropriate Content")}
              </Button>
            ))}
          </DialogContent>
        </Dialog>
        {/*<Snackbar*/}
        {/*    open={showNotification}*/}
        {/*    autoHideDuration={6000}*/}
        {/*    severity="success"*/}
        {/*    message="Report sent"*/}
        {/*    action={action}*/}
        {/*>*/}
        {/*</Snackbar>*/}
        {/* <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isReportSubmitted}
            onClose={closeSnackbar}
            autoHideDuration={6000}
            // message="Sent Report"
            key={'top' + 'center'}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Sent Report
          </Alert>
        </Snackbar> */}

        <Dialog
          open={isOpenReportModal}
          onClose={() => {
            setIsOpenReportModal(false);
            success();
          }}
        >
          <DialogTitle style={{ fontFamily: "Roboto", textAlign: "center" }}>
            {t("thankYou")}, <br />
            {t("reportSuccessMessage")}
          </DialogTitle>
          <Divider />
          <button
            type={"button"}
            onClick={() => {
              setIsOpenReportModal(false);
              success();
            }}
            style={{
              backgroundColor: "white",
              padding: "10px",
              border: "none",
              // borderTop: "0.02rem solid #9F9F9F",
              color: "#ff2775",
              fontSize: "	1.1rem",
              fontWeight: "700",
              fontFamily: "Roboto",
            }}
          >
            {t("Ok")}
          </button>
        </Dialog>
      </React.Fragment>
    </>
  );
};

export default ReportAccount;
