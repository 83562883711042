/* eslint-disable no-unused-vars */
import { Button } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import cross from "../../../assets/images/unlock-publication/Group 54930@2x.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import ticklesAtom from "../../../stateManagement/atoms/ticklesAtom";
import moneyAtom from "../../../stateManagement/atoms/moneyAtom";
import EuroIcon from "@material-ui/icons/Euro";
import { isIOS } from "react-device-detect";

const WithdrawModalContent = ({ sendedTickles, setSendedTickles }) => {
  const [ticklesHave, setTicklesHave] = useRecoilState(ticklesAtom);
  const [numberTickles, setNumberTickles] = useState("");
  const [amountTickles, setAmountTickles] = useRecoilState(moneyAtom);
  let history = useHistory();
  const { t } = useTranslation();
  const amount = 0.001;

  const decrementTickles = () => {
    if (Number(numberTickles) <= Number(ticklesHave)) {
      setTicklesHave(ticklesHave - numberTickles);
      setAmountTickles(numberTickles * amount);
      localStorage.setItem("eurosFromTickles", amountTickles);
      localStorage.setItem("tickles", ticklesHave - numberTickles);
      localStorage.setItem("numberTickles", numberTickles);
      history.push("/tickles-account");
    }
    if (Number(ticklesHave) < Number(numberTickles)) {
      setTicklesHave(ticklesHave);
      return alert(
        `Vous n'avez pas assez de Tickles, il vous en reste seulement ${ticklesHave}`
      );
    }
    // Condition for minimum amount conversion ( commented in dev mode - need to be decommented when switch to prod mode)
    // if(Number(amountTickles) < Number(50)){
    //   setTicklesHave(ticklesHave)
    //   return alert(`€50 minimum amount to be converted`)
    // }
  };

  useEffect(() => {
    setAmountTickles(numberTickles * amount);
  }, [numberTickles, setAmountTickles]);

  const handleSendedTickles = () => {
    if (sendedTickles === false) {
      setSendedTickles(true);
    }
  };

  return (
    <Fragment>
      <div className="Withdraw__content">
        <div className="Withdraw__header">
          <h2 id="simple-modal-title">Withdraw Tickles</h2>
          <img
            src={cross}
            alt="close"
            onClick={() => history.push("/tickles-account")}
          />
        </div>
        <div className="Withdraw__body">
          <div className="Withdraw__top">
            <p>
              When withdrawing Tickles, it will be converted to Euro and will
              credit to your bank account.
            </p>
            <p>(1000 Tickles = €1)</p>
          </div>
          <div className="Withdraw__center">
            <h4>Total Tickles</h4>
            <input
              value={numberTickles}
              onChange={(e) => setNumberTickles(e.target.value)}
              className="Withdraw__input"
              type="number"
              placeholder="Number of tickles"
            />
            <h4 className="WithdrawCenter__converted">Converted Amount</h4>
            <EuroIcon className="WithdrawEuroIcon" />
            <input
              disabled
              value={numberTickles * amount}
              onChange={(e) => setAmountTickles(e.target.value)}
              className="Withdraw__input"
              type="number"
              placeholder="€"
            />
          </div>
          <sub className="WithdrawCenter__amount">
            €50 minimum amount to be converted
          </sub>
          <div className="Withdraw__bottom">
            <div
              className={
                isIOS === true
                  ? "Withdraw__center-button Withdraw__center-buttonIphone"
                  : "Withdraw__center-button"
              }
            >
              <Button
                variant="contained"
                className="Withdraw__button"
                onClick={() => history.push("/tickles-account")}
              >
                <h4>Cancel</h4>
              </Button>
              <Button
                variant="contained"
                className="Withdraw__button"
                onClick={() => {
                  decrementTickles();
                  handleSendedTickles();
                }}
              >
                <h4>Send</h4>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WithdrawModalContent;
