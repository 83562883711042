/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import "./messages.css";
import TabChoise from "./TabChoice";
import newMessageBadgeAtom from "../../../../stateManagement/atoms/newMessageBadgeAtom";
import { userTypeAtom } from "../../../../stateManagement/atoms/studioAtom";
import NotificationService from "../../../../_services/notification.service";

const Messages = (props) => {
  let history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const [_, setNewMessageBadge] = useRecoilState(newMessageBadgeAtom);
  const [userType, setUserType] = useRecoilState(userTypeAtom);

  useEffect(() => {
    NotificationService.readAllNotificationsWithType("Messages")
    setNewMessageBadge(false);
    return () => {};
  }, [setNewMessageBadge]);

  return (
    <div className="Messages__container">
      <div className="Messages__Header page_fixed_header">
        <Button
          onClick={() => {
            // if (userType === "Model") history.push("/home-page-models");
            // else history.push("/home-page");
            history.goBack();
          }}
          className="back__button"
        >
          <ChevronLeft />
        </Button>
        <h4 className="messagesContainer__title">Messages</h4>
        <Button
          onClick={() => history.push("/messages-search")}
          className="header__rightButtton"
        >
          <h4 className="messagesContainer__create">
            {t("messageLoverCreate")}
          </h4>
        </Button>
      </div>
      <div className="Messages__tabChoice content__withHeader minmax__wrapper">
        <TabChoise userType={userType} />
      </div>
    </div>
  );
};

export default Messages;
