/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";

import "react-toastify/dist/ReactToastify.css";
import receiveMediasMessageToUserAtom from "../../../../../stateManagement/atoms/receiveMediasMessageToUserAtom";
import BlockService from "../../../../../_services/block.service";
import Button from "../../../../Button";
import SendTickles from "../../../sendTickles/SendTickles";
import BuyTickleModal from "../../../../BuyTickleModal";
import UserService from "../../../../../_services/user.service";
import CommonAlertModal from "../../../../Modals/AlertModals/CommonAlertModal";

const MessageContentBottom = (props) => {
  const {
    toUser,
    sendMess,
    getRootProps,
    setClickedStop,
    handleHeart,
    Heart,
    getInputProps,
    IconButton,
    inputValue,
    speechToText,
    setInputValue,
    t,
    Fragment,
    Picture,
    clickedStop,
    handleSendMessages,
    isBlockedByAuthenticatedUser,
    setIsBlockedByAuthenticatedUser,
    authUser,
    TicklesIcon,
    handleTicklesMessages,
    user,
    handleSendedTickles,
    isUploading,
    epochProducts
  } = props;

  let history = useHistory();
  const [isReceiveMediaToUser] = useRecoilState(receiveMediasMessageToUserAtom);

  const [openBuyTickleModal, setOpenBuyTickleModal] = useState(false);
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [isTickleModalOpen, setTickleModalOpen] = useState(false);
  const [epochProductLists, setEpochProductLists] = useState([]);
  const [openCommonAlertModal, setOpenCommonAlertModal] = useState(false);
  const handleMessageNoMediasAutorized = () => {
    toast(
      "The 'send & receive media' option is disabled, to enable it please go to the configuration section of the application.",
      {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const handleUnblock = async () => {
    try {
      // un-block
      setIsBlockedByAuthenticatedUser(false);

      await BlockService.unblockUser(toUser._id);
    } catch (error) {
      toast.error(
        `${error.response?.data?.error || "Error unblocking user"} `,
        {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const handleKeypress = (event) => {
    if(event.key === 'Enter'){
      handleSendMessages(toUser);
      setClickedStop(false);
    }
  }

  useEffect(() => {
      if (openBuyTickleModal === true && epochProducts) {
          setEpochProductLists(epochProducts)
      }
  }, [openBuyTickleModal, epochProducts]);

  return (
    <>
      {isBlockedByAuthenticatedUser ? (
        <div className="block_user_message flex-center">
          <p>
            You blocked messages from <b>{toUser.username}</b> account. You
            can&apos;t message them in this chat, and you won&apos;t receive
            their messages.
          </p>
          <Button
            variant="contained"
            className="unblock__Button white__button"
            onClick={() => handleUnblock()}
          >
              {t("unblockButton")}
          </Button>
        </div>
      ) : (
        <div
          className={
            sendMess === ""
              ? "createMessage__bottom"
              : "createMessage__bottom createMessageSend__bottom"
          }
        >
          <ToastContainer
            position="top-center"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{top: "5em"}}
          />

          <input
            autoComplete="off"
            value={inputValue || speechToText}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyUp={handleKeypress}
            placeholder={t("createMessageTypeMess")}
            type="text"
            className={
              isReceiveMediaToUser
                ? "createMessage__input"
                : "createMessage__input createMessage__inputNoMedias"
            }
            id="mess-input"
          />
          {inputValue === "" || speechToText !== "" ? (
            <Fragment>
              {isReceiveMediaToUser &&
              authUser &&
              authUser?.user &&
              authUser?.user?.userType &&
              authUser?.user?.userType === "Model" ? (
                /* upload media */
                <span
                  {...getRootProps({ className: "dropzone__messagePicture" })}
                  style={{ outline: "none" }}
                >
                  {!isUploading ? (
                    <>
                      <input {...getInputProps()} disabled={!authUser?.user?.idCheckDetails?.verified}/>
                      <IconButton>
                        <img
                          src={Picture}
                          alt="pict Icon"
                          className={
                            clickedStop === false
                              ? "createMessage__picture"
                              : "none"
                          }
                          onClick={() => {
                              if (!authUser?.user?.idCheckDetails?.verified)
                                  history.push("/identity-verification");
                          }}
                        />
                      </IconButton>
                    </>
                  ) : <CircularProgress color="secondary" size={20} />}
                </span>
              ) : (
                <>
                  {authUser &&
                  authUser?.user &&
                  authUser.user?.userType &&
                  authUser.user?.userType === "Lover" &&
                  toUser &&
                  toUser?.userType !== "Lover"
                    ? !isBlockedByAuthenticatedUser && (
                        /* Leave a tip or send tickles button. */
                        <Button
                          background="linear-gradient(90deg, #FF2775 0%, #FF5768 100%)"
                          width="clamp(112.56px, 4vw, 143px)"
                          height="clamp(32px, 4vw, 44px)"
                          padding="0 20px 0 0"
                          fontSize="clamp(12px, 3vw, 14px)"
                          borderRadius="13px"
                          onClick={async () => {
                            const { user } = await UserService.getUser(
                              authUser.user._id
                            );
                            const toUserData =
                                await UserService.getUser(
                                    toUser?._id
                                );
                            if (toUserData.user && (!toUserData.user?.suppress && !toUserData.user?.accountSoftDeleted)) {
                               setAuthenticatedUser(user);
                               handleTicklesMessages();
                               if (user && (user.tickles === 0 || !user.tickles)) {
                                 setOpenBuyTickleModal(true);
                               } else setTickleModalOpen(true);
                            } else {setOpenCommonAlertModal(true);}
                          }}
                          variant="contained"
                        >
                          <span> {t("leaveATipButton")}</span>
                          <img
                            src={TicklesIcon}
                            alt="tickles icon"
                            width="20"
                            height="20"
                            style={{ position: "absolute", right: "5px" }}
                          />
                        </Button>
                      )
                    : ""}
                </>
              )}

              <IconButton
                onClick={
                  isReceiveMediaToUser
                    ? handleHeart
                    : handleMessageNoMediasAutorized
                }
              >
                <img
                  src={Heart}
                  alt="heart Icon"
                  className={
                    clickedStop === false
                      ? isReceiveMediaToUser
                        ? "createMessage__heart"
                        : "createMessage__heart createMessage__heartNoMedias"
                      : "none"
                  }
                />
              </IconButton>
            </Fragment>
          ) : (
            <Button
              type="submit"
              width="clamp(64px, 4vw, 24.5rem)"
              background="white"
              height="clamp(42px, 4vw, 52px)"
              color="#f72c3f"
              boxShadow="none"
              padding="0"
              fontSize="clamp(18px, 4vw, 1.2rem)"
              onClick={() => {
                handleSendMessages(toUser);
                setClickedStop(false);
              }}
            >
              {t("sendMessage")}
            </Button>
          )}
        </div>
      )}
      {/* send tickles modal */}
      <SendTickles
        recipient={user}
        isOpen={isTickleModalOpen}
        setOpen={setTickleModalOpen}
        handleSendedTickles={handleSendedTickles}
        authenticatedUser={authenticatedUser}
        setOpenBuyTickleModal={setOpenBuyTickleModal}
        ticklesIcon={TicklesIcon}
      />

      {/* buy tickle modal */}
      <BuyTickleModal
        openBuyTickleModal={openBuyTickleModal}
        setOpenBuyTickleModal={setOpenBuyTickleModal}
        epochPrices={epochProductLists}
      />

      {/* validation alert modal*/}
      <CommonAlertModal
          title={"Operation Failed!"}
          subTitle={"Model is currently inactive or deactivated."}
          lists={"Thank you."}
          setHelperOpen={setOpenCommonAlertModal}
          open={openCommonAlertModal}
      />
    </>
  );
};

export default MessageContentBottom;
