/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { Avatar, Button, IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import "./searchHistory.css";
import HistoryService from "../../../_services/history.service";
import searchHistoryQuery from "../../../stateManagement/selectors/searchHistorySelector";
import searchHistoryAtom from "../../../stateManagement/atoms/searchHistoryAtom";
import genericAvatar from "../../../assets/images/generic-user-avatar.png";
import { onSettingModelsAtom } from "../../../stateManagement/atoms/studioAtom";
import feetModelAtom from "../../../stateManagement/atoms/feetModelAtom";

const SearchHistory = (props) => {
  let history = useHistory();
  const { t } = useTranslation();

  const { users } = useRecoilValue(searchHistoryQuery);
  const [_, setSearchHistory] = useRecoilState(searchHistoryAtom);
  const [__, setFeetModel] = useRecoilState(feetModelAtom);

  const onSettingModels = useRecoilValue(onSettingModelsAtom);

  const handleClearHistory = async () => {
    try {
      await HistoryService.deleteSearchHistory();

      setSearchHistory([]); // reset dependency state atom

      onSettingModels === false
        ? history.push("/settings-lovers")
        : history.push("/setting-models");
    } catch (error) {
      console.error("ERROR DELETE /api/v1/histories/history", error);
    }
  };

  useEffect(() => {
    /* Force trigger the recoil selector to fetch the search history data on mount. */
    setSearchHistory(() => []);

    return () => {};
  }, [setSearchHistory]);

  return (
    <div className="searchHistory__container">
      <div className="searchHistory__header header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() =>
            onSettingModels === false
              ? history.push("/settings-lovers")
              : history.push("/setting-models")
          }
        >
          <ChevronLeft />
        </IconButton>
        <h4>{t("Search History")}</h4>
      </div>
      <div className="searchHistory__content content__withHeader minmax__wrapper">
        {users && users.length ? (
          <div className="user_list">
            {users.map((user) => (
              <div
                className="user_thumbnail_details"
                key={user._id}
                onClick={() => {
                  if (user.userType === "Lover") {
                    history.push({
                      pathname: `/visit-feet-lover/${user._id}`,
                    });
                  } else {
                    setFeetModel(user);
                    history.push({
                      pathname: "/feet-models-view",
                      search: `?user=${user._id}`,
                    });
                  }
                }}
              >
                <div className="avatar">
                  <Avatar
                    className="searchHistory__avatar"
                    src={user.image ? user.image.url : genericAvatar}
                    alt="profile pict"
                  />
                </div>
                <div className="user_details">
                  <h2>{user.username}</h2>
                  {/* <p>{user.username}</p> */}
                </div>
              </div>
            ))}
            {/*use to get fill gaps and have even cells*/}
            <div className="user_thumbnail_details space_filler"></div>
            <div className="user_thumbnail_details space_filler"></div>
            <div className="user_thumbnail_details space_filler"></div>
          </div>
        ) : (
          <span style={{ alignSelf: "center", color: "#545454" }}>
            {t("noRecentSearches")}
          </span>
        )}

        {users && users.length ? (
          <Button
            className="searchHistory__button pink__button"
            onClick={handleClearHistory}
          >
            {t("Clear All")}
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SearchHistory;
