/* eslint-disable no-unused-vars */
import React from "react";
import CropIcon from "@material-ui/icons/Crop";
import BottomNavigationCropTool from "../../layouts/shared/BottomNavigationCropTool";
import "./cropperView.css";
import Header from "../../layouts/shared/Header";
import { useTranslation } from "react-i18next";

const CropperView = () => {
  const [Pict, setPict] = React.useState({});
  const { i18n, t } = useTranslation();

  React.useEffect(() => {
    setPict(window.sessionStorage.getItem("publicationPicture"));
  }, []);

  return (
    <div className="bottomNavigationModels__content homeStudioCrop">
      <div className="header-crop-view">
        <Header />
      </div>
      <div className="text-step-crop" style={{ color: "#f82f72" }}>
        <h4 style={{ color: "black", fontSize: 13 }}>
          {t("homeStudioStepTwo")}
        </h4>
        <div
          className="step-text-crop"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <CropIcon /> &ensp;
          <h4 className="text-picture-step-crop"> {t("helloPictureCrop")}</h4>
        </div>
      </div>
      <div className="img-crop-content">
        {Pict ? (
          <img
            className="publication-picture-cropper"
            src={Pict}
            alt="profile"
          />
        ) : null}
      </div>
      <BottomNavigationCropTool />
    </div>
  );
};

export default CropperView;
