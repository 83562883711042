/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";

import "./bankAccountGuide.css";
import { studioAtom } from "../../../../stateManagement/atoms/studioAtom";
import { useTranslation } from "react-i18next";

const BankAccountConfirmation = (props) => {
  const { t } = useTranslation();

  const [_, setOnStudio] = useRecoilState(studioAtom);

  useEffect(() => {
    setOnStudio(true);

    return () => {
      setOnStudio(false);
    };
  }, [setOnStudio]);

  return (
    <div className="feetloverGuides__container">
      <div className={"feet-model-guides-container"}>
        <p>
          {t("bankWelcomeHi")} <i>{props.user.username}</i>,<br />
          <br />
          {t("addABankAccountNote")}
        </p>
      </div>
    </div>
  );
};

export default BankAccountConfirmation;
