/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { Avatar, IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

import "./index.css";

import genericAvatar from "../../assets/images/generic-user-avatar.png";
import feetModelAtom from "../../stateManagement/atoms/feetModelAtom";
import useInfiniteQuery from "../../hooks/useInfiniteQuery";
import useSessionStorage from "../forms/services/storageHooks/useSessionStorage";
import SubscribeService from "../../_services/subscribe.service";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";

const Subscribers = (props) => {
  let history = useHistory();
  const { t } = useTranslation();

  let { id } = useParams();

  const [authUser] = useSessionStorage({}, "infosUser");
  const [__, setFeetModel] = useRecoilState(feetModelAtom);

  const {
    data,
    error,
    status,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery("subscribers-page", async ({ next_offset = 0 }) => {
    const response = await SubscribeService.getSubscriberList(id, next_offset);

    return {
      data: response.data.subscriptions,
      next_offset: response.data.next_offset,
    };
  });

  const loadMoreReference = useRef();

  useIntersectionObserver({
    target: loadMoreReference,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  const handleViewSubscriberProfile = (subscriber) => {
    if (subscriber.subscriber.userType === "Lover") {
      if (authUser.user._id === subscriber.subscriber._id) {
        history.push("/feet-lover-profile");
      } else {
        history.push({
          pathname: `/visit-feet-lover/${subscriber.subscriber._id}`,
        });
      }
    } else {
      if (authUser.user._id === subscriber.subscriber._id) {
        history.push("/view-own-profile-models");
      } else {
        setFeetModel(subscriber.subscriber);
        history.push({
          pathname: "/feet-models-view",
          search: `?user=${subscriber.subscriber._id}`,
        });
      }
    }
  };

  return (
    <div>
      <div className="header page_fixed_header">
        <IconButton className="back__button" onClick={() => history.goBack()}>
          <ChevronLeft />
        </IconButton>
        <h4>{t("subscribersFeetModelView")}</h4>
      </div>
      <div className="blockedAccounts__content content__withHeader minmax__wrapper">
        <div className="blocked_account_list">
          {data.map((subscriber) => (
            <div className="subscribers_item flex-center" key={subscriber._id}>
              <div className="user_thumbnail_details">
                <div className="avatar">
                  <Avatar
                    className="blockedAccounts__avatar"
                    src={
                      subscriber &&
                      subscriber.subscriber &&
                      subscriber.subscriber.image
                        ? subscriber.subscriber.image.url
                        : genericAvatar
                    }
                    alt="profile pict"
                    onClick={() => handleViewSubscriberProfile(subscriber)}
                  />
                </div>
                <div
                  className="user_details"
                  onClick={() => handleViewSubscriberProfile(subscriber)}
                >
                  <h2 style={{ color: "#ff2775", fontWeight: "bold" }}>
                    {subscriber.subscriber.username}
                  </h2>
                  <p>{t("subscriber")}</p>
                </div>
              </div>
              <sub
                style={{
                  fontSize: ".7rem",
                  width: "70px",
                }}
              >
                {moment(subscriber.createdAt).format("DD/MM/YYYY H[h]mm")}
              </sub>
            </div>
          ))}
          {/*use to get fill gaps and have even cells*/}
          <div className="user_thumbnail_details space_filler"></div>
          <div className="user_thumbnail_details space_filler"></div>
          <div className="user_thumbnail_details space_filler"></div>
        </div>
      </div>
      <div ref={loadMoreReference}>
        {isFetchingNextPage ? (
          <CircularProgress color="secondary" size={10} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Subscribers;
