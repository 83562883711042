import { selector } from "recoil";

import MessageService from "../../_services/message.service";

const userSuggestionsInMessageQuery = selector({
  key: "userSuggestionsInMessage",
  get: async () => {
    try {
      const response = await MessageService.getUserSuggestions();

      return response.data;
    } catch (e) {
      console.error("ERROR GET /api/v1/messages/suggestions", e);
    }
  },
});

export default userSuggestionsInMessageQuery;
