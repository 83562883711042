import "./style.css";

import { Button, Card, Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { IconButton } from "@material-ui/core/";
import logo from "../../assets/images/logo-login.png";
import { useTranslation } from "react-i18next";

function WelcomePage() {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const [userType, setUserType] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    setUserType(query.get("type"));
    localStorage.setItem("language", query.get("locale"));
    localStorage.setItem("i18nextLng", query.get("locale"));
  }, [location, setUserType]);

  return (
    <div className="confirm-registration-container">
      <div className="profileForm__header header page_fixed_header edit-profile-header__v3">
        <IconButton
          className="back__button"
          onClick={() => {
            history.goBack();
          }}
        >
          {/* <ChevronLeft style={{ fill: "black" }} /> */}
        </IconButton>
        <h4>{t("welcomePageHome")}</h4>
      </div>
      <Container>
        <Card style={{ padding: "20px", borderRadius: "5px" }}>
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <div className="formSignin__imageContainer">
              <img className="auth__imgLogo" src={logo} alt="" />

              <h3>{t("welcomePageAppTitle")}</h3>
              <h3>
                {userType && userType === "Lover"
                  ? t("welcomePageFeetyLovers")
                  : t("welcomePageFeetyGirls")}
              </h3>
            </div>

            <Button
              className={"welcome-btn-text"}
              onClick={() => history.push("/")}
              style={{
                backgroundColor: "#E54E70",
                height: "clamp(38px, 3vw, 52px)",
                color: "white",
                boxShadow: "none",
                padding: "0",
                fontSize: "clamp(14px, 3vw, 14px)",
                borderRadius: "128px",
                width: "100%",
                marginTop: "100px",
              }}
            >
              {userType && userType === "Lover"
                ? t("welcomePageJoiningFeetGirls")
                : t("welcomePageStartBoth")}
            </Button>

            {/* {userType && userType === "Model" ? (
              <Button
                onClick={() => history.push("/")}
                type="submit"
                style={{
                  backgroundColor:
                    "linear-gradient(90deg, #E54E70 0%, #FF5768 100%)",
                  height: "clamp(38px, 3vw, 52px)",
                  color: "white",
                  boxShadow: "none",
                  padding: "0",
                  fontSize: "clamp(14px, 3vw, 14px)",
                  borderRadius: "128px",
                  width: "100%",
                }}
              >
                {t("welcomePageJoiningFeetGirls")}
              </Button>
            ) : (
              <Container
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  gap: "20px",
                  paddingTop: 20,
                }}
                className={"welcome-feety-page-commence"}
              >
                {feets()}
              </Container>
            )}
              */}
          </div>
        </Card>
      </Container>
    </div>
  );
}

export default WelcomePage;
