import React, { useState } from "react";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { Button, IconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast, ToastContainer } from "react-toastify";

import "./feetyCertification.css";
import CertificationService from "../../../_services/certification.service";
import { useTranslation } from "react-i18next";

const FeetyCertification = () => {
  let history = useHistory();
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <div className="feetyCertification__container">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{top: "5em"}}
      />
      <div className="feetyCertification__header header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => history.push("/setting-models")}
        >
          <ChevronLeft />
        </IconButton>
        <h4>{t("feetyCertification")}</h4>
      </div>
      <div className="certfiContent content__withHeader minmax__wrapper">
        <div className="feetyCertification__content">
          <h4>{t("certificationInstruction")}</h4>
          <div className="Certification-content">
            <p>{t("certificationGuide")}</p>
            <input
              className="crtifEmailInput"
              type="email"
              placeholder={t("certificationPlaceholder")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <Button
            className="pink__button full-width"
            variant="contained"
            onClick={async () => {
              try {
                setLoading(true);
                await CertificationService.requestCertification({ email });
                setLoading(false);
                toast.dark(t("certificationSuccessful"), {
                  position: "top-center",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } catch (error) {
                toast.error(t("certificationFailed"), {
                  position: "top-center",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } finally {
                // history.push("/setting-models");
                setEmail("");
              }
            }}
          >
            {loading ? (
              <CircularProgress color="secondary" size={20} />
            ) : (
              t("certificationError")
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FeetyCertification;
