/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import useLocalStorage from "../../../forms/services/storageHooks/useLocalStorage";
import setIsFromCameraPublicationImage from "../../../../stateManagement/selectors/publication/setIsFromCamera";

// import filter config
import {
  DEFAULT_OPTIONS_3,
  DEFAULT_OPTIONS_4,
  FILTER_1977,
  FILTER_ADEN,
  FILTER_AMARO,
  FILTER_ASHBY,
  FILTER_BRANNAN,
  FILTER_BROOKLYN,
  FILTER_CHARMES,
  FILTER_CLARENDON,
  FILTER_CREMA,
  FILTER_DOGPATCH,
  FILTER_EARLYBIRD,
  FILTER_GINGHAM,
  FILTER_GINZA,
} from "../filters/Filters";

export const useCropAndFilter = () => {
  const [crop, setCrop] = useState({ unit: "%", width: 100, aspect: 1 / 1 });
  const [image, setImage] = useState(null);
  const [clickCrop, setClickCrop] = useState(false);
  const [clickFilters, setClickFilters] = useState(false);
  // Post style to Local Storage
  const [ImageStyle, setImageStyle] = useLocalStorage(
    {},
    "publicationStyleImg"
  );
  const setIsFromCameraPublicationImageSelector = useSetRecoilState(
    setIsFromCameraPublicationImage
  );

  const [result, setResult] = useState(null);
  const [preview, setPreview] = useState(null);
  // options for filtered miniatures and selected filter
  const [options, setOptions] = useState(FILTER_1977);
  const [options2, setOptions2] = useState(FILTER_ADEN);
  const [options3, setOptions3] = useState(DEFAULT_OPTIONS_3);
  const [options4, setOptions4] = useState(DEFAULT_OPTIONS_4);
  const [options5, setOptions5] = useState(FILTER_AMARO);
  const [options6, setOptions6] = useState(FILTER_ASHBY);
  const [options7, setOptions7] = useState(FILTER_BRANNAN);
  const [options8, setOptions8] = useState(FILTER_BROOKLYN);
  const [options9, setOptions9] = useState(FILTER_CHARMES);
  const [options10, setOptions10] = useState(FILTER_CLARENDON);

  const [options11, setOptions11] = useState(FILTER_CREMA);
  const [options12, setOptions12] = useState(FILTER_DOGPATCH);
  const [options13, setOptions13] = useState(FILTER_EARLYBIRD);
  const [options14, setOptions14] = useState(FILTER_GINGHAM);
  const [options15, setOptions15] = useState(FILTER_GINZA);

  const [clickedEffect1, setClickedEffect1] = useState(false);
  const [clickedEffect2, setClickedEffect2] = useState(false);
  const [clickedEffect3, setClickedEffect3] = useState(false);
  const [clickedEffect4, setClickedEffect4] = useState(false);
  const [clickedEffect5, setClickedEffect5] = useState(false);
  const [clickedEffect6, setClickedEffect6] = useState(true);
  const [clickedEffect7, setClickedEffect7] = useState(false);
  const [clickedEffect8, setClickedEffect8] = useState(false);
  const [clickedEffect9, setClickedEffect9] = useState(false);
  const [clickedEffect10, setClickedEffect10] = useState(false);

  const [clickedEffect11, setClickedEffect11] = useState(false);
  const [clickedEffect12, setClickedEffect12] = useState(false);
  const [clickedEffect13, setClickedEffect13] = useState(false);
  const [clickedEffect14, setClickedEffect14] = useState(false);
  const [clickedEffect15, setClickedEffect15] = useState(false);

  // THIS VARIABLE NEED TO BE SENDED TO BACKEND WITH PICTURE ID PUBLICATION FOR GETTING AND DISPLAY THIS INFO IN HOMEPAGEMODEL & HOMEPAGELOVER COMPONENTS
  const [selectedStylePicture, setSelectedStylePicture] = useState({});

  //  Crop function
  function getCroppedImg(isFromCreateMessage = false) {
    const canvas = document.createElement("canvas");
    /* UNCOMMENT */
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    var originWidth = crop.width * scaleX;
    var originHeight = crop.height * scaleY;

    // maximum width/height
    var maxWidth = 1200,
      maxHeight = 1200 / (16 / 9);

    var targetWidth = originWidth,
      targetHeight = originHeight;

    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth;
        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      } else {
        targetHeight = maxHeight;
        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      }
    }

    // set canvas size
    canvas.width = targetWidth;
    canvas.height = targetHeight;

    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      targetWidth,
      targetHeight
    );

    // // expiriment
    // const scaleX = image.naturalWidth / image.width;
    // const scaleY = image.naturalHeight / image.height;
    // const ctx = canvas.getContext("2d");
    // const pixelRatio = window.devicePixelRatio;

    // canvas.width = scaleX * crop.width * pixelRatio;
    // canvas.height = scaleY * crop.height * pixelRatio;

    // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    // ctx.imageSmoothingQuality = "high";

    // ctx.drawImage(
    //   image,
    //   crop.x * scaleX,
    //   crop.y * scaleY,
    //   crop.width * scaleX,
    //   crop.height * scaleY,
    //   0,
    //   0,
    //   crop.width * scaleX,
    //   crop.height * scaleY
    // );

    // Save result on base64
    const base64Image = canvas.toDataURL("image/png", 1);
    // setPreview(base64Image);
    setResult(base64Image);

    if (isFromCreateMessage) {
      localStorage.setItem("message-upload-file", base64Image);
    }

    // This is the cropped media result and stored to local storage.
    window.localStorage.setItem("resultPost", base64Image);

    /* This code seems to compress the image... */
    // return new Promise((resolve, reject) => {
    //   canvas.toBlob(
    //     (blob) => {
    //       if (!blob) {
    //         return;
    //       }
    //       try {
    //         setResult(null);
    //         let min = 1,
    //           max = 100;
    //         let random = min + Math.random() * (max - min);
    //         const newImage = new File([blob], `thumbnail${random * 2}.jpeg`, {
    //           type: "image/jpeg",
    //         });

    //         setResult(newImage);
    //       } catch (e) {
    //       }
    //       const fileUrl = window.URL.createObjectURL(blob);

    //       resolve(fileUrl);
    //     },
    //     "image/jpeg",
    //     1
    //   );
    // });
  }

  //  Functions for getting style filter
  function getImageStyle1() {
    const filters = options.map((option) => {
      return `${option.property}(${option.range.max}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  function getImageStyle2() {
    const filters = options2.map((option) => {
      return `${option.property}(${option.range.max}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  function getImageStyle3() {
    const filters = options3.map((option) => {
      return `${option.property}(${option.range.max}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  function getImageStyle4() {
    const filters = options4.map((option) => {
      return `${option.property}(${option.range.max}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  function getImageStyle5() {
    const filters = options5.map((option) => {
      return `${option.property}(${option.range.max}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  function getImageStyle6() {
    const filters = options6.map((option) => {
      return `${option.property}(${option.range.max}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  function getImageStyle7() {
    const filters = options7.map((option) => {
      return `${option.property}(${option.range.max}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  function getImageStyle8() {
    const filters = options8.map((option) => {
      return `${option.property}(${option.range.max}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  function getImageStyle9() {
    const filters = options9.map((option) => {
      return `${option.property}(${option.range.max}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  function getImageStyle10() {
    const filters = options10.map((option) => {
      return `${option.property}(${option.range.max}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  function getImageStyle11() {
    const filters = options11.map((option) => {
      return `${option.property}(${option.range.max}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  function getImageStyle12() {
    const filters = options12.map((option) => {
      return `${option.property}(${option.range.max}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  function getImageStyle13() {
    const filters = options13.map((option) => {
      return `${option.property}(${option.range.max}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  function getImageStyle14() {
    const filters = options14.map((option) => {
      return `${option.property}(${option.range.max}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  function getImageStyle15() {
    const filters = options15.map((option) => {
      return `${option.property}(${option.range.max}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  // SetState for passing style filter from miniature to cropped image
  const handleClickedEffect6 = () => {
    setClickedEffect6(true);
    setClickedEffect15(false);
    setClickedEffect14(false);
    setClickedEffect13(false);
    setClickedEffect12(false);
    setClickedEffect11(false);
    setClickedEffect10(false);
    setClickedEffect9(false);
    setClickedEffect7(false);
    setClickedEffect8(false);
    setClickedEffect1(false);
    setClickedEffect2(false);
    setClickedEffect3(false);
    setClickedEffect4(false);
    setClickedEffect5(false);
    setSelectedStylePicture(getImageStyle6());
  };

  const handleClickedEffect1 = () => {
    setClickedEffect1(true);
    setClickedEffect15(false);
    setClickedEffect14(false);
    setClickedEffect13(false);
    setClickedEffect12(false);
    setClickedEffect11(false);
    setClickedEffect10(false);
    setClickedEffect9(false);
    setClickedEffect7(false);
    setClickedEffect8(false);
    setClickedEffect6(false);
    setClickedEffect2(false);
    setClickedEffect3(false);
    setClickedEffect4(false);
    setClickedEffect5(false);
    setSelectedStylePicture(getImageStyle1());
  };

  const handleClickedEffect2 = () => {
    setClickedEffect2(true);
    setClickedEffect15(false);
    setClickedEffect14(false);
    setClickedEffect13(false);
    setClickedEffect12(false);
    setClickedEffect11(false);
    setClickedEffect10(false);
    setClickedEffect9(false);
    setClickedEffect7(false);
    setClickedEffect8(false);
    setClickedEffect6(false);
    setClickedEffect1(false);
    setClickedEffect3(false);
    setClickedEffect4(false);
    setClickedEffect5(false);
    setSelectedStylePicture(getImageStyle2());
  };

  const handleClickedEffect3 = () => {
    setClickedEffect3(true);
    setClickedEffect15(false);
    setClickedEffect14(false);
    setClickedEffect13(false);
    setClickedEffect12(false);
    setClickedEffect11(false);
    setClickedEffect10(false);
    setClickedEffect9(false);
    setClickedEffect7(false);
    setClickedEffect8(false);
    setClickedEffect6(false);
    setClickedEffect1(false);
    setClickedEffect2(false);
    setClickedEffect4(false);
    setClickedEffect5(false);
    setSelectedStylePicture(getImageStyle3());
  };

  const handleClickedEffect4 = () => {
    setClickedEffect4(true);
    setClickedEffect15(false);
    setClickedEffect14(false);
    setClickedEffect13(false);
    setClickedEffect12(false);
    setClickedEffect11(false);
    setClickedEffect10(false);
    setClickedEffect9(false);
    setClickedEffect7(false);
    setClickedEffect8(false);
    setClickedEffect6(false);
    setClickedEffect1(false);
    setClickedEffect3(false);
    setClickedEffect2(false);
    setClickedEffect5(false);
    setSelectedStylePicture(getImageStyle4());
  };

  const handleClickedEffect5 = () => {
    setClickedEffect5(true);
    setClickedEffect15(false);
    setClickedEffect14(false);
    setClickedEffect13(false);
    setClickedEffect12(false);
    setClickedEffect11(false);
    setClickedEffect10(false);
    setClickedEffect9(false);
    setClickedEffect7(false);
    setClickedEffect8(false);
    setClickedEffect6(false);
    setClickedEffect1(false);
    setClickedEffect3(false);
    setClickedEffect4(false);
    setClickedEffect2(false);
    setSelectedStylePicture(getImageStyle5());
  };

  const handleClickedEffect7 = () => {
    setClickedEffect7(true);
    setClickedEffect15(false);
    setClickedEffect14(false);
    setClickedEffect13(false);
    setClickedEffect12(false);
    setClickedEffect11(false);
    setClickedEffect10(false);
    setClickedEffect9(false);
    setClickedEffect8(false);
    setClickedEffect6(false);
    setClickedEffect1(false);
    setClickedEffect2(false);
    setClickedEffect3(false);
    setClickedEffect4(false);
    setClickedEffect5(false);
    setSelectedStylePicture(getImageStyle7());
  };

  const handleClickedEffect8 = () => {
    setClickedEffect8(true);
    setClickedEffect15(false);
    setClickedEffect14(false);
    setClickedEffect13(false);
    setClickedEffect12(false);
    setClickedEffect11(false);
    setClickedEffect10(false);
    setClickedEffect9(false);
    setClickedEffect7(false);
    setClickedEffect6(false);
    setClickedEffect1(false);
    setClickedEffect2(false);
    setClickedEffect3(false);
    setClickedEffect4(false);
    setClickedEffect5(false);
    setSelectedStylePicture(getImageStyle8());
  };

  const handleClickedEffect9 = () => {
    setClickedEffect9(true);
    setClickedEffect15(false);
    setClickedEffect14(false);
    setClickedEffect13(false);
    setClickedEffect12(false);
    setClickedEffect11(false);
    setClickedEffect10(false);
    setClickedEffect7(false);
    setClickedEffect8(false);
    setClickedEffect6(false);
    setClickedEffect1(false);
    setClickedEffect2(false);
    setClickedEffect3(false);
    setClickedEffect4(false);
    setClickedEffect5(false);
    setSelectedStylePicture(getImageStyle9());
  };

  const handleClickedEffect10 = () => {
    setClickedEffect10(true);
    setClickedEffect15(false);
    setClickedEffect14(false);
    setClickedEffect13(false);
    setClickedEffect12(false);
    setClickedEffect11(false);
    setClickedEffect9(false);
    setClickedEffect7(false);
    setClickedEffect8(false);
    setClickedEffect6(false);
    setClickedEffect1(false);
    setClickedEffect2(false);
    setClickedEffect3(false);
    setClickedEffect4(false);
    setClickedEffect5(false);
    setSelectedStylePicture(getImageStyle10());
  };

  const handleClickedEffect11 = () => {
    setClickedEffect11(true);
    setClickedEffect15(false);
    setClickedEffect14(false);
    setClickedEffect13(false);
    setClickedEffect12(false);
    setClickedEffect10(false);
    setClickedEffect9(false);
    setClickedEffect7(false);
    setClickedEffect8(false);
    setClickedEffect6(false);
    setClickedEffect1(false);
    setClickedEffect2(false);
    setClickedEffect3(false);
    setClickedEffect4(false);
    setClickedEffect5(false);
    setSelectedStylePicture(getImageStyle11());
  };
  const handleClickedEffect12 = () => {
    setClickedEffect12(true);
    setClickedEffect15(false);
    setClickedEffect14(false);
    setClickedEffect13(false);
    setClickedEffect11(false);
    setClickedEffect10(false);
    setClickedEffect9(false);
    setClickedEffect7(false);
    setClickedEffect8(false);
    setClickedEffect6(false);
    setClickedEffect1(false);
    setClickedEffect2(false);
    setClickedEffect3(false);
    setClickedEffect4(false);
    setClickedEffect5(false);
    setSelectedStylePicture(getImageStyle12());
  };
  const handleClickedEffect13 = () => {
    setClickedEffect13(true);
    setClickedEffect15(false);
    setClickedEffect14(false);
    setClickedEffect12(false);
    setClickedEffect11(false);
    setClickedEffect10(false);
    setClickedEffect9(false);
    setClickedEffect7(false);
    setClickedEffect8(false);
    setClickedEffect6(false);
    setClickedEffect1(false);
    setClickedEffect2(false);
    setClickedEffect3(false);
    setClickedEffect4(false);
    setClickedEffect5(false);
    setSelectedStylePicture(getImageStyle13());
  };
  const handleClickedEffect14 = () => {
    setClickedEffect14(true);
    setClickedEffect15(false);
    setClickedEffect13(false);
    setClickedEffect12(false);
    setClickedEffect11(false);
    setClickedEffect10(false);
    setClickedEffect9(false);
    setClickedEffect7(false);
    setClickedEffect8(false);
    setClickedEffect6(false);
    setClickedEffect1(false);
    setClickedEffect2(false);
    setClickedEffect3(false);
    setClickedEffect4(false);
    setClickedEffect5(false);
    setSelectedStylePicture(getImageStyle14());
  };
  const handleClickedEffect15 = () => {
    setClickedEffect15(true);
    setClickedEffect14(false);
    setClickedEffect13(false);
    setClickedEffect12(false);
    setClickedEffect11(false);
    setClickedEffect10(false);
    setClickedEffect9(false);
    setClickedEffect7(false);
    setClickedEffect8(false);
    setClickedEffect6(false);
    setClickedEffect1(false);
    setClickedEffect2(false);
    setClickedEffect3(false);
    setClickedEffect4(false);
    setClickedEffect5(false);
    setSelectedStylePicture(getImageStyle15());
  };
  /* CODE FOR SEND SELECTED STYLE CSS IN LOCALSTORAGE -
     (NEED TO SEND THIS INFO TO BACKEND FOR PERSISTENCE FOR ALL POSTS PUBLICATION FOR EACH PICTURE WITH CSS EFFECTS)*/
  useEffect(() => {
    localStorage.setItem(
      "stylePublication",
      JSON.stringify(selectedStylePicture)
    );
  }, [selectedStylePicture]);

  return {
    clickCrop,
    setClickCrop,
    result,
    image,
    setImage,
    crop,
    setCrop,
    clickFilters,
    setClickFilters,
    clickedEffect1,
    setClickedEffect1,
    clickedEffect2,
    setClickedEffect2,
    clickedEffect3,
    setClickedEffect3,
    clickedEffect4,
    setClickedEffect4,
    clickedEffect5,
    setClickedEffect5,
    clickedEffect6,
    setClickedEffect6,
    clickedEffect7,
    setClickedEffect7,
    clickedEffect8,
    setClickedEffect8,
    clickedEffect9,
    setClickedEffect9,
    clickedEffect10,
    setClickedEffect10,
    clickedEffect11,
    setClickedEffect11,
    clickedEffect12,
    setClickedEffect12,
    clickedEffect13,
    setClickedEffect13,
    clickedEffect14,
    setClickedEffect14,
    clickedEffect15,
    setClickedEffect15,
    getImageStyle1,
    getImageStyle2,
    getImageStyle3,
    getImageStyle4,
    getImageStyle5,
    getImageStyle6,
    getImageStyle7,
    getImageStyle8,
    getImageStyle9,
    getImageStyle10,
    getImageStyle11,
    getImageStyle12,
    getImageStyle13,
    getImageStyle14,
    getImageStyle15,
    getCroppedImg,
    handleClickedEffect1,
    handleClickedEffect2,
    handleClickedEffect3,
    handleClickedEffect4,
    handleClickedEffect5,
    handleClickedEffect6,
    handleClickedEffect7,
    handleClickedEffect8,
    handleClickedEffect9,
    handleClickedEffect10,
    handleClickedEffect11,
    handleClickedEffect12,
    handleClickedEffect13,
    handleClickedEffect14,
    handleClickedEffect15,
    selectedStylePicture,
    preview,
  };
};
