/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { useRecoilState } from "recoil";

import "./feetloverGuides.css";
import { studioAtom } from "../../stateManagement/atoms/studioAtom";
import { useTranslation } from "react-i18next";
import TicklesImg from "../../assets/images/tickles/Intersection 3@2x.png";
import StarImg from "../../assets/images__v3/Abonnement_1.png";
import FavImg from "../../assets/images__v3/Favoris.png";
import CMImg from "../../assets/images__v3/Media Personnalise.png";

const FeetloverGuides = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [_, setOnStudio] = useRecoilState(studioAtom);

  useEffect(() => {
    setOnStudio(true);

    return () => {
      setOnStudio(false);
    };
  }, [setOnStudio]);

  return (
    <div className="feetloverGuides__container">
      <div className="feetloverGuides__header header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => {
            // update global state from recoil to show bottom navigation.
            setOnStudio(false);
            history.goBack();
          }}
        >
          <ChevronLeft />
        </IconButton>
        <h4>{t("FeetLovers Guide")}</h4>
      </div>
      <div className="feetloverGuides__content minmax__wrapper content__withHeader feet-lover-guides-container">
        <p>
          {t("feetLoverGuidFirstSection")}
          <div>
            <h2>
              {t("feetLoverGuidePublicPrivateTitle")}
              <img
                src={StarImg}
                alt="tickles"
                width="31"
                height="31"
                style={{ verticalAlign: "middle", marginLeft: "10px" }}
              />
            </h2>
            <p>{t("feetLoverGuidePublicSection")}</p>
            <p>
              {t("feetLoverGuidePrivateSection1st")}
              <img
                src={StarImg}
                alt="tickles"
                width="31"
                height="31"
                style={{
                  verticalAlign: "middle",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
              {t("feetLoverGuidePrivateSection2nd")}
            </p>
          </div>
          <div>
            <h2>
              {t("feetLoverGuideTicklesTitle")}
              <img
                src={TicklesImg}
                alt="tickles"
                width="31"
                height="31"
                style={{ verticalAlign: "middle", marginLeft: "10px" }}
              />
            </h2>
            <span>{t("feetLoverGuideTicklesSection")}</span>
            <ul>
              <li>
                {t("feetLoverGuideTicklesOne1st")}
                <img
                  src={CMImg}
                  alt="tickles"
                  width="41"
                  height="31"
                  style={{
                    verticalAlign: "middle",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                />
                {t("feetLoverGuideTicklesOne2nd")}
              </li>
              <li>{t("feetLoverGuideTicklesTwo")}</li>
            </ul>
          </div>
          <div>
            <h2>{t("feetLoverGuideCMTitle")}</h2>
            <span>{t("feetLoverGuideCMSection")}</span>
            <ul>
              <li>
                {t("feetLoverGuideCMOne1st")}
                <img
                  src={FavImg}
                  alt="tickles"
                  width="31"
                  height="31"
                  style={{
                    verticalAlign: "middle",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                />
                {t("feetLoverGuideCMOne2nd")}
              </li>
              <li>{t("feetLoverGuideCMTwo")}</li>
              <li>
                {t("feetLoverGuideCMTree1st")}
                <img
                  src={TicklesImg}
                  alt="tickles"
                  width="31"
                  height="31"
                  style={{
                    verticalAlign: "middle",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                />
                {t("feetLoverGuideCMTree2nd")}
                <p>{t("feetLoverGuideCMFour")}</p>
              </li>
              <li>
                <b>{t("feetLoverGuideCMFive")}</b>
                <img
                  src={CMImg}
                  alt="tickles"
                  width="41"
                  height="31"
                  style={{
                    verticalAlign: "middle",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                />
                {t("feetLoverGuideCMSix")}
              </li>
            </ul>
          </div>
          <p>{t("feetLoverGuideCMSeven")}</p>
          <p>{t("feetLoverGuideCMEndSection")}</p>
          <p>
            {t("feetLoverGuideEndSection")}
            <a href={`mailto: ${t("feetLoverGuideEndMailSection")}`}>
              <b>{t("feetLoverGuideEndMailSection")}</b>
            </a>
          </p>
          <i>
            <b>{t("feetLoverGuideFrom")}</b>
          </i>
        </p>
        <br />
        <br />
      </div>
    </div>
  );
};

export default FeetloverGuides;
