import React, { Fragment } from "react";
import Webcam from "react-webcam";
import { useHistory } from "react-router-dom";
import "./videoStudioModels.css";
import { Autorenew, ChevronLeft, CloseOutlined } from "@material-ui/icons/";
import BaseLayout from "../layouts/BaseLayout";
import { Avatar, Button } from "@material-ui/core";
import PictyFilters from "../../pictyFilters/PictyFilters";
import useSelectAndRemove from "../postPublications/hooks/useSelectAndRemove";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  studioAtom,
  userTypeAtom,
} from "../../../stateManagement/atoms/studioAtom";

const VideoStudioModels = (props) => {
  let history = useHistory();
  const userType = useRecoilValue(userTypeAtom);
  const [onStudio, setOnStudio] = useRecoilState(studioAtom);
  const {
    selectedPictiesList,
    selected1,
    selected2,
    selected3,
    selected4,
    selected5,
    handleRemove1,
    handleRemove2,
    handleRemove3,
    handleRemove4,
    handleRemove5,
  } = useSelectAndRemove();

  React.useEffect(() => {
    if (onStudio === false) {
      setOnStudio(true);
    }
  }, [onStudio, setOnStudio]);

  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [clickedFlip, setClickedFlip] = React.useState(false);
  const [clickedSee, setClickedSee] = React.useState(false);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      let vidSave = document.getElementById("vid2");
      vidSave.src = url;
    }
  }, [recordedChunks]);

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing]);

  const handleClickedFlip = () => {
    setClickedFlip(clickedFlip !== true);
  };

  const videoConstraints = (flip) => {
    return {
      facingMode: { exact: !flip ? "environment" : "user" },
      width: { min: 480 },
      height: { min: 720 },
      aspectRatio: 1.1,
    };
  };

  return (
    <BaseLayout>
      <div
        className={
          recordedChunks.length <= 0
            ? "video-container"
            : "video-container-hidden"
        }
        id="top"
      >
        {recordedChunks.length <= 0 && (
          <Webcam
            videoConstraints={videoConstraints(clickedFlip)}
            height={820}
            width={410}
            audio={true}
            ref={webcamRef}
            className={recordedChunks.length <= 0 ? "video1" : "hidden"}
          />
        )}
        {capturing ? (
          <div
            className={
              recordedChunks.length <= 0 ? "div-button-stop" : "hidden"
            }
          >
            <button
              className="button-video-stop blink_me"
              onClick={handleStopCaptureClick}
            >
              {""}
            </button>
          </div>
        ) : (
          <div
            className={recordedChunks.length <= 0 ? "button-content" : "hidden"}
          >
            <div className="div-button-close">
              <button
                className="button-close"
                onClick={() => history.push("/studio")}
              >
                <CloseOutlined style={{ color: "white" }} />
              </button>
            </div>
            <div className="div-button-start">
              <button
                className="button-video-start"
                onClick={handleStartCaptureClick}
              >
                {""}
              </button>
            </div>
            <div className="div-button-flip">
              <button
                className="button-flip-camera"
                onClick={handleClickedFlip}
              >
                <Autorenew />
              </button>
            </div>
          </div>
        )}
        {recordedChunks.length > 0 && (
          <div id="movieTop">
            <div className={clickedSee === true ? "" : "overlay"}> </div>
            <a
              className={clickedSee === false ? "movieTopContent" : "hidden"}
              onClick={() => {
                setClickedSee(true);
                history.push("/studio-video#vid2");
              }}
              href="#movieTop"
            >
              <button className="downVid" onClick={handleDownload}>
                See your video
              </button>
            </a>
            <a
              className={clickedSee === false ? "movieTopContent" : "hidden"}
              onClick={() => history.push("/studio")}
              href="/studio"
            >
              <button className="recordVid">Record an another video</button>
            </a>
            <div className="movieTopContent__header">
              <Button>
                <ChevronLeft />
              </Button>
              <h4>{t("postPublicationTitle")}</h4>
              <Button
                onClick={() => history.push("/home-page-models")}
                className="videoStudio__postButton"
              >
                {t("postPublicationButton")}
              </Button>
            </div>
            <div className="videoStudio__postSection">
              <video id="vid2" style={{ maxWidth: 385 }} controls></video>
              <textarea
                className="text-publi-video"
                type="text"
                multiple
                placeholder="Add post description"
              />
              <div className="videoStudio__selectedPicty">
                <div className="videoStudioSelectedPicty__title">
                  <b>Selected Picty</b>
                  <p>{`( ${selectedPictiesList.length}/5 )`}</p>
                </div>
                <div className="videoStudioSelectedPicty__icons">
                  {selectedPictiesList[0] !== undefined ? (
                    <Fragment>
                      <Avatar
                        src={selected1}
                        className="videoStudioSelectedPicty__selectedAvatar"
                      />
                      <span className="videoStudioSelectedPicty__button">
                        <span
                          onClick={() => handleRemove1()}
                          className="videoStudioSelectedPicty__deleteButton"
                        >
                          +
                        </span>
                      </span>
                    </Fragment>
                  ) : (
                    <Avatar style={{ opacity: 0.1 }} />
                  )}
                  {selectedPictiesList[1] !== undefined ? (
                    <Fragment>
                      <Avatar
                        src={selected2}
                        className="videoStudioSelectedPicty__selectedAvatar"
                      />
                      <span className="videoStudioSelectedPicty__button">
                        <span
                          onClick={() => handleRemove2()}
                          className="videoStudioSelectedPicty__deleteButton"
                        >
                          +
                        </span>
                      </span>
                    </Fragment>
                  ) : (
                    <Avatar style={{ opacity: 0.1 }} />
                  )}
                  {selectedPictiesList[2] !== undefined ? (
                    <Fragment>
                      <Avatar
                        src={selected3}
                        className="videoStudioSelectedPicty__selectedAvatar"
                      />
                      <span className="videoStudioSelectedPicty__button">
                        <span
                          onClick={() => handleRemove3()}
                          className="videoStudioSelectedPicty__deleteButton"
                        >
                          +
                        </span>
                      </span>
                    </Fragment>
                  ) : (
                    <Avatar style={{ opacity: 0.1 }} />
                  )}
                  {selectedPictiesList[3] !== undefined ? (
                    <Fragment>
                      <Avatar
                        src={selected4}
                        className="videoStudioSelectedPicty__selectedAvatar"
                      />
                      <span className="videoStudioSelectedPicty__button">
                        <span
                          onClick={() => handleRemove4()}
                          className="videoStudioSelectedPicty__deleteButton"
                        >
                          +
                        </span>
                      </span>
                    </Fragment>
                  ) : (
                    <Avatar style={{ opacity: 0.1 }} />
                  )}
                  {selectedPictiesList[4] !== undefined ? (
                    <Fragment>
                      <Avatar
                        src={selected5}
                        className="videoStudioSelectedPicty__selectedAvatar"
                      />
                      <span className="videoStudioSelectedPicty__button">
                        <span
                          onClick={() => handleRemove5()}
                          className="videoStudioSelectedPicty__deleteButton"
                        >
                          +
                        </span>
                      </span>
                    </Fragment>
                  ) : (
                    <Avatar style={{ opacity: 0.1 }} />
                  )}
                </div>
                <div className="videoStudioSelectedPictyList__names">
                  {selectedPictiesList[0] !== undefined ? (
                    <sub>{selectedPictiesList[0]}</sub>
                  ) : (
                    <sub style={{ opacity: 0 }}>{"     "}</sub>
                  )}
                  {selectedPictiesList[1] !== undefined ? (
                    <sub>{selectedPictiesList[1]}</sub>
                  ) : (
                    <sub style={{ opacity: 0 }}>{"     "}</sub>
                  )}
                  {selectedPictiesList[2] !== undefined ? (
                    <sub>{selectedPictiesList[2]}</sub>
                  ) : (
                    <sub style={{ opacity: 0 }}>{"     "}</sub>
                  )}
                  {selectedPictiesList[3] !== undefined ? (
                    <sub>{selectedPictiesList[3]}</sub>
                  ) : (
                    <sub style={{ opacity: 0 }}>{"     "}</sub>
                  )}
                  {selectedPictiesList[4] !== undefined ? (
                    <sub>{selectedPictiesList[4]}</sub>
                  ) : (
                    <sub style={{ opacity: 0 }}>{"     "}</sub>
                  )}
                </div>
              </div>
            </div>
            <div className="videoStudio__pictyFilter">
              <PictyFilters userType={userType} />
            </div>
          </div>
        )}
      </div>
    </BaseLayout>
  );
};

export default VideoStudioModels;
