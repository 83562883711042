import { atom } from "recoil";
import { getAuthSession } from "../../helpers/auth.helpers";

export const studioAtom = atom({
  key: "studioState",
  default: false,
});

export const userTypeAtom = atom({
  key: "userTypeState",
  default: getAuthSession()?.user?.userType || "",
});

export const editProfileAtom = atom({
  key: "editProfileState",
  default: false,
});

export const onSignupAtom = atom({
  key: "onSignUpState",
  default: false,
});

export const onSettingModelsAtom = atom({
  key: "onSettingModelsState",
  default: false,
});

export const sendedTicklesAtom = atom({
  key: "sendedTicklesState",
  default: false,
});
