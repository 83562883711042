import moment from "moment";

const dateLocale = {
  setup: async function () {
    const lang = localStorage.getItem("language");

    if (lang && lang !== "en") {
      await import(`moment/locale/${lang}`);
    }
  },
  localize: function (date) {
    const lang = localStorage.getItem("language") || "en";
    return moment(date).locale(lang);
  },
};

export default dateLocale;
