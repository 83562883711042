import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import ticklesLogo from "../../../../../assets/images/tickles/Intersection 3@2x.png";
import { getAuthSession } from "../../../../../helpers/auth.helpers";
import dateLocale from "../../../../../dateLocale";

export const MessageTickle = (props) => {
  const user = getAuthSession().user;
  const { t } = useTranslation();
  return (
    <Fragment>
      <br />
      <p className="createMessage__date">
        {dateLocale.localize(props.message.createdAt).fromNow()}
      </p>
      <div className="createMessage__SentTickles">
        {user.id === props.message.from.id ? (
          <b>
            {t("createMessageYouVeSent")} {props.message.tickles}
          </b>
        ) : (
          <b>
            {t("createMessageYouVeReceived")} {props.message.tickles}
          </b>
        )}
        <img
          src={ticklesLogo}
          alt="tickles logo"
          className="createMessage__ticklesLogo"
        />
      </div>
    </Fragment>
  );
};
