import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import MessageService from "../../_services/message.service";
/* eslint-disable */
const VideoPlayer = React.forwardRef((props, ref) => {
  const { playsinline, playing, fsUrl, controls, ...rest } = props;

  const [play, setPlay] = useState(playing);
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (fsUrl) {
      async function fetchPublication() {
        try {
          if (fsUrl.includes("storage.googleapis.com")) {
            setUrl(fsUrl);
          } else {
            const response = await MessageService.getMessageFileFromFileSystem(
              fsUrl
            );

            const text = await new Response(response).text();

            if (text !== "Input file is missing") {
              const objectURL = URL.createObjectURL(response);

              setUrl(objectURL);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
      fetchPublication();
    }
  }, [fsUrl]);

  return (
    <div
      className={"cm-video-player"}
      style={{ position: "relative", paddingTop: "66.25%" }}
    >
      <ReactPlayer
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        controls
        config={{ file: { attributes: { controlsList: "nodownload" } } }}
        url={url}
        width="100%"
        height="100%"
        onPause={() => setPlay(false)}
        // onClick={() => setPlay((prev) => !prev)}
        playing={play}
        {...rest}
      />
    </div>
  );
});
export default VideoPlayer;
