/* eslint-disable no-unused-vars */
import "./notFoundPage.css";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PageNotFound from "../../assets/images/not-found/error2.png";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { studioAtom } from "../../stateManagement/atoms/studioAtom";

const NotFoundPage = (props) => {
  const [isClicked, setIsClicked] = useState(false);
  const [, setOnStudio] = useRecoilState(studioAtom);
  let history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    setOnStudio(true);
  }, [setOnStudio]);

  const handleIsClicked = () => {
    if (isClicked === true) {
      setIsClicked(false);
    }
    if (isClicked === false) {
      setIsClicked(true);
    }
  };

  const returnHome = () => {
    setTimeout(() => {
      setOnStudio(false);
      history.replace("/");
    }, 1000);
  };

  return (
    <div className="notFound__container color-change-2x">
      <img
        className={
          isClicked === false
            ? "notFound__img slide-in-top"
            : "notFound__img scale-out-center"
        }
        src={PageNotFound}
        alt="not found"
      />
      <div
        className={
          isClicked === false
            ? "notFound__text puff-in-center"
            : "notFound__text tracking-out-expand-fwd"
        }
        style={{ textAlign: "center" }}
      >
        <p>{t("notFoundParagraph1")}</p>
      </div>
      <Button
        onClick={() => {
          handleIsClicked();
          returnHome();
        }}
        variant="contained"
        className={
          isClicked === false
            ? "notFound__button swirl-in-fwd"
            : "notFound__button slit-out-vertical"
        }
      >
        {t("notFoundButton")}{" "}
      </Button>
    </div>
  );
};
export default NotFoundPage;
