import { Redirect, Route } from "react-router";
import React from "react";
import { isAuthenticated } from "../helpers/auth.helpers";

const AuthenticatedRoute = ({
  component: Component = null,
  render: Render = null,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated()) {
          if (Render) {
            return Render(props);
          } else if (Component) {
            return <Component {...props} />;
          } else {
            return null;
          }
        } else {
          return <Redirect to="/" />;
        }
      }}
    />
  );
};

export default AuthenticatedRoute;
