/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import BottomNavigationCropTool from "../layouts/shared/BottomNavigationCropTool";
import Header from "../layouts/shared/Header";
import CropIcon from "@material-ui/icons/Crop";
import "./hello-picture.css";
import { useCropAndFilter } from "./hook/useCropAndFilter";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import useSessionStorage from "../../forms/services/storageHooks/useSessionStorage";
import { useSetRecoilState } from "recoil";
import uploadFilePublicationSelector from "../../../stateManagement/selectors/publication/setUploadFile";

const HelloPicture = () => {
  let history = useHistory();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const [selectedStylesForImg, setSelectedStylesForImg] = useState({});
  const [__] = useSessionStorage({}, "infosUser");
  const [isFromCreateMessage, setIsFromCreateMessage] = React.useState(false);
  const [isFromCreateMessageData, setIsFromCreateMessageData] = React.useState(
    {}
  );

  const setUploadFilePublication = useSetRecoilState(
    uploadFilePublicationSelector
  );

  const [fileToLocStor, setFileToLocStor] = useState(
    window.localStorage.getItem("preview")
  );

  const {
    clickCrop,
    setClickCrop,
    result,
    image,
    setImage,
    crop,
    setCrop,
    clickFilters,
    setClickFilters,
    clickedEffect1,
    clickedEffect2,
    clickedEffect3,
    clickedEffect4,
    clickedEffect5,
    clickedEffect6,
    clickedEffect7,
    clickedEffect8,
    clickedEffect9,
    clickedEffect10,
    clickedEffect11,
    clickedEffect12,
    clickedEffect13,
    clickedEffect14,
    clickedEffect15,
    getImageStyle1,
    getImageStyle2,
    getImageStyle3,
    getImageStyle4,
    getImageStyle5,
    getImageStyle6,
    getImageStyle7,
    getImageStyle8,
    getImageStyle9,
    getImageStyle10,
    getImageStyle11,
    getImageStyle12,
    getImageStyle13,
    getImageStyle14,
    getImageStyle15,
    getCroppedImg,
    handleClickedEffect1,
    handleClickedEffect2,
    handleClickedEffect3,
    handleClickedEffect4,
    handleClickedEffect5,
    handleClickedEffect6,
    handleClickedEffect7,
    handleClickedEffect8,
    handleClickedEffect9,
    handleClickedEffect10,
    handleClickedEffect11,
    handleClickedEffect12,
    handleClickedEffect13,
    handleClickedEffect14,
    handleClickedEffect15,
    selectedStylePicture,
    preview,
  } = useCropAndFilter();

  useEffect(() => {
    if (location && location.state && location.state.isFromCreateMessage) {
      setIsFromCreateMessage(true);
      setIsFromCreateMessageData({
        receiver: location.state.receiver,
        convoId: location.state.convoId,
      });
    }
  }, [location]);

  useEffect((e) => {
    if (!__?.user?.idCheckDetails?.verified) {
      history.replace("/identity-verification");
    }
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     result && setUploadFilePublication(result);
  //   }, 1000);
  // }, [result]);

  return (
    <Fragment>
      <div
        className="cropFromFile-header"
        onClick={() =>
          __?.user?.userType === "Lover"
            ? history.push("/home-page")
            : history.push("/home-page-models")
        }
      >
        <Header />
      </div>
      <div className="CropFromFile-container content__withHeader minmax__wrapper">
        {clickCrop === false ? (
          <div className="cropFromFile-content">
            <div
              className="text-step-cropFromFile"
              style={{ color: "#f82f72" }}
            >
              <h4
                className="cropFromFile-h4"
                style={{ color: "black", fontSize: 13 }}
              >
                {t("homeStudioStepTwo")}
              </h4>
              <div
                className="step-text-cropFromFile"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CropIcon /> &ensp;
                <h4 className="text-picture-step-crop">
                  {" "}
                  {t("helloPictureCrop")}
                </h4>
              </div>
            </div>
            <div className="cropFromFile-img-content">
              {fileToLocStor && (
                <span>
                  <ReactCrop
                    src={fileToLocStor}
                    onImageLoaded={setImage}
                    crop={crop}
                    locked
                    onChange={setCrop}
                  />
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className="cropFromFile-content">
            <div
              className="text-step-cropFromFile"
              style={{ color: "#f82f72" }}
            >
              <h4
                className="cropFromFile-h4"
                style={{ color: "black", fontSize: 13, marginBottom: 10 }}
              >
                {t("helloPictureStepThree")}
              </h4>
              <div
                className="step-text-cropFromFile"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CropIcon /> &ensp;
                <h4
                  className={
                    i18n.language === "en"
                      ? "text-picture-step-crop"
                      : "text-picture-step-cropFr"
                  }
                >
                  {t("helloPictureAddFilters")}
                </h4>
              </div>
            </div>
            <div className="cropFromFile-img-content">
              {preview && (
                <div className="col-6">
                  <img src={preview} alt="cropped" className="img-fuild" />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {clickCrop === false ? (
        <div className="cropFromFile-footer">
          <BottomNavigationCropTool
            isFromCreateMessage={isFromCreateMessage}
            getCroppedImg={getCroppedImg}
            clickCrop={clickCrop}
            setClickCrop={setClickCrop}
            clickFilters={clickFilters}
            setClickFilters={setClickFilters}
            getImageStyle
            isFromCreateMessageData={isFromCreateMessageData}
            authUser={__}
            image={image}
          />
        </div>
      ) : null}
    </Fragment>
  );
};

export default HelloPicture;
