import { Dialog, Divider } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

import DialogTitle from "../../../../../BuyTickleModal/DialogTitle";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../../../pages/SuppressAccount/styled";
import PublicationService from "../../../../../../_services/publication.service";

function DeletePublication(props) {
  let history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const { openModal, setOpenModal, publication, setOpen } = props;
  return (
    <Dialog
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      className={classes.suppressDialog}
    >
      <DialogTitle>
        <p style={{ marginTop: "10px" }}>{t("modalYesNo")}</p>
      </DialogTitle>
      <Divider />
      <div className={classes.suppressModalButtonsContainer}>
        <button
          type={"button"}
          onClick={() => {
            setOpenModal(false);
          }}
          style={{ color: "#000000" }}
        >
          {t("No")}
        </button>

        <button
          type={"button"}
          onClick={async () => {
            setOpenModal(false);
            try {
              await PublicationService.remove(publication._id);

              setOpen(false);

              if (
                window.location.pathname === `/publication/${publication._id}`
              ) {
                history.goBack();
              } else {
                window.location.reload();
              }
            } catch (error) {
              console.error("ERROR POST /api/v1/block/user/:id", error);
            }
          }}
        >
          {t("Yes")}
        </button>
      </div>
    </Dialog>
  );
}

export default DeletePublication;
