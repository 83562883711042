import React, { Fragment, useEffect, useState } from "react";
import "./successPayment.css";
import Success from "../../../assets/images/success-payment/Group 2599@2x.png";
import SuccesPhone from "../../../assets/images/success-payment/success-phone.png";
import LoaderSuccess from "./LoaderSuccess";
import { useTranslation } from "react-i18next";

const SuccessPayment = () => {
  const [loadedPayment, setLoaderPayment] = useState(true);
  const [bluredText, setBluredText] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setLoaderPayment(false);
    }, 3500);

    setTimeout(() => {
      setBluredText(true);
    }, 3000);
  });

  return (
    <div className="successPayment__container">
      <div className="successPayment__header header header-height">
        <h4>{t("subscriptionPaymentTitle")}</h4>
      </div>
      <div className="successPayment__content">
        {loadedPayment === true ? (
          <Fragment>
            <img
              src={SuccesPhone}
              alt="success"
              className="successPayment__img slide-in-left"
            />
            <div className="successPayment__loader slide-in-left">
              <LoaderSuccess />
            </div>
            <h4 className={bluredText === false ? "" : "text-blur-out"}>
              {t("transactionProgressSuccesPayment")}
            </h4>
            <div className="successPayment__dot-collision">
              <div className="dot-pulse"></div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <img
              src={Success}
              alt="success"
              className="successPayment__img flip-horizontal-bottom"
            />
            <h4 className="text-focus-in">{t("succesPayment")}</h4>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default SuccessPayment;
