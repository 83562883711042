import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import React from "react";
import { useStyles } from "../../../../../BuyTickleModal/styled";

import DialogTitle from "../../../../../BuyTickleModal/DialogTitle";
import CheckSolidIcon from "../../../../../../assets/images__v3/Certification_solid.png";
import "./styles.css";
import genericUserIcon from "../../../../../../assets/images/generic-user-avatar.png";
import TransactionService from "../../../../../../_services/transaction.service";
import { useTranslation } from "react-i18next";

function Subscription(props) {
  const { openSubscriptionModal, setOpenSubscriptionModal, feetModelProfile } =
    props;
  const { t } = useTranslation();
  const classes = useStyles();
  const roundPrice = (num, decimalPlaces = 2) => {
    const p = Math.pow(10, decimalPlaces);
    const n = num * p * (1 + Number.EPSILON);
    return Math.round(n) / p;
  };

  const subscriptionPrice = (num) => {
    let amount = 0;
    if (num === 10.83) {
      amount = 13;
    } else if (num === 20.83) {
      amount = 25;
    } else if (num === 40.83) {
      amount = 49;
    }
    return amount;
  };
  const isRoleTypeBlogger = () => {
    if (feetModelProfile && feetModelProfile.profile) {
      if (feetModelProfile?.profile?.roleType) {
        return feetModelProfile?.profile?.roleType.includes("blogger");
      }
      return false;
    }
  };
  return (
    <Dialog
      className={classes.dialogRoot}
      open={openSubscriptionModal}
      onClose={() => setOpenSubscriptionModal(false)}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setOpenSubscriptionModal(false)}
        iconClassName={classes.closeButton}
        className={classes.dialogTitleRoot}
        page={"subscription"}
      >
        Abonnement au mur privé
      </DialogTitle>
      <DialogContent className={classes.dialogContentRoot}>
        <di className="SubscribeToUnlockPublication__content">
          <div className="SubscribeToUnlockPublication__body">
            <div className="SubscribeToUnlockPublication__center">
              <div className="SubscribeToUnlockPublication__imgContainer">
                <img
                  className="SubscribeToUnlockPublication__pict"
                  src={
                    feetModelProfile &&
                    feetModelProfile.profile &&
                    feetModelProfile.profile.image
                      ? feetModelProfile.profile.image.url
                      : genericUserIcon
                  }
                  alt="profile-pict"
                />
                {/*<img*/}
                {/*  className="SubscribeToUnlockPublication__pictyPict"*/}
                {/*  src={star}*/}
                {/*  alt="star"*/}
                {/*/>*/}
              </div>
              <div className="SubscribeToUnlockPublication__name">
                <h4>
                  {feetModelProfile && feetModelProfile.profile
                    ? feetModelProfile.profile.username
                    : null}
                </h4>
              </div>
            </div>
            <ul className="buy-tickles-check-list">
              <li>
                <img
                  width="17.8"
                  height="13.45"
                  src={CheckSolidIcon}
                  alt="check-icon"
                  className="check-icon"
                />{" "}
                Discover her exclusive videos
              </li>
              <li>
                <img
                  width="17.8"
                  height="13.45"
                  src={CheckSolidIcon}
                  alt="check-icon"
                  className="check-icon"
                />{" "}
                Access her blurred photos
              </li>
              <li className="leave-tips-in-the-chat">
                <img
                  width="17.8"
                  height="13.45"
                  src={CheckSolidIcon}
                  alt="check-icon"
                  className="check-icon"
                />
                <span>
                  Highlighting your nickname so that it distinguish you from
                  other Feetlovers
                </span>
              </li>
            </ul>
          </div>
        </di>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          className="SubscribeToUnlockPublication__button"
          onClick={async () => {
            if (
              feetModelProfile &&
              feetModelProfile.profile &&
              feetModelProfile.profile?.currentProduct?.amount &&
              feetModelProfile.profile?.acceptSubscribers === false &&
              !isRoleTypeBlogger()
            ) {
              const response = await TransactionService.subscribeToUser({
                modelToSubscribe: feetModelProfile.profile._id,
                return_url: `${process.env.REACT_APP_FRONTEND_URL}/feet-models-view?user=${feetModelProfile.profile._id}`,
              });

              if (response.status === 200) {
                addNotificationMessage(
                  "Please wait while you are redirected to the payment gateway",
                  "info"
                );

                // redirect to epoch payment gateway
                window.location.href = response.data.url;
              }
            }
          }}
          disabled={
            feetModelProfile.profile?.acceptSubscribers ||
            (feetModelProfile?.profile?.roleType &&
              feetModelProfile?.profile?.roleType.includes("blogger"))
          }
        >
          {feetModelProfile &&
          feetModelProfile.profile &&
          feetModelProfile.profile?.currentProduct &&
          feetModelProfile.profile?.currentProduct?.amount &&
          feetModelProfile.profile?.acceptSubscribers === false &&
          !isRoleTypeBlogger() ? (
            <>
              <h4>
                {subscriptionPrice(
                  roundPrice(feetModelProfile.profile?.currentProduct?.amount)
                )}
                € / {t("monthUnlockPublicationLover")}
              </h4>
              <p>{t("cancelUnlockPublicationLover")}</p>
            </>
          ) : (
            <h4>{t("subscriptionNotSet")}</h4>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Subscription;
