import "../styles.css";

import { Avatar, IconButton } from "@material-ui/core";
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ChevronLeft } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomMediaModal from '../../../Modals/CustomMedia/index';
import FeetModelService from "../../../../_services/feet-models.service";
import FetchFileWithAuthentication from "../../../FetchFileWithAuthentication";
import Picture from "../../../../assets/images__v3/Planche_boutons-55.png";
import TickGris from "../../../../assets/images__v3/Certification_solid.png";
import TicklesImg from "../../../../assets/images__v3/Tickles.png";
import TicklesImgGris from "../../../../assets/images__v3/Tickles Gris.png";
import UserService from "../../../../_services/user.service";
import VideoPreviewModal from "../../../Modals/VideoPreviewModal";
import Warning from "../../../../assets/images/toasts/warning.svg";
import feetModelAtom from "../../../../stateManagement/atoms/feetModelAtom";
import genericAvatar from "../../../../assets/images/generic-user-avatar.png";
import { handleImageCompression } from "../../../../helpers/browser-image-compression";
import moment from "moment/moment";
import { useDropzone } from "react-dropzone";
import { useRecoilState } from "recoil";
import useSessionStorage from "../../../forms/services/storageHooks/useSessionStorage";
import { useTranslation } from "react-i18next";
import useMessageOrSpeech from "../../../loversComponents/messageSection/createMessage/hooks/useMessageOrSpeech";
import {uploadMultipleMedias} from "../../../../_services/firebase.service";
import nanoMetadata from 'nano-metadata'
import WarningIcon from "../../../../assets/images__v3/Feety Warning Icon.png";

const CustomMediaTrackerPage = (props) => {
  let history = useHistory();
  const { t } = useTranslation();
  let { id } = useParams();

  const [authUser] = useSessionStorage({}, "infosUser");
  const [__, setFeetModel] = useRecoilState(feetModelAtom);

  const [data, setData] = React.useState([]);
  const [images, setImages] = React.useState([]);
  const [isSubscriber, setIsSubscriber] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
  const [videoFileName, setVideoFileName] = useState("");
  const [play, setPlay] = useState(false);

  const [activeCustomMedia, setActiveCustomMedia] = useState(null);
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const [cmResponse, setCMResponse] = useState(null);
  const [acceptFiles, setAcceptFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [urls, setURLs] = useState([]);
  const {
    handleSendCMNotif,
    addNotificationMessage
  } = useMessageOrSpeech();

  const handleValidatingVideo = (acceptedFiles) => {
    console.log(acceptedFiles)
    const filesWithDuration = acceptedFiles.map((file) => {
      if (file && (file.type.includes("image"))) {
        return new Promise((resolve, reject) => {
          resolve({duration: null, files: file})
        })
      } else {
        return new Promise((resolve, reject) => {
          // nanoMetadata.video.duration(file).then((duration) => {
          //   console.log(duration) // will show you video duration in seconds
            resolve({duration: null, files: file})
          // })
          // setTimeout(reject, 1000);
          // const reader = new FileReader();
          // reader.onerror = reject
          // reader.onload = function () {
          //   const aud = new Audio(reader.result);
          //   aud.onloadedmetadata = function () {
          //     resolve({duration: aud.duration, files: file})
          //   };
          // };
          // reader.readAsDataURL(file);
        })
      }
    })
    Promise.all(filesWithDuration).then(async (results) => {
      console.log("files: ", results)
      let proceed = false;
      let approvedFile = [];
      results.map((file) => {
          if (file && (file.files.type.includes("image") || (file.files.type.includes("video")))) {
            proceed = true;
            approvedFile.push(file);
            // addNotificationMessage("Uploaded Successfully!", "info");
          } else {
            addNotificationMessage(t("ppUploadingValidateCMError"), "error");
            setIsUploading(false);
            setURLs([]);
            approvedFile = [];
            proceed = false;
          }
      })
      if (proceed === true && approvedFile.length === acceptedFiles.length) {
        console.log("cm_progress: ", progress)
        await uploadMultipleMedias(approvedFile, setProgress, uploadWithFirebase, 'message')
      }
    }).catch((e) => {
      console.log("Upload CM Error: Video has a bad format!", e)
      setIsUploading(false);
      setURLs([]);
      addNotificationMessage(t("ppUploadingBadFormatError"), "error");
    })
  }

  const uploadWithFirebase = async (responseUrl) => {
    console.log("tracking_responseUrl: ", responseUrl)
    if (responseUrl && responseUrl.length > 0) {
      responseUrl.map((result_url) => {
        if (result_url.error === false) {
          setURLs((url) => [...url, result_url.formatted_path])
        } else {
          addNotificationMessage(`${t("ppUploadingValidateCMError")} Filename: ${result_url.formatted_path}`, "error");
          setIsUploading(false);
        }
      })
    }
  }

  useEffect(() => {
    if ((urls.length > 0 && acceptFiles.length > 0)) {
      updateCM(true, urls).then()
    }
  }, [urls, acceptFiles])

  const updateCM = async (success, url) => {
    if (success) {
      console.log("response_urls: ", url)
      const uploadCMResponse = await FeetModelService.uploadCustomMediaFile(
          activeCustomMedia?.to._id,
          activeCustomMedia?.messageId || activeCustomMedia?._id,
          url
      ).catch(() => setIsUploading((isUploading) => !isUploading));
      // setup preview.

      setCMResponse(uploadCMResponse.data?.data);

      // setFiles(
      //     acceptFiles.map((file) =>
      //         Object.assign(file, {
      //           messageId: activeCustomMedia?.messageId || activeCustomMedia?._id,
      //           ...(!file.preview && { preview: uploadCMResponse.data.previewUrl }), // URL.createObjectURL(file) }),
      //         })
      //     )
      // );
      setTimeout(() => {
        setURLs([]);
        location.reload()
        setIsUploading((isUploading) => !isUploading)
      }, 5000)
    } else {
      setIsUploading(false);
      setURLs([])
    }
  }

  const { getInputProps, getRootProps } = useDropzone({
    noDrag: true,
    id: "file",
    type: "file",
    onDrop: async (acceptedFiles) => {
      // const compressedFiles = await Promise.all(
      //   acceptedFiles.map(async (file) => await handleImageCompression(file))
      // );
      setAcceptFiles(acceptedFiles)
      setIsUploading((isUploading) => !isUploading);
      // const uploadCustomMediaResponse = await FeetModelService.uploadCustomMediaFile(
      //   activeCustomMedia?.messageId || activeCustomMedia?._id,
      //   compressedFiles
      // );
      // To check if the custom media is a video or image then if it's a video it will validate the video length must not exceed to 5 mins
      // acceptedFiles.map((file) => {
      handleValidatingVideo(acceptedFiles)
      // })
    },
  });

  const handleRedirectProfile = (custom) => {
    if (authUser.user._id === custom.from._id) {
      history.push("/view-own-profile-models");
    } else {
      setFeetModel(custom.from);
      history.push({
        pathname: "/feet-models-view",
        search: `?user=${custom.from._id}`,
      });
    }
  };

  const handleCaption = () => {
    let caption = null;
    const dateFormat = "YYYY-MM-DD HH:mm:ss";
    const now = moment().format(dateFormat);
    const end = moment(data[0].updatedAt).format(dateFormat);
    const hoursLeft = moment(now).diff(end, "hours", true);
    if (data[0]?.customMediaStatus === "pending" && hoursLeft < 72) {
      caption = (
        <span style={{ color: "#A9A9A9" }}>
          ({t("ticklesAppearOn")}{" "}
          <a href="/tickles-account" style={{ color: "#FF5768" }}>
            {t("myEarnings")}
          </a>{" "}
          {t("myEarningsPhrase")} )
        </span>
      );
    } else if (data[0]?.customMediaStatus === "pending" && hoursLeft >= 72) {
      caption = (
        <span style={{ color: "#FF5768" }}>
          {t("warningMessageCMDeadline")}
        </span>
      );
    }
    return caption;
  };

  const handleCancelVideo = async () => {
    await setPlay(false);
    setVideoFileName("");
    setIsVideoModalVisible(false);
  };

  const showVideosModal = (fsUrl, status) => {
    setVideoFileName(fsUrl);
    setPlay(true);
    if (authUser && authUser.user) {
      authUser.user.userType === "Model"
        ? setIsVideoModalVisible(true)
        : status === "approved" && setIsVideoModalVisible(true);
    }
  };

  useEffect(() => {
    (async () => {
      if (data.length) {
        const dateFormat = "YYYY-MM-DD HH:mm:ss";
        const now = moment().format(dateFormat);
        const end = moment(data?.[0].updatedAt).format(dateFormat);
        const hoursLeft = moment(now).diff(end, "hours", true);
        setIsExpired(
          data?.[0].customMediaStatus === "pending" && hoursLeft >= 72
        );

        if (isExpired) {
          await UserService.processRefund(id, data)
            .then((response) => response)
            .catch((error) => error);
        }
      }
    })();
  }, [data, isExpired, id]);

  useEffect(() => {
    if (id) {
      UserService.getCustomMediasById(id)
        .then((response) => {
          setMessage(response?.data?.message?.customMedias);
          setIsSubscriber(response.data.message?.subscriber);
          setData(() => [response.data.message?.customMedias]);
          setImages(response.data.message?.images);
          setLoading(false);
        })
        .catch((error) => error);
    }
  }, [id]);

  useEffect(() => {
    if (cmResponse && images.length < 1) {
      handleSendCMNotif(data[0].to)
    }

    return () => {
      setCMResponse(null);
    };
  }, [cmResponse]);

  return (
    <div>
      <div className="header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => {
            window.history.pushState(null, null, "/custom-media-tracking");
            window.history.go();
          }}
        >
          <ChevronLeft />
        </IconButton>
        <h4>{t("Custom media tracking")}</h4>
      </div>
      {loading ? (
        <div
          style={{
            position: "absolute",
            left: "calc(50% - 20px)",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : null}
      {data && data.length > 0 ? (
        <div className="blockedAccounts__content content__withHeader minmax__wrapper">
          <div className="messageSearch__search">
            <div className="user_thumbnail_details">
              {authUser.user.userType === "Lover" ? (
                <div className="avatar">
                  <Avatar
                    className="blockedAccounts__avatar"
                    src={
                      data && data[0].from && data[0].from.image
                        ? data[0].from.image.url
                        : genericAvatar
                    }
                    alt="profile pict"
                    onClick={() => handleRedirectProfile(data[0])}
                  />
                </div>
              ) : null}
              <div
                className="user_details"
                onClick={() => handleRedirectProfile(data[0])}
              >
                <h2 className={isSubscriber ? "subscriber" : ""}>
                  {authUser.user.userType === "Lover" &&
                  (data[0]?.from?.username || data[0]?.to?.username)
                    ? data[0]?.from?.username
                    : data[0]?.to?.username}
                </h2>
                {authUser.user.userType === "Lover" ? (
                  <p>
                    {moment(data[0].createdAt).format("DD.MM.yyyy")} : &nbsp;
                    {data[0]?.customMediaStatus !== "rejected" ? (
                      <span>{data[0].tickles}</span>
                    ) : (
                      <del>{data[0].tickles}</del>
                    )}
                    &nbsp;
                    <span>
                      <img
                        src={
                          data[0]?.customMediaStatus !== "rejected"
                            ? TicklesImg
                            : TicklesImgGris
                        }
                        alt="tickles icon"
                        width="20"
                        height="20"
                        style={{ verticalAlign: "bottom" }}
                      />
                    </span>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div>
            {authUser.user.userType === "Lover" &&
            (images && images.length) &&
            data[0]?.customMediaStatus !== "rejected" ? (
              <div className="custom_item flex-center">
                {t("cmImagesAndVideos")}
              </div>
            ) : null}
            {authUser.user.userType !== "Lover" &&
            (data[0]?.customMediaStatus === "pending" || data[0]?.customMediaStatus === "accepted" || data[0]?.customMediaStatus === "uploaded" || data[0]?.customMediaStatus === "reset") ? (
              <div className="custom_item flex-center">
                {
                  !isUploading ? (
                    <span
                    {...getRootProps({
                      className: "dropzone__messagePicture",
                    })}
                    style={{ outline: "none" }}
                  >
                    {(data[0]?.customMediaStatus === "pending" || data[0]?.customMediaStatus === "accepted" || data[0]?.customMediaStatus === "uploaded" || data[0]?.customMediaStatus === "reset") ? (
                      <input {...getInputProps()} />
                    ) : null}
                    <IconButton
                      onClick={() => {
                        setActiveCustomMedia(message);
                      }}
                    >
                      <img
                        src={Picture}
                        alt="pict Icon"
                        width="260px"
                        height="260px"
                      />
                    </IconButton>
                  </span>
                  ) : (<CircularProgress color="secondary" width={60} />)
                }
              </div>
            ) : null}
            {images && images.length ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                {images.map((file, index) => {
                  return (
                    <div key={index}>
                      <p style={{ fontSize: "small" }}>ID : {file._id}</p>
                      <FetchFileWithAuthentication
                        alt="custom-media"
                        className=""
                        messageFsUrl={file?.content}
                        width="150px"
                        height="150px"
                        type={file?.customMediaType && file?.customMediaType}
                        status={file?.status}
                        showVideoModal={showVideosModal}
                        authUser={authUser}
                        images={images}
                        page="tracker"
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            {authUser.user.userType === "Lover" ? (
              <div className="custom_item flex-center">
                {data[0]?.customMediaStatus === "pending" ? (
                  <p>{data.from.username} {t("cmNotYetUploaded")}</p>
                ) : null}
                {data[0]?.customMediaStatus === "accepted" ? (
                  <p>{t("cmDownloading")}Downloading...</p>
                ) : null}
                {data[0]?.customMediaStatus === "rejected" ? (
                  <p>{t("cmTicklesRefunded")}</p>
                ) : null}
              </div>
            ) : null}
            {authUser.user.userType !== "Lover" ? (
              <div className="custom_item flex-center">
                <p>
                  {data[0]?.customMediaStatus === "uploaded" ? (
                    <img
                      src={TickGris}
                      alt="accepted icon"
                      width="20"
                      height="20"
                      style={{ verticalAlign: "bottom" }}
                    />
                  ) : null}
                  {data[0]?.customMediaStatus === "reset" ? (
                      <img
                          src={WarningIcon}
                          alt="accepted icon"
                          width="20"
                          height="20"
                          style={{ verticalAlign: "bottom" }}
                      />
                  ) : null}
                  &nbsp;
                  {moment(data[0].createdAt).format("DD.MM.yyyy")}
                  :&nbsp;
                  {data[0]?.customMediaStatus !== "rejected" ? (
                    <span>
                      <span>{data[0].tickles}</span>
                      &nbsp;
                      <img
                        src={TicklesImg}
                        alt="tickles icon"
                        width="20"
                        height="20"
                        style={{ verticalAlign: "bottom" }}
                      />
                      &nbsp;
                    </span>
                  ) : (
                    <del>
                      <span>{data[0].tickles}</span>
                      &nbsp;
                      <img
                        src={TicklesImgGris}
                        alt="tickles icon"
                        width="20"
                        height="20"
                        style={{ verticalAlign: "bottom" }}
                      />
                      &nbsp;
                    </del>
                  )}
                </p>
              </div>
            ) : null}
            {authUser.user.userType !== "Lover" &&
            data[0]?.customMediaStatus === "pending" ? (
              <div
                className="custom_item flex-center"
                style={{ textAlign: "center" }}
              >
                {handleCaption()}
              </div>
            ) : null}
            {authUser.user.userType === "Lover" &&
            (!images || images.length === 0) &&
            data[0]?.customMediaStatus !== "rejected" ? (
              <div className="custom_item flex-center">
                <p>{data[0].from.username} {t("cmNotYetUploaded")}</p>
              </div>
            ) : null}
            {authUser.user.userType !== "Lover" &&
            (!images || images.length === 0) &&
            (data[0]?.customMediaStatus === "pending" || data[0]?.customMediaStatus === "accepted") ? (
              <div className="custom_item flex-center">
                <p>{t("cmNotYetSubmitted")}</p>
              </div>
            ) : null}
            {authUser.user.userType !== "Lover" ? (
              <div className="custom_item flex-center">
                <p>ID: {data[0]._id}</p>
              </div>
            ) : null}
            {authUser.user.userType === "Lover" &&
            data[0]?.customMediaStatus !== "rejected" ? (
              <div className="custom_item flex-center">
                <p>ID: {data[0]._id}</p>
              </div>
            ) : null}
            {authUser.user.userType !== "Lover" &&
            (!images || images.length === 0) &&
            (data[0]?.customMediaStatus === "pending" || data[0]?.customMediaStatus === "accepted") ? (
              <div className="custom_item flex-center">
                <p>You have not yet sent the media</p>
              </div>
            ) : null}
            {authUser.user.userType !== "Lover" &&
            data[0]?.customMediaStatus === "rejected" ? (
              <div className="custom_item flex-center">
                <p>{t("cmCreditBack")}</p>
              </div>
            ) : null}
            {/*use to get fill gaps and have even cells*/}
            <div className="user_thumbnail_details space_filler"></div>
            <div className="user_thumbnail_details space_filler"></div>
            <div className="user_thumbnail_details space_filler"></div>
          </div>
        </div>
      ) : null}
      {/* Video Preview Modal  */}
      <VideoPreviewModal
        isVideoModalVisible={isVideoModalVisible}
        handleCancelVideo={handleCancelVideo}
        videoFileName={videoFileName}
        play={play}
      />
      {/* Video Preview Modal  */}
    </div>
  );
};

export default CustomMediaTrackerPage;
