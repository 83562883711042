import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    "& .MuiPaper-root": {
      borderRadius: "15px",
    },
  },

  dialogTitleRoot: {
    margin: 0,
    // padding: theme.spacing(2),
    // padding: "13px 13px 0 0 ",
    padding: "23px 74px 0 74px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",

    "& .MuiTypography-h6": {
      fontSize: "1.125rem",
      fontWeight: "700",
    },
  },
  dialogContentRoot: {
    padding: "27px 30px 30px 30px",

    "& .buy-tickles-check-list": {
      listStyle: "none",
      padding: 0,
      margin: 0,
    },

    "& .buy-tickles-check-list > li": {
      fontSize: "0.75rem",
      marginBottom: "16px",
      display: "flex",
      alignItems: "center",
      gap: "8.21px",
    },

    "&  .buy-tickles-check-list > .leave-tips-in-the-chat": {
      alignItems: "flex-start !important",
    },

    "& .buy-tickles-check-list > li > .check-icon": {
      fill: "#FF5768 !important",
    },

    "& .tickles-prices": {
      // width: "261px",
      // height: "152px",
      display: "flex",
      flexWrap: "wrap",
      marginTop: "22px",
      gap: "10px",
      justifyContent: "space-around",
    },

    "& .tickles-prices > .tickles-prices-item": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: "0 0 23.3333333333%",
      marginBottom: "15px",
      cursor: "pointer",
    },

    "& .tickles-prices > .tickles-prices-item div:first-child": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      outline: "0.01em solid #9f9f9f ",
      width: "100%",
      padding: "10px",
      borderRadius: "20px",
      justifyContent: "flex-end",
      gap: "5px",
    },

    "& .tickles-prices > .tickles-prices-item div:first-child span:nth-child(2)": {
      fontWeight: "700",
    },

    "& .tickles-prices > .tickles-prices-item > span": {
      fontSize: "0.75rem",
      marginTop: "0.313rem",
    },

    /* active selected tickle product */

    "& .selected-tickle-product > .tickles-prices-item-icon": {
      backgroundColor: "#f8cbd0",
      outlineColor: "#FF7E9D !important",
    },
  },
  dialogActionsRoot: {
    justifyContent: "center",
    padding: "18px 0",
    backgroundColor: "#FF7E9D",
    backgroundImage:
      "linear-gradient( to right, rgb(248, 46, 115), rgb(249, 80, 107) )",
    color: "white!important",

    "& .buy-tickles-confirm": {
      fontSize: "1.375rem",
      fontWeight: "700",
      color: "#ffffff",
      textTransform: "none",
      padding: 0,
      lineHeight: "25.78px",
    },
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
    padding: "13px 13px 0 0 ",
  },
}));
