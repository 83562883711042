import React, { useEffect, useState } from "react";
import { Badge, Button, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";

import "./feetLoverProfile.css";

import LogoText from "../../../assets/images/logo-text@2x.png";
import ticklesLog from "../../../assets/images/menu/tickles.svg";
import SettingIcon from "../../../assets/images/setting-icon.png";
import defaultAvatar from "../../../assets/images/generic-user-avatar.png";

import authUserAtom from "../../../stateManagement/atoms/auth/user";
import notificationFollowerBadgeAtom from "../../../stateManagement/atoms/notifications/followerBadge";
import fromFollowersLinkAtom from "../../../stateManagement/atoms/fromFollowersLinkAtom";
import feetLoverProfileQuery from "../../../stateManagement/selectors/feetLoverProfileSelector";

import PictyService from "../../../_services/picty.service";
import FeetLoverService from "../../../_services/feet-lovers.service";
import BuyTickle from "../../BuyTickleModal";
import { getAuthSession } from "../../../helpers/auth.helpers";
import searchFilterPictyAtom from "../../../stateManagement/atoms/searchFilterPictyAtom";
import TicklePricesService from "../../../_services/tickle-prices.service";

const StyledBadge = withStyles((theme) => ({
  badge: {
    left: 15,
    top: 42,
    padding: "0",
    borderRadius: "50%",
    width: "6px",
    lineHeight: "8px",
    height: "6px",
    minWidth: "6px !important",
  },
}))(Badge);

const FeetLoverProfile = () => {
  let history = useHistory();
  const { t } = useTranslation();

  const [isInvisible] = useRecoilState(notificationFollowerBadgeAtom);
  const [isLoading, setIsLoading] = useState(false);

  const [preferredPicties, setPreferredPicites] = useState([]);
  const [fromFollowersLink, setFromFollowersLink] = useRecoilState(
    fromFollowersLinkAtom
  );
  const [openBuyTickleModal, setOpenBuyTickleModal] = useState(false);
  const [feetLoverProfile, setFeetLoverProfile] = React.useState({});
  let [feetLover, setFeetLover] =useState({});
  const [epochProductLists, setEpochProductLists] = useState([]);
  const [, setSearchFilterPicty] = useRecoilState(searchFilterPictyAtom);

  // feet lover profile
  let [tmpFeetLover] = useState(getAuthSession());
  useEffect(() => {
    tmpFeetLover = {
      ...tmpFeetLover,
      profile: tmpFeetLover.user
    }
    setFeetLover(tmpFeetLover);
  }, [tmpFeetLover])

  useEffect(() => {
    FeetLoverService.getOwnFeetLoverProfile().then((response) => {
      setFeetLoverProfile(response);
    });
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (fromFollowersLink === true) {
      setFromFollowersLink(false);
    }
  }, [fromFollowersLink, setFromFollowersLink]);

  useEffect(() => {
    (async () => {
      if (feetLoverProfile?.profile?.preferredPicties?.length) {
        const tmpPreferredPicties = await Promise.all(
          feetLoverProfile?.profile?.preferredPicties?.map(async (picty) => {
            return await PictyService.getPicty(picty).then(
              (response) => response.data.picty
            );
          })
        );
        setPreferredPicites(tmpPreferredPicties);
        setIsLoading(false);
      }
    })();
  }, [feetLoverProfile]);

  useEffect(() => {
    (async () => {
      if (feetLoverProfile?.profile?.userType === "Lover" && openBuyTickleModal === true) {
        const response = await TicklePricesService.getEpochProductLists();
        setEpochProductLists(response.data);
      }
    })();
  }, [openBuyTickleModal, feetLoverProfile])

  const mapPictiesPanel = (text) => <p>{text}</p>;
  const mapSelectedPicties = (favorites) => {
    return favorites?.map((picty) => (
      <div 
        key={picty._id}
        onClick={() => {
          setSearchFilterPicty([picty]);
          history.push("/search-lover");
        }}
      >
        <img key={picty._id} src={picty.url} alt="" style={{ width: "42px" }} />
        <p>{picty.label}</p>
      </div>
    ));
  };

  return (
    <div className="feetLoverProfile__container">
      <div className="profile_header flex-center header page_fixed_header">
        <img className="profile__logo logo" src={LogoText} alt="" />
        <IconButton
          className="header__rightButtton settings-button"
          onClick={() => history.push("/settings-lovers")}
        >
          <img
            src={SettingIcon}
            alt="menu"
            className="ViewOwnProfileModels__img"
          />
        </IconButton>
      </div>
      <div className="feetLoverProfile__content content__withHeader">
        <div className="feetLoverProfileContent__top">
          <img
            src={
              feetLover && feetLover.profile && feetLover.profile.image
                ? feetLover.profile.image.url
                : defaultAvatar
            }
            alt=""
          />

          <h2>{feetLoverProfile?.profile?.username}</h2>
          <h3>
            {feetLoverProfile &&
              feetLoverProfile.profile &&
              feetLoverProfile.profile.title}
          </h3>
          <p>
            {feetLoverProfile &&
              feetLoverProfile.profile &&
              feetLoverProfile.profile.description}
          </p>
        </div>
        <div className="feetLoverProfileContent__bloc">
          <div className="feetLoversProfile__button-content">
            <Button
              // onClick={() => history.push("/buy-tickles-credit")}
              onClick={() => setOpenBuyTickleModal(true)}
              className="buyTicklesButton pink__button"
              variant="contained"
            >
              <img
                className="feetLoverProfile__ticklesIcon"
                src={ticklesLog}
                alt=""
              />{" "}
              {t("feetLoverProfileBuyTickles")}
            </Button>
          </div>
          <div className="profile_counts">
            <div>
              <h4>
                {(feetLoverProfile &&
                  feetLoverProfile.profile &&
                  feetLoverProfile.profile.tickles) ||
                  0}
              </h4>
              <p>{t("feetLoverProfileTickleBalance")}</p>
            </div>
            <div
              onClick={() => {
                history.push(`/follow-page-lover/${feetLover.profile._id}`);
              }}
              style={{
                cursor: "pointer"
              }}
            >
              <h4>
                {feetLoverProfile &&
                  feetLoverProfile.profile &&
                  feetLoverProfile.profile?.activeFollowings}
              </h4>
              <p className="contentBlocText__follower">
                {t("followingFeetModelView")}
              </p>
            </div>
            <div
              onClick={() => {
                setFromFollowersLink(true);
                history.push(`/follow-page-lover/${feetLover.profile._id}`);
              }}
              style={{
                cursor: "pointer"
              }}
            >
              <h4>
                {feetLoverProfile &&
                  feetLoverProfile.profile &&
                  feetLoverProfile.profile?.activeFollowers}
              </h4>
              <StyledBadge
                color="secondary"
                variant="dot"
                invisible={isInvisible}
                overlap={"rectangular"}
              >
                <p className="followersText">{t("followersFeetModelView")}</p>
              </StyledBadge>
            </div>
          </div>
        </div>

        <div className="feetLoverProfileContent__picty">
          <h2 className="feetLoverContentPictyTitle">
            {t("feetLoverProfileFavPicty")}
          </h2>
          <div className="picty__container">
            {isLoading
              ? mapPictiesPanel("Fetching picties")
              : preferredPicties?.length === 0
              ? mapPictiesPanel("No favorite picty selected yet.")
              : mapSelectedPicties(preferredPicties)}
          </div>
        </div>
      </div>

      <BuyTickle
        openBuyTickleModal={openBuyTickleModal}
        setOpenBuyTickleModal={setOpenBuyTickleModal}
        epochPrices={epochProductLists}
      />
    </div>
  );
};

export default FeetLoverProfile;
