import React, { useEffect, useRef, useState } from "react";
import { Avatar, Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { find as _find, remove as _remove } from "lodash";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import "./pictyFilters.css";
import selectedPictiesAtom from "../../stateManagement/atoms/selectedPictiesAtom";
import useSelectAndRemove from "../modelsComponents/postPublications/hooks/useSelectAndRemove";
import useFavoritePicty from "../favoritePicty/hooks/useFavoritePicty";
import searchFilterPictyAtom from "../../stateManagement/atoms/searchFilterPictyAtom";
import getPictiesQuery from "../../stateManagement/selectors/getPictiesSelector";
import postPublicationSelectedPictiesAtom from "../../stateManagement/atoms/postPublicationPictiesAtom";
import CloseIcon from "../../assets/images/pict/close.svg";

const PictyFilters = ({ isDisabled, userType }) => {
  const { i18n, t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  let history = useHistory();
  const location = useLocation();

  const {
    HandleCountEgyptian,
    HandleCountGreek,
    HandleCountRoman,
    HandleCountVoyage,
    HandleCountMode,
    HandleCountSport,
    HandleCountWater,
    HandleCountCocooning,
    HandleCountArtiste,
    HandleCountAiguille,
    HandleCountBijoux,
    HandleCountChaussette,
    HandleCountBas,
    HandleCountBasket,
    HandleCountTatouage,
    HandleCountVernis,
    HandleCountMirror,
    HandleCountPedales,
    HandleCountJouer,
    HandleCountVue,
    HandleCountJambe,
    HandleCountPiedMain,
    HandleCountChatouilles,
    HandleCountCopines,
    HandleCountPublic,
    HandleCountArche,
    HandleCountOrteils,
    HandleCountTalons,
    HandleCountSelfeet,
    HandleCountPlisses,
    HandleCountPlantePied,
    HandleCountOrteilsEcartes,
  } = useFavoritePicty();

  const [selectedPictiesList, setSelectedPictiesList] =
    useRecoilState(selectedPictiesAtom);
  const [pictyForSelection, setPictyForSelection] = useState("");
  const { limitCompleted, setLimitCompleted } = useSelectAndRemove();
  const [clickedAccordionInscriptions, setClickedAccordionInscriptions] =
    useState(true);
  const [clickedAccordion1, setClickedAccordion1] = useState(true);
  const [clickedAccordion2, setClickedAccordion2] = useState(true);
  const [clickedAccordion3, setClickedAccordion3] = useState(true);
  const [clickedAccordionDaring, setClickedAccordionDaring] = useState(true);
  const [clickedAccordion4, setClickedAccordion4] = useState(true);
  const [clickedAccordion5, setClickedAccordion5] = useState(true);
  const buttonRef = useRef();

  // remote data
  const { picties } = useRecoilValue(getPictiesQuery);

  // global state
  const [searchFilterPicties, setSearchFilterPicty] = useRecoilState(
    searchFilterPictyAtom
  );
  const [
    selectedPictiesForPostPublication,
    setSelectedPictiesForPostPublication,
  ] = useRecoilState(postPublicationSelectedPictiesAtom);

  // local state for search filter picty
  const [filterPicty, setFilterPicty] = useState({});

  useEffect(() => {
    if (filterPicty && filterPicty._id) {
      setSearchFilterPicty((searchFilterPicty) =>
        searchFilterPicty.concat(filterPicty)
      );
    }
  }, [filterPicty, setSearchFilterPicty]);

  useEffect(() => {
    if (pictyForSelection !== "") {
      setSelectedPictiesList((selectedPictiesList) =>
        selectedPictiesList.concat(pictyForSelection)
      );

      buttonRef.current.disabled = true;
    }
  }, [
    pictyForSelection,
    userType,
    setSelectedPictiesList,
    setSearchFilterPicty,
  ]);

  const filterOpt = {
    order: 1,
    title: t("pictyFilterInscription"),
    containerClass: `searchFilter__daily-body ${
        clickedAccordionInscriptions && "hidden-daily-body"
    }`,
    action: () => handleClickAccordionInscriptions(),
    actionClass: "inscriptionsAccordion",
    icon: clickedAccordionInscriptions ? (
        <KeyboardArrowDownIcon />
    ) : (
        <KeyboardArrowUpIcon />
    ),
    pathname: "filter",
    options: [
      {
        pathname: "/filter",
        selection: "Egyptian",
        selectedForFilter: "egyptian",
        imgClassName:
            _find(searchFilterPicties, { code: "egyptian" }) && "selectedPicty",
        imgSrc: picties?.length && _find(picties, { code: "egyptian" })?.url,
        paragraph: t("egyptianParticularityFeetDetails"),
        pClass: "searchFilter__p searchFilterTextPict__p",
        disabled: limitCompleted && selectedPictiesList.includes("Egyptian"),
        handledAction: () => HandleCountEgyptian(),
      },
      {
        pathname: "/filter",
        selection: "Greek",
        selectedForFilter: "greek",
        imgClassName:
            _find(searchFilterPicties, { code: "greek" }) && "selectedPicty",
        imgSrc: picties?.length && _find(picties, { code: "greek" })?.url,
        paragraph: t("greekParticularityFeetDetails"),
        pClass: "searchFilter__p searchFilterTextPict__p",
        disabled: limitCompleted && selectedPictiesList.includes("Greek"),
        handledAction: () => HandleCountGreek(),
      },
      {
        pathname: "/filter",
        selection: "Roman",
        selectedForFilter: "roman",
        imgClassName:
            _find(searchFilterPicties, { code: "roman" }) && "selectedPicty",
        imgSrc: picties?.length && _find(picties, { code: "roman" })?.url,
        paragraph: t("romanParticularityFeetDetails"),
        pClass: "searchFilter__p searchFilterTextPict__p",
        disabled: limitCompleted && selectedPictiesList.includes("Roman"),
        handledAction: () => HandleCountRoman(),
      },
    ],
  };

  const dropdownCompData = [
    {
      order: 2,
      title: t("pictyFilterDaily"),
      containerClass: `searchFilter__daily-body ${
        clickedAccordion1 && "hidden-daily-body"
      }`,
      action: () => handleClickAccordion1(),
      actionClass: "accordion1",
      icon: clickedAccordion1 ? (
        <KeyboardArrowDownIcon />
      ) : (
        <KeyboardArrowUpIcon />
      ),
      pathname: "filter",
      options: [
        {
          pathname: "/filter",
          selection: "voyage",
          selectedForFilter: "envoyage",
          imgClassName:
            _find(searchFilterPicties, { code: "envoyage" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "envoyage" })?.url,
          paragraph: t("pictyFilterPictyTravelling"),
          pClass: `searchFilter__p ${
            i18n.language === "en"
              ? "searchFilterDaily__p"
              : "searchFilterDailyFr__p"
          }`,
          disabled: limitCompleted || selectedPictiesList.includes("voyage"),
          handledAction: () => HandleCountVoyage(),
        },
        {
          pathname: "/filter",
          selection: "mode",
          selectedForFilter: "mode",
          imgClassName:
            _find(searchFilterPicties, { code: "mode" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "mode" })?.url,
          paragraph: t("pictyFilterPictyFashion"),
          pClass: `searchFilter__p ${
            i18n.language === "en"
              ? "searchFilterDaily__p"
              : "searchFilterDailyFr__p"
          }`,
          disabled: limitCompleted || selectedPictiesList.includes("mode"),
          handledAction: () => HandleCountMode(),
        },
        {
          pathname: "/filter",
          selection: "sport",
          selectedForFilter: "sport",
          imgClassName:
            _find(searchFilterPicties, { code: "sport" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "sport" })?.url,
          paragraph: t("Sport"),
          pClass: `searchFilter__p ${
            i18n.language === "en"
              ? "searchFilterDaily__p"
              : "searchFilterDailyFr__p"
          }`,
          disabled: limitCompleted || selectedPictiesList.includes("sport"),
          handledAction: () => HandleCountSport(),
        },
        {
          pathname: "/filter",
          selection: "water",
          selectedForFilter: "eau",
          imgClassName:
            _find(searchFilterPicties, { code: "eau" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "eau" })?.url,
          paragraph: t("pictyFilterPictyWater"),
          pClass: `searchFilter__p ${
            i18n.language === "en"
              ? "searchFilterDaily__p"
              : "searchFilterDailyFr__p"
          }`,
          disabled: limitCompleted || selectedPictiesList.includes("water"),
          handledAction: () => HandleCountWater(),
        },
        {
          pathname: "/filter",
          selection: "cooconing",
          selectedForFilter: "cooconing",
          imgClassName:
            _find(searchFilterPicties, { code: "cooconing" }) &&
            "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "cooconing" })?.url,
          paragraph: t("cooconing"),
          pClass: `searchFilter__p ${
            i18n.language === "en"
              ? "searchFilterDaily__p"
              : "searchFilterDailyFr__p"
          }`,
          disabled: limitCompleted || selectedPictiesList.includes("cooconing"),
          handledAction: () => HandleCountCocooning(),
        },
        {
          pathname: "/filter",
          selection: "artiste",
          selectedForFilter: "solartisque",
          imgClassName:
            _find(searchFilterPicties, { code: "solartisque" }) &&
            "selectedPicty",
          imgSrc:
            picties?.length && _find(picties, { code: "solartisque" })?.url,
          paragraph: t("pictyFilterPictyArtisticFloor"),
          pClass: `searchFilter__p ${
            i18n.language === "en"
              ? "searchFilterDaily__p"
              : "searchFilterDailyFr__p"
          }`,
          disabled: limitCompleted || selectedPictiesList.includes("artiste"),
          handledAction: () => HandleCountArtiste(),
        },
      ],
    },
    {
      order: 3,
      title: t("pictyFilterSophisticated"),
      containerClass: `searchFilter__daily-body ${
        clickedAccordion2 && "hidden-daily-body"
      }`,
      action: () => handleClickAccordion2(),
      actionClass: "accordion1",
      icon: clickedAccordion2 ? (
        <KeyboardArrowDownIcon />
      ) : (
        <KeyboardArrowUpIcon />
      ),
      pathname: "filter",
      options: [
        {
          pathname: "/filter",
          selection: "Aiguille",
          selectedForFilter: "talonsaiguille",
          imgClassName:
            _find(searchFilterPicties, { code: "talonsaiguille" }) &&
            "selectedPicty",
          imgSrc:
            picties?.length && _find(picties, { code: "talonsaiguille" })?.url,
          paragraph: t("pictyFilterPictyHighHells"),
          pClass: `searchFilter__p ${
            i18n.language === "en"
              ? "searchFilterSophisticated__p"
              : "searchFilterSophisticated__p"
          }`,
          disabled: limitCompleted || selectedPictiesList.includes("Aiguille"),
          handledAction: () => HandleCountAiguille(),
        },
        {
          pathname: "/filter",
          selection: "Bijoux",
          selectedForFilter: "bijoux",
          imgClassName:
            _find(searchFilterPicties, { code: "bijoux" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "bijoux" })?.url,
          paragraph: t("pictyFilterPictyJewelry"),
          pClass: `searchFilter__p ${
            i18n.language === "en"
              ? "searchFilterSophisticated__p"
              : "searchFilterSophisticatedFr__p"
          }`,
          disabled: limitCompleted || selectedPictiesList.includes("Bijoux"),
          handledAction: () => HandleCountBijoux(),
        },
        {
          pathname: "/filter",
          selection: "chaussette",
          selectedForFilter: "chaussettes",
          imgClassName:
            _find(searchFilterPicties, { code: "chaussettes" }) &&
            "selectedPicty",
          imgSrc:
            picties?.length && _find(picties, { code: "chaussettes" })?.url,
          paragraph: t("pictyFilterPictySocks"),
          pClass: `searchFilter__p ${
            i18n.language === "en"
              ? "searchFilterSophisticated__p"
              : "searchFilterSophisticatedFr__p"
          }`,
          disabled:
            limitCompleted || selectedPictiesList.includes("chaussette"),
          handledAction: () => HandleCountChaussette(),
        },
        {
          pathname: "/filter",
          selection: "Bas",
          selectedForFilter: "collant&bas",
          imgClassName:
            _find(searchFilterPicties, { code: "collant&bas" }) &&
            "selectedPicty",
          imgSrc:
            picties?.length && _find(picties, { code: "collant&bas" })?.url,
          paragraph: t("pictyFilterPictyPantyhose"),
          pClass: `searchFilter__p ${
            i18n.language === "en"
              ? "searchFilterSophisticated__p"
              : "searchFilterSophisticatedFr__p"
          }`,
          disabled: limitCompleted || selectedPictiesList.includes("Bas"),
          handledAction: () => HandleCountBas(),
        },
        {
          pathname: "/filter",
          selection: "Basket",
          selectedForFilter: "basket",
          imgClassName:
            _find(searchFilterPicties, { code: "basket" }) &&
            "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "basket" })?.url,
          paragraph: t("pictyFilterPictySneakers"),
          pClass: `searchFilter__p ${
            i18n.language === "en"
              ? "searchFilterSophisticated__p"
              : "searchFilterSophisticatedFr__p"
          }`,
          disabled: limitCompleted || selectedPictiesList.includes("Basket"),
          handledAction: () => HandleCountBasket(),
        },
        {
          pathname: "/filter",
          selection: "Tatouage",
          selectedForFilter: "tatouage",
          imgClassName:
            _find(searchFilterPicties, { code: "tatouage" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "tatouage" })?.url,
          paragraph: t("pictyFilterPictyTattoos"),
          pClass: `searchFilter__p ${
            i18n.language === "en"
              ? "searchFilterSophisticated__p"
              : "searchFilterSophisticatedFr__p"
          }`,
          disabled:
            limitCompleted || selectedPictiesList.includes("BaskTatouageet"),
          handledAction: () => HandleCountTatouage(),
        },
      ],
    },
    {
      order: 4,
      title: t("pictyFilterStars"),
      containerClass: `searchFilter__daily-body ${
        clickedAccordion3 && "hidden-daily-body"
      }`,
      action: () => handleClickAccordion3(),
      actionClass: "accordion1",
      icon: clickedAccordion3 ? (
        <KeyboardArrowDownIcon />
      ) : (
        <KeyboardArrowUpIcon />
      ),
      pathname: "filter",
      options: [
        {
          pathname: "/filter",
          selection: "Vernis",
          selectedForFilter: "vernis",
          imgClassName:
            _find(searchFilterPicties, { code: "vernis" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "vernis" })?.url,
          paragraph: t("pictyFilterPictyPolish"),
          pClass: "searchFilter__p searchFilterStars__p",
          disabled: limitCompleted || selectedPictiesList.includes("Vernis"),
          handleAction: () => HandleCountVernis(),
        },
        {
          pathname: "/filter",
          selection: "Mirror",
          selectedForFilter: "miroir",
          imgClassName:
            _find(searchFilterPicties, { code: "miroir" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "miroir" })?.url,
          paragraph: t("pictyFilterPictyMirror"),
          pClass: "searchFilter__p searchFilterStars__p",
          disabled: limitCompleted || selectedPictiesList.includes("Mirror"),
          handleAction: () => HandleCountMirror(),
        },
        {
          pathname: "/filter",
          selection: "Pedales",
          selectedForFilter: "pedales",
          imgClassName:
            _find(searchFilterPicties, { code: "pedales" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "pedales" })?.url,
          paragraph: t("pictyFilterPictyPedals"),
          pClass: "searchFilter__p searchFilterStars__p",
          disabled: limitCompleted || selectedPictiesList.includes("Pedales"),
          handleAction: () => HandleCountPedales(),
        },
        {
          pathname: "/filter",
          selection: "Jouer",
          selectedForFilter: "jouer",
          imgClassName:
            _find(searchFilterPicties, { code: "jouer" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "jouer" })?.url,
          paragraph: t("pictyFilterPictyPlaying"),
          pClass: "searchFilter__p searchFilterStars__p",
          disabled: limitCompleted || selectedPictiesList.includes("Jouer"),
          handleAction: () => HandleCountJouer(),
        },
        {
          pathname: "/filter",
          selection: "Vue",
          selectedForFilter: "vue",
          imgClassName:
            _find(searchFilterPicties, { code: "vue" }) &&
            "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "vue" })?.url,
          paragraph: t("pictyFilterPictyView"),
          pClass: "searchFilter__p searchFilterStarsSub__p",
          disabled: limitCompleted || selectedPictiesList.includes("Vue"),
          handleAction: () => HandleCountVue(),
        },
        {
          pathname: "/filter",
          selection: "Jambe",
          selectedForFilter: "jambe",
          imgClassName:
            _find(searchFilterPicties, { code: "jambe" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "jambe" })?.url,
          paragraph: t("pictyFilterPictyLegs"),
          pClass: "searchFilter__p searchFilterStarsSub__p",
          disabled: limitCompleted || selectedPictiesList.includes("Jambe"),
          handleAction: () => HandleCountJambe(),
        },
        {
          pathname: "/filter",
          selection: "PiedMain",
          selectedForFilter: "piedmain",
          imgClassName:
            _find(searchFilterPicties, { code: "piedmain" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "piedmain" })?.url,
          paragraph: t("pictyFilterPictyFeetHand"),
          pClass: "searchFilter__p searchFilterStarsSub__p",
          disabled: limitCompleted || selectedPictiesList.includes("PiedMain"),
          handleAction: () => HandleCountPiedMain(),
        },
      ],
    },
    {
      order: 5,
      title: t("pictyFilterDaring"),
      containerClass: `searchFilter__daily-body ${
        clickedAccordionDaring && "hidden-daily-body"
      }`,
      action: () => handleClickAccordionDaring(),
      actionClass: "accordion1",
      icon: clickedAccordionDaring ? (
        <KeyboardArrowDownIcon />
      ) : (
        <KeyboardArrowUpIcon />
      ),
      pathname: "filter",
      options: [
        {
          pathname: "/filter",
          selection: "Chatouilles",
          selectedForFilter: "chatouilles",
          imgClassName:
            _find(searchFilterPicties, { code: "chatouilles" }) &&
            "selectedPicty",
          imgSrc:
            picties?.length && _find(picties, { code: "chatouilles" })?.url,
          paragraph: t("pictyFilterPictyTickling"),
          pClass: "searchFilter__p searchFilterStars__p",
          disabled:
            limitCompleted || selectedPictiesList.includes("Chatouilles"),
          handleAction: () => HandleCountChatouilles(),
        },
        {
          pathname: "/filter",
          selection: "Copines",
          selectedForFilter: "copines",
          imgClassName:
            _find(searchFilterPicties, { code: "copines" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "copines" })?.url,
          paragraph: t("pictyFilterPictyGirlfriends"),
          pClass: "searchFilter__p searchFilterStars__p",
          disabled: limitCompleted || selectedPictiesList.includes("Copines"),
          handleAction: () => HandleCountCopines(),
        },
        {
          pathname: "/filter",
          selection: "Public",
          selectedForFilter: "public",
          imgClassName:
            _find(searchFilterPicties, { code: "public" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "public" })?.url,
          paragraph: t("pictyFilterPictyPublic"),
          pClass: "searchFilter__p searchFilterStars__p",
          disabled: limitCompleted || selectedPictiesList.includes("Public"),
          handleAction: () => HandleCountPublic(),
        },
      ],
    },
    {
      order: 6,
      title: t("pictyFilterBarfoot"),
      containerClass: `searchFilter__daily-body ${
        clickedAccordion4 && "hidden-daily-body"
      }`,
      action: () => handleClickAccordion4(),
      actionClass: "accordion1",
      icon: clickedAccordion4 ? (
        <KeyboardArrowDownIcon />
      ) : (
        <KeyboardArrowUpIcon />
      ),
      pathname: "filter",
      options: [
        {
          pathname: "/filter",
          selection: "Arche",
          selectedForFilter: "arche",
          imgClassName:
            _find(searchFilterPicties, { code: "arche" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "arche" })?.url,
          paragraph: t("pictyFilterPictyArk"),
          pClass: "searchFilter__p searchFilterBarefoot__p",
          disabled: limitCompleted || selectedPictiesList.includes("Arche"),
          handleAction: () => HandleCountArche(),
        },
        {
          pathname: "/filter",
          selection: "Orteils",
          selectedForFilter: "orteils",
          imgClassName:
            _find(searchFilterPicties, { code: "orteils" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "orteils" })?.url,
          paragraph: t("pictyFilterPictyToes"),
          pClass: `searchFilter__p searchFilterBarefoot${
            i18n.language === "en" ? "__p" : "Fr__p"
          }`,
          disabled: limitCompleted || selectedPictiesList.includes("Orteils"),
          handleAction: () => HandleCountOrteils(),
        },
        {
          pathname: "/filter",
          selection: "Talons",
          selectedForFilter: "talons",
          imgClassName:
            _find(searchFilterPicties, { code: "talons" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "talons" })?.url,
          paragraph: t("pictyFilterPictyHeels"),
          pClass: "searchFilter__p searchFilterBarefoot__p",
          disabled: limitCompleted || selectedPictiesList.includes("Talons"),
          handleAction: () => HandleCountTalons(),
        },
        {
          pathname: "/filter",
          selection: "Selfeet",
          selectedForFilter: "selfeet",
          imgClassName:
            _find(searchFilterPicties, { code: "selfeet" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "selfeet" })?.url,
          paragraph: t("Selfeet"),
          pClass: "searchFilter__p searchFilterBarefoot__p",
          disabled: limitCompleted || selectedPictiesList.includes("Selfeet"),
          handleAction: () => HandleCountSelfeet(),
        },
        {
          pathname: "/filter",
          selection: "Plisses",
          selectedForFilter: "plisses",
          imgClassName:
            _find(searchFilterPicties, { code: "plisses" }) && "selectedPicty",
          imgSrc: picties?.length && _find(picties, { code: "plisses" })?.url,
          paragraph: t("pictyFilterPictyPleated"),
          pClass: "searchFilter__p searchFilterBarefoot__p",
          disabled: limitCompleted || selectedPictiesList.includes("Plisses"),
          handleAction: () => HandleCountPlisses(),
        },
        {
          pathname: "/filter",
          selection: "PlantePied",
          selectedForFilter: "plantepied",
          imgClassName:
            _find(searchFilterPicties, { code: "plantepied" }) &&
            "selectedPicty",
          imgSrc:
            picties?.length && _find(picties, { code: "plantepied" })?.url,
          paragraph: t("pictyFilterPictySoles"),
          pClass: "searchFilter__p searchFilterBarefootSub__p",
          disabled:
            limitCompleted || selectedPictiesList.includes("PlantePied"),
          handleAction: () => HandleCountPlantePied(),
        },
        {
          pathname: "/filter",
          selection: "OrteilsEcartes",
          selectedForFilter: "orteilsecartes",
          imgClassName:
            _find(searchFilterPicties, { code: "orteilsecartes" }) &&
            "selectedPicty",
          imgSrc:
            picties?.length && _find(picties, { code: "orteilsecartes" })?.url,
          paragraph: t("pictyFilterPictySpreadToes"),
          pClass: `searchFilter__p searchFilterBarefootSub ${
            i18n.language === "en" ? "__p" : "Fr__p"
          }`,
          disabled:
            limitCompleted || selectedPictiesList.includes("OrteilsEcartes"),
          handleAction: () => HandleCountOrteilsEcartes(),
        },
      ],
    },
  ];

  const completedLimitList = () => {
    if (location.pathname === "/filter") {
      if (selectedPictiesList.length === 1) {
        setLimitCompleted(true);
      }
      if (selectedPictiesList.length === 0) {
        setLimitCompleted(false);
      }
    } else {
      if (selectedPictiesList.length === 5) {
        setLimitCompleted(true);
      }
      if (selectedPictiesList.length === 0) {
        setLimitCompleted(false);
      }
    }
  };
  const handleClickAccordionInscriptions = () => {
    if (clickedAccordionInscriptions === false) {
      setClickedAccordionInscriptions(true);
    }
    if (clickedAccordionInscriptions === true) {
      setClickedAccordionInscriptions(false);
    }
  };
  const handleClickAccordionDaring = () => {
    if (clickedAccordionDaring === false) {
      setClickedAccordionDaring(true);
    }
    if (clickedAccordionDaring === true) {
      setClickedAccordionDaring(false);
    }
  };

  const handleClickAccordion1 = () => {
    setClickedAccordion1((clickedAccordion1) => !clickedAccordion1);
  };
  const handleClickAccordion2 = () => {
    setClickedAccordion2((clickedAccordion2) => !clickedAccordion2);
  };
  const handleClickAccordion3 = () => {
    setClickedAccordion3((clickedAccordion3) => !clickedAccordion3);
  };
  const handleClickAccordion4 = () => {
    setClickedAccordion4((clickedAccordion4) => !clickedAccordion4);
  };
  const handleClickAccordion5 = () => {
    setClickedAccordion5((clickedAccordion5) => !clickedAccordion5);
  };

  /**
   * handle adding or removing selected picty for search filter or post publication in global state.
   * @param {*} code  = picty code
   * @returns: None
   */
  const handleSelectedPictyForFilter = (code) => {
    // selected picty for post publication
    if (location.pathname === "/post-publication") {
      const existingPublicationPicty = _find(
        selectedPictiesForPostPublication,
        { code }
      );

      if (existingPublicationPicty) {
        setSelectedPictiesForPostPublication(() =>
          _remove(
            [...selectedPictiesForPostPublication],
            (picty) => picty.code !== code
          )
        );
      } else {
        setSelectedPictiesForPostPublication((publicationPicties) =>
          publicationPicties.concat(_find(picties, { code }))
        );
      }
    } else {
      // selected picty for advance search
      const existingSearchFilterPicty = _find(searchFilterPicties, { code });

      if (searchFilterPicties.length === 1) {
        if (existingSearchFilterPicty) {
          setSearchFilterPicty(() =>
            _remove([...searchFilterPicties], (picty) => picty.code !== code)
          );
          setFilterPicty(() =>
            _remove([...picties], (picty) => picty.code !== code)
          );
        } else {
          setSearchFilterPicty(() =>
            _remove([...searchFilterPicties], (picty) => picty.code === code)
          );
          setFilterPicty(() =>
            _remove([...picties], (picty) => picty.code === code)
          );
        }
      }
      if (!existingSearchFilterPicty) setFilterPicty(_find(picties, { code }));
    }
  };

  const handleRemoveSelectedSearchFilterPicty = (searchPicty) => {
    setSearchFilterPicty(() =>
      _remove(
        [...searchFilterPicties],
        (picty) => picty.code !== searchPicty.code
      )
    );
    setFilterPicty(() =>
      _remove([...picties], (picty) => picty.code !== searchPicty.code)
    );
  };

  const isDisable = (length) => false;

  const dropdownOptions = (data) => {
    const btnComp = data.map((item, key) => {
      return (
        <div key={key}>
          <Button
            {...(location.pathname === item.pathname
              ? {
                  disabled: isDisable(searchFilterPicties.length),
                }
              : {
                  disabled: item.disabled,
                })}
            ref={buttonRef}
            onClick={() => {
              if (isDisabled === true) {
                setPictyForSelection("");
                return false;
              }

              setPictyForSelection(item.selection);
              {
                item.handledAction;
              }
              handleSelectedPictyForFilter(item.selectedForFilter);
            }}
            className="picty-icons"
            style={{ borderRadius: "25px" }}
          >
            <img
              className={location.pathname === "/post-publication" ? (selectedPictiesList.includes(item.selection)
                  ? "selectedPicty"
                  : "") : item.imgClassName}
              src={item.imgSrc}
              alt={item.selection}
              style={{ width: "42px" }}
            />
          </Button>
          <p className={item.pClass}>{item.paragraph}</p>
        </div>
      );
    });
    return <div className="filter_options picty__container">{btnComp}</div>;
  };

  const mapDropdown = (data) => {
    if (location.pathname !== "/post-publication") {
      data.push(filterOpt)
    }
    return data
        .sort((a, b) =>{ return a.order - b.order })
        .map((item, key) => {
      return (
        <div className="searchFilter__daily" key={key}>
          <div className={item.containerClass}>
            <span onClick={item.action} className="searchFilterDailyBody__h2 searchFilter__h2">
              <h2>{item.title}</h2>
              <Button className={item.actionClass}>
                {item.icon}
              </Button>
            </span>
            <hr className="hr-sep-filters" />
            {dropdownOptions(item.options)}
          </div>
          <div className="searchFilter__daily-footer"></div>
        </div>
      );
    });
  };

  return (
    <>
    {/* selected picty for search filter in /filter page */}
    {location.pathname === "/filter" && (
      <div className="postPublicationcontent__selectedPicty">
        <div className="postPublicationcontent__selectedPictyText">
          <b>{t("Selected Picties")}</b>
          <p>{`( ${searchFilterPicties.length}/1 )`}</p>
        </div>
        <div
          className="postPublicationSelectedPicty__list picty__container"
          style={{ justifyContent: "start", minHeight: "75px" }}
        >
          {searchFilterPicties?.map((searchPicty) => (
            <div key={searchPicty._id}>
              <>
                <Avatar
                  src={searchPicty.url}
                  className="postPublication__selectedAvatar"
                  style={{
                    margin: "auto",
                  }}
                />
                <span
                  className="postPublicationPictylist__button"
                >
                  <img
                    onClick={() =>
                      handleRemoveSelectedSearchFilterPicty(searchPicty)
                    }
                    className="postPublicationPictyList__deleteButton"
                    src={CloseIcon}
                  />
                </span>
              </>
              <p>{t(searchPicty.label)}</p>
            </div>
          )) || null}
        </div>
      </div>
    )}

    <div onClick={() => completedLimitList()} className="searchFilter__body">
      {mapDropdown(dropdownCompData)}
      <div className="searchFilter__daily">
        <div className="searchFilter__daily-footer"></div>
        <br />
        <br />
        <br />
      </div>
    </div>
    </>
  );
};

export default PictyFilters;
