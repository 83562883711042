/* eslint-disable no-unused-vars */
import { Divider, IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import "./settingFeetLovers.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { onSettingModelsAtom } from "../../../stateManagement/atoms/studioAtom";
import isPushNotificationAtom from "../../../stateManagement/atoms/isPushNotificationAtom";
import isReceivedMediasAtom from "../../../stateManagement/atoms/isReceivedMediasAtom";
import { isAndroid, isIOS } from "react-device-detect";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSessionStorage from "../../forms/services/storageHooks/useSessionStorage";
import receiveMediasMessageToUserAtom from "../../../stateManagement/atoms/receiveMediasMessageToUserAtom";
import LogoutModal from "../../modelsComponents/settingsFeetModels/logoutModal/LogoutModal";
import UserService from "../../../_services/user.service";

const SettingFeetLovers = (props) => {
  const { i18n, t } = useTranslation();
  let history = useHistory();
  const [onSettingModels, setOnSettingModels] = useRecoilState(
    onSettingModelsAtom
  );
  const [isPushNotification, setIsPushNotification] = useRecoilState(
    isPushNotificationAtom
  );
  const [isConnectionMobile, setIsConnectionMobile] = useState(true);
  const [toggleSwitchConnection, setToggleSwitchConnection] = useState(false);
  const [isReceivedMedias, setIsReceivedMedias] = useRecoilState(
    isReceivedMediasAtom
  );
  const [authUser, setAuthUser] = useSessionStorage({}, "infosUser");
  const [isReceiveMediaToUser, setIsReceiveMediaToUser] = useRecoilState(
    receiveMediasMessageToUserAtom
  );

  useEffect(() => {
    if (onSettingModels === true) {
      setOnSettingModels(false);
    }
  }, [onSettingModels, setOnSettingModels]);

  // CODE FOR CONNECTION DETECTION
  // useEffect(() => {
  //   const connection =
  //     navigator.connection ||
  //     navigator.mozConnection ||
  //     navigator.webkitConnection;

  //   function updateConnectionStatus() {
  //     setIsConnectionMobile(connection.effectiveType);
  //   }

  //   connection.addEventListener("change", updateConnectionStatus);
  //   updateConnectionStatus();
  //   sessionStorage.setItem("connection", isConnectionMobile);
  // }, [isConnectionMobile]);

  // const handleSetConnetionSwitch = () => {
  //   if (isConnectionMobile === "3g" || "4g" || "5g") {
  //     setToggleSwitchConnection(true);
  //   } else if (isConnectionMobile !== "3g" || "4g" || "5g") {
  //     setToggleSwitchConnection(false);
  //   }
  // };

  // PUSH AND TOAST NOTIFICATION FOR ANDROID & IPHONE MOBILE
  const handleToggleConnectionSwitch = () => {
    if (isAndroid && !isPushNotification) {
      toast(
        `your are in ${isConnectionMobile.toUpperCase()} connection in your Android phone. You are using mobile network`,
        {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
    if (isAndroid && isPushNotification) {
      alert(
        `your are in ${isConnectionMobile.toUpperCase()} connection to your Android phone. You are using mobile network`
      );
      // alert("This is an Android")
      Notification.requestPermission((result) => {
        if (result === "granted") {
          showNotification(
            `your are in ${isConnectionMobile.toUpperCase()} connection. You are using mobile network`
          );
        }
      });

      function showNotification(title, message) {
        if ("Notification" in window) {
          navigator.serviceWorker.ready.then((registration) => {
            registration.showNotification(title, {
              body: message,
              tag: "vibration-sample",
            });
          });
        }
      }
    }
    if (isIOS === true) {
      //  alert(`your are in ${isConnectionMobile.toUpperCase()} connection to your Iphone. You are using mobile network`)
      // Function for toast notification if user type is not selected
      toast(
        `your are in ${isConnectionMobile.toUpperCase()} connection in your Iphone. You are using mobile network`,
        {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  // CODE FOR PUSH NOTIFICATIONS
  useEffect(() => {
    if (localStorage.getItem("isPush") === null) {
      localStorage.setItem("isPush", isPushNotification);
    }
    if (!isPushNotification) {
      localStorage.setItem("isPush", false);
    } else if (isPushNotification) {
      localStorage.setItem("isPush", true);
    }
    return () => {};
  }, [isPushNotification, setIsPushNotification]);

  const handleIsPushNotifications = () => {
    if (!isPushNotification) {
      setIsPushNotification(true);
      localStorage.setItem("isPush", true);
    } else if (isPushNotification) {
      setIsPushNotification(false);
      localStorage.setItem("isPush", false);
    }
  };

  const sentToastForNotifiedActivePushNotifications = () => {
    if (!isPushNotification) {
      toast("You have just activated the reception of push notifications", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  //BOOLEAN CODE FOR RECEIVED MEDIAS
  const handleReceivedMediasSwitch = async () => {
    await UserService.updateUser(authUser.user._id, {
      mediaOptions: isReceivedMedias ? false : true,
    });

    setAuthUser((prev) => {
      return {
        ...prev,
        user: {
          ...prev.user,
          mediaOptions: isReceivedMedias ? false : true,
        },
      };
    });

    if (isReceivedMedias) {
      setIsReceivedMedias(false);
      setIsReceiveMediaToUser(false);
    }
    if (!isReceivedMedias) {
      setIsReceivedMedias(true);
      setIsReceiveMediaToUser(true);
    }
  };

  useEffect(() => {
    if (authUser && authUser.user && authUser.user._id) {
      setIsReceivedMedias(authUser.user.mediaOptions);
      setIsReceiveMediaToUser(authUser.user.mediaOptions);
    }
  }, [authUser, setIsReceivedMedias, setIsReceiveMediaToUser]);

  const handleToastReceiveMediasOption = () => {
    if (!isReceivedMedias) {
      toast("You have just activated the media reception options", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (isReceivedMedias) {
      toast("You have just deactivated the media reception options", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    localStorage.setItem("isReceivedMedias", isReceivedMedias);
  }, [isReceivedMedias]);

  return (
    <div className="settingFeetLovers__container content__withHeader">
      <ToastContainer
        position="top-center"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{top: "5em"}}
      />
      <div className="settingFeetLovers__header header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => history.push("/feet-lover-profile")}
        >
          <ChevronLeft />
        </IconButton>
        <h4>{t("settingTitleFeetLover")}</h4>
      </div>
      <div className={isIOS === true ? "settingFeetLovers__contentIphone" : ""}>
        {/* Tickles */}
        <div className="setting_item">
          <p
            onClick={() => history.push("/tickles-lovers")}
            className="cursor-pointer"
          >
            {t("feetLoverProfileTickleBalance")}
          </p>
        </div>
        <Divider />
        {/* Edit Profile */}
        <div className="setting_item">
          <p
            onClick={() => history.push("/edit-profile")}
            className="cursor-pointer"
          >
            {t("editProfileSettingLover")}
          </p>
        </div>
        <Divider />
        {/* Customized media tracking */}
        <div className="setting_item">
          <p
            onClick={() => history.push("/custom-media-tracking")}
            className="cursor-pointer"
          >
            {t("customizedMediaTracking")}
          </p>
        </div>
        <Divider />

        {/* Feetlovers Guide */}
        <div className="setting_item">
          <p
            onClick={() => {
              const path =
                i18n.language === "en"
                  ? "/en/guide/feetlovers"
                  : "/guide/feetlovers";
              window.open(`https://feety.com${path}`, "_blank");
            }}
            className="cursor-pointer"
          >
            {t("feetLoversGuide")}
          </p>
        </div>
        <Divider />
        {/*  <div className="setting_item">
          <p onClick={() => history.push("/subscription-default")}>
            {t("subscriptionSettingLover")}
          </p>
          <Divider />
      </div>*/}
        {/* Push notification */}
        <div className="setting_item">
          <p>{t("pushNotificationSettingLover")}</p>
          <div className="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              className="onoffswitch-checkbox"
              id="myonoffswitch1"
              tabIndex="0"
              onClick={() => {
                handleIsPushNotifications();
                sentToastForNotifiedActivePushNotifications();
              }}
              checked={Boolean(isPushNotification)}
              onChange={() => handleIsPushNotifications()}
            />
            <label
              className="onoffswitch-label"
              htmlFor="myonoffswitch1"
            ></label>
          </div>
        </div>
        <Divider />

        {/* Send & Receive Medias */}
        {/* <div className="setting_item">
          <p>{t("receiveMediaSettingLover")}</p>
          <div className="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              className="onoffswitch-checkbox"
              id="myonoffswitch2"
              tabIndex="0"
              checked={Boolean(isReceivedMedias)}
              onClick={() => {
                handleReceivedMediasSwitch();
                handleToastReceiveMediasOption();
              }}
              onChange={() => handleReceivedMediasSwitch()}
            />
            <label
              className="onoffswitch-label"
              htmlFor="myonoffswitch2"
            ></label>
          </div>
        </div> */}
        {/* <Divider /> */}
        <div
          className="setting_item languageSettings"
          onClick={() => history.push("/language")}
        >
          <p className="cursor-pointer">{t("languageSettingLover")}</p>
          <p>
             {i18n.language === "en"
              ? t("englishSettingLover")
              : t("frenchSettingLover")}
            {/*{authUser.user.preferredLanguage
              ? authUser.user.preferredLanguage === "fr"
                ? t("frenchSettingLover")
                : t("englishSettingLover")
              : t("frenchSettingLover")}*/}
          </p>
        </div>
        <Divider />
        <div className="setting_item">
          <p
            onClick={() => history.push("/search-history")}
            className="cursor-pointer"
          >
            {t("searchHistory")}
          </p>
        </div>
        <Divider />
        <div className="setting_item">
          <p onClick={() => history.push("/blocked-accounts")}>
            {t("settingsBlockedAccounts")}
          </p>
        </div>
        {/* <Divider />
        <div className="setting_item">
          <p>{t("cellularDataSettingLover")}</p>
          <div className="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              className="onoffswitch-checkbox"
              id="myonoffswitch3"
              tabIndex="0"
              onClick={() => setIsConnectionMobile(false)}
              defaultChecked={isConnectionMobile}

              // onChange={handleSetConnetionSwitch}
            />
            <label
              className="onoffswitch-label"
              htmlFor="myonoffswitch3"
            ></label>
          </div>
        </div> */}
        <Divider />
        <div className="setting_item">
          <p
            onClick={() => {
              const url =
                i18n.language === "en"
                  ? "https://feety.com/en/help-center"
                  : "https://feety.com/aide";
              window.open(url, "_blank");
            }}
            className="cursor-pointer"
          >
            {t("helpSettingLover")}
          </p>
        </div>
        <Divider />
        <div className="setting_item">
          <p
            onClick={() => history.push("/suppress-account")}
            className="cursor-pointer"
          >
            {t("deleteAccount")}
          </p>
        </div>
        <Divider />
        <div className="setting_item">
          <p
            onClick={() => {
              const url =
                i18n.language === "en"
                  ? "https://feety.com/en/privacy-policy"
                  : "https://feety.com/confidentialite";
              window.open(url, "_blank");
            }}
            className="cursor-pointer"
          >
            {t("privacyPolicySettingLover")}
          </p>
        </div>
        <Divider />
        <div className="setting_item">
          <p
            onClick={() => {
              const url =
                i18n.language === "en"
                  ? "https://feety.com/en/terms-of-service"
                  : "https://feety.com/conditions-generales-utilisation";
              window.open(url, "_blank");
            }}
            className="cursor-pointer"
          >
            {t("termsSettingLover")}
          </p>
        </div>
        <Divider />
        <div className="setting_item">
          {/*<p
            onClick={() => {
              sessionStorage.clear();
              window.location.href = "/";
            }}
          >
            {t("logoutSettingLover")}
          </p>*/}
          <LogoutModal />
        </div>
        <Divider />
      </div>
    </div>
  );
};

export default SettingFeetLovers;
