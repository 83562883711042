import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const blockUser = async (userId) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.post(
    `/v1/blocks/user/${userId}`,
    {},
    {
      headers,
    }
  );

  return response.data;
};

const unblockUser = async (userId) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.delete(`/v1/blocks/user/${userId}`, {
    headers,
  });

  return response.data;
};

const findBlockUser = async (userId) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(`/v1/blocks/user/${userId}`, {
    headers,
  });

  return response.data;
};

const findBlockedOrBlocker = async (userId) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
    `/v1/blocks/restrictions/user/${userId}`,
    {
      headers,
    }
  );

  return response.data;
};

const BlockService = {
  blockUser,
  unblockUser,
  findBlockUser,
  findBlockedOrBlocker,
};

export default BlockService;
