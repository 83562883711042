import { selector } from "recoil";

import publicationUploadFile from "../../atoms/publication/uploadFileAtom";
import PrePublicationBase64Service from "../../../_services/pre-publication-base64.service";
import { handleImageCompression } from "../../../helpers/browser-image-compression";
import { isVideo } from "../../../helpers";
import Bugsnag from "@bugsnag/js";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const setUploadFile = selector({
  key: "setPublicationUploadFileSelector",
  set: async ({ set }, value) => {
    // causes re-render to all component that is subscribed to the atom state.
    set(publicationUploadFile, value);

    if (value) {
      // compress if not video
      const compressedFile =
        !isVideo(value) && (await handleImageCompression(value));

      // backup logic to store the base64 temporarily to the database, just in case a re-load or refresh is needed in post publication page.
      const base64File = isVideo(value)
        ? await getBase64(value)
        : await getBase64(compressedFile);

      if (base64File) {
        PrePublicationBase64Service.postPrePublicationBase64File({
          base64: base64File,
        })
          .then((response) => {
            // store the mongodb id in session storage for the base64 file of publication.
            window.sessionStorage.setItem(
              "pre-publication-base64-file-id",
              response.data._id
            );
          })
          .catch((error) => {
            Bugsnag.notify("Upload File: ", error);
          });
      }
    }
  },
});

export default setUploadFile;
