/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import Modal from "@material-ui/core/Modal";
import "./sendTickles.css";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import ModalContent from "./SendTicklesModalContent";
import {
  sendedTicklesAtom,
  userTypeAtom,
} from "../../../stateManagement/atoms/studioAtom";

function getModalStyle() {
  const top = 47;
  const left = 48;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    border: "none !important",
  };
}

export default function SendTickles(props) {
  // getModalStyle is not a pure function, we roll the style only on the first render

  let history = useHistory();
  const location = useLocation();
  const [modalStyle] = React.useState(getModalStyle);
  // const recipient = location.recipient;
  const {
    recipient,
    setOpen,
    isOpen,
    handleSendedTickles,
    publicationForTickle,
    authenticatedUser,
    setOpenBuyTickleModal,
    ticklesIcon,
  } = props;
  const [userType, setUserType] = useRecoilState(userTypeAtom);
  const [sendedTickles, setSendedTickles] = useRecoilState(sendedTicklesAtom);

  const handleClose = () => {
    setOpen(false);
  };

  // Boby of the modal content
  const body = (
    <div style={modalStyle} className="SendTickles__paper modal-SendTickles">
      <ModalContent
        sendedTickles={sendedTickles}
        setSendedTickles={setSendedTickles}
        userType={userType}
        setUserType={setUserType}
        recipient={recipient}
        handleClose={handleClose}
        handleSendedTickles={handleSendedTickles}
        publicationForTickle={publicationForTickle}
        authenticatedUser={authenticatedUser}
        setOpenBuyTickleModal={setOpenBuyTickleModal}
        ticklesIcon={ticklesIcon}
      />
    </div>
  );

  return (
    <Fragment>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="SendTickles-simple-modal-title"
        aria-describedby="SendTickles-simple-modal-description"
      >
        {body}
      </Modal>
    </Fragment>
  );
}
