/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import publicationItemAtom from "../../stateManagement/atoms/publicationItemAtom";
import genericAvatar from "../../assets/images/generic-user-avatar.png";
import { IconButton } from "@material-ui/core";
import menuPoints from "../../assets/images/menu/menu.svg";
import SendTickles from "../loversComponents/sendTickles/SendTickles";
import { PublicationTickle } from "./PublicationTickle";
import { PublicationLike } from "./PublicationLike";
import { PublicationCommentButton } from "./PublicationCommentButton";
import { PublicationCommentModal } from "./PublicationCommentModal";
import PublicationService from "../../_services/publication.service";
import { useHistory } from "react-router";
import { PublicationFeetModel } from "./PublicationFeetModel";
import { useTranslation } from "react-i18next";
import KebabMenuOptionsLovers from "../loversComponents/KebabMenuOptionsLovers/KebabMenuOptionsLovers";
import "./public.css";
import { ToastContainer } from "react-toastify";
import { ChevronLeft } from "@material-ui/icons";
import { has as _has } from "lodash";
import Snackbar from "@material-ui/core/Snackbar";

import TicklesIcon from '../../assets/images/notifications-models/Group 54498@2x.png';
import heart from "../../assets/images/menu/heart.svg";
import likedHeart from "../../assets/images/notifications-models/likes.png";
import PublicationMedia from "../PublicationMedia";
import ReadMore from "../ReadMore";
import useSessionStorage from "../forms/services/storageHooks/useSessionStorage";
import UserService from "../../_services/user.service";
import feetModelAtom from "../../stateManagement/atoms/feetModelAtom";
import BuyTickleModal from "../../components/BuyTickleModal";
import Alert from "../Alert";
import feetModelProfileQuery from "../../stateManagement/selectors/feetModelProfileSelector";
import TicklePricesService from "../../_services/tickle-prices.service";

const Publication = (props) => {
  // const {publication}, data = useHistory();
  const { i18n, t } = useTranslation();
  const { location } = useHistory();

  const [authenticated] = useSessionStorage({}, "infosUser");

  const history = useHistory();

  const [_, setFeetModel] = useRecoilState(feetModelAtom);

  const { publication: publicationData = null } = location;
  const { scrollPosition = null } = props;
  const publication_id = publicationData?.id || location.pathname.split("/")[2];
  const [openProfile, setOpenProfile] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [picties, setPicties] = useState([]);

  const [publicationForTickle, setPublicationForTickle] = useState("");

  // The content of comment textArea from Atom state
  const [writeComment, setWriteComment] = useState(false);
  const [isTickleModalOpen, setTickleModalOpen] = useState(false);
  const [epochProductLists, setEpochProductLists] = useState([]);

  // publication state
  const [publication, setPublication] = useRecoilState(
    publicationItemAtom({
      id: publication_id,
    })
  );

  const [openBuyTickleModal, setOpenBuyTickleModal] = useState(false);
  const [authenticatedUser, setAuthenticatedUser] = useState(null);

  // alert message
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    reason: "",
    alertStatus: "info",
  });

  const feetModelProfile = useRecoilValue(feetModelProfileQuery);

  useEffect(() => {
    async function fetchData() {
      const data = await PublicationService.getPublication(publication_id);
      setPublication((item) => ({
        ...item,
        ...data.publication,
        heart: data.publication.isLiked
          ? likedHeart
          : heart,
      }));
      setLoaded(true);
    }

    const fetchPictiesByPublication = async () => {
      const response = await PublicationService.getPublicationPicties(
        publication_id
      );
      setPicties(response.picties);
    };

    fetchPictiesByPublication();
    fetchData();
  }, [publication_id]);

  const handleSendTicklesPublication = async (publication) => {
    const { user } = await UserService.getUser(authenticated.user._id);

    setAuthenticatedUser(user);

    if (user && (user.tickles === 0 || !user.tickles)) {
      setOpenBuyTickleModal(true);
    }

    if (user && user.tickles >= 1) {
      setTickleModalOpen(true);
      setPublicationForTickle(publication._id);
    }
  };

  useEffect(() => {
    (async () => {
      if (authenticated.user.userType === "Lover" && openBuyTickleModal === true) {
        const response = await TicklePricesService.getEpochProductLists();
        setEpochProductLists(response.data);
      }
    })();
  }, [openBuyTickleModal, authenticated]);

  const handleCloseAlertMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlertMessage(false);
    setAlertMessage({
      reason: "",
      alertStatus: "info",
    });
  };

  const isSubscriber = () => {
    return !!(
        feetModelProfile &&
        feetModelProfile.profile &&
        feetModelProfile.profile.subscribers &&
        feetModelProfile.profile.subscribers.length &&
        feetModelProfile.profile.subscribers.filter(
            (subscriber) => subscriber === authenticated.user._id
        ).length
    );
  };
  return (
    <div style={{ width: "100%", height: "100vh" }} className="publiContent">
      <div className="commentsList__head header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => {
            if (
              location &&
              location.state &&
              _has(location.state, "mediaTab")
            ) {
              if (_has(location.state, "feetModelsViewRoute")) {
                history.push({
                  pathname: location.state.feetModelsViewRoute,
                  search: location.state.search,
                  state: { ...location.state },
                });
              } else {
                history.push({
                  pathname: `/view-own-profile-models`,
                  state: { ...location.state },
                });
              }
            } else {
              history.goBack();
            }
          }}
        >
          <ChevronLeft />
        </IconButton>
        <h4>Publication</h4>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{top: "5em"}}
      />

      {loaded && (
        <div key={publication._id} className="post-content-page">
          <div className="post-content">
            <div
              className={
                publication.user.online
                  ? "post-header publication__page_header"
                  : "post-header homePage__topOffline publication__page_header"
              }
            >
              <div className="post-user-details">
                <div className="post-user-avatar">
                  <span
                    className={
                      publication.user.online
                        ? "allMessages__online homePage__online"
                        : ""
                    }
                  ></span>
                  <img
                    className="post-user-image"
                    src={
                      publication.user && publication.user.image
                        ? publication.user.image.url
                        : genericAvatar
                    }
                    alt="avatar"
                    onClick={() => {
                      if (authenticated.user._id === publication.user._id) {
                        history.push("/view-own-profile-models");
                      } else {
                        setFeetModel(publication.user);
                        history.push({
                          pathname: "/feet-models-view",
                          search: `?user=${publication.user._id}`,
                        });
                      }
                    }}
                  />
                </div>
                <PublicationFeetModel publication={publication} />
              </div>
              <IconButton onClick={() => setOpenProfile(true)}>
                <img className="post-header-menu" src={menuPoints} alt="menu" />
              </IconButton>
            </div>
            <div className="post-picture publication__page__img">
              <PublicationMedia
                width="100%"
                effect="blur"
                publication={publication}
                scrollPosition={scrollPosition}
                isOwner={authenticated.user._id === publication.user._id}
                isSubscribe={isSubscriber()}
              />
            </div>
            <div className="post-bottom">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
                className={
                  i18n.languages[0] === "en"
                    ? "homePage__stickers action__buttons"
                    : "homePageFr__stickers action__buttons"
                }
              >
                {authenticated.user.userType === "Lover" || authenticated.user._id === publication.user._id ? (
                  <PublicationTickle
                      publication={publication}
                      t={t}
                      handleSendTicklesPublication={handleSendTicklesPublication}
                      userType={authenticated.user.userType}
                  />
                ) : null}
                <PublicationLike
                  publication={publication}
                  setPublication={setPublication}
                />
                <PublicationCommentButton
                  publication={publication}
                  setWriteComment={setWriteComment}
                  writeComment={writeComment}
                />
              </div>
              <div className="post-bottomContent">
                <div className="post-bottomText">
                  <ReadMore>{{ ...publication, picties }}</ReadMore>
                </div>
                {/*<div className="homePage__comments">
                    <sub onClick={handleDisplayCommentsList}>
                      {t("viewCommentsHomePageLover")} {publication.commentCount}{" "}
                        {t("commentHomePageLover")}}
                    </sub>
                    writeComment ? (
                        <CommentModal handleSendComment={() => handleSendComment(publication)} />
                    ) : (
                        ""
                    )
                    isDisplayCommentsList
                        ? history.push({
                            pathname: "/comments-list",
                            state: { user: publication.user._id },
                            publication
                        })
                        : ""
                </div> */}
                <PublicationCommentModal
                  publication={publication}
                  showComment={writeComment}
                />
              </div>
              <hr className="post-hr" />
            </div>
            <SendTickles
              recipient={publication.user}
              isOpen={isTickleModalOpen}
              setOpen={setTickleModalOpen}
              publicationForTickle={publicationForTickle}
              authenticatedUser={authenticatedUser}
              setOpenBuyTickleModal={setOpenBuyTickleModal}
              setOpenAlertMessage={setOpenAlertMessage}
              setAlertMessage={setAlertMessage}
              ticklesIcon={TicklesIcon}
            />
            <KebabMenuOptionsLovers
              publication={publication}
              user={publication.user}
              open={openProfile}
              setOpen={setOpenProfile}
            />
          </div>
        </div>
      )}

      {/* buy tickle modal */}
      <BuyTickleModal
        openBuyTickleModal={openBuyTickleModal}
        setOpenBuyTickleModal={setOpenBuyTickleModal}
        epochPrices={epochProductLists}
      />

      <Snackbar
        open={openAlertMessage}
        autoHideDuration={6000}
        onClose={handleCloseAlertMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseAlertMessage}
          severity={alertMessage.alertStatus}
        >
          {/* todo: improve with dynamic message */}
          {alertMessage.reason}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Publication;
