import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { useState } from "react";
import useNotificationsModels from "./hooks/useNotificationsModels";
import GeneralNotificationList from "./GeneralNotificationList";
import { withStyles } from "@material-ui/core/styles";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Badge } from "@material-ui/core/";
import GeneralIcon from "../../../assets/images__v3/Information.png";

import generalBadgeAtom from "../../../stateManagement/atoms/notifications/badge/general";
import NotificationService from "../../../_services/notification.service";
import setNotificationGeneralBadgeInVisibleSelector from "../../../stateManagement/selectors/notifications/badge/setGeneralNotifcationBadgeInvisible";
import { useTranslation } from "react-i18next";

const StyledBadge = withStyles((theme) => ({
  badge: {
    padding: "0",
    borderRadius: "50%",
    width: "6px",
    lineHeight: "8px",
    height: "6px",
    // bottom:'-5px !important'
    top: "10px",
    left: "-18px",
    minWidth: "6px !important",
  },
}))(Badge);

const GeneralNotificationModels = ({
  notifications,
  count,
  setCount
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isInvisibleGeneralBadge, setInvisibleGeneralBadge] = useRecoilState(generalBadgeAtom);
  const setGeneralNotificationBadgeInVisible = useSetRecoilState(
    setNotificationGeneralBadgeInVisibleSelector
  );
  const { NoNotificationsYet, handleCheckReadAllNotifications, } = useNotificationsModels();

  const handleReadAllNotification = async () => {
    await NotificationService.readAllNotificationsWithType("general");

    setShowDropdown(true);
    setGeneralNotificationBadgeInVisible(true);
  };

  const { t } = useTranslation();

  return (
    <div className="notificationsModelsContentSection__subscritions">
      <div
        className={
          showDropdown === false
            ? "notificationsModels__subscritionsList hidden-body"
            : "notificationsModels__subscritionsList"
        }
        onClick={
            !showDropdown ? () => {
              handleReadAllNotification()
              // handleCheckReadAllNotifications()
              setCount(count+1)
            } : 
            () => setShowDropdown(false)
        }
      >
        <div className="notificationsModelsSection__head">
          <div className="notif-title" style={{ display: "flex", alignItems: "center" }}>
            <StyledBadge
              color="secondary"
              variant="dot"
              invisible={isInvisibleGeneralBadge}
              overlap={"rectangular"}
            >
              <img
                src={GeneralIcon}
                alt=""
                className="notificationsModels__Icon"
              />
            </StyledBadge>
            <b>{t("General")}</b>
          </div>
          {!showDropdown ? (
            <KeyboardArrowDownIcon
              className="generalNotificationsTopChevron"
            />
          ) : (
            <KeyboardArrowUpIcon
              className="generalNotificationsTopChevron"
            />
          )}
        </div>
        <hr className="hr-sep-notifications" />
        <div className="notification-body">
          {notifications.length === 0 ? (
            <NoNotificationsYet />
          ) : (
            <GeneralNotificationList data={notifications} isInvisibleGeneralBadge={isInvisibleGeneralBadge} />
          )}
        </div>
      </div>
    </div>
  );
};
export default GeneralNotificationModels;
