/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Container,
  CssBaseline,
  Grid,
  Typography,
} from "@material-ui/core/";
import { toast, ToastContainer } from "react-toastify";
import { useRecoilState } from "recoil";

import "./resetPassword.css";
import "react-toastify/dist/ReactToastify.css";

import logo from "../../../assets/images/logo-login.png";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { studioAtom } from "../../../stateManagement/atoms/studioAtom";
import usePasswordVisibility from "../hooks/usePasswordVisibility";
import AuthService from "../../../_services/auth.service";

const ResetPassword = (props) => {
  // for making redirection with react-router-dom
  let history = useHistory();
  // Variable for translation text
  const { t } = useTranslation();
  // Set info user to session storage
  let { token } = useParams();

  const {
    togglePasswordVisibility,
    setTogglePasswordVisibility,
    handlePasswordVisibility,
    toggleConfirmNewPasswordVisibility,
    handleConfirmNewPasswordVisibility,
  } = usePasswordVisibility();

  const [onStudio, setOnStudio] = useRecoilState(studioAtom);

  function clear() {
    document.getElementById("signin-input").value = "";
  }

  // Formik parameters and Yup validation inputs
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8, t("errPasswordShortSignin"))
        .required(t("errPasswordRequiredSignin")),
      confirmNewPassword: Yup.string()
        .min(8, t("errPasswordShortSignin"))
        .required(t("errPasswordRequiredSignin")),
    }),
    onSubmit: async (values) => {
      try {
        const response = await AuthService.resetPassword(token, {
          newPassword: values.newPassword,
          confirmNewPassword: values.confirmNewPassword,
        });

        toast.dark("Password reset successful", {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        history.push("/");
      } catch (error) {
        toast.error(
          `${error.response?.data?.error || t("errSigninPostResponse")}`,
          {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );

        if (
          error.response?.data?.error !==
          "Mismatch new password with confirm new password"
        )
          history.push("/forgot-password");
      }
    },
  });

  useEffect(() => {
    if (onStudio === false) {
      setOnStudio(true);
    }
  }, [onStudio, setOnStudio]);

  return (
    <Container className="formSignin__container" component="main" maxWidth="xs">
      <CssBaseline />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ top: "5em" }}
      />
      <div className="formSignin__paper">
        <div className="formSignin__imageContainer">
          <img className="auth__imgLogo" src={logo} alt="" />
        </div>
        <form
          className="formSignin__form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <span className="login__password_wrap">
            <div className="name-input">{t("newPasswordTitleSignin")}</div>
            <span className="spanInput">
              <input
                id="signin-input"
                className="formSignin__secondInput"
                type="password"
                name="newPassword"
                placeholder={t("placeholderPasswordSignin")}
                value={formik.values.newPassword}
                onChange={formik.handleChange}
              />{" "}
              {!togglePasswordVisibility ? (
                <VisibilityOffIcon
                  className="visibilityIcon"
                  onClick={handlePasswordVisibility}
                />
              ) : (
                <VisibilityIcon
                  className="visibilityIcon"
                  onClick={handlePasswordVisibility}
                />
              )}
              {formik.errors.newPassword && formik.touched.newPassword ? (
                <p>{formik.errors.newPassword}</p>
              ) : null}
            </span>
          </span>
          <span className="login__password_wrap">
            <div className="name-input">
              {t("confirmNewPasswordTitleSignin")}
            </div>
            <span className="spanInput">
              <input
                id="confirm-new-password-input"
                className="formSignin__secondInput"
                type="password"
                name="confirmNewPassword"
                placeholder={t("placeholderPasswordSignin")}
                value={formik.values.confirmNewPassword}
                onChange={formik.handleChange}
              />{" "}
              {!toggleConfirmNewPasswordVisibility ? (
                <VisibilityOffIcon
                  className="visibilityIcon"
                  onClick={handleConfirmNewPasswordVisibility}
                />
              ) : (
                <VisibilityIcon
                  className="visibilityIcon"
                  onClick={handleConfirmNewPasswordVisibility}
                />
              )}
              {formik.errors.confirmNewPassword &&
              formik.touched.confirmNewPassword ? (
                <p>{formik.errors.confirmNewPassword}</p>
              ) : null}
            </span>
          </span>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className="formSignin__submit pink__button"
          >
            Submit
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default ResetPassword;
