import CommentNotificationModels from "./CommentNotificationModels";
import NotificationTickles from "./NotificationTickles";
import NotificationLikes from "./NotificationLikes";
import GeneralNotificationModels from "./GeneralNotificationModels";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import useNotificationsModels from "./hooks/useNotificationsModels";
import {useRecoilState, useRecoilValue} from "recoil";
import feetModelOwnProfileQuery from "../../../stateManagement/selectors/feetModelOwnProfileSelector";
import useInfiniteQuery from "../../../hooks/useInfiniteQuery";
import SubscribeService from "../../../_services/subscribe.service";
import feetModelAtom from "../../../stateManagement/atoms/feetModelAtom";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import NotificationService from "../../../_services/notification.service";
import generalNotificationsQuery from "../../../stateManagement/selectors/generalNotificationSelector";
import {useTranslation} from "react-i18next";
import { uniqBy as _uniqBy } from "lodash";
import {SocketContext} from "../../../context/socket";
import NotificationSubscriptions from "./NotificationSubscriptions";
import { CircularProgress } from "@material-ui/core";

const NotificationContent = ({handleCheckReadAllNotifications}) => {

    const { t } = useTranslation();
    const socket = useContext(SocketContext);
    const [count, setCount] = useState(0);
    // feet model profile
    const feetModel = useRecoilValue(feetModelOwnProfileQuery);
    const [__, setFeetModel] = useRecoilState(feetModelAtom);
    const [loading, setLoading] = useState(false);

    const {
        data: subscribers,
        hasNextPage: subscribersHasNextPage,
        fetchNextPage: subscribersFetchNextPage,
    } = useInfiniteQuery("notification-subscribers", async () => {
        setLoading(true)
        const response = await SubscribeService.getSubscriptionsV2();

        setLoading(false)
        return {
            data: response.data.subscriptions,
            next_offset: response.data.next_offset,
        };
    });

    const {
        data: notifications,
        hasNextPage: notificationHasNextPage,
        fetchNextPage: notificationFetchNextPage,
    } = useInfiniteQuery("notification", async ({ next_offset = 0 }) => {
        const response = count > 0 ? await NotificationService.getNotifications(next_offset) : null;

        return {
            data: response.data.notifications,
            next_offset: response.data.next_offset,
        };
    }, count);

    const loadMoreGeneralsReference = useRef();

    useIntersectionObserver({
        target: loadMoreGeneralsReference,
        onIntersect: notificationFetchNextPage,
        enabled: notificationHasNextPage,
    });

    const newGeneralNotificationHandler = useCallback(
        (newMessage) => {
            setCount(count+1)
        },
        [notifications]
    );

    useEffect(() => {
        // observer > new message
        const general = socket.onNotification();
        const onTickle = general.subscribe(newGeneralNotificationHandler);

        // clean up observer
        return () => onTickle.unsubscribe();
    }, [socket, newGeneralNotificationHandler]);

    useEffect(() => {
        handleCheckReadAllNotifications();
    }, [handleCheckReadAllNotifications])

    return <div className="notificationsModels__content content__withHeader minmax__wrapper">
        <div className="notificationsModelsContent__section">
            {loading ? (
            <div
                style={{
                position: "absolute",
                left: "calc(50% - 20px)",
                top: "60px",
                }}
            >
                <CircularProgress color="secondary" />
            </div>
            ) : (
                <>
                <CommentNotificationModels subscribers={subscribers}/>
                {/* tickles */}
                <NotificationTickles subscribers={subscribers}/>
                {/* likes */}
                <NotificationLikes subscribers={subscribers}/>
                {/* subscriptions */}
                <NotificationSubscriptions/>
                {/* general notifications */}
                <GeneralNotificationModels
                    notifications={notifications}
                    count={count}
                    setCount={setCount}
                />
                </>
            )}
        </div>
    </div>
}

export default NotificationContent
