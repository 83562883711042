import { selector } from "recoil";

import searchHistoryAtom from "../atoms/searchHistoryAtom";
import HistoryService from "../../_services/history.service";

const searchHistoryQuery = selector({
  key: "searchHistory",
  get: async ({ get }) => {
    try {
      get(searchHistoryAtom); // dependency
      const response = await HistoryService.getSearchHistory();

      return response.data;
    } catch (e) {
      console.error("ERROR GET /api/v1/histories/search-history", e);
    }
  },
});

export default searchHistoryQuery;
