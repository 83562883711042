import React from "react";
import { Button, IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useRecoilValue } from "recoil";
import * as Yup from "yup";

import "./helpLover.css";
import HelpService from "../../../_services/help.service";
import { onSettingModelsAtom } from "../../../stateManagement/atoms/studioAtom";

const HelpMessageSchema = Yup.object().shape({
  message: Yup.string().required(),
});

const HelpLover = (props) => {
  let history = useHistory();

  const onSettingModels = useRecoilValue(onSettingModelsAtom);

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: HelpMessageSchema,
    onSubmit: async (values) => {
      try {
        await HelpService.sendHelpMessage(values);
        // TODO: success message
        history.push("/settings-lovers");
      } catch (error) {
        console.log("error help api: ", error);
      }
    },
  });

  return (
    <div className="helpLover__container">
      <div className="helpLover__header header page_fixed_header">
        <IconButton
          className="helpLover__chevron back__button"
          onClick={() =>
            onSettingModels === true
              ? history.push("/setting-models")
              : history.push("/settings-lovers")
          }
        >
          <ChevronLeft />
        </IconButton>
        <h4>Help</h4>
      </div>
      <div className="helpLover__content content__withHeader minmax__wrapper">
        <form noValidate onSubmit={formik.handleSubmit}>
          <div>
            <h4>Let us know we may help you.</h4>
            <textarea
              placeholder="Write your message"
              className="helpLover__textarea"
              value={formik.values.message}
              onChange={formik.handleChange}
              id="message"
              name="message"
              required
            />
            {formik.errors.message && formik.touched.message && (
              <p>{formik.errors.message}</p>
            )}
          </div>
          <div className="helpLover__bottom">
            <Button
              className="helpLoverBottom__button pink__button"
              variant="contained"
              type="submit"
            >
              Send
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HelpLover;
