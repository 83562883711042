import { axiosInstance } from "./base";
import { getAuthSession } from "../helpers/auth.helpers";

const uploadDocument = async (data) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.post(
    "/id-verification/document/id",
    data,
    {
      headers,
    }
  );
  return response;
};

const validate = async (type) => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.post(
    "/id-verification/validate",
    { type },
    {
      headers,
    }
  );
  return response;
};

const submitVerification = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.post(
    "/id-verification/submit",
    {},
    {
      headers,
    }
  );
  return response;
};

const IdVerificationService = {
  uploadDocument,
  submitVerification,
  validate,
};

export default IdVerificationService;
