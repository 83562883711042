/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useRecoilState } from "recoil";

import "./successEmailUpdate.css";

import { studioAtom } from "../../../stateManagement/atoms/studioAtom";
import { useTranslation } from "react-i18next";
import userService from "../../../_services/user.service";

const SuccessEmailUpdates = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [_, setOnStudio] = useRecoilState(studioAtom);

  useEffect(() => {
    const fetchData = async (token) => {
      const data = await userService.successEmail(token);
      return data;
    };
    setOnStudio(true);
    const location = history.location.pathname;
    const tokenId = location.split("/")[3];
    fetchData(tokenId);

    return () => {
      setOnStudio(false);
    };
  }, [setOnStudio]);

  return (
    <div className="feetloverGuides__container">
      <div className="feetloverGuides__header header page_fixed_header">
        <h4>{t("Update Email Success")}</h4>
      </div>
      <div className="feetloverGuides__content minmax__wrapper content__withHeader feet-model-guides-container">
        <p>
          {t("Congratulate Email")}
          <br /> <br />
          <Button
            type="submit"
            className="profileForm__submit profileForm__loginButton pink__button"
            onClick={() => history.push("/edit-profile")}
          >
            {t("Go to your edit profile page")}
          </Button>
        </p>
      </div>
    </div>
  );
};

export default SuccessEmailUpdates;
