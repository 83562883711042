import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SubscribeService from "../../_services/subscribe.service";

import { useStyles } from "./styled";

const DialogTitle = (props) => {
  const { classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.dialogTitleRoot}
      {...other}
    >
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

function CancelSubscribe(props) {
  const classes = useStyles();

  const {
    openCancelSubscribeModal,
    setOpenCancelSubscribeModal,
    subscription,
    setData,
    setIsLoading,
    ...rest
  } = props;

  return (
    <Dialog
      className={classes.dialogRoot}
      open={openCancelSubscribeModal}
      onClose={() => setOpenCancelSubscribeModal(false)}
      {...rest}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setOpenCancelSubscribeModal(false)}
        classes={classes}
      >
        Unsubscribe this person.
      </DialogTitle>
      <DialogContent className={classes.dialogContentRoot}>
        Are you sure you want to unsubscribe from this private wall?
      </DialogContent>
      <DialogActions className={classes.dialogActionsRoot}>
        <Button
          autoFocus
          onClick={async () => {
            setIsLoading(true);
            try {
              await SubscribeService.cancelSubscription(subscription._id);
              setData((prev) => {
                prev.find(
                  (subscriptionData) =>
                    subscriptionData._id === subscription._id
                ).cancelled = true;
                return prev;
              });
              setOpenCancelSubscribeModal(false);
              setIsLoading(false);
            } catch (error) {
              console.log("error at cancel subscription: ", error);
            }
          }}
          color="primary"
          className="buy-tickles-confirm"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CancelSubscribe;
