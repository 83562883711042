/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import "./socialLoginWelcome.css";
import ModalContent from "./ModalContent";
import {
  studioAtom,
  userTypeAtom,
} from "../../stateManagement/atoms/studioAtom";

import authUserAtom from "../../stateManagement/atoms/auth/user";
import useSessionStorage from "../../components/forms/services/storageHooks/useSessionStorage";

function getModalStyle() {
  const top = 47;
  const left = 48;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    border: "none !important",
  };
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SocialLoginWelcome(props) {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  let history = useHistory();
  let query = useQuery();

  const [open, setOpen] = React.useState(false);
  const [userType, setUserType] = useRecoilState(userTypeAtom);
  const [onStudio, setOnStudio] = useRecoilState(studioAtom);

  const [userInfo, setResponseInfo] = useSessionStorage({}, "infosUser");
  const [_, setAuthenticatedUser] = useRecoilState(authUserAtom);

  useEffect(() => {
    if (userInfo && userInfo.user && userInfo.user._id) {
      if (userInfo.user && userInfo.user.userType) {
        if (userInfo.user.userType === "Model") {
          history.push("/home-page-models");
        }

        if (userInfo.user.userType === "Lover") {
          history.push("/home-page");
        }
      }
    }
  }, [userInfo, history]);

  useEffect(() => {
    const setUser = (userData, type) => {
      setResponseInfo({ user: userData });
      setAuthenticatedUser(userData);
      setUserType(type);
    };

    const userData = JSON.parse(decodeURI(query.get("user")));

    if (userData && userData._id && userData.userType) {
      if (userData.userType && userData.userType === "Model") {
        setUser(userData, "Model");
      }

      if (userData.userType && userData.userType === "Lover") {
        setUser(userData, "Lover");
      }
    } else {
      // existing user but does not have user type yet; Not Model or Lover
      setOpen(true);
    }
  }, [query, setAuthenticatedUser, setResponseInfo, setUserType]);

  const handleClose = () => {
    setOpen(false);
    history.push("/sign-up");
  };

  // Boby of the modal content
  const body = (
    <div style={modalStyle} className="modalSocial__paper modal-social">
      <ModalContent
        onStudio={onStudio}
        setOnStudio={setOnStudio}
        userType={userType}
        setUserType={setUserType}
        query={query}
      />
    </div>
  );

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </Fragment>
  );
}
