import React from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { useStyles } from "./styled";

function ChangeFeetModelSubscriptionPriceButton({ monthlySub = 0 }) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const roundPrice = (num, decimalPlaces = 2) => {
    const p = Math.pow(10, decimalPlaces);
    const n = num * p * (1 + Number.EPSILON);
    return Math.round(n) / p;
  };

  return (
    <div className={classes.root}>
      <b>{t("Change the price of your subscription")}</b>
      <div className="change-subscription-amount-button">
        {monthlySub === 0 ? (
          <p>{t("Not yet chosen")}</p>
        ) : (
          <p>
            {roundPrice(monthlySub)}€/{t("months")}
          </p>
        )}

        <div className="change-button-arrow">
          <Button onClick={() => history.push("/update-subscriptions")}>
            {t("change")}
          </Button>
          <ArrowForwardIosIcon />
        </div>
      </div>
    </div>
  );
}

export default ChangeFeetModelSubscriptionPriceButton;
