import React from "react";
import RedLogo from "../../../assets/images/red-logo.png";
import "./subscriptionDefault.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ChevronLeft } from "@material-ui/icons";
import { useRecoilValue } from "recoil";

import feetModelSuggestionToSubscribeQuery from "../../../stateManagement/selectors/subscribeSuggestionSelector";
import genericAvatar from "../../../assets/images/generic-user-avatar.png";
import genericPublicationImage from "../../../assets/images/generic-publication-color.png";

import PublicationMedia from "../../PublicationMedia";

const SubscriptionDefault = () => {
  let history = useHistory();

  const feetModel = useRecoilValue(feetModelSuggestionToSubscribeQuery);

  Notification.requestPermission((result) => {
    if (result === "granted") {
      showNotification("No subscription yet.");
    }
  });

  function showNotification(title, message) {
    if ("Notification" in window) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.showNotification(title, {
          body: message,
          tag: "vibration-sample",
        });
      });
    }
  }
  return (
    <div className="subscriptionDefault__container">
      <div
        style={{ color: "black" }}
        className="subscriptionDefault__header header header-height"
      >
        <ChevronLeft />
        <h4>Subscription</h4>
      </div>
      <div
        className="subscriptionDefault__top"
        onClick={() => history.push("/subscription-lover")}
      >
        <img src={RedLogo} alt="" />
        <div className="subscriptionDefault__top-text">
          <h4>No subscriptions yet.</h4>
          <p>(Search or check feet models to subscribe)</p>
        </div>
      </div>
      <div className="subscriptionDefault__content">
        <h3>Suggestions</h3>
        <div className="subscriptionDefault__grid-picture">
          {feetModel.suggestions.map((feetModel) => (
            <div
              key={feetModel._id}
              className="subscriptionDefault__pictures-publications"
            >
              <div className="subscriptionDefault__picture">
                <PublicationMedia
                  publication={feetModel.publication}
                  alt="publictions"
                  width="100%"
                  effect="blur"
                  className="subscriptionDefault__photo"
                  preview={genericPublicationImage}
                />
              </div>
              <div className="subscriptionDefault__bottom-publication">
                <img
                  className="subscriptionDefault__avatar"
                  src={feetModel.image ? feetModel.image.url : genericAvatar}
                  alt="avatar"
                />
                <h4 className="subscriptionDefault__avatarName">
                  {feetModel.username}
                </h4>
              </div>
              <Button
                variant="contained"
                className="subscriptionDefault__follow-button"
              >
                Subscribe
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDefault;
