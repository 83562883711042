/* eslint-disable no-unused-vars */
import { Button } from "@material-ui/core";
import comments from "../../assets/images/menu/comment.svg";
import React from "react";
import { useTranslation } from "react-i18next";

export const PublicationCommentButton = (props) => {
  const { publication, setPublication, writeComment, setWriteComment } = props;
  const { i18n, t } = useTranslation();

  const handleDisplayCommentModal = (publication) => {
    setWriteComment(!writeComment);
  };

  return (
    <div>
      <Button onClick={() => handleDisplayCommentModal(publication)}>
        <img src={comments} alt="comments" />
        <sub>
          {publication.commentCount} {t("commentHomePageLover")}
        </sub>
      </Button>
    </div>
  );
};
