import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core/";
import { useRecoilState } from "recoil";

import LogoText from "../../../assets/images/logo-text@2x.png";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import IdentityVerificationStepper from "../IdentityVerificationStepper/IdentityVerificationStepper";
import { studioAtom } from "../../../stateManagement/atoms/studioAtom";
import qs from "query-string";
import { getAuthSession } from "../../../helpers/auth.helpers";

const getDefaultStep = (step) => {
  const { user } = getAuthSession();
  if (user.idCheckDetails.verified) {
    return 6;
  }
  if (user.idCheckDetails.status === "selfie-for-admin-validation") {
    return 5;
  }
  if (user.idCheckDetails.idImageVerified) {
    return 3;
  }
  return step;
};
function IdentityVerificationPage() {
  let history = useHistory();
  let location = useLocation();
  const query = qs.parse(location.search);
  const { user } = getAuthSession();
  const [step, setStep] = useState(getDefaultStep(query?.step || 0));
  const finalizeId = () => {
    setStep(3);
  };
  useEffect(() => {
    return () => {
      if (user?.idCheckDetails?.verified) {
        setStep(6);
      } else if (
        user?.idCheckDetails?.status === "selfie-for-admin-validation"
      ) {
        setStep(5);
      } else if (user?.idCheckDetails?.idImageVerified) {
        setStep(3);
      }
    };
  }, [
    user?.idCheckDetails?.idImageVerified,
    user?.idCheckDetails?.status,
    step,
  ]);

  // access global state using recoil
  const [onStudio, setOnStudio] = useRecoilState(studioAtom);

  return (
    <Container maxWidth="sm" style={{ height: "calc(100vh - 64px)" }}>
      <div className="homePageModels__header header page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => {
            // update global state from recoil to show bottom navigation.
            setOnStudio(false);
            history.goBack();
          }}
        >
          <ChevronLeft />
        </IconButton>
        <img src={LogoText} alt="" className="logo" />
      </div>
      <IdentityVerificationStepper step={step} onFinalizeId={finalizeId} />
    </Container>
  );
}

export default IdentityVerificationPage;
