import { selector } from "recoil";

import generalBadge from "../../../atoms/notifications/badge/general";

const setNotificationGeneralBadgeInVisible = selector({
  key: "setNotificationGeneralBadgeInVisibleSelector",
  set: ({ set }, value) => {
    set(generalBadge, value);
  },
});

export default setNotificationGeneralBadgeInVisible;
