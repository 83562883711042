import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const CommonAlertModal = (props) => {
  const { t } = useTranslation();

  const { title, lists, subTitle, setHelperOpen, open } = props;

  const handleClose = () => setHelperOpen(false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={title}
      aria-describedby={title}
    >
      <DialogTitle style={{ textAlign: "center" }}>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" gutterBottom>
          {subTitle}
        </Typography>
        {lists}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color={"primary"}
          variant={"outlined"}
          style={{
            backgroundColor: "white",
            padding: "10px",
            border: "none",
            color: "#ff2775",
            fontSize: "	1.1rem",
            fontWeight: "700",
            fontFamily: "Roboto",
          }}
        >
          {t("Ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CommonAlertModal;
