/* eslint-disable no-unused-vars */
import { ChevronLeft } from "@material-ui/icons";
import React, { useCallback, useState } from "react";
import "./messageSearch.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Avatar, IconButton } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";

import userSuggestionsQuery from "../../../../stateManagement/selectors/userSuggestionsInMessageSelector";
import genericAvatar from "../../../../assets/images/generic-user-avatar.png";
import SearchIcon from "../../../../assets/images__v3/Recherche_outline_2.png";
import UserService from "../../../../_services/user.service";

const MessageSearch = () => {
  const { t } = useTranslation();
  let history = useHistory();

  const { suggestions } = useRecoilValue(userSuggestionsQuery);

  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [usersData, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  /**
   * This will be called every time there is a change in the input
   * @param {*} { target: { value } }
   */
  const onChange = ({ target: { value } }) => {
    setLoading(true);
    setQuery(value);

    const search = _.debounce(sendQuery, 200);

    setSearchQuery((prevSearch) => {
      if (prevSearch.cancel) {
        prevSearch.cancel();
      }
      return search;
    });

    search(value);
  };

  /**
   * In charge to send the value to the API
   *
   * @param {*} value
   */
  const sendQuery = useCallback(async (value) => {
    const { cancelPrevQuery, users } = await UserService.searchUsers(value);

    if (cancelPrevQuery) return;

    if (users) {
      setLoading(false);
      setUsers(users);
    } else {
      setLoading(false);
      setUsers([]);
    }
  }, []);

  return (
    <div className="messageSearch__container">
      <div className="messageSearch__header header page_fixed_header">
        <IconButton
          onClick={() => history.push("/messages")}
          className="back__button"
        >
          <ChevronLeft className="messageSearch__chevron" />
        </IconButton>
        <h4 className="messageSearch__title">{t("searchMessageTitle")}</h4>
      </div>
      <div className="content__withHeader minmax__wrapper">
        <div className="messageSearch__search">
          <div className="messageSearch__inputContainer searchInput__wrapper">
            <input
              className="messageSearch__input rounded_input"
              type="text"
              placeholder={t("searchDefaultViewTitle")}
              value={query}
              onChange={onChange}
            />
            <img id="searchIcon" src={SearchIcon} alt="search" />
          </div>
        </div>
        <div className="messageSearch__content">
          <div className="messageUser_list user_list">
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  left: "calc(50% - 20px)",
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              usersData.map((user) => (
                <div className="user_thumbnail_details" key={user._id}>
                  <div className="avatar">
                    <Avatar src={user.image ? user.image.url : genericAvatar} />
                  </div>
                  <div
                    className="user_details"
                    onClick={() =>
                      history.push({
                        pathname: "/create-messages",
                        state: { receiver: user },
                      })
                    }
                  >
                    <h2 className="messageSearch__name">{user.username}</h2>
                    {/* <p className="messageSearch__sub">{user.username}</p> */}
                  </div>
                </div>
              ))
            )}
            {/*use to get fill gaps and have even cells*/}
            <div className="user_thumbnail_details space_filler"></div>
            <div className="user_thumbnail_details space_filler"></div>
            <div className="user_thumbnail_details space_filler"></div>
          </div>
        </div>

        <div className="messageSearch__content">
          <div className="messageSearch__contentText">
            <b>{t("messageSearchLoverSuggested")}</b>
          </div>
          <div className="messageUser_list user_list">
            {suggestions.map((user) => (
              <div className="user_thumbnail_details" key={user._id}>
                <div className="avatar">
                  <Avatar
                    src={user.image ? user.image.url : genericAvatar}
                    alt="profile pict"
                  />
                </div>
                <div
                  className="user_details"
                  onClick={() =>
                    history.push({
                      pathname: "/create-messages",
                      state: { receiver: user },
                    })
                  }
                >
                  <h2 className="messageSearch__name">{user.username}</h2>
                  {/* <p className="messageSearch__sub">{user.username}</p> */}
                </div>
              </div>
            ))}
            {/*use to get fill gaps and have even cells*/}
            <div className="user_thumbnail_details space_filler"></div>
            <div className="user_thumbnail_details space_filler"></div>
            <div className="user_thumbnail_details space_filler"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageSearch;
