import React, { useMemo, useState } from "react";

import { SocketEventContext } from "./socketEvent";

/* handle socket events.

ex: receive and send new message
*/
export const SocketEventsProvider = ({ children }) => {
  // global state
  const [newMessage, setNewMessage] = useState("");

  const value = useMemo(() => ({ newMessage, setNewMessage }), [newMessage]);

  return (
    <SocketEventContext.Provider value={value}>
      {children}
    </SocketEventContext.Provider>
  );
};
