import React from "react";
import { useTranslation } from "react-i18next";

import "./logoutModal.css";

const LogoutModal = () => {
  const { t } = useTranslation();
  return (
    <div className="LogoutModalContainer">
      <div className="container">
        <div className="interior">
          <a className="btn" href="#open-modal">
            {t("disconnect")}
          </a>
        </div>
      </div>
      <div id="open-modal" className="modal-window">
        <div className="logoutConfirmeModalContent">
          <div>{t("logoutConfirm")}</div>
          <div className="logoutModal-buttons">
            <p
              className="logout-button"
              onClick={() => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.href = "/";
              }}
            >
                {t("Yes")}
            </p>
            <a href="#" title="Close" className="logoutButton-no">
                {t("No")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
