import { getAuthSession } from "../helpers/auth.helpers";
import { axiosInstance } from "./base";

const getWithdrawals = async () => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const response = await axiosInstance.get(
    `/v1/feet-models/get-withdrawal-list`,
    {
      headers,
    }
  );

  return response;
};

const transferRequest = async (type = "") => {
  const headers = {
    Authorization: `Bearer ${getAuthSession().user.access_token}`,
  };

  const id = getAuthSession().user._id;
  const data = {
    type: type
  }
  const response = await axiosInstance.post(
    `/v1/feet-models/${id}/withdrawGains`, data,
    {
      headers,
    }
  );

  return response;
};

const WithdrawalService = {
  getWithdrawals,
  transferRequest,
};

export default WithdrawalService;
