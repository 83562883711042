import React from "react";
import dateLocale from "../../../../../dateLocale";

export const MessageText = (props) => {
  return (
    <div className="messageTextSection">
      <p className="createMessage__sendMessageDate">
        {dateLocale.localize(props.message.createdAt).fromNow()}
      </p>
      <div className="slit-in-horizontal createMessage__SentMessage">
        <p>{props.message.content}</p>
      </div>
    </div>
  );
};
