import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useStyles } from "./styled";
import Button from "../../components/Button";
import SubscriptionPricingService from "../../_services/subscription-pricing";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import UserService from "../../_services/user.service";
import { getAuthSession } from "../../helpers/auth.helpers";
import authUserAtom from "../../stateManagement/atoms/auth/user";
import signUpUserDataAtom from "../../stateManagement/atoms/auth/sign-up-user-data-atom";
import setSignUpUserData from "../../stateManagement/selectors/auth/sign-up-user-data-selector";

function ChangeFeetModelSubscriptionAmountPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const [signUpUserData] = useRecoilState(signUpUserDataAtom);
  const setSignUpUserDataSelector = useSetRecoilState(setSignUpUserData);
  const { addNotificationMessage } = useNotificationAlert();
  const [user, setUpdateAuthenticatedUser] = useRecoilState(authUserAtom);
  const [epochSubscriptionProductLists, setEpochSubscriptionProductLists] =
    useState([]);
  const [selectedSubscriptionProduct, setSelectedSubscriptionProduct] =
    useState("");

  const [isUpdatingSubscriptionPrice, setIsUpdatingSubscriptionPrice] =
    useState(false);

  useEffect(() => {
    const fetchEpochSubscriptionProductLists = async () => {
      const response =
        await SubscriptionPricingService.getEpochSubscriptionProducts();

      setEpochSubscriptionProductLists(response.data);
    };

    fetchEpochSubscriptionProductLists();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (
        getAuthSession() &&
        getAuthSession()?.user &&
        getAuthSession()?.user?._id
      ) {
        const response = await UserService.getUser(getAuthSession().user._id);

        if (response && response?.user && response?.user?.currentProduct) {
          setSelectedSubscriptionProduct(response?.user?.currentProduct);
        }
      }
    };

    if (user) {
      if (user.currentProduct && user.currentProduct?._id) {
        setSelectedSubscriptionProduct(user?.currentProduct);
      } else {
        fetchUserData();
      }
    } else {
      fetchUserData();
    }
  }, [user]);

  const handleSelectSubscriptionProduct = (product = null) => {
    setSelectedSubscriptionProduct(product);
  };

  const handleConfirmUpdateSubscription = async () => {
    try {
      if (selectedSubscriptionProduct) {
        if (user || getAuthSession()?.user) {
          setIsUpdatingSubscriptionPrice(true);

          const userId = getAuthSession().user._id;

          const response = await UserService.updateUser(userId, {
            currentProduct: selectedSubscriptionProduct._id,
          });

          if (response.message === "User Updated") {
            setIsUpdatingSubscriptionPrice(false);
            addNotificationMessage(
              "Successfully updated your subscription rate",
              "success"
            );
            setUpdateAuthenticatedUser(response.data);
            history.goBack();
          }
        } else {
          setSignUpUserDataSelector({
            ...signUpUserData,
            currentProduct: selectedSubscriptionProduct._id,
          });

          history.push("/sign-up");
        }
      } else {
        addNotificationMessage(t("subscriptionErrorNotification"), "error");
      }
    } catch (error) {
      addNotificationMessage(error.message, "error");
    }
  };

  const addVatToAmount = (amount) => {
    // const tax = 1.2;
    // return (amount / tax).toLocaleString();

    return `${roundPrice(amount)} €`;
  };

  const roundPrice = (num, decimalPlaces = 2) => {
    const p = Math.pow(10, decimalPlaces);
    const n = num * p * (1 + Number.EPSILON);
    return Math.round(n) / p;
  };

  return (
    <div className={classes.root}>
      <div className="page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => {
            user ? history.push("/edit-profile") : history.goBack();
          }}
        >
          <ChevronLeft />
        </IconButton>
        <h4>{t("subscription")}</h4>
      </div>
      <div className="change-feet-model-subscription-amount-content-container content__withHeader">
        <div className="" style={{ textAlign: "center" }}>
          <h4>{t("setThePriceHeader")}</h4>
          <p style={{ fontSize: "16px", textAlign: "center" }}>
            {t("updateSubscriptionPtag")}
          </p>
          <span
            style={{
              fontSize: "14px",
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            {t("updateSubscriptionSpanTag")}
          </span>
        </div>

        <div className="epoch-subscription-product-lists">
          {epochSubscriptionProductLists &&
          epochSubscriptionProductLists.length ? (
            epochSubscriptionProductLists
              .sort((a, b) => a.amount - b.amount)
              .map((product, index) => (
                <div
                  key={product._id}
                  className="epoch-subscription-product-wrapper"
                >
                  {product?.popular ? (
                    <div className="popular-text-container popular-text">
                      {t("popular")}
                    </div>
                  ) : (
                    <div className="popular-text-container"></div>
                  )}
                  <div
                    onClick={() => handleSelectSubscriptionProduct(product)}
                    className={`epoch-subscription-amount-wrapper ${
                      product.popular ? "popular-subscription-product" : ""
                    }
                    ${
                      selectedSubscriptionProduct._id === product._id
                        ? "selected-subscription-product"
                        : ""
                    }`}
                  >
                    <span>
                      <span className="amount">
                        {addVatToAmount(product?.amount)}
                      </span>
                      <br />/ {t("month")}
                    </span>
                  </div>

                  {product?.popular ? (
                    <span className="popular-message">
                      {t("popularMessageOne")} <br /> {t("popularMessageTwo")}
                    </span>
                  ) : null}
                </div>
              ))
          ) : (
            <span>Fetching subscription product list...</span>
          )}
        </div>

        <div className="button-test">
          <Button
            width="219px"
            onClick={handleConfirmUpdateSubscription}
            background="linear-gradient(90deg, #FF2775 0%, #FF5768 100%)"
          >
            {isUpdatingSubscriptionPrice ? (
              <CircularProgress color="inherit" size={15} />
            ) : (
              t("confirm")
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ChangeFeetModelSubscriptionAmountPage;
