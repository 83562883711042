import "./formSignin.css";
import "react-toastify/dist/ReactToastify.css";

import * as Yup from "yup";

import {
  Button,
  Container,
  CssBaseline,
  Grid,
  Typography,
} from "@material-ui/core/";
import { IconButton, InputBase, Paper } from "@material-ui/core";
// import { IonText, useIonAlert } from "@ionic/react";
import { Link, useHistory } from "react-router-dom";
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";

import AuthService from "../../../_services/auth.service";
import Bugsnag from "@bugsnag/js";
import CircularProgress from "@material-ui/core/CircularProgress";
import LanguageSelector from "../../Language";
import { SocketContext } from "../../../context/socket";
import { ToastContainer } from "react-toastify";
import UserService from "../../../_services/user.service";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import authUserAtom from "../../../stateManagement/atoms/auth/user";
import logo from "../../../assets/images/logo-login.png";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { studioAtom } from "../../../stateManagement/atoms/studioAtom";
import ticklesAtom from "../../../stateManagement/atoms/ticklesAtom";
import { useFormik } from "formik";
import useNotificationAlert from "../../../hooks/useNotificationAlert";
import usePasswordVisibility from "../hooks/usePasswordVisibility";
import { useRecoilState } from "recoil";
import useSessionStorage from "../services/storageHooks/useSessionStorage";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    backgroundColor: "#f50057",
    color: "white",
    height: 60,
    marginLeft: 6,
    borderRadius: 6,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const FormSignIn = (props) => {
  // for making redirection with react-router-dom
  let history = useHistory();
  const classes = useStyles();
  // Variable for translation text
  const { i18n, t } = useTranslation();

  const { addNotificationMessage } = useNotificationAlert();

  // Set info user to session storage
  const [__, setResponseInfo] = useSessionStorage({}, "infosUser");
  const [_, setAuthenticatedUser] = useRecoilState(authUserAtom);
  const [onStudio, setOnStudio] = useRecoilState(studioAtom);
  const [tickles, setTickles] = useRecoilState(ticklesAtom);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(0);
  const [expiry, setExpiry] = useState(false);
  const [isStillSoft, setIsStillSoft] = useState(true);
  const [registrationAvailability, setRegistrationAvailability] = useState({});
  const [isOpenForRegistration, setIsOpenForRegistration] = useState(false);
  const [registrationDate, setRegistrationDate] = useState("");
  const [isSubscribing, setIsSubscribing] = useState(false);
  const {
    togglePasswordVisibility,
    setTogglePasswordVisibility,
    handlePasswordVisibility,
  } = usePasswordVisibility();
  const socket = useContext(SocketContext);

  // const [presentAlert] = useIonAlert();

  /*
  const {
    data,
    error,
    status,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    "check-registration-status",
    async ({ next_offset = 0 }) => {
      const response = await AuthService.getRegistrationStatus();
      console.log("check-reg", response);
      return {
        data: response.data,
      };
    },
    refetch
  );
  */

  const locale = localStorage.getItem("language");
  const [open, setOpen] = React.useState(false);

  const handleChangeLang = (lang) => {
    localStorage.setItem("language", lang);
    localStorage.setItem("i18nextLng", lang);
    setOpen((open) => !open);
    history.push("/");
  };

  useEffect(() => {
    setOnStudio(true);
  }, [setOnStudio]);

  useEffect(() => {
    (async () => {
      const dateFormat = "YYYY-MM-DD";
      const now = moment().format(dateFormat);
      const { data } = await AuthService.getRegistrationStatus();
      const softLaunchDate = moment(
        data.registration_status.official_launch_date
      ).format(dateFormat);
      setRegistrationDate(
        moment(data.registration_status.official_launch_date).format(
          "DD.MM.YYYY"
        )
      );
      setIsOpenForRegistration(moment(now).isSameOrAfter(softLaunchDate));
    })();
  }, []);

  /*
  useEffect(() => {
    if (data && data.message === "success") {
      setExpiry(
        moment(data?.registration_status?.expiry).fromNow().includes("ago")
      );
      console.log(
        "expiry",
        moment(data?.registration_status?.expiry).fromNow()
      );
      localStorage.setItem(
        "expiry",
        `${moment(data?.registration_status?.expiry).fromNow().includes("ago")}`
      );
      localStorage.setItem("softlaunch", "true");
      setIsStillSoft(true);
    } else {
      setExpiry(true);
      setIsStillSoft(false);
      localStorage.setItem("expiry", "true");
      localStorage.setItem("softlaunch", "false");
    }
  }, [data]);
  */

  function clear() {
    document.getElementById("signin-input").value = "";
  }

  // Formik parameters and Yup validation inputs
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(2, t("errUsernameShortSignin"))
        .max(20, t("errUsernameLongSignin"))
        .required(t("errUsernameRequiredSignin")),
      password: Yup.string()
        .min(8, t("errPasswordShortSignin"))
        .required(t("errPasswordRequiredSignin")),
    }),
    onSubmit: async (values) => {
      try {
        const res = await AuthService.signIn({
          username: values.username,
          password: values.password,
        });

        // bottom navigation will show here but the problem is that when going to edit profile feet lover, it's not showing the correct page.
        setOnStudio(false);

        setResponseInfo(res.data);
        setAuthenticatedUser(res.data.user); // user state atom for dependency selector query
        setTickles(res?.data?.user?.tickles || 0);
        // bottom nav will not show up if set here.
        // setOnStudio(false);

        socket.connect(res.data.user._id);
          const lang =
            localStorage.getItem("language") ||
            localStorage.getItem("i18nextLng");
          const userLocale = await UserService.updateLanguage(
            res.data.user._id,
            lang
          );

        if (res.data.user.userType === "Model")
          history.push("/home-page-models");
        if (res.data.user.userType === "Lover") history.push("/home-page");

        localStorage.setItem("i18nextLng", userLocale.data.locale);
        localStorage.setItem("language", userLocale.data.locale);
      } catch (error) {
        Bugsnag.notify(error.response.data.error);
        clear();

        addNotificationMessage(
          `${error?.response?.data?.error || error?.response?.data?.message}` ||
            t("errSigninPostResponse"),
          "error"
        );
      }
    },
  });

  function clearEmail() {
    document.getElementById("subs-email-input").value = "";
  }

  // Formik parameters and Yup validation inputs for subscribing an email - soft launch
  const formikII = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("errInvalidSubsEmail"))
        .required(t("errSubsEmailRequired")),
    }),
    onSubmit: async (values, { setFieldValue }) => {
      try {
        if (values.email.length === 0) return;
        setIsSubscribing((isSubscribing) => !isSubscribing);
        const res = await AuthService.subscribeEmail({
          email: values.email,
        });
        console.info("Inscription Response: ", { res });
        setFieldValue("email", "");
        addNotificationMessage(t("preSubscription"), "success");
        setIsSubscribing((isSubscribing) => !isSubscribing);
      } catch (error) {
        Bugsnag.notify(error.response.data.error);
        clearEmail();

        addNotificationMessage(
          `${error?.response?.data?.error || error?.response?.data?.message}` ||
            t("errSigninPostResponse"),
          "error"
        );
        setIsSubscribing((isSubscribing) => !isSubscribing);
      }
    },
  });

  const translatedText = t("softJoinFirstUsersLabel").replace(
    "__registration_date__",
    registrationDate
  );

  const newsLetterPanel = () => {
    return (
      <div
        style={{
          border: "2px solid",
          borderRadius: "15px",
          borderColor: "#FF5768",
          textAlign: "center",
        }}
      >
        <div>
          <h1>Feetgirls,</h1>
        </div>
        <div>
          <h1>Feetlovers,</h1>
        </div>
        <div>
          <h4>{t("softPreRegistrationLabel")}</h4>
        </div>
        <div
          style={{
            minWidth: "100px",
            marginLeft: "10px",
            marginRight: "10px",
          }}
        >
          <h4>{translatedText}</h4>
        </div>
        <p>{t("softRegisterYourEmailLabel")}</p>
        <div
          style={{
            marginLeft: "10px",
            marginRight: "10px",
            marginBottom: "10px",
          }}
        >
          <Paper component="form" className={classes.root}>
            <InputBase
              id="subs-email-input"
              name="email"
              className={classes.input}
              placeholder={t("softRegisterYourEmailInputLabel")}
              inputProps={{
                "aria-label": t("softRegisterYourEmailInputLabel"),
              }}
              value={formikII.values.email}
              onChange={formikII.handleChange}
            />
            <Button
              className={`${classes.iconButton} email-subscription-button`}
              aria-label={"send-email"}
              onClick={() => formikII.handleSubmit()}
              disabled={isSubscribing}
              style={{
                opacity: isSubscribing ? "0.7" : "1",
              }}
            >
              {!isSubscribing ? (
                "OK"
              ) : (
                <CircularProgress
                  style={{
                    width: "1.875rem",
                    height: "1.875rem",
                    color: "#ccc",
                  }}
                />
              )}
            </Button>
          </Paper>
        </div>
      </div>
    );
  };

  const userRegistrationLinkPanel = () => {
    return (
      <div className="bottom__signIn">
        <hr size="2" className="formSignin__roundedHr" />
        <Grid item className="formSignin__gridItems formSignin__createAccount">
          {expiry === true && isStillSoft === false ? (
            <Link to="/choose-account" variant="body2">
              {t("createAccountSignin")}
            </Link>
          ) : null}
          {expiry === false && isStillSoft === true ? (
            <Link to="/soft-choose-account" variant="body2">
              {t("createAccountSignin")}
            </Link>
          ) : null}
        </Grid>
      </div>
    );
  };

  const loginForm = () => {
    return (
      <form
        className="formSignin__form"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <span>
          <div className="name-input">{t("usernameTitleSignin")}</div>
          <span className="spanInput">
            <input
              className="formSignin__firstInput"
              type="text"
              name="username"
              placeholder={t("placeholderUsernameSignin")}
              value={formik.values.username}
              onChange={formik.handleChange}
            />
            {formik.errors.username && formik.touched.username && (
              <p>{formik.errors.username}</p>
            )}
          </span>
        </span>
        <span className="login__password_wrap">
          <div className="name-input">{t("passwordTitleSignin")}</div>
          <span className="spanInput">
            <input
              id="signin-input"
              className="formSignin__secondInput"
              type="password"
              name="password"
              placeholder={t("placeholderPasswordSignin")}
              value={formik.values.password}
              onChange={formik.handleChange}
            />{" "}
            {!togglePasswordVisibility ? (
              <VisibilityOffIcon
                className="visibilityIcon"
                onClick={handlePasswordVisibility}
              />
            ) : (
              <VisibilityIcon
                className="visibilityIcon"
                onClick={handlePasswordVisibility}
              />
            )}
            {formik.errors.password && formik.touched.password && (
              <p>{formik.errors.password}</p>
            )}
          </span>
        </span>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className="formSignin__submit pink__button"
        >
          {t("loginButtonSignin")}
        </Button>
        <Grid container className="formSignin__textUnderButtons">
          <span>
            <Grid item className="formSignin__gridItems">
              <Link to="/forgot-password" variant="p">
                {t("forgotPasswordSignin")}
              </Link>
            </Grid>
          </span>
        </Grid>
      </form>
    );
  };

  // const LanguageSelectionAlert = () => {
  //   const locale =
  //     localStorage.getItem("language") || localStorage.getItem("i18nextLng");
  //   return (
  //       <>
      // <IonText
      //   onClick={() => {
          // presentAlert({
          //   header: t("selectLanguage"),
          //   cssClass: "language-selection",
          //   buttons: ["Ok"],
          //   inputs: [
          //     {
          //       label: t("frenchSettingLover"),
          //       type: "radio",
          //       value: "fr",
          //       checked: locale === "fr",
          //     },
          //     {
          //       label: t("englishSettingLover"),
          //       type: "radio",
          //       value: "en",
          //       checked: locale === "en",
          //     },
          //   ],
          //   onDidDismiss: (data) => {
          //     const value = data.detail.data.values;
          //     localStorage.setItem("language", value);
          //     localStorage.setItem("i18nextLng", value);
          //     history.push("/");
          //   },
          // });
      //   }}
      //   className="formSignin__privacy-terms"
      //   style={{
      //     fontSize: "12px",
      //     color: "#fe294e",
      //     position: "inherit",
      //     display: "flex",
      //     cursor: "pointer",
      //   }}
      // >
      //   {t("languageSettingLover")} - {locale}
      // </IonText>
  //   </ >);
  // };

  const PolicyPanel = () => {
    return (
      <Grid
        container
        direction="row"
        justify-content="center"
        alignItems="center"
        spacing={1}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item className="formSignin__privacy-terms">
          <a
            href={
              i18n.language === "en"
                ? "https://feety.com/en/privacy-policy"
                : "https://feety.com/confidentialite"
            }
            target={"_blank"}
            rel="noreferrer"
          >
            {t("privacyPolicy")}
          </a>
        </Grid>
        <Grid item className="formSignin__privacy-terms">
          <a
            href={
              i18n.language === "en"
                ? "https://feety.com/en/terms-of-service"
                : "https://feety.com/conditions-generales-utilisation"
            }
            target={"_blank"}
            rel="noreferrer"
          >
            {t("termsAndConditions")}
          </a>
        </Grid>
        {/*<Grid item className="formSignin__privacy-terms">*/}
        {/*  <LanguageSelectionAlert />*/}
        {/*</Grid>*/}
      </Grid>
    );
  };

  return (
    <Container className="formSignin__container signin_Page" component="main">
      <CssBaseline />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{top: "5em"}}
      />
      <LanguageSelector
        locale={locale}
        handleChangeLang={handleChangeLang}
        setOpen={setOpen}
        open={open}
      />
      <Container className="formSignin__paper" maxWidth="xs">
        <div className="formSignin__imageContainer">
          <img className="auth__imgLogo" src={logo} alt="" />
        </div>

        {loginForm()}
        <Container className="formSignin__gridButtons">
          {isOpenForRegistration ? userRegistrationLinkPanel() : null}
          <PolicyPanel />
        </Container>
      </Container>
    </Container>
  );
};

export default FormSignIn;
