import axios from "axios";
import { selector } from "recoil";

import authenticatedUserAtom from "../atoms/auth/user";

import { getAuthSession } from "../../helpers/auth.helpers";

const { REACT_APP_API_BASE_URL } = process.env;

const getUserQuery = selector({
  key: "user",
  get: async ({ get }) => {
    try {
      // note: this will trigger if the dependency atom is updated anywhere in the application.
      get(authenticatedUserAtom); // added as dependency to refetch user query
      const userId = getAuthSession().user._id;

      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/v1/users/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${getAuthSession().user.access_token}`,
          },
        }
      );

      return response.data;
    } catch (e) {
      console.error("ERROR GET /api/v1/users/:id", e);
    }
  },
});

export default getUserQuery;
