/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  CssBaseline,
  Grid,
  Typography,
} from "@material-ui/core/";
import { toast, ToastContainer } from "react-toastify";
import { useRecoilState } from "recoil";

import "./resetPassword.css";
import "react-toastify/dist/ReactToastify.css";

import logo from "../../../assets/images/logo-login.png";

import { studioAtom } from "../../../stateManagement/atoms/studioAtom";
import AuthService from "../../../_services/auth.service";

const ForgotPassword = (props) => {
  // for making redirection with react-router-dom
  let history = useHistory();
  // Variable for translation text
  const { t } = useTranslation();
  // Set info user to session storage

  const [onStudio, setOnStudio] = useRecoilState(studioAtom);

  function clear() {
    document.getElementById("signin-input").value = "";
  }

  // Formik parameters and Yup validation inputs
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("errEmailInvalidFormatSignup"))
        .required(t("errEmailRequiredSignup")),
    }),
    onSubmit: async (values) => {
      try {
        const response = await AuthService.forgotPassword({
          email: values.email,
        });

        toast.dark("Success! Please check your email.", {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        toast.error(t("errSigninPostResponse"), {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
  });

  useEffect(() => {
    if (onStudio === false) {
      setOnStudio(true);
    }
  }, [onStudio, setOnStudio]);

  return (
    <Container className="formSignin__container" component="main" maxWidth="xs">
      <CssBaseline />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ top: "5em" }}
      />
      <div className="formSignin__paper">
        <div className="formSignin__imageContainer">
          <img className="auth__imgLogo" src={logo} alt="" />
        </div>
        <form
          className="formSignin__form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <span>
            <div className="name-input">{t("emailTitleSignup")}</div>
            <span className="spanInput">
              <input
                className="formSignin__firstInput"
                type="email"
                name="email"
                placeholder={t("placeholderEmailSignup")}
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.errors.email && formik.touched.email ? (
                <p>{formik.errors.email}</p>
              ) : null}
            </span>
          </span>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className="formSignin__submit pink__button"
          >
            Submit
          </Button>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="default"
            className="formSignin__submit white__button"
            onClick={() => history.push("/")}
          >
            Back to login
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default ForgotPassword;
