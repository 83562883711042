import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ToastContainer } from "react-toastify";
import { ChevronLeft } from "@material-ui/icons";
import {
  Button,
  Container,
  CssBaseline,
  IconButton,
  Typography,
} from "@material-ui/core/";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import "./feet-details.css";
import { useStyles } from "./styled";
import logo from "../../assets/images/logo-login.png";
import feetDetailAtom from "../../stateManagement/atoms/feetDetailAtom";
import feetParticularityAtom from "../../stateManagement/atoms/feetParticularityAtom";
import PictyService from "../../_services/picty.service";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import signUpUserDataAtom from "../../stateManagement/atoms/auth/sign-up-user-data-atom";
import setSignUpUserData from "../../stateManagement/selectors/auth/sign-up-user-data-selector";

export default function FeetDetails({ userType }) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { addNotificationMessage } = useNotificationAlert();

  const [feetDetail, setFeetDetail] = useRecoilState(feetDetailAtom);
  // eslint-disable-next-line no-unused-vars
  const [feetParticularity, setFeetParticularity] = useRecoilState(
    feetParticularityAtom
  );
  const [signUpUserData] = useRecoilState(signUpUserDataAtom);
  const setSignUpUserDataSelector = useSetRecoilState(setSignUpUserData);

  const [feetParticularities, setFeetParticularities] = useState(null);

  const [euroShoeSizes, setEuroShoeSizes] = useState([
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    41,
    42,
    43,
    44,
    45,
  ]);

  const isStepCompleted = () => {
    if (feetDetail === "" || !feetParticularity) {
      addNotificationMessage(
        "Vous devez renseigner tous les champs!!!",
        "error"
      );
    } else {
      setSignUpUserDataSelector({
        ...signUpUserData,
        footSize: feetDetail,
        userFootType: feetParticularity?._id,
      });

      history.push("/update-subscriptions");
    }
  };

  useEffect(() => {
    async function fetchPictiesByCategory() {
      const response = await PictyService.getPictyByCategory("Inscription");

      if (response) {
        setFeetParticularities(response.data.picties.reverse());
      }
    }

    fetchPictiesByCategory().then();
  }, [setFeetParticularities]);

  return (
    <Container
      className="feetDetails__container"
      component="main"
      maxWidth="xs"
    >
      <CssBaseline />
      <div className="feet_details_page_fixed_header">
        <IconButton
          className="back__button"
          onClick={() => {
            if (
              localStorage.getItem("expiry") &&
              localStorage.getItem("expiry").includes("false") &&
              localStorage.getItem("softlaunch") &&
              localStorage.getItem("softlaunch").includes("true")
            ) {
              history.push("/soft-choose-account");
            } else {
              history.push("/choose-account");
            }
          }}
        >
          <ChevronLeft />
        </IconButton>

        <h4> {t("firstStepWelcomeFeetGirl")}</h4>
      </div>
      <div className="feetDetails__paper">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{top: "5em"}}
        />
        {/* <div className="feetDetails__imageContainer">
          <img className="feetDetails__imgLogo" src={logo} alt="" />
        </div>
        <Typography className="feetDetails__Text " component="h1" variant="h5">
          {t("firstStepWelcomeFeetGirl")}
        </Typography> */}
        <Typography
          className="feetDetails__Text  feetDetails__TextRegular"
          component="h1"
          variant="h5"
        >
          {t("firstStepMakeAPortrait")}
        </Typography>
        <form className="feetDetails__form" noValidate>
          <div className="feetDetails__feetSizeInput">
            {t("firstStepMeasurementLabel")}
          </div>
          <div className={classes.accordion}>
            <Accordion>
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon style={{ fill: "#E5586E" }} size={15} />
                }
                aria-controls="panel1a-content"
                id=""
              >
                <p>{feetDetail}</p>
              </AccordionSummary>
              {euroShoeSizes.length
                ? euroShoeSizes.map((size) => (
                    <p
                      key={size}
                      onClick={() => setFeetDetail(size)}
                      className={`pTag ${
                        size === feetDetail ? "selected-shoe-size" : ""
                      }`}
                    >
                      Size {size} EU
                    </p>
                  ))
                : ""}
            </Accordion>
          </div>

          <span>
            <div className="feetDetails__feetParticularity">
              {t("firstStepChooseBloodline")}
            </div>
            <div className="feetDetails__pict_container picty__container choose_picty_bloodline">
              {feetParticularities && feetParticularities.length
                ? feetParticularities.map((picty) => (
                    <div key={picty._id}>
                      <Button
                        onClick={() => setFeetParticularity(picty)}
                        style={{ borderRadius: "25px" }}
                      >
                        <img
                          className={
                            feetParticularity._id === picty._id &&
                            "selectedPicty"
                          }
                          src={picty.url}
                          alt={picty.label}
                          style={{ width: "50px" }}
                        />
                      </Button>
                      <p>{picty.label}</p>
                      <span>{picty?.description}</span>
                    </div>
                  ))
                : ""}
            </div>
          </span>
        </form>
      </div>
      <Button
        onClick={() => isStepCompleted()}
        fullWidth
        variant="contained"
        color="secondary"
        className="feetDetails__submit pink__button "
      >
        {t("firstStepNextStepButton")}
      </Button>
    </Container>
  );
}
