/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronLeft } from "@material-ui/icons";
import { Avatar, Button, IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { debounce as _debounce, remove as _remove } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isIOS } from "react-device-detect";

import "./searchDefaultView.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import SearchIcon from "../../../assets/images__v3/Recherche_outline_2.png";
import settingSearch from "../../../assets/images/search-default-view-lovers/filter-icon.svg";
import genericAvatar from "../../../assets/images/generic-user-avatar.png";

import FeetModelService from "../../../_services/feet-models.service";
import searchFilterPictyAtom from "../../../stateManagement/atoms/searchFilterPictyAtom";
import { userTypeAtom } from "../../../stateManagement/atoms/studioAtom";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import useSessionStorage from "../../forms/services/storageHooks/useSessionStorage";

import PublicationMedia from "../../PublicationMedia";

import UserService from "../../../_services/user.service";
import feetModelAtom from "../../../stateManagement/atoms/feetModelAtom";
import CloseIcon from "../../../assets/images/pict/close.svg";
import newMessageBadgeAtom from "../../../stateManagement/atoms/newMessageBadgeAtom";
import MessagesIcon from "../../../assets/images/home-page-models/messages-icon.svg";

const SearchDefaultView = (props) => {
  let history = useHistory();
  const { t } = useTranslation();

  // global state for search filter picties
  const [searchFilterPicty, setSearchFilterPicty] = useRecoilState(
    searchFilterPictyAtom
  );
  const [userType, _] = useRecoilState(userTypeAtom);
  const [newMessageBadge] = useRecoilState(newMessageBadgeAtom);
  const [authUser] = useSessionStorage({}, "infosUser");
  const [__, setFeetModel] = useRecoilState(feetModelAtom);

  // local state
  const [query, setQuery] = useState(""); // this query will now be for users...
  const [searchQuery, setSearchQuery] = useState({});

  /** feet model publications */
  const [feetModelPublications, setFeetModelPublications] = useState([]);
  const [offset, setNextOffset] = useState(0);
  const [status, setStatus] = useState("");
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);

  const offSetRef = useRef();
  offSetRef.current = offset;

  /** users */
  const [users, setUsers] = useState([]);
  const [offsetUsers, setNextOffsetUsers] = useState(0);
  const [isFetchingNextPageOfUsers, setIsFetchingNextPageOfUsers] =
    useState(false);

  const offSetUserRef = useRef();
  offSetUserRef.current = offsetUsers;

  /** fetch next page callback function */
  const fetchNextPage = useCallback(
    () => setIsFetchingNextPage(true),
    [setIsFetchingNextPage]
  );

  const fetchNextPageOfUsers = useCallback(
    () => setIsFetchingNextPageOfUsers(true),
    [setIsFetchingNextPageOfUsers]
  );

  const loadMorePublicationsReference = useRef();
  const loadMoreUsersReference = useRef();

  /**
   * observer to enable infinite scroll and execute fetch next page callback function for feet model publications.
   */
  useIntersectionObserver({
    target: loadMorePublicationsReference,
    onIntersect: fetchNextPage,
    enabled: offSetRef.current,
  });

  /**
   * observer reference to enable infinite scroll for users
   */
  useIntersectionObserver({
    target: loadMoreUsersReference,
    onIntersect: fetchNextPageOfUsers,
    enabled: offSetUserRef.current,
  });

  const onChange = ({ target: { value } }) => {
    setQuery(value);

    const searchPublications = _debounce(memoizedSendQueryCallback, 200);
    const search = _debounce(memoizedSendQueryCallbackForSearchUser, 200);

    setSearchQuery((prevSearch) => {
      if (prevSearch.cancel) {
        prevSearch.cancel();
      }
      return search;
    });

    if (value) {
      search(value); // search users
    } else {
      setUsers([]);
      setNextOffsetUsers(0);
      setFeetModelPublications([]);
      setNextOffset(0);
      setIsFetchingNextPageOfUsers(false);
      searchPublications(value); // search publications
    }
  };

  // send query call back for feet model publications
  const memoizedSendQueryCallback = useCallback(
    async (value = "") => {
      try {
        setIsFetchingNextPage(false);
        setStatus("loading");

        const picty =
          searchFilterPicty && searchFilterPicty.length
            ? searchFilterPicty.map((picty) => picty._id)
            : [];

        const { cancelPrevQuery, data } =
          await FeetModelService.searchFeetModelPublications(value, {
            picty,
          });

        if (cancelPrevQuery) return;

        if (data && data.publications) {
          setStatus("success");
          setFeetModelPublications(data.publications);
          setNextOffset(data.next_offset);
        }
      } catch (error) {
        setStatus("error");
        console.log("ERROR GET search feet model publications: ", error);
      }
    },
    [searchFilterPicty]
  );

  // send query call back for searching user
  const memoizedSendQueryCallbackForSearchUser = useCallback(
    async (value = "") => {
      try {
        setIsFetchingNextPageOfUsers(false);
        setStatus("loading");

        const { cancelPrevQuery, users, next_offset } =
          await UserService.searchUsers(value);

        if (cancelPrevQuery) return;

        if (users) {
          setStatus("success");
          setUsers(users);
          setNextOffsetUsers(next_offset);
        }
      } catch (error) {
        setStatus("error");
        console.log("ERROR GET search feet model publications: ", error);
      }
    },
    []
  );

  /**
   * On-mount fetch initial feet model publications.
   */
  useEffect(() => {
    setStatus("loading");

    const picty =
      searchFilterPicty && searchFilterPicty.length
        ? searchFilterPicty.map((picty) => picty._id)
        : [];

    FeetModelService.searchFeetModelPublications(
      query,
      {
        picty,
      },
      offSetRef.current
    ).then(({ data }) => {
      if (data && data.publications) {
        setStatus("success");
        setFeetModelPublications(data.publications);
        setNextOffset(data.next_offset);
      }
    });
  }, [searchFilterPicty]);

  /**
   * Fetch more feet model publications if it has next page
   */
  React.useEffect(() => {
    if (isFetchingNextPage) {
      const picty =
        searchFilterPicty && searchFilterPicty.length
          ? searchFilterPicty.map((picty) => picty._id)
          : [];

      FeetModelService.searchFeetModelPublications(
        query,
        {
          picty,
        },
        offSetRef.current
      ).then(({ data }) => {
        if (data && data.publications) {
          setFeetModelPublications([
            ...feetModelPublications,
            ...data.publications,
          ]);
          setNextOffset(data.next_offset);
          setIsFetchingNextPage(false);
        }
      });
    }
  }, [isFetchingNextPage]);

  /**
   * Fetch more users if it has next page
   */
  React.useEffect(() => {
    if (isFetchingNextPageOfUsers) {
      UserService.searchUsers(query, offSetUserRef.current).then((response) => {
        setIsFetchingNextPageOfUsers(false);
        if (response && response.users && response.users.length) {
          setUsers([...users, ...response.users]);
          setNextOffsetUsers(response.next_offset);
        }
      });
    }
  }, [isFetchingNextPageOfUsers]);

  const handleRemoveSelectedSearchFilterPicty = (searchPicty) => {
    setSearchFilterPicty(() =>
      _remove(
        [...searchFilterPicty],
        (picty) => picty.code !== searchPicty.code
      )
    );
  };

  return (
    <div
      className={
        isIOS === true
          ? "searchDefaultView__container searchDefaultContainerIphone"
          : "searchDefaultView__container"
      }
    >
      <div className="searchDefaultView__head header page_fixed_header">
        <IconButton className="back__button" onClick={() => history.goBack()}>
          <ChevronLeft />
        </IconButton>
        <h4 className={"searchDefaultView__Title"}>
          {t("searchDefaultViewTitle")}
        </h4>
        {
          userType !== "Lover" ? (
              <IconButton
                  className="homePageModels__messages header__rightButtton"
                  onClick={() => history.push("/messages")}
              >
                <img src={MessagesIcon} alt="message icon" />
                {newMessageBadge && (
                    <span className="homePageModels__onMessages"></span>
                )}
              </IconButton>
          ) : null
        }

      </div>
      <div
        className={
          isIOS === true
            ? "searchDefaultView__search content__withHeader searchDefaultViewIphone searchDefaultView__searchIphoneXr"
            : "searchDefaultView__search content__withHeader"
        }
      >
        <div className="searchDefaultView__inputContainer">
          <div className="searchInput__wrapper">
            <input
              className="searchDefaultView__input"
              type="text"
              placeholder={t("searchDefaultViewTitle")}
              value={query}
              onChange={onChange}
            />
            <img id="searchIcon" src={SearchIcon} alt="search" />
          </div>
          <Button onClick={() => history.push("/filter")}>
            <img id="settingSearch" src={settingSearch} alt="setting" />
          </Button>
        </div>
        {searchFilterPicty && searchFilterPicty.length ? (
          <div className="searchDefaultView__selectedFilters">
            <div style={{ padding: "0 10px" }}>
              <p style={{ textAlign: "left" }}>{t("Active filters")}</p>
            </div>
            <div
              className="postPublicationSelectedPicty__list picty__container"
              style={{ justifyContent: "center" }}
            >
              {searchFilterPicty.map((searchPicty) => (
                <div key={searchPicty._id}>
                  <>
                    <Avatar
                      src={searchPicty.url}
                      className="postPublication__selectedAvatar"
                    />
                    <span className="postPublicationPictylist__button">
                      <img
                        onClick={() =>
                          handleRemoveSelectedSearchFilterPicty(searchPicty)
                        }
                        className="postPublicationPictyList__deleteButton"
                        src={CloseIcon}
                      />
                    </span>
                  </>
                  <p>{t(searchPicty.label)}</p>
                </div>
              ))}
            </div>
          </div>
          ) : ""
        }
        <div className="serachDefaultView__pictureContent">
          {status === "loading" ? (
            <div className="pagination-loader">
              <CircularProgress color="secondary" />
            </div>
          ) : query === "" && feetModelPublications.length ? (
            <>
              {feetModelPublications.map((publication) => (
                <div
                  key={publication._id}
                  className="searchDefaultView__picture"
                  onClick={() => {
                    // history.push({
                    //   pathname: `publication/${
                    //     publication.firstPublication.user.ambassadress
                    //       ? publication.firstPublication._id
                    //       : publication.lastPublication._id
                    //   }`,
                    //   state: publication.firstPublication.user.ambassadress
                    //     ? publication.firstPublication
                    //     : publication.lastPublication,
                    //   publication,
                    //   publication_id: publication.firstPublication.user
                    //     .ambassadress
                    //     ? publication.firstPublication._id
                    //     : publication.lastPublication._id,
                    // });

                    history.push({
                      pathname: `publication/${publication._id}`,
                      state: publication,
                      publication,
                      publication_id: publication._id,
                    });
                  }}
                >
                  <PublicationMedia
                    publication={publication}
                    className="searchDefaultViewPicture__img"
                    alt="pict search"
                    effect="blur"
                  />
                </div>
              ))}
              <div
                className="pagination-loader"
                ref={loadMorePublicationsReference}
                style={{ textAlign: "center" }}
              >
                {isFetchingNextPage ? (
                  <>
                    <CircularProgress color="secondary" size={10} />
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : query !== "" && users.length ? (
            <div
              style={{
                width: "100%",
              }}
            >
              {users.map((user) => (
                <div
                  className="user_thumbnail_details"
                  style={{ margin: "10px 0" }}
                  key={user._id}
                  onClick={() => {
                    if (user.userType === "Lover") {
                      if (authUser.user._id === user._id) {
                        history.push("/feet-lover-profile");
                      } else {
                        history.push({
                          pathname: `/visit-feet-lover/${user._id}`,
                          search: `?fromSearchPage=true`,
                          // fromSearchPage identifier will be use in search history
                        });
                      }
                    } else {
                      if (authUser.user._id === user._id) {
                        history.push("/view-own-profile-models");
                      } else {
                        setFeetModel(user);
                        history.push({
                          pathname: "/feet-models-view",
                          search: `?user=${user._id}&fromSearchPage=true`,
                          // fromSearchPage identifier will be use in search history
                        });
                      }
                    }
                  }}
                >
                  <div className="avatar">
                    <Avatar src={user.image ? user.image.url : genericAvatar} />
                  </div>

                  <div
                    className="user_details"
                    style={{ display: "inline-flex" }}
                  >
                    <h2 
                      className="messageSearch__name"
                      style={
                        user?.isSubscriber &&
                        authUser?.user?.userType === "Model"
                          ? { color: "#ff2775", fontWeight: "bold" }
                          : {}
                      }
                    >{user.username}</h2>
                  </div>
                </div>
              ))}
              <div
                className="pagination-loader"
                ref={loadMoreUsersReference}
                style={{ textAlign: "center" }}
              >
                {isFetchingNextPageOfUsers ? (
                  <>
                    <CircularProgress color="secondary" size={10} />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <div className="no-data-text">
              <p>{t("No Data")}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchDefaultView;
